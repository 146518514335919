import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainedButtons from '../../../../Components/Button/Button';
import { APIHelper } from '../../../../utils/ApiHelper';
import API from '../../../../utils/Urls';
import CircularProgress from '@material-ui/core/CircularProgress';
import { savePDF } from '@progress/kendo-react-pdf';
import HourlyContractPDFView from './HourlyContractPDFView';

const HourlyContract = (props) => {
    const [data, setData] = React.useState(props.contractData);
    const [loading, setLoading] = React.useState(false);
    const [pdfExport, setPdfExport] = React.useState(false);
    const pdfExportComponent = React.useRef(null);

    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
            .then((res) => {
                setData(res[0]);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const handlePDFSave = (event) => {
        setPdfExport(true);
        setTimeout(() => {
            savePDF(pdfExportComponent.current, {
                paperSize: 'A4',
                fileName: 'ClientContract.pdf',
            });
            setPdfExport(false);
        }, 1000);
    };

    return (
        <div>
            <div style={{ margin: 100, marginTop: 70, fontFamily: 'Libre Franklin' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                        Contract (Hourly)
                    </h3>
                </div>
                {loading ? (
                    <div style={{ textAlign: 'center', marginTop: 200 }}>
                        <CircularProgress size={40} />
                    </div>
                ) : data !== undefined ? (
                    <div style={{ marginTop: 64, fontSize: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Contract No:</p>
                            <p>{data.contractNumber}</p>
                        </div>
                        {data.employerProfileData &&
                            data.employerProfileData.length > 0 &&
                            data.employerProfileData[0].company && (
                                <div style={{ display: 'flex', marginTop: 30 }}>
                                    <p style={{ width: 350, color: '#9a9a9a', lineHeight: '70px' }}>
                                        Client:
                                    </p>
                                    <AccountCircleIcon
                                        style={{
                                            height: '70px',
                                            width: '70px',
                                        }}
                                    />
                                    <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                                        {data.employerProfileData &&
                                            data.employerProfileData.length > 0 &&
                                            data.employerProfileData[0].company &&
                                            data.employerProfileData[0].company.name}
                                    </div>
                                </div>
                            )}
                        <div style={{ display: 'flex', marginTop: 14 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                            <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Client Address:</p>
                            <p>
                                {data.employerProfileData &&
                                    data.employerProfileData.length > 0 &&
                                    data.employerProfileData[0].address &&
                                    data.employerProfileData[0].address.length > 0 &&
                                    `${data.employerProfileData[0].address[0].street_address}, ${data.employerProfileData[0].address[0].unit_number}, ${data.employerProfileData[0].address[0].city}, ${data.employerProfileData[0].address[0].state} ${data.employerProfileData[0].address[0].postal_code} ${data.employerProfileData[0].address[0].country}`}
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Talent Name:</p>
                            <p>{`${data.employeeData[0].firstName} ${data.employeeData[0].lastName}`}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                            <p>{data.jobs[0].title}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ minWidth: 350, color: '#9a9a9a' }}>Field of Work:</p>
                            <p>{data.jobs[0].field && data.jobs[0].field.name}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 30,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: 59,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Speciality:</p>
                            <p>{data.jobs[0].speciality && data.jobs[0].speciality.name}</p>
                        </div>
                        <div
                            style={{
                                marginTop: 59,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: '50px',
                            }}
                        >
                            <p style={{ minWidth: 350, color: '#9a9a9a' }}>Job Description:</p>
                            <pre
                                style={{
                                    marginTop: 30,
                                    color: '#1c1c1c',
                                    fontsize: 20,
                                    fontFamily: 'Libre Franklin',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {data.jobs[0].description}
                            </pre>
                        </div>
                        {data.jobs[0].additionalDetails && (
                            <div
                                style={{
                                    marginTop: 59,
                                    borderBottom: '2px solid #C4C4C4',
                                    paddingBottom: '59px',
                                }}
                            >
                                <p style={{ minWidth: 350, color: '#9a9a9a' }}>
                                    Additional Instructions:
                                </p>
                                <pre
                                    style={{
                                        marginTop: 30,
                                        color: '#1c1c1c',
                                        fontsize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {data.jobs[0].additionalDetails}
                                </pre>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 59,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: 59,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                            <p>
                                {data.jobs[0].remoteWorking
                                    ? 'Remote Working'
                                    : `${data.jobs[0].workAddress.street_address} ${
                                          data.jobs[0].workAddress.unit_number
                                              ? ', ' + data.jobs[0].workAddress.unit_number
                                              : ''
                                      }, ${data.jobs[0].workAddress.city}, ${
                                          data.jobs[0].workAddress.state
                                      } ${data.jobs[0].workAddress.postal_code} ${
                                          data.jobs[0].workAddress.country
                                      }`}
                            </p>
                        </div>
                        <div style={{ marginTop: 59 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work Schedule:</p>
                            <div
                                style={{
                                    border: '2px solid #C4C4C4',
                                    borderRadius: '15px',
                                    marginTop: '15px',
                                    paddingRight: '10px',
                                    paddingLeft: '20px',
                                    height: 350,
                                    overflow: 'auto',
                                }}
                            >
                                <table
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        borderCollapse: 'collapse',
                                        fontFamily: 'Libre Franklin',
                                        height: 350,
                                        overflow: 'auto',
                                    }}
                                    className="table-small-row-font"
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                height: 60,
                                                borderBottom: '2px solid #C4C4C4',
                                            }}
                                        >
                                            <th style={{ width: '12%', color: '#1c1c1c' }}>Date</th>
                                            <th style={{ width: '12%', color: '#1c1c1c' }}>
                                                Start Time
                                            </th>
                                            <th style={{ width: '12%', color: '#1c1c1c' }}>
                                                End Time
                                            </th>
                                            <th style={{ width: '20%', color: '#1c1c1c' }}>
                                                Non-Payable (hr)
                                            </th>
                                            <th style={{ width: '15%', color: '#1c1c1c' }}>
                                                Working Hours
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.schedule.map((element, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    style={{
                                                        height: 60,
                                                        borderBottom: '2px solid #C4C4C4',
                                                        backgroundColor: 'inherit',
                                                    }}
                                                >
                                                    <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                        {moment(element.dateSchedule)
                                                            .utc(0)
                                                            .format('MMM DD, YYYY')}
                                                    </td>
                                                    <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                        {moment(element.startTime).format(
                                                            'hh:mm A'
                                                        )}
                                                    </td>
                                                    <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                        {moment(element.endTime).format('hh:mm A')}
                                                    </td>
                                                    <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                        {element.nonPayableTime}
                                                    </td>
                                                    <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                        {element.totalHours}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'space-between',
                                marginTop: 30,
                                marginBottom: 50,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Total Working Hours:</p>
                            <div
                                style={{
                                    border: '2px solid #C4C4C4',
                                    borderRadius: '5px',
                                    marginRight: '50px',
                                    padding: '12px',
                                }}
                            >
                                {data.jobs[0].hourlyTotalHours}
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                paddingTop: 59,
                                borderTop: '2px solid #C4C4C4',
                            }}
                        >
                            <p style={{ width: 300, color: '#9a9a9a' }}>Job Value:</p>
                            <p>
                                {data.jobs[0].jobLocationType==="LOCAL"?localStorage.getItem('local_currency_symbol'):"$"}
                                {localStorage.getItem('userType') === 'EMPLOYER'
                                    ? parseFloat(data.finalFee).toFixed(2)
                                    : (parseFloat(data.finalFee) - parseFloat(data.empolinkServiceFeeByEmployer)).toFixed(2)}
                                {' '}{data.jobs[0].currency}    
                            </p>
                        </div>
                        {data.jobs[0].paymentMethod === 'DIRECT' ? (
                            <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                                Client will pay an amount equal to job value directly to talent.
                            </p>
                        ) : (
                            <>
                                <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                                    Client will pay an amount equal to job value to empolink.
                                </p>
                                <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                                    Empolink will pay talent an amount equal to job value deducted
                                    by talent service fee.
                                </p>
                            </>
                        )}
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 300, color: '#9a9a9a' }}>Client Payment Method:</p>
                            <p>
                                {data.jobs[0].paymentMethod === 'DIRECT'
                                    ? 'Directly by Client'
                                    : 'Through Empolink'}
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 300, color: '#9a9a9a' }}>Payment Cycle:</p>
                            <p>
                                {data.jobs[0].paymentFrequency === 'WEEKLY'
                                    ? 'Weekly'
                                    : 'Bi-weekly'}
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 300, color: '#9a9a9a' }}>Date:</p>
                            <p>{moment(data.createdAt).utc(0).format('MMM DD, YYYY')}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 300, color: '#9a9a9a' }}>Time:</p>
                            <p>{moment(data.createdAt).utc(0).format('hh:mm A')}</p>
                        </div>
                    </div>
                ) : null}
            </div>
            {!loading ? (
                <div style={{ marginLeft: 100, marginBottom: 100 }}>
                    <ContainedButtons
                        text={'Save PDF'}
                        onClick={(e) => {
                            handlePDFSave();
                        }}
                        color={props.isEmployee ? ['#a4c772', '#4e8400'] : ['#2493F1', '#15568D']}
                        height="56px"
                        width={'215px'}
                    />
                </div>
            ) : null}
            <div ref={pdfExportComponent}>
                {!loading && data !== undefined && pdfExport ? (
                    <HourlyContractPDFView data={data} />
                ) : null}
            </div>
        </div>
    );
};

export default HourlyContract;
