// All the API urls should have come from here

const BASE_URL = "https://api.empolink.com/";
const SOCKET_URL = "https://api.empolink.com";
// const BASE_FRONT_URL = "http://3.215.235.223:8000/";
const BASE_FRONT_URL = "https://empolink.com/";
// const BASE_FRONT_URL = "http://localhost:3000/";//front end url
export const STRIPE_PAYMENT_REFRESH_URL = "http://empolink.com/account-details";
export const STRIPE_PAYMENT_RETURN_URL = "http://empolink.com/success-connect";

// const BASE_URL = "http://localhost:3000/";//backend url
// const SOCKET_URL = "http://localhost:3000";//backend url
// export const STRIPE_PAYMENT_REFRESH_URL = "http://localhost:8000/account-details";
// export const STRIPE_PAYMENT_RETURN_URL = "http://localhost:8000/success-connect";

const Url = {
  //GET
  HOST: BASE_URL,
  SOCKET_URL: SOCKET_URL,
  FRONT_HOST: BASE_FRONT_URL,
  GET_COUNTRY: BASE_URL + "api/Masters/Country",
  GET_STATES: BASE_URL + "api/Masters/States",
  GET_CITIES: BASE_URL + "api/Masters/Cities",
  GET_FIELDS: BASE_URL + "api/Masters/Fields",
  GET_SPECIALITY_BY_FIELDID: BASE_URL + "api/Masters/Specialities",
  GET_SKILLS_BY_SPECIALITYID: BASE_URL + "api/Masters/Skills",
  GET_LANGUAGES: BASE_URL + "api/Masters/Languages",
  TALENT_SEARCH_BY_SEARCH_TERM: BASE_URL + "api/adminPanel/Search?searchTerm=",
  GET_LOCATION_SEARCH_BY_SEARCH_TERM: BASE_URL + "api/adminPanel/locationSearch?searchTerm=",
  GET_ACTIVE_JOB_POST: BASE_URL + "api/jobs/active/",
  GET_EMPLOYEE_PROFILE: BASE_URL + "api/employers/active-user",
  GET_COMPANY_DETAILS: BASE_URL + "api/company/one",
  GET_EMPLOYEES_ACTIVE_USER: BASE_URL + "api/employees/active-user",
  GET_EMPLOYEE_PORTFOLIO: BASE_URL + "api/employees/portfolio/",
  GET_EMPLOYEE_BY_ID: BASE_URL + "api/employees/one/",
  EMPLOYEE_UPLOAD_FILES: BASE_URL + "api/employees/uploads",
  GET_NOTIFICATION: BASE_URL + "api/notifications/",
  GET_JOB_POST_AND_INVITATION: BASE_URL + "api/applications/listJobs/employer?page=",
  GET_JOB_ONE: BASE_URL + "api/jobs/one/",
  GET_LIST_EMPLOYEE_OFFER: BASE_URL + "api/applications/listOffers/employee?applicationType=INVITATION&",
  GET_LIST_EMPLOYEE_APPLIED_JOB: BASE_URL + "api/applications/listOffers/employee?applicationType=APPLICATION&",
  GET_LIST_FOR_EMPLOYEE_APLICATION_REVIEW: BASE_URL + "api/jobs/application-review?page=",
  GET_APPLICATION_JOB_DETAILS: BASE_URL + "api/applications/one/",
  GET_LIST_EMPLOYEE_HOURLY_JOBS: BASE_URL + "api/contracts/hourly?",
  GET_LIST_TIMESHEET_JOBS: BASE_URL + "api/contracts/timesheet?",
  GET_LIST_EMPLOYEE_FIXED_JOBS: BASE_URL + "api/contracts/fixed?",
  GET_EMPLOYER_FIXED_ONGOING_JOB: BASE_URL + "api/contracts/employerOngoing/fixed?page=",
  GET_WORK_HISTORY_END_CONTRACT: BASE_URL + "api/contracts/closed?page=",
  GET_FAVORITES_EMPLOYEE: BASE_URL + "api/employers/favirouteEmployee",
  GET_CONTRACT_DETAILS: BASE_URL + "api/contracts/",
  GET_EMPLOYER_STATISTICS: BASE_URL + "api/employers/statistics",
  GET_EMPLOYER_JOBS: BASE_URL + "api/employers/jobs",
  GET_EMPLOYER_FINANCE: BASE_URL + "api/employers/finance",
  GET_EMPLOYER_INVOICE: BASE_URL + "api/employers/invoice",
  GET_EMPLOYER_OVERVIEW: BASE_URL + "api/employers/overview",
  GET_EMPLOYEE_STATISTICS: BASE_URL + "api/employees/statistics",
  GET_EMPLOYEE_FINANCE: BASE_URL + "api/employees/finance",
  GET_EMPLOYEE_INVOICE: BASE_URL + "api/employees/invoice",
  GET_EMPLOYEE_OVERVIEW: BASE_URL + "api/employees/overview",
  GET_EMPLOYEE_FEEDBACK: BASE_URL + "api/employees/feedback/",
  GET_EMPLOYEE_CALENDER: BASE_URL + "api/calender/",
  GET_STRIPE_ACCOUNT_ID: BASE_URL + "api/payment/account-create",
  GET_STRIPE_ACCOUNT_LINK: BASE_URL + "api/payment/account-link",
  GET_STRIPE_ACCOUNT_LINK_STATUS: BASE_URL + "api/payment/status",
  ADD_CARD_WITH_STRIPE: BASE_URL + "api/payment/add-card",
  GET_STRIPE_ACCOUNT_DETAILS: BASE_URL + "api/payment/get-status",
  GET_EMPLOYER_PAYMENT_METHOD: BASE_URL + "api/payment/employer-get-card-status",
  GET_EMPLOYEE_PAYMENT_METHOD: BASE_URL + "api/payment/employee-get-card-status",
  GET_EMPLOYER_PAYMENT_ACTIVE: BASE_URL + "api/payment/employer-paymentmethod",
  GET_EMPLOYEE_PAYMENT_ACTIVE: BASE_URL + "api/payment/employee-paymentmethod",
  STRIPE_REMOVE_CARD: BASE_URL + "api/payment/remove-card",
  STRIPE_REMOVE_CONNECT_ACCOUNT: BASE_URL + "api/payment/remove-stripe-connection",
  GET_CHARGING_DATA: BASE_URL + "api/payment/get-charging-details-of-contract/",

  //POST
  REGISTER: BASE_URL + "api/auth/register", //Employee
  REGISTER_EMPLOYER: BASE_URL + "api/auth/register-employer",
  LOGIN: BASE_URL + "api/auth/login",
  VERIFY_OTP: BASE_URL + "api/auth/verify-otp",
  RESEND_VERIFY_OTP: BASE_URL + "api/auth/resend-verify-otp",
  FORGOT_PASSWORD_LINK: BASE_URL + "api/auth/forgot-password-link",
  UPDATE_PASSWORD_BY_LINK: BASE_URL + "api/auth/update-password-bylink",
  EMPLOYEE_BY_SKILLS: BASE_URL + "api/employees/filter/skill",
  JOB_INVITE_POST: BASE_URL + "api/jobs/",
  TALENT_FILTER_ADVANCE: BASE_URL + "api/employees/filter/talent?page=",
  JOB_INVITE: BASE_URL + "api/jobs/invite",
  JOB_UPLOAD_FILES: BASE_URL + "api/jobs/uploads",
  CHANGE_PASSWORD: BASE_URL + "api/auth/change-password",
  CHECK_EMAIL: BASE_URL + "api/auth/checkEmail",
  UPDATE_MOBILE: BASE_URL + "api/auth/update-mobile",
  VERIFY_MOBILE: BASE_URL + "api/auth/verify-mobile-otp",
  ADD_COMPANY: BASE_URL + "api/company/",
  EDIT_EMPLOYER: BASE_URL + "api/employers/edit",
  EDIT_EMPLOYEES: BASE_URL + "api/employees/edit",
  UPDATE_EMAIL: BASE_URL + "api/auth/update-email",
  EDIT_PORTFOLIO: BASE_URL + "api/employees/edit-portfolio",
  EDIT_IDENTITY: BASE_URL + "api/employees/edit-identity",
  EDIT_NOTIFICATION: BASE_URL + "api/notifications/update",
  TALENT_FILTER_ADVANCE_EMPLOYEE: BASE_URL + "api/jobs/search?page=",
  APPLY_TO_FIX_JOB: BASE_URL + "api/jobs/apply/fix",
  APPLY_TO_HOURLY_JOB: BASE_URL + "api/jobs/apply/hour",
  GET_EMP_CALENDAR: BASE_URL + "api/contracts/getEmpCalendar",
  RATE_EMPLYOEE: BASE_URL + "api/employees/feedback/",
  RATE_EMPLOYER: BASE_URL + "api/employers/feedback/",
  REHIRING_EMPLOYER_JOB: BASE_URL + "api/jobs/rehiring?job=",
  GET_APPLICATION_VARIABLE: BASE_URL + "api/applications/variable",
  CONTRACT_NOT_CANCELLED: BASE_URL + "api/contracts/no-answer",
  UPDATE_CALENDER_LOG: BASE_URL + "api/calender/working-week",
  RESET_CALENDER: BASE_URL + "api/calender/reset",
  UPDATE_NON_WORKING_DAY: BASE_URL + "api/calender/non-working-days",
  GET_BLOCK_DATE: BASE_URL + "api/calender/schedule",
  CREATE_PAYMENT_INTENT: BASE_URL + "api/employers/payment-method-verification",
  PAYMENT_METHOD_HOLD: BASE_URL + "api/employers/payment-on-hold",
  RELEASE_PAYMENT_METHOD: BASE_URL + "api/employers/payment-release",
  PAYMENT_GENERATE: BASE_URL + "api/payment/generate-intent",

  // PUT
  JOB_APPLICATION_DECLINED: BASE_URL + "api/applications/decline/",
  JOB_APPLICATION_WITHDRAW_FROM_EMPLOYEE: BASE_URL + "api/applications/withdraw/",
  JOB_APPLICATION_APPROVE: BASE_URL + "api/applications/approve/",
  ACCEPT_JOB_FROM_EMPLOYEE_SIDE: BASE_URL + "api/applications/accept/",
  ACCEPT_JOB_FIXED_FROM_EMPLOYEE_SIDE: BASE_URL + "api/applications/accept-fixed/",
  CANCEL_CONTRACTS: BASE_URL + "api/contracts/cancel/",
  FINAL_CANCEL_CONTRACTS: BASE_URL + "api/contracts/finalcancel/",
  UPDATE_MILESTONES: BASE_URL + "api/contracts/updateMilestone/",
  UPDATE_TIMESHEET: BASE_URL + "api/contracts/modifyTimesheet/",
  ADD_TO_FAVORITE: BASE_URL + "api/employers/addFavEmployee",
  REVIEW_MILESTONE_BY_EMPLOYEE: BASE_URL + "api/contracts/update-milestone-agree/",

  // DELETE
  DELETE_JOB_POST: BASE_URL + "api/jobs/delete/",
  REMOVE_FAVORITE: BASE_URL + "api/employers/removeFavorite/",

  ADD_CONTACT: BASE_URL + "api/messages/addContact/",
  GET_CONTACT: BASE_URL + "api/messages/getContact",
  POST_MESSAGE: BASE_URL + "api/messages/postMessage",
  GET_CONVERSATIONS: BASE_URL + "api/messages/getConversations",
  GET_MESSAGES: BASE_URL + "api/messages/getMessage",
  UPDATE_CONVERSATIONS: BASE_URL + "api/messages/updateConversation",
  UPLOAD_FILE_CONVERSATIONS: BASE_URL + "api/messages/uploadConversation",
  SIGN_URL_CONVERSATIONS: BASE_URL + "api/messages/getConversationSignedUrl",
  MARK_FAVOURITE_CONVERSATIONS: BASE_URL + "api/messages/makeFavouriteConversation",
  ADD_NEW_CONVERSATIONS: BASE_URL + "api/messages/addNewConversation",
};

export default Url;
