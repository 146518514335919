import {LOADING, ERROR,  SUCCESS} from '../action/suggested_skills_action';
import { APIHelper } from '../utils/ApiHelper';
import API from '../utils/Urls'

export const getSuggestedSkillsList = (selectedSpeciality) => {

    return async dispatch => {
        try {

            dispatch({ type: LOADING })

            APIHelper.getMethod(
            API.GET_SKILLS_BY_SPECIALITYID + `?speciality_id=${selectedSpeciality.value}`
            ).then((response) => {
              if (response["status"] === 1) {

                let skillsArr = response.data.map((skill) => ({
                  ...skill,
                  lable: skill.name,
                  value: skill.name,
                  skills_id: skill._id,
                }))

                let moreOptions = skillsArr.splice(10)

                let updatedArr = skillsArr.map((skill) => ({
                  ...skill,
                  label: skill.lable,
                  value: skill.value,
                  isSelected : false,
                  skills_id: skill._id,
                }))

                dispatch({ type: SUCCESS, data : updatedArr })
              }else{
                dispatch({ type: ERROR, errorMessage : "Unable to fecth Spaciality List" })
              }
            })
          } catch(error) {
              dispatch({ type: ERROR })
          }
    }
  }
