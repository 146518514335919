import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './InstructionModal.scss';

const InstructionModal = ({ children, style }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  if (!isModalOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={style}>
        <span className="close-button" onClick={() => setIsModalOpen(false)}>×</span>
        {children}
        </div>
    </div>
  );
};

InstructionModal.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

InstructionModal.defaultProps = {
  style: {},
};

export default InstructionModal;
