import React from 'react';
import ScreenSize from '../../components/screen_size'
import JobDescriptionMobile from './components/job_description_mobile'
import JobDescriptionWeb from './components/job_description_web'


export default class JobDescription extends React.Component {

render() {
  return (
      <ScreenSize
        mobile  = {<JobDescriptionMobile/>}
        tablet = {<JobDescriptionMobile/>}
        web = {<JobDescriptionWeb/>}/>
    );
  }
}
