import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainedButtons from '../../../../Components/Button/Button';
import { APIHelper } from '../../../../utils/ApiHelper';
import API from '../../../../utils/Urls';
import CircularProgress from '@material-ui/core/CircularProgress';

const ContractCancel = (props) => {
    const [data, setData] = React.useState(props.contractData);
    const [loading, setLoading] = React.useState(false);
    const [mileStone, setMileStone] = React.useState([]);
    const [mileStoneAmount, setMileStoneAmount] = React.useState(0);

    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
            .then((res) => {
                setData(res[0]);
                if (
                    res &&
                    res.length > 0 &&
                    res[0].milestone &&
                    res[0].milestone.length > 0 &&
                    res[0].jobs &&
                    res[0].jobs.length > 0
                ) {
                    const startDate = moment(res[0].jobs[0].startDate);
                    const milestone = res[0].milestone;

                    if (startDate < moment()) {
                        const currentMileStone = milestone.filter(
                            (x) =>
                                moment(x.dateSchedule) >= startDate &&
                                moment(x.dateSchedule) >= moment()
                        );

                        if (currentMileStone && currentMileStone.length > 0) {
                            const amount =
                                currentMileStone &&
                                currentMileStone.length > 0 &&
                                currentMileStone.map((x) => x.amount);
                            setMileStoneAmount(amount[0]);
                            setMileStone([currentMileStone[0]]);
                        }
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
            <div style={{ display: 'flex' }}>
                <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                    Job Cancellation Request
                </h3>
            </div>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: 200 }}>
                    <CircularProgress size={40} />
                </div>
            ) : data !== undefined ? (
                <div style={{ marginTop: 64, fontSize: 20 }}>
                    <div style={{ display: 'flex' }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>
                            Contract No:
                        </p>
                        <p>{data.contractNumber}</p>
                    </div>
                    {data.employerProfileData &&
                        data.employerProfileData.length > 0 &&
                        data.employerProfileData[0].company && (
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a', lineHeight: '70px' }}>
                                    Client:
                                </p>
                                <AccountCircleIcon
                                    style={{
                                        height: '70px',
                                        width: '70px',
                                    }}
                                />
                                <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                                    {data.employerProfileData &&
                                        data.employerProfileData.length > 0 &&
                                        data.employerProfileData[0].company &&
                                        data.employerProfileData[0].company.name}
                                </div>
                            </div>
                        )}
                    <div style={{ display: 'flex', marginTop: 14 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                        <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Talent Name:</p>
                        <p>{`${data.employeeData[0].firstName} ${data.employeeData[0].lastName}`}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                        <p>{data.jobs[0].title}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Cancellation date and time:</p>
                        <p>{moment(data.updatedAt).utc(0).format('hh:mm A - MMM DD, YY')}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Work Start Date:</p>
                        <p>{moment(data.jobs[0].startDate).utc(0).format('MMM DD, YYYY')}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Work End Date:</p>
                        <p>{moment(data.jobs[0].endDate).utc(0).format('MMM DD, YYYY')}</p>
                    </div>
                    <div
                        style={{
                            marginTop: 50,
                            borderBottom: '2px solid #C4C4C4',
                            borderTop: '2px solid #C4C4C4',
                            paddingTop: 30,
                            paddingBottom: '50px',
                        }}
                    >
                        <p style={{ minWidth: 350, color: '#9A9A9A' }}>Cancellation Reason:</p>
                        <p style={{ marginTop: 15 }}>{data.reasonType}</p>
                        {data.reason && (
                            <>
                                <p style={{ minWidth: 350, color: '#9A9A9A', marginTop: 30 }}>
                                    Cancellation reason:
                                </p>
                                <p style={{ marginTop: 15 }}>{data.reason}</p>
                            </>
                        )}
                    </div>
                    {props.page === 'HOURLY' && (
                        <div
                            style={{
                                borderBottom: '2px solid #C4C4C4',
                                paddingTop: 30,
                                paddingBottom: '50px',
                            }}
                        >
                            <p style={{ marginTop: 15, color: '#9A9A9A' }}>
                                Your timesheet for the work done to date will be submitted to
                                client as planned.
                            </p>
                        </div>
                    )}
                    {props.page === 'FIXED' && (
                        <div
                            style={{
                                borderBottom: '2px solid #C4C4C4',
                                paddingTop: 50,
                                paddingBottom: '50px',
                            }}
                        >
                            <p style={{ marginTop: 15, color: '#9A9A9A' }}>Current Milestone</p>
                            <div
                                style={{
                                    border: '2px solid #C4C4C4',
                                    borderRadius: '15px',
                                    marginTop: '15px',
                                    paddingRight: '10px',
                                    paddingLeft: '20px',
                                }}
                            >
                                <table
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        borderCollapse: 'collapse',
                                        fontFamily: 'Libre Franklin',
                                    }}
                                    className="table-small-row-font"
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                height: 60,
                                                borderBottom: '2px solid #C4C4C4',
                                            }}
                                        >
                                            <th style={{ width: '25%', color: '#1c1c1c' }}>
                                                Milestones
                                            </th>
                                            <th style={{ width: '25%', color: '#1c1c1c' }}>
                                                Status
                                            </th>
                                            <th
                                                style={{
                                                    width: '20%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                Amount
                                            </th>
                                            <th style={{ width: '25%', color: '#1c1c1c' }}>
                                                Payment Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mileStone &&
                                            mileStone.length > 0 &&
                                            mileStone.map((element, index) => {
                                                return (
                                                    <tr
                                                        style={{
                                                            height: 60,
                                                            borderBottom: '2px solid #C4C4C4',
                                                            backgroundColor: 'inherit',
                                                        }}
                                                    >
                                                        <td
                                                            style={{
                                                                width: '25%',
                                                                color: '#1c1c1c',
                                                            }}
                                                        >
                                                            {element.description}
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '25%',
                                                                color: '#1c1c1c',
                                                            }}
                                                        >
                                                            In Progress {/* TODO */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '20%',
                                                                color: '#1c1c1c',
                                                            }}
                                                        >
                                                            ${element.amount}
                                                        </td>
                                                        <td
                                                            style={{
                                                                width: '25%',
                                                                color: '#1c1c1c',
                                                            }}
                                                        >
                                                            {moment(element.dateSchedule)
                                                                .utc(0)
                                                                .format('MMM DD, YYYY')}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <p style={{ marginTop: 35, color: '#9A9A9A' }}>
                                Client has specified the completion of “In Progress” milestone as
                            </p>
                            <p style={{ marginTop: 25, color: '#9A9A9A' }}>
                                {data.inProgressMilestoneByEmployer}
                            </p>
                            <div style={{ display: 'flex', marginTop: 25 }}>
                                <p style={{ color: '#9A9A9A', width: 340 }}>
                                    Final Milestone Amount:
                                </p>
                                <p style={{ color: '#1C1C1C' }}>
                                    ${data.finalMileStoneAmountByEmployer}
                                </p>
                            </div>
                            <p style={{ marginTop: 15, color: '#9A9A9A' }}>
                                (Percentage of work completed multiplied by current milestone
                                amount)
                            </p>
                        </div>
                    )}
                    <div
                        style={{
                            marginTop: 59,
                            padding: '41px 30px',
                            backgroundColor: '#d5d5d5',
                            borderRadius: 10,
                        }}
                    >
                        <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                            Note: This cancellation as submitted by client is final. You are
                            requested to provide your opinion below as Yes or No as it will impact
                            client’s credit rating.{' '}
                        </p>
                    </div>
                    <div style={{ marginTop: 60 }}>
                        <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                            Are you in agreement with the client?
                        </p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <div style={{ marginRight: 10 }}>
                            <ContainedButtons
                                text="No"
                                color={['#a4c772', '#4e8400']}
                                height="56px"
                                width={'12vw'}
                                variant="outlined"
                                onClick={() => props.finalCancelContractJob(props.contractId)}
                            />
                        </div>
                        <ContainedButtons
                            text="Yes"
                            color={['#a4c772', '#4e8400']}
                            height="56px"
                            width={'12vw'}
                            onClick={() => props.finalCancelJob(props.contractId)}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default ContractCancel;
