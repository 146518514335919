// All the app constant should be here

import defaultDp from "../Assets/blankProfile.png";

const CHOOSE_EMPLOYER_EMPLOYEE = "/signup";
const TALENT_SEARCH_ADVANCE = "/talent-search/advance";
const TALENT_SEARCH_ = "/talent-search";
const JOB_POST = "/job-post";
const USER_DETAILS = "/account-details";
const EMPLOYEE_USER_DETAILS = "/employee/account-details";
const HIRING_PROCESS_EMPLOYER = "/hiring-process";
const HIRING_PROCESS_EMPLOYEE = "/employee/hiring-process";
const EMPLOYEE_SEARCH_TALENT = "/employee-job-search";
const EMPLOYEE_ADVANCED_SEARCH_TALENT = "/employee-job-search/advance";
const INVOICES = "/invoices";
const EMPLOYER_DASHBOARD = "/overview";
const EMPLOYEE_DASHBOARD = "/employee/overview";
const VERIFICATION = "/verification";
const HELP = "/help";
const MESSAGES = "/messages";
const STRIPE_KEY_ENV = "pk_live_51JiKbiH9qqKxRW8b9ktoHgb5ouRta5ConslGjCyy3Y9n0Mos8B1ck2ivP4PBZcMBEcwlJXo1ieeip42ZssOwY7Ta00cO2jF7Do";

const CONSTANTS = {
  // Generic Constants
  EMPTY_STRING: "",

  // Routes
  ROUTES_CHOOSE_EMPLOYER_EMPLOYEE: CHOOSE_EMPLOYER_EMPLOYEE,
  ROUTE_TALENT_SEARCH_ADVANCE: TALENT_SEARCH_ADVANCE,
  ROUTES_TALENT_SEARCH: TALENT_SEARCH_,
  ROUTES_JOB_POST: JOB_POST,
  ROUTES_USER_DETAILS: USER_DETAILS,
  ROUTE_EMPLOYEE_DETAILS: EMPLOYEE_USER_DETAILS,
  HIRING_PROCESS_EMPLOYER: HIRING_PROCESS_EMPLOYER,
  HIRING_PROCESS_EMPLOYEE: HIRING_PROCESS_EMPLOYEE,
  EMPLOYEE_SEARCH_TALENT: EMPLOYEE_SEARCH_TALENT,
  EMPLOYEE_ADVANCED_SEARCH_TALENT: EMPLOYEE_ADVANCED_SEARCH_TALENT,
  EMPLOYER_DASHBOARD: EMPLOYER_DASHBOARD,
  EMPLOYEE_DASHBOARD: EMPLOYEE_DASHBOARD,
  INVOICES: INVOICES,
  MESSAGES: MESSAGES,
  // Single Invite Card
  SINGLE_CARD_INVITE_JOB_TITLE_DROPDOWN: "Job Title",
  SINGLE_CARD_INVITE_JOB_DESCRIPTION: "Job Description (optional)",
  SINGLE_CARD_INVITE_WORK_ADDRESS_LEVEL: "Work Address",
  SINGLE_CARD_INVITE_WORK_ADDRESS_DROPDOWN_LABEL: "Same as .....",
  SINGLE_CARD_INVITE_PAYMENT_METHOD_HEADING_LABEL: "Payment Method",
  SINGLE_CARD_SCHEDULE_LABEL: "Provide Schedule of Work",

  //validation messages for invite cards

  SELECT_JOB_ERROR_MSG: "Please select a job or new invitation.",
  EMPTY_JOB_TITLE_ERROR_MESSAGE: "Please add a job title.",
  WORK_ADDRESS_SELECTION_ERROR_MESSAGE: "Please select work address type",

  // Urls where footer is visible
  VISIBLE_HEADER_FOOTER_LIST: [
    TALENT_SEARCH_ADVANCE,
    TALENT_SEARCH_,
    JOB_POST,
    USER_DETAILS,
    EMPLOYEE_USER_DETAILS,
    HIRING_PROCESS_EMPLOYER,
    HIRING_PROCESS_EMPLOYEE,
    EMPLOYEE_SEARCH_TALENT,
    EMPLOYEE_ADVANCED_SEARCH_TALENT,
    INVOICES,
    EMPLOYER_DASHBOARD,
    EMPLOYEE_DASHBOARD,
    VERIFICATION,
    HELP,
    MESSAGES,
  ],

  // Default Resources go here
  DEFAULT_PROFILE_IMAGE: defaultDp,

  // Account Details Pages Constants
  ACCOUNT_INFORMATION_LABEL: "Account Information",
  PASSWORD_AND_SECURITY_LABEL: "Password and Security",
  FIELD_AND_SPECIALITY_LABEL: "Field and Specialities",
  ID_VERIFICATION_LABEL: "ID Verification",
  NOTIFICATION_SETTINGS_LABEL: "Notification Settings",
  PAYMENT_METHOD_LABEL: "Payment Method",
  DEPOSIT_METHOD_LABEL: "Deposit Method",
  SET_UP_COMPANY_PAGE_LABEL: "Set up Company Page",

  COMPANY_BUSSINESS_NAME_LABEL: "Company/Business Name",
  FIRST_NAME_LABEL: "First Name",
  LAST_NAME_LABEL: "Last Name",
  BUSSINESS_ADDRESS_LABEL: "Business Address",
  MOBILE_NUMBER_LABEL: "Mobile Number (optional)",
  LANGUAGES_LABEL: "Language(s)",
  MOBILE_VERIFICATION_LABEL: "Verify or Change",

  EMAIL_ADDRESS: "Email Address",
  PASSWORD: "Password",

  FIELD_OF_WORK: "Field of Work",

  // Employee Details
  MY_RATE: "My Rate",
  MY_PROFILE: "My Profile",
  PREFERRED_NAME: "Preferred Name",
  ADDRESS_LABEL: "Address",
  RATE: "Rate (For working in local market)",
  HIGHLIGHTS: "Highlights",

  // AWS Configurations
  BUCKET_NAME: "prd-empolink",
  REGION: "us-east-1",
  
  // Ip API
  GEO_LOCATION_API: "https://api.ipgeolocation.io/ipgeo?apiKey=53b337f79d02413fba7d2f626fa6402a",

  // Stripe public key
  // STRIPE_KEY : 'pk_test_O1muKEXXSGWqCtGIWmwuhbJC'
  // STRIPE_KEY: "pk_test_51JiKbiH9qqKxRW8byfz0vlEqDPW8oj2WTPTYiLLYM6IdXE1SZNTYHLwKMl5vwTtEf2VMlagI9mutFKOOBpNknJ0j00b6AHDrhe",
  // pk_live_51JiKbiH9qqKxRW8b9ktoHgb5ouRta5ConslGjCyy3Y9n0Mos8B1ck2ivP4PBZcMBEcwlJXo1ieeip42ZssOwY7Ta00cO2jF7Do
  STRIPE_KEY: STRIPE_KEY_ENV
};

export default CONSTANTS;
