import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {useEffect, useState } from 'react';
import Paper from "@material-ui/core/Paper";
import Spacer from './spacer'
import Text from './text'
import ScreenSize from './screen_size'

import {
  VERTICAL_SMALL
} from '../../../utils/enums';
import { ThemeProvider,createTheme } from '@material-ui/core/styles';

export default function RangeSlider(props) {
  const [width, setWidth] = useState("40%");

  const muiTheme = createTheme({
    overrides:{
      MuiSlider: {
        thumb:{
        color: "#1876D2",
        },
        track: {
          color: '#F50B0B'
        },
        rail: {
          color: '#9A9A9A'
        }
      }
  }
  });


  const useStyles = makeStyles({
      root: {
          marginLeft: "2.5vw",
          marginRight: "2.5vw"
      },

  });

  function valuetext(value) {
      return `$${value}`;
  }

  const useStylesCard = makeStyles({
      root: {
        width: width,
        backgroundColor: '#ffffff',
        borderRadius:"1.041vw",
      },
      title: {
        fontSize: 16,
      },
      pos: {
        marginTop: 12,
      },
    });

    const classes = useStyles();
    const cardClasses = useStylesCard();
    const [value, setValue] = React.useState([0, 50]);

    useEffect(() => {
        if(props.rangeValue !== undefined){
          setValue(props.rangeValue);
        }
      }, []);

      useEffect(() => {
        if(props.width !== undefined)
          setWidth(props.width);
        }, [props.width]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(props.onChangeCallback !== undefined){
          props.onChangeCallback(newValue);
        }

    };

    return (
      <div>

        <ScreenSize
          mobile  = {<Text fontSize = {"2vw"} color = {"#070707"} text={props.heading}/>}
          tablet = {<Text fontSize = {"2vw"} color = {"#070707"} text={props.heading}/>}
          web = {<Text fontSize = {"1.04166vw"} color = {"#070707"} text={props.heading}/>}/>

          <Spacer spaceType={VERTICAL_SMALL} />

          <ThemeProvider theme={muiTheme}>
        <Paper className={cardClasses.root}>
        <div style={{display:"inline-block"}}>{props.rangeDisplay}</div>
            <div className={classes.root}>
                <Slider
                   valueLabelDisplay="on"
                    value={value}
                     onChange={handleChange}
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    marks={props.marks}
                    min={props.min}
                    max={props.max}
                />
            </div>
        </Paper>
         </ThemeProvider>
        </div>
    );
}
