import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
// import AddIcon from '@material-ui/icons/Add';
import PaymentCard from "../../Assets/PaymentCard.svg";
import DiscoverCard from "../../Assets/DiscoverCard.svg";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import StripeConnect from './StripeConnect';
import { APIHelper } from "../../utils/ApiHelper";
import API from "../../utils/Urls";
import CONSTANTS from "../../utils/constants";
import Button from "../../Components/Button/Button";
// import CloseIcon from '@material-ui/icons/Close';
const stripePromise = loadStripe(CONSTANTS.STRIPE_KEY);

const PaymentModule = (props) => {
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isPaymentIntagreted, setPaymentIntagreted] = useState(false);
  // const [btnLoading, setBtnLoading] = useState(false);
  const [isCardExist, setIsCardExit] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardActive, setCardActive] = useState(false);
  const [cardExpiryMonth, setCardExpiryMonth] = useState()
  const [cardExpiryYear, setCardExpiryYear] = useState()
  const [activeContract, setActiveContract] = useState()

  const [key, setKey] = useState(0);
  const [showDeletePayment, setShowDeletePayment] = useState(false);

  useEffect(() => {
    getBanking();
  }, [showAddPayment, key]);

  const getBanking = () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    setLoader(true);
    APIHelper.getMethod(API.GET_STRIPE_ACCOUNT_DETAILS, headers)
      .then((res) => {
        if (res && res.isPaymentMethodConnect === "false") {
          setPaymentIntagreted(false);
        } else {
          setPaymentIntagreted(true);
        }
        if (res && res.card_token && res.card_number) {
          setIsCardExit(true);
          setCardActive(!res.card_expired);
          setCardNumber(res.card_number);
          setCardType(res.card_brand);
          setCardExpiryMonth(res.exp_month)
          setCardExpiryYear(res.exp_year)
        } else {
          setIsCardExit(false);
          setCardActive(false);
          setCardNumber("");
          setCardType("");
          setCardExpiryMonth();
          setCardExpiryYear();
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });

    if (localStorage.getItem("userType") === "EMPLOYER") {
      APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_METHOD, headers)
        .then((res) => {
          setActiveContract(res.result)
          // setCardActive(res.result);
        })
        .catch((error) => {
          setLoader(false);
        });
    } else {
      APIHelper.getMethod(API.GET_EMPLOYEE_PAYMENT_METHOD, headers)
        .then((res) => {
          setActiveContract(res.result)
          // setCardActive(res.result);
        })
        .catch((error) => {
          setLoader(false);
        });
    }
  };

  const removePayment = () => {
    setLoader(true);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.postMethod(API.STRIPE_REMOVE_CARD, {}, headers)
      .then((res) => {
        setLoader(false);
        setShowDeletePayment(false);
        setKey(Math.random());
        getBanking();
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  return (
    <>
      <Card
        style={{
          width: "100%",
          // height: '20vw',
          borderRadius: "1.041vw",
          margin: "auto",
          marginLeft: "auto",
          backgroundColor: "#F5F5F5",
          marginTop: 40,
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={PaymentCard}
              alt="payment-card"
              style={{
                height: 30,
                marginLeft: 6,
                // marginTop: 30,
              }}
            />
            <img
              src={DiscoverCard}
              alt="discover-card"
              style={{
                height: 47,
                position: "relative",
                top: 8,
              }}
            />
          </div>
          <Button
            text="Add Card"
            width={"140px"}
            disabled={isCardExist}
            height="48px"
            onClick={() => !isCardExist && setShowAddPayment(true)}
            color={["#2699fb", "#134d7e"]}
          />
          {/* <StripeConnect
                        setStripeConnetcID={(id) =>
                            this.setState({
                                setStripeConnetcID: id,
                            })
                        }
                        loader={loader}
                        setLoader={setLoader}
                        isPaymentIntagreted={isPaymentIntagreted}
                        setPaymentIntagreted={setPaymentIntagreted}
                        btnLoading={btnLoading}
                        setBtnLoading={setBtnLoading}
                    /> */}
        </div>
        {/* <div
                    style={{
                        display: 'flex',
                        height: 30,
                        alignItems: 'center',
                        marginTop: 32,
                        cursor: 'pointer',
                    }}
                    onClick={() => !isCardExist && setShowAddPayment(true)}
                >
                    <AddIcon style={{ color: !isCardExist ? '#1876D2' : '#C4C4C4' }} />
                    <p
                        style={{
                            fontSize: 18,
                            fontWeight: 500,
                            color: !isCardExist ? '#070707' : '#C4C4C4',
                        }}
                    >
                        Add a payment method
                    </p>
                </div> */}
        <div className="EditDetailsBottomLineStyles" style={{ marginTop: 20 }} />
        <div style={{ marginTop: 50, marginLeft: 6 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ fontSize: 18, fontWeight: 500, color: "#707070" }}>Current Payment method</p>
            <p style={{ fontSize: 18, fontWeight: 500, color: "#707070", width: 200 }}>Current Status</p>
          </div>
          <div style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }}>
            <p style={{ fontSize: 18, fontWeight: 500, color: "#707070" }}>
              Credit Card Ending in *****{cardNumber} {cardType}
            </p>
            <p style={{ fontSize: 18, fontWeight: 500, color: "#707070", width: 200 }}>
              {cardNumber && cardType && <>{cardActive ? <span>Active</span> : <span style={{ color: "red" }}>Expired</span>}</>}
            </p>
          </div>

          <div style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }}>
            <p style={{ fontSize: 18, fontWeight: 500, color: "#707070" }}>
              Expiry date:{cardExpiryMonth && cardExpiryYear && <>{" "}{String(cardExpiryMonth).length === 2 ? cardExpiryMonth : `0${cardExpiryMonth}`}/{String(cardExpiryYear).slice(-2)}</>}
            </p>
          </div>

          <div style={{ display: "flex", marginTop: 30, marginBottom: 15 }}>
            <Button
              text="Update"
              width={"140px"}
              disabled={!isCardExist}
              height="48px"
              onClick={() => isCardExist && setShowAddPayment(true)}
              color={["#2699fb", "#134d7e"]}
            />
            <div style={{ marginLeft: 20 }}>
              <Button
                text="Remove"
                width={"140px"}
                disabled={(!isCardExist && !cardActive) || activeContract}
                height="48px"
                onClick={() => isCardExist && cardActive && setShowDeletePayment(true)}
                color={["#2699fb", "#134d7e"]}
              />
            </div>

            {/* <p
                            style={{
                                marginLeft: 25,
                                marginRight: 20,
                                color: isCardExist ? '#070707' : '#C4C4C4',
                                cursor: 'pointer',
                            }}
                            onClick={() => isCardExist && setShowAddPayment(true)}
                        >
                            Update
                        </p> */}
            {/* <p
                            style={{
                                color: isCardExist && !cardActive ? '#070707' : '#C4C4C4',
                                cursor: 'pointer',
                            }}
                            onClick={() => isCardExist && !cardActive && removePayment()}
                        >
                            Remove
                        </p> */}
          </div>
        </div>
      </Card>
      {showAddPayment && (
        <div id="overlay">
          <div
            style={{
              width: "38vw",
              // height: '15vw',
              backgroundColor: "white",
              position: "absolute",
              borderRadius: "10px",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              padding: 20,
            }}
          >
            <Elements stripe={stripePromise}>
              <CheckoutForm closeModel={() => setShowAddPayment(false)} setKey={setKey} />
            </Elements>
          </div>
        </div>
      )}
      {showDeletePayment && (
        <div id="overlay">
          <div
            style={{
              width: "38vw",
              height: "15vw",
              backgroundColor: "white",
              position: "absolute",
              borderRadius: "10px",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              padding: 20,
              textAlign: "center",
            }}
          >
            <p style={{ marginTop: 24 }}>Are you sure you want to delete your Payment Method?</p>
            <div style={{ display: "flex", marginTop: 30, justifyContent: "center" }}>
              <div style={{ marginRight: 10 }}>
                <Button
                  text="No"
                  color={["#2699fb", "#134d7e"]}
                  height="56px"
                  width={"12vw"}
                  onClick={() => setShowDeletePayment(false)}
                />
              </div>
              <Button
                text="Yes"
                height="56px"
                width={"12vw"}
                variant="outlined"
                color={["#2699fb", "#134d7e"]}
                onClick={() => {
                  removePayment();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentModule;
