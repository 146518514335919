import {LOADING, ERROR,  SUCCESS} from '../action/location_action';
import { APIHelper } from '../utils/ApiHelper';
import API from '../utils/Urls'


export const getLocation = (location) => {

    return async dispatch => {
        try {

            dispatch({ type: LOADING })

            let headers = {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }

            APIHelper.getMethod(
              API.GET_LOCATION_SEARCH_BY_SEARCH_TERM + location, headers
            ).then((response) => {
              if (response.length > 0) {
                let locationOptions = response.map(item => {
                  return { lable: item.name, value: item.name, _id: item._id }
                })

                dispatch({ type: SUCCESS, data : locationOptions })
              }else{
                dispatch({ type: ERROR, errorMessage : "Location not found" })
              }
            })
          } catch(error) {
              dispatch({ type: ERROR , errorMessage : error})
          }
    }
  }
