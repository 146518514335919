import React from "react";
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
} from "recharts";
import Photo from "../../../Assets/DashboardIcon.svg";
import { CustomPieChart } from "./Overview";
import moment from 'moment';
const StatisticsReportPDFView = ({data,startDate, endDate, COLORS, COLORS2, renderCustomizedLabel, renderInternationalCustomizedLabel}) => {
    return (
        <div>
                <div >
                    <div className="section">
                        <div style={{ display: 'flex' }}>
                            <img src={Photo} alt="" />
                            <div style={{ marginLeft: 10 }}>
                                <p style={{ color: '#070707' }}>ABC Co.</p>
                                <p style={{ color: '#9a9a9a', fontSize: 12 }}>John Smith</p>
                            </div>
                        </div>
                        <div style={{ marginTop: 15, display: 'flex' }}>
                            <p style={{ color: '#9a9a9a', width: 150 }}>Report Period:</p>
                            <p>{moment(`${parseInt(startDate.split('-')[1])}-${parseInt(startDate.split('-')[0])}-01`).format('MMM DD, YY')} -
                         {endDate && moment(`${parseInt(endDate.split('-')[1])}-${parseInt(endDate.split('-')[0])}-01`).format('MMM DD, YY')}</p>
                        </div>
                        <div style={{ marginTop: 15, display: 'flex' }}>
                            <p style={{ color: '#9a9a9a', width: 150 }}>Report Date:</p>
                            <p>{moment().format('MMM DD, YY')}</p>
                        </div>
                        <div style={{ marginTop: 15, display: 'flex' }}>
                            <p style={{ color: '#9a9a9a', width: 150 }}>Job Value:</p>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignContent:'center' }}>
                    <div
                        
                        style={{
                            display: 'flex',
                            width: '550px',
                            padding: '20px 25px',
                            height: 250,
                        }}
                    >
                        <div style={{ width: '30%' }}>
                            <p
                                style={{
                                    color: '#9a9a9a',
                                    width: 150,
                                    fontWeight: 700,
                                }}
                            >
                                Local Jobs
                            </p>
                            <p
                                style={{
                                    color: '#070707',
                                    marginTop: 20,
                                    fontWeight: 500,
                                }}
                            >
                                {data && data.localJobsTotal} <br />
                                ({localStorage.getItem('localCurrency')})
                            </p>
                        </div>
                        <div style={{ width: '70%' }}>
                        <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={data && data.jobsPieChartData.local}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        fill="#8884d8"
                                        labelLine={false}
                                        animationDuration={0}
                                        label={renderCustomizedLabel}
                                        dataKey="value"
                                    >
                                        {data &&
                                            data.jobsPieChartData.local.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div
                        
                        style={{
                            display: 'flex',
                            width: '550px',
                            padding: '20px 25px',
                            height: 250,
                        }}
                    >
                        <div style={{ width: '30%' }}>
                            <p
                                style={{
                                    color: '#9a9a9a',
                                    width: 150,
                                    fontWeight: 700,
                                }}
                            >
                                International Jobs
                            </p>
                            <p
                                style={{
                                    color: '#070707',
                                    marginTop: 20,
                                    fontWeight: 500,
                                }}
                            >
                                ${data && data.internationalJobsTotal} (USD)
                            </p>
                        </div>
                        <div style={{ width: '70%' }}>
                            <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={data && data.jobsPieChartData.international}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={120}
                                            animationDuration={0}
                                            label={renderInternationalCustomizedLabel}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {data && data.jobsPieChartData.international.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS2[index % COLORS2.length]}
                                                />
                                            ))}
                                        </Pie>
                                    </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '43%',
                            padding: '20px 25px',
                            marginTop:'50px',
                            height: 350,
                        }}
                    >
                        <p
                            style={{
                                color: '#9a9a9a',
                                marginBottom: 40,
                                fontWeight: 700,
                                textAlign: 'center',
                            }}
                        >
                            Local Jobs({localStorage.getItem('localCurrency')})
                        </p>
                        <ResponsiveContainer width="99%" height={270}>
                            <BarChart
                                data={data && data.monthlyLocalJobs}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#621EEE" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div
                        
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '43%',
                            padding: '20px 25px',
                            height: 350,
                        }}
                    >
                        <p
                            style={{
                                color: '#9a9a9a',
                                marginBottom: 40,
                                fontWeight: 700,
                                textAlign: 'center',
                            }}
                        >
                            International Jobs(USD)
                        </p>
                        <ResponsiveContainer width="99%" height={270}>
                            <BarChart
                                data={data &&  data.monthlyInternationalJobs}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#DEB136" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div  style={{ padding: '20px 25px' }}>
                    <h3 style={{ color: '#070707', fontWeight: 'normal' }}>
                        Hourly Job Statistics
                    </h3>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection:'column'
                        }}
                    >
                        <div style={{ width: '38%', marginTop: 25 }}>
                            <p style={{ color: '#9a9a9a', fontWeight: 700 }}>
                                Total Hours (Hourly Jobs Only)
                            </p>
                            <div style={{ display: 'flex', margin: '10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '60%' }}>Local:</p>
                                <p className="value">{data && data.hourlyJobsStatisticsData.totalHoursLocal} hr</p>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '10px 0 50px 0',
                                }}
                            >
                                <p style={{ color: '#9a9a9a', width: '60%' }}>International:</p>
                                <p className="value">{data && data.hourlyJobsStatisticsData.totalHoursInternational} hr</p>
                            </div>
                            <div>
                                <p
                                    style={{
                                        color: '#9a9a9a',
                                        fontWeight: 700,
                                        marginTop: 40,
                                    }}
                                >
                                    Average Rate
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        Local:
                                    </p>
                                    <p className="value">{data && parseFloat(data.hourlyJobsStatisticsData.averageLocal).toFixed(2) || 0}</p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0 40px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        International:
                                    </p>
                                    <p className="value">{data && parseFloat(data.hourlyJobsStatisticsData.averageInternational).toFixed(2) || 0}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '60%' }}>
                            <p
                                style={{
                                    textAlign: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                Total Hours
                            </p>
                            <ResponsiveContainer width="100%" height={290}>
                                <BarChart
                                    data={data && data.hourlyJobsStatisticsData.totalHours}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Local" fill="#621EEE" />
                                    <Bar dataKey="International" fill="#CFB2F9" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '20px 25px', marginTop:'60px' }}>
                    <h3 style={{ color: '#070707', fontWeight: 'normal' }}>Workforce Statistics</h3>
                    <div
                        className="row"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection:'column'
                        }}
                    >
                        <div style={{ width: '38%', marginTop: 25 }}>
                            <p style={{ color: '#9a9a9a', fontWeight: 700 }}>
                                Total number of employees
                            </p>
                            <div style={{ display: 'flex', margin: '10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                <p className="value">{data && data.workforceStatistics.totalLocalEmployees}</p>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '10px 0 50px 0',
                                }}
                            >
                                <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                <p className="value">{data && data.workforceStatistics.totalInternationalEmployees}</p>
                            </div>
                        </div>
                        <div style={{ width: '60%' }}>
                            <p
                                style={{
                                    textAlign: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                Number of Employees
                            </p>
                            <ResponsiveContainer width="100%" height={290}>
                                <BarChart
                                    data={data && data.workforceStatistics.workforceBarChartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Local" fill="#621EEE" />
                                    <Bar dataKey="International" fill="#CFB2F9" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div  style={{ padding: '0 25px' }}>
                    <h3 style={{ color: '#070707', fontWeight: 'normal' }}>
                        Fixed Fee Job Statistics
                    </h3>
                    <div
                        className="row"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection:'column'
                        }}
                    >
                        <div style={{ width: '38%', marginTop: 25 }}>
                            <p style={{ color: '#9a9a9a', fontWeight: 800 }}>
                                Total number of fixed fee jobs
                            </p>
                            <div style={{ display: 'flex', margin: '10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                <p className="value">{data && data.fixedFeeJobsStatistics.totalFixedFeeJobs.local}</p>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '10px 0 10px 0',
                                }}
                            >
                                <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                <p className="value">{data && data.fixedFeeJobsStatistics.totalFixedFeeJobs.international}</p>
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <p
                                    style={{
                                        color: '#9a9a9a',
                                        fontWeight: 800,
                                    }}
                                >
                                    Total number of new fixed fee jobs
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        Local:
                                    </p>
                                    <p className="value">{data && data.fixedFeeJobsStatistics.totalNewFixedFeeJobs.local}</p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0 10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        International:
                                    </p>
                                    <p className="value">{data && data.fixedFeeJobsStatistics.totalNewFixedFeeJobs.international}</p>
                                </div>
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <p
                                    style={{
                                        color: '#9a9a9a',
                                        fontWeight: 800,
                                    }}
                                >
                                    Total number of ongoing fee jobs
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        Local:
                                    </p>
                                    <p className="value">{data && data.fixedFeeJobsStatistics.totalOngoingFixedFeeJobs.local}</p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0 10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        International:
                                    </p>
                                    <p className="value">{data && data.fixedFeeJobsStatistics.totalOngoingFixedFeeJobs.international}</p>
                                </div>
                            </div>
                            <div style={{ paddingTop: 10 }}>
                                <p
                                    style={{
                                        color: '#9a9a9a',
                                        fontWeight: 800,
                                    }}
                                >
                                    Total number of completed fee jobs
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        Local:
                                    </p>
                                    <p className="value">{data && data.fixedFeeJobsStatistics.totalCompletedFixedFeeJobs.local}</p>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        margin: '10px 0 10px 0',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: '#9a9a9a',
                                            width: '80%',
                                        }}
                                    >
                                        International:
                                    </p>
                                    <p className="value">{data && data.fixedFeeJobsStatistics.totalCompletedFixedFeeJobs.international}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '60%' }}>
                            <p
                                style={{
                                    textAlign: 'center',
                                    paddingBottom: 10,
                                }}
                            >
                                Number of Fixed Fee Jobs
                            </p>
                            <ResponsiveContainer width="100%" height={290}>
                                <BarChart
                                    data={data && data.fixedFeeJobsStatistics?.fixedFeeJobChartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Local" fill="#621EEE" />
                                    <Bar dataKey="International" fill="#CFB2F9" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default StatisticsReportPDFView;
