import React from 'react'
import './SwitchButton.scss'

const blueButtonLeftStyle = {
    backgroundColor: "#1e7bc9",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    color: "white",
    width: "50%"
}

const blueButtonRightStyle = {
    backgroundColor: "#1e7bc9",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    color: "white",
    width: "50%"
}

const SwitchButton = (props) => {
    return (
        <div style={{ borderRadius: props.borderRadius, width: props.width, height: props.height, display: "table" }} className="SwitchButtonWrapper">
            <div onClick={props.disabled ? null : props.onLeftClicked} style={props.isPrimarySelected ? blueButtonLeftStyle : null} className="SwitchTableCell">{props.primaryLabel}</div>
            <div onClick={props.disabled ? null : props.onRightClicked} style={props.isPrimarySelected ? null : blueButtonRightStyle} className="SwitchTableCell">{props.secondaryLabel}</div>
        </div>
    )
}

export default SwitchButton;