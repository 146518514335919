import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import DrawerComponent from '../../Components/Drawer/Drawer';
import UserDetail from './UserDetail';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import './UserAccountDetails.scss';
import EditDetailsComponent from '../../Components/EditDetailsComponent/EditDetailsComponent';
import Dropdown from '../../Components/DropdownComponent/Dropdown.component';
import Textfield from '../../Components/TextField/Textfield';
import Button from '../../Components/Button/Button';
import CONSTANTS from '../../utils/constants';
import API from '../../utils/Urls';
import { APIHelper } from '../../utils/ApiHelper';
import { getFieldOfWork } from '../../api/field_of_work';
import { getSpecialityList } from '../../api/speciality_list';
import CheckboxList from '../../Components/CheckboxList/CheckboxList';
import CheckboxComponent from '../../Components/CheckboxComponent/CheckboxComponent';
import Basic from '../../Components/FileDropComponent/FileDropComponent';
import FileUploadForTextField from '../../Components/FileUploadForTextField/FileUploadForTextField';
import { cardType, S3_BUCKET_TYPE } from '../../utils/enums';
import {
    notificationSettings,
    verificationLevel,
    identificationLevel,
} from './UserAccountDetails.constant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from 'react-otp-input';
import MessageCard from '../../Components/MessageCard/MessageCard';
import axios from 'axios';
import queryString from 'query-string';
import { config } from '../../Components/FileUpload/FileUpload';
import { getFileName } from '../../utils/commonFunction';
import ReactS3 from 'react-s3';
import StripeConnect from '../../Components/StripeConnect/StripeConnect';
import CreatePayment from './CreatePayment';
import PaymentModule from '../../Components/StripeConnect/PaymentModule';
import BankingModule from '../../Components/StripeConnect/BankingModule';

const selectedHeadingstyles = {
    marginTop: '2.16vw',
    fontSize: '1.25vw',
    fontWeight: '600',
    letterSpacing: '0.96px',
    color: '#1c1c1c',
    backgroundColor: 'rgba(24, 118, 210, 0.17)',
};

class UserAccountDetails extends Component {
    constructor(props) {
        super(props);
        this.uploadDoc = React.createRef();
        this.state = {
            page: localStorage.getItem('card_expired')==="true"?5:0,
            paymentPopup:false,
            employerId: '',
            companyName: '',
            firstName: '',
            lastName: '',
            empolinkID: '',
            otpError: false,
            otp: '',
            address: {
                city: '',
                country: '',
                state: '',
                unit_number: '',
                postal_code: '',
                street_address: '',
            },
            mobileNumber: '',
            selectedLanguages: [],
            isLanguageLoading: false,
            isAddressLoading: false,
            languageOptions: [],
            countryOptions: [],
            stateOptions: [],
            cityOptions: [],
            selectedCountry: {},
            selectedState: {},
            selectedCity: {},
            pinCode: '',
            streetAddress: '',
            emailAddress: '',
            password: '',
            isEmailAddressEditable: false,
            isPasswordEditable: false,
            isFieldOfWorkEditable: false,
            fieldOfWork: {},
            specialitiesList: [],
            drivingLicenceNumber: '',
            identificationType: '',
            document: '',
            notificationsArr: [],
            numberOfEmployees: '',
            headQuarterLocation: '',
            foundedYear: '',
            companyWeb: '',
            linkedIn: '',
            companyHighlight: '',
            companyFieldOfWork: {},
            companySpeciality: [],
            companyPortFolio: {},
            bussinessRegistrationNo: '',
            companyVerificationKey: '',
            bussinessRegistrationDocumentName: '',
            bussinessRegistrationDocumentLocation: '',
            logoUploadName: '',
            logoUploadLocation: '',
            filesUploaded: [],
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            isCardDisplayed: false,
            typeCardToDisplay: cardType.UPDATE_PASSWORD,
            passwordDoesNotMatch: false,
            cardTitle: '',
            cardNumber: 0,
            drawerOpen: false,
            newEmail: '',
            isAccountInfoEditable: false,
            isEmailExist: false,
            documentFileName: '',
            isMobileNumberConfirmed: false,
            atLeastSixCharPassword: true,
            isValidCurrentPassword: true,
            isValidnewPassword: true,
            isValidConfirmPassword: true,
            setStripeConnetcID: '',
            newNotifications:[]
        };
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        if (params && Object.keys(params).length > 0 && params['payment']) {
            this.setState({
                page: 5,
            });
        }
        this.getEmployeeDetails();
        this.props.getFieldOfWork();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.getPreviousData();
        }
    }

    getPreviousData = async () => {
        await this.getEmployeeDetails();
        this.props.getFieldOfWork();
    };

    getEmployeeDetails = async () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(API.GET_EMPLOYEE_PROFILE, headers).then((response) => {
            const { employerModel, employer } = response;
            this.setState({
                employerId: employerModel._id,
                empolinkID: employerModel.empolinkID,
                firstName: employerModel?.firstName,
                lastName: employerModel.lastName,
                address: employer.address && employer.address[0],
                mobileNumber: employerModel.mobile_number,
                selectedLanguages: employer.languages,
                emailAddress: employerModel.email,
                fieldOfWork: employer.field &&
                    employer.field.length > 0 && {
                        value: employer.field[0].id,
                        lable: employer.field[0].name,
                        _id: employer.field[0]._id,
                    },
                specialitiesList: employer.speciality, // TODO ( There is nothing any specialities )
                password: '', // TODO for what should we do for encrypt password.
                companyName: employer && employer.company && employer.company.name,
                isMobileNumberConfirmed: employerModel.isMobileNumberConfirmed,
                // ID Verification is remaining
                // Notification is also remaining
            });

            if (employer.field && employer.field.length > 0) {
                this.props.getSpecialityList({
                    _id: employer.field[0]._id || employer.field[0].id,
                    lable: employer.field[0].name,
                });
            }

            // APIHelper.getMethod(
            //     `${API.GET_COMPANY_DETAILS}/${employer.userid}`, headers //TODO for API query params
            // ).then((response) => {
            //     if (response.field) {
            //         this.props.getSpecialityList({ _id: response.field._id, lable: response.field.name });
            //     };

            //     this.setState({
            //         companyName: response.name,
            //         numberOfEmployees: response.number_of_employee,
            //         headQuarterLocation: response.head_location,
            //         foundedYear: response.founded_year,
            //         companyWeb: response.website,
            //         linkedIn: response.linkedin,
            //         companyHighlight: response.highlights,
            //         // companyFieldOfWork: response.field && { value: response.field._id, lable: response.field.name },
            //         companySpeciality: response.speciality,
            //         logoUploadLocation: response.logo,
            //     });
            // });
        });

        APIHelper.getMethod(API.GET_NOTIFICATION, headers).then((response) => {
            if (response.notification) {
                this.setState({
                    notificationsArr: response.notification,
                    user_preference:response.user_preference
                });
            }
        });
    };

    onEditLanguages = () => {
        this.setState({ isLanguageLoading: true });
        APIHelper.getMethod(API.GET_LANGUAGES).then((response) => {
            if (response['status'] === 1) {
                let languages = response.data.map(function (row) {
                    return { value: row.name, label: row.name, id: row._id };
                });
                this.setState({
                    languageOptions: languages,
                    isLanguageLoading: false,
                    isLanguageEditable: !this.state.isLanguageEditable,
                });
            }
        });
    };

    onEditAddress = () => {
        this.setState({ isAddressLoading: true });

        APIHelper.getMethod(API.GET_COUNTRY).then((response) => {
            if (response['status'] === 1) {
                const countries = response.data.map(function (row) {
                    return {
                        value: row.name,
                        label: row.name,
                        id: row._id,
                        Id: row.country_id,
                    };
                });
                this.setState({
                    countryOptions: countries,
                    isAddressLoading: false,
                    isBusinessAddressEditable: !this.state.isBusinessAddressEditable,
                });
            }
        });
    };

    onEditFieldOfWork = () => {
        this.setState((state) => ({
            isFieldOfWorkEditable: !state.isFieldOfWorkEditable,
        }));
    };

    decideCardToDisplay = (card) => {
        switch (card) {
            case cardType.UPDATE_PASSWORD:
                this.setState({
                    cardTitle: 'Update password',
                    cardNumber: 0,
                });
                break;

            case cardType.UPDATE_EMAIL:
                this.setState({
                    cardTitle: 'Update Email',
                    cardNumber: 1,
                });
                break;

            case cardType.VERIFY_EMAIL:
                this.setState({
                    cardTitle:
                        'You haven’t verified your email yet, please enter verification code',
                    cardNumber: 2,
                });
                break;

            case cardType.UPDATE_MOBILE:
                this.setState({
                    cardTitle: 'Verify Mobile Number',
                    cardNumber: 3,
                });
                break;

            case cardType.VERIFY_MOBILE:
                this.setState({
                    cardTitle: 'Mobile Number Verification',
                    cardNumber: 4,
                });
                break;

            default:
            // code block
        }
    };

    handleCheckboxList = (checkedItems) => {
        const { dataSpecialityDropdown } = this.props;
        var intersection =
            dataSpecialityDropdown &&
            dataSpecialityDropdown.filter(function (e) {
                return checkedItems.indexOf(e.lable) > -1;
            });
        let selectedValue = intersection.map(function (row) {
            return { name: row.lable, id: row._id };
        });
        this.setState({
            specialitiesList: selectedValue,
        });
    };

    handleCompanySpecialityCheckboxList = (checkedItems) => {
        const { dataSpecialityDropdown } = this.props;
        var intersection =
            dataSpecialityDropdown &&
            dataSpecialityDropdown.filter(function (e) {
                return checkedItems.indexOf(e.lable) > -1;
            });
        let selectedValue = intersection.map(function (row) {
            return { name: row.lable, id: row._id };
        });
        this.setState({
            companySpeciality: selectedValue,
        });
    };

    handleFileUploadChange = (e, name) => {
        let files = this.state.filesUploaded;

        files.push({ name: name, url: e });
        this.setState({ filesUploaded: files });
    };

    changeCountry = (id) => {
        APIHelper.getMethod(API.GET_STATES + `?country_id=${id}`).then((response) => {
            if (response['status'] === 1) {
                const states = response.data.map((state) => ({
                    ...state,
                    label: state.name,
                    value: state.name,
                }));
                this.setState({
                    stateOptions: states,
                });
            }
        });
    };

    changeState = (id) => {
        APIHelper.getMethod(API.GET_CITIES + `?state_id=${id}`).then((response) => {
            if (response['status'] === 1) {
                const cities = response.data.map((city) => ({
                    ...city,
                    label: city.name,
                    value: city.name,
                }));
                this.setState({
                    cityOptions: cities,
                });
            }
        });
    };

    handleUpdatePassword = () => {
        if (
            this.state.newPassword !== '' &&
            this.state.confirmPassword !== '' &&
            this.state.currentPassword !== ''
        ) {
            if (Number(this.state.newPassword.length) >= 6) {
                this.setState({ atLeastSixCharPassword: true });
                if (this.state.newPassword !== this.state.confirmPassword) {
                    this.setState({ passwordDoesNotMatch: true });
                } else {
                    let body = {
                        email: localStorage.getItem('username'),
                        new_password: this.state.newPassword,
                        old_password: this.state.currentPassword,
                    };

                    let headers = {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    };

                    APIHelper.postMethod(API.CHANGE_PASSWORD, body, headers)
                        .then((response) => {
                            if (response.status === 1) {
                                this.setState({
                                    isCardDisplayed: false,
                                });
                                Swal.fire({
                                    customClass: 'Alert-Class',
                                    title: 'Information!',
                                    text: response.message,
                                    icon:
                                        response.message === 'Password Successfully updated'
                                            ? 'success'
                                            : 'error',
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: '#1876D2',
                                }).then((res) => {
                                    if (response.message === 'Password Successfully updated') {
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('hourlyRateMin');
                                        localStorage.removeItem('hourlyRateMax');
                                        localStorage.removeItem('talentSearchData');
                                        localStorage.removeItem('calendarDates');
                                        localStorage.removeItem('userId');
                                        localStorage.removeItem('fieldData');
                                        window.location = '/login';
                                    }
                                });
                            }
                        })
                        .catch((err) => {
                            Swal.fire({
                                customClass: 'Alert-Class',
                                title: 'Information!',
                                text: 'Failed',
                                icon: 'error',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#1876D2',
                            });
                        });
                }
            } else {
                this.setState({ atLeastSixCharPassword: false });
            }
        } else {
            this.setState({
                isValidCurrentPassword: false,
                isValidnewPassword: false,
                isValidConfirmPassword: false,
            });
        }
    };

    handleUpdateEmail = () => {
        const params = new URLSearchParams();
        params.append('email', this.state.newEmail);
        let headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        };

        APIHelper.postMethod(API.CHECK_EMAIL, params, headers)
            .then((response) => {
                if (response.data.isEmailExist) {
                    this.setState({ isEmailExist: true });
                } else {
                    let tokenHeader = {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    };
                    const paramsPost = new URLSearchParams();
                    paramsPost.append('newEmail', this.state.newEmail);
                    paramsPost.append('currentEmail', this.state.emailAddress);
                    APIHelper.postMethod(API.UPDATE_EMAIL, paramsPost, tokenHeader)
                        .then((response) => {
                            if (response.status === 1) {
                                Swal.fire({
                                    customClass: 'Alert-Class',
                                    title: 'Information!',
                                    text: response.message,
                                    icon: 'success',
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: '#1876D2',
                                });
                                this.decideCardToDisplay(cardType.VERIFY_EMAIL);
                                this.setState({
                                    isCardDisplayed: true,
                                });
                            } else {
                                Swal.fire({
                                    customClass: 'Alert-Class',
                                    title: 'Error!',
                                    text: response.message,
                                    icon: 'error',
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: '#1876D2',
                                });
                            }
                        })
                        .catch((err) => {
                            // this.setState({ isEmailExist: false });
                        });
                    this.setState({ isEmailExist: false });
                }
            })
            .catch((err) => {
                this.setState({ isEmailExist: false });
            });
    };

    handleUpdateMobileNumber = () => {
        const payload = {
            mobileNumber: this.state.mobileNumber,
            userId: localStorage.getItem('userId'),
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(API.UPDATE_MOBILE, payload, headers)
            .then((response) => {
                if (response.status === 1) {
                    this.decideCardToDisplay(cardType.VERIFY_MOBILE);
                    // this.setState({
                    //     isCardDisplayed: false
                    // })
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Error!',
                        text: response.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    handleVerifyMobileNumber = () => {
        const payload = {
            otp: this.state.otp,
            userId: localStorage.getItem('userId'),
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(API.VERIFY_MOBILE, payload, headers)
            .then((response) => {
                if (response.status === 1) {
                    this.saveProfile();
                    this.setState({
                        isCardDisplayed: false,
                    });
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                } else {
                    this.setState({
                        otpError: true,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    otpError: true,
                });
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    handleVerifyEmail = () => {
        const payload = {
            otp: this.state.otp,
            email: this.state.newEmail,
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(API.VERIFY_OTP, payload, headers)
            .then((response) => {
                if (response.status === 1) {
                    this.saveProfile();
                    this.setState({
                        isCardDisplayed: false,
                    });
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                } else {
                    this.setState({
                        otpError: true,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    otpError: true,
                });
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    resendOtp = async () => {
        axios
            .post(API.RESEND_VERIFY_OTP, {
                email: this.state.newEmail,
            })
            .then((response) => {
                if (response.status === 1) {
                    alert('OTP resend');
                }
            })
            .catch((err) => console.log(err));
    };

    updateEmail = () => {
        return (
            <div style={{ width: '100%', textAlign: 'center', height: '20vw', overflow: 'auto' }}>
                <div
                    style={{
                        width: '50%',
                        marginLeft: '25%',
                        marginTop: '58px',
                        marginBottom: '18px',
                    }}
                >
                    <Textfield
                        placeholder={'Current Email'}
                        width={'100%'}
                        value={this.state.emailAddress}
                        disabled
                    />
                </div>
                <div style={{ width: '50%', marginLeft: '25%', marginBottom: '18px' }}>
                    <Textfield
                        placeholder={'New Email'}
                        width={'100%'}
                        value={this.state.newEmail}
                        onChange={(event) => this.setState({ newEmail: event.target.value })}
                    />
                </div>
                <div
                    style={{
                        fontFamily: 'Libre Franklin',
                        fontSize: '18px',
                        color: '#707070',
                        width: '50%',
                        marginLeft: '25%',
                    }}
                >
                    You will be logged out from your account until you have verified your new email.
                </div>
                <div style={{ marginTop: '49px', marginBottom: '60px' }}>
                    {this.state.isEmailExist && (
                        <div className="errorMsg" style={{ marginBottom: 10 }}>
                            New email already exists.{' '}
                        </div>
                    )}
                    <Button
                        text="Verify Email"
                        width={'50%'}
                        height="56px"
                        onClick={() => this.handleUpdateEmail()}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
            </div>
        );
    };

    updateMobile = () => {
        return (
            <div style={{ width: '100%', textAlign: 'center', height: '20vw', overflow: 'auto' }}>
                <div
                    style={{
                        width: '50%',
                        marginLeft: '25%',
                        marginBottom: '18px',
                        marginTop: '127px',
                    }}
                >
                    <PhoneInput
                        // country={'in'}
                        specialLabel={''}
                        value={this.state.mobileNumber}
                        onChange={(phone) => this.setState({ mobileNumber: phone })}
                    />
                </div>
                <div style={{ marginTop: '53px', marginBottom: '127px' }}>
                    <Button
                        text="Verify Mobile Number"
                        width={'50%'}
                        height="56px"
                        onClick={() => this.handleUpdateMobileNumber()}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
            </div>
        );
    };

    verifyMobile = (isMobile) => {
        return (
            <div style={{ width: '100%', textAlign: 'center', height: '20vw', overflow: 'auto' }}>
                <OtpInput
                    value={this.state.otp}
                    onChange={(e) => {
                        this.setState({
                            otp: e,
                            otpError: false,
                        });
                    }}
                    containerStyle={{
                        width: '100%',
                        marginTop: '85px',
                        justifyContent: 'space-around',
                    }}
                    inputStyle={{
                        width: '82px',
                        fontSize: '22px',
                        height: '100px',
                        borderRadius: '10px',
                        justifyContent: 'space-around',
                        border: `1px ${this.state.otpError ? 'red' : '#707070'} solid`,
                    }}
                    numInputs={4}
                />
                {this.state.otpError ? (
                    <div className="errorMsg">The otp you entered does not match!</div>
                ) : null}
                <div style={{ paddingTop: '37px' }}>
                    <Button
                        text="Confirm"
                        width={'458px'}
                        height="56px"
                        onClick={() =>
                            isMobile ? this.handleVerifyMobileNumber() : this.handleVerifyEmail()
                        }
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
                <div className="verifyEmailFooterWrapper">
                    <span className="verifyEmailHeading">
                        {isMobile ? 'Haven’t received the code?' : 'Haven’t received the email?'}{' '}
                        <span
                            className="resendOtp"
                            onClick={() =>
                                isMobile ? this.handleUpdateMobileNumber() : this.resendOtp()
                            }
                            style={{ color: '#1d78c6', textDecoration: 'underline' }}
                        >
                            Resend Code.
                        </span>
                    </span>
                </div>
            </div>
        );
    };

    updatePasswordCard = () => {
        return (
            <div style={{ height: '19.5vw', overflow: 'auto' }}>
                <div style={{ marginTop: '39.5px', marginBottom: '30px' }}>
                    <Textfield
                        placeholder={'Current password'}
                        value={this.state.currentPassword}
                        type={this.state.showCurrentPassword ? 'text' : 'password'}
                        onChange={(event) =>
                            this.setState({
                                currentPassword: event.target.value,
                                isValidCurrentPassword: true,
                            })
                        }
                        error={!this.state.isValidCurrentPassword}
                        width={'50%'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            this.setState({
                                                showCurrentPassword:
                                                    !this.state.showCurrentPassword,
                                            })
                                        }
                                        edge="end"
                                    >
                                        {this.state.showCurrentPassword ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div style={{ float: 'left', marginLeft: '25%' }}>
                    Must include at least 6 characters.
                </div>
                <div style={{ marginBottom: '18px' }}>
                    <Textfield
                        placeholder={'New password'}
                        value={this.state.newPassword}
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        onChange={(event) =>
                            this.setState({
                                newPassword: event.target.value,
                                isValidnewPassword: true,
                                passwordDoesNotMatch: false,
                            })
                        }
                        width={'50%'}
                        error={
                            this.state.passwordDoesNotMatch ||
                            !this.state.isValidnewPassword ||
                            !this.state.atLeastSixCharPassword
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            this.setState({
                                                showNewPassword: !this.state.showNewPassword,
                                                passwordDoesNotMatch: false,
                                            })
                                        }
                                        edge="end"
                                    >
                                        {this.state.showNewPassword ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div>
                    <Textfield
                        placeholder={'Re-enter new password'}
                        value={this.state.confirmPassword}
                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                        onChange={(event) =>
                            this.setState({
                                confirmPassword: event.target.value,
                                isValidConfirmPassword: true,
                                passwordDoesNotMatch: false,
                            })
                        }
                        width={'50%'}
                        error={
                            this.state.passwordDoesNotMatch || !this.state.isValidConfirmPassword
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            this.setState({
                                                showConfirmPassword:
                                                    !this.state.showConfirmPassword,
                                                passwordDoesNotMatch: false,
                                            })
                                        }
                                        edge="end"
                                    >
                                        {this.state.showConfirmPassword ? (
                                            <VisibilityOutlinedIcon />
                                        ) : (
                                            <VisibilityOffOutlinedIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                {this.state.passwordDoesNotMatch ? (
                    <div className="errorMsg">Passwords does not match.</div>
                ) : null}
                <div style={{ marginTop: '36px' }}>
                    <Button
                        text="Update Password"
                        width={'50%'}
                        height="56px"
                        onClick={() => {
                            this.handleUpdatePassword();
                        }}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
            </div>
        );
    };

    saveProfile = () => {
        const {
            employerId,
            firstName,
            lastName,
            emailAddress,
            mobileNumber,
            empolinkID,
            specialitiesList,
            selectedLanguages,
            companyName,
            address,
            fieldOfWork,
            // dataFieldOfWorkDropdown
        } = this.state;

        var fieldWork;
        if (fieldOfWork.value && fieldOfWork._id) {
            fieldWork = { id: fieldOfWork._id, name: fieldOfWork.lable, _id: fieldOfWork._id };
        } else {
            const fieldFindData = this.props.dataFieldOfWorkDropdown.filter(
                (item) => item.lable === fieldOfWork.lable
            );
            if (fieldFindData.length > 0) {
                fieldWork = {
                    id: fieldFindData[0]._id,
                    name: fieldFindData[0].lable,
                    _id: fieldFindData[0]._id,
                };
            }
        }
        const request = {
            employerModel: {
                _id: employerId,
                empolinkID: empolinkID,
                firstName,
                lastName,
                email: emailAddress,
                mobile_number: mobileNumber,
            },
            employer: {
                speciality: specialitiesList,
                languages: selectedLanguages,
                address: [
                    {
                        unit_number: address.unit_number,
                        street_address: address.street_address,
                        city: address.city,
                        state: address.state,
                        country: address.country,
                        postal_code: address.postal_code,
                    },
                ],
                field: [fieldWork],
                company: { _id: '000000000000000000000000', name: companyName },
            },
        };

        if (
            firstName !== '' &&
            lastName !== '' &&
            companyName !== '' &&
            firstName.match('^[a-zA-Z ]*$') !== null &&
            lastName.match('^[a-zA-Z ]*$')
        ) {
            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            APIHelper.putMethod(API.EDIT_EMPLOYER, request, headers).then((response) => {
                localStorage.setItem('fieldData', JSON.stringify([fieldWork]));
            });
            this.setState({ isAccountInfoEditable: false });
        } else {
            this.setState({ error: { firstName: true, lastName: true, companyName: true } });
        }
    };

    saveNotification = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const request = {
            user_preference: this.state.newNotifications,
        };

        APIHelper.putMethod(API.EDIT_NOTIFICATION, request, headers).then((response) => {
            Swal.fire({
                customClass: 'Alert-Class',
                title: 'Success!',
                text: 'Notification setting saved successfully!',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1876D2',
            });
        });
    };

    verifyMyID = () => {
        const payload = {
            id_verification: {
                legal_id_type: this.state.identificationType,
                legal_id: this.state.drivingLicenceNumber,
                file_url: this.state.document,
                is_id_verified: false,
            },
            user_id: localStorage.getItem('userId'),
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.putMethod(API.EDIT_IDENTITY, payload, headers)
            .then((response) => {
                console.log('response', response);
                if (response.ok === 1) {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Error!',
                        text: response.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                }
            })
            .catch((err) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    uploadDocument = (event) => {
        const configS3 = {
            ...config,
            dirName: `${S3_BUCKET_TYPE.DOCUMENTS}/profile`,
        };
        const fileNameGenerate = getFileName(event.target.files[0].name);
        const file = event.target.files[0];
        this.setState({
            documentFileName: file.name,
        });

        Object.defineProperty(file, 'name', {
            writable: true,
            value: fileNameGenerate,
        });

        ReactS3.uploadFile(file, configS3)
            .then((data) => {
                console.log(data);
                // props.setFile(data.location);
                this.setState(
                    {
                        document: data.location,
                    },
                    () => {
                        this.saveProfile();
                    }
                );
            })
            .catch((err) => {
                console.log(err);
            });
        console.log('photo');
    };

    saveComapny = () => {
        const request = {
            name: this.state.companyName,
            number_of_employee: this.state.numberOfEmployees,
            head_location: this.state.headQuarterLocation,
            founded_year: this.state.foundedYear,
            website: this.state.companyWeb,
            linkedin: this.state.linkedIn,
            logo: this.state.logoUploadLocation,
            highlights: this.state.companyHighlight,
            field: this.state.companyFieldOfWork && this.state.companyFieldOfWork.value,
            speciality: this.state.companySpeciality.map((spec) => spec.id),
            createdBy: '6110afe27d174819c41dbccf',
            id_verification: {
                //TODO for id verification
                legal_id_type: 'DL',
                legal_id: '54353534',
                file_url: '34543543',
            },
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(API.ADD_COMPANY, request, headers).then((response) => {
            // TODO for toast message
        });
    };

    accountInformationDetails = () => {
        const contentField = (
            <>
                <div style={{ width: '100%', marginTop: '63px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">
                            {CONSTANTS.COMPANY_BUSSINESS_NAME_LABEL}
                        </div>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.companyName}
                            onChange={(event) => this.setState({ companyName: event.target.value })}
                            error={
                                this.state.error?.companyName && !(this.state.companyName !== '')
                            }
                        />
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: 28 }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">{CONSTANTS.FIRST_NAME_LABEL}</div>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.firstName}
                            onChange={(event) => this.setState({ firstName: event.target.value })}
                            error={
                                (this.state.error?.firstName && !(this.state.firstName !== '')) ||
                                !this.state.firstName.match('^[a-zA-Z ]*$')
                            }
                        />
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: 28 }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">{CONSTANTS.LAST_NAME_LABEL}</div>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.lastName}
                            onChange={(event) => this.setState({ lastName: event.target.value })}
                            error={
                                (this.state.error?.lastName && !(this.state.lastName !== '')) ||
                                !this.state.lastName.match('^[a-zA-Z ]*$')
                            }
                        />
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: 28 }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">
                            {CONSTANTS.BUSSINESS_ADDRESS_LABEL}
                        </div>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        <div>
                            <Dropdown
                                placeholder={'Country *'}
                                options={this.state.countryOptions}
                                disabledDropdownIndicator={false}
                                value={
                                    this.state.address && {
                                        label: this.state.address.country,
                                        value: this.state.address.country,
                                    }
                                }
                                onChange={(event) => {
                                    this.changeCountry(event.Id);
                                    const address = this.state.address;
                                    address.country = event.value;
                                    address.state = undefined;
                                    address.city = undefined;
                                    this.setState({ address });
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Dropdown
                                placeholder={'State/Province *'}
                                options={this.state.stateOptions}
                                value={
                                    this.state.address && {
                                        label: this.state.address.state,
                                        value: this.state.address.state,
                                    }
                                }
                                disabledDropdownIndicator={false}
                                onChange={(event) => {
                                    this.changeState(event.state_id);
                                    const address = this.state.address;
                                    address.state = event.value;
                                    address.city = undefined;
                                    this.setState({ address });
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Dropdown
                                placeholder={'City *'}
                                options={this.state.cityOptions}
                                disabledDropdownIndicator={false}
                                value={
                                    this.state.address && {
                                        label: this.state.address.city,
                                        value: this.state.address.city,
                                    }
                                }
                                onChange={(event) => {
                                    const address = this.state.address;
                                    address.city = event.value;
                                    this.setState({ address });
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Textfield
                                width={'100%'}
                                placeholder="Street Address *"
                                value={this.state.address && this.state.address.street_address}
                                onChange={(event) => {
                                    const address = this.state.address;
                                    address.street_address = event.target.value;
                                    this.setState({ address });
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Textfield
                                width={'100%'}
                                placeholder="Unit Number"
                                value={this.state.address && this.state.address.unit_number}
                                onChange={(event) => {
                                    const address = this.state.address;
                                    address.unit_number = event.target.value;
                                    this.setState({ address });
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Textfield
                                width={'100%'}
                                placeholder="Zip/Postal Code *"
                                value={this.state.address && this.state.address.postal_code}
                                onChange={(event) => {
                                    const address = this.state.address;
                                    address.postal_code = event.target.value;
                                    this.setState({ address });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', marginTop: 28 }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading"> {CONSTANTS.LANGUAGES_LABEL}</div>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        <Dropdown
                            placeholder={'Language(s)'}
                            isMulti
                            value={this.state.selectedLanguages.map((lang) => {
                                return { label: lang, value: lang };
                            })}
                            options={this.state.languageOptions}
                            disabledDropdownIndicator={true}
                            onChange={(event) => {
                                const data = event.map((lang) => lang.value);
                                this.setState({
                                    selectedLanguages: data,
                                });
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <Button
                        text="Save"
                        width={'120px'}
                        height="48px"
                        onClick={() => {
                            this.saveProfile();
                        }}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
                <div
                    className="EditDetailsBottomLineStyles"
                    style={{ marginBottom: 60, marginTop: 65 }}
                />
            </>
        );

        return (
            <div className="editDetails">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="editDetailsContentHeading">
                        {CONSTANTS.ACCOUNT_INFORMATION_LABEL}
                    </div>
                    {this.state.isAccountInfoEditable ? (
                        <CloseIcon
                            style={{ color: '#2699fb' }}
                            onClick={async () => {
                                await this.getPreviousData();
                                this.setState({
                                    isAccountInfoEditable: false,
                                });
                            }}
                        />
                    ) : (
                        <EditOutlinedIcon
                            style={{ color: '#2699fb' }}
                            onClick={() => {
                                this.onEditAddress();
                                this.onEditLanguages();
                                this.setState({
                                    isAccountInfoEditable: true,
                                });
                            }}
                        />
                    )}
                </div>
                <p style={{ marginTop: 10, color: '#9A9A9A', fontFamily: 'Libre Franklin' }}>
                {' '}
                </p>
                <br />
                <br />
                {this.state.isAccountInfoEditable ? (
                    contentField
                ) : (
                    <>
                        <div style={{ marginTop: '20px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    {' '}
                                    {CONSTANTS.COMPANY_BUSSINESS_NAME_LABEL}{' '}
                                </div>
                            </div>
                            <div className="EditDetailsCompDetailsStyles">
                                <div style={{ width: '100%' }}>{this.state.companyName}</div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                        </div>
                        <div style={{ marginTop: '35.5px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    {' '}
                                    {CONSTANTS.FIRST_NAME_LABEL}{' '}
                                </div>
                            </div>
                            <div className="EditDetailsCompDetailsStyles">
                                <div style={{ width: '100%' }}>{this.state.firstName}</div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                        </div>
                        <div style={{ marginTop: '35.5px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    {' '}
                                    {CONSTANTS.LAST_NAME_LABEL}{' '}
                                </div>
                            </div>
                            <div className="EditDetailsCompDetailsStyles">
                                <div style={{ width: '100%' }}>{this.state.lastName}</div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                        </div>
                        <div style={{ marginTop: '35.5px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    {' '}
                                    {CONSTANTS.BUSSINESS_ADDRESS_LABEL}{' '}
                                </div>
                            </div>
                            <div className="EditDetailsCompDetailsStyles">
                                <div style={{ width: '100%' }}>
                                    {`${
                                        this.state.address.unit_number &&
                                        this.state.address.unit_number + ','
                                    } ${
                                        this.state.address.street_address
                                            ? (this.state.address.street_address || '') + ','
                                            : ''
                                    } ${this.state.address.city}, ${this.state.address.state}, ${
                                        this.state.address.postal_code
                                    }, ${this.state.address.country}`}
                                </div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                        </div>
                        <div style={{ marginTop: '35.5px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    {' '}
                                    {CONSTANTS.LANGUAGES_LABEL}{' '}
                                </div>
                            </div>
                            <div className="EditDetailsCompDetailsStyles">
                                <div style={{ width: '100%' }}>
                                    {this.state.selectedLanguages.map((lang) => lang).join(', ')}
                                </div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                        </div>
                    </>
                )}
                <div style={{ marginTop: '35.5px', marginBottom: '85.5px' }}>
                    <EditDetailsComponent
                        showBorderBottom={false}
                        otherChangeType={
                            <div
                                style={{
                                    fontSize: '20px',
                                    color: '#1876d2',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    this.decideCardToDisplay(cardType.UPDATE_MOBILE);
                                    this.setState({
                                        isCardDisplayed: true,
                                    });
                                }}
                            >
                                {CONSTANTS.MOBILE_VERIFICATION_LABEL}
                            </div>
                        }
                        label={CONSTANTS.MOBILE_NUMBER_LABEL}
                        editFields={<div>Test Data</div>}
                        verifiedIcon={this.state.isMobileNumberConfirmed}
                        unverifiedIcon={!this.state.isMobileNumberConfirmed}
                        content={this.state.mobileNumber}
                    />
                </div>
            </div>
        );
    };

    passwordSecurityDetails = () => {
        const editEmailAddress = (
            <div style={{ marginTop: '20px' }}>
                <Textfield
                    width={'100%'}
                    value={this.state.emailAddress}
                    onChange={(event) => this.setState({ emailAddress: event.target.value })}
                />
                <div style={{ marginTop: '20px' }}>
                    <Button
                        text="Save"
                        width={'120px'}
                        height="48px"
                        onClick={() => {
                            this.saveProfile();
                            this.setState({ isEmailAddressEditable: false });
                        }}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
            </div>
        );

        const editPassword = (
            <div style={{ marginTop: '20px' }}>
                <Textfield
                    type="password"
                    width={'100%'}
                    value={this.state.password}
                    onChange={(event) => this.setState({ password: event.target.value })}
                />
                <div style={{ marginTop: '20px' }}>
                    <Button
                        text="Save"
                        width={'120px'}
                        height="48px"
                        onClick={() => this.setState({ isPasswordEditable: false })}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
            </div>
        );
        return (
            <div className="editDetails">
                <div className="editDetailsContentHeading">
                    {CONSTANTS.PASSWORD_AND_SECURITY_LABEL}
                </div>
                <div style={{ marginTop: '63px' }}>
                    <EditDetailsComponent
                        showBorderBottom={true}
                        label={CONSTANTS.EMAIL_ADDRESS}
                        editFields={editEmailAddress}
                        content={this.state.emailAddress}
                        // isEditButtonClicked={this.state.isEmailAddressEditable}
                        onEditClick={() => {
                            this.decideCardToDisplay(cardType.UPDATE_EMAIL);
                            this.setState({
                                isEmailAddressEditable: !this.state.isEmailAddressEditable,
                                isCardDisplayed: true,
                            });
                        }}
                    />
                </div>
                <div style={{ marginTop: '63px' }}>
                    <EditDetailsComponent
                        showBorderBottom={true}
                        label={CONSTANTS.PASSWORD}
                        editFields={editPassword}
                        content={this.state.password}
                        encrypt={true}
                        // isEditButtonClicked={this.state.isPasswordEditable}
                        onEditClick={() => {
                            this.decideCardToDisplay(cardType.UPDATE_PASSWORD);
                            this.setState({
                                isPasswordEditable: !this.state.isPasswordEditable,
                                isCardDisplayed: true,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    fieldSpecialityDetails = () => {
        const { fieldOfWork } = this.state;
        const { dataSpecialityDropdown, dataFieldOfWorkDropdown, getSpecialityList } = this.props;
        const editFieldOfWork = (
            <>
                <div style={{ width: '100%', marginTop: '63px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading"> {CONSTANTS.FIELD_OF_WORK}</div>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        <Dropdown
                            placeholder={'Field of Work *'}
                            options={dataFieldOfWorkDropdown}
                            disabledDropdownIndicator={false}
                            value={this.state.fieldOfWork}
                            onChange={(event) => {
                                this.setState({
                                    fieldOfWork: event,
                                    specialitiesList: [],
                                });
                                getSpecialityList(event);
                            }}
                            getOptionLabel={(option) => option.lable}
                            getOptionValue={(option) => option.value}
                        />
                        <div style={{ marginTop: '30px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    What specialities you usually look for? (Optional){' '}
                                </div>
                            </div>
                            {fieldOfWork && fieldOfWork.lable && (
                                <div style={{ marginTop: '20px' }}>
                                    <CheckboxList
                                        value={this.state.specialitiesList.map((spec) => spec.name)}
                                        options={
                                            dataSpecialityDropdown &&
                                            dataSpecialityDropdown.map(function (row) {
                                                return {
                                                    value: row.value,
                                                    label: row.lable,
                                                    id: row._id,
                                                };
                                            })
                                        }
                                        onNewSelected={(checked) =>
                                            this.handleCheckboxList(checked)
                                        }
                                        color="#2699fb"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '20px', marginBottom: '85.5px' }}>
                    <Button
                        text="Save"
                        width={'120px'}
                        height="48px"
                        onClick={() => {
                            this.saveProfile();
                            this.setState({ isFieldOfWorkEditable: false });
                        }}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
            </>
        );
        return (
            <div className="editDetails">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="editDetailsContentHeading">
                        {CONSTANTS.FIELD_AND_SPECIALITY_LABEL}
                    </div>
                    {this.state.isFieldOfWorkEditable ? (
                        <CloseIcon
                            style={{ color: '#2699fb' }}
                            onClick={async () => {
                                await this.getPreviousData();
                                this.setState({
                                    isFieldOfWorkEditable: false,
                                });
                            }}
                        />
                    ) : (
                        <EditOutlinedIcon
                            style={{ color: '#2699fb' }}
                            onClick={() =>
                                this.setState({
                                    isFieldOfWorkEditable: true,
                                })
                            }
                        />
                    )}
                </div>
                {this.state.isFieldOfWorkEditable ? (
                    editFieldOfWork
                ) : (
                    <div style={{ marginTop: '63px' }}>
                        <div className="EditDetailsCompHeadingStyles">
                            <div className="editDetailsCompHeading">
                                {' '}
                                {CONSTANTS.FIELD_OF_WORK}{' '}
                            </div>
                        </div>
                        <div className="EditDetailsCompDetailsStyles">
                            <div style={{ width: '100%' }}>
                                {this.state.fieldOfWork?.lable || ''}
                            </div>
                        </div>
                        <div className="EditDetailsBottomLineStyles" />
                        <div style={{ marginTop: '50px' }}>
                            <div className="EditDetailsCompHeadingStyles">
                                <div className="editDetailsCompHeading">
                                    What specialities you usually look for? (Optional){' '}
                                </div>
                            </div>
                            <div className="EditDetailsCompDetailsStyles">
                                {this.state.specialitiesList
                                    .map(function (item) {
                                        return item.name;
                                    })
                                    .join(', ')}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    idVerificationDetails = () => {
        const editVerficationLevel = (
            <div style={{ marginTop: '20px' }}>
                <p style={{ marginBottom: 15, fontSize: 20, color: '#1C1C1C' }}>
                    Verification Level
                </p>
                <Dropdown
                    placeholder={'Individual Verification'}
                    options={verificationLevel}
                    disabledDropdownIndicator={false}
                    onChange={(event) => {
                        // TODO
                        console.log('event', event);
                    }}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    isDisabled
                />
            </div>
        );

        const individualType = (
            <div style={{ marginTop: '30px' }}>
                <p style={{ marginBottom: 15, fontSize: 20, color: '#1C1C1C' }}>
                    Identification Type
                </p>
                <Dropdown
                    placeholder={"Driver's Licence/Passport"}
                    options={identificationLevel}
                    disabledDropdownIndicator={false}
                    onChange={(event) => {
                        this.setState({ identificationType: event.value });
                    }}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                />
                <div style={{ marginTop: 15, marginBottom: 20 }}>
                    <Textfield
                        placeholder="ID Number"
                        width={'100%'}
                        value={this.state.drivingLicenceNumber}
                        onChange={(event) =>
                            this.setState({ drivingLicenceNumber: event.target.value })
                        }
                    />
                </div>
            </div>
        );

        return (
            <div className="editDetails">
                <div className="editDetailsContentHeading">{CONSTANTS.ID_VERIFICATION_LABEL}</div>
                <div style={{ marginTop: 40 }}>
                    <p style={{ color: '#9A9A9A', fontSize: 20 }}>
                        You need to verify your ID before hiring.
                    </p>
                </div>
                <div style={{ marginTop: 40 }}>
                    {editVerficationLevel}
                    {individualType}
                    <div style={{ marginTop: '20px' }}>
                        <input
                            id="myProfile"
                            type="file"
                            ref={(ref) => (this.uploadDoc = ref)}
                            style={{ display: 'none' }}
                            onChange={this.uploadDocument}
                        />
                        <Button
                            text="Upload Doc"
                            width={'180px'}
                            height="48px"
                            onClick={() => this.uploadDoc.click()}
                            color={['#2699fb', '#134d7e']}
                        />
                    </div>
                </div>
                <div style={{ marginTop: 60, marginBottom: 50 }}>
                    <p style={{ fontFamily: 'Libre Franklin', fontSize: 19, color: '#1C1C1C' }}>
                        By clicking on verify my ID, you agree to terms and conditions of Empolink.
                        A small charge will apply for verification process.
                    </p>
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            text="Verify My ID"
                            width={'180px'}
                            height="48px"
                            onClick={this.verifyMyID}
                            color={['#2699fb', '#134d7e']}
                        />
                    </div>
                </div>
            </div>
        );
    };

    notificationSettingDetails = () => {
        this.state.newNotifications=[...this.state.notificationsArr];
        this.state.notificationsArr.map((i,index)=>   
        {    
            if(this.state.user_preference.filter((k)=>k.title===i.title).length>0)
            {
                this.state.newNotifications[index]=this.state.user_preference.filter((k)=>k.title===i.title)[0]
            }
            this.state.notificationsArr.length===index && 
        this.setState({
            newNotifications:this.state.newNotifications,
        })
    }        
)
        return (
            <div className="editDetails">
                <div className="editDetailsContentHeading">
                    {CONSTANTS.NOTIFICATION_SETTINGS_LABEL}
                </div>
                <div style={{ marginTop: 40 }}>
                    {/* TODO: Notification data is static or dynamic cominf from API */}
                    {this.state.newNotifications.map((notification, index) => (
                        <div key={notification.title}>
                            <div
                                style={{
                                    height: 20,
                                    display: 'flex',
                                    lineHeight: '20px',
                                    marginTop: 20,
                                }}
                            >
                                <p style={{ lineHeight: '40px', fontSize: 20, color: '#1C1C1C' }}>
                                    {notification.title}
                                </p>
                                <div
                                    style={{
                                        flex: 1,
                                        textAlign: 'right',
                                    }}
                                >
                                    <CheckboxComponent                                    
                                        checked={notification.isEmail}
                                        name={'Email'}
                                        label={'Email'}
                                        onChange={() => {
                                            const array = [...this.state.newNotifications];
                                            array[index].isEmail = !notification.isEmail;
                                            this.setState({
                                                newNotifications: array,    
                                            });
                                        }}
                                        color="#2699fb"
                                    />
                                    <CheckboxComponent
                                        checked={notification.isText}
                                        name={'Mobile'}
                                        label={'Mobile'}
                                        onChange={() => {
                                            const array = [...this.state.newNotifications];
                                            array[index].isText = !notification.isText;
                                            this.setState({
                                                newNotifications: array,
                                            });
                                        }}
                                        color="#2699fb"
                                    />
                                </div>
                            </div>
                            {index + 1 !== notificationSettings.length && (
                                <div className="EditDetailsBottomLineStyles" />
                            )}
                        </div>
                    ))}
                    <div style={{ marginTop: 50, marginBottom: 70 }}>
                        <Button
                            text="Save"
                            width={'140px'}
                            height="48px"
                            onClick={() => this.saveNotification()}
                            color={['#2699fb', '#134d7e']}
                        />
                    </div>
                </div>
            </div>
        );
    };

componentDidMount()
{
    if(localStorage.getItem('card_expired')==="true")
    {
        this.setState({
            paymentPopup:true
        })
    }
}
    paymentMethodDetails = () => {       
return (
<>
  {this.state.paymentPopup&&
    <div id="overlay">
        <div
            style={{
                width: '25vw',
                maxWidth: '600px',
                height: 'auto',
                backgroundColor: 'white',
                position: 'absolute',
                borderRadius: '10px',
                left: '58%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
                padding: '2em',
                textAlign: 'left',
                boxSizing: 'border-box',
            }}
        >
      
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
               <CloseIcon
                            style={{ color: '#2699fb' }}
                            onClick={() => {
                                this.setState({
                                    paymentPopup: false,
                                });
                            }}
                />
            </div>
            <p style={{ marginTop: '0.5em', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                Your payment method is either expired or not verified. Please update
                your payment information.
            </p>
           
        </div>
    </div>} 



            <div className="editDetails">
                <div className="editDetailsContentHeading">{CONSTANTS.PAYMENT_METHOD_LABEL}</div>
                <p style={{ marginTop: 10, color: '#9A9A9A', fontFamily: 'Libre Franklin' }}>
                    Your security is important to us. We do not store or have access to your credit
                    card information. We use Stripe to securely handle all payments . Stripe is a
                    Validated Level 1 PCI DSS Compliant Service Provider.
                </p>
                <PaymentModule />
            </div>
       </> 
       );
    };

    setUpCompanyPageDetails = () => {
        return (
            <div className="editDetails">
                <div className="editDetailsContentHeading">
                    {CONSTANTS.SET_UP_COMPANY_PAGE_LABEL}
                </div>
                <div style={{ marginTop: 40, marginBottom: 70 }}>
                    <Button
                        text="View my public profile"
                        height="48px"
                        onClick={() => this.setState({ drawerOpen: true })}
                        color={['#2699fb', '#134d7e']}
                    />
                </div>
                <DrawerComponent
                    open={this.state.drawerOpen}
                    onClose={() => this.setState({ drawerOpen: false })}
                    component={<UserDetail />}
                    width="90%"
                />
                <div className="EditDetailsCompHeadingStyles">
                    <div className="editDetailsCompHeading">
                        <b>Company Information</b>
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Company/Business Name</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.companyName}
                            onChange={(event) => this.setState({ companyName: event.target.value })}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Number of Employees</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.numberOfEmployees}
                            onChange={(event) =>
                                this.setState({ numberOfEmployees: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Headquarter Location</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.headQuarterLocation}
                            onChange={(event) =>
                                this.setState({ headQuarterLocation: event.target.value })
                            }
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Founded Year</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.foundedYear}
                            onChange={(event) => this.setState({ foundedYear: event.target.value })}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Company Web (optional)</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.companyWeb}
                            onChange={(event) => this.setState({ companyWeb: event.target.value })}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Linkedin (optional)</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.linkedIn}
                            onChange={(event) => this.setState({ linkedIn: event.target.value })}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Logo Upload (optional)</div>
                    </div>
                    <div style={{ marginTop: '16px', display: 'flex' }}>
                        <FileUploadForTextField
                            setFileName={(name) =>
                                this.setState({
                                    logoUploadName: name,
                                })
                            }
                            setFile={(location) =>
                                this.setState({
                                    logoUploadLocation: location,
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Company Highlights (optional)</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Textfield
                            width={'100%'}
                            value={this.state.companyHighlight}
                            onChange={(event) =>
                                this.setState({ companyHighlight: event.target.value })
                            }
                            rows={10}
                            multiline={true}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Field of Work</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Dropdown
                            placeholder={'Field of Work *'}
                            options={this.props.dataFieldOfWorkDropdown}
                            value={this.state.companyFieldOfWork}
                            disabledDropdownIndicator={false}
                            onChange={(event) => {
                                this.setState({
                                    companyFieldOfWork: event,
                                });
                                this.props.getSpecialityList(event);
                            }}
                            getOptionLabel={(option) => option.lable}
                            getOptionValue={(option) => option.value}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">
                            What specialities you usually look for? (Optional){' '}
                        </div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <CheckboxList
                            value={this.state.companySpeciality.map((speciality) => {
                                return speciality.name;
                            })}
                            options={
                                this.props.dataSpecialityDropdown &&
                                this.props.dataSpecialityDropdown.map(function (row) {
                                    return { value: row.value, label: row.lable, id: row._id };
                                })
                            }
                            onNewSelected={(checked) =>
                                this.handleCompanySpecialityCheckboxList(checked)
                            }
                            color="#2699fb"
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px', marginBottom: '50px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Portfolio</div>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Basic
                            setFile={(e, name) => this.handleFileUploadChange(e, name)}
                            uploaded={this.state.companyPortFolio}
                            withoutWidth
                        />
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div style={{ marginTop: 16 }}>
                        <Button
                            text="Save"
                            width={'160px'}
                            height="48px"
                            onClick={() => this.saveComapny()}
                            color={['#2699fb', '#134d7e']}
                        />
                    </div>
                </div>
                <div className="EditDetailsBottomLineStyles" />
                <div className="EditDetailsCompHeadingStyles" style={{ marginTop: 40 }}>
                    <div className="editDetailsCompHeading">
                        <b>Company Verification</b>
                    </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                    <div className="EditDetailsCompHeadingStyles">
                        <div className="editDetailsCompHeading">Business Registration Document</div>
                    </div>
                    <div style={{ marginTop: '16px', display: 'flex' }}>
                        <FileUploadForTextField
                            setFileName={(name) =>
                                this.setState({
                                    bussinessRegistrationDocumentName: name,
                                })
                            }
                            setFile={(location) =>
                                this.setState({
                                    bussinessRegistrationDocumentLocation: location,
                                })
                            }
                        />
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <div className="EditDetailsCompHeadingStyles">
                            <div className="editDetailsCompHeading">
                                Business Registartion/Licence No.
                            </div>
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <Textfield
                                width={'100%'}
                                value={this.state.bussinessRegistrationNo}
                                onChange={(event) =>
                                    this.setState({ bussinessRegistrationNo: event.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        <p>
                            By clicking on verify my ID, you agree to terms and conditions of
                            Empolink. A small charge will apply for verification process.
                        </p>
                        <div style={{ marginTop: 16 }}>
                            {/* TODO */}
                            <Button
                                text="Verify My ID"
                                width={'160px'}
                                height="48px"
                                // onClick={() => this.setState({ isFieldOfWorkEditable: false })}
                                color={['#2699fb', '#134d7e']}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: '30px', marginBottom: 80 }}>
                        <div className="EditDetailsCompHeadingStyles">
                            <div className="editDetailsCompHeading">Company Verification Key</div>
                        </div>
                        <div style={{ marginTop: '16px' }}>
                            <Textfield
                                width={'100%'}
                                value={this.state.companyVerificationKey}
                                onChange={(event) =>
                                    this.setState({ companyVerificationKey: event.target.value })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const accountDetailPages = [
            this.accountInformationDetails(),
            this.passwordSecurityDetails(),
            this.fieldSpecialityDetails(),
            this.idVerificationDetails(),
            this.notificationSettingDetails(),
            this.paymentMethodDetails(),
            // this.setUpCompanyPageDetails(),
        ];

        const profileUpdateCards = [
            this.updatePasswordCard(),
            this.updateEmail(),
            this.verifyMobile(false),
            this.updateMobile(),
            this.verifyMobile(true),
        ];

        return (
            <div style={{ display: 'flex' }}>
                {this.state.isCardDisplayed && (
                    <div id="overlay">
                        <div style={{ left: '75%', top: '10vw' }} className="overlayImageStyle">
                            <div
                                style={{
                                    width: '47.08vw',
                                    height: '26.041vw',
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    borderRadius: '10px',
                                }}
                            >
                                <div className="overlayCardTitle">
                                    <div style={{ paddingTop: '1.6vw', display: 'inline-block' }}>
                                        {this.state.cardTitle}
                                    </div>
                                    <div
                                        onClick={() => {
                                            this.setState({ isCardDisplayed: false });
                                        }}
                                        style={{
                                            paddingTop: '1.6vw',
                                            display: 'inline-block',
                                            float: 'right',
                                            paddingRight: '1.5vw',
                                            pointer: 'cursor',
                                        }}
                                    >
                                        <CloseIcon />
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    {profileUpdateCards[this.state.cardNumber]}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div style={{ width: '24.8%', minHeight: '79.4vh', backgroundColor: '#f2f2f2' }}>
                    <div style={{ marginTop: '5.24vw', marginBottom: 100 }}>
                        <div
                            onClick={() => this.setState({ page: 0 })}
                            style={this.state.page === 0 ? selectedHeadingstyles : {}}
                            className="defaultStepperLabelStyles"
                        >
                            <div className="defaultStepperInternalHeading">
                                <PersonOutlineOutlinedIcon />
                                <p className="StepperHeader">
                                    {CONSTANTS.ACCOUNT_INFORMATION_LABEL}
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 1 })}
                            style={this.state.page === 1 ? selectedHeadingstyles : {}}
                            className="defaultStepperLabelStyles"
                        >
                            <div className="defaultStepperInternalHeading">
                                <LockOutlinedIcon />
                                <p className="StepperHeader">
                                    {CONSTANTS.PASSWORD_AND_SECURITY_LABEL}
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 2 })}
                            style={this.state.page === 2 ? selectedHeadingstyles : {}}
                            className="defaultStepperLabelStyles"
                        >
                            <div className="defaultStepperInternalHeading">
                                <AssignmentIndOutlinedIcon />
                                <p className="StepperHeader">
                                    {' '}
                                    {CONSTANTS.FIELD_AND_SPECIALITY_LABEL}
                                </p>
                            </div>
                        </div>
                        <div
                            // onClick={() => this.setState({ page: 3 })}
                            // style={this.state.page === 3 ? selectedHeadingstyles : {}}
                            style={{cursor:"not-allowed"}}
                            className="defaultStepperLabelStyles"
                        >
                            <div className="defaultStepperInternalHeading">
                                <VerifiedUserOutlinedIcon />
                                <p className="StepperHeader"> {CONSTANTS.ID_VERIFICATION_LABEL}</p>
                            </div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 4 })}
                            style={this.state.page === 4 ? selectedHeadingstyles : {}}
                            className="defaultStepperLabelStyles"
                        >
                            <div className="defaultStepperInternalHeading">
                                <NotificationsNoneOutlinedIcon />
                                <p className="StepperHeader">
                                    {CONSTANTS.NOTIFICATION_SETTINGS_LABEL}
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 5 })}
                            style={this.state.page === 5 ? selectedHeadingstyles : {}}
                            className="defaultStepperLabelStyles"
                        >
                            <div className="defaultStepperInternalHeading">
                                <PaymentOutlinedIcon />
                                <p className="StepperHeader">{CONSTANTS.PAYMENT_METHOD_LABEL}</p>
                            </div>
                        </div>
                        {/* <div onClick={() => this.setState({ page: 6 })} style={this.state.page === 6 ? selectedHeadingstyles : {}} className="defaultStepperLabelStyles">
                            <div className="defaultStepperInternalHeading">
                                <BusinessCenterOutlinedIcon />
                                <p className="StepperHeader">{CONSTANTS.SET_UP_COMPANY_PAGE_LABEL}</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div style={{ width: '75.2%' }}>
                    {this.state.page !== 4 && this.state.page !== 5 ? (
                        <div style={{ width: '50%', marginLeft: '14.12%', marginTop: '6.9vw' }}>
                            {accountDetailPages[this.state.page]}
                        </div>
                    ) : this.state.page === 5 ? (
                        <div style={{ width: '60%', marginLeft: '14.12%', marginTop: '6.9vw' }}>
                            {accountDetailPages[this.state.page]}
                        </div>
                    ) : (
                        <div style={{ width: '72%', marginLeft: '14.12%', marginTop: '6.9vw' }}>
                            {accountDetailPages[this.state.page]}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        dataFieldOfWorkDropdown: state.fieldOfWorkReducer.data,
        dataSpecialityDropdown: state.specialityReducer.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFieldOfWork: () => {
            dispatch(getFieldOfWork());
        },
        getSpecialityList: (value) => {
            dispatch(getSpecialityList(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountDetails);
