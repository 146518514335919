import {LOADING, ERROR,  SUCCESS} from '../action/profile_action';
import { APIHelper } from '../utils/ApiHelper';
import API from '../utils/Urls'

export const getProfileData = () => {

    return async dispatch => {
        try {
            dispatch({ type: LOADING })

            let headers = {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }

            APIHelper.getMethod(
              API.GET_EMPLOYEE_PROFILE, headers
            ).then((response) => {
              if(response.employer !== undefined){

                if(response.employer.address !== undefined && response.employer.address.length > 0){
                  var addressProfile = response.employer.address[0].unit_number + " " +
                  response.employer.address[0].city + " " +response.employer.address[0].state + " " +
                  response.employer.address[0].country + " - " + response.employer.address[0].zip;
                  dispatch({ type: SUCCESS, data : addressProfile, userid :  response.employer.userid})
                }else{
                  dispatch({ type: ERROR, errorMessage : "" })
                }
              }else{
                dispatch({ type: ERROR, errorMessage : "" })
              }
            })

          } catch(error) {
              dispatch({ type: ERROR, errorMessage : ""  })
          }
    }
  }
