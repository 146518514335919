import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContainedButtons from '../../../Components/Button/Button';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import Swal from 'sweetalert2';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import JobApplicationApply from '../../EmployeeSearch/EmployeeAdvancedSearchTalent/Component/JobApplicationApply';
import CancellationReasonPopup from './Component/CancellationReasonPopup';

const AppliedJobs = (props) => {
    const [appliedJobData, setAppliedJobData] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [jobData, setJobData] = React.useState({});
    const [employer, setEmployer] = React.useState({});
    const [employerProfile, setEmployerProfile] = React.useState({});
    const [applicationData, setApplicationData] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [jobLoading, setJobLoading] = React.useState(false);
    const [withdrawReason, setWithdrawReason] = React.useState(false);
    const [applicationID, setAppplicationID] = React.useState('');

    React.useEffect(() => {
        getListAppliedJob();
    }, [page]);

    const getListAppliedJob = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_LIST_EMPLOYEE_APPLIED_JOB + `page=${page}`, headers)
            .then((res) => {
                if (res) {
                    setAppliedJobData(res.employeeListOffer);
                    setTotal(res.employeeListOfferCount);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const jobDetails = (id) => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                setJobData(response.job);
                setEmployer(response.employerModel);
                setEmployerProfile(response.employer);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    const applicationWithdraw = (obj) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.putMethod(
            `${API.JOB_APPLICATION_WITHDRAW_FROM_EMPLOYEE}${applicationID}`,
            obj,
            headers
        )
            .then((response) => {
                if (response.nModified) {
                    setWithdrawReason(false);
                    getListAppliedJob();
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: 'Application withdraw successfully!',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Error!',
                        text: 'Something went wrong, please try again!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                }
            })
            .catch((err) => {});
    };

    const getStatusByName = (status) => {
        switch (status) {
            case 'APPLIED':
                return 'Applied';
            case 'WITHDRAW':
                return 'Withdraw';
            case 'EMPLOYEE_ACCEPT':
                return 'Employee accept';
            case 'DECLINED':
                return 'Declined';
            default:
                return '';
        }
    };

    return (
        <>
            <div className="editDetailsContentHeading">Applied Jobs</div>
            <table
                style={{
                    width: '100%',
                    textAlign: 'left',
                    borderCollapse: 'collapse',
                    fontFamily: 'Libre Franklin',
                    marginTop: 85,
                }}
            >
                <thead>
                    <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>Company Name</th>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>Job Title</th>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>Start Date</th>
                        <th style={{ width: '15%', color: '#1c1c1c' }}>Fee/Rate</th>
                        {/* <th style={{ width: '15%', color: '#1c1c1c' }}>Status</th> */}
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                {loading ? (
                    <tr style={{ textAlign: 'center', height: 100 }}>
                        <td colSpan="5">
                            <CircularProgress size={40} />
                        </td>
                    </tr>
                ) : (
                    <tbody>
                        {appliedJobData.length > 0 ? (
                            appliedJobData.map((item) => (
                                <tr
                                    style={{
                                        height: 86,
                                        borderBottom: '2px solid #C4C4C4',
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <td style={{ width: '20%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div>
                                                <AccountCircleIcon
                                                    style={{ height: '70px', width: '70px' }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    margin: 'auto',
                                                    marginLeft: 10,
                                                    opacity: 0.78,
                                                }}
                                            >
                                                <p className="hiringEmployeeName">
                                                    {(item.employerProfile &&
                                                        item.employerProfile.company.name) ||
                                                        ''}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '20%', color: '#1c1c1c' }}>
                                        {item.job && item.job.title}
                                    </td>
                                    <td style={{ width: '20%', color: '#1c1c1c' }}>
                                        {/* {item.job && item.job.jobType === 'HOURLY' ? 
                                        item.job.hourlySchedule 
                                        && item.job.hourlySchedule.length> 0 
                                        && moment(item.job.hourlySchedule[0]).utc().format('MMM DD, YY') : moment(item.job && item.job.startDate).utc().format('MMM DD, YY')} */}
                                        {moment(item.startDate).utc(0).format('MMM DD, YY')}
                                    </td>
                                    <td style={{ width: '20%', color: '#1c1c1c' }}>
                                        {item.job && item.job.jobPostType === 'JOB_POST' ? (
                                            <>
                                                {item.job && item.job.jobType === 'HOURLY'
                                                    ? `$${item.proposeHourlyRate}`
                                                    : `$${item.proposedFeeByEmployee}`}
                                            </>
                                        ) : (
                                            `$${item.proposeHourlyRate}`
                                        )}
                                    </td>
                                    {/* <td style={{ width: '15%', color: '#1c1c1c' }}>
                                        {getStatusByName(item.status)}
                                    </td> */}
                                    <td
                                        style={{ width: '20%', textAlign: 'end', color: '#1c1c1c' }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <ContainedButtons
                                                text="Withdraw"
                                                color={['#a4c772', '#4e8400']}
                                                height={48}
                                                width={'12vw'}
                                                onClick={() => {
                                                    setAppplicationID(item._id);
                                                    setWithdrawReason(true);
                                                }}
                                                disabled={item.status === 'DECLINED'}
                                            />
                                            <div
                                                style={{
                                                    height: 42,
                                                    width: 42,
                                                    backgroundColor: '#a4c772',
                                                    borderRadius: 42,
                                                    textAlign: 'center',
                                                    lineHeight: '62px',
                                                    marginLeft: 33,
                                                }}
                                            >
                                                <RemoveRedEyeOutlinedIcon
                                                    style={{
                                                        height: 34,
                                                        width: 34,
                                                        color: '#FFF',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setApplicationData(item);
                                                        jobDetails(item.job._id);
                                                        setDrawerOpen(true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr style={{ textAlign: 'center', height: 100 }}>
                                <td colSpan="5">
                                    <p>No data found.</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            {!loading && appliedJobData.length > 0 && (
                <div
                    className="advancedSearchPagination"
                    style={{ marginTop: 85, marginBottom: 86 }}
                >
                    <Pagination
                        count={Math.ceil(total / 10)}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        style={{ justifyContent: 'center' }}
                        size="large"
                    />
                </div>
            )}
            {console.log('applicationData', applicationData)}
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <JobApplicationApply
                        jobData={jobData}
                        jobLoading={jobLoading}
                        employer={{ ...employer, ...employerProfile }}
                        employerProfile={employerProfile}
                        onClose={() => setDrawerOpen(false)}
                        applicationData={applicationData}
                        isApplied
                    />
                }
                width="80%"
            />
            {withdrawReason && (
                <div id="overlay" style={{ zIndex: 999 }}>
                    <div className="overlayImageStyle">
                        <div
                            style={{
                                width: '68.64vw',
                                height: '41vw',
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                            }}
                        >
                            <CancellationReasonPopup
                                cancelBtn={() => {
                                    setWithdrawReason(false);
                                }}
                                yesButtonClick={(obj) => applicationWithdraw(obj)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AppliedJobs;
