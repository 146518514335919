import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import ContainedButtons from '../../../Components/Button/Button';
import TextField from '../../../Components/TextField/Textfield';
import MonthYearPicker from '../../MonthPicker/MonthPicker';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import DrawerComponent from '../../Drawer/Drawer';
import ContractInformation from '../../../Pages/EmployeeHiringProcess/ProcessStep/Component/ContractInformation';
import ContractInvoice from '../../Invoice/ContractInvoice';
import '../DashboardOverview.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(4),
            justifyContent: 'center',
            display: 'flex'
        },
        '& .Mui-selected': {
            color: '#fff',
            backgroundColor: '#1876D2',
            '&:hover': {
                backgroundColor: '#1876D2'
            }
        },
    },
}));

const Invoices = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState();
    const [contractType, setContractType] = useState('ALL');
    const [employeeName, setEmployeeName] = useState();
    const [jobTitle, setJobTitle] = useState('');
    const [data, setData] = useState();
    const [localHiresSortBy, setLocalHiresSortBy] = useState('charged_on_by_stripe');
    const [optionValue, setOptionValue] = useState([
        { label: 'Date(most recent)', value: 'charged_on_by_stripe' },
        { label: 'Job Title', value: 'jobTitle' },
        { label: 'Employee', value: 'employee' },
    ]);
    const [buttonColor, setButtonColor] = useState(() => {
        return ['#2493F1', '#15568D'];
    });
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [jobData, setJobData] = useState({});
    const [jobLoading, setJobLoading] = useState(false);
    const [employer, setEmployer] = useState({});
    const [employerProfile, setEmployerProfile] = useState({});
    const [applicationData, setApplicationData] = useState({});
    const [contractInfo, setContractInfo] = useState({});
    const [milestone, setMilestone] = useState();
    const classes = useStyles();
    const [currentPage, setcurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getInvoiceData();
    }, [currentPage]);

    const getDefaultSortValue = () => {
        return optionValue.find((o) => o.value === localHiresSortBy);
    };

    const getInvoiceData = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        // setLoading(true);
        const tempStartDate = startDate === '' ? moment().subtract(1, 'M').startOf('month').format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD');
        const tempEndDate = endDate === '' ? moment().endOf('month').format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD');

        const data = {
            contractType,
            employeeName: employeeName && employeeName.trim(),
            jobTitle: jobTitle && jobTitle.trim(),
            startDate: tempStartDate,
            endDate: tempEndDate,
            sort: localHiresSortBy,
            pageNo: currentPage,
            size: pageSize
        };

        APIHelper.postMethod(API.GET_EMPLOYER_INVOICE, data, headers)
            .then((response) => {
                setData(response?.invoiceDetails);
                setTotalPages(response?.totalPage);
                // setLoading(false);
            })
            .catch((err) => {
                // setLoading(false);
            });
    };

    const jobDetails = (id) => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                setJobData(response.job);
                setEmployer(response.employerModel);
                setEmployerProfile(response.employer);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    const renderInvoice = (d) => {
        if (d.contractDetail.contractType === "HOURLY") {
            return (
                <tr className='invoice-tabel-tr'>
                    <td className='invoice-tabel-td'>
                        {(d.charged_on_by_stripe !== undefined && d.charged_on_by_stripe !== null) ? (moment(d.charged_on_by_stripe).utc(0).format('MMM DD,YY')) : `---`}
                    </td>
                    <td className='invoice-tabel-td'>
                        {d.jobDetail?.title}
                    </td>
                    <td className='invoice-tabel-td'>
                        {`${d.employeeDetail?.firstName} ${d.employeeDetail?.lastName}`}
                    </td>
                    <td className='invoice-tabel-td'>
                        {d.contractDetail.contractType}
                    </td>
                    <td className='invoice-tabel-td'>
                        {d.contractDetail.contractNumber}
                    </td>
                    <td className='invoice-tabel-td'>
                        ${d.payment_method === 'EMPOLINK' ? parseFloat(parseFloat(d.baseFee) + parseFloat(d.employerServiceFee) || 0).toFixed(2) : (parseFloat(d?.employerServiceFee)).toFixed(2)}
                    </td>
                    <td className='invoice-tabel-td'>
                        <VisibilityOutlinedIcon
                            style={{
                                width: '30px',
                                height: '30px',
                                background: '#1876D2',
                                color: '#FFF',
                                padding: 4,
                                borderRadius: 30,
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setContractInfo(d);
                                if (d.jobDetail) {
                                    jobDetails(d.jobDetail?._id);
                                    setDrawerOpen(true);
                                    setApplicationData({
                                        proposeHourlyRate: d.contractDetail.proposeHourlyRate,
                                        empolinkServiceFee: d.contractDetail.empolinkServiceFee,
                                        employeeCompensation:
                                            d.contractDetail.employeeCompensation || 0,
                                    });
                                }
                            }}
                        />
                    </td>
                </tr>
            );
        }
        if (d.contractDetail.contractType === "FIXED") {
            const fixContract = d.contractDetail.milestone.map((milestone) => {
                return (
                    <tr className='invoice-tabel-tr'>
                        <td className='invoice-tabel-td'>
                            {moment(d.charged_on_by_stripe).utc(0).format('MMM DD,YY')}
                        </td>
                        <td className='invoice-tabel-td'>
                            {d.jobDetail?.title}
                        </td>
                        <td className='invoice-tabel-td'>
                            {`${d.employeeDetail?.firstName} ${d.employeeDetail?.lastName}`}
                        </td>
                        <td className='invoice-tabel-td'>
                            {d.contractDetail.contractType}
                        </td>
                        <td className='invoice-tabel-td'>
                            {d.contractDetail.contractNumber}
                        </td>
                        <td className='invoice-tabel-td'>
                            ${d.payment_method === 'EMPOLINK' ? parseFloat(parseFloat(milestone?.amount) + parseFloat(milestone?.employerServiceFee) || 0).toFixed(2) : milestone?.employerServiceFee ? (parseFloat(milestone?.employerServiceFee).toFixed(2)) : parseFloat(d?.employerServiceFee).toFixed(2)}
                        </td>
                        <td className='invoice-tabel-td'>
                            <VisibilityOutlinedIcon
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    background: '#1876D2',
                                    color: '#FFF',
                                    padding: 4,
                                    borderRadius: 30,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setContractInfo(d);
                                    if (d.jobDetail) {
                                        jobDetails(d.jobDetail?._id);
                                        setDrawerOpen(true);
                                        setMilestone(milestone)
                                        setApplicationData({
                                            proposeHourlyRate: d.contractDetail.proposeHourlyRate,
                                            empolinkServiceFee: d.contractDetail.empolinkServiceFee,
                                            employeeCompensation:
                                                d.contractDetail.employeeCompensation || 0,
                                        });
                                    }
                                }}
                            />
                        </td>
                    </tr>
                );
            });
            return fixContract;
        }
    };

    const handlePageChanged = (e, page) => {
        setcurrentPage(page);
    };

    const applyFilter = () => {
        setcurrentPage(1);
        getInvoiceData();
    };

    return (
        <div style={{ padding: '0 40px' }}>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}
            >
                <h1 style={{ fontSize: '36px', color: '#070707', margin: 0, fontWeight: 'normal' }}>
                    Invoices
                </h1>
            </div>
            <div className="card-override">
                <Card style={{ marginTop: '50px', borderRadius: '18px', padding: 30 }}>
                    <div className="row">
                        <div
                            className="col-md-4"
                            style={{ color: '#9a9a9a', display: 'flex', alignItems: 'center' }}
                        >
                            <p style={{ margin: '0' }}>Period Start Date:</p>
                            <div
                                style={{ width: '150px', marginLeft: 10 }}
                                className="monthYearPicker"
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MonthYearPicker
                                        fieldLabel={'From'}
                                        startMonth={moment().subtract(1, 'M').format('M')}
                                        startYear={moment().format('YYYY')}
                                        onSelected={(val) => {
                                            setStartDate(moment(val, 'MM-YYYY').startOf('month').format('YYYY-MM-DD'));
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div
                            className="col-md-4"
                            style={{ color: '#9a9a9a', display: 'flex', alignItems: 'center' }}
                        >
                            <p style={{ margin: '0' }}>Period End Date:</p>
                            <div
                                style={{ width: '150px', marginLeft: 10 }}
                                className="monthYearPicker"
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MonthYearPicker
                                        fieldLabel={'End'}
                                        endMonth={moment().format('M')}
                                        endYear={moment().format('YYYY')}
                                        onSelected={(val) => {
                                            setEndDate(moment(val, 'MM-YYYY').endOf('month').format('YYYY-MM-DD'));
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 40 }}>
                        <div className="col-md-3">
                            <p style={{ color: '#707070', fontWeight: 500, marginBottom: 10 }}>
                                Job Title
                            </p>
                            <div style={{ height: 40 }}>
                                <TextField
                                    width={'100%'}
                                    height={42}
                                    value={jobTitle}
                                    onChange={(e) => {
                                        setJobTitle(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3" style={{ marginLeft: 20 }}>
                            <p style={{ color: '#707070', fontWeight: 500, marginBottom: 10 }}>
                                Talent Name
                            </p>
                            <TextField
                                width={'100%'}
                                height={42}
                                value={employeeName}
                                onChange={(e) => {
                                    setEmployeeName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-3">
                            <p style={{ color: '#707070', fontWeight: 500, marginBottom: 10 }}>
                                Contract Type
                            </p>
                            <div style={{ width: '200px', marginLeft: 10 }}>
                                <Dropdown
                                    placeholder={'All Jobs'}
                                    height={40}
                                    options={[
                                        { label: 'All', value: 'ALL' },
                                        { label: 'Fixed', value: 'FIXED' },
                                        { label: 'Hourly', value: 'HOURLY' },
                                    ]}
                                    onChange={({ value }) => {
                                        setContractType(value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3" style={{ marginTop: '30px' }}>
                            <ContainedButtons
                                text="Apply"
                                color={buttonColor}
                                height={40}
                                width={'12vw'}
                                onClick={() => applyFilter()}
                            />
                        </div>
                    </div>
                </Card>
            </div>
            <Card style={{ marginTop: 50, borderRadius: 18, padding: 30 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <p style={{ color: '#9a9a9a', fontWeight: 500, fontSize: 24 }}></p>
                    <div
                        style={{
                            width: 220,
                            marginLeft: 10,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <p style={{ color: '#9a9a9a' }}>Sort by:</p>
                        <div style={{ width: 140, marginLeft: 10 }}>
                            <Dropdown
                                height={40}
                                defaultValue={getDefaultSortValue()}
                                placeholder={'Job Title'}
                                options={optionValue}
                                onChange={({ value }) => {
                                    setLocalHiresSortBy(value);
                                    applyFilter();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <table className='invoice-tabel'>
                    <thead>
                        <tr className='invoice-tabel-tr'>
                            <th className='invoice-tabel-td'>Date</th>
                            <th className='invoice-tabel-td'>
                                Job Title
                            </th>
                            <th className='invoice-tabel-td'>
                                Talent
                            </th>
                            <th className='invoice-tabel-td'>
                                Contract Type
                            </th>
                            <th className='invoice-tabel-td'>
                                Contract No.
                            </th>
                            <th className='invoice-tabel-td'>Amount</th>
                            <th className='invoice-tabel-td'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((obj, index) => renderInvoice(obj))}
                    </tbody>
                </table>
                <div className={classes.root}>
                    <Pagination
                        color='primary'
                        size='large'
                        page={currentPage}
                        count={totalPages}
                        onChange={handlePageChanged}
                    />
                </div>
            </Card>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <ContractInvoice
                        jobData={jobData}
                        contractInfo={contractInfo}
                        milestone={milestone}
                        employerProfile={employerProfile}
                        type={contractInfo.contractDetail?.contractType}
                        onClose={() => setDrawerOpen(false)}
                        userType={localStorage.getItem('userType')}
                    />
                }
                width="54%"
            />
        </div>
    );
};

export default Invoices;
