import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


export default function CentralizeInputText(props) {
  // const [selectedValue, setSelectedValue] = useState("");
  // const [text, setText] = useState("");
  const [width, setWidth] = useState("40%");
  const [fontSize, setFontSize] = useState("0.9375vw");

  const useStyles = makeStyles((theme) => ({
    inputText: {
      width: width,
      '& .MuiOutlinedInput-root': {
        fontFamily: "Libre Franklin",
        fontSize: fontSize,
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: "#9a9a9a",
        borderRadius: "10px",
      },
      '& .MuiFormLabel-root': {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        bottom: "0px",
        right: "22px"
      }
    },
  }));

  const classes = useStyles();


  useEffect(() => {
    if (props.width !== undefined)
      setWidth(props.width);
  }, [props.width]);

  useEffect(() => {
    if (props.fontSize !== undefined)
      setFontSize(props.fontSize);
  }, [props.fontSize]);


  // useEffect(() => {
  //   setText(props.text !== undefined ? props.text : "");
  // }, [props.text !== undefined ? props.text : ""]);

  return (
    <TextField
      id={props.id}
      label={props.label}
      variant="outlined"
      autoComplete='off'
      disabled={props.disabled ?? false}
      error={props.error ?? false}
      className={props.inputTextFiledType ?? classes.inputText}
      value={props.text}
      multiline={props.isMutiline === undefined || !props.isMutiline ? false : true}
      rows={props.isMutiline === undefined || !props.isMutiline ? 1 : (props.row === undefined ? 4 : props.row)}
      onChange={event => {
        if (props.onChangeCallback !== undefined) {
          props.onChangeCallback(event.target.value);
        }
      }}
      onClick={() => {
        if (props.onClickCallback !== undefined) {
          props.onClickCallback();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (props.onEnter !== undefined) {
            props.onEnter();
          }
        }
      }}
      {...props?.newProps}
    />
  );
}
