import React from 'react'
import ContractInvoice from "../../Components/Invoice/ContractInvoice";
import DrawerComponent from '../../Components/Drawer/Drawer';
const Invoices = () => {

    
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [invoicesType, setInvoicesType] = React.useState();
    
    return (
        <div style={{ display: "flex", flex: 1 }}>  
            <div style={{ width: "24.8%", minHeight: "79.4vh", backgroundColor: "#f2f2f2" }}>
                <div style={{ marginTop: "5.24vw" }}>
                    <p className="hiringHeader">Invoices</p>
                    <div className="hiringStepperLabelStyles">
                        <div className="hiringStepperInternalHeading"  onClick={()=>{setDrawerOpen(true); setInvoicesType("FIXED") }}>
                            Fixed Invoices
                        </div>
                    </div>
                    <div className="hiringStepperLabelStyles">
                        <div className="hiringStepperInternalHeading" onClick={()=>{setDrawerOpen(true); setInvoicesType("HOURLY")}}>
                            Hourly Invoices
                        </div>
                    </div>
                </div>
                <DrawerComponent
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            component={
                                <ContractInvoice
                                    onClose={() => setDrawerOpen(false)}
                                    type={invoicesType}
                                    userType={localStorage.getItem('userType')}
                                />
                            }
                            width="80%"
                />           
            </div>    
        </div>
    )
}

export default Invoices
