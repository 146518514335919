import React, { useState } from 'react';
// import { GoogleLogin } from 'react-google-login';
import TextBox from '../../../Components/TextField/Textfield';
// import Button from '../../../Components/Button/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import '../../LoginPage/LoginPage.scss';
import './EmployeeRegisterMainPage.scss';
import { useHistory, withRouter } from 'react-router-dom';
// import { MicrosoftLogin } from 'react-microsoft-login';
import { ValidateEmail } from '../../../utils/validations';
import API from '../../../utils/Urls';
import { APIHelper } from '../../../utils/ApiHelper';
import EmpolinkLogo from '../../../Assets/images/login-logo1.png';
import SignUpLogo from '../../../Assets/images/signup-img.png';
import GoogleLogo from '../../../Assets/images/google.png';
import MicrosoftLogo from '../../../Assets/images/microsoft.png';

const EmployeeRegisterMainPage = ({ handleRegister, setCurrentPage }) => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordDoesnotMatch, setPasswordDoesnotMatch] = useState(false);
    const [emailError, setError] = useState(false);
    const [username, setUsername] = useState('');
    const [googleApiData, setGoogleApiData] = useState({});
    const [isEmailExist, setIsEmailExist] = useState(false);
    const onLogInClicked = () => {
        history.push('/login');
    };

    const onSignUpClicked = () => {
        if (!ValidateEmail(username) || isEmailExist) {
            setError(true);
        }
        if (!confirmPassword || !password) {
            setPasswordDoesnotMatch(true);
        }

        if (confirmPassword !== password || password.length < 6) {
            setPasswordDoesnotMatch(true);
        }

        if (
            ValidateEmail(username) &&
            !isEmailExist &&
            confirmPassword === password &&
            password.length >= 6
        ) {
            handleRegister(password, username, googleApiData);
            setCurrentPage();
        }
    };

    const responseGoogle = (response) => {
        setGoogleApiData(response);
        handleRegister(password, username, response);
        setCurrentPage();
    };

    const authHandler = (err, data) => {
        console.log(err, data);
    };

    const checkIfEmailExists = () => {
        const params = new URLSearchParams();
        params.append('email', username);
        let headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        };

        APIHelper.postMethod(API.CHECK_EMAIL, params, headers)
            .then((response) => {
                if (response.data.isEmailExist) {
                    setIsEmailExist(true);
                } else {
                    setIsEmailExist(false);
                }
            })
            .catch((err) => {
                setIsEmailExist(false);
            });
    };

    return (
        <section className="login-wrapper sign-up-body">
            <div className="container">
                <div className="row row-center">
                    <div className="col-md-6">
                        <div className="login-img">
                            <a className="login-logo" href={void(0)} onClick={() => history.push('/')}>
                                <img src={EmpolinkLogo} alt="empolink-logo" />
                            </a>
                            <img src={SignUpLogo} alt="signup-logo" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="login-right">
                            <h2>Sign up</h2>
                            <div className="form-col">
                                <div className="form-style form-email">
                                    {/* <input
                                        type="email"
                                        placeholder="Enter your email address"
                                    /> */}
                                    <TextBox
                                        required={true}
                                        label={'Email Address'}
                                        onChange={(event) => {
                                            setError(false);
                                            setUsername(event.target.value);
                                        }}
                                        onBlur={() => checkIfEmailExists()}
                                        value={username}
                                        width={'100%'}
                                        error={emailError || isEmailExist}
                                    />
                                    {isEmailExist ? (
                                        <div className="errorMsg">Email already exists. </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-col">
                                <div className="form-style form-password">
                                    {/* <input type="password" placeholder="Min 6 Characters" />
                                    <i className="toggle-password fa fa-fw fa-eye-slash"></i> */}
                                    <TextBox
                                        label={'Password * (Min. 6 Characters)'}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(event) => {
                                            setPasswordDoesnotMatch(false);
                                            setPassword(event.target.value);
                                        }}
                                        width={'100%'}
                                        error={passwordDoesnotMatch}
                                        InputProps={{
                                            // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(!showPassword)
                                                        }
                                                        edge="end"
                                                        tabIndex="-1" // Exclude from tab order
                                                    >
                                                        {showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-col">
                                <div className="form-style form-password">
                                    {/* <input type="password" placeholder="Confirm Password" />
                                    <i className="toggle-password fa fa-fw fa-eye-slash"></i> */}
                                    <TextBox
                                        required={true}
                                        label={'Confirm Password'}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        onChange={(event) => {
                                            setPasswordDoesnotMatch(false);
                                            setConfirmPassword(event.target.value);
                                        }}
                                        width={'100%'}
                                        error={passwordDoesnotMatch}
                                        InputProps={{
                                            // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowConfirmPassword(
                                                                !showConfirmPassword
                                                            )
                                                        }
                                                        edge="end"
                                                        tabIndex="-1" // Exclude from tab order
                                                    >
                                                        {showConfirmPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-col">
                                <input
                                    type="submit"
                                    value="Sign up"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => onSignUpClicked()}
                                />
                            </div>
                            <div className="form-seperator">
                                <span>Or</span>
                            </div>
                            <div className="login-method">
                                {/* <a href="/"> */}
                                <span style={{ display: 'inline-block', cursor: 'not-allowed', pointerEvents: 'auto' }}>
                                    <img src={GoogleLogo} alt="" /> Sign up with Google
                                </span>
                                {/* </a> */}
                            </div>
                            <div className="login-method">
                                {/* <a href="/"> */}
                                <span style={{ display: 'inline-block', cursor: 'not-allowed', pointerEvents: 'auto' }}>
                                    <img src={MicrosoftLogo} alt="" /> Sign up with Microsoft
                                </span>
                                {/* </a> */}
                            </div>
                            <div className="form-link">
                                Already have an account ? <a href="/login">Log in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

};
export default withRouter(EmployeeRegisterMainPage);
