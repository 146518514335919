import React from 'react';
import moment from 'moment';
import ContainedButtons from '../../../../Components/Button/Button';
import { convertFrom24To12Format, getAttachmentIcon } from '../../../../utils/commonFunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import CancellationReasonPopup from './CancellationReasonPopup';

const JobDescription = (props) => {
    const { jobData, jobLoading } = props;

    return (
        <>
            <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                        {jobData.jobPostType === 'JOB_POST'
                            ? 'Job Post Overview'
                            : 'Invitation Overview'}
                    </h3>
                    {props.isDeleteJobPost && (
                        <ContainedButtons
                            text={
                                jobData.jobPostType === 'JOB_POST'
                                    ? 'Delete Job Post'
                                    : 'Delete Invitation(s)'
                            }
                            color={['#2493F1', '#15568D']}
                            height="56px"
                            width={'215px'}
                            onClick={() => props.deleteJobPost(jobData._id)}
                        />
                    )}
                </div>
                {jobLoading && (
                    <div style={{ textAlign: 'center', marginTop: '250px' }}>
                        <CircularProgress size={50} />
                    </div>
                )}
                {!jobLoading && (
                    <div style={{ marginTop: 64, fontSize: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                            <p>{jobData && jobData.title}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 30,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Job Post Date:</p>
                            <p>
                                {moment(jobData && jobData.createdAt)
                                    .utc(0)
                                    .format('MMM DD, YY')}
                            </p>
                        </div>
                        <div
                            style={{
                                marginTop: 30,
                                paddingBottom: 16,
                                borderBottom: '2px solid #C4C4C4',
                            }}
                        >
                            <p style={{ color: '#9a9a9a', marginTop: 30 }}>Description:</p>
                            <pre
                                style={{
                                    marginTop: 30,
                                    color: '#1c1c1c',
                                    fontsize: 20,
                                    fontFamily: 'Libre Franklin',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {jobData && jobData.description}
                            </pre>
                        </div>
                        {jobData && jobData.additionalDetails && (
                            <div
                                style={{
                                    marginTop: 50,
                                    paddingBottom: 16,
                                    borderBottom: '2px solid #C4C4C4',
                                }}
                            >
                                <p style={{ color: '#9a9a9a', marginTop: 30 }}>
                                    Additional Instruction:
                                </p>
                                <pre
                                    style={{
                                        marginTop: 30,
                                        color: '#1c1c1c',
                                        fontsize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {jobData && jobData.additionalDetails}
                                </pre>
                            </div>
                        )}
                        <div style={{ marginTop: 70 }}></div>
                        {jobData.field && (
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Field of work:</p>
                                <p>{jobData && jobData.field && jobData.field.name}</p>
                            </div>
                        )}
                        {jobData.jobPostType === 'JOB_POST' && (
                            <>
                                {jobData.speciality && (
                                    <div style={{ display: 'flex', marginTop: 30 }}>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Speciality:</p>
                                        <p>
                                            {jobData &&
                                                jobData.speciality &&
                                                jobData.speciality.name}
                                        </p>
                                    </div>
                                )}
                                <div style={{ display: 'flex', marginTop: 30 }}>
                                    <p style={{ minWidth: 350, color: '#9a9a9a' }}>Skills:</p>
                                    <div>
                                        <div style={{ marginBottom: 8 }}>
                                            {jobData &&
                                                jobData.skills &&
                                                jobData.skills.map((j) => (
                                                    <Chip
                                                        label={j.name}
                                                        style={{
                                                            marginTop: 15,
                                                            marginRight: 6,
                                                            width: 120,
                                                        }}
                                                        variant="outlined"
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: 30,
                                        paddingBottom: 30,
                                        borderBottom: '2px solid #C4C4C4',
                                    }}
                                >
                                    <p style={{ width: 350, color: '#9a9a9a' }}>Experience:</p>
                                    <p>
                                        {jobData && jobData.experience && jobData.experience.min}-
                                        {jobData && jobData.experience && jobData.experience.max}{' '}
                                        years
                                    </p>
                                </div>
                                {jobData.jobType === 'HOURLY' ? (
                                    <div style={{ display: 'flex', marginTop: 70 }}>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Hourly Rate:</p>
                                        <p>
                                            {jobData &&
                                                jobData.rate &&
                                                `$${jobData.rate.min} - $${jobData.rate.max}`}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', marginTop: 30 }}>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Fee Amount:</p>
                                        <p>
                                            {jobData.jobLocationType==="LOCAL"?localStorage.getItem('local_currency_symbol'):"$"}
                                            {jobData &&
                                                jobData.JobValue &&
                                                (jobData.JobValue.toFixed(2) || '00.00')}{' '}{jobData.currency}
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 30,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Client Payment Method:</p>
                            <p>
                                {jobData && jobData.paymentMethod === 'DIRECT'
                                    ? 'Directly by Client'
                                    : 'Through Empolink'}
                            </p>
                        </div>
                        {jobData.jobType === 'HOURLY' && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: 30,
                                        paddingBottom: 30,
                                        borderBottom: '2px solid #C4C4C4',
                                    }}
                                >
                                    <p style={{ width: 350, color: '#9a9a9a' }}>Payment Cycle:</p>
                                    <p>
                                        {jobData && jobData.paymentFrequency === 'WEEKLY'
                                            ? 'Weekly'
                                            : 'Bi-weekly'}
                                    </p>
                                </div>
                                <div style={{ display: 'flex', marginTop: 70 }}>
                                    <p style={{ width: 350, color: '#9a9a9a', marginBottom: 10 }}>
                                        Work Schedule:
                                    </p>
                                </div>
                                {jobData &&
                                    jobData.hourlySchedule &&
                                    jobData.hourlySchedule.length > 0 && (
                                        <>
                                            <div
                                                style={{
                                                    height: '350px',
                                                    overflow: 'auto',
                                                    width: '80%',
                                                }}
                                            >
                                                <table
                                                    style={{
                                                        width: '100%',
                                                        textAlign: 'right',
                                                        marginTop: 36,
                                                        marginBottom: 36,
                                                        fontSize: 18,
                                                        border: '1px solid #c7c7c7',
                                                        borderRadius: 10,
                                                        borderCollapse: 'separate',
                                                        borderSpacing: 0,
                                                        padding: 30,
                                                    }}
                                                >
                                                    <thead>
                                                        <tr style={{ height: 60 }}>
                                                            <th
                                                                style={{
                                                                    width: '13%',
                                                                    textAlign: 'left',
                                                                    borderBottom:
                                                                        '2px solid #9a9a9a',
                                                                }}
                                                            >
                                                                Date
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '19%',
                                                                    borderBottom:
                                                                        '2px solid #9a9a9a',
                                                                }}
                                                            >
                                                                Start Time
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '19%',
                                                                    borderBottom:
                                                                        '2px solid #9a9a9a',
                                                                }}
                                                            >
                                                                End Time
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '25%',
                                                                    borderBottom:
                                                                        '2px solid #9a9a9a',
                                                                }}
                                                            >
                                                                Non-Payable(hr)
                                                            </th>
                                                            <th
                                                                style={{
                                                                    width: '24%',
                                                                    borderBottom:
                                                                        '2px solid #9a9a9a',
                                                                }}
                                                            >
                                                                Working Hours
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {jobData &&
                                                            jobData.hourlySchedule &&
                                                            jobData.hourlySchedule.map(
                                                                (item, index) => (
                                                                    <tr style={{ height: 60 }}>
                                                                        <td
                                                                            style={{
                                                                                width: '13%',
                                                                                textAlign: 'left',
                                                                                borderBottom:
                                                                                    index + 1 !==
                                                                                    jobData
                                                                                        .hourlySchedule
                                                                                        .length
                                                                                        ? '1px solid #9a9a9a'
                                                                                        : '',
                                                                            }}
                                                                        >
                                                                            {moment(
                                                                                item.dateSchedule
                                                                            )
                                                                                .utc(0)
                                                                                .format(
                                                                                    'MMM DD, YY'
                                                                                )}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '19%',
                                                                                borderBottom:
                                                                                    '1px solid #9a9a9a',
                                                                            }}
                                                                        >
                                                                            {convertFrom24To12Format(
                                                                                item.startTime
                                                                                    .hours,
                                                                                item.startTime
                                                                                    .minutes
                                                                            )}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '19%',
                                                                                borderBottom:
                                                                                    '1px solid #9a9a9a',
                                                                            }}
                                                                        >
                                                                            {convertFrom24To12Format(
                                                                                item.endTime.hours,
                                                                                item.endTime.minutes
                                                                            )}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '25%',
                                                                                borderBottom:
                                                                                    '1px solid #9a9a9a',
                                                                            }}
                                                                        >
                                                                            {item.nonPayableTime}
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: '25%',
                                                                                borderBottom:
                                                                                    '1px solid #9a9a9a',
                                                                            }}
                                                                        >
                                                                            {item.totalHours}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                    justifyContent: 'space-between',
                                                    marginTop: 30,
                                                    marginBottom: 50,
                                                    width: '80%',
                                                }}
                                            >
                                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                                    Total Working Hours:
                                                </p>
                                                <div
                                                    style={{
                                                        border: '2px solid #C4C4C4',
                                                        borderRadius: '5px',
                                                        marginRight: '50px',
                                                        padding: '12px',
                                                    }}
                                                >
                                                    {jobData.hourlyTotalHours}
                                                </div>
                                            </div>
                                            {/* <div
                                                style={{
                                                    display: 'flex',
                                                    marginTop: 36,
                                                    justifyContent: 'space-between',
                                                    width: '76%',
                                                }}
                                            >
                                                <p style={{ color: '#707070' }}>
                                                    Total working Hours
                                                </p>
                                                <p>{jobData.hourlyTotalHours}</p>
                                            </div> */}
                                        </>
                                    )}
                            </>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 50,
                                borderTop: '2px solid #C4C4C4',
                                paddingTop: 30,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Job Post Expiry Date:</p>
                            <p>{moment(jobData.jobExpiryDate).utc(0).format('MMM DD, YY')}</p>
                        </div>

                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Start Date:</p>
                            <p>{moment(jobData.startDate).utc(0).format('MMM DD, YY')}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>End Date:</p>
                            <p>{moment(jobData.endDate).utc(0).format('MMM DD, YY')}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 30,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                            <p>
                                {jobData.remoteWorking
                                    ? 'Remote Working'
                                    : jobData.workAddress &&
                                      `${jobData.workAddress.unit_number + ', ' ?? ''}${
                                          jobData.workAddress.street_address + ', ' ?? ''
                                      }${jobData.workAddress.city + ', ' ?? ''}${
                                          jobData.workAddress.state + ', ' ?? ''
                                      }${jobData.workAddress.postal_code + ', ' ?? ''}${
                                          jobData.workAddress.country ?? ''
                                      }`}
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 70 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                        </div>
                        <div
                            style={{
                                height: 210,
                                border: '1px solid #c7c7c7',
                                marginTop: 10,
                                borderRadius: 10,
                                padding: 20,
                            }}
                        >
                            {jobData &&
                                jobData.attachments &&
                                jobData.attachments.map((attach) => (
                                    <a href={attach.url} download target="_blank" rel="noreferrer">
                                        <img
                                            src={getAttachmentIcon(attach.name)}
                                            style={{ width: 60, height: 60, marginRight: 10 }}
                                        />
                                    </a>
                                ))}
                        </div>
                    </div>
                )}
                {props.cancelReason && (
                    <div id="overlay" style={{ zIndex: 999 }}>
                        <div className="overlayImageStyle">
                            <div
                                style={{
                                    width: '68.64vw',
                                    height: '42vw',
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    borderRadius: '10px',
                                }}
                            >
                                <CancellationReasonPopup
                                    yesButtonClick={(obj) => props.yesButtonClick(obj)}
                                    noButtonClick={() => props.noButtonClick(false)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default JobDescription;
