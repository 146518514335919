import React from 'react';
import { HEADING, SUBHEADING, LIGHTHEADING,LIGHTHEADING_CURSOR,SUBHEADING_WITH_BORDER,SUBHEADING_WITH_BORDER_FIXED_WIDTH } from '../../../utils/enums';
import ScreenSizeDiv from './screen_size_dv'
import { makeStyles } from '@material-ui/core/styles';
import {useEffect, useState } from 'react';


export default function Text(props) {
  const [fontSize, setFontSize] = useState("2.0vw");
  const [color, setColor] = useState("#070707");
  const [alignment, setAlignment] = useState("left");
  const [fontWeight, setFontWeight] = useState("normal");
  const [cursor, setCursor] = useState("text");
  const [padding, setPadding] = useState("0px");
  const [border, setBorder] = useState("");
  const [borderRadius, setBorderRadius] = useState("0px");

  const useStyles = makeStyles((theme) => ({
          text :{
            fontFamily: "Libre Franklin",
            fontSize: fontSize,
            fontWeight: fontWeight,
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            textAlign: alignment,
            color: color,
            cursor : cursor,
            border: border,
            padding: padding,
            borderRadius: borderRadius
       },
  }));

  const classes = useStyles();

  useEffect(() => {
    if(props.fontSize !== undefined)
      setFontSize(props.fontSize);
    }, [props.fontSize]);

    useEffect(() => {
      if(props.color !== undefined)
        setColor(props.color);
    }, [props.color]);

    useEffect(() => {
      if(props.alignment !== undefined)
        setAlignment(props.alignment);
    }, [props.alignment]);


    useEffect(() => {
      if(props.fontWeight !== undefined)
        setFontWeight(props.fontWeight);
    }, [props.fontWeight]);

    useEffect(() => {
      if(props.cursor !== undefined)
        setCursor(props.cursor);
    }, [props.cursor]);

    useEffect(() => {
      if(props.borderRadius !== undefined)
        setBorderRadius(props.borderRadius);
    }, [props.borderRadius]);


    useEffect(() => {
      if(props.border !== undefined)
        setBorder(props.border);
    }, [props.border]);


    useEffect(() => {
      if(props.padding !== undefined)
        setPadding(props.padding);
    }, [props.padding]);

  return (
    <div className = {classes.text} onClick={() => {
           if(props.onClickCallback != undefined){
             props.onClickCallback();
           }
         }}>
         {props.text}
    </div>
    );
}
