import React, { useEffect, useState } from 'react';
import EmployeeRegisterMainPage from './EmployeeRegisterMainPage/EmployeeRegisterMainPage';
import EmployeeRegisterThankyouMessage from './EmployeeRegisterThankyouMessage/EmployeeRegisterThankyouMessage';
import EmployeeRegisterPageFive from './EmployeeRegisterPageFive';
import EmployeeRegisterPageOne from './EmployeeRegisterPageOne';
import EmployeeRegisterPageFour from './EmployeeRegisterPageFour';
import EmployeeRegisterPageThree from './EmployeeRegisterPageThree';
import EmployeeRegisterPageTwo from './EmployeeRegisterPageTwo';
import EmployeeRegisterPageSix from './EmployeeRegisterPageSix';
import EmployeeRegisterOTP from './EmployeeRegisterVerifyEmail/EmployeeRegisterOTP';
import { APIHelper } from '../../utils/ApiHelper';
import axios from 'axios';
import API from '../../utils/Urls';
import { Log } from '../../utils/logger';
import CONSTANTS from '../../utils/constants';

const EmployeeSignUp = () => {
    const [registerData, setRegisterData] = useState({}); 
    const [googleApiData, setGoogleApiData] = useState({});
    const [registerCredentials, setRegisterCredentials] = useState({});
    const [ip, setIp] = useState('');
    const [country, setCountry] = useState('');
    const [registerError, setRegisterError] = useState();
    const [registerLoading, setRegisterLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const handleRegister = (password, username, googleApiData) => {
        setRegisterCredentials({ email: username, username, password });
        setGoogleApiData(googleApiData);
    };

    const handleRegisterData = (data) => {
        setRegisterData({ ...data, ...registerData });
    };

    useEffect(() => {
        APIHelper.getMethod(CONSTANTS.GEO_LOCATION_API).then((response) => {
            setIp(response.ip);
            setCountry(response.country_name);
        });
    }, []);

    const registerAccount = async () => {
        setRegisterLoading(true);
        const body = {
            firstName: registerData.firstName,
            lastName: registerData.lastName,
            password: registerCredentials.password,
            email: registerCredentials.email,
            phone: registerData.mobileNumber,
            preferred_name: registerData.preferredName,
            registration_type: 'EMAIL',
            user_type: 'EMPLOYEE',
            is_only_show_fname: registerData.showFirstLetterOfLastName,
            linkedin_link: registerData.linkedInLink,
            id_verification: {
                legal_id_type: registerData.idType,
                legal_id: registerData.idNumber,
                file_url: registerData.file,
                is_id_verified: 'false',
            },
            photo_url: registerData.photo,
            // languages: registerData.languages,
            languages: ['English'],
            location: {
                ip,
                country,
            },
            address: {
                //street_number: "11009",
                unit_number: registerData.unitNumber,
                street_address: registerData.streetAddress,
                city: registerData.city.name,
                state: registerData.state.name,
                country: registerData.country.name,
                postal_code: registerData.zipCode,
            },
            is_student: false,
            is_international_worker: registerData.workInternationally,
            international_rate: parseFloat(registerData.internationalRate) || 0,
            rate: parseFloat(registerData.myRate) || 0,
            field: registerData.field,
            specialities: [...registerData.specialitiesArr],
            skills: [...registerData.skillArr],
            work_experience: parseFloat(registerData.yearsOfExperience),
            description: registerData.fieldWork,
            notification: {
                job_email: true,
                job_text: false,
            },
            is_terms_accepted: true,
        };

        var bodyFormData = new FormData();
        if (registerData.photo !== undefined && registerData.photo !== null && registerData.photo !== '') {
            bodyFormData.append('files', registerData.photo, registerData.photo.name);
        }
        for (const key in body) {
            if (key !== 'photo') {
                bodyFormData.append(key, typeof body[key] === 'object' ? JSON.stringify(body[key]) : body[key]);
            }
        }
        axios
            .post(API.REGISTER, bodyFormData)
            .then((response) => {
                setRegisterError(undefined);
                setCurrentPage((prv) => prv + 1);
                setRegisterLoading(false);
            })
            .catch((err) => {
                setRegisterError(err.response.data.message);
                setRegisterLoading(false);
            });
    };

    const resendOtp = async () => {
        axios
            .post(API.RESEND_VERIFY_OTP, {
                email: registerCredentials.email,
            })
            .then((response) => {
                if (response.status === 1) {
                    alert('OTP resend');
                }
            })
            .catch((err) => console.log(err));
    };

    const pages = [
        <EmployeeRegisterMainPage
            setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
            handleRegister={handleRegister}
        />,
        <EmployeeRegisterPageOne
            setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
            googleApiData={googleApiData}
            handleRegisterData={handleRegisterData}
        />,
        // <EmployeeRegisterPageTwo
        //     setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
        //     handleRegisterData={handleRegisterData}
        // />,
        <EmployeeRegisterPageThree
            setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
            handleRegisterData={handleRegisterData}
        />,
        <EmployeeRegisterPageFour
            setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
            handleRegisterData={handleRegisterData}
        />,
        <EmployeeRegisterPageFive
            setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
            registerData={registerData}
            handleRegisterData={handleRegisterData}
        />,
        <EmployeeRegisterPageSix
            registerAccount={registerAccount}
            handleRegisterData={handleRegisterData}
            registerError={registerError}
            registerLoading={registerLoading}
        />,
        <EmployeeRegisterOTP
            setCurrentPage={() => setCurrentPage((prv) => prv + 1)}
            email={registerCredentials.email}
            resendOtp={resendOtp}
        />,
        <EmployeeRegisterThankyouMessage />,
    ];

    return <>{pages[currentPage]}</>;
};
export default EmployeeSignUp;
