import React, { useState } from 'react';
import moment from 'moment';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import Radio from '@material-ui/core/Radio/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { Checkbox } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField/TextField';
import TextField from '../../../Components/TextField/Textfield';

import '../../../Assets/common.css';

import TimePicker from '../../JobPostPages/components/centralize_time_picker';
import ContainedButtons from '../../../Components/Button/Button';
import CentralizeRangeDatePicker from '../../JobPostPages/components/centralize_range_date_picker';
import SimpleTimePicker from '../../../Components/TimePicker/TimePicker';
import Constant from '../../JobPostPages/Constant/constant';
import MultipleDatePicker from '../../../Components/MultipleDatePicker/MultipleDatePicker';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import {
    getTimeDifferent,
    getTotalWorkingHours,
    intervalTime,
    timeToDecimal,
} from '../../../utils/commonFunction';
import Swal from 'sweetalert2';

const ReHiring = (props) => {
    const [jobLoading, setJobLoading] = React.useState(true);
    const [jobData, setJobData] = React.useState({});
    const [selectedDatesArr, setSelectedDatesArr] = React.useState([]);
    const [startTime, setStartTime] = React.useState(null);
    const [endTime, setEndTime] = React.useState(null);
    const [tableData, setTableData] = React.useState([]);
    const [nonPayableTime, setNonPayableTime] = React.useState('1 hour');
    const [totalHours, setTotalHours] = React.useState(0);
    const [isValidDifference, setIsValidDifference] = React.useState(true);
    const [isValidDate, setIsValidDate] = React.useState(true);
    const [isValidStartTime, setIsValidStartDate] = React.useState(true);
    const [isValidEndTime, setIsValidEndTime] = React.useState(true);
    const [refreshData, setRefreshData] = React.useState(false);
    const [currentScheduleData, setCurrentScheduleData] = React.useState({
        date: '',
        startTime: '',
        endTime: '',
        nonPayable: 1,
        working: 0,
    });
    const [isValidExpiryDate, setIsValidExpiryDate] = React.useState(true);
    const [expiryDate, setExpiryDate] = React.useState('');
    const [rate, setRate] = React.useState('');
    const [rateErr, setRateErr] = React.useState(false);
    const [noScheduleErr, setNoScheduleErr] = React.useState(false);
    const [selected, setSelected] = React.useState([]);

    React.useEffect(() => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${props.jobID}`, headers)
            .then((response) => {
                setJobData(response.job);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    }, []);

    const addToScheduleHandler = () => {
        let isValid = true;
        selectedDatesArr.forEach((date) => {
            const data = tableData.find(
                (tblData) =>
                    moment(tblData.date).format('DD/MM/YYYY') ===
                        moment(date).format('DD/MM/YYYY') &&
                    moment(tblData.startTime).format('h:mm A') ===
                        moment(startTime).format('h:mm A') &&
                    moment(tblData.endTime).format('h:mm A') === moment(endTime).format('h:mm A')
            );
            if (data) {
                isValid = false;
            }
        });

        if (
            startTime !== '' &&
            endTime !== '' &&
            isValid &&
            nonPayableTime &&
            selectedDatesArr.length > 0 &&
            moment(startTime) < moment(endTime)
        ) {
            let newSchedule = selectedDatesArr.map((item) => {
                return {
                    date: new Date(item),
                    startTime: startTime,
                    endTime: endTime,
                    nonPayable: nonPayableTime,
                    working: getTimeDifferent(startTime, endTime, intervalTime(nonPayableTime)),
                };
            });

            const updatedSchedule = tableData.concat(newSchedule);

            const times = updatedSchedule.map((update) => update.working);
            const NonpayableTime = updatedSchedule.map((update) => update.nonPayable);
            let totalNonPayMin = 0;
            NonpayableTime.forEach((non) => {
                if (non === '1/2 hour') {
                    totalNonPayMin += 30;
                } else if (non === '1 hour') {
                    totalNonPayMin += 60;
                }
            });
            const totalTime = getTotalWorkingHours(times, totalNonPayMin * 60);

            const newtable = updatedSchedule.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });

            if (newtable && newtable.length > 0) {
                setNoScheduleErr(false);
            }

            setTableData(newtable);
            setTotalHours(totalTime);
            setIsValidDifference(true);
            resetValue();
        } else {
            if (selectedDatesArr.length === 0) {
                setIsValidDate(false);
            }
            if (startTime === '') {
                setIsValidStartDate(false);
            }
            if (endTime === '') {
                setIsValidEndTime(false);
            }
            if (moment(startTime) > moment(endTime)) {
                setIsValidDifference(false);
            }
        }
    };

    const resetValue = () => {
        setSelectedDatesArr([]);
        setRefreshData(true);
    };

    const handleNonPayableTime = (e) => {
        if (e.target.value === '1 hour') {
            currentScheduleData['nonPayable'] = 1;
        } else if (e.target.value === '1/2 hour') {
            currentScheduleData['nonPayable'] = 0.5;
        } else if (e.target.value === 'none') {
            currentScheduleData['nonPayable'] = 0;
        }

        setNonPayableTime(e.target.value);
        setCurrentScheduleData(currentScheduleData);
    };

    const handleStartTimeChange = (time) => {
        currentScheduleData['startTime'] = moment(time, 'HH:mm')._i;
        setStartTime(moment(time, 'HH:mm'));
        setIsValidStartDate(true);
        setCurrentScheduleData(currentScheduleData);
    };

    const handleEndTimeChange = (time) => {
        let hours = moment(time, 'HH:mm').diff(startTime, 'hours', true).toFixed(2);
        currentScheduleData['endTime'] = moment(time, 'HH:mm')._i;
        currentScheduleData['working'] = parseInt(hours);
        setEndTime(moment(time, 'HH:mm'));
        setIsValidEndTime(true);
        setCurrentScheduleData(currentScheduleData);
    };

    const handleDateChange = (dates) => {
        let momentDate = [];
        if (dates.length > 0 && dates !== undefined) {
            dates.map((item) => {
                momentDate.push(
                    moment(new Date(`${item.month.number}-${item.day}-${item.year}`)).utc(0).format(
                        'MM-DD-YYYY'
                    )
                );
            });
            setSelectedDatesArr(momentDate);
            setRefreshData(false);
            setIsValidDate(true);
        } else {
            setIsValidDate(false);
        }
    };

    const rehiringSubmit = () => {
        var isValid = true;
        if (!rate) {
            setRateErr(true);
            isValid = false;
        }
        if (!expiryDate) {
            setIsValidExpiryDate(false);
            isValid = false;
        }
        if (tableData.length === 0) {
            setNoScheduleErr(true);
            isValid = false;
        }
        if (isValid) {
            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            // var totalHours = 0;
            let schedule = tableData.map((item) => {
                return {
                    dateSchedule: item.date ? moment(item.date).utc(0).format('YYYY-MM-DDTHH:mm:ssZ') : '',
                    startTime: {
                        hours: moment(item.startTime).hours(),
                        minutes: moment(item.startTime).minutes(),
                    },
                    endTime: {
                        hours: moment(item.endTime).hours(),
                        minutes: moment(item.endTime).minutes(),
                    },
                    nonPayableTime: intervalTime(item.nonPayable),
                    totalHours: timeToDecimal(item.working),
                };
            });

            const request = {
                rate: {
                    min: rate,
                    max: rate,
                },
                hourlySchedule: schedule,
                hourlyTotalHours: parseFloat(totalHours),
                jobExpiryDate: expiryDate,
                employeeID: props.employeeID,
            };

            APIHelper.postMethod(`${API.REHIRING_EMPLOYER_JOB}${props.jobID}`, request, headers)
                .then((response) => {
                    if (response) {
                        Swal.fire({
                            customClass: 'Alert-Class',
                            title: 'Success!',
                            text: 'Re-Hiring job submitted successfully.',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#1876D2',
                        }).then((result) => {
                            if (result) {
                                props.onClose();
                            }
                        });
                    }
                })
                .catch((err) => {});
        }
    };

    const handleDelete = () => {
        // const { tableData, selected } = this.state;
        var temp = tableData.filter(
            (item, index) => !selected.find((itemToDelete) => itemToDelete === index)
        );

        if (selected.indexOf(0) > -1) {
            temp.splice(0, 1);
        }

        const times = temp.map((update) => update.working);
        const NonpayableTime = temp.map((update) => update.nonPayable);
        let totalNonPayMin = 0;
        NonpayableTime.forEach((non) => {
            if (non === '1/2 hour') {
                totalNonPayMin += 30;
            } else if (non === '1 hour') {
                totalNonPayMin += 60;
            }
        });
        const totalTime = getTotalWorkingHours(times, totalNonPayMin * 60);

        const newtable = temp.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });

        // this.setState({
        //     tableData: newtable,
        //     selected: [],
        //     totalHours: totalTime,
        // });
        setTableData(newtable);
        setSelected([]);
        setTotalHours(totalTime);
    };

    const onCheckBoxClicked = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    return (
        <div style={{ fontFamily: 'Libre Franklin', padding: 100, paddingTop: 70 }}>
            <div className="editDetailsContentHeading">Re-Hiring</div>
            {jobLoading && (
                <div style={{ textAlign: 'center', marginTop: '250px' }}>
                    <CircularProgress size={50} />
                </div>
            )}
            {!jobLoading && (
                <>
                    <div className="row" style={{ marginTop: 92 }}>
                        <div
                            className="col-md-4 col-sm-12"
                            style={{ color: '#707070', fontSize: 20 }}
                        >
                            Job Title:
                        </div>
                        <div
                            className="col-md-8 col-sm-12"
                            style={{ fontSize: 20, color: '#1c1c1c' }}
                        >
                            {jobData.title}
                        </div>
                    </div>
                    <div style={{ color: '#707070', fontSize: 20, fontWeight: 600, marginTop: 92 }}>
                        Provide Schedule of Work
                    </div>
                    <div className="row" style={{ marginTop: 35 }}>
                        <div className="col-md-3 col-sm-12">
                            <CentralizeRangeDatePicker
                                key={refreshData}
                                onChangeCallback={(dates) => {
                                    handleDateChange(dates);
                                }}
                                error={!isValidDate}
                                minDate={new Date(moment().add(2, 'days').toLocaleString())}
                                maxDate={new Date(moment().add(3, 'M').toLocaleString())}
                            />
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: 42,
                                }}
                            >
                                <TimePicker
                                    id={'startTime'}
                                    label={'Start Time'}
                                    widthParent={'60%'}
                                    width={'80%'}
                                    fontSize={Constant.FONT_SIZE_WEB}
                                    fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                                    fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                                    refreshData={refreshData}
                                    onChangeCallback={(time, timeObject, time24hr) => {
                                        handleStartTimeChange(time24hr);
                                    }}
                                    error={!isValidStartTime}
                                />
                                <TimePicker
                                    id={'endTime'}
                                    label={'End Time'}
                                    widthParent={'60%'}
                                    width={'80%'}
                                    fontSize={Constant.FONT_SIZE_WEB}
                                    fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                                    fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                                    refreshData={refreshData}
                                    onChangeCallback={(time, timeObject, time24hr) => {
                                        handleEndTimeChange(time24hr);
                                    }}
                                    error={!isValidEndTime}
                                />
                            </div>
                            <div style={{ paddingLeft: 42, marginTop: 24 }}>
                                <ContainedButtons
                                    text="Add to schedule"
                                    color={['#2493F1', '#15568D']}
                                    height={48}
                                    onClick={() => addToScheduleHandler()}
                                />
                            </div>
                        </div>
                        <div
                            className="col-md-4 col-sm-11"
                            style={{ fontSize: 20, color: '#707070' }}
                        >
                            <div style={{ marginBottom: 20, fontSize: 18 }}>Non-Payable Time</div>
                            <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={nonPayableTime}
                                onChange={(e) => handleNonPayableTime(e)}
                            >
                                <div>
                                    <FormControlLabel
                                        value="1/2 hour"
                                        control={<Radio color={Constant.COLOR_BLACK_300} />}
                                        label="1/2 hour"
                                    />
                                    <FormControlLabel
                                        value="1 hour"
                                        control={<Radio color={Constant.COLOR_BLACK_300} />}
                                        label="1 hour"
                                    />
                                    <FormControlLabel
                                        value="none"
                                        control={<Radio color={Constant.COLOR_BLACK_300} />}
                                        label="none"
                                    />
                                </div>
                            </RadioGroup>
                        </div>
                    </div>
                    <div style={{ marginTop: 67 }}>
                        <div className="scheduleTable" style={{ width: '66.66%', height: 345 }}>
                            <div
                                style={{
                                    marginTop: '1.5vw',
                                    marginLeft: '1.5vw',
                                    marginRight: '1.5vw',
                                }}
                                className="scheduleTableHeadings"
                            >
                                <div style={{ flex: 1 }} className="scheduleTableHeading">
                                    <DeleteIcon
                                        fontSize="inherit"
                                        onClick={() => handleDelete()}
                                        style={{ cursor: 'pointer', height: 20, width: 20 }}
                                    />
                                </div>
                                <div style={{ flex: 4 }} className="scheduleTableHeading">
                                    Date
                                </div>
                                <div style={{ flex: 3 }} className="scheduleTableHeading">
                                    Start
                                </div>
                                <div style={{ flex: 3 }} className="scheduleTableHeading">
                                    End
                                </div>
                                <div style={{ flex: 4 }} className="scheduleTableHeading">
                                    Non-Payable
                                </div>
                                <div style={{ flex: 4 }} className="scheduleTableHeading">
                                    Working Hours
                                </div>
                            </div>
                            <div className="scheduleTableHeadingsBottom"></div>
                            <div style={{ height: '310px', overflowX: 'auto' }}>
                                {tableData.map((item, id) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                borderBottom:
                                                    id + 1 === tableData.length
                                                        ? '0px solid #c7c7c7'
                                                        : '1px solid #c7c7c7',
                                                marginLeft: '1.5vw',
                                                width: '93%',
                                                height: 58,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    marginTop: 0,
                                                    lineHeight: '58px',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={selected.indexOf(id) > -1}
                                                    padding="0px"
                                                    onChange={() => onCheckBoxClicked(id)}
                                                    style={{ margin: 0 }}
                                                    color="#2699fb"
                                                />
                                            </div>
                                            <div
                                                className="sceduleListDate"
                                                style={{
                                                    flex: 4,
                                                    marginTop: 0,
                                                    lineHeight: '58px',
                                                }}
                                            >
                                                {moment(item.date).utc(0).format('MMM DD, YYYY')}
                                            </div>
                                            <div
                                                className="sceduleListStartTime"
                                                style={{
                                                    flex: 3,
                                                    marginTop: 0,
                                                    lineHeight: '58px',
                                                }}
                                            >
                                                {moment(item.startTime).utc(0).format('hh:mm A')}
                                            </div>
                                            <div
                                                className="sceduleListStartTime"
                                                style={{
                                                    flex: 3,
                                                    marginTop: 0,
                                                    lineHeight: '58px',
                                                }}
                                            >
                                                {moment(item.endTime).utc(0).format('hh:mm A')}
                                            </div>
                                            <div
                                                className="sceduleListStartTime"
                                                style={{
                                                    flex: 4,
                                                    marginTop: 0,
                                                    lineHeight: '58px',
                                                }}
                                            >
                                                {item.nonPayable}
                                            </div>
                                            <div
                                                className="sceduleListStartTime"
                                                style={{
                                                    flex: 4,
                                                    marginTop: 0,
                                                    lineHeight: '58px',
                                                }}
                                            >
                                                {item.working}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            borderBottom: '2px solid #C4C4C4',
                            paddingBottom: 66,
                            marginTop: 20,
                        }}
                    >
                        {noScheduleErr && (
                            <p style={{ color: 'red' }}>
                                There is nothing any schedule data found.
                            </p>
                        )}
                        <div
                            style={{
                                width: '66.66%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ fontSize: 20, color: '#707070', marginTop: 21 }}>
                                Total working Hours
                            </div>
                            <div style={{ width: 100 }}>
                                <TextField
                                    required={false}
                                    label=""
                                    defaultValue={''}
                                    variant="outlined"
                                    style={{ marginTop: 11 }}
                                    onChange={(e) => {}}
                                    placeholder=""
                                    value={totalHours}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 66 }}>
                        <div style={{ color: '#707070', fontSize: 20 }}>Hourly Rate</div>
                        <TextField
                            required={false}
                            variant="outlined"
                            onChange={(e) => {
                                setRateErr(false);
                                setRate(e.target.value);
                            }}
                            placeholder="Please Enter Hourly Rate"
                            value={rate}
                            style={{ width: 350, marginTop: 22 }}
                            error={rateErr}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: 51,
                            borderBottom: '2px solid #C4C4C4',
                            paddingBottom: 30,
                        }}
                    >
                        <div style={{ color: '#707070', fontSize: 20 }}>Offer Expiry Date</div>
                        <TextField
                            type="date"
                            width={'100%'}
                            onChange={(date) => {
                                setExpiryDate(date.target.value);
                                setIsValidExpiryDate(true);
                            }}
                            style={{ marginTop: 6, width: 350 }}
                            value={expiryDate}
                            inputProps={{
                                min: moment().add(1, 'days').format('YYYY-MM-DD'),
                                max:
                                    tableData.length > 0
                                        ? moment(tableData[0].date)
                                              .subtract(1, 'days')
                                              .format('YYYY-MM-DD')
                                        : null,
                            }}
                            error={!isValidExpiryDate}
                        />
                        <div style={{ marginTop: 30 }}>
                            <ContainedButtons
                                text="Send Re-scheduling Offer"
                                color={['#2493F1', '#15568D']}
                                height={48}
                                onClick={() => rehiringSubmit()}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: 50, fontFamily: 'Libre Franklin', width: '66.66%' }}>
                        <div style={{ color: '#9A9A9A', fontSize: 20 }}>Job Description</div>
                        <pre
                            style={{
                                marginTop: 7,
                                color: '#1c1c1c',
                                fontsize: 20,
                                fontFamily: 'Libre Franklin',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            {jobData && jobData.description}
                        </pre>
                        {jobData && jobData.additionalDetails && (
                            <>
                                <div style={{ color: '#9A9A9A', fontSize: 20, marginTop: 51 }}>
                                    Additional Instructions
                                </div>
                                <pre
                                    style={{
                                        marginTop: 30,
                                        color: '#1c1c1c',
                                        fontsize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {jobData && jobData.additionalDetails}
                                </pre>
                            </>
                        )}
                        <div style={{ marginTop: 60, fontFamily: 'Libre Franklin', fontSize: 20 }}>
                            <div className="row" style={{ marginBottom: 30 }}>
                                <div className="col-md-4 col-sm-12" style={{ color: '#9A9A9A' }}>
                                    Work Address:
                                </div>
                                <div className="col-md-8 col-sm-12" style={{ color: '#1c1c1c' }}>
                                    {jobData.remoteWorking
                                        ? 'Remote Working'
                                        : jobData.workAddress &&
                                          `${jobData.workAddress.unit_number + ', ' ?? ''}${
                                              jobData.workAddress.street_address + ', ' ?? ''
                                          }${jobData.workAddress.city + ', ' ?? ''}${
                                              jobData.workAddress.state + ', ' ?? ''
                                          }${jobData.workAddress.postal_code + ', ' ?? ''}${
                                              jobData.workAddress.country ?? ''
                                          }`}
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: 30 }}>
                                <div className="col-md-4 col-sm-12" style={{ color: '#9A9A9A' }}>
                                    Client Payment Method:
                                </div>
                                <div className="col-md-8 col-sm-12" style={{ color: '#1c1c1c' }}>
                                    {jobData && jobData.paymentMethod === 'DIRECT'
                                        ? 'Directly by Client'
                                        : 'Through Empolink'}
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: 90 }}>
                                <div className="col-md-4 col-sm-12" style={{ color: '#9A9A9A' }}>
                                    Payment Cycle:
                                </div>
                                <div className="col-md-8 col-sm-12" style={{ color: '#1c1c1c' }}>
                                    {jobData && jobData.paymentFrequency === 'WEEKLY'
                                        ? 'Weekly'
                                        : 'Bi-weekly'}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ReHiring;
