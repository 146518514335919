import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Button from './centralize_button';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Swal from 'sweetalert2';

export default function CentralizeTimePicker(props) {
    const hours = [
        { hour: '01' },
        { hour: '02' },
        { hour: '03' },
        { hour: '04' },
        { hour: '05' },
        { hour: '06' },
        { hour: '07' },
        { hour: '08' },
        { hour: '09' },
        { hour: '10' },
        { hour: '11' },
        { hour: '12' },
    ];

    const minutes = [{ minute: '00' }, { minute: '30' }];
    const amPM = [{ lable: 'AM' }, { lable: 'PM' }];

    const [time, setTime] = useState('');
    const [widthParent, setWidthParent] = useState('60%');
    const [width, setWidth] = useState('80%');

    const [fontSize, setFontSize] = useState('0.9375vw');
    const [fontSizeDropDown, setFontSizeDropDown] = useState('0.9375vw');

    const [fontSizeButton, setFontSizeButton] = useState('0.9375vw');
    const [marginRightButton, setMarginRightButton] = useState('0');
    const [borderRadiusButton, setBorderRadiusButton] = useState('0px');
    const [paddingButton, setPaddingButton] = useState('1px 2px');
    const [backgroundImageButton, setBackgroundImageButton] = useState(
        'linear-gradient(to bottom, #1876D2, #1876D2)'
    );
    const [colorButton, setColorButton] = useState('#ffffff');
    const [backgroundImageButtonCancel, setBackgroundImageButtonCancel] = useState(
        'linear-gradient(to bottom, #ffffff, #ffffff)'
    );
    const [colorButtonCancel, setColorButtonCancel] = useState('#2699fb');

    const [selectedHour, setSelectedHour] = useState('');
    const [selectedMinute, setSelectedMinute] = useState('');
    const [amPMValue, setAmPM] = useState('PM');

    const [isDropDownOpen, setDropDownState] = useState(false);
    const [isValidHour, setIsValidHour] = useState(true);
    const [isValidMin, setIsValidMin] = useState(true);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: widthParent,
        },

        inputText: {
            width: width,
            '& .MuiOutlinedInput-root': {
                fontFamily: 'Libre Franklin',
                fontSize: fontSize,
                fontWeight: 'normal',
                fontStretch: 'normal',
                fontStyle: 'normal',
                letterSpacing: 'normal',
                color: '#9a9a9a',
                borderRadius: '10px',
            },
            '& .MuiFormLabel-root': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                bottom: '0px',
                right: '22px',
            },
        },

        dropDownMenu: {
            position: 'absolute',
            marginTop: '5px',
            marginLeft: '5px',
            elevation: '10',
            zIndex: '5',
            borderRadius: '8px',
            '& .MuiListItem-root': {
                alignItems: 'flex-start',
            },
        },

        dropDownMenuScrollable: {
            minHeight: '0px',
            maxHeight: '200px',
            overflowY: 'scroll',
            marginLeft: '5px',
            paddingRight: '5px',
            whiteSpace: 'nowrap',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },

        dropDownMenuList: {
            '&:hover': {
                backgroundColor: '#ffffff',
            },
        },

        dropDownMenuTextHour: {
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#707070',
            padding: '10px 10px 10px 10px',
            marginTop: '2px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D22C',
                borderRadius: '5px',
                border: '0px solid  #707070',
            },
        },

        dropDownMenuTextHourSelected: {
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            padding: '10px 10px 10px 10px',
            overflow: 'hidden',
            marginTop: '2px',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: '#1876D2',
            borderRadius: '5px',
            border: '0px solid  #707070',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D2',
                borderRadius: '5px',
                border: '0px solid  #707070',
            },
        },

        dropDownMenuTextMinute: {
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#707070',
            padding: '10px 10px 10px 10px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D22C',
                borderRadius: '5px',
                border: '0px solid  #707070',
            },
        },

        dropDownMenuTextMinuteSelected: {
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            padding: '10px 10px 10px 10px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: '#1876D2',
            borderRadius: '5px',
            border: '0px solid  #707070',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D2',
                borderRadius: '5px',
                border: '0px solid  #707070',
            },
        },

        dropDownMenuTextAMPM: {
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#707070',
            padding: '10px 10px 10px 10px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D22C',
                borderRadius: '5px',
                border: '0px solid  #707070',
            },
        },

        dropDownMenuTextAMPMSelected: {
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'center',
            padding: '10px 10px 10px 10px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: '#1876D2',
            borderRadius: '5px',
            border: '0px solid  #707070',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D2',
                borderRadius: '5px',
                border: '0px solid  #707070',
            },
        },

        buttonParent: {
            padding: '0',
        },
        error: {
            border: '2px solid red',
            borderRadius: '12px',
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (props.width !== undefined) setWidth(props.width);
    }, [props.width]);

    useEffect(() => {
        if (props.widthParent !== undefined) setWidthParent(props.widthParent);
    }, [props.widthParent]);

    useEffect(() => {
        if (props.fontSize !== undefined) setFontSize(props.fontSize);
    }, [props.fontSize]);

    useEffect(() => {
        if (props.fontSizeDropDown !== undefined) setFontSizeDropDown(props.fontSizeDropDown);
    }, [props.fontSizeDropDown]);

    useEffect(() => {
        if (props.fontSizeButton !== undefined) setFontSizeButton(props.fontSizeButton);
    }, [props.fontSizeButton]);

    useEffect(() => {
        if (props.marginRightButton !== undefined) setMarginRightButton(props.marginRightButton);
    }, [props.marginRightButton]);

    useEffect(() => {
        if (props.borderRadiusButton !== undefined) setBorderRadiusButton(props.borderRadiusButton);
    }, [props.borderRadiusButton]);

    useEffect(() => {
        if (props.paddingButton !== undefined) setPaddingButton(props.paddingButton);
    }, [props.paddingButton]);

    useEffect(() => {
        if (props.backgroundImageButton !== undefined)
            setBackgroundImageButton(props.backgroundImageButton);
    }, [props.backgroundImageButton]);

    useEffect(() => {
        if (props.colorButton !== undefined) setColorButton(props.colorButton);
    }, [props.colorButton]);

    useEffect(() => {
        if (props.backgroundImageButtonCancel !== undefined)
            setBackgroundImageButtonCancel(props.backgroundImageButtonCancel);
    }, [props.backgroundImageButtonCancel]);

    useEffect(() => {
        if (props.colorButtonCancel !== undefined) setColorButton(props.colorButtonCancel);
    }, [props.colorButtonCancel]);

    useEffect(() => {
        if (props.selectedHour !== undefined) setSelectedHour(props.selectedHour);
    }, [props.selectedHour]);

    useEffect(() => {
        if (props.selectedMinute !== undefined) setSelectedMinute(props.selectedMinute);
    }, [props.selectedMinute]);

    useEffect(() => {
        if (props.refreshData) {
            setSelectedHour('');
            setSelectedMinute('');
            setAmPM('PM');
            setTime('');
            if (props.refreshCallback !== undefined) {
                props.refreshCallback();
            }
        }
    }, [props.refreshData]);

    useEffect(() => {
        if (props.value) {
          
            if (props.value && props.value.split(':').length === 2) {
                let hour = parseInt(props.value.split(':')[0]);
                let min = parseInt(props.value.split(':')[1]);
                min = min <= 9 ? `0${min}` : min;
                let timeSet = 'AM';
                if(hour > 12) {
                    hour = hour-12;
                    hour = hour <= 9 ? `0${hour}` : hour;
                    timeSet = 'PM';
                } else {
                    hour = hour <= 9 ? `0${hour}` : hour;
                }
                setAmPM(timeSet);
                setSelectedMinute(min.toString());
                setSelectedHour(hour);
                // setTime(props.value);
                setTime(
                    hour + ':' + min + ' ' + timeSet
                );
            }
        }
    }, [props.value]);

    return (
        <div className={classes.root}>
            <TextField
                id={props.id}
                label={props.label}
                variant="outlined"
                error={props.error || false}
                disabled={true}
                InputProps={{
                    shrink: true,
                    endAdornment: (
                        <InputAdornment>
                            <AccessTimeIcon />
                        </InputAdornment>
                    ),
                }}
                className={`${props.inputTextFiledType ?? classes.inputText} ${
                    props.error ? `${classes.error}` : ``
                }`}
                value={time}
                onChange={(event) => {
                    if (props.onChangeCallback !== undefined) {
                        props.onChangeCallback(event.target.value);
                    }
                    setTime(event.target.value);
                }}
                onClick={() => {
                    if (!isDropDownOpen) setDropDownState(true);
                    else setDropDownState(false);
                }}
            />

            {isDropDownOpen ? (
                <Card elevation={5} className={classes.dropDownMenu}>
                    <List disablePadding>
                        <ListItem disableGutters={true} className={classes.dropDownMenuList}>
                            <div
                                className={`${classes.dropDownMenuScrollable} ${
                                    !isValidHour ? `${classes.error}` : ``
                                }`}
                            >
                                {hours.map((data, index) => (
                                    <div
                                        key={index}
                                        className={
                                            data.hour === selectedHour
                                                ? classes.dropDownMenuTextHourSelected
                                                : classes.dropDownMenuTextHour
                                        }
                                        onClick={() => {
                                            setSelectedHour(data.hour);
                                        }}
                                    >
                                        {data.hour}
                                    </div>
                                ))}
                            </div>

                            <div
                                className={`${classes.dropDownMenuScrollable} ${
                                    !isValidMin ? `${classes.error}` : ``
                                }`}
                            >
                                {minutes.map((data, index) => (
                                    <div
                                        className={
                                            data.minute === selectedMinute
                                                ? classes.dropDownMenuTextHourSelected
                                                : classes.dropDownMenuTextMinute
                                        }
                                        key={index}
                                        onClick={() => {
                                            setSelectedMinute(data.minute);
                                        }}
                                    >
                                        {data.minute}
                                    </div>
                                ))}
                            </div>
                            <div className={classes.dropDownMenuScrollable}>
                                {amPM.map((data, index) => (
                                    <div
                                        key={index}
                                        className={
                                            data.lable === amPMValue
                                                ? classes.dropDownMenuTextAMPMSelected
                                                : classes.dropDownMenuTextAMPM
                                        }
                                        onClick={() => {
                                            setAmPM(data.lable);
                                        }}
                                    >
                                        {data.lable}
                                    </div>
                                ))}
                            </div>
                        </ListItem>

                        <ListItem
                            disableGutters={true}
                            disablePadding
                            className={classes.buttonParent}
                        >
                            <Button
                                lable={'Cancel'}
                                fullWidthButton={true}
                                fontSize={fontSizeButton}
                                marginRight={marginRightButton}
                                borderRadius={borderRadiusButton}
                                padding={paddingButton}
                                backgroundImage={backgroundImageButtonCancel}
                                color={colorButtonCancel}
                                onClickCallback={(value) => {
                                    setDropDownState(false);
                                }}
                            />
                            <Button
                                lable={'Apply'}
                                fullWidthButton={true}
                                fontSize={fontSizeButton}
                                marginRight={marginRightButton}
                                borderRadius={borderRadiusButton}
                                padding={paddingButton}
                                backgroundImage={backgroundImageButton}
                                color={colorButton}
                                onClickCallback={(value) => {
                                    if (selectedHour === '') {
                                        setIsValidHour(false);
                                    } else if (selectedMinute === '') {
                                        setIsValidMin(false);
                                    } else {
                                        setIsValidHour(true);
                                        setIsValidMin(true);
                                        setTime(
                                            selectedHour + ':' + selectedMinute + ' ' + amPMValue
                                        );
                                        setDropDownState(false);
                                        if (props.onChangeCallback !== undefined) {
                                            var hour;
                                            if (amPMValue === 'AM') {
                                                hour = selectedHour;
                                            } else {
                                                hour = 12 + parseInt(selectedHour);
                                            }
                                            props.onChangeCallback(
                                                selectedHour +
                                                    ':' +
                                                    selectedMinute +
                                                    ' ' +
                                                    amPMValue,
                                                {
                                                    hour: selectedHour,
                                                    minute: selectedMinute,
                                                    format: amPMValue,
                                                },
                                                hour.toString() + ':' + selectedMinute
                                            );
                                        }
                                    }
                                }}
                            />
                        </ListItem>
                    </List>
                </Card>
            ) : (
                <div />
            )}
        </div>
    );
}
