export const COLOR_TYPE = {
    GREEN: "#6CA417",
    ORANGE: "#DEB136",
    RED: "#DB6224",
    BLUE: "#1885D9",
    GREY: "#E6E6E6"
};

export const types = Object.freeze({
    HourlyAndFixedJob: "HourlyAndFixedJob",
    JobPostAndInvitation: "JobPostAndInvitation",
    FixedFeeJobs: "FixedFeeJobs",
    TimeSheetReview: "TimeSheetReview"
});

export const HourlyAndFixedJob = Object.freeze({
    IN_PROGRESS: "IN_PROGRESS",
    NOT_STARTED: "NOT_STARTED",
    CANCELLED: "CANCELLED",
    TIME_LEFT: "TIME_LEFT",
    COMPLETED: "COMPLETED"
});

export const JobPostAndInvitation = Object.freeze({
    JOB_EXPIRED: "JOB_EXPIRED",
    TIME_LEFT: "TIME_LEFT",
    ACTIVE: "ACTIVE"
});

export const TimeSheetReview = Object.freeze({
    TIME_LEFT: "TIME_LEFT",
    TIME_MODIFIED: "TIME_MODIFIED",
});



