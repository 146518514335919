import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainedButtons from '../../../../Components/Button/Button';
import TextField from '../../../../Components/TextField/Textfield';
import { APIHelper } from '../../../../utils/ApiHelper';
import API from '../../../../utils/Urls';
import { Radio } from '@material-ui/core';

const CancelApplicationFormFixed = (props) => {
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [inProgressMilestone, setInProgressMilestone] = React.useState();
    const [agree, setAgree] = React.useState(true);
    const [mileStone, setMileStone] = React.useState([]);
    const [mileStoneAmount, setMileStoneAmount] = React.useState(0);
    const [inProgressMileStoneErr, setInProgressMileStoneErr] = React.useState(false);

    const local_currency_symbol=localStorage.getItem('local_currency_symbol')
    
    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
            .then((res) => {
                setData(res[0]);
                setLoading(false);

                if (
                    res &&
                    res.length > 0 &&
                    res[0].milestone &&
                    res[0].milestone.length > 0 &&
                    res[0].jobs &&
                    res[0].jobs.length > 0
                ) {
                    const startDate = moment(res[0].jobs[0].startDate);
                    const milestone = res[0].milestone;

                    if (startDate < moment()) {
                        const currentMileStone = milestone.filter(
                            (x) =>
                                moment(x.dateSchedule) >= startDate &&
                                moment(x.dateSchedule) >= moment()
                        );

                        if (currentMileStone && currentMileStone.length > 0) {
                            const amount =
                                currentMileStone &&
                                currentMileStone.length > 0 &&
                                currentMileStone.map((x) => x.amount);
                            setMileStoneAmount(amount[0]);
                            setMileStone([currentMileStone[0]]);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
            .then((res) => {
                setData(res[0]);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const onConfirmCancel = () => {
        if (agree !== undefined) {
            if (agree === false && !parseFloat(inProgressMilestone)) {
                setInProgressMileStoneErr(true);
            } else {
                let body = {};
                body.inProgressMilestoneByEmployer =
                    agree === true
                        ? `${data.inProgressMilestoneByEmployee}% mile stone done`
                        : `${inProgressMilestone}% mile stone done`;
                body.finalMileStoneAmountByEmployer =
                    agree === true
                        ? parseFloat(data.inProgressMilestoneByEmployee) *
                          parseFloat(mileStoneAmount) *
                          parseFloat(0.01)
                        : parseFloat(inProgressMilestone) *
                          parseFloat(mileStoneAmount) *
                          parseFloat(0.01);
                props.finalCancelJob(body);
            }
        }
    };

    return (
        <React.Fragment>
            <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                        Job Cancellation Request
                    </h3>
                </div>
                {loading ? (
                    <div></div>
                ) : data !== undefined ? (
                    <div style={{ marginTop: 64, fontSize: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Contract No:</p>
                            <p>{data.contractNumber}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a', lineHeight: '70px' }}>
                                Client:
                            </p>
                            <AccountCircleIcon
                                style={{
                                    height: '70px',
                                    width: '70px',
                                }}
                            />
                            <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                                {data.employerProfileData &&
                                    data.employerProfileData.length > 0 &&
                                    data.employerProfileData[0].company &&
                                    data.employerProfileData[0].company.name}
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 14 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                            <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Talent Name:</p>
                            <p>{`${data.employeeData[0].firstName} ${data.employeeData[0].lastName}`}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                            <p>{data.jobs[0].title}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                Cancellation date and time:
                            </p>
                            <p>{moment(data.updatedAt).format('hh:mm A - MMM DD, YY')}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work Start Date:</p>
                            <p>{moment(data.jobs[0].startDate).utc(0).format('MMM DD, YYYY')}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 30,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: 46,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work End Date:</p>
                            <p>{moment(data.jobs[0].endDate).utc(0).format('MMM DD, YYYY')}</p>
                        </div>
                        <div
                            style={{
                                marginTop: 59,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: '50px',
                            }}
                        >
                            <p style={{ minWidth: 350, color: '#9a9a9a' }}>Cancellation Reason:</p>
                            <p style={{ marginTop: 15 }}>{data.reasonType && data.reasonType}</p>
                            {data.reason && (
                                <>
                                    <p style={{ minWidth: 350, color: '#9a9a9a', marginTop: 30 }}>
                                        Cancellation reason:
                                    </p>
                                    <p style={{ marginTop: 15 }}>{data.reason}</p>
                                </>
                            )}
                        </div>
                        <div style={{ marginTop: 59 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Current Milestone:</p>
                            <div
                                style={{
                                    border: '2px solid #C4C4C4',
                                    borderRadius: '15px',
                                    marginTop: '15px',
                                    paddingRight: '10px',
                                    paddingLeft: '20px',
                                }}
                            >
                                <table
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        borderCollapse: 'collapse',
                                        fontFamily: 'Libre Franklin',
                                    }}
                                    className="table-small-row-font"
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                height: 60,
                                                borderBottom: '2px solid #C4C4C4',
                                            }}
                                        >
                                            <th style={{ width: '25%', color: '#1c1c1c' }}>
                                                Milestones
                                            </th>
                                            <th style={{ width: '25%', color: '#1c1c1c' }}>
                                                Status
                                            </th>
                                            <th style={{ width: '25%', color: '#1c1c1c' }}>
                                                Amount
                                            </th>
                                            <th
                                                style={{
                                                    width: '20%',
                                                    color: '#1c1c1c',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                Payment Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mileStone.map((element, index) => {
                                            return (
                                                <tr
                                                    style={{
                                                        height: 60,
                                                        // borderBottom: '2px solid #C4C4C4',
                                                        backgroundColor: 'inherit',
                                                    }}
                                                >
                                                    <td style={{ width: '25%', color: '#1c1c1c' }}>
                                                        {element.description}
                                                    </td>
                                                    <td style={{ width: '25%', color: '#1c1c1c' }}>
                                                        In Progress
                                                    </td>
                                                    <td style={{ width: '25%', color: '#1c1c1c' }}>
                                                        ${element.amount}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '20%',
                                                            color: '#1c1c1c',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        {moment(element.dateSchedule)
                                                            .utc(0)
                                                            .format('MMM DD, YYYY')}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: 30,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: '59px',
                            }}
                        >
                            <p style={{ marginTop: 15, color: '#9a9a9a' }}>
                                Proposed percentage of work completed for “In Progress” milestone by
                                talent
                            </p>
                            <p style={{ marginTop: 30 }}>{data.inProgressMilestoneByEmployee}</p>
                            <p style={{ marginTop: 15 ,color: '#808080'}}>
                                <Radio
                                    // checked={agree === true}
                                    checked={true}
                                    disabled={true}
                                    onChange={() => setAgree(true)}
                                    value="agree"
                                    name="radio-buttons"
                                />
                                I agree with proposed percentage for “In Progress” milestone
                            </p>
                            <p style={{ marginTop: 15,color: '#808080' }}>
                                <Radio
                                    // checked={agree === false}
                                    checked={false}
                                    disabled={true}
                                    onChange={() => {
                                        setAgree(false);
                                        setInProgressMileStoneErr(false);
                                    }}
                                    value="disagree"
                                    name="radio-buttons"
                                />
                                I disagree and propose a revised percentage for “In Progress
                                milestone
                            </p>
                            <div style={{ marginTop: 28 }}>
                                <TextField
                                    placeholder="XX%"
                                    value={inProgressMilestone}
                                    onChange={(e) => {
                                        setInProgressMileStoneErr(false);
                                        setInProgressMilestone(e.target.value);
                                    }}
                                    // disabled={agree === true}
                                    disabled={true}
                                    error={inProgressMileStoneErr}
                                />
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 380 }}>Final Milestone Amount:</p>
                                <p style={{ width: 380, fontSize: 18 }}>
                                    {agree !== undefined
                                        ? agree === false
                                            ? `${data.jobs[0].jobLocationType==="LOCAL"?local_currency_symbol:"$"}
                                            ${
                                                (parseFloat(inProgressMilestone) *
                                                      parseFloat(mileStoneAmount) *
                                                      parseFloat(0.01)).toFixed(2) || '00.00'
                                              } ${data.jobs[0].currency}`
                                            : `${data.jobs[0].jobLocationType==="LOCAL"?local_currency_symbol:"$"}
                                            ${
                                                  (parseFloat(data.inProgressMilestoneByEmployee) *
                                                      parseFloat(mileStoneAmount) *
                                                      parseFloat(0.01)).toFixed(2)|| '00.00'
                                              } ${data.jobs[0].currency}`
                                        : `${data.jobs[0].jobLocationType==="LOCAL"?
                                                    local_currency_symbol:"$"}00.00 ${data.jobs[0].currency}`}
                                </p>
                            </div>
                            <p style={{ marginTop: 8, color: '#9a9a9a', fontSize: 18 }}>
                                (Percentage of work completed multiplied by current milestone
                                amount)
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '41px 30px',
                                backgroundColor: '#d5d5d5',
                                borderRadius: 10,
                                marginTop: 59,
                            }}
                        >
                            <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                                Note: This cancellation as submitted by talent is final. You are
                                requested to provide your opinion below as Yes or No as it will
                                impact talent’s credit rating.
                            </p>
                        </div>
                        <div style={{ marginTop: 60 }}>
                            <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                                Are you in agreement with the talent?
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <div style={{ marginRight: 10 }}>
                                <ContainedButtons
                                    text="No"
                                    color={['#2699fb', '#134d7e']}
                                    height="56px"
                                    width={'12vw'}
                                    variant="outlined"
                                    onClick={() => props.finalCancelContractJob(props.contractId)}
                                />
                            </div>
                            <ContainedButtons
                                text="Yes"
                                height="56px"
                                width={'12vw'}
                                color={['#2699fb', '#134d7e']}
                                onClick={() => onConfirmCancel()}
                                disabled={agree === undefined}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

export default CancelApplicationFormFixed;
