import React from 'react';
import { useEffect, useState } from 'react';
import DatePicker from "react-multi-date-picker"
import Text from './text'
import InputText from './centralize_input_text'

import { SUBHEADING_WITH_BORDER_FIXED_WIDTH } from '../../../utils/enums';


export default function CentralizeDatePicker(props) {
  const [dates, setDates] = useState("")
  const [width, setWidth] = useState("40%");
  const [fontSize, setFontSize] = useState("0.9375vw");


  useEffect(() => {
    if (props.width !== undefined)
      setWidth(props.width);
  }, [props.width]);

  useEffect(() => {
    if (props.fontSize !== undefined)
      setFontSize(props.fontSize);
  }, [props.fontSize]);


  useEffect(() => {
    if (props.refreshData) {
      setDates("");
      if (props.refreshCallback !== undefined) {
        props.refreshCallback();
      }
    }
  }, [props.refreshData]);

  useEffect(() => {
    if (props.selectedDate !== undefined) {
      setDates(props.selectedDate);
    }
  }, [props.selectedDate !== undefined ? props.selectedDate : ""]);


  return (
    <div className={dates.length === 1 ? "React-DatePicker-Single" : "React-DatePicker"}  >
      <DatePicker
        value={dates}
        format={"MMM DD,YYYY"}
        minDate={props.startDate}
        maxDate={props.endDate}
        disabled={props.readOnly === undefined ? false : props.readOnly}
        render={(value, openCalendar) => {
          return (
            <InputText
              id={props.id ?? ""} label={props.lable ?? ""}
              disabled={props.readOnly === undefined ? false : props.readOnly}
              error={props.error || false}
              fontSize={fontSize}
              width={width}
              onClickCallback={openCalendar}
              text={value === "" ? "" : value}
            />
          )
        }}
        weekStartDayIndex={1}
        onChange={dateObjects => {
          setDates(dateObjects)
          if (props.onChangeCallback != undefined) {
            props.onChangeCallback(dateObjects);
          }
        }}
        mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
          let props = {}
          props.style = {}

          let isSunday = [6].includes(date.weekDay.index)
          if (isSunday) props.style.color = "#A7B9F0"

          let isSaturday = [0].includes(date.weekDay.index)
          if (isSaturday) props.style.color = "#FF6464"



          if (isSameDate(date, today)) props.style.color = "#455997"
          if (isSameDate(date, today)) props.style.background = "rgb(255 255 255)"
          if (isSameDate(date, today)) props.style.border = "1px solid #9A9A9A"
          return props
        }}
      />
    </div>

  );
}
