import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import ContainedButtons from '../../../Components/Button/Button';
import Card from '@material-ui/core/Card';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import TextField from '../../../Components/TextField/Textfield';
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
} from 'recharts';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import FinanceReport from './FinanceReport';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MonthYearPicker from '../../../Components/MonthPicker/MonthPicker';

const Finance = () => {
    const [buttonColor, setButtonColor] = React.useState(() => {
        return ['#a4c772', '#4e8400'];
    });

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [data, setData] = React.useState();

    const [loading, setLoading] = React.useState(false);
    const [contractType, setContractType] = React.useState('ALL');
    const [employerName, setEmployerName] = React.useState();
    const [jobTitle, setJobTitle] = React.useState();
    const [startDate, setStartDate] = React.useState('01-1970');
    const [endDate, setEndDate] = React.useState();
    const [localHiresSortBy, setLocalHiresSortBy] = React.useState();
    const [internationalHiresSortBy, setInternationalHiresSortBy] = React.useState();

    React.useEffect(() => {
        getFinanceData();
    }, []);

    const getFinanceData = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        setLoading(true);
        let start = moment(
            `${parseInt(startDate.split('-')[1])}-${parseInt(startDate.split('-')[0])}-01`
        ).add(1, 'day');
        let end = endDate
            ? new Date(parseInt(endDate.split('-')[1]), parseInt(endDate.split('-')[0]), 1, 0, 0, 0)
            : null;

        const data = {
            contractType,
            employerName: employerName && employerName.trim(),
            jobTitle: jobTitle && jobTitle.trim(),
            startDate: start,
            endDate: end,
        };
        APIHelper.postMethod(API.GET_EMPLOYEE_FINANCE, data, headers)
            .then((response) => {
                setData(response);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <div style={{ padding: '0 40px' }}>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}
            >
                <h1 style={{ fontSize: '36px', color: '#070707', margin: 0, fontWeight: 'normal' }}>
                    Finance
                </h1>
                <ContainedButtons
                    text="View Report"
                    color={buttonColor}
                    height={48}
                    width={'12vw'}
                    onClick={() => {
                        setDrawerOpen(true);
                    }}
                />
            </div>
            <div className="card-override">
                <Card style={{ marginTop: '50px', borderRadius: '18px', padding: 30 }}>
                    <div className="row">
                        <div
                            className="col-md-4"
                            style={{ color: '#9a9a9a', display: 'flex', alignItems: 'center' }}
                        >
                            <p style={{ margin: '0' }}>Period Start Date:</p>
                            <div
                                style={{ width: '150px', marginLeft: 10 }}
                                className="monthYearPicker"
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MonthYearPicker
                                        startMonth={1}
                                        startYear={1970}
                                        fieldLabel={'From'}
                                        endMonth={new Date().getMonth() + 1}
                                        endYear={new Date().getFullYear()}
                                        onSelected={(val) => {
                                            setStartDate(val);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div
                            className="col-md-4"
                            style={{ color: '#9a9a9a', display: 'flex', alignItems: 'center' }}
                        >
                            <p style={{ margin: '0' }}>Period End Date:</p>
                            <div
                                style={{ width: '150px', marginLeft: 10 }}
                                className="monthYearPicker"
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MonthYearPicker
                                        startMonth={1}
                                        startYear={1970}
                                        fieldLabel={'End'}
                                        endMonth={moment().month()}
                                        endYear={moment().year()}
                                        onSelected={(val) => {
                                            setEndDate(val);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 40 }}>
                        <div className="col-md-3">
                            <p style={{ color: '#707070', fontWeight: 500, marginBottom: 10 }}>
                                Job Title
                            </p>
                            <div style={{ height: 40 }}>
                                <TextField
                                    className="textField"
                                    width={'100%'}
                                    value={jobTitle}
                                    onChange={(e) => {
                                        setJobTitle(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3" style={{ marginLeft: 20 }}>
                            <p style={{ color: '#707070', fontWeight: 500, marginBottom: 10 }}>
                                Client Name
                            </p>
                            <TextField
                                width={'100%'}
                                className="textField"
                                height={42}
                                value={employerName}
                                onChange={(e) => {
                                    setEmployerName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-md-3">
                            <p style={{ color: '#707070', fontWeight: 500, marginBottom: 10 }}>
                                Contract Type
                            </p>
                            <div style={{ width: '200px', marginLeft: 10 }}>
                                <Dropdown
                                    placeholder={'All jobs'}
                                    height={40}
                                    options={[
                                        { label: 'All jobs', value: 'ALL' },
                                        { label: 'Fixed', value: 'FIXED' },
                                        { label: 'Hourly', value: 'HOURLY' },
                                    ]}
                                    onChange={({ value }) => {
                                        setContractType(value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-3" style={{ marginTop: '30px' }}>
                            <ContainedButtons
                                text="Apply"
                                color={buttonColor}
                                height={40}
                                width={'12vw'}
                                onClick={() => {
                                    getFinanceData();
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 50 }}>
                <Card style={{ width: '49%', marginRight: 20, borderRadius: 18, padding: '30px' }}>
                    <p style={{ color: '#070707', fontWeight: 400 }}>Local Summary</p>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Total Job Value:</p>
                        <p>
                            ({localStorage.getItem('localCurrency')}){' '}
                            {data && data.totalLocalJobsValue}
                        </p>
                    </div>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Total Service Fee:</p>
                        <p>
                            ({localStorage.getItem('localCurrency')}){' '}
                            {data && data.totalLocalServiceValue}
                        </p>
                    </div>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Total Cost:</p>
                        <p>
                            ({localStorage.getItem('localCurrency')}){' '}
                            {data &&
                                parseFloat(
                                    parseFloat(data.totalLocalJobsValue) -
                                        parseFloat(data.totalLocalServiceValue)
                                ).toFixed(2)}
                        </p>
                    </div>
                </Card>
                <Card style={{ width: '49%', marginRight: 20, borderRadius: 18, padding: '30px' }}>
                    <p style={{ color: '#070707', fontWeight: 400 }}>International Summary</p>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Total Job Value:</p>
                        <p>${data && data.totalInternationalJobsValue}</p>
                    </div>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Total Service Fee:</p>
                        <p>${data && data.totalInternationalServicevalue}</p>
                    </div>
                    <div style={{ display: 'flex', margin: '20px 0' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Total Cost:</p>
                        <p>
                            $
                            {data &&
                                parseFloat(
                                    parseFloat(data.totalInternationalJobsValue) -
                                        parseFloat(data.totalInternationalServicevalue)
                                ).toFixed(2)}
                        </p>
                    </div>
                </Card>
            </div>
            <Card style={{ marginTop: 50, borderRadius: 18, padding: 30 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <p style={{ color: '#9a9a9a', fontWeight: 500, fontSize: 24 }}>Local Hires</p>
                    <div
                        style={{
                            width: 220,
                            marginLeft: 10,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <p style={{ color: '#9a9a9a' }}>Sort by:</p>
                        <div style={{ width: 140, marginLeft: 10 }}>
                            <Dropdown
                                height={40}
                                options={[
                                    { label: 'Date(most recent)', value: 'createdAt' },
                                    {
                                        label: 'Job Title',
                                        value: 'jobTitle',
                                    },
                                    {
                                        label: 'Client',
                                        value: 'employer',
                                    },
                                ]}
                                onChange={({ value }) => setLocalHiresSortBy(value)}
                            />
                        </div>
                    </div>
                </div>
                <table
                    style={{
                        borderCollapse: 'collapse',
                        fontFamily: 'Libre Franklin',
                        width: '100%',
                    }}
                >
                    <thead>
                        <tr
                            style={{
                                borderBottom: '2px solid #C4C4C4',
                                height: 60,
                                textAlign: 'left',
                            }}
                        >
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Job Title
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Client
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Contract Type
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Contract No.
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Payment Type
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Job Value
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Service Fee
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Total Cost
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.localHires.length > 0
                            ? data.localHires
                                  .sort((a, b) =>
                                      a[localHiresSortBy] !== b[localHiresSortBy]
                                          ? a[localHiresSortBy] < b[localHiresSortBy]
                                              ? -1
                                              : 1
                                          : 0
                                  )
                                  .map((item) => {
                                      return (
                                          <tr
                                              style={{
                                                  borderBottom: '2px solid #C4C4C4',
                                                  height: 60,
                                                  textAlign: 'left',
                                              }}
                                          >
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.jobTitle}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.employer}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.contractType}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.contractNumber}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.paymentType}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  ${item.jobValue}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  ${item?.serviceFee || 0}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  $
                                                  {parseFloat(
                                                      parseFloat(item.jobValue) -
                                                          parseFloat(item?.serviceFee || 0)
                                                  ).toFixed(2)}
                                              </td>
                                          </tr>
                                      );
                                  })
                            : null}
                        {data && data.localHires.length > 0 && (
                            <tr style={{ height: 60, textAlign: 'left' }}>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                    ${data.totalLocalJobsValue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 16,
                                    }}
                                >
                                    ${data.totalLocalServiceValue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 16,
                                    }}
                                >
                                    $
                                    {parseFloat(data.totalLocalJobsValue) -
                                        parseFloat(data.totalLocalServiceValue)}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Card>

            <Card style={{ marginTop: 50, borderRadius: 18, padding: 30 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <p style={{ color: '#9a9a9a', fontWeight: 500, fontSize: 24 }}>
                        International Hires
                    </p>
                    <div
                        style={{
                            width: 220,
                            marginLeft: 10,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <p style={{ color: '#9a9a9a' }}>Sort by:</p>
                        <div style={{ width: 140, marginLeft: 10 }}>
                            <Dropdown
                                height={40}
                                options={[
                                    { label: 'Date(most recent)', value: 'createdAt' },
                                    {
                                        label: 'Job Title',
                                        value: 'jobTitle',
                                    },
                                    {
                                        label: 'Client',
                                        value: 'employer',
                                    },
                                ]}
                                onChange={({ value }) => setInternationalHiresSortBy(value)}
                            />
                        </div>
                    </div>
                </div>
                <table
                    style={{
                        borderCollapse: 'collapse',
                        fontFamily: 'Libre Franklin',
                        width: '100%',
                    }}
                >
                    <thead>
                        <tr
                            style={{
                                borderBottom: '2px solid #C4C4C4',
                                height: 60,
                                textAlign: 'left',
                            }}
                        >
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Job Title
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Client
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Contract Type
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Contract No.
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Payment Type
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Job Value
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Service Fee
                            </th>
                            <th style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                Total Cost
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.internationalHires.length > 0
                            ? data.internationalHires
                                  .sort((a, b) =>
                                      a[internationalHiresSortBy] !== b[internationalHiresSortBy]
                                          ? a[internationalHiresSortBy] <
                                            b[internationalHiresSortBy]
                                              ? -1
                                              : 1
                                          : 0
                                  )
                                  .map((item) => {
                                      return (
                                          <tr
                                              style={{
                                                  borderBottom: '2px solid #C4C4C4',
                                                  height: 60,
                                                  textAlign: 'left',
                                              }}
                                          >
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.jobTitle}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.employee}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.contractType}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.contractNumber}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  {item.paymentType}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  ${item.jobValue}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  ${item?.serviceFee || 0}
                                              </td>
                                              <td
                                                  style={{
                                                      width: '13%',
                                                      color: '#1c1c1c',
                                                      fontSize: 16,
                                                  }}
                                              >
                                                  $
                                                  {parseFloat(
                                                      parseFloat(item.jobValue) -
                                                          parseFloat(item?.serviceFee || 0)
                                                  ).toFixed(2)}
                                              </td>
                                          </tr>
                                      );
                                  })
                            : null}
                        {data && data.internationalHires.length > 0 ? (
                            <tr style={{ height: 60, textAlign: 'left' }}>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 16 }}>
                                    ${data.totalInternationalJobsValue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 16,
                                    }}
                                >
                                    ${data.totalInternationalServicevalue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 16,
                                    }}
                                >
                                    $
                                    {parseFloat(data.totalInternationalJobsValue) -
                                        parseFloat(data.totalInternationalServicevalue)}
                                </td>
                            </tr>
                        ) : null}
                    </tbody>
                </table>
            </Card>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 50 }}>
                <Card
                    style={{
                        width: '49%',
                        marginRight: 20,
                        borderRadius: 18,
                        padding: '30px 15px',
                    }}
                >
                    <div style={{ height: 350 }}>
                        <p
                            style={{
                                fontSize: 19,
                                fontFamily: 'Libre Franklin',
                                color: '#070707',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            Total Cost <br />
                            Local Jobs{' '}
                            <span style={{ color: '#9a9a9a' }}>
                                ({localStorage.getItem('localCurrency')})
                            </span>
                        </p>
                        <ResponsiveContainer width="99%" height={270}>
                            <BarChart
                                data={data && data.localJobsChartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#621EEE" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
                <Card style={{ width: '49%', borderRadius: 18, padding: '30px 15px' }}>
                    <div style={{ height: 350 }}>
                        <p
                            style={{
                                fontSize: 19,
                                fontFamily: 'Libre Franklin',
                                color: '#070707',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            Total Cost <br />
                            International Jobs <span style={{ color: '#9a9a9a' }}>(USD)</span>
                        </p>
                        <ResponsiveContainer width="99%" height={270}>
                            <BarChart
                                data={data && data.internationalJobsChartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#DEB136" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <FinanceReport
                        onClose={() => setDrawerOpen(false)}
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                    />
                }
                width="80%"
            />
        </div>
    );
};

export default Finance;
