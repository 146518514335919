import React, { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";

import "../../HiringProcess.scss";

import Dropdown from "../../../../Components/DropdownComponent/Dropdown.component";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import ContainedButtons from "../../../../Components/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DrawerComponent from "../../../../Components/Drawer/Drawer";
import TimeSheetForm from "./TimeSheetForm";
import { APIHelper } from "../../../../utils/ApiHelper";
import API from "../../../../utils/Urls";

const TimeSheetReview = (props) => {
  const [reviewData, setReviewData] = useState(props.reviewData);
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [reasonWorkModify, setReasonWorkModify] = useState("");
  const [week, setWeek] = useState("");
  const [weekCount, setWeekCount] = useState(0);
  const [editTimeSheet, setEditTimeSheet] = useState(false);
  const [scheduleDate, setScheduleDate] = React.useState([]);
  const [weekStarts, setWeekStart] = React.useState(moment());
  const [weekEnds, setWeekEnd] = React.useState(moment());
  const [sortBy, setSortBy] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [currentTimeSheetDate, setCurrentTimeSheetDate] = React.useState([]);
  const [modifiedButtonDisabled, setModifiedButtonDisabled] =
    React.useState(false);

  useEffect(() => {
    setWeek(getCurrentWeek());
  }, []);

  useEffect(() => {
    setScheduleDate(reviewData.schedule);
    setTimeSheetData(reviewData.timesheet);
  }, [reviewData]);

  useEffect(() => {
    setWeek(getCurrentWeek());
  }, [weekCount]);

  useEffect(() => {
    let dates = enumerateDaysBetweenDates(weekStarts, weekEnds);
    setCurrentTimeSheetDate(dates);
  }, [weekStarts, weekEnds, reviewData.schedule]);

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];
    reviewData &&
      reviewData.timesheet &&
      reviewData.timesheet.length > 0 &&
      reviewData.timesheet.forEach((date) => {
        if (
          moment(date.dateSchedule) >= moment(startDate) &&
          (moment(date.dateSchedule).utc(0) <= moment(endDate).utc(0) ||
            moment(date.dateSchedule).utc(0).format("DD-MM-YYYY") ===
              moment(endDate).utc(0).format("DD-MM-YYYY"))
        ) {
          dates.push(date);
        }
      });
    return dates;
  };

  const getReturnStatus = (status, startDate, timeLeft) => {
    switch (status) {
      case "COMPLETED":
        return { status: "COMPLETED" };
      case "CANCELLATION_REQUESTED":
        return { status: "CANCELLED" };
      case "CANCELLED":
        return { status: "CANCELLED" };
      case "STARTED":
        const isStart = moment(startDate).utc(0) >= moment();
        if (isStart) {
          return { status: "NOT_STARTED" };
        } else {
          return { status: "IN_PROGRESS" };
        }
      default:
        return { status: "IN_PROGRESS" };
    }
  };

  const getCurrentWeek = () => {
    const weekStart = moment()
      .subtract(weekCount, "weeks")
      .startOf("week")
      .add(1, "day");
    const weekEnd = moment()
      .subtract(weekCount, "weeks")
      .endOf("week")
      .add(1, "day");
    setWeekStart(weekStart);
    setWeekEnd(weekEnd);

    return weekStart.format("MMM D, YY") + " - " + weekEnd.format("MMM D, YY");
  };

  const onSelectChange = (selected, index) => {
    const data = currentTimeSheetDate;
    data[index].selectedValue = selected.value;
    setCurrentTimeSheetDate([...data]);
    };

  const onChange = (e, index) => {
    const data = currentTimeSheetDate;
    data[index].totalHours = e.target.value;
    setCurrentTimeSheetDate([...data]);
  };

  const updateTimeSheet = (timeSheetData) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let data = {
      timesheet: timeSheetData,
      otherModifyReason: reasonWorkModify,
    };
    APIHelper.putMethod(
      `${API.UPDATE_TIMESHEET}${reviewData._id}`,
      data,
      headers
    )
      .then((res) => {
        setReviewData({...reviewData,timesheet:timeSheetData})
        setEditTimeSheet(false);
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Timesheet reviews are updated successfully.",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="editDetailsContentHeading">Time Sheet Review</div>
      <div className="timeSheetInstrction">
        You don’t need to approve weekly timesheet unless you want to report a
        modified worked time.
      </div>
      {reviewData.timesheet && (
        <>
          <div className="timeSheetReviewScreenTop">
            <span style={{ color: "#B3B3B3" }}> Job Title:</span>{" "}
            <span>
              {reviewData.jobs &&
                reviewData.jobs.length > 0 &&
                reviewData.jobs[0].title}
            </span>{" "}
          </div>
          <div className="timeSheetReviewScreen">
            {" "}
            <span style={{ color: "#B3B3B3" }}> Talent Name:</span>{" "}
            <span>
              {reviewData.users &&
                reviewData.users.length > 0 &&
                `${reviewData.users[0].firstName} ${reviewData.users[0].lastName}`}
            </span>{" "}
          </div>
          <div
            style={{
              marginTop: 42,
              width: "70%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", height: 56 }}>
              <ArrowBackIosIcon
                style={{ fill: "#9A9A9A", cursor: "pointer", marginTop: 18 }}
                onClick={() => setWeekCount(weekCount + 1)}
              />
              <div className="time-sheet-week" style={{}}>
                <div style={{ textAlign: "center", marginTop: 12 }}>{week}</div>
              </div>
              <ArrowForwardIosIcon
                style={{
                  fill: "#9A9A9A",
                  marginLeft: 5,
                  cursor: "pointer",
                  marginTop: 18,
                }}
                onClick={() =>
                  weekCount > 0 ? setWeekCount(weekCount - 1) : null
                }
              />
            </div>
            <ContainedButtons
              text="Modify"
              height={48}
              color={["#2493F1", "#15568D"]}
              width={150}
              onClick={() => {
                setEditTimeSheet(true);
              }}
              disabled={
                !(weekCount === 0 && moment().day() <= 3 && moment().day() > 0)
              }
            />
          </div>
          <div
            style={{
              padding: 10,
              width: "70%",
              borderRadius: 8,
              marginTop: 20,
              border: "2px solid #B3B3B3",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <table
              style={{
                width: "100%",
                textAlign: "left",
                borderCollapse: "collapse",
                fontFamily: "Libre Franklin",
              }}
            >
              <thead>
                <tr style={{ borderBottom: "2px solid #C4C4C4", height: 50 }}>
                  <th style={{ width: "20%" }}>Start</th>
                  <th style={{ width: "15%" }}>Date</th>
                  <th style={{ width: "30%", textAlign: "end" }}>
                    Scheduled Hours
                  </th>
                  <th style={{ width: "35%", textAlign: "end" }}>
                    Actual Worked Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {enumerateDaysBetweenDates(weekStarts, weekEnds).length > 0 &&
                  enumerateDaysBetweenDates(weekStarts, weekEnds).map(
                    (item, index) => (
                      <tr
                        key={index}
                        className="review-table-row"
                        style={{
                          height: 58,
                          borderBottom: "2px solid #C4C4C4",
                          backgroundColor: "inherit",
                        }}
                      >
                        <td style={{ width: "20%" }}>
                          {moment.utc(item.dateSchedule).format("dddd")}
                        </td>
                        <td style={{ width: "15%" }}>
                          {moment.utc(item.dateSchedule).format("MMM DD, YY")}
                        </td>
                        <td style={{ width: "30%", textAlign: "end" }}>
                          {parseInt(item.totalHours) +
                            parseFloat(item.nonPayableTime)}
                        </td>
                        <td style={{ width: "35%", textAlign: "end" }}>
                          {item.totalHours}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
          <div
            style={{
              color: "#1E7AC9",
              cursor: "pointer",
              marginTop: 32,
              fontSize: 20,
              textDecoration: "underline",
              marginBottom: 80,
            }}
            onClick={() => props.setCurrentPage("HourlyJobs")}
          >
            Back to Timesheet List
          </div>
        </>
      )}
      <DrawerComponent
        open={editTimeSheet}
        onClose={() => {
          setEditTimeSheet(false);
          enumerateDaysBetweenDates(weekStarts, weekEnds);
        }}
        component={
          <TimeSheetForm
            reasonWorkModify={reasonWorkModify}
            setReasonWorkModify={setReasonWorkModify}
            timeSheetData={enumerateDaysBetweenDates(weekStarts, weekEnds)}
            updateTimeSheet={updateTimeSheet}
          />
        }
        width="33%"
      />
    </>
  );
};

export default TimeSheetReview;
