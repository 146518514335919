import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import "../../../Pages/Register/ThankyouMessage/ThankyouMessage.scss";
import "../../../Assets/dev-style.scss";
import Slider from "../../../Components/Slider/Slider";
import "./TalentSearch.scss";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from "react-router-dom";
import AutoCompleteCard from "../../../Components/AutoCompleteCard/AutoCompleteCard";
import { APIHelper } from "../../../utils/ApiHelper";
import API from "../../../utils/Urls";
import MultipleDatePicker from "../../../Components/MultipleDatePicker/MultipleDatePicker";
import { useEffect } from "react";
import { connect } from "react-redux";
import { SHOW_HEADER } from "../../../action/talent_search_action";
import { DASHBOARD } from "../../../utils/enums";

const TalentSearch = (props) => {
  const [hourlyRate, setHourlyRate] = React.useState([0, 100]);
  const [showHourlyRate, setShowHourlyRate] = React.useState(true);
  const [showHourlyRateSlider, setShowHourlyRateSlider] = React.useState(false);
  const [isSearchTermCalled, setIsSearchTermCalled] = React.useState(false);
  const [isReloaded, setIsReloaded] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [selectedSearchTerm, setSelectedSearchTerm] = React.useState({});
  const [selectedDates, setSelectedDates] = React.useState({});
  const [activePart, setActivePart] = React.useState();
  const [autoFocusCard, setAutoFocusCard] = React.useState(false);
  const [textFilter, setTextFilter] = React.useState("");
  const [key, setKey] = React.useState(0);
  const [autoCompleteValue, setautoCompleteValue] = React.useState(null);

  const useStylesCard = makeStyles({
    root: {
      width: "50.10vw",
      backgroundColor: "#f7f7f7",
      borderRadius: "1.041vw",
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  useEffect(() => {
    if (!isReloaded) {
      // window.location.reload();
      setIsReloaded(true);
    }
    props.showHeader();

    console.log(props.shouldShowHeader);
  }, []);

  const cardClasses = useStylesCard();
  const history = useHistory();

  const displayRateRange = <div className="displayDynamicRateStyles">{"$" + hourlyRate[0] + " - " + "$" + hourlyRate[1]}</div>;

  const handleSliderChange = (event, newValue) => {
    setHourlyRate(newValue);
  };

  const handleClickaway = () => {
    setShowHourlyRateSlider(false);
  };

  const handleOnSearchClick = () => {
    if (
      Object.keys(selectedSearchTerm).length === 0 &&
      Object.keys(selectedDates).length === 0 &&
      // !showHourlyRate &&
      textFilter === ""
    ) {
      setAutoFocusCard(true);
    } else {
      let any = selectedSearchTerm._id;
      localStorage.setItem("hourlyRateMin", JSON.stringify(hourlyRate[0]));
      localStorage.setItem("hourlyRateMax", JSON.stringify(hourlyRate[1]));
      localStorage.setItem("specialityData", JSON.stringify({}));
      localStorage.setItem("skillsData", JSON.stringify([]));
      localStorage.setItem("minExperienceData", JSON.stringify(0));
      localStorage.setItem("maxExperienceData", JSON.stringify(20));
      history.push("/talent-search/advance");
    }
  };

  const handleDateSelect = (dates) => {
    setSelectedDates(`$dates[0]-$dates[1]`);
    localStorage.setItem("calendarDates", dates);
  };

  const handleSearchChange = (e) => {
    if (e.length >= 2) {
      setIsSearchTermCalled(true);

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      APIHelper.getMethod(API.TALENT_SEARCH_BY_SEARCH_TERM + e, headers)
        .then((response) => {

          if (response.length > 0) {
            setIsSearchTermCalled(false);
            let searchOptions = response.map((item) => {
              return { label: item.name, value: item.name, _id: item._id };
            });

            setSearchOptions(searchOptions);
          }
          if (response["status"] === 0) {
            setIsSearchTermCalled(false);
          }
        })
        .catch((err) => {
          setIsSearchTermCalled(false);
        });
    }
  };

  return (
    <div>
      <div className="myImage">
        <div style={{ paddingTop: "6.45vw", paddingLeft: "25vw" }}>
          <Card className={cardClasses.root}>
            <div className="row searchedJob">
              <div className="col-md-4 col-sm-12">
                <div className={activePart === DASHBOARD.AUTO_COMPLETE && "activeCard"} style={{ display: "inline-block" }}>
                  <div style={{ marginTop: "0.7vw", marginLeft: "0.9vw", height: "98%" }} className="specialityDropdownLabel">
                    {"Skills"}
                  </div>
                  <div className="autoSearchTextBox" key={key} style={{ marginTop: "0.2vw" }}>
                    <AutoCompleteCard
                      isSearchJob
                      inputValue={textFilter}
                      autoFocus={autoFocusCard}
                      key={`dropdown${autoFocusCard}`}
                      placeholder={"What talent are you looking for?"}
                      value={autoCompleteValue}
                      onInputChange={(value, action) => {
                        // only set the input when the action that caused the
                        // change equals to "input-change" and ignore the other
                        // ones like: "set-value", "input-blur", and "menu-close"
                        if (action.action === "input-change") {
                          setTextFilter(value);
                          localStorage.setItem("talentSearchData", JSON.stringify(value));
                          handleSearchChange(value);
                        }
                      }}
                      options={searchOptions}
                      onChange={(e) => {
                        setautoCompleteValue(e);
                        setTextFilter(e.label);
                        setKey(Math.random());
                        localStorage.setItem("talentSearchData", JSON.stringify(e.label));
                      }}
                      onFocus={() => setActivePart(DASHBOARD.AUTO_COMPLETE)}
                      active={activePart === DASHBOARD.AUTO_COMPLETE}
                      loading={isSearchTermCalled}
                    />
                  </div>
                </div>
              </div>
              <div className={activePart === DASHBOARD.DATE_PICKER ? "col-md-4 col-sm-12 activeCard" : "col-md-4 col-sm-12"}>
                <div style={{ marginTop: "0.7vw", marginLeft: "1.8vw" }} className="specialityDropdownLabel">
                  Dates
                </div>
                <div style={{ marginTop: "0.6vw", marginLeft: "1.4vw" }}>
                  <MultipleDatePicker
                    rangeDisplay
                    onChange={(e) => handleDateSelect(e)}
                    value={selectedDates}
                    style={{ border: "0px", backgroundColor: "#f7f7f7" }}
                    placeholder="Specify dates"
                    onOpen={() => setActivePart(DASHBOARD.DATE_PICKER)}
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div
                className={activePart === DASHBOARD.HOUR_RATE ? "activeCard col-md-4 col-sm-12" : "col-md-4 col-sm-12"}
                onClick={() => {
                  setActivePart(DASHBOARD.HOUR_RATE);
                  setShowHourlyRateSlider(true);
                }}
              >
                <div className="row" style={{ height: 64 }}>
                  <ClickAwayListener onClickAway={handleClickaway}>
                    <div className="col-md-9">
                      <div style={{ marginTop: "0.7vw", marginLeft: "0.8vw" }} className="hourDropdownLabel">
                        HourlyRate
                      </div>
                      <div
                        className="SearchBarRateShow"
                        style={{ marginTop: "0.6vw", marginLeft: "0.8vw", height: 28, alignItems: "center", display: "flex" }}
                      >
                        {!showHourlyRate ? (
                          <p className="selectHourPlaceHolder">Select rate ($)</p>
                        ) : (
                          <p className="parameterValue">{hourlyRate[0] + "-" + hourlyRate[1] + "($)"}</p>
                        )}
                      </div>
                      <div style={{ display: showHourlyRateSlider ? "block" : "none", position: "absolute", marginTop: "2vw" }}>
                        <div>
                          <Slider
                            // heading={"Hourly Rate"}
                            value={hourlyRate}
                            handleChange={handleSliderChange}
                            rangeDisplay={displayRateRange}
                            onChangeCommitted={() => setShowHourlyRate(true)}
                            min={0}
                            max={100}
                            marks={[
                              {
                                value: 0,
                                label: "$0",
                              },
                              {
                                value: 50,
                                label: "$50",
                              },
                              {
                                value: 100,
                                label: "$100+",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </ClickAwayListener>

                  <div
                    onClick={() => handleOnSearchClick()}
                    className="col-md-3"
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      borderLeft: "1px solid #C4C4C4",
                      lineHeight: "75px",
                      textAlign: "center",
                    }}
                  >
                    <SearchIcon fontSize={"inherit"} style={{ width: 22, height: 22 }} />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <p
          style={{
            backgroundColor: "transparent",
            color: "#FFF",
            fontSize: 50,
            width: 400,
            marginLeft: 100,
            marginBottom: 200,
            marginTop: "15vw",
            fontFamily: "Libre Franklin",
          }}
        >
          Hunt for the perfect talent
        </p>
      </div>
      {/* <div><Footer/></div> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showHeader: () => dispatch({ type: SHOW_HEADER }),
  };
};

const mapStateToProps = function (state) {
  return {
    shouldShowHeader: state.talentSearchReducer.showHeader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentSearch);
