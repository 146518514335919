import React from 'react';
import { connect } from 'react-redux';

import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import InputText from '../../../components/centralize_input_text'
import Attachment from '../../../components/attachment'
import Constant from '../../../Constant/constant'
import { capitalizeFirstLetter } from '../../../../../utils/commonFunction'


import {
  VERTICAL_NORMAL, VERTICAL_EXTRA,
  VERTICAL_SMALL, VERTICAL_EXTRA_LARGE
} from '../../../../../utils/enums';

import {
  JOB_TITLE, JOB_DESCRIPTION, ATTACHMENT_URL, REMOVE_ATTACHMENT_LIST,ADDITIONAL_INSTRUCTION,
  NEXT_BUTTON_ENABLE
} from '../../../../../action/form_action';



class JobDescription extends React.Component {


  render() {
    const { jobTitleText, jobDescriptionText, attachmentURLValue,additionalUserInstructionText, error } = this.props;

    return (
      <div>

        <InputText
            id="jobTitle" label="Job Title"
            fontSize = {Constant.FONT_SIZE_MOBILE}
            width = "80%"
            text={jobTitleText}
            error={error.jobTitle || false}
            onChangeCallback={value => {
            //  this.props.jobTitle(value);
              const capitalizedValue = capitalizeFirstLetter(value); // Capitalize the first letter
              this.props.jobTitle(capitalizedValue); // Update the job title
              this.props.enableNextButton();
            }}
          />



        <Spacer spaceType={VERTICAL_NORMAL} />

        <InputText
              id="jobDescription" label="Job Description"
              isMutiline={true}
              row={13}
              text={jobDescriptionText}
              error={error.jobDescription || false}
              onChangeCallback={value => {
                this.props.jobDescription(value);
              }}
              fontSize = {Constant.FONT_SIZE_MOBILE}
              width = "90%"
            />


        <Spacer spaceType={VERTICAL_NORMAL} />


        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text="Attachment (optional)" />

        <Spacer spaceType={VERTICAL_SMALL} />

        <Attachment
          selectedList={attachmentURLValue}
          onSelectedValueCallback={listSelected => {
            this.props.addAttachment(listSelected);
          }}
          onRemoveValueCallback={removeObject => {
            this.props.removeAttachment(removeObject);
          }}
        />

        <Spacer spaceType={VERTICAL_NORMAL} />

          <InputText
              id="additionalInstruction" label="Additional instruction (optional)"
              isMutiline={true}
              row={7}
              text={additionalUserInstructionText}
              onChangeCallback={value => {
                this.props.additionalUserInstruction(value);
              }}
              fontSize = {Constant.FONT_SIZE_MOBILE}
              width = "90%"
            />

      </div>
    );
  }
}


const mapStateToProps = function (state) {
  return {
    jobTitleText: state.formReducer.jobTitle,
    jobDescriptionText: state.formReducer.jobDescription,
    attachmentURLValue: state.formReducer.attachmentURL,
    additionalUserInstructionText: state.formReducer.additionalUserInstruction,
    error: state.formReducer.error || {}
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    jobTitle: (value) => dispatch({ type: JOB_TITLE, jobTitle: value }),
    jobDescription: (value) => dispatch({ type: JOB_DESCRIPTION, jobDescription: value }),
    addAttachment: (value) => dispatch({ type: ATTACHMENT_URL, attachmentURL: value }),
    removeAttachment: (value) => dispatch({ type: REMOVE_ATTACHMENT_LIST, attachmentURL: value }),

    additionalUserInstruction: (value) => dispatch({ type: ADDITIONAL_INSTRUCTION, additionalUserInstruction: value }),
    enableNextButton: () => dispatch({ type: NEXT_BUTTON_ENABLE, screenType: 1 }),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(JobDescription);
