export const FIRST_NAME = (firstName) => ({
    type: "FIRST_NAME",
    firstName : firstName,
  })

  export const LAST_NAME = (lastName) => ({
    type: "LAST_NAME",
    lastName : lastName,
  })

  export const COMPANY_NAME = (companyName) => ({
    type: "COMPANY_NAME",
    companyName : companyName,
  })

  export const MOBILE_NUMBER = (mobileNumber) => ({
    type: "MOBILE_NUMBER",
    mobileNumber : mobileNumber,
  })

  export const LANGUAGE = (language) => ({
    type: "LANGUAGE",
    language : language,
  })

  export const COUNTRY = (country) => ({
    type: "COUNTRY",
    country : country,
  })

  export const STATE = (state) => ({
    type: "STATE",
    state : state,
  })

  export const CITY = (city) => ({
    type: "CITY",
    city : city,
  })

  export const STREET_ADDRESS = (street_address) => ({
    type: "STREET_ADDRESS",
    street_address : street_address,
  })

  export const UNIT_NUMBER = (unitNumber) => ({
    type: "UNIT_NUMBER",
    unitNumber : unitNumber,
  })

  export const POSTAL_CODE = (postalCode) => ({
    type: "POSTAL_CODE",
    postalCode : postalCode,
  })

  export const FIELD_OF_WORK = (field) => ({
    type: "FIELD_OF_WORK",
    field : field,
  })

  export const SPECIALITY_OPTIONS_LIST = (specialityOptions) => ({
    type: "SPECIALITY_OPTIONS_LIST",
    specialityOptions : specialityOptions,
  })

  export const TERMS_AND_CONDITION_ACCEPTED = (isTermsAndConditionAccepted) => ({
    type: "TERMS_AND_CONDITION_ACCEPTED",
    isTermsAndConditionAccepted : isTermsAndConditionAccepted,
  })