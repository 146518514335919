import React from 'react';
import './DashboardOverview.scss';

import Overview from './Component/Overview';
import Statistics from './Component/Statistics';
import Finance from './Component/Finance';
import Invoices from './Component/Invoices';

export class DashboardOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props?.history?.location?.state?.page || 0,
            selectedHeadingstyles: {
                fontSize: '1.25vw',
                fontWeight: '600',
                letterSpacing: '0.96px',
                color: '#1c1c1c',
                backgroundColor: 'rgba(24, 118, 210, 0.17)',
            },
            refreshKey: 1,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let page = localStorage.getItem('page');
        if (page !== null) {
            this.setState({ page: parseInt(page) });
            localStorage.removeItem('page');
        }
    }

    render() {
        const pages = [<Overview />, <Statistics />, <Finance />, <Invoices />];

        return (
            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ width: '24.8%', minHeight: '79.4vh', backgroundColor: '#f2f2f2' }}>
                    <div style={{ marginTop: '5.24vw' }}>
                        <div
                            onClick={() => {
                                localStorage.setItem('page', 0);
                                this.setState({ refreshKey: Math.random() });
                            }}
                            style={this.state.page === 0 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Overview</div>
                        </div>
                        <div
                            onClick={() => {
                                localStorage.setItem('page', 1);
                                this.setState({ refreshKey: Math.random() });
                            }}
                            style={this.state.page === 1 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Statistics</div>
                        </div>
                        <div
                            onClick={() => {
                                localStorage.setItem('page', 2);
                                this.setState({ refreshKey: Math.random() });
                            }}
                            style={this.state.page === 2 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Finance</div>
                        </div>
                        <div
                            onClick={() => {
                                localStorage.setItem('page', 3);
                                this.setState({ refreshKey: Math.random() });
                            }}
                            style={this.state.page === 3 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Invoices</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '75.2%', padding: 20 }}>
                    <div style={{ width: '100%', marginTop: '3.3vw' }}>
                        {pages[this.state.page]}
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardOverview;
