import React from 'react'
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './MessageCard.scss'
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import { useHistory } from 'react-router-dom';

const MessageCard = (props) => {
const history = useHistory();

    return (
        <div className={props.secondary?"secondaryBackgroundOfPage":"backgroundOfPage"} style={{ width: "100%" }}>
            <div className="empolinkCardBackgroundHeading" style={{ cursor: 'pointer' }} onClick={()=>{history.push("/")}}>             
                <div class="logo">
                     <img src={EmpolinkLogo} alt="Empolink"/>
                </div>
        </div>
            <div style={{ marginTop: props.marginTop, width: props.width, height: props.height, minWidth: props.minWidth, maxWidth: props.maxWidth }} className="cardWrapper">
                {props.heading ?
                    <div>
                        {props.onBackButtonClicked ? <div style={props.backbuttonStyles}><ArrowBackIosIcon/></div>: null}
                        <div className="forgotPasswordHeadingCard">
                            {props.heading}
                        </div>
                        {/* {props.onClose ? <div className="closeWrapper"><span style={{ color: props.closeIconColor ? props.closeIconColor : "#454545"}}onClick = {props.onClose}><ClearIcon/></span></div>: null} */}
                        {props.onClose ? <div style={props.closeButtonStyles} className="closeWrapper"><span onClick = {props.onClose}><ClearIcon/></span></div>: null}
                        <div className = "bottomHeadingLine"></div>                        
                    </div>
                    : null}
                {props.content}
            </div>
        </div>
    );
}

export default MessageCard;
