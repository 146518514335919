import React, { Component } from "react";
import "../Register/RegisterPage.scss";
import TextBox from "../../Components/TextField/Textfield";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import { APIHelper } from "../../utils/ApiHelper";
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import API from "../../utils/Urls";
import {COUNTRY, STATE, CITY, STREET_ADDRESS, UNIT_NUMBER, POSTAL_CODE} from '../../action/employer_registration_action'
import EmpolinkLogo from '../../Assets/images/login-logo1.png';

class BusinessInfoPageTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      streetAddress: "",
      unitNumber: "",
      city: "",
      state: "",
      postalcode: "",
      countries: [],
      states: [],
      cities: [],
      isStreetAddress: true,
      isCity: true,
      isCountry: true,
      isState: true,
      isPostalCode: true,
    };
  }

  componentDidMount() {
    APIHelper.getMethod(API.GET_COUNTRY).then(
      (response) => {
        if (response["status"] === 1) {
          const options = response.data.map(function (row) {
            return {
              value: row.name,
              label: row.name,
              id: row._id,
              Id: row.country_id,
            };
          });
          this.setState({ countries: options });
        }
      }
    );
  }

  render() {
    const onCountrySelect = (value) => {
      APIHelper.getMethod(
        API.GET_STATES + `?country_id=${value.Id}`
      ).then((response) => {
        if (response["status"] === 1) {
          console.log("on country select state API response");
          console.log(response);
          const options = response.data.map(function (row) {
            return {
              value: row.name,
              label: row.name,
              id: row._id,
              Id: row.state_id,
            };
          });
          this.setState({
            states: options,
            country: value.value,
          });
        }
      });
    };

    const onStateSelect = (value) => {
      APIHelper.getMethod(
        API.GET_CITIES + `?state_id=${value.Id}`
      ).then((response) => {
        console.log(response);
        console.log(response["status"]);
        if (response["status"] === 1) {
          console.log("on State select City API response");
          console.log(response.data);
          const options = response.data.map(function (row) {
            return {
              value: row.name,
              label: row.name,
              id: row._id,
              Id: row.state_id,
            };
          });
          this.setState({
            cities: options,
            state: value.value,
          });
        }
      });
    };

    const onNextClicked = () => {
      if (!this.props.country) {
        this.setState({ isCountry: false });
      }
      if (this.props.postalCode === "") {
        this.setState({ isPostalCode: false });
      }
      if (!this.props.state) {
        this.setState({ isState: false });
      }
      if (this.props.street_address === "") {
        this.setState({ isStreetAddress: false });
      }
      if (!this.props.city) {
        this.setState({ isCity: false });
      }
      if (
        (this.props.country && this.props.country.value !== "") &&
        this.props.state !== "" &&
        this.props.city !== "" &&
        this.props.street_address !== "" &&
        this.props.postalCode !== ""
      ) {
        this.props.onPageTwoNextClicked(
          this.props.country && this.props.country.value,
          this.props.street_address,
          this.props.unitNumber,
          this.props.city,
          this.props.state,
          this.props.postalCode
        );
      }
    };

    return (
      <>
        <div style={{ width: "100%", height: "1080px", position: "absolute" }}>
          <div
            style={{
              background: "#E2F5FF",
              width: "24.16%",
              height: "100%",
              position: "absolute",
            }}
          >
            <div class="logo">
              <img src={EmpolinkLogo} alt="Empolink" style={{ marginTop: "111px", marginLeft:"156px", cursor: 'pointer' }} 
                onClick={()=>{this.props.history.push("/")}}/>
            </div>
          </div>
          {/* <div onClick={this.props.handleBackButtonClick} className="backIconButtonStyles"><div><ArrowBackIosOutlinedIcon color="inherit"/></div></div> */}
          <div
            style={{
              float: "right",
              background: "white",
              height: "100%",
              width: "75.84%",
            }}
          >
            <div
              style={{
                marginLeft: "34.27%",
                marginRight: "34.27%",
                height: "100%",
                width: "31.46%",
              }}
            >
              <div>
                <div>
                  <h1 className="signUpHeading">Sign Up</h1>
                  <div
                    style={{ paddingBottom: "133px" }}
                    className="signUpSubHeading"
                  >
                    Business Information
                  </div>
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                  <div style={{ paddingBottom: "18px" }}>
                    <Dropdown
                      onChange={(value) => {
                        this.setState({ isCountry: true });
                        this.props.handleCountry(value);
                        this.props.handleState(null);
                        this.props.handleCity(null);
                        onCountrySelect(value);
                      }}
                      value={this.props.country}
                      disabledDropdownIndicator={true}
                      options={this.state.countries}
                      placeholder={"Country *"}
                      error={!this.state.isCountry}
                    />
                    {console.log('!this.state.isCountry', !this.state.isCountry)}
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <Dropdown
                      onChange={(value) => {
                        this.setState({ isState: true });
                        this.props.handleState(value);
                        this.props.handleCity(null);
                        onStateSelect(value);
                      }}
                      value={this.props.state}
                      disabledDropdownIndicator={true}
                      options={this.state.states}
                      placeholder={"State/Province *"}
                      error={!this.state.isState}
                    />
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <Dropdown
                      onChange={(value) =>{
                        this.props.handleCity(value)
                        this.setState({ city: value.label, isCity: true })
                      }}
                      value={this.props.city}
                      disabledDropdownIndicator={true}
                      options={this.state.cities}
                      placeholder={"City *"}
                      error={!this.state.isCity}
                    />
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      label={"Street Address *"}
                      onChange={(event) => {
                        this.props.handleStreetAddress(event.target.value)
                        this.setState({
                          streetAddress: event.target.value,
                          isStreetAddress: true,
                        });
                      }}
                      value={this.props.street_address}
                      width={"100%"}
                      error={!this.state.isStreetAddress}
                    />
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      label={"Unit Number"}
                      onChange={(event) => {
                        this.props.handleUnitNumber(event.target.value)
                        // this.setState({ unitNumber: event.target.value });
                      }}
                      value={this.props.unitNumber}
                      width={"100%"}
                    />
                  </div>

                  <div style={{ paddingBottom: "47.5px" }}>
                    <TextBox
                      label={"Zip/Postal Code *"}
                      onChange={(event) => {
                        this.props.handlePostalCode(event.target.value)
                        this.setState({
                          postalcode: event.target.value,
                          isPostalCode: true,
                        });
                      }}
                      value={this.props.postalCode}
                      width={"100%"}
                      error={!this.state.isPostalCode}
                    />
                  </div>
                </div>
                <Button
                  text="Next"
                  width={"100%"}
                  height="56px"
                  onClick={() => onNextClicked()}
                  color={["#2699fb", "#134d7e"]}
                />
                <div style={{ textAlign: "center", paddingTop: "45px" }}>
                  <Stepper steps={3} activeStep={1} color={"#1876d2"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleCountry: (event) => dispatch({ type: COUNTRY, country: event }),
    handleState: (event) => dispatch({ type: STATE, state: event }),
    handleCity: (event) => dispatch({ type: CITY, city: event }),
    handleStreetAddress: (event) => dispatch({ type: STREET_ADDRESS, street_address: event }),
    handleUnitNumber: (event) => dispatch({ type: UNIT_NUMBER, unitNumber: event }),
    handlePostalCode: (event) => dispatch({ type: POSTAL_CODE, postalCode: event }),
  }
}

const mapStateToProps = function(state) {
  return {
    country : state.employerRegisterReducer.country,
    state : state.employerRegisterReducer.state,
    city : state.employerRegisterReducer.city,
    street_address : state.employerRegisterReducer.street_address,
    unitNumber : state.employerRegisterReducer.unitNumber,
    postalCode : state.employerRegisterReducer.postalCode,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessInfoPageTwo));

