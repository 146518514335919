import React from 'react';
import { VERTICAL_NORMAL, VERTICAL_EXTRA,VERTICAL_ULTRA_SMALL,
VERTICAL_EXTRA_LARGE, HORIZONTAL_NORMAL,HORIZONTAL_EXTRA,VERTICAL_SMALL,HORIZONTAL_ULTRA_SMALL,
HORIZONTAL_SMALL } from '../../../utils/enums';
import ScreenSizeDiv from './screen_size_dv'


export default function Spacer(props) {
  return (
    <>
    {(() => {
      switch (props.spaceType) {
        case VERTICAL_ULTRA_SMALL:
        return <ScreenSizeDiv
          mobile = "Ultra-Small-Space"
          tablet = "Ultra-Small-Space"
          web = "Ultra-Small-Space"/>
          break;

        case VERTICAL_SMALL:
        return <ScreenSizeDiv
          mobile = "Ultra-Small-Space"
          tablet = "Ultra-Small-Space"
          web = "Small-Space"/>
          break;

        case VERTICAL_NORMAL:
        return <ScreenSizeDiv
          mobile = "Small-Space"
          tablet = "Small-Space"
          web = "Normal-Space"/>
         break;

       case VERTICAL_EXTRA:
       return <ScreenSizeDiv
         mobile = "Normal-Space"
         tablet = "Normal-Space"
         web = "Extra-Space"/>
         break;

       case VERTICAL_EXTRA_LARGE:
       return <ScreenSizeDiv
         mobile = "Extra-Space"
         tablet = "Extra-Space"
         web = "Extra-Large-Space"/>
        break;

       case HORIZONTAL_ULTRA_SMALL:
       return <ScreenSizeDiv
         mobile = "Horizontal-Ultra-Small-Space-Between"
         tablet = "Horizontal-Ultra-Small-Space-Between"
         web = "Horizontal-Ultra-Small-Space-Between"/>
         break;

        case HORIZONTAL_SMALL:
        return <ScreenSizeDiv
           mobile =  "Horizontal-Ultra-Small-Space-Between"
           tablet = "Horizontal-Ultra-Small-Space-Between"
           web = "Horizontal-Small-Space-Between"/>
        break;


        case HORIZONTAL_NORMAL:
          return <ScreenSizeDiv
             mobile = "Horizontal-Small-Space-Between"
             tablet = "Horizontal-Small-Space-Between"
             web = "Horizontal-Normal-Space-Between"/>
          break;

          case HORIZONTAL_EXTRA:
            return <ScreenSizeDiv
               mobile = "Horizontal-Normal-Space-Between"
               tablet = "Horizontal-Normal-Space-Between"
               web = "Horizontal-Extra-Space-Between"/>
            break;

        }
      })()}
      </>
    );
}
