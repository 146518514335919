import React from 'react';
import ApplicationReview from './ProcessStep/ApplicationReview';
import JobPostAndInvitation from './ProcessStep/JobPostAndInvitation';
import ReHiring from './ProcessStep/ReHiring';
import HourlyJobs from './ProcessStep/HourlyJobs';
import FixedFeeJobs from './ProcessStep/FixedFeeJobs';
import TimeSheetReview from './ProcessStep/TimeSheetReview';
import WorkHistory from './ProcessStep/WorkHistory';
import Favorites from './ProcessStep/Favorites';

import './HiringProcess.scss';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
const selectedHeadingstyles = {
    fontSize: '1.25vw',
    fontWeight: '600',
    letterSpacing: '0.96px',
    color: '#1c1c1c',
    backgroundColor: 'rgba(24, 118, 210, 0.17)',
};

export class HiringProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
        };
    }

    render() {
        const pages = [
            <ApplicationReview />,
            <JobPostAndInvitation />,
            <ReHiring />,
            <HourlyJobs />,
            <FixedFeeJobs />,
            <TimeSheetReview />,
            <WorkHistory />,
            <Favorites />,
        ];

        return (
            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ width: '24.8%', minHeight: '79.4vh', backgroundColor: '#f2f2f2' }}>
                    <div style={{ marginTop: '5.24vw' }}>
                        <p className="hiringHeader" style={{ color: '#1c1c1c' }}>
                            Hiring Process
                        </p>
                        <div
                            onClick={() => this.setState({ page: 0 })}
                            style={this.state.page === 0 ? selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Application Review</div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 1 })}
                            style={this.state.page === 1 ? selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">
                                Job Posts and Invitations
                            </div>
                        </div>
                        {/* <div
                            onClick={() => this.setState({ page: 2 })}
                            style={this.state.page === 2 ? selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Re-Hiring</div>
                        </div> */}
                        <p className="hiringHeader" style={{ color: '#1c1c1c' }}>
                            Ongoing Jobs
                        </p>
                        <div
                            onClick={() => this.setState({ page: 3 })}
                            style={this.state.page === 3 ? selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Hourly Jobs</div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 4 })}
                            style={this.state.page === 4 ? selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Fixed Fee Jobs</div>
                        </div>
                        <div
                            onClick={() => this.setState({ page: 5 })}
                            style={this.state.page === 5 ? selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Timesheet Review</div>{' '}
                        </div>
                        <p className="hiringHeader" style={{ color: '#1c1c1c' }}>
                            Completed Jobs
                        </p>
                        <div
                            onClick={() => this.setState({ page: 6 })}
                            style={this.state.page === 6 ? { ...selectedHeadingstyles } : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Work History</div>
                        </div>
                        <p className="hiringHeader" style={{ color: '#1c1c1c' }}>
                            Favorites
                        </p>
                        <div
                            onClick={() => this.setState({ page: 7 })}
                            style={
                                this.state.page === 7
                                    ? { ...selectedHeadingstyles, marginBottom: 60 }
                                    : { marginBottom: 60 }
                            }
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">My Favorites</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '75.2%', padding: 20 }}>
                    <div style={{ width: '100%', marginTop: '3.3vw' }}>
                        {pages[this.state.page]}
                    </div>
                </div>
            </div>
        );
    }
}

export default HiringProcess;
