import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from '../Button/Button';
import { APIHelper } from '../../utils/ApiHelper';
import API, { STRIPE_PAYMENT_REFRESH_URL, STRIPE_PAYMENT_RETURN_URL } from '../../utils/Urls';
// import { APIHelper } from '../../utils/ApiHelper';
// import API from '../../utils/Urls';

const StripeConnect = ({
    loader,
    isPaymentIntagreted,
    btnLoading,
    setLoader,
    setPaymentIntagreted,
    setBtnLoading,
}) => {
    const verifyStripeConnection = () => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        setBtnLoading(true);

        const refresh_url = STRIPE_PAYMENT_REFRESH_URL;
        const return_url = STRIPE_PAYMENT_RETURN_URL;

        APIHelper.postMethod(
            API.GET_STRIPE_ACCOUNT_ID,
            { refresh_url, return_url, ActionTypeStr: 'create' },
            headers
        )
            .then((res) => {
                const link = res?.data?.url;
                window.location.href = link;
            })
            .catch((error) => {
                setBtnLoading(false);
            });
    };

    return (
        <div style={{ marginTop: 30 }}>
            {loader ? (
                <CircularProgress size={40} />
            ) : (
                <>
                    {!isPaymentIntagreted && (
                        <Button
                            text="Connect Stripe"
                            width={'220px'}
                            height="48px"
                            onClick={() => verifyStripeConnection()}
                            // disabled={!stripe || !elements}
                            color={['#2699fb', '#134d7e']}
                            loading={btnLoading}
                            loadingMaskSize={30}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default StripeConnect;
