import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import API from '../../../utils/Urls';
import { APIHelper } from '../../../utils/ApiHelper';

import './currentCal.scss';

const CurrentCalender = (props) => {
    const [initLoad, setInitLoad] = useState(true);
    const [currentSchedule, setCurrentSchedule] = useState([]);

    useEffect(() => {
        if (initLoad) {
            loadCalendar();
            setInitLoad(false);
        }
    }, [initLoad]);

    const loadCalendar = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_BLOCK_DATE, headers).then((response) => {
            const data = response;
            let allSchedule = [];
            data &&
                data['block_days'] &&
                data['block_days'].forEach((contract) => {
                    allSchedule.push({
                        date: moment(contract.date).utc(0).format('YYYY-MM-DD'),
                        title: contract.title,
                        backgroundColor:
                            contract.job_type === 'FIXED'
                                ? '#599DD5'     //FIXED
                                : contract.job_type === 'HOURLY'
                                ? '#9FB772'     //Hourly
                                : '#E62D62',    //Day-off
                    });
                });
            setCurrentSchedule(allSchedule);
        });
        return false;
    };

    return (
        <>
            <div className="editDetailsContentHeading">Current Calender</div>
            <div className="cal-container">
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay',
                    }}
                    events={currentSchedule}
                />
            </div>
        </>
    );
};

export default CurrentCalender;
