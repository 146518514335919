import React from 'react';
import { useHistory } from 'react-router-dom';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
import useQuery from '../../utils/useQuery';

const StripePaymentConnect = () => {
    const history = useHistory();
    const query = useQuery();

    React.useEffect(async () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(
            API.GET_STRIPE_ACCOUNT_LINK_STATUS,
            { status: true, account_id: query.get('acct_id') },
            headers
        )
            .then((res) => {
                if (localStorage.getItem('userType') === 'EMPLOYER') {
                    history.push('/account-details');
                } else {
                    history.push('/employee/account-details');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return <h3>Loading....</h3>;
};

export default StripePaymentConnect;
