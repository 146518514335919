import React, { useEffect, useState } from "react";
import ReactS3 from "react-s3";
import { IconButton } from "@material-ui/core";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CONSTANTS from "../../utils/constants";
import { getFileName } from "../../utils/commonFunction";

export const config = {
  bucketName: CONSTANTS.BUCKET_NAME,
  // dirName: "photos",
  region: CONSTANTS.REGION,
  accessKeyId: CONSTANTS.ACCESS_KEY_ID,
  secretAccessKey: CONSTANTS.SECRET_ACCESS_KEY,
};

const FileUpload = (props) => {

  const onFileUploadClicked = (event) => {
    const file = event.target.files[0];
    props.setFileName(file.name);
    props.setFile(file);

  };

  return (
    <div
      style={{ position: "absolute", marginLeft: "28%", marginTop: "-55px" }}
    >
      <input
        type="file"
        id={props.id}
        style={{ display: "none" }}
        value={props.value}
        onChange={(e) => onFileUploadClicked(e)}
      />
      <label
        htmlFor={props.id}
        style={{
          right: 0,
          marginRight: "-40%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          component={"span"}
          style={{
            backgroundColor: props.error ? "red" : "rgba(164,199,114,0.64)",
            marginRight: "5px",
            color: "#707070",
            border: props.error ? '1px solid #f44336' : ''
          }}
        >
          <AddAPhotoIcon />
        </IconButton>
        <p style={{ width: "100px", overflow: "hidden" }}>{props.helperText}</p>
      </label>
    </div>
  );
};

export default FileUpload;
