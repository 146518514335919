import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './extendedpages.scss';
//import '../../../Assets/dev-style.scss';
import EmpolinkLogo from '../../Assets/images/logo.svg';
import InvisibleLogo from '../../Assets/images/invisable-logo.svg';
import FooterPublic from '../../Components/FooterPublic/FooterPublic';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import { Helmet } from 'react-helmet';

class retailjobs extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink - Your source for retail jobs</title>
                    <meta name="keywords" content="Department Store Assistant Jobs, Department Store Associate Jobs, Department Store Cashier Jobs, Department Store Clerk Jobs, Department Store Order Processing & Filler Jobs, Department Store Packer Jobs, Department Store Sales Person Jobs, Department Store Shipping & Receiving Jobs, Department Store Worker Jobs, Retail Franchise Assistant Jobs, Retail Franchise Associate Jobs, Retail Franchise Cashier Jobs, Retail Franchise Clerk Jobs, Retail Franchise Order Filler Jobs, Retail Franchise Salesperson Jobs, Retail Franchise Shipping & Receiving Jobs, Warehouse Assistant Jobs, Warehouse Associate Jobs, Warehouse Handler Jobs, Warehouse Operator Jobs, Warehouse Worker Jobs" />
                    <meta name="description" content="Empolink connects clients and talents quickly and efficiently. Discover a platform designed for seamless interactions and services." />
                    <meta property="og:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta property="og:description" content="Empolink bridges the gap between clients and talents, providing a streamlined platform for finding and offering services." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@@empolink" />
                    <meta name="twitter:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta name="twitter:description" content="Explore diverse freelance opportunities and connect with clients who need your skills on Empolink." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>

                </Helmet>
                <HeaderPublic /> {/* From HeaderPublic component */}
                <section class="image-text image-text--right section-padding">
                    <div class="container">
                        <div class="row flex-row flex-direction">
                            <div className="col-md-6 image-text__half">
                                    <div className="specialtieslist-box">
                                        <ul>
                                        <li>Department Store Assistant, Clerk, Cashier</li>
                                        <li>Department Store Order Processing & Filler</li>
                                        <li>Department Store Packer</li>
                                        <li>Department Store Sales Person</li>
                                        <li>Department Store Shipping & Receiving</li>
                                        <li>Retail Franchise Assistant, Clerk, Cashier</li>
                                        <li>Retail Franchise Order Filler</li>
                                        <li>Retail Franchise Salesperson</li>
                                        <li>Retail Franchise Shipping & Receiving</li>
                                        <li>Warehouse Assistant</li>
                                        <li>Warehouse Handler</li>
                                        <li>Warehouse Operator</li>
                                        <li>Warehouse Worker</li>
                                        </ul>
                                    </div>
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    READY TO ELEVATE YOUR CAREER IN <span>RETAIL</span> SERVICES?
                                </h2>
                                <p>Join our platform to connect with clients seeking talented professionals in the dynamic world of Retail, Sales & Services. Whether you're a charismatic Sales Representative, customer-focused Retail Associate, results-driven Business Development Manager, or have expertise in other related areas, your next rewarding opportunity is just a click away! Turn your people skills into profit and enjoy the flexibility of freelancing in this ever-evolving industry.</p>
                                <p></p>
                                <p></p>                                
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                                <p></p>
                                
                            </div>
                            
                        </div>
                    </div>
                </section>

                <FooterPublic />
            </>
        );
    }
}

export default withRouter(retailjobs);
