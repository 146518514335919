/* eslint-disable no-undef */
import React, { useState } from 'react';
import EmployeeDetail from '../../Components/WideProfileCard/EmployeeDetail';
import useQuery from '../../utils/useQuery';

const PublicProfile = () => {
    const query = useQuery();
    const [data, setData] = useState({});
    const [userID, setUserID] = useState('');

    React.useEffect(async () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(
            API.GET_STRIPE_ACCOUNT_LINK_STATUS,
            { status: true, account_id: query.get('acct_id') },
            headers
        )
            .then((res) => {

            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <h1>Under development</h1>
        // <EmployeeDetail
        //     data={data}
        //     userID={userID}
        //     addToFavorite={() => console.log('call')}
        //     removeFromFavorite={() => console.log('call')}
        //     onClose={() => console.log('call close')}
        //     isPublicView
        //     isPublicAccountView
        // />
    )
}

export default PublicProfile;
