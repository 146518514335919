import React from 'react';
import { connect } from 'react-redux';

import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import InputText from '../../../components/centralize_input_text'
import DropDownList from '../../../components/centralize_dropdown'
import SearchableChips from '../../../components/centralize_searchable_chips'
import ChipsSelection from '../../../components/centralize_chips_selection'
import RangeSlider from '../../../components/slider'
import Constant from '../../../Constant/constant'

import { VERTICAL_NORMAL, VERTICAL_EXTRA,
  VERTICAL_SMALL, VERTICAL_EXTRA_LARGE, HORIZONTAL_NORMAL, HORIZONTAL_SMALL
} from '../../../../../utils/enums';

import {
  FIELD_OF_WORK, SPECIALITY, SUGGESTED_SKILLS, NEW_ADDITIONAL_SKILLS, REMOVE_NEW_ADDITIONAL_SKILLS,
  EXPERIENCE_RANGE,NEXT_BUTTON_ENABLE,CLEAR_SKILLS_SELECTION,CLEAR_CHIPS_SELECTION
} from '../../../../../action/form_action';

import {CLEAR} from '../../../../../action/suggested_skills_action';

import { getFieldOfWork } from '../../../../../api/field_of_work';
import { getSpecialityList } from '../../../../../api/speciality_list';
import { getSuggestedSkillsList } from '../../../../../api/suggested_skills_list';


class SkillsExperience extends React.Component {


  componentDidMount() {
    if(this.props.dataFieldOfWorkDropdown === undefined || this.props.dataFieldOfWorkDropdown === "" || this.props.dataFieldOfWorkDropdown.length === 0){
        this.props.getFieldOfWork();
    }
  }


  render() {
    const {
      fieldOfWorkValue, specialityValue, suggestedSkillsValue, additionalSkillsValue, experienceRangeValue,clearChipsSelectionValue,

      statusFieldOfWorkDropdown,dataFieldOfWorkDropdown,errorMessageFieldOfWorkDropdown,

      statusSpecialityDropdown,dataSpecialityDropdown,errorMessageSpecialityDropdown, error,

      statusSuggestedSkills,dataSuggestedSkills,errorMessageSuggestedSkills
    } = this.props;

    return (
      <div >

        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text="Field of work:" />

        <Spacer spaceType={VERTICAL_SMALL} />
        <div className="Row-Parent">

          {(() => {
            switch (statusFieldOfWorkDropdown) {
              case "LOADING":
              return  <DropDownList
                fontSize = {Constant.FONT_SIZE_MOBILE}
                width = "45%"
                menuWidth = "20%"
                error={error.fieldOfWork || false}
                showDropDownIcon = {false} dropDownList={[]} label={"Loading..."}/>
                break;
              case "ERROR":
              return <DropDownList
                fontSize = {Constant.FONT_SIZE_MOBILE}
                width = "45%"
                menuWidth = "20%"
                error={error.fieldOfWork || false}
                showDropDownIcon = {false} dropDownList={[]} label={errorMessageFieldOfWorkDropdown}/>
                break;
              case "SUCCESS":
              return <DropDownList
                fontSize = {Constant.FONT_SIZE_MOBILE}
                width = "45%"
                menuWidth = "20%"
                error={error.fieldOfWork || false}
                fontSizeDropDown = {Constant.FONT_SIZE_DROPDOWN_MOBILE}
                dropDownList={dataFieldOfWorkDropdown} label={"Job Title"}
                  text={fieldOfWorkValue}
                  showDropDownIcon = {false}
                  onSelectedValueCallback={(selectedValue, data) => {
                    this.props.fieldOfWork(selectedValue, data);
                    this.props.speciality("");
                    this.props.clearSkills();
                    this.props.clearAPIData();
                    this.props.clearChipsSelection(true);
                    this.props.getSpecialityList(data);
                  }} />
                break;
              default:
              return <DropDownList
                fontSize = {Constant.FONT_SIZE_MOBILE}
                width = "45%"
                menuWidth = "20%"
                error={error.fieldOfWork || false}
                showDropDownIcon = {false} dropDownList={[]} label={"Job Title"}/>
              }
            })()}

          <Spacer spaceType={HORIZONTAL_SMALL}/>

            {(() => {
              switch (statusSpecialityDropdown) {
                case "LOADING":
                return  <DropDownList
                  fontSize = {Constant.FONT_SIZE_MOBILE}
                  width = "50%"
                  menuWidth = "20%"
                  error={error.speciality || false}
                  dropDownList={[]} label={"Loading..."}/>
                  break;
                case "ERROR":
                return <DropDownList
                  fontSize = {Constant.FONT_SIZE_MOBILE}
                  width = "50%"
                  menuWidth = "20%"
                  error={error.speciality || false}
                  dropDownList={[]} label={errorMessageSpecialityDropdown}/>
                  break;
                case "SUCCESS":
                return <DropDownList
                  fontSize = {Constant.FONT_SIZE_MOBILE}
                  width = "50%"
                  menuWidth = "20%"
                  error={error.speciality || false}
                  fontSizeDropDown = {Constant.FONT_SIZE_DROPDOWN_MOBILE}
                  dropDownList={dataSpecialityDropdown} label={"Speciality*"}
                    text={specialityValue}
                    onSelectedValueCallback={(selectedValue, data) => {
                      this.props.clearSkills();
                      this.props.clearAPIData();
                      this.props.clearChipsSelection(true);
                      this.props.speciality(selectedValue, data);
                      this.props.getSuggestedSkillsList(data)
                    }} />
                  break;
                default:
                return <DropDownList
                  fontSize = {Constant.FONT_SIZE_MOBILE}
                  error={error.speciality || false}
                  width = "50%"
                  menuWidth = "20%"
                  dropDownList={[]} label={"Speciality*"}/>
                }
              })()}

        </div>

        <Spacer spaceType={VERTICAL_EXTRA} />

        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text="Suggested skills" />
        <Spacer spaceType={VERTICAL_SMALL} />

          {(() => {
            switch (statusSuggestedSkills) {
              case "LOADING":
              return  <ChipsSelection chipsList={[]} selectedChipList={suggestedSkillsValue}/>
                break;
              case "ERROR":
              return <ChipsSelection chipsList={[]} selectedChipList={suggestedSkillsValue}/>
                break;
              case "SUCCESS":
              return <ChipsSelection chipsList={dataSuggestedSkills}
                selectedChipList={suggestedSkillsValue}
                onSelectedValueCallback={value => {
                  this.props.suggestedSkills(value);
                }} />
                break;
              default:
              return <ChipsSelection chipsList={[]} selectedChipList={suggestedSkillsValue}/>
              }
            })()}


        <Spacer spaceType={VERTICAL_EXTRA} />

        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text="New additional skills:" />

        <Spacer spaceType={VERTICAL_SMALL} />

          <SearchableChips
            fontSize = {Constant.FONT_SIZE_MOBILE}
            width = "60%"
            error={error.additionalSkills || false}
            fontSizeDropDown = {Constant.FONT_SIZE_DROPDOWN_MOBILE}
            menuWidthDropDown = "40%"
            clearChipList = {clearChipsSelectionValue}
            clearChipsSelectionCallback={() => {
              this.props.clearChipsSelection(false);
            }}
              selectedList={additionalSkillsValue}
                searchList=
                  {(() => {
                    switch (statusSuggestedSkills) {
                      case "LOADING":
                      return  []
                        break;
                      case "ERROR":
                      return  []
                        break;
                      case "SUCCESS":
                      return   dataSuggestedSkills
                        break;
                      default:
                      return []
                      }
                    })()}
                label=
                  {(() => {
                    switch (statusSuggestedSkills) {
                      case "LOADING":
                      return  "Loading ..."
                        break;
                      case "ERROR":
                      return  errorMessageSuggestedSkills
                        break;
                      case "SUCCESS":
                      return "Search for more Skills"
                        break;
                      default:
                      return "Search for more Skills"
                      }
                    })()}
                    onSelectedValueCallback={listSelected => {
                      this.props.additionalSkills(listSelected);
                    }}
                    onRemoveValueCallback={removeObject => {
                      this.props.removeAdditionalSkills(removeObject);
                    }} />

        <Spacer spaceType={VERTICAL_EXTRA} />

          <RangeSlider
             heading={"Experience:"}
              min={0}
              max={50}
              width = {"60%"}
              rangeValue = {experienceRangeValue}
              onChangeCallback={(value)  => {
                this.props.experience(value);
              }}
              marks={[
                  {
                      value: 0,
                      label: '0',
                  },
                  {
                      value: 10,
                      label: '10',
                  },
                  {
                      value: 20,
                      label: '20',
                  },
                  {
                      value: 30,
                      label: '30',
                  },
                  {
                      value: 40,
                      label: '40',
                  },
                  {
                      value: 50,
                      label: '50',
                  },
              ]}
          />

      </div>
    );
  }
}


const mapStateToProps = function (state) {
  return {
    fieldOfWorkValue: state.formReducer.fieldOfWork,
    specialityValue: state.formReducer.speciality,
    suggestedSkillsValue: state.formReducer.suggestedSkills,
    additionalSkillsValue: state.formReducer.additionalSkills,
    experienceRangeValue: state.formReducer.experienceRange,
    clearChipsSelectionValue: state.formReducer.clearChipsSelection,
    error: state.formReducer.error || {},

    statusFieldOfWorkDropdown: state.fieldOfWorkReducer.status,
    dataFieldOfWorkDropdown: state.fieldOfWorkReducer.data,
    errorMessageFieldOfWorkDropdown: state.fieldOfWorkReducer.errorMessage,

    statusSpecialityDropdown: state.specialityReducer.status,
    dataSpecialityDropdown: state.specialityReducer.data,
    errorMessageSpecialityDropdown: state.specialityReducer.errorMessage,

    statusSuggestedSkills: state.suggestedSkillsReducer.status,
    dataSuggestedSkills: state.suggestedSkillsReducer.data,
    errorMessageSuggestedSkills: state.suggestedSkillsReducer.errorMessage,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fieldOfWork: (value,fieldOfWorkObject) => dispatch({ type: FIELD_OF_WORK, fieldOfWork: value, fieldOfWorkObject : fieldOfWorkObject }),
    speciality: (value,specialityObject) => dispatch({ type: SPECIALITY, speciality: value, specialityObject : specialityObject }),
    suggestedSkills: (value) => dispatch({ type: SUGGESTED_SKILLS, suggestedSkills: value }),
    additionalSkills: (value) => dispatch({ type: NEW_ADDITIONAL_SKILLS, additionalSkills: value }),
    removeAdditionalSkills: (value) => dispatch({ type: REMOVE_NEW_ADDITIONAL_SKILLS, additionalSkills: value }),
    experience: (value) => dispatch({ type: EXPERIENCE_RANGE, experienceRange: value }),
    clearSkills: () => dispatch({ type: CLEAR_SKILLS_SELECTION}),
    clearAPIData: () => dispatch({ type: CLEAR}),
    clearChipsSelection: (value) => dispatch({ type: CLEAR_CHIPS_SELECTION, clearChipsSelection : value}),

    getFieldOfWork: () => { dispatch(getFieldOfWork()); },
    getSpecialityList: (value) => { dispatch(getSpecialityList(value)); },
    getSuggestedSkillsList: (value) => { dispatch(getSuggestedSkillsList(value)); },
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(SkillsExperience);
