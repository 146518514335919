import React from 'react';
import ContainedButtons from '../../../../Components/Button/Button';
import Dropdown from '../../../../Components/DropdownComponent/Dropdown.component';
import TextField from '../../../../Components/TextField/Textfield';

const CancellationReasonPopup = (props) => {
    const [reason, setReason] = React.useState(null);
    const [optionValue, setOptionValue] = React.useState(null);
    const [reasonTxt, setReasonTxt] = React.useState('');
    const [reaonErr, setReasonErr] = React.useState(false);
    const [reasonTypeErr, setReasonTypeErr] = React.useState(false);

    const onDeleteJob = () => {
        var isValid = true;
        if (!reason) {
            isValid = false;
            setReasonTypeErr(true);
        }

        if (reason.trim() === 'Other') {
            if (reasonTxt.trim() === '') {
                isValid = false;
                setReasonErr(true);
            }
        }
        
        if (reason && isValid) {
            props.yesButtonClick({
                reasonType: reason,
                reason: reasonTxt,
            });
        }
    };

    return (
        <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
            <div style={{ display: 'flex' }}>
                <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>Cancellation Reason</h3>
            </div>
            <div style={{ marginTop: 50 }}>
                <div style={{ width: '50%' }}>
                    <Dropdown
                        options={[
                            {
                                label: 'Job not available anymore',
                                value: 'Job not available anymore',
                            },
                            {
                                label: 'Job needs to be rescheduled',
                                value: 'Job needs to be rescheduled',
                            },
                            { label: 'Emergency Situation', value: 'Emergency Situation' },
                            { label: 'Other', value: 'Other' },
                        ]}
                        value={optionValue}
                        onChange={(e) => {
                            setReason(e.value);
                            setOptionValue(e);
                            setReasonTypeErr(false);
                            setReasonErr(false);
                        }}
                        error={reasonTypeErr}
                    />
                </div>
                <div
                    style={{
                        marginTop: 30,
                    }}
                >
                    <TextField
                        rows={8}
                        multiline
                        width={'96%'}
                        label={'Reason'}
                        value={reasonTxt}
                        onChange={(e) => setReasonTxt(e.target.value)}
                        disabled={reason !== 'Other'}
                        error={reaonErr}
                    />
                </div>
                <div style={{ marginTop: 30 }}>
                    <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                        Are you sure you want to cancel the job?
                    </p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <div style={{ marginRight: 10 }}>
                        <ContainedButtons
                            text="No"
                            color={['#2699fb', '#134d7e']}
                            height="56px"
                            width={"12vw"}
                            onClick={props.noButtonClick}
                        />
                    </div>
                    <ContainedButtons
                        text="Yes"
                        height="56px"
                        width={"12vw"}
                        variant="outlined"
                        color={['#2699fb', '#134d7e']}
                        onClick={onDeleteJob}
                    />
                </div>
            </div>
        </div>
    );
};

export default CancellationReasonPopup;
