import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../Home/Home.scss';
// import Button from '@material-ui/core/Button';
// import EmployeeBg from '../../../Assets/EmployeeBg.svg';
// import EmployerBg from '../../../Assets/EmployerBg.svg';
import EmpolinkLogo from '../../../Assets/images/login-logo1.png';
import WorkImg from '../../../Assets/images/work-img.png';
import HireImg from '../../../Assets/images/hire-img.png';
import HeaderPublic from '../../../Components/HeaderPublic/HeaderPublic';
import FooterPublic from '../../../Components/FooterPublic/FooterPublic';
// import '../../../Assets/dev-style.scss';

class ChooseEmployerEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onEmployerClicked() {
        this.props.history.push('/register');
    }

    onEmployeeClicked() {
        this.props.history.push('/employee/register');
    }

    onHomeClicked() {
        this.props.history.push('/');
    }

    render() {
        return (
            <>
                <HeaderPublic forceScrolled={false} />
                <section class="login-wrapper user-body">
                    <div class="container">
                        <div class="row row-center">
                            <div class="col-md-6">
                                <div class="login-img">
                                    <a href="javascript:void(0)" class="login-logo" onClick={() => this.onHomeClicked()}>
                                        <img src={EmpolinkLogo} alt="" />
                                    </a>
                                    <div class="login-img-wrapper">
                                        <img src={WorkImg} alt="" />
                                        <a href="javascript:void(0)" class="log_in_left_btn" onClick={() => this.onEmployerClicked()}>
                                            I want to hire
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="login-right">
                                    <div class="login-img">
                                        <div class="login-img-wrapper">
                                            <img src={HireImg} alt="" />
                                            <a href="javascript:void(0)" class="log_in_left_btn" onClick={() => this.onEmployeeClicked()}>
                                                I want to work
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterPublic />
            </>
        );
    }
}

export default withRouter(ChooseEmployerEmployee);
