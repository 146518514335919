import React from "react";
import moment from "moment";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import { Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Dropdown from "../../../Components/DropdownComponent/Dropdown.component";
import ContainedButtons from "../../../Components/Button/Button";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import { APIHelper } from "../../../utils/ApiHelper";
import API from "../../../utils/Urls";
import DrawerComponent from "../../../Components/Drawer/Drawer";
import ContractCancel from "./Component/ContractCancel";
import HourlyContractRequest from "./Component/HourlyContractRequest";
import HourlyContract from "../../EmployerHiringProcess/ProcessStep/Component/HourlyContract";
import Swal from "sweetalert2";

const HourlyJobs = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({ label: "Start Date", value: "startDate" });
  const [loading, setLoading] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerPage, setDrawerPage] = React.useState("contractCancel");
  const [contractId, setContractId] = React.useState();

  const handleClick = (event, item) => {
    setContractId(item._id);
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    getListHourlyJobs();
  }, [page, sortBy]);

  const getListHourlyJobs = () => {
    setLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(API.GET_LIST_EMPLOYEE_HOURLY_JOBS + `page=${page}&sort=${sortBy ? sortBy.value : ""}`, headers)
      .then((res) => {
        setData(res[0].data);
        setCount(res[0].total);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewContract = () => {
    setDrawerPage("viewContract");
    setDrawerOpen(true);
    handleClose();
  };

  const cancelJob = (id, data) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.putMethod(`${API.CANCEL_CONTRACTS}${id}`, data, headers)
      .then((res) => {
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Job cancel successfully!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        }).then((result) => {
          if (result) {
            setDrawerOpen(false);
            getListHourlyJobs();
          }
        });
      })
      .catch((error) => console.log(error));
  };

  const finalCancelJob = (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.putMethod(`${API.FINAL_CANCEL_CONTRACTS}${id}`, null, headers)
      .then((res) => {
        setDrawerOpen(false);
        getListHourlyJobs();
      })
      .catch((error) => console.log(error));
  };

  const finalCancelContractJob = (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.postMethod(`${API.CONTRACT_NOT_CANCELLED}`, { contract_id: id }, headers)
      .then((res) => {
        setDrawerOpen(false);
        getListHourlyJobs();
      })
      .catch((error) => console.log(error));
  };

  const getReturnStatus = (status, startDate, endDate, cancelBy) => {
    switch (status) {
      case "CANCELLATION_REQUESTED":
        if (cancelBy === "EMPLOYER") {
          return { status: "CANCELLED_BY" };
        }
        return { status: "CANCELLED" };
      case "CANCELLED":
        return { status: "CANCELLED_FINAL" };
      case "STARTED":
        const isStart = moment(startDate) >= moment();
        const isEnd = moment(endDate) < moment();
        if (isStart) {
          return { status: "NOT_STARTED" };
        } else if (isEnd) {
          return { status: "COMPLETED" };
        } else {
          return { status: "IN_PROGRESS" };
        }
      default:
        return { status: "IN_PROGRESS" };
    }
  };

  return (
    <>
      <div className="editDetailsContentHeading">Hourly Jobs</div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <p className="SortByDropdown">Sort by:</p>
        <div style={{ width: "170px" }}>
          <Dropdown
            options={[
              { label: "Start Date", value: "startDate" },
              { label: "Company A-Z", value: "companyAtoZ" },
            ]}
            placeholder="Select sorting"
            value={sortBy}
            onChange={(e) => {
              setPage(1);
              setSortBy(e);
            }}
          />
        </div>
      </div>
      <table
        style={{
          width: "100%",
          textAlign: "left",
          borderCollapse: "collapse",
          fontFamily: "Libre Franklin",
        }}
      >
        <thead>
          <tr
            style={{
              borderBottom: "2px solid #C4C4C4",
              height: 60,
            }}
          >
            <th style={{ width: "13%", color: "#1c1c1c" }}>Start Date</th>
            <th style={{ width: "13%", color: "#1c1c1c" }}>Start Time</th>
            <th style={{ width: "13%", color: "#1c1c1c" }}>End Time</th>
            <th style={{ width: "20%", color: "#1c1c1c" }}>Company Name</th>
            <th style={{ width: "15%", color: "#1c1c1c" }}>Job Title</th>
            <th style={{ width: "20%", color: "#1c1c1c" }}>Status</th>
            <th style={{ width: "5%" }}></th>
          </tr>
        </thead>
        {loading ? (
          <tr style={{ textAlign: "center", height: 100 }}>
            <td colSpan="7">
              <CircularProgress size={40} />
            </td>
          </tr>
        ) : (
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <ListRowItem
                  item={item}
                  index={index}
                  viewContract={viewContract}
                  getReturnStatus={getReturnStatus}
                  setDrawerPage={setDrawerPage}
                  setDrawerOpen={setDrawerOpen}
                  handleClick={handleClick}
                  anchorEl={anchorEl}
                />
              ))
            ) : (
              <tr style={{ textAlign: "center", height: 100 }}>
                <td colSpan="7">
                  <p>No data found.</p>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      {!loading && data.length > 0 && (
        <div className="advancedSearchPagination" style={{ marginTop: 85, marginBottom: 86 }}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={page}
            onChange={(e, value) => setPage(value)}
            style={{ justifyContent: "center" }}
            size="large"
          />
        </div>
      )}
      <DrawerComponent
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setDrawerPage("contractCancel");
        }}
        component={
          <>
            {drawerPage === "viewCancellationRequest" ? (
              <ContractCancel
                finalCancelJob={(id) => {
                  finalCancelJob(id);
                }}
                setDrawerPage={() => setDrawerPage("requestContract")}
                page="HOURLY"
                handleClose={() => {
                  setDrawerOpen(false);
                }}
                finalCancelContractJob={(id) => {
                  finalCancelContractJob(id);
                }}
                contractId={contractId}
              />
            ) : drawerPage === "cancelJob" ? (
              <HourlyContractRequest
                handleClose={() => {
                  setDrawerOpen(false);
                }}
                contractId={contractId}
                cancelJob={(id, data) => {
                  cancelJob(id, data);
                }}
              />
            ) : (
              <HourlyContract setDrawerPage={() => setDrawerPage("requestContract")} contractId={contractId} isEmployee />
            )}
          </>
        }
        width="80%"
      />
    </>
  );
};

function ListRowItem({ item, index, viewContract, setDrawerPage, setDrawerOpen, handleClick, anchorEl, getReturnStatus }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const progresBar = getReturnStatus(item.status, item.contractStartedOn, item.jobEndOn, item.cancelBy);

  return (
    <tr
      key={index}
      style={{
        height: 87,
        borderBottom: "2px solid #C4C4C4",
        backgroundColor: "inherit",
      }}
    >
      <td style={{ width: "13%", color: "#1c1c1c" }}>
        {item.schedule && item.schedule.length > 0 && moment(item.schedule[0].dateSchedule).utc(0).format("MMM DD, YY")}
      </td>
      <td style={{ width: "12%", color: "#1c1c1c" }}>
        {item.schedule && item.schedule.length > 0 && moment(item.schedule[0].startTime).format("h:mm A")}{" "}
      </td>
      <td style={{ width: "13%", color: "#1c1c1c" }}>
        {item.schedule && item.schedule.length > 0 && moment(item.schedule[0].endTime).format("h:mm A")}{" "}
      </td>
      <td style={{ width: "20%" }}>
        {item.profiles && item.profiles.length > 0 && item.profiles[0].company && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <AccountCircleIcon
                style={{
                  height: "70px",
                  width: "70px",
                }}
              />
            </div>
            <div
              style={{
                textAlign: "center",
                margin: "auto",
                marginLeft: 10,
                opacity: 0.78,
              }}
            >
              <p className="hiringEmployeeName">{item.profiles[0].company.name}</p>
            </div>
          </div>
        )}
      </td>
      <td style={{ width: "15%", color: "#1c1c1c" }}>{item.jobs && item.jobs.length > 0 && item.jobs[0].title}</td>
      <td style={{ width: "20%", paddingRight: 20 }}>
        <ProgressBar type="HOURLY" variant={progresBar.status} />
      </td>
      <td style={{ width: "5%" }}>
        <MoreHorizIcon
          onClick={(e) => {
            handleClick(e, item);
            setIsMenuOpen(true);
          }}
          style={{
            fill: "#9A9A9A",
            border: "2px solid #9A9A9A",
            borderRadius: "50%",
            height: 34,
            width: 34,
          }}
        />
        {/*TODO status base condition*/}
        <Menu
          id={`simple-menu-${item._id}`}
          key={`simple-menu-${item._id}`}
          anchorEl={anchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={() => {
            setIsMenuOpen(false);
          }}
        >
          <MenuItem
            onClick={() => {
              viewContract();
              setIsMenuOpen(false);
            }}
          >
            View Contract
          </MenuItem>

          {moment(item.jobEndOn) > moment() && (
            <>
              <MenuItem
                onClick={() => {
                  setDrawerPage("cancelJob");
                  setDrawerOpen(true);
                  setIsMenuOpen(false);
                }}
                disabled={item.status !== "STARTED"}
              >
                Cancel Job
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDrawerPage("viewCancellationRequest");
                  setDrawerOpen(true);
                  setIsMenuOpen(false);
                }}
                disabled={
                  (item.status !== "CANCELLATION_REQUESTED" && item.cancelBy !== "EMPLOYER") ||
                  (item.status === "CANCELLATION_REQUESTED" && item.cancelBy !== "EMPLOYER") ||
                  item.status === "CANCELLED" ||
                  item.status === "STARTED"
                }
              >
                View cancellation request
              </MenuItem>
            </>
          )}
        </Menu>
      </td>
    </tr>
  );
}
export default HourlyJobs;
