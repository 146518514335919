import { combineReducers } from 'redux'
import formReducer from './form_reducer';
import specialityReducer from './speciality_reducer';
import suggestedSkillsReducer from './suggested_skills_reducer';
import talentSearchReducer from './talent_search_reducer'
import fieldOfWorkReducer from './field_of_work_reducer'
import locationReducer from './location_reducer'
import profileReducer from './profile_reducer'
import employerRegisterReducer from './employer_register_reducer'
import jobPostReducer from './job_post_reducer'
import messagesReducer from './messages_reducer'

const rootReducer = combineReducers({
		formReducer,
		specialityReducer,
		suggestedSkillsReducer,
		talentSearchReducer,
		fieldOfWorkReducer,
		locationReducer,
		profileReducer,
		employerRegisterReducer,
		jobPostReducer,
		messagesReducer,
})

export default rootReducer
