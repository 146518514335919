import React from "react";
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
} from "recharts";
import Photo from "../../../Assets/DashboardIcon.svg";
import { CustomPieChart } from "./Overview";
import moment from 'moment';
const FinanceReportPDFView = ({data, startDate, endDate}) => {
    return (
        <div>
            <div>
                <div className="section">
                    <div style={{ display: 'flex' }}>
                        <img src={Photo} alt="" />
                        <div style={{ marginLeft: 10, alignSelf: 'center' }}>
                            <p style={{ color: '#070707' }}>{localStorage.getItem('fullName')}</p>
                        </div>
                    </div>
                    <div style={{ marginTop: 15, display: 'flex' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Report Period:</p>
                        <p>{startDate !== '01-1970' && moment(`${parseInt(startDate.split('-')[1])}-${parseInt(startDate.split('-')[0])}-01`).format('MMM DD, YY')} -
                         {endDate && moment(`${parseInt(endDate.split('-')[1])}-${parseInt(endDate.split('-')[0])}-01`).format('MMM DD, YY')}</p>
                    </div>
                    <div style={{ marginTop: 15, display: 'flex' }}>
                        <p style={{ color: '#9a9a9a', width: 150 }}>Report Date:</p>
                        <p>{moment().format('MMM DD, YY')}</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="section">
                    <div style={{ display: 'flex', flexDirection:'column' }}>
                        <div>
                            <p style={{ color: '#070707', fontWeight: 400 }}>Local Summary</p>
                            <div style={{ display: 'flex', margin: '20px 0' }}>
                                <p style={{ color: '#9a9a9a', width: 150 }}>Total Job Value:</p>
                                <p>({localStorage.getItem('localCurrency')}) {data && data.totalLocalJobsValue}</p>
                            </div>
                            <div style={{ display: 'flex', margin: '20px 0' }}>
                                <p style={{ color: '#9a9a9a', width: 150 }}>Total Service Fee:</p>
                                <p>({localStorage.getItem('localCurrency')}) {data && data.totalLocalServiceValue}</p>
                            </div>
                            <div style={{ display: 'flex', margin: '20px 0' }}>
                                <p style={{ color: '#9a9a9a', width: 150 }}>Total Cost:</p>
                                <p>({localStorage.getItem('localCurrency')}) {data && data.totalLocalJobsValue + data.totalLocalServiceValue}</p>
                            </div>
                        </div>
                        <div>
                            <p style={{ color: '#070707', fontWeight: 400 }}>
                                International Summary
                            </p>
                            <div style={{ display: 'flex', margin: '20px 0' }}>
                                <p style={{ color: '#9a9a9a', width: 150 }}>Total Job Value:</p>
                                <p>${data && data.totalInternationalJobsValue}</p>
                            </div>
                            <div style={{ display: 'flex', margin: '20px 0' }}>
                                <p style={{ color: '#9a9a9a', width: 150 }}>Total Service Fee:</p>
                                <p>${data && data.totalInternationalServicevalue}</p>
                            </div>
                            <div style={{ display: 'flex', margin: '20px 0' }}>
                                <p style={{ color: '#9a9a9a', width: 150 }}>Total Cost:</p>
                                <p>${data && data.totalInternationalJobsValue + data.totalInternationalServicevalue}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="section">
                    <p style={{ color: '#9a9a9a', fontWeight: 500, fontSize: 24 }}>Local Hires</p>
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            fontFamily: 'Libre Franklin',
                            width: '100%',
                        }}
                    >
                        <thead>
                            <tr
                                style={{
                                    borderBottom: '2px solid #C4C4C4',
                                    height: 60,
                                    textAlign: 'left',
                                }}
                            >
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Job Title
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Employee
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Contract Type
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Contract No.
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Payment Type
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Job Value
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Service Fee
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Total Cost
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.localHires.length > 0 ?
                            data.localHires.map((item) => {
                                return (
                            <tr
                                style={{
                                    borderBottom: '2px solid #C4C4C4',
                                    height: 60,
                                    textAlign: 'left',
                                }}
                            >
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.jobTitle}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.employee}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.contractType}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.contractNumber}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.paymentType}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {localStorage.getItem('localCurrency')}{item.jobValue}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {localStorage.getItem('localCurrency')}{item?.serviceFee || 0}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {localStorage.getItem('localCurrency')}{parseFloat(item.jobValue)}
                                </td>
                            </tr>); }): null}
                            <tr style={{ height: 60, textAlign: 'left' }}>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    ${data && data.totalLocalJobsValue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 12,
                                    }}
                                >
                                    ${data && data.totalLocalServiceValue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 12,
                                    }}
                                >
                                    ${parseFloat(data.totalLocalJobsValue) + parseFloat(data.totalLocalServiceValue)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <div className="section">
                    <p style={{ color: '#9a9a9a', fontWeight: 500, fontSize: 24 }}>
                        International Hires
                    </p>
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            fontFamily: 'Libre Franklin',
                            width: '100%',
                        }}
                    >
                        <thead>
                            <tr
                                style={{
                                    borderBottom: '2px solid #C4C4C4',
                                    height: 60,
                                    textAlign: 'left',
                                }}
                            >
                                <th style={{ width: '18%', color: '#1c1c1c', fontSize: 12 }}>
                                    Job Title
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Employee
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Contract Type
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Contract No.
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Payment Type
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Job Value
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Service Fee
                                </th>
                                <th style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    Total Cost
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {data && data.internationalHires.length > 0 ? 
                            data.internationalHires.map((item) => {
                                return(
                                    <tr
                                style={{
                                    borderBottom: '2px solid #C4C4C4',
                                    height: 60,
                                    textAlign: 'left',
                                }}
                            >
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.jobTitle}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.employee}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.contractType}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.contractNumber}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    {item.paymentType}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    ${item.jobValue}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    ${item?.serviceFee || 0}
                                </td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    ${parseFloat(item.jobValue)}
                                </td>
                            </tr>)
                            }) : null }
                            <tr style={{ height: 60, textAlign: 'left' }}>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}></td>
                                <td style={{ width: '13%', color: '#1c1c1c', fontSize: 12 }}>
                                    ${data.totalInternationalJobsValue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 12,
                                    }}
                                >
                                    ${data.totalInternationalServicevalue}
                                </td>
                                <td
                                    style={{
                                        marginTop: 10,
                                        width: '13%',
                                        color: '#1c1c1c',
                                        fontSize: 12,
                                    }}
                                >
                                    ${parseFloat(data.totalInternationalJobsValue) + parseFloat(data.totalInternationalServicevalue)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{width:'100%', display: 'flex', justifyContent: 'space-between', alignContent:'center', flexDirection:'column' }}>
                <div
                
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '43%',
                        
                        height: 350,
                    }}
                >
                    <p
                        style={{
                            color: '#9a9a9a',
                            marginBottom: 40,
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        Local Jobs({localStorage.getItem('localCurrency')})
                    </p>
                    <ResponsiveContainer width="99%" height={270}>
                        <BarChart
                            data={data && data.localJobsChartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total" fill="#621EEE" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div
                
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '43%',
                        padding: '20px 25px',
                        height: 350,
                    }}
                >
                    <p
                        style={{
                            color: '#9a9a9a',
                            marginBottom: 40,
                            fontWeight: 700,
                            textAlign: 'center',
                        }}
                    >
                        International Jobs(USD)
                    </p>

                    <ResponsiveContainer width="99%" height={270}>
                        <BarChart
                            data={data && data.internationalJobsChartData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total" fill="#DEB136" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            </div>
    )
}

export default FinanceReportPDFView;