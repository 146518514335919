export const verificationLevel = [
    { label: 'Link to Verified Company', value: 'Link to Verified Company' },
    { label: 'Individual Verification', value: 'Individual Verification' }
];

export const identificationLevel = [
    { label: 'Bussiness Licence Number', value: 'Bussiness Licence Number' },
    { label: "Driver's Licence Number", value: "Driver's Licence Number" },
    { label: 'Passport Number', value: 'Passport Number' },
];

export const notificationSettings = [
    {
        title: "When I post a job",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When I receive an application",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When I am hired for a job",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When a payment is captured",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When my job posts are about to expire",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "Send me a copy of the contract",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When an initial payment is pre-authorized",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When I receive a job invitation",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When a contract is cancelled",
        subTitle: "",
        email: false,
        mobile: false
    }
];