import React from 'react';
import moment from 'moment';

import './DataTable.scss';

import ProgressBar from '../ProgressBar/ProgressBar';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Dropdown from '../DropdownComponent/Dropdown.component';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { JobpostAndInvitationExpiry } from '../../utils/commonFunction';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorderOutlined';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';

const data = [
    {
        title: 'Looking for a web dev',
        startDate: 'Jun 02, 21',
        status: 'IN_Progress',
        data: [
            {
                name: 'Charles Banks1',
                jobTitle: 'UI Designer - Typography',
                address: 'Toronto, Canada',
                hour: '$30/hr',
                status: 'Declined',
                icon: 'icons',
            },
            {
                name: 'Charles Banks2',
                jobTitle: 'UI Designer - Typography',
                address: 'Toronto, Canada',
                hour: '$30/hr',
                status: 'Declined',
                icon: 'icons',
            },
            {
                name: 'Charles Banks3',
                jobTitle: 'UI Designer - Typography',
                address: 'Toronto, Canada',
                hour: '$30/hr',
                status: 'Declined',
                icon: 'icons',
            },
            {
                name: 'Charles Banks4',
                jobTitle: 'UI Designer - Typography',
                address: 'Toronto, Canada',
                hour: '$30/hr',
                status: 'Declined',
                icon: 'icons',
            },
        ],
    },
    {
        title: 'Accountant required',
        startDate: 'Jun 01, 21',
        status: 'IN_Progress',
        data: [],
    },
];

const DataTable = (props) => {
    return (
        <>
            <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                        <th style={{ width: '5%' }}></th>
                        <th style={{ width: '35%', color: '#1c1c1c' }}>Job Title</th>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>Start date</th>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>Status</th>
                        <th style={{ width: '20%' }}></th>
                    </tr>
                </thead>
                {props.loading ? (
                    <tr style={{ textAlign: 'center', height: 100 }}>
                        <td colSpan="5">
                            <CircularProgress size={40} />
                        </td>
                    </tr>
                ) : null}
                {!props.loading && (
                    <tbody>
                        {props.data.length > 0 ? (
                            props.data.map((item, index) => (
                                <DataRow
                                    title={item.title}
                                    startDate={
                                        item.jobType === 'FIXED'
                                            ? moment(item.startDate).utc(0).format('MMM DD, YY')
                                            : moment(
                                                  item.hourlySchedule &&
                                                      item.hourlySchedule.length > 0 &&
                                                      item.hourlySchedule[0].dateSchedule
                                              )
                                                  .utc(0)
                                                  .format('MMM DD, YY')
                                    }
                                    key={index}
                                    subData={item.application}
                                    setDrawerOpen={props.setDrawerOpen}
                                    type={item.jobType}
                                    jobID={item._id}
                                    jobExpiryDate={item.jobExpiryDate}
                                    getJobPostDetails={props.getJobPostDetails}
                                />
                            ))
                        ) : (
                            <tr style={{ textAlign: 'center', height: 100 }}>
                                <td colSpan="5">
                                    <p>No data found.</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            {!props.loading && props.data.length > 0 && (
                <div className="advancedSearchPagination" style={{ marginTop: 80 }}>
                    <Pagination
                        count={Math.ceil(props.total / 10)}
                        page={props.page}
                        onChange={(e, value) => props.onPaginationChange(value)}
                        style={{ justifyContent: 'center' }}
                        size="large"
                    />
                </div>
            )}
        </>
    );
};

const DataRow = (props) => {
    const pageIndex = 6;
    const [open, setOpen] = React.useState(false);
    const [itemShow, setItemShow] = React.useState(1);
    const newArray = React.useMemo(() => {
        return props.subData && props.subData.slice(0, itemShow * pageIndex);
    }, [itemShow]);

    const isShowMore = React.useMemo(() => {
        return newArray.length !== props.subData.length;
    }, [newArray]);

    const status = JobpostAndInvitationExpiry(props.jobExpiryDate);
    var progressVariantType = 'ACTIVE';
    var progressBarTime = '';
    if (status.hours > 48) {
        progressVariantType = 'ACTIVE';
    } else if (status.hours <= 48 && status.hours > 24) {
        progressVariantType = 'JOB_EXPIRED';
    } else if (status.hours <= 24) {
        progressVariantType = 'TIME_LEFT';
        progressBarTime = status.timeRemaing;
    }

    const removeFromFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.deleteMethod(`${API.REMOVE_FAVORITE}${id}`, headers)
            .then((res) => {
                props.getJobPostDetails();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const addToFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        let data = {
            emp_id: id,
        };

        APIHelper.putMethod(`${API.ADD_TO_FAVORITE}`, data, headers)
            .then((res) => {
                props.getJobPostDetails();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <tr
                style={{
                    height: 120,
                    borderBottom: '2px solid #C4C4C4',
                    backgroundColor: open ? '#F2F2F2' : 'inherit',
                }}
            >
                <td style={{ width: '5%' }}>
                    {props.subData.length > 0 && (
                        <>
                            {open ? (
                                <RemoveOutlinedIcon
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        color: '#A3C771',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setOpen(!open)}
                                />
                            ) : (
                                <AddOutlinedIcon
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        color: '#A3C771',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setOpen(!open)}
                                />
                            )}
                        </>
                    )}
                </td>
                <td
                    style={{
                        width: '35%',
                        fontWeight: open ? 700 : 500,
                        fontSize: 20,
                        color: '#1c1c1c',
                    }}
                >
                    {props.title}
                </td>
                <td
                    style={{
                        width: '20%',
                        fontWeight: open ? 700 : 500,
                        fontSize: 20,
                        color: '#1c1c1c',
                    }}
                >
                    {moment(props.startDate).utc(0).format('MMM DD, YY')}
                </td>
                <td style={{ width: '20%', color: '#1c1c1c' }}>
                    <ProgressBar
                        type={'JobPostAndInvitation'}
                        variant={progressVariantType}
                        time={progressBarTime}
                    />
                </td>
                <td
                    style={{ width: '20%', textAlign: 'right', paddingRight: 15, color: '#1c1c1c' }}
                >
                    <VisibilityOutlinedIcon
                        style={{
                            width: '36px',
                            height: '36px',
                            background: '#1876D2',
                            color: '#FFF',
                            padding: 4,
                            borderRadius: 30,
                            cursor: 'pointer',
                        }}
                        onClick={() => props.setDrawerOpen(props.jobID)}
                    />
                </td>
            </tr>
            {open && (
                <>
                    {newArray &&
                        newArray.length > 0 &&
                        newArray.map((item) => (
                            <tr style={{ borderBottom: '2px solid #C4C4C4', height: 160 }}>
                                <td></td>
                                <td>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            {item.profiles.photo ? (
                                                <img
                                                    src={item.profiles.photo}
                                                    style={{
                                                        height: 70,
                                                        width: 70,
                                                        borderRadius: 70,
                                                    }}
                                                />
                                            ) : (
                                                <AccountCircleIcon
                                                    style={{ height: '70px', width: '70px' }}
                                                />
                                            )}
                                        </div>
                                        <div style={{ marginLeft: 26 }}>
                                            <h3
                                                className="sub-row-name"
                                                style={{ opacity: 0.78, display: 'inline-flex' }}
                                            >
                                                {item.users &&
                                                    `${item.users.firstName} ${item.users.lastName}`}
                                                {item.isFav ? (
                                                    <div
                                                        onClick={() => {
                                                            removeFromFavorite(item.users._id);
                                                            // this.setState({
                                                            //     isFavorite: !this.state.isFavorite,
                                                            // });
                                                        }}
                                                    >
                                                        <FavoriteIcon
                                                            style={{
                                                                color: '#1876D2',
                                                                marginLeft: 14,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            addToFavorite(item.users._id);
                                                            // this.setState({
                                                            //     isFavorite: !this.state.isFavorite,
                                                            // });
                                                        }}
                                                    >
                                                        <FavoriteBorderIcon
                                                            style={{
                                                                color: '#1876D2',
                                                                marginLeft: 14,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </h3>
                                            <p className="sub-row-title">
                                                {item.profiles &&
                                                    item.profiles.field &&
                                                    item.profiles.field.length > 0 &&
                                                    item.profiles.field[0].name}
                                            </p>
                                            <p className="sub-row-title-second">
                                                {item.profiles.address &&
                                                    item.profiles.address.length > 0 &&
                                                    `${item.profiles.address[0].city}, ${item.profiles.address[0].country}`}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td style={{ fontSize: 20 }}>
                                    {/* ${item.profiles && item.profiles.rate}/hr */}$
                                    {props.type === 'HOURLY'
                                        ? `${item.proposeHourlyRate}/hr`
                                        : item.proposedFeeByEmployee}
                                </td>
                                <td style={{ fontSize: 20 }}>
                                    {item.status === 'APPROVED'
                                        ? 'In progress'
                                        : item.status === 'PENDING'
                                        ? 'Invited'
                                        : item.status === 'APPLIED'
                                        ? 'Applied'
                                        : item.status === 'DECLINED'
                                        ? 'Declined'
                                        : item.status === 'WITHDRAW'
                                        ? 'Withdraw'
                                        : 'In progress'}
                                </td>
                                <td></td>
                            </tr>
                        ))}

                    {isShowMore && (
                        <tr style={{ textAlign: 'center', height: 70, marginBottom: 20 }}>
                            <td colSpan="5">
                                <div
                                    style={{
                                        height: 52,
                                        backgroundColor: '#F2F2F2',
                                        lineHeight: '52px',
                                    }}
                                    onClick={() => setItemShow(itemShow + 1)}
                                >
                                    <p style={{ fontSize: 20, color: '#000' }}>Show more results</p>
                                </div>
                            </td>
                        </tr>
                    )}
                </>
            )}
        </>
    );
};

export default DataTable;
