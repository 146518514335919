import React from 'react';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MessageIcon from '@material-ui/icons/Message';
import { Chip } from '@material-ui/core';

import Rating from '../../Components/Rating/Rating';
import Button from '../../Components/Button/Button';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
import ScoreChart from '../../Components/GaugeChart/GaugeChart';
import { PortFolioCard } from './EmployeeAccountDetails';

class EmployeeDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: '',
            firstName: '',
            lastName: '',
            address: {
                state: '',
                country: '',
            },
            rating: 0,
            review: 0,
            fieldOfWork: '',
            yearOfExperience: 0,
            highlight: '',
            skills: [],
            rate: 0,
            internationalRate: 0,
            educations: [],
            certifications: [],
            languages: [],
            linkedIn: '',
            employeementHistory: [],
            portfolios: [],
            feedBacks: [],
            totalPunctuality: 0,
            totalCommunication: 0,
            totalSkills: 0,
        };
    }

    componentDidMount() {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(API.GET_EMPLOYEES_ACTIVE_USER, headers).then((response) => {
            const { employeeModal, employee } = response;
            console.log('response', response);

            const skills = [];
            if (employee.speciality && employee.speciality.length > 0) {
                employee.speciality.forEach((spec) => {
                    const skillArr = employee.skills.filter(
                        (skill) => skill.speciality_id === spec.id
                    );
                    const skilles = skillArr.map((skill) => {
                        return {
                            specialty_id: skill.speciality_id,
                            _id: skill.skills_id,
                            name: skill.name,
                        };
                    });
                    const obj = {
                        speciality: spec.name,
                        skill: skilles,
                    };
                    skills.push(obj);
                });
            }

            this.setState({
                firstName: employeeModal.firstName,
                lastName: employeeModal.lastName,
                address: employee.address && employee.address[0],
                yearOfExperience: employee.work_experience && employee.work_experience,
                fieldOfWork: employee.field && employee.field.name,
                rate: employee.rate && employee.rate,
                internationalRate: employee.international_rate && employee.international_rate,
                rating: employee.ratings,
                review: employee.review_count,
                educations: employee.education,
                certifications: employee.certifications,
                languages: employee.languages,
                linkedIn: employee.linkedin_link,
                photo: employee.photo,
                employeementHistory: employee.employement,
                skills: skills,
                highlight: employee.description,
            });
        });

        APIHelper.getMethod(
            `${API.GET_EMPLOYEE_PORTFOLIO}${localStorage.getItem('userId')}`,
            headers
        ).then((response) => {
            const portfolio = [...response.employee.portfolio];
            for (let i = 0; i < 6 - response.employee.portfolio.length; i++) {
                portfolio.push({
                    file_name: '',
                    file_name_original: '',
                    file_url: '',
                    file_type: '',
                });
            }

            this.setState({
                portfolios: portfolio,
            });
        });

        APIHelper.getMethod(
            `${API.GET_EMPLOYEE_FEEDBACK}${localStorage.getItem('userId')}`,
            headers
        ).then((response) => {
            this.setState({
                feedBacks: response,
            });
            if (response && response.length > 0) {
                const punctuality = response.map((feed) => feed.employeeRatings.punctuality);
                const skills = response.map((feed) => feed.employeeRatings.skills);
                const communication = response.map(
                    (feed) => feed.employeeRatings.communication
                );
                const totalPun = punctuality.reduce((a, b) => a + b);
                const totalSkil = skills.reduce((a, b) => a + b);
                const totalCom = communication.reduce((a, b) => a + b);
                this.setState({
                    totalPunctuality: totalPun,
                    totalCommunication: totalCom,
                    totalSkills: totalSkil,
                });
            }
        });
    }

    render() {
        return (
            <>
                <Card style={{ margin: 60, padding: 25 }}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            {this.state.photo ? (
                                <div
                                    style={{
                                        height: '100px',
                                        width: '100px',
                                        backgroundImage: `url('${this.state.photo}')`,
                                        backgroundSize: 'cover',
                                        borderRadius: 50,
                                    }}
                                ></div>
                            ) : (
                                <AccountCircleIcon style={{ height: '100px', width: '100px' }} />
                            )}
                        </div>
                        <div style={{ marginLeft: 26, width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontSize: 22,
                                                fontFamily: 'Libre Franklin',
                                            }}
                                        >
                                            {this.state.firstName} {this.state.lastName}
                                        </h3>
                                        <FavoriteIcon
                                            style={{ color: '#9A9A9A', marginLeft: 14 }}
                                        />
                                        <MessageIcon style={{ color: '#9A9A9A', marginLeft: 8 }} />
                                    </div>
                                    <p style={{ fontFamily: 'Libre Franklin' }}>
                                        {this.state.fieldOfWork}
                                    </p>
                                    <p style={{ color: '#9A9A9A', fontFamily: 'Libre Franklin' }}>
                                        {this.state.address && this.state.address.state},{' '}
                                        {this.state.address && this.state.address.country}
                                    </p>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginTop: 15,
                                        }}
                                    >
                                        <p style={{ fontFamily: 'Libre Franklin' }}>
                                            Experience: {parseFloat(this.state.yearOfExperience) >=20 ? '20+' : this.state.yearOfExperience} years
                                        </p>
                                        <LinkedInIcon
                                            style={{ color: '#0288D1', marginLeft: 26 }}
                                            onClick={() =>
                                                window.open(this.state.linkedIn, '_blank')
                                            }
                                        />
                                    </div>
                                </div>
                                <div style={{ paddingTop: 30 }}>
                                    <p style={{ fontFamily: 'Libre Franklin' }}>
                                        Rate: {this.state.rate}/hr
                                    </p>
                                    <p style={{ fontFamily: 'Libre Franklin' }}>
                                        Int. Rate: {this.state.internationalRate} USD/hr
                                    </p>
                                </div>
                                <div>
                                    <Rating rating={this.state.rating} color="#a4c772" />
                                    <ScoreChart score={parseInt(500)} totalScore={1000} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 30 }}>
                        <p style={{ color: '#9A9A9A', fontFamily: 'Libre Franklin' }}>Highlights</p>
                        <div
                            style={{
                                height: 200,
                                border: '1px solid #9A9A9A',
                                borderRadius: 4,
                                padding: 6,
                                marginTop: 10,
                            }}
                        >
                            <p style={{ fontFamily: 'Libre Franklin' }}>{this.state.highlight}</p>
                        </div>
                    </div>
                </Card>
                <div style={{ margin: 60 }}>
                    <div className="row">
                        <div
                            className="col-md-3"
                            style={{ paddingRight: 20, borderRight: '1px solid #C4C4C4' }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>Punctuality</p>
                                <Rating rating={this.state.totalPunctuality} color="#a4c772" />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 16,
                                }}
                            >
                                <p>Skills</p>
                                <Rating rating={this.state.totalSkills} color="#a4c772" />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 16,
                                }}
                            >
                                <p>Communication</p>
                                <Rating rating={this.state.totalCommunication} color="#a4c772" />
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <h3 style={{ fontFamily: 'Libre Franklin' }}>Education</h3>
                            {this.state.educations.map((edu) => (
                                <div style={{ marginBottom: 35 }}>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <p>{edu.school_name}</p>
                                        <p>
                                            {moment(edu.startdate).utc(0).format('YYYY')}-
                                            {moment(edu.enddate).utc(0).format('YYYY')}
                                        </p>
                                    </div>
                                    <p style={{ marginTop: 10 }}>{edu.major}</p>
                                    <p style={{ marginTop: 10 }}>{edu.degree}</p>
                                </div>
                            ))}
                            <div className="EditDetailsBottomLineStyles" />
                            <h3 style={{ fontFamily: 'Libre Franklin' }}>Certifications</h3>
                            {this.state.certifications.map((cert) => (
                                <div style={{ marginBottom: 12 }}>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <p>{cert.name}</p>
                                        <p>{cert.year}</p>
                                    </div>
                                </div>
                            ))}
                            <div className="EditDetailsBottomLineStyles" />
                            <h3 style={{ fontFamily: 'Libre Franklin' }}>Languages</h3>
                            <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                                {this.state.languages.map((lang) => (
                                    <p style={{ marginRight: 16 }}>{lang}</p>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div style={{ marginLeft: 25, marginRight: 25 }}>
                                <Card style={{ padding: 25 }}>
                                    <p
                                        style={{
                                            fontSize: 24,
                                            fontFamily: 'Libre Franklin',
                                            fontWeight: 600,
                                            marginBottom: 25,
                                            color: '#1C1C1C',
                                        }}
                                    >
                                        Specialities
                                    </p>
                                    {this.state.skills.map((item, id) => (
                                        <div
                                            key={id}
                                            style={{ marginBottom: '5px', marginTop: '8px' }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    color: '#454545',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {item.speciality}{' '}
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    color: '#454545',
                                                    marginBottom: '5px',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {item.skill.map((skill, id) => (
                                                    <div
                                                        style={{
                                                            marginLeft: '5px',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        <Chip
                                                            height={'27px'}
                                                            backgroundColor={'#a4c772'}
                                                            borderRadius={'8px'}
                                                            label={skill.name}
                                                            color={'#ffffff'}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </Card>

                                <Card style={{ padding: 25, marginTop: 35 }}>
                                    <p
                                        style={{
                                            fontSize: 24,
                                            fontFamily: 'Libre Franklin',
                                            fontWeight: 600,
                                            marginBottom: 25,
                                            color: '#1C1C1C',
                                        }}
                                    >
                                        Employment History
                                    </p>
                                    {this.state.employeementHistory.map((emp, index) => (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <div>
                                                    <p className="paragraph">{emp.company}</p>
                                                </div>
                                                <p className="paragraph">
                                                    {' '}
                                                    {emp.date_left && emp.date_left != null ? (
                                                        <p className="paragraph">
                                                            {moment(
                                                                emp.date_join,
                                                                'MM-YYYY'
                                                            ).format('MMM YY')}{' '}
                                                            -{' '}
                                                            {moment(
                                                                emp.date_left,
                                                                'MM-YYYY'
                                                            ).format('MMM YY')}
                                                        </p>
                                                    ) : (
                                                        <p className="paragraph">
                                                            {moment(
                                                                emp.date_join,
                                                                'MM-YYYY'
                                                            ).format('MMM YY') + ' - Present'}
                                                        </p>
                                                    )}{' '}
                                                </p>
                                            </div>
                                            <div style={{ marginTop: 13 }}>
                                                <p>{emp.position}</p>
                                            </div>
                                            <div style={{ marginTop: 13 }}>
                                                <p>{emp.description}</p>
                                            </div>
                                            {index + 1 !==
                                                this.state.employeementHistory.length && (
                                                <div
                                                    className="EditDetailsBottomLineStyles"
                                                    style={{ marginBottom: 33 }}
                                                />
                                            )}
                                        </>
                                    ))}
                                </Card>
                                <Card style={{ padding: 25, marginTop: 35 }}>
                                    <p
                                        style={{
                                            fontSize: 24,
                                            fontFamily: 'Libre Franklin',
                                            fontWeight: 600,
                                            marginBottom: 25,
                                            color: '#1C1C1C',
                                        }}
                                    >
                                        Portfolio
                                    </p>
                                    <div className="row" style={{ flexFlow: 'wrap' }}>
                                        {this.state.portfolios.map((item, index) => (
                                            <PortFolioCard
                                                onFileUpload={(value) =>
                                                    this.handlePortFolio(value, index)
                                                }
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                </Card>
                                <Card
                                    style={{
                                        marginTop: 47,
                                        padding: 25,
                                        fontFamily: 'Libre Franklin',
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 24,
                                            fontFamily: 'Libre Franklin',
                                            fontWeight: 600,
                                            color: '#1C1C1C',
                                            marginBottom: 25,
                                        }}
                                    >
                                        Client Reviews
                                    </p>
                                    {this.state.feedBacks.length === 0 && (
                                        <p style={{ textAlign: 'center' }}>No review</p>
                                    )}
                                    {this.state.feedBacks &&
                                        this.state.feedBacks.length > 0 &&
                                        this.state.feedBacks.map((feedback) => {
                                            const totalRating =
                                                feedback.employeeRatings &&
                                                parseFloat(
                                                    feedback.employeeRatings.punctuality || 0
                                                ) +
                                                    parseFloat(
                                                        feedback.employeeRatings.skills || 0
                                                    ) +
                                                    parseFloat(
                                                        feedback.employeeRatings.communication || 0
                                                    );
                                            return (
                                                <>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <div>
                                                            <p
                                                                style={{
                                                                    color: '#9A9A9A',
                                                                    fontSize: 20,
                                                                }}
                                                            >
                                                                {feedback.jobs &&
                                                                    feedback.jobs.length > 0 &&
                                                                    feedback.jobs[0].title}
                                                            </p>
                                                            <Rating
                                                                rating={totalRating / 3}
                                                                color="#a4c772"
                                                            />
                                                        </div>
                                                        <p
                                                            style={{
                                                                color: '#9A9A9A',
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            {moment(feedback.createdAt).format(
                                                                'MMM/YYYY'
                                                            )}
                                                        </p>
                                                    </div>
                                                    <p
                                                        style={{
                                                            color: '#9A9A9A',
                                                            marginTop: 20,
                                                            fontSize: 20,
                                                        }}
                                                    >
                                                        {feedback.feedback}
                                                    </p>
                                                    {/* <p
                                                    style={{
                                                        color: '#A3C771',
                                                        fontSize: 20,
                                                        marginTop: 20,
                                                        textDecoration: 'underline',
                                                    }}
                                                >
                                                    See More
                                                </p> */}
                                                    <div
                                                        className="EditDetailsBottomLineStyles"
                                                        style={{ marginBottom: 30 }}
                                                    />
                                                </>
                                            );
                                        })}
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EmployeeDetail;
