import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Home.scss';
//import '../../../Assets/dev-style.scss';
import EmpolinkLogo from '../../../Assets/images/logo.svg';
import InvisibleLogo from '../../../Assets/images/invisable-logo.svg';
import HereImg from '../../../Assets/images/hero-img.png';
import UserA from '../../../Assets/images/user-a.png';
import UserB from '../../../Assets/images/user-b.png';
import Platform from '../../../Assets/images/platform.png';
import FooterPublic from '../../../Components/FooterPublic/FooterPublic';
import HeaderPublic from '../../../Components/HeaderPublic/HeaderPublic';
import LogoGrey from '../../../Assets/Logo_Circle_Grey.svg';
import CardFlip from '../../../Components/CardFlip/CardFlip';
import { MobileStepper, Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import '../../../Components/CardFlip/CardFlip.scss';
import { Helmet } from 'react-helmet';

const cardDetails = [
    { title: 'AI, App, Software Development & Web Design', icon: 'fa-keyboard', backText: ['Application Developer', 'Full Stack Developer', 'Cloud Engineer', 'Game Developer', 'UX/UI Designer', 'Mobile App Developer', `<a href="/devjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Media Creation & Marketing', icon: 'fa-palette', backText: ['3D Modeler', 'Graphic Designer', 'Animation Designer', 'Marketing Specialist', 'Audio Technician', 'Content Writer', 'Interior Designer', `<a href="/mediajobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Consulting', icon: 'fa-pen-to-square', backText: ['Engineer', 'Project Manager', 'Architect', 'Telecom Engineer', `<a href="/engjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Finance & Insurance', icon: 'fa-chart-line', backText: ['Accountant', 'Financial Advisor', 'Insurance Specialist', 'Investment Manager', `<a href="/financejobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Office & Admin', icon: 'fa-folder-open', backText: ['Office Assistant', 'Translator', 'Business Analyst', 'Desktop Publisher', 'Human Resource Admin', `<a href="/adminjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Entertainment, Recreation & Hospitality', icon: 'fa-champagne-glasses', backText: ['Event planner', 'Hotel Staff', 'Tourism Services', 'Fitness Trainer', `<a href="/hospitalityjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'IT, Communication & Support', icon: 'fa-satellite-dish', backText: ['IT Support', 'Network Architect', 'Network Security Specialist', 'System Admin', `<a href="/itsupportjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Food & Serving Services', icon: 'fa-mug-hot', backText: ['Food Manufacturing', 'Cashier', 'Servers & Helpers', `<a href="/foodservicesjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Retail, Sales & Services', icon: 'fa-cart-shopping', backText: ['Store Assistant', 'Clerk', 'Cashier', 'Warehouse Assistant', `<a href="/retailjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] },
    { title: 'Technicians & Skilled Workers', icon: 'fa-lightbulb', backText: ['Building Inspector', 'Fire Alarm Technician', 'Heating & Cooling Technician', 'Electrician', `<a href="/technicianjobs" style="color: #009FF5; text-decoration: underline;">& more</a>`] }
];

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false,
            activeStep: 0,
        };
    }

    handleNext = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    renderCards() {
        return (
            <div className="card-row">
                {cardDetails.map((card, index) => (
                    <CardFlip key={index} card={card} />
                ))}
            </div>
        );
    }

    renderMobileCards() {
        const { activeStep } = this.state;
        const maxSteps = cardDetails.length;
        const card = cardDetails[activeStep];

        return (
            <div className="mobile-stepper-container">
                <CardFlip card={card} />
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                        </Button>
                    }
                    classes={{ root: 'custom-stepper' }}
                />
            </div>
        );
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink - Connecting Clients and Talents Simply, Fast, and Efficiently</title>
                    <meta name="keywords" content="Empolink, Client-Talent Platform, Freelance Opportunities, Talent Marketplace, Online Works, freelancing, Job Opportunities, Top hire, Job Platform, Job Matching, Remote Work, Hire Freelancers, Freelance Network, Freelancers, Job Sites, Full Stack Developer, Web Developer" />
                    <meta name="description" content="Empolink connects clients and talents quickly and efficiently. Discover a platform designed for seamless interactions and services." />
                    <meta property="og:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta property="og:description" content="Empolink bridges the gap between clients and talents, providing a streamlined platform for finding and offering services." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@@empolink" />
                    <meta name="twitter:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta name="twitter:description" content="Explore diverse freelance opportunities and connect with clients who need your skills on Empolink." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>

                </Helmet>
                <HeaderPublic /> {/* From HeaderPublic component */}
                <div className="logo-background">
                    <img src={LogoGrey} alt="Empolink Logo" className="background-logo" />
                </div>
                <section class="masthead">
                    <div class="container">
                        <div class="row masthead__flex">
                            <div class="col-md-6">
                                <div class="masthead__img">
                                    <img src={HereImg} alt="" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h1>
                                    EMPOWERING <span>LINKS</span><br /> BETWEEN <span>CLIENTS</span> AND <span>TALENTS</span> IN A SIMPLE, FAST AND EFFICIENT WAY
                                </h1>
                                <p>
                                    {/* no text */}
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    class="btn-primary"
                                    onClick={() => this.props.history.push('/signup')}
                                >
                                    SIGN UP NOW
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="image-text image-text--left lightgrey-bg section-padding">
                    <div class="container">
                        <div class="row flex-row">
                            <div class="col-md-6 image-text__half image-text__img">
                                <img src={UserA} alt="" style={{ maxWidth: '425px', width: '95%', margin: '0 auto', display: 'block' }} />
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    LOOKING TO <span>HIRE?</span>
                                </h2>
                                <p>
                                    Access all the tools you need to plan your job and find the top
                                    people. Hire as many people as you want for a fixed or random
                                    work schedule.{' '}
                                </p>
                                <p>
                                    Choose from our local or international pool of talents to best
                                    suit your needs.{' '}
                                </p>
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="image-text image-text--right section-padding">
                    <div class="container">
                        <div class="row flex-row flex-direction">
                            <div class="col-md-6 image-text__half image-text__img">
                                <img src={UserB} alt="" />
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    LOOKING FOR A <span>JOB?</span>
                                </h2>
                                <p>Working can be flexible and yet fulfilling! </p>
                                <p>
                                    Manage your own work calendar by choosing which days you want to
                                    work and find the best jobs that match your interests.
                                </p>
                                <p>
                                    No Platform <span class="yellow-text">Fee</span>* for Talents!
                                </p>
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                                <p></p>
                                <p>
                                    <span className="small-font">* See Platform <a href="/Pricing">Fees</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="image-text image-text--left lightgrey-bg section-padding">
                    <div class="container">
                        <div class="row flex-row">
                            <div class="col-md-6 image-text__half image-text__img">
                                <img src={Platform} alt="" />
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>PLATFORM</h2>
                                <p>
                                    We connect businesses with the right people looking for jobs and
                                    projects that match their schedule and expertise.{' '}
                                </p>
                                <p>
                                    With the rapidly changing market we strive to make the hiring
                                    and job finding process more efficient and enjoyable by
                                    empowering both sides with the right tools to manage their
                                    businesses, and by linking the top people with the right jobs in
                                    an easy to follow, quick and efficient way.{' '}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* New Card Section */}
                <section className="card-section section-padding">
                    <div className="container">
                        <div class="col-md-6 image-text__half image-text__text">
                            <h2>FIELDS & SPECIALTIES</h2>
                            <p></p>
                            <p></p>
                        </div>
                        <div className="desktop-view">
                            {this.renderCards()}
                        </div>
                        <div className="mobile-view">
                            {this.renderMobileCards()}
                        </div>
                        
                    </div>
                </section>
                <FooterPublic />
            </>
        );
    }
}

export default withRouter(Home);
