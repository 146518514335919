import React from 'react';
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import ContainedButtons from '../../../Components/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import Swal from 'sweetalert2';
import JobApplicationApply from '../../EmployeeSearch/EmployeeAdvancedSearchTalent/Component/JobApplicationApply';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import ContractInformation from './Component/ContractInformation';
import HourlyContract from '../../EmployerHiringProcess/ProcessStep/Component/HourlyContract';
import FixedContract from '../../EmployerHiringProcess/ProcessStep/Component/FixedContract';

const StyledRating = withStyles({
    iconFilled: {
        color: '#a3c771',
    },
    sizeSmall: {
        fontSize: '1.8vw',
    },
    iconHover: {
        color: '#a3c771',
    },
})(Rating);

const WorkHistory = (props) => {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [workHistoryData, setWorkHistoryData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [rate, setRate] = React.useState(0);
    const [employerID, setEmployerID] = React.useState('');
    const [jobID, setJobID] = React.useState('');
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [jobData, setJobData] = React.useState({});
    const [jobLoading, setJobLoading] = React.useState(false);
    const [employer, setEmployer] = React.useState({});
    const [employerProfile, setEmployerProfile] = React.useState({});
    const [applicationData, setApplicationData] = React.useState({});
    const [contractInfo, setContractInfo] = React.useState({});

    React.useEffect(() => {
        getListWorkHistory();
    }, [page]);

    const getListWorkHistory = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_WORK_HISTORY_END_CONTRACT + `${page}`, headers)
            .then((res) => {
                if (res) {
                    setWorkHistoryData(res[0].data);
                    setTotal(res[0].total);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const giveRate = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        let body = {
            employerRatings: rate,
            job_id: jobID,
        };
        APIHelper.postMethod(`${API.RATE_EMPLYOEE}${employerID}`, body, headers)
            .then((res) => {
                if (res && res === 'Feedback is already submitted') {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Information!',
                        text: 'Feedback has been already submitted!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((result) => {
                        if (result) {
                            setOpen(false);
                        }
                    });
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: 'Thank you for rating!',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((result) => {
                        if (result) {
                            setOpen(false);
                        }
                    });
                    getListWorkHistory();
                }
            })
            .catch((error) => {
                console.log(error);
                setOpen(false);
            });
    };

    const jobDetails = (id) => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                setJobData(response.job);
                setEmployer(response.employerModel);
                setEmployerProfile(response.employer);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    return (
        <>
            <div className="editDetailsContentHeading">Work History</div>
            {/* <ContainedButtons
                text="Rate"
                onClick={() => setOpen(true)}
                color={['#2493F1', '#15568D']}
            /> */}
            {open ? (
                <div id="overlay">
                    <div className="overlayImageStyle">
                        <div
                            style={{
                                width: '68.64vw',
                                height: 443,
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                            }}
                        >
                            <div onClick={() => setOpen(false)} className="inviteCardCrossStyles">
                                <ClearIcon />
                            </div>
                            <p
                                style={{
                                    marginTop: 50,
                                    textAlign: 'center',
                                    color: '#707070',
                                    fontSize: 30,
                                }}
                            >
                                Talent Feedback Form
                            </p>
                            <div
                                style={{
                                    marginTop: 80,
                                    marginLeft: 150,
                                    color: '#707070',
                                    fontSize: 25,
                                }}
                            >
                                <p>Rate your experience working with this client?</p>
                                <div style={{ marginTop: 25 }}>
                                    <StyledRating
                                        name="rate"
                                        size={'small'}
                                        value={rate}
                                        onChange={(e) => setRate(e.target.value)}
                                        precision={0.5}
                                    />
                                </div>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: 20 }}>
                                <ContainedButtons
                                    text="Submit"
                                    onClick={() => {
                                        giveRate();
                                    }}
                                    color={['#a4c772', '#4e8400']}
                                    height={55}
                                    width={215}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <table
                style={{
                    width: '100%',
                    textAlign: 'left',
                    borderCollapse: 'collapse',
                    fontFamily: 'Libre Franklin',
                    marginTop: 67,
                }}
            >
                <thead>
                    <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                        <th style={{ width: '30%', color: '#1c1c1c' }}>Company Name</th>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>Job Title</th>
                        <th style={{ width: '20%', color: '#1c1c1c' }}>End Date</th>
                        <th style={{ width: '30%' }}></th>
                    </tr>
                </thead>
                {loading ? (
                    <tr style={{ textAlign: 'center', height: 100 }}>
                        <td colSpan="4">
                            <CircularProgress size={40} />
                        </td>
                    </tr>
                ) : (
                    <tbody>
                        {workHistoryData.length > 0 ? (
                            workHistoryData.map((item) => (
                                <tr
                                    style={{
                                        height: 87,
                                        borderBottom: '2px solid #C4C4C4',
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <td style={{ width: '30%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div>
                                                <AccountCircleIcon
                                                    style={{ height: '70px', width: '70px' }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    margin: 'auto',
                                                    marginLeft: 10,
                                                    opacity: 0.78,
                                                }}
                                            >
                                                <p className="hiringEmployeeName">
                                                    {item.profiles &&
                                                        item.profiles.length > 0 &&
                                                        item.profiles[0].company &&
                                                        item.profiles[0].company.name}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '20%', color: '#1c1c1c' }}>
                                        {item.jobs && item.jobs.length > 0 && item.jobs[0].title}
                                    </td>
                                    <td style={{ width: '20%', color: '#656363' }}>
                                        {moment(item.jobEndOn).utc(0).format('MMM DD, YY')}
                                    </td>
                                    <td style={{ width: '30%', color: '#1c1c1c' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <ContainedButtons
                                                text="Rate Client"
                                                color={['#a4c772', '#4e8400']}
                                                height={48}
                                                onClick={() => {
                                                    setRate(0);
                                                    setJobID(
                                                        item &&
                                                            item.jobs &&
                                                            item.jobs.length > 0 &&
                                                            item.jobs[0] &&
                                                            item.jobs[0]._id
                                                    );
                                                    setEmployerID(item.employer);
                                                    setOpen(true);
                                                }}
                                                disabled={item.feedback.find(
                                                    (x) => x.ratingBy === 'EMPLOYEE'
                                                )}
                                            />
                                            <div
                                                style={{
                                                    height: 42,
                                                    width: 42,
                                                    backgroundColor: '#a4c772',
                                                    borderRadius: 42,
                                                    textAlign: 'center',
                                                    lineHeight: '62px',
                                                    marginLeft: 33,
                                                }}
                                            >
                                                <RemoveRedEyeOutlinedIcon
                                                    style={{
                                                        height: 34,
                                                        width: 34,
                                                        color: '#FFF',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setContractInfo(item);
                                                        if (item.jobs && item.jobs.length > 0) {
                                                            jobDetails(item.jobs[0]._id);
                                                            setDrawerOpen(true);
                                                            setApplicationData({
                                                                proposeHourlyRate:
                                                                    item.proposeHourlyRate,
                                                                empolinkServiceFee:
                                                                    item.empolinkServiceFee,
                                                                employeeCompensation:
                                                                    item.employeeCompensation || 0,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr style={{ textAlign: 'center', height: 100 }}>
                                <td colSpan="4">
                                    <p>No data found.</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            {!loading && workHistoryData.length > 0 && (
                <div
                    className="advancedSearchPagination"
                    style={{ marginTop: 85, marginBottom: 86 }}
                >
                    <Pagination
                        count={Math.ceil(total / 10)}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        style={{ justifyContent: 'center' }}
                        size="large"
                    />
                </div>
            )}
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    // <ContractInformation
                    //     jobData={jobData}
                    //     jobLoading={jobLoading}
                    //     employer={employer}
                    //     employerProfile={employerProfile}
                    //     onClose={() => setDrawerOpen(false)}
                    //     applicationData={applicationData}
                    //     contractInfo={contractInfo}
                    //     isApplied
                    // />
                    <>
                    {
                    contractInfo.contractType==="HOURLY" ? 
                    <HourlyContract contractId={contractInfo._id}/> :
                    <FixedContract contractId={contractInfo._id} />           
                    }
                    </>
                }
                width="80%"
            />
        </>
    );
};

export default WorkHistory;
