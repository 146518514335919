import React from 'react';
import ScreenSize from '../../components/screen_size'
import WorkScheduleHourlyMobile from './components/work_schedule_hourly_mobile'
import WorkScheduleHourlyWeb from './components/work_schedule_hourly_web'

export default class WorkScheduleHourly extends React.Component{

   render() {
   return (
     <ScreenSize
       mobile  = {<WorkScheduleHourlyMobile/>}
       tablet = {<WorkScheduleHourlyMobile/>}
       web = {<WorkScheduleHourlyWeb/>}/>
   );
  }
 }
