import React, { useEffect } from 'react';
import './EmployerTalentSearchAdvanced.scss';
import TextfieldCard from '../../Components/TextfieldCard/TextfieldCard';
import Slider from '../../Components/Slider/Slider';
import WideProfileCard from '../../Components/WideProfileCard/WideProfileCard';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ShortProfileCard from '../../Components/ShortProfileCard/ShortProfileCard';
import SingleEmployeeInviteDetails from '../SingleEmployeeInviteCards/SingleEmployeeInviteDetails';
import Button from '../../Components/Button/Button';
import MultipleInviteCard from '../MultipleInviteCard/MultipleInviteCard';
import Pagination from '@material-ui/lab/Pagination';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Dropdown from '../../Components/DropdownComponent/Dropdown.component';
import API from '../../utils/Urls';
import { APIHelper } from '../../utils/ApiHelper';
import AutoCompleteCard from '../../Components/AutoCompleteCard/AutoCompleteCard';
import moment from 'moment';
import MultipleDatePicker from '../../Components/MultipleDatePicker/MultipleDatePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '../../Components/Chip/CustomizableChip';
import { useHistory } from 'react-router-dom';
import DrawerComponent from '../../Components/Drawer/Drawer';
import EmployeeDetail from '../../Components/WideProfileCard/EmployeeDetail';

const useStylesCard = makeStyles({
    root: {
        borderRadius: '1.041vw',
        marginLeft: '4.04vw',
        marginTop: '6.4vw',
        width: '50vw',
        display: 'inline-block',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const EmployerTalentSearchAdvanced = (props) => {
    const [rating, setRating] = React.useState([0, 5]);
    const [isWideCardSelected, setIsWideCardSelected] = React.useState(true);
    const [showSingleInviteCard, setShowSingleInviteCard] = React.useState(false);
    const [inviteFromEmplyoeeDrawer, setInviteFromEmplyoeeDrawer] = React.useState(false);
    const [showMultipleInviteCard, setShowMultipleInviteCard] = React.useState(false);
    const [cardsData, setCardsData] = React.useState([]);
    const [totalCardsData, setTotalCardsData] = React.useState([]);
    const [latestPageNumber, setLatestPageNumber] = React.useState(0);
    const [invitedProfilesId, setInvitedProfilesId] = React.useState([]);
    const [invitedProfiles, setInvitedProfiles] = React.useState([]);
    const [isLocationSearchCalled, setIsLocationSearchCalled] = React.useState(false);
    const [locationOptions, setLocationOptions] = React.useState([]);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [location, setLocation] = React.useState([
        {
            label: 'Worldwide',
            value: 'Worldwide',
            _id: '00000000000000',
        },
    ]);
    const cardClasses = useStylesCard();
    const [selectedDates, setSelectedDates] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedSearchTerm, setSelectedSearchTerm] = React.useState({});
    const [searchOptions, setSearchOptions] = React.useState([]);
    const [specialityOptions, setSpecialityOptions] = React.useState([]);
    const [skillsOptions, setSkillsOptions] = React.useState([]);
    const [userFieldData, setUserFieldData] = React.useState({});
    const [singleInviteProfileId, setSingleInviteProfileId] = React.useState(0);

    var localMinHourlyRate = JSON.parse(localStorage.getItem('hourlyRateMin'));
    var localMaxHourlyRate = JSON.parse(localStorage.getItem('hourlyRateMax'));
    var localSpeciality = JSON.parse(localStorage.getItem('specialityData'));
    var localSkills = JSON.parse(localStorage.getItem('skillsData'));
    var localMinExperience = JSON.parse(localStorage.getItem('minExperienceData'));
    var localMaxExperience = JSON.parse(localStorage.getItem('maxExperienceData'));
    const [experience, setExperience] = React.useState([
        parseInt(localMinExperience || 0),
        parseInt(localMaxExperience || 20),
    ]);
    const [hourlyRate, setHourlyRate] = React.useState([
        parseInt(localMinHourlyRate || 0),
        parseInt(localMaxHourlyRate || 100),
    ]);
    const [selectedSpeciality, setSelectedSpeciality] = React.useState(
        localSpeciality && Object.keys(localSpeciality).length > 0
            ? localSpeciality
            : { label: 'All', value: 'All', _id: '0000000000000' }
    );
    const [selectedSkills, setSelectedSkills] = React.useState(localSkills || []);
    const [page, setPage] = React.useState(1);
    const [textFilter, setTextFilter] = React.useState(
        JSON.parse(localStorage.getItem('talentSearchData'))
    );

    const [count, setCount] = React.useState(0);
    const [key, setKey] = React.useState(0);
    const [sortBy, setSortBy] = React.useState(undefined);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState();
    const [skillSelect, setSkillSelect] = React.useState(null);
    const [whereLocation, setWhereLocation] = React.useState(null);
    const [isPaymentMethodVerified, setIsPaymentMethodVerified] = React.useState(false);
    const [userID, setUserID] = React.useState('');
    const history = useHistory();
    const localFieldData = JSON.parse(localStorage.getItem('fieldData'));

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        // var localSpeciality = JSON.parse(localStorage.getItem('specialityData'));
        // if (localSpeciality) {
        //     APIHelper.getMethod(
        //         API.GET_SKILLS_BY_SPECIALITYID + '?speciality_id=' + localSpeciality._id
        //         )
        //         .then((response) => {
        //             if (response.data.length > 0) {
        //                 let skillsOptions = response.data.map((item) => {
        //                     return { label: item.name, value: item.name, _id: item._id };
        //                 });
        //                 setSkillsOptions(skillsOptions);
        //             }
        //         })
        //         .catch((err) => {});
        // }
        // else{
        APIHelper.getMethod(
            API.GET_SKILLS_BY_SPECIALITYID + '?Field_id=' + localFieldData[0].id
        )
            .then((response) => {
                if (response.data.length > 0) {
                    let skillsOptions = response.data.map((item) => {
                        return { label: item.name, value: item.name, _id: item._id };
                    });
                    setSkillsOptions(skillsOptions);
                }
            })
            .catch((err) => { });
        // }

        APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_ACTIVE, headers)
            .then((res) => {
                setIsPaymentMethodVerified(res.result);
            })
            .catch((error) => { });
    }, []);

    const getUpdatedCardsData = () => {
        setIsLoading(true);
        let dates = [];

        if (selectedDates.length > 0 && typeof selectedDates[0] == 'string') {
            dates = selectedDates.map((item) => {
                var d = item;
                var date = d.split('/');
                var f = new Date(date[2], date[0] - 1, date[1]);
                return f;
            });
        } else {
            dates = selectedDates.map((item) => {
                return moment(
                    `${item.day}-${item.month.number}-${item.year}`,
                    'DD-MM-YYYY'
                ).toDate();
            });
            dates = dates.map((d) => moment(d).format('DD-MM-YYYY'));
        }

        const experienceMax = experience[1] === 20 ? 100 : experience[1];

        const loc = location.map((item) => {
            return item.label;
        });

        let body = {
            skills: selectedSkills.map((item) => item.label),
            specialities: selectedSpeciality._id === '0000000000000' ? '' : selectedSpeciality._id,
            location: loc,
            dateRange: dates,
            searchTerm: textFilter,
            rate: {
                min: parseInt(hourlyRate[0] || 0),
                max: parseInt(hourlyRate[1] || 30),
            },
            ratings: {
                min: parseInt(rating[0] || 0),
                max: parseInt(rating[1] || 5),
            },
            experience: {
                min: experience[0],
                max: experienceMax,
            },
            sortBy: sortBy ? sortBy.value : '',
            sortType: 'asc',
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(`${API.TALENT_FILTER_ADVANCE}${page}`, body, headers)
            .then((response) => {
                if (response.length > 0) {
                    let data = response[0].data.map((obj) =>
                        invitedProfilesId.includes(obj._id)
                            ? { ...obj, isPersonInvited: true }
                            : obj
                    );

                    setCardsData(data);

                    if (page > latestPageNumber) {
                        setLatestPageNumber(page);
                        setTotalCardsData([...totalCardsData, ...response[0].data]);
                    }
                    setCount(response[0].total);
                }
                if (response.message === 'jwt malformed') {
                    history.push('/login');
                } else {
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        handleSpecialityInputChange();
    }, [userFieldData]);

    useEffect(() => {
        setHourlyRate([
            localStorage.getItem('hourlyRateMin'),
            localStorage.getItem('hourlyRateMax'),
        ]);
        if (JSON.parse(localStorage.getItem('fieldData'))) {
            const data = JSON.parse(localStorage.getItem('fieldData'));
            if (Array.isArray(data)) {
                setUserFieldData(data[0]);
            } else {
                setUserFieldData(data);
            }
        }

        if (localStorage.getItem('calendarDates')) {
            let calendarDates = localStorage.getItem('calendarDates').split(',');
            let dates = calendarDates.map((item) => {
                return item;
            });
            setSelectedDates(dates);
        }

        setSelectedSearchTerm(JSON.parse(localStorage.getItem('talentSearchData')));
        getUpdatedCardsData();
    }, [sortBy]);

    useEffect(() => {
        getUpdatedCardsData();
    }, [page, location, selectedSpeciality]);

    const handleSliderChange = (event, newValue) => {
        setHourlyRate(newValue);
        localStorage.setItem('hourlyRateMin', JSON.stringify(newValue[0]));
        localStorage.setItem('hourlyRateMax', JSON.stringify(newValue[1]));
    };

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
    };

    const handleExperienceChange = (event, newValue) => {
        setExperience(newValue);
        localStorage.setItem('minExperienceData', JSON.stringify(newValue[0]));
        localStorage.setItem('maxExperienceData', JSON.stringify(newValue[1]));
    };

    const onSingleInviteCardCloseClick = () => {
        setShowSingleInviteCard(false);
        let initialData = cardsData;

        let data = initialData.map((obj) => (true ? { ...obj, isPersonInvited: false } : obj));

        setCardsData(data);
        setInvitedProfilesId([]);
        setRefreshKey(Math.random());
    };

    const onMultipleInviteCardCloseClick = () => {
        setShowMultipleInviteCard(false);

        let newData = [];

        cardsData.forEach((element) => {
            let newelement = { ...element };
            newelement.isPersonInvited = false;
            newData.push(newelement);
        });
        setCardsData([...newData]);
        setInvitedProfilesId([]);
        setRefreshKey(Math.random());
    };

    const displayRateRange = (
        <div className="displayDynamicRateStyles">
            {'$' + hourlyRate[0] + ' - ' + '$' + hourlyRate[1]}
        </div>
    );

    const displayRatingRange = (
        <div className="displayDynamicRateStyles">
            {rating[0] + ' stars' + ' - ' + rating[1] + ' stars'}
        </div>
    );

    const displayExperienceRange = (
        <div className="displayDynamicRateStyles">
            {experience[0] + ' years' + ' - ' + experience[1] + ' years'}
        </div>
    );

    const onProfileInviteClicked = (id) => {
        let initialData = cardsData;

        let data = initialData.map((obj) =>
            obj._id == id ? { ...obj, isPersonInvited: !obj.isPersonInvited } : obj
        );

        setCardsData(data);

        if (!data.find((a) => a._id === id).isPersonInvited) {
            let tempData = invitedProfilesId;
            setInvitedProfilesId([]);
            setInvitedProfilesId(tempData.filter((a) => a !== id));
        } else {
            setInvitedProfilesId([...invitedProfilesId, id]);
        }
        setRefreshKey(Math.random());
    };

    const onInviteSelectedClicked = () => {
        let invitedProfiles = totalCardsData.filter((obj) => invitedProfilesId.includes(obj._id));
        invitedProfiles = invitedProfiles.filter(
            (v, i, a) => a.findIndex((t) => t._id === v._id) === i
        );
        if (invitedProfiles.length > 0) {
            setInvitedProfiles(invitedProfiles);
            setShowMultipleInviteCard(true);
        }
    };

    const onRemoveItemClicked = (item) => {
        setInvitedProfiles(invitedProfiles.filter((obj) => obj._id != item._id));
    };

    const handleLocationChange = (e) => {
        if (e.length > 2) {
            setIsLocationSearchCalled(true);

            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            APIHelper.getMethod(API.GET_LOCATION_SEARCH_BY_SEARCH_TERM + e, headers)
                .then((response) => {
                    if (response.length > 0) {
                        setIsLocationSearchCalled(false);
                        let locationOptions = response.map((item) => {
                            return { label: item.name, value: item.name, _id: item._id };
                        });
                        setLocationOptions(locationOptions);
                    }
                    if (response['status'] === 0) {
                        setIsLocationSearchCalled(false);
                    }
                })
                .catch((err) => {
                    setIsLocationSearchCalled(false);
                });
        }
    };

    const handleSpecialityInputChange = (e) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(
            API.GET_SPECIALITY_BY_FIELDID + '?field_id=' + userFieldData.id,
            headers
        )
            .then((response) => {
                if (response.data.length > 0) {
                    let specialityOptions = response.data.map((item) => {
                        return { label: item.name, value: item.name, _id: item._id };
                    });
                    setSpecialityOptions([
                        { label: 'All', value: 'All', _id: '0000000000000' },
                        ...specialityOptions,
                    ]);
                }
            })
            .catch((err) => { });
    };

    const handleSpecialityChange = (e) => {
        setSelectedSpeciality(e);
        if (e.label === "All") {
            APIHelper.getMethod(
                API.GET_SKILLS_BY_SPECIALITYID + '?Field_id=' + localFieldData[0].id
            )
                .then((response) => {
                    if (response.data.length > 0) {
                        let skillsOptions = response.data.map((item) => {
                            return { label: item.name, value: item.name, _id: item._id };
                        });
                        setSkillsOptions(skillsOptions);
                    }
                })
                .catch((err) => { });
        }
        localStorage.setItem('specialityData', JSON.stringify(e));
        localStorage.setItem('skillsData', JSON.stringify([]));
        setSelectedSkills([]);
        setSkillsOptions([]);
        APIHelper.getMethod(API.GET_SKILLS_BY_SPECIALITYID + '?speciality_id=' + e._id)
            .then((response) => {
                if (response.data.length > 0) {
                    let skillsOptions = response.data.map((item) => {
                        return { label: item.name, value: item.name, _id: item._id };
                    });
                    setSkillsOptions(skillsOptions);
                }
            })
            .catch((err) => { });

        setPage(1);
    };

    const handleMultipleDateSelection = (date) => {
        let dates = date.map((item) => {
            return moment(`${item.day}-${item.month.number}-${item.year}`).format('DD-MM-YYYY');
        });

        setSelectedDates(date);
    };

    const handleSearchChange = (e) => {
        if (e.length > 2) {
            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            APIHelper.getMethod(API.TALENT_SEARCH_BY_SEARCH_TERM + e, headers)
                .then((response) => {
                    if (response.length > 0) {
                        let searchOptions = response.map((item) => {
                            return { label: item.name, value: item.name, _id: item._id };
                        });

                        setSearchOptions(searchOptions);
                    }
                })
                .catch((err) => { });
        } else {
            setSearchOptions([]);
        }
    };

    const handleSkillsSelect = (e) => {
        if (selectedSkills.length < 9) {
            let skills = selectedSkills;

            if (skills.filter((obj) => obj.label === e.label).length < 1) {
                skills.push(e);
            }

            localStorage.setItem('skillsData', JSON.stringify(skills));
            setSelectedSkills(skills);
            setPage(1);
            getUpdatedCardsData();
        }
    };

    const handleLocationSelect = (e) => {
        if (location.length < 6) {
            let loc = [...location];

            if (location.find((l) => l.label === 'Worldwide')) {
                loc = [];
            }

            if (loc.filter((obj) => obj.label === e.label).length < 1) {
                loc.push(e);
            }

            setLocation(loc);
            setPage(1);
            // getUpdatedCardsData();
        }
    };

    const addToFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        let data = {
            emp_id: id,
        };
        let initialData = cardsData;

        let newData = initialData.map((obj) =>
            obj.user._id === id ? { ...obj, isFav: true } : obj
        );

        setCardsData(newData);
        setRefreshKey(Math.random());
        APIHelper.putMethod(`${API.ADD_TO_FAVORITE}`, data, headers)
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const removeFromFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        let initialData = cardsData;

        let newData = initialData.map((obj) =>
            obj.user._id === id ? { ...obj, isFav: false } : obj
        );

        setCardsData(newData);
        setRefreshKey(Math.random());
        APIHelper.deleteMethod(`${API.REMOVE_FAVORITE}${id}`, headers)
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const is_suspend = localStorage.getItem('is_suspend') || false;
    const disabledDate = moment(localStorage.getItem('suspended_date')) || moment();
    const month_data = disabledDate.add(1, 'months');
    const disabledBtn = is_suspend === 'true' ? month_data.isAfter(moment()) : false;

    return (
        <div>
            <div>
                <div>
                    {showSingleInviteCard ? (
                        <div id="overlay">
                            <div className="overlayImageStyle">
                                <div
                                    style={{
                                        width: '68.64vw',
                                        height: '39vw',
                                        backgroundColor: 'white',
                                        position: 'absolute',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <SingleEmployeeInviteDetails
                                        onCloseClick={() => onSingleInviteCardCloseClick()}
                                        profileData={cardsData[singleInviteProfileId]}
                                        selectedSpeciality={selectedSpeciality}
                                        selectedSkills={selectedSkills}
                                        experience={experience}
                                        fromFav={false}
                                        isPaymentMethodVerified={isPaymentMethodVerified}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div
                        style={{
                            width: '29.63vw',
                            position: 'relative',
                            borderRight: 'solid 1px #dedede',
                            height: 'auto',
                            float: 'left',
                        }}
                    >
                        <div className="advancedSearchHeading">Advanced Search</div>
                        <div className="fieldOfWorkHeading">Field of work :</div>
                        {/* <div className="fieldOfWorkSelected">App, Software Development & Web Design</div> */}
                        <div className="fieldOfWorkSelected">{userFieldData?.name}</div>

                        <div style={{ width: '100%', marginTop: '67px' }}>
                            <Card
                                style={{
                                    width: '70%',
                                    height: '4.79vw',
                                    margin: 'auto',
                                    marginLeft: 'auto',
                                    borderRadius: '1.041vw',
                                }}
                            >
                                <div
                                    style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                    className="specialityDropdownLabel"
                                >
                                    {'All Speciality'}
                                </div>
                                <div className="inputBaseWrapperStyle">
                                    <AutoCompleteCard
                                        placeholder={''}
                                        onInputChange={(e) => handleSpecialityInputChange(e)}
                                        options={specialityOptions}
                                        onChange={(e) => handleSpecialityChange(e)}
                                        value={selectedSpeciality}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div style={{ width: '100%', marginTop: '67px' }}>
                            <Card
                                style={{
                                    width: '70%',
                                    height: '4.79vw',
                                    borderRadius: '1.041vw',
                                    margin: 'auto',
                                    marginLeft: 'auto',
                                }}
                            >
                                <div
                                    style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                    className="specialityDropdownLabel"
                                >
                                    {'Skills'}
                                </div>
                                <div className="inputBaseWrapperStyle">
                                    <AutoCompleteCard
                                        placeholder={'Search by Skills'}
                                        options={skillsOptions}
                                        onChange={(e) => {
                                            setSkillSelect(null);
                                            handleSkillsSelect(e);
                                        }}
                                        value={skillSelect}
                                    // createSelect
                                    />
                                </div>
                            </Card>
                            <div style={{ width: '70%', margin: 'auto', marginTop: 10 }}>
                                {selectedSkills &&
                                    selectedSkills.length > 0 &&
                                    selectedSkills.map((item, index) => {
                                        return (
                                            <Chip
                                                // height={"1.66vw"}
                                                // fontSize={"0.729vw"}
                                                style={{
                                                    width: '31%',
                                                    height: 32,
                                                    fontSize: 12,
                                                    marginTop: 9,
                                                }}
                                                label={item.label}
                                                key={index}
                                                onDelete={() => {
                                                    let existingSkills = selectedSkills;
                                                    existingSkills.splice(index, 1);
                                                    localStorage.setItem(
                                                        'skillsData',
                                                        JSON.stringify(existingSkills)
                                                    );
                                                    setSelectedSkills(existingSkills);
                                                    setPage(1);
                                                    getUpdatedCardsData();
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: '67px' }}>
                            <Card
                                style={{
                                    width: '70%',
                                    height: '4.79vw',
                                    margin: 'auto',
                                    marginLeft: 'auto',
                                    borderRadius: '1.041vw',
                                }}
                            >
                                <div
                                    style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                    className="specialityDropdownLabel"
                                >
                                    {'Where'}
                                </div>
                                <div className="inputBaseWrapperStyle">
                                    <AutoCompleteCard
                                        placeholder={'City, Province/State, or Country'}
                                        onInputChange={(e) => handleLocationChange(e)}
                                        options={locationOptions}
                                        loading={isLocationSearchCalled}
                                        onChange={(e) => {
                                            setWhereLocation(null);
                                            handleLocationSelect(e);
                                        }}
                                        createSelect={false}
                                        value={whereLocation}
                                    />
                                </div>
                            </Card>
                            <div style={{ width: '70%', margin: 'auto', marginTop: 10 }}>
                                {location.map((item, index) => {
                                    return (
                                        <Chip
                                            label={item.label}
                                            style={{
                                                width: '31%',
                                                height: 32,
                                                fontSize: 12,
                                                marginTop: 9,
                                            }}
                                            key={index}
                                            onDelete={() => {
                                                let existingLocation = [...location];
                                                existingLocation.splice(index, 1);
                                                if (existingLocation.length === 0) {
                                                    setLocation([
                                                        {
                                                            label: 'Worldwide',
                                                            value: 'Worldwide',
                                                            _id: '00000000000000',
                                                        },
                                                    ]);
                                                } else {
                                                    setLocation(
                                                        existingLocation || [
                                                            {
                                                                label: 'Worldwide',
                                                                value: 'Worldwide',
                                                                _id: '00000000000000',
                                                            },
                                                        ]
                                                    );
                                                }
                                                // setLocation(existingLocation);
                                                setPage(1);
                                                // getUpdatedCardsData();
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: 32 }}>
                            <div className="maps"></div>
                        </div>
                        <div style={{ width: '100%', marginTop: 67 }}>
                            <div className="hourlyRateSlider">
                                <Slider
                                    heading={'Hourly Rate'}
                                    value={hourlyRate}
                                    handleChange={handleSliderChange}
                                    rangeDisplay={displayRateRange}
                                    onChangeCommitted={() => {
                                        setPage(1);
                                        getUpdatedCardsData();
                                    }}
                                    min={0}
                                    max={100}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '$0',
                                        },
                                        {
                                            value: 50,
                                            label: '$50',
                                        },
                                        {
                                            value: 100,
                                            label: '$100+',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: 67 }}>
                            <div className="ratingSlider">
                                <Slider
                                    heading={'Rating'}
                                    value={rating}
                                    handleChange={handleRatingChange}
                                    rangeDisplay={displayRatingRange}
                                    onChangeCommitted={() => {
                                        setPage(1);
                                        getUpdatedCardsData();
                                    }}
                                    min={0}
                                    max={5}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '0',
                                        },
                                        {
                                            value: 1,
                                            label: '1',
                                        },
                                        {
                                            value: 2,
                                            label: '2',
                                        },
                                        {
                                            value: 3,
                                            label: '3',
                                        },
                                        {
                                            value: 4,
                                            label: '4',
                                        },
                                        {
                                            value: 5,
                                            label: '5',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: 67, marginBottom: 120 }}>
                            <div className="experienceSlider">
                                <Slider
                                    heading={'Experience'}
                                    value={experience}
                                    handleChange={handleExperienceChange}
                                    rangeDisplay={displayExperienceRange}
                                    onChangeCommitted={() => {
                                        setPage(1);
                                        getUpdatedCardsData();
                                    }}
                                    min={0}
                                    max={20}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '0',
                                        },
                                        {
                                            value: 10,
                                            label: '10',
                                        },
                                        {
                                            value: 20,
                                            label: '20+',
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <WideProfileCard/> */}
                    <div style={{ marginLeft: '29.63vw', position: 'relative' }}>
                        <Card className={cardClasses.root}>
                            <div style={{ display: 'flex' }} className="topProfileSearchBar">
                                <div style={{ display: 'inline-block' }}>
                                    <Card
                                        style={{
                                            width: '32.7vw',
                                            marginLeft: '0px',
                                            marginTop: '0.1vw',
                                            height: '4.79vw',
                                        }}
                                        className={cardClasses.root}
                                    >
                                        <div
                                            style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                            className="specialityDropdownLabel"
                                        >
                                            {'What are you looking for?'}
                                        </div>
                                        <div className="inputBaseWrapperStyle">
                                            <AutoCompleteCard
                                                inputValue={textFilter}
                                                placeholder={'What talent are you looking for?'}
                                                onInputChange={(value, action) => {
                                                    // only set the input when the action that caused the
                                                    // change equals to "input-change" and ignore the other
                                                    // ones like: "set-value", "input-blur", and "menu-close"
                                                    if (action.action === 'input-change') {
                                                        setTextFilter(value);
                                                        localStorage.setItem(
                                                            'talentSearchData',
                                                            JSON.stringify(value)
                                                        );
                                                        handleSearchChange(value);
                                                        setSelectedSearchTerm(null);
                                                    }
                                                }}
                                                options={searchOptions}
                                                value={selectedSearchTerm}
                                                onChange={(e) => {
                                                    setSelectedSearchTerm(e);
                                                    setTextFilter(e.label);
                                                    setKey(Math.random());
                                                    localStorage.setItem(
                                                        'talentSearchData',
                                                        JSON.stringify(e.label)
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </div>
                                <div className="specifyDatesCardsPage">
                                    <div>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                position: 'absolute',
                                            }}
                                        >
                                            <div
                                                style={{ marginTop: '0.7vw', marginLeft: '1.8vw' }}
                                                className="specialityDropdownLabel"
                                            >
                                                Dates
                                            </div>
                                            <div style={{ marginTop: '0.5vw' }}>
                                                <MultipleDatePicker
                                                    value={selectedDates}
                                                    style={{
                                                        border: '1px solid #f2f2f2',
                                                        width: '10.4vw',
                                                        backgroundColor: '#f2f2f2',
                                                    }}
                                                    onChange={(e) => {
                                                        handleMultipleDateSelection(e);
                                                    }}
                                                    minDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        onClick={() => {
                                            setPage(1);
                                            getUpdatedCardsData();
                                        }}
                                        className="searchIcon"
                                        style={{
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            marginLeft: '0vw',
                                        }}
                                    >
                                        <SearchIcon fontSize={'inherit'} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div style={{ marginLeft: '4.04vw', marginTop: '1.68vw' }}>
                            <div style={{ display: "flex", flexDirection: "row", marginRight: "3vw" }}>
                                <div className="wideCardHeading" style={{ justifyContent: 'space-between', flex: 1 }}>
                                    <div>
                                        {isWideCardSelected ?
                                            <Button
                                                text="Invite selected"
                                                height="2.5vw"
                                                onClick={() => onInviteSelectedClicked()}
                                                color={['#2699fb', '#134d7e']}
                                                disabled={disabledBtn}
                                            /> : <div />
                                        }
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div className="sortByStyles">Sort by:</div>
                                        <div style={{ width: '13.6vw' }}>
                                            <Dropdown
                                                value={sortBy}
                                                placeholder={''}
                                                options={[
                                                    { label: 'Score (High to low)', value: 'scoreHTL' },
                                                    {
                                                        label: 'Hourly rate (Hight to low)',
                                                        value: 'hourlyHTL',
                                                    },
                                                    {
                                                        label: 'Hourly rate (Low to high)',
                                                        value: 'hourlyLTH',
                                                    },
                                                    {
                                                        label: 'Experience (High to low)',
                                                        value: 'experienceHTL',
                                                    },
                                                ]}
                                                height={'3vw'}
                                                onChange={(e) => {
                                                    setPage(1);
                                                    setSortBy(e);
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '1vw',
                                                paddingTop: '0.8vw',
                                                fontSize: '1.25vw',
                                                color: isWideCardSelected ? '#1c1c1c' : '#c4c4c4',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setIsWideCardSelected(true);
                                            }}
                                        >
                                            <ViewListIcon fontSize="inherit" />
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '1.6vw',
                                                paddingTop: '0.8vw',
                                                fontSize: '1.25vw',
                                                color: isWideCardSelected ? '#c4c4c4' : '#1c1c1c',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setIsWideCardSelected(false);
                                            }}
                                        >
                                            <ViewModuleIcon fontSize="inherit" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {isWideCardSelected ? (
                                isLoading ? (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '150px',
                                            marginBottom: '150px',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div>
                                        {cardsData.length > 0 ? (
                                            cardsData.map((item, index) => {
                                                return (
                                                    <WideProfileCard
                                                        key={index}
                                                        userid={item.user._id}
                                                        name={
                                                            item.user &&
                                                            `${item.user.firstName} ${item.user.lastName}`
                                                        }
                                                        designation={
                                                            item.speciality.length > 0
                                                                ? item.speciality[0].name
                                                                : null
                                                        }
                                                        location={
                                                            item.address &&
                                                            item.address.length > 0 &&
                                                            `${item.address[0].city}, ${item.address[0].country}`
                                                        }
                                                        experience={item.work_experience}
                                                        rate={
                                                            (item.address &&
                                                                item.address.length > 0 &&
                                                                item.address[0].country.toLowerCase()) ===
                                                                (localStorage.getItem('country') &&
                                                                    localStorage
                                                                        .getItem('country')
                                                                        .toLowerCase())
                                                                ? item.rate
                                                                : item.international_rate
                                                        }
                                                        rating={item.ratings}
                                                        skills={item.skills}
                                                        numberOfRatings={item.review_count}
                                                        profilePicture={item.photo}
                                                        score={item.score}
                                                        onProfileInviteClicked={() =>
                                                            onProfileInviteClicked(item._id)
                                                        }
                                                        isPersonInvited={invitedProfilesId.includes(
                                                            item._id
                                                        )}
                                                        handleInvite={() => {
                                                            setShowSingleInviteCard(true);
                                                            setSingleInviteProfileId(index);
                                                        }}
                                                        isFavorite={item.isFav}
                                                        setDrawerOpen={() => {
                                                            setDrawerData(item);
                                                            setUserID(item.userid);
                                                            setDrawerOpen(true);
                                                        }}
                                                        addToFavorite={addToFavorite}
                                                        removeFromFavorite={removeFromFavorite}
                                                        disabledBtn={disabledBtn}
                                                    />
                                                );
                                            })
                                        ) : (
                                            <p
                                                style={{
                                                    fontSize: '20px',
                                                    textAlign: 'center',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                No record found.
                                            </p>
                                        )}
                                    </div>
                                )
                            ) : isLoading ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '150px',
                                        marginBottom: '150px',
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '62vw',
                                        height: 'auto',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {cardsData.length > 0 ? (
                                        cardsData.map((item, index) => {
                                            return (
                                                <div>
                                                    <ShortProfileCard
                                                        key={index}
                                                        name={
                                                            item.user &&
                                                            `${item.user.firstName} ${item.user.lastName}`
                                                        }
                                                        designation={
                                                            item.speciality.length > 0
                                                                ? item.speciality[0].name
                                                                : null
                                                        }
                                                        score={item.score}
                                                        experience={item.work_experience}
                                                        rate={item.rate}
                                                        rating={item.ratings}
                                                        numberOfRatings={item.review_count}
                                                        profilePicture={item.photo}
                                                        // skills={item.skills}
                                                        onProfileInviteClicked={() =>
                                                            onProfileInviteClicked(item._id)
                                                        }
                                                        isPersonInvited={invitedProfilesId.includes(
                                                            item._id
                                                        )}
                                                        handleInvite={() => {
                                                            setShowSingleInviteCard(true);
                                                            setSingleInviteProfileId(index);
                                                        }}
                                                        disabledBtn={disabledBtn}
                                                        location={
                                                            item.address &&
                                                            item.address.length > 0 &&
                                                            `${item.address[0].city}, ${item.address[0].country}`
                                                        }
                                                    />
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p
                                            style={{
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                color: '#1c1c1c',
                                            }}
                                        >
                                            No record found.
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="advancedSearchPagination">
                                <Pagination
                                    count={Math.ceil(count / 10)}
                                    page={page}
                                    onChange={(e, value) => setPage(value)}
                                    style={{ justifyContent: 'center' }}
                                />
                            </div>
                        </div>
                    </div>
                    <DrawerComponent
                        open={drawerOpen}
                        onClose={() => {
                            setDrawerOpen(false);
                            setInviteFromEmplyoeeDrawer(false);
                        }}
                        component={
                            <EmployeeDetail
                                data={drawerData}
                                userID={userID}
                                addToFavorite={addToFavorite}
                                removeFromFavorite={removeFromFavorite}
                                onClose={() => setDrawerOpen(false)}
                                invite={() => {
                                    let id = cardsData.findIndex((a) => a._id === drawerData._id);
                                    setInviteFromEmplyoeeDrawer(true);
                                    setShowSingleInviteCard(true);
                                    setDrawerOpen(false);
                                    setSingleInviteProfileId(id);
                                }}
                                isPublicView
                                history={history}
                            />
                        }
                    />
                </div>
            </div>
            {showMultipleInviteCard && (
                <div id="overlay">
                    <div className="overlayImageStyle">
                        <div
                            style={{
                                width: '68.64vw',
                                height: '39vw',
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                            }}
                        >
                            <MultipleInviteCard
                                data={invitedProfiles}
                                onCloseClick={() => onMultipleInviteCardCloseClick()}
                                onRemoveItemClicked={(id) => onRemoveItemClicked(id)}
                                selectedSpeciality={selectedSpeciality}
                                selectedSkills={selectedSkills}
                                experience={experience}
                                isPaymentMethodVerified={isPaymentMethodVerified}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployerTalentSearchAdvanced;
