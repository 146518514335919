import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import {useEffect, useState , useRef} from 'react';


export default function DrawerMenuTablet(props) {
  const [selectionValue, setSelectionValue] = React.useState(0);
  const [marginTop, setMarginTop] = React.useState("60px");
  const [marginBottom, setMarginBottom] = React.useState("60px");
  const [marginLeft, setMarginLeft] = React.useState("40px");
  const [selectedBgColor, setSelectedBgColor] = React.useState("#1876d22c");
  const [unSelectedBgColor, setUnSelectedBgColor] = React.useState("#F2F2F2");
  const [avatarSelectedColor, setAvatarSelectedColor] = React.useState("#1876D2");
  const [avatarUnSelectedColor, setAvatarUnSelectedColor] = React.useState("#1876d22c");
  const [avatarUnSelectedTextColor, setAvatarUnSelectedTextColor] = React.useState("#707070");

  const useStyles = makeStyles((theme) => ({

          root :{
            width: "100%",
            height : "100%"
          },

           listTitleS :{
             paddingLeft: marginLeft,
             marginTop: marginTop,
             backgroundColor: selectedBgColor,
             cursor: "text"
           },

           listTitleUS :{
               paddingLeft: marginLeft,
               marginTop: marginTop,
               marginBottom: marginBottom,
               backgroundColor: unSelectedBgColor,
               cursor: "text"
           },

           listTitleSL :{
               paddingLeft: marginLeft,
               marginTop: marginTop,
               marginBottom: marginBottom,
               backgroundColor: selectedBgColor,
               cursor: "text"
          },

           listTitleUSL :{
               paddingLeft: marginLeft,
               marginTop: marginTop,
               marginBottom: marginBottom,
               backgroundColor: unSelectedBgColor,
               cursor: "text"
           },

           avatarS :{
               backgroundColor: avatarSelectedColor,
           },

           avatarUS :{
               backgroundColor: avatarUnSelectedColor,
               color: avatarUnSelectedTextColor
           },
           avatarSP :{
               backgroundColor: avatarUnSelectedColor,
               color: avatarUnSelectedTextColor
           }
  }));


 const classes = useStyles();

  useEffect(() => {
    if(props.selectedValue !== undefined)
      setSelectionValue(props.selectedValue);
    }, [props.selectedValue]);

    useEffect(() => {
      if(props.marginTop !== undefined)
        setMarginTop(props.marginTop);
      }, [props.marginTop]);

    useEffect(() => {
      if(props.marginBottom !== undefined)
          setMarginBottom(props.marginBottom);
      }, [props.marginBottom]);

    useEffect(() => {
          if(props.marginLeft !== undefined)
            setMarginLeft(props.marginLeft);
      }, [props.marginLeft]);


      useEffect(() => {
            if(props.selectedBgColor !== undefined)
              setSelectedBgColor(props.selectedBgColor);
        }, [props.selectedBgColor]);


          useEffect(() => {
              if(props.unSelectedBgColor !== undefined)
                setUnSelectedBgColor(props.unSelectedBgColor);
          }, [props.unSelectedBgColor]);


          useEffect(() => {
                if(props.avatarSelectedColor !== undefined)
                  setAvatarSelectedColor(props.avatarSelectedColor);
          }, [props.avatarSelectedColor]);


          useEffect(() => {
                  if(props.avatarUnSelectedColor !== undefined)
                    setAvatarUnSelectedColor(props.avatarUnSelectedColor);
          }, [props.avatarUnSelectedColor]);


          useEffect(() => {
                    if(props.avatarUnSelectedTextColor !== undefined)
                      setAvatarUnSelectedTextColor(props.avatarUnSelectedTextColor);
          }, [props.avatarUnSelectedTextColor]);


  return (
    <List className={classes.root} >
      {props.data.map((text, index) => (

         <div key = {index} >

        <ListItem button key={text} disableRipple
        className= {selectionValue === index
        ? ( index === (props.data.length -1) ? classes.listTitleSL : classes.listTitleS)
        : ( index === (props.data.length -1) ? classes.listTitleUSL : classes.listTitleUS) }>


        {selectionValue <= index ?
          <ListItemAvatar>
             <Avatar   className= {selectionValue === index
               ? classes.avatarS
               : classes.avatarUS }>
             <div>{index+1}</div>
             </Avatar>
           </ListItemAvatar>
         :
         <ListItemAvatar>
            <Avatar className = {classes.avatarSP}>
            <CheckIcon/>
            </Avatar>
          </ListItemAvatar> }
        </ListItem>
        </div>

      ))}
    </List>
    );
}
