import React from 'react';
import moment from 'moment';

import '../HiringProcess.scss';

import Pagination from '@material-ui/lab/Pagination/Pagination';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import HourlyContract from './Component/HourlyContract';
import ContractRequest from './Component/ContractRequest';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import { Menu, MenuItem } from '@material-ui/core';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import CancelApplicationFormHourly from './Component/CancelApplicationFormHourly';
import Swal from 'sweetalert2';
import TimeSheetReview from './Component/TimeSheetReview';

const HourlyJobs = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [reviewData, setReviewData] = React.useState({});
    const [currentPage, setCurrentPage] = React.useState('HourlyJobs');
    const [count, setCount] = React.useState(1);
    const [data, setData] = React.useState([]);
    const [sortBy, setSortBy] = React.useState({ label: 'Start Date', value: 'startDate' });
    const [loading, setLoading] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerPage, setDrawerPage] = React.useState('viewContract');
    const [contractId, setContractId] = React.useState();

    const handleClick = (event, item) => {
        setContractId(item._id);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        getListHourlyJobs();
    }, [page, sortBy]);

    const getListHourlyJobs = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(
            API.GET_LIST_EMPLOYEE_HOURLY_JOBS + `page=${page}&sort=${sortBy ? sortBy.value : ''}`,
            headers
        )
            .then((res) => {
                setData(res[0].data);
                setCount(res[0].total);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const cancelJob = (id, data) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.putMethod(`${API.CANCEL_CONTRACTS}${id}`, data, headers)
            .then((res) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Success!',
                    text: 'Job cancel successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                }).then((result) => {
                    if (result) {
                        setDrawerOpen(false);
                        getListHourlyJobs();
                    }
                });
            })
            .catch((error) => console.log(error));
    };

    const finalCancelJob = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.putMethod(`${API.FINAL_CANCEL_CONTRACTS}${id}`, null, headers)
            .then((res) => {
                setDrawerOpen(false);
                getListHourlyJobs();
            })
            .catch((error) => console.log(error));
    };

    const finalCancelContractJob = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.postMethod(`${API.CONTRACT_NOT_CANCELLED}`, { contract_id: id }, headers)
            .then((res) => {
                setDrawerOpen(false);
                getListHourlyJobs();
            })
            .catch((error) => console.log(error));
    };

    const getReturnStatus = (status, startDate, endDate, cancelBy) => {
        switch (status) {
            case 'CANCELLATION_REQUESTED':
                if (cancelBy === 'EMPLOYEE') {
                    return { status: 'CANCELLED_BY' };
                }
                return { status: 'CANCELLED' };
            case 'CANCELLED':
                return { status: 'CANCELLED_FINAL' };
            case 'STARTED':
                const isStart = moment(startDate).utc(0) >= moment();
                const isEnd = moment(endDate).utc(0) < moment();
                if (isStart) {
                    return { status: 'NOT_STARTED' };
                } else if (isEnd) {
                    return { status: 'COMPLETED' };
                } else {
                    return { status: 'IN_PROGRESS' };
                }
            default:
                return { status: 'IN_PROGRESS' };
        }
    };

    return (
        <>
            {currentPage === 'HourlyJobs' ? (
                <React.Fragment>
                    <div className="editDetailsContentHeading">Hourly Jobs</div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '30px',
                        }}
                    >
                        <p className="SortByDropdown">Sort by:</p>
                        <div style={{ width: '170px' }}>
                            <Dropdown
                                placeholder="Select sorting"
                                options={[
                                    { label: 'Start Date', value: 'startDate' },
                                    { label: 'Job Title', value: 'jobTitle' },
                                ]}
                                value={sortBy}
                                onChange={(e) => {
                                    setPage(1);
                                    setSortBy(e);
                                }}
                            />
                        </div>
                    </div>
                    <table
                        style={{
                            width: '100%',
                            textAlign: 'left',
                            borderCollapse: 'collapse',
                            fontFamily: 'Libre Franklin',
                        }}
                        className="table-small-row-font"
                    >
                        <thead>
                            <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                                <th style={{ width: '12%', color: '#1c1c1c' }}>Start Date</th>
                                <th style={{ width: '12%', color: '#1c1c1c' }}>Start Time</th>
                                <th style={{ width: '12%', color: '#1c1c1c' }}>End Time</th>
                                <th style={{ width: '20%', color: '#1c1c1c' }}>Talent Name</th>
                                <th style={{ width: '15%', color: '#1c1c1c' }}>Job Title</th>
                                <th style={{ width: '19%', color: '#1c1c1c' }}>Status</th>
                                <th style={{ width: '10%' }}></th>
                            </tr>
                        </thead>
                        {loading ? (
                            <tr style={{ textAlign: 'center', height: 100 }}>
                                <td colSpan="7">
                                    <CircularProgress size={40} />
                                </td>
                            </tr>
                        ) : null}
                        {!loading && (
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((item) => (
                                        <tr
                                            style={{
                                                height: 87,
                                                borderBottom: '2px solid #C4C4C4',
                                                backgroundColor: 'inherit',
                                            }}
                                        >
                                            <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                {item.schedule &&
                                                    item.schedule.length > 0 &&
                                                    moment(item.schedule[0].dateSchedule).utc(0).format(
                                                        'MMM DD, YY'
                                                    )}
                                            </td>
                                            <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                {item.schedule &&
                                                    item.schedule.length > 0 &&
                                                    moment(item.schedule[0].startTime).utc(0).format(
                                                        'h:mm A'
                                                    )}{' '}
                                            </td>
                                            <td style={{ width: '12%', color: '#1c1c1c' }}>
                                                {item.schedule &&
                                                    item.schedule.length > 0 &&
                                                    moment(item.schedule[0].endTime).utc(0).format(
                                                        'h:mm A'
                                                    )}{' '}
                                            </td>
                                            <td style={{ width: '20%', color: '#1c1c1c' }}>
                                                {item.users && item.users.length > 0 && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <div>
                                                            {item.profiles &&
                                                            item.profiles.length > 0 &&
                                                            item.profiles[0].photo ? (
                                                                <img
                                                                    src={
                                                                        item.profiles &&
                                                                        item.profiles[0] &&
                                                                        item.profiles[0].photo
                                                                    }
                                                                    style={{
                                                                        width: 70,
                                                                        height: 70,
                                                                        borderRadius: 70,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <AccountCircleIcon
                                                                    style={{
                                                                        height: '70px',
                                                                        width: '70px',
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign: 'center',
                                                                margin: 'auto',
                                                                marginLeft: 10,
                                                                opacity: 0.78,
                                                            }}
                                                        >
                                                            <p className="hiringEmployeeName">{`${item.users[0].firstName} ${item.users[0].lastName}`}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td style={{ width: '15%', color: '#1c1c1c' }}>
                                                {item.jobs &&
                                                    item.jobs.length > 0 &&
                                                    item.jobs[0].title}
                                            </td>
                                            <td style={{ width: '19%', color: '#1c1c1c' }}>
                                                <ProgressBar
                                                    type="HOURLY"
                                                    variant={
                                                        getReturnStatus(
                                                            item.status,
                                                            item.contractStartedOn,
                                                            item.jobEndOn,
                                                            item.cancelBy
                                                        ).status
                                                    }
                                                />
                                            </td>
                                            <td
                                                style={{
                                                    width: '10%',
                                                    textAlign: 'end',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                <CustomMenu
                                                    item={item}
                                                    anchorEl={anchorEl}
                                                    handleClick={handleClick}
                                                    setDrawerPage={setDrawerPage}
                                                    setDrawerOpen={setDrawerOpen}
                                                    setCurrentPage={setCurrentPage}
                                                    setReviewData={setReviewData}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr style={{ textAlign: 'center', height: 100 }}>
                                        <td colSpan="7">
                                            <p>No data found.</p>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        )}
                    </table>
                    {!loading && data.length > 0 && (
                        <div className="advancedSearchPagination" style={{ marginTop: 80 }}>
                            <Pagination
                                count={Math.ceil(count / 10)}
                                page={page}
                                onChange={(e, value) => setPage(value)}
                                style={{ justifyContent: 'center' }}
                                size="large"
                            />
                        </div>
                    )}
                    <DrawerComponent
                        open={drawerOpen}
                        onClose={() => {
                            setDrawerOpen(false);
                        }}
                        component={
                            <>
                                {drawerPage === 'viewContract' ? (
                                    <HourlyContract contractId={contractId} />
                                ) : drawerPage === 'contractCancel' ? (
                                    <ContractRequest
                                        page="HOURLY"
                                        handleClose={() => {
                                            setDrawerOpen(false);
                                        }}
                                        contractId={contractId}
                                        cancelJob={(id, data) => {
                                            cancelJob(id, data);
                                        }}
                                    />
                                ) : (
                                    <CancelApplicationFormHourly
                                        finalCancelJob={(id) => {
                                            finalCancelJob(id);
                                        }}
                                        finalCancelContractJob={(id) => {
                                            finalCancelContractJob(id);
                                        }}
                                        handleClose={() => setDrawerOpen(false)}
                                        contractId={contractId}
                                    />
                                )}
                            </>
                        }
                        width="80%"
                    />
                </React.Fragment>
            ) : (
                <TimeSheetReview reviewData={reviewData} setCurrentPage={setCurrentPage} />
            )}
        </>
    );
};

const CustomMenu = ({
    item,
    handleClick,
    anchorEl,
    setDrawerPage,
    setDrawerOpen,
    setCurrentPage,
    setReviewData,
}) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const handleClose = () => {
        setIsMenuOpen(false);
    };

    return (
        <React.Fragment>
            <MoreHorizIcon
                style={{
                    width: '28px',
                    height: '28px',
                    border: '1px solid #9A9A9A',
                    color: '#9A9A9A',
                    padding: 4,
                    borderRadius: 20,
                }}
                onClick={(e) => {
                    handleClick(e, item);
                    setIsMenuOpen(true);
                }}
            />

            <Menu
                key={`simple-menu${item._id}`}
                id={`simple-menu${item._id}`}
                anchorEl={anchorEl}
                keepMounted
                open={isMenuOpen}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        setDrawerPage('viewContract');
                        setDrawerOpen(true);
                        handleClose();
                    }}
                >
                    View Contract
                </MenuItem>

                {moment(item.jobEndOn).utc(0) > moment() && (
                    <>
                        <MenuItem
                            onClick={() => {
                                setDrawerPage('contractCancel');
                                setDrawerOpen(true);
                                handleClose();
                            }}
                            disabled={item.status !== 'STARTED'}
                        >
                            Cancel job
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setDrawerPage('jobCancelrequestContract');
                                setDrawerOpen(true);
                                handleClose();
                            }}
                            disabled={
                                (item.status !== 'CANCELLATION_REQUESTED' &&
                                    item.cancelBy !== 'EMPLOYEE') ||
                                (item.status === 'CANCELLATION_REQUESTED' &&
                                    item.cancelBy !== 'EMPLOYEE') ||
                                item.status === 'CANCELLED' ||
                                item.status === 'STARTED'
                            }
                        >
                            View cancellation request
                        </MenuItem>
                        {/* {item.status !== 'CANCELLED' && (
                            <MenuItem
                                onClick={() => {
                                    setCurrentPage('TimeSheetReview');
                                    setReviewData(item);
                                }}
                            >
                                View Timesheet
                            </MenuItem>
                        )} */}
                    </>
                )}
            </Menu>
        </React.Fragment>
    );
};
export default HourlyJobs;
