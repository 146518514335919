import React from "react";
import moment from "moment";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import { Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Dropdown from "../../../Components/DropdownComponent/Dropdown.component";
import { APIHelper } from "../../../utils/ApiHelper";
import API from "../../../utils/Urls";
import DrawerComponent from "../../../Components/Drawer/Drawer";
import FixedContractRequest from "./Component/FixedContractRequest";
import ContractCancel from "./Component/ContractCancel";
import RevisedMileStonePopup from "./Component/RevisedMileStonePopup";
import FixedContract from "../../EmployerHiringProcess/ProcessStep/Component/FixedContract";
import Swal from "sweetalert2";

const FixedFeeJobs = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [sortBy, setSortBy] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerPage, setDrawerPage] = React.useState("contractCancel");
  const [revisedContract, setRevisedContract] = React.useState(false);
  const [contractId, setContractId] = React.useState();
  const [item, setItem] = React.useState(null);

  React.useEffect(() => {
    getListFixedJobs();
  }, [page, sortBy]);

  const getListFixedJobs = () => {
    setLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(API.GET_LIST_EMPLOYEE_FIXED_JOBS + `page=${page}&sort=${sortBy ? sortBy.value : ""}`, headers)
      .then((res) => {
        setData(res[0].data);
        setTotal(res[0].total);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleClick = (event, item) => {
    setContractId(item._id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelJob = (id, data) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.putMethod(`${API.CANCEL_CONTRACTS}${id}`, data, headers)
      .then((res) => {
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Job cancel successfully!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        }).then((result) => {
          if (result) {
            setDrawerOpen(false);
            getListFixedJobs();
          }
        });
      })
      .catch((error) => console.log(error));
  };

  const finalCancelJob = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.putMethod(`${API.FINAL_CANCEL_CONTRACTS}${contractId}`, null, headers)
      .then((res) => {
        setDrawerOpen(false);
        getListFixedJobs();
      })
      .catch((error) => console.log(error));
  };

  const finalCancelContractJob = (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.postMethod(`${API.CONTRACT_NOT_CANCELLED}`, { contract_id: id }, headers)
      .then((res) => {
        setDrawerOpen(false);
        getListFixedJobs();
      })
      .catch((error) => console.log(error));
  };

  const getReturnStatus = (status, startDate, endDate, mileStoneUpdate, customerUnderReview, cancelBy) => {
    if (mileStoneUpdate && !customerUnderReview) {
      return { status: "MILESTONE_REVIEW" };
    }
    switch (status) {
      case "CANCELLATION_REQUESTED":
        if (cancelBy === "EMPLOYER") {
          return { status: "CANCELLED_BY" };
        }
        return { status: "CANCELLED" };
      case "CANCELLED":
        return { status: "CANCELLED_FINAL" };
      case "STARTED":
        const isStart = moment(startDate) >= moment();
        const isEnd = moment(endDate) < moment();
        if (isStart) {
          return { status: "NOT_STARTED" };
        } else if (isEnd) {
          return { status: "COMPLETED" };
        } else {
          return { status: "IN_PROGRESS" };
        }
      default:
        return { status: "IN_PROGRESS" };
    }
  };

  return (
    <>
      <div className="editDetailsContentHeading">Fixed Fee Jobs</div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <p className="SortByDropdown">Sort by:</p>
        <div style={{ width: "170px" }}>
          <Dropdown
            options={[
              { label: "Start Date", value: "startDate" },
              { label: "Job Title", value: "jobTitle" },
            ]}
            value={sortBy}
            placeholder="Select sorting"
            onChange={(e) => {
              setPage(1);
              setSortBy(e);
            }}
          />
        </div>
      </div>
      <table
        style={{
          width: "100%",
          textAlign: "left",
          borderCollapse: "collapse",
          fontFamily: "Libre Franklin",
        }}
      >
        <thead>
          <tr
            style={{
              borderBottom: "2px solid #C4C4C4",
              height: 60,
            }}
          >
            <th style={{ width: "20%", color: "#1c1c1c" }}>Company Name</th>
            <th style={{ width: "18%", color: "#1c1c1c" }}>Job Title</th>
            <th style={{ width: "18%", color: "#1c1c1c" }}>Start Date</th>
            <th style={{ width: "18%", color: "#1c1c1c" }}>End Date</th>
            <th style={{ width: "20%", color: "#1c1c1c" }}>Status</th>
            <th style={{ width: "6%" }}></th>
          </tr>
        </thead>
        {loading ? (
          <tr style={{ textAlign: "center", height: 100 }}>
            <td colSpan="6">
              <CircularProgress size={40} />
            </td>
          </tr>
        ) : (
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => {
                const progessBar = getReturnStatus(
                  item.status,
                  item.contractStartedOn,
                  item.jobEndOn,
                  item.isMileStoneUpdated,
                  item.isConfirmWithEmployee,
                  item.cancelBy
                );
                return (
                  <tr
                    style={{
                      height: 110,
                      borderBottom: "2px solid #C4C4C4",
                      backgroundColor: "inherit",
                    }}
                  >
                    <td style={{ width: "20%" }}>
                      {item.profiles && item.profiles.length > 0 && item.profiles[0].company && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <AccountCircleIcon
                              style={{
                                height: "70px",
                                width: "70px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              margin: "auto",
                              marginLeft: 10,
                              opacity: 0.78,
                            }}
                          >
                            <p className="hiringEmployeeName">
                              {item.profiles &&
                                item.profiles.length > 0 &&
                                item.profiles[0].company &&
                                item.profiles[0].company.name}
                            </p>
                          </div>
                        </div>
                      )}
                    </td>
                    <td
                      style={{
                        width: "18%",
                        color: "#1c1c1c",
                      }}
                    >
                      {item.jobs && item.jobs.length > 0 && item.jobs[0].title}
                    </td>
                    <td
                      style={{
                        width: "18%",
                        color: "#1c1c1c",
                      }}
                    >
                      {item.jobs && item.jobs.length > 0 && moment(item.jobs[0].startDate).utc(0).format("MMM DD, YY")}
                    </td>
                    <td
                      style={{
                        width: "18%",
                        color: "#1c1c1c",
                      }}
                    >
                      {item.jobs && item.jobs.length > 0 && moment(item.jobs[0].endDate).utc(0).format("MMM DD, YY")}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        paddingRight: 20,
                      }}
                    >
                      <ProgressBar type="FIXED" variant={progessBar.status} />
                    </td>
                    <td style={{ width: "6%" }}>
                      <CustomMenu
                        setRevisedContract={(value, item) => {
                          setRevisedContract(value);
                          setItem(item);
                        }}
                        setDrawerPage={setDrawerPage}
                        item={item}
                        handleClick={handleClick}
                        anchorEl={anchorEl}
                        setDrawerOpen={setDrawerOpen}
                        isMileStoneUpdated={item.isMileStoneUpdated && !item.isConfirmWithEmployee}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ textAlign: "center", height: 100 }}>
                <td colSpan="6">
                  <p>No data found.</p>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      {!loading && data.length > 0 && (
        <div className="advancedSearchPagination" style={{ marginTop: 85, marginBottom: 86 }}>
          <Pagination
            count={Math.ceil(total / 10)}
            page={page}
            onChange={(e, value) => setPage(value)}
            style={{ justifyContent: "center" }}
            size="large"
          />
        </div>
      )}
      <DrawerComponent
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        component={
          <>
            {drawerPage === "viewCancellationContract" ? (
              <ContractCancel
                page="FIXED"
                finalCancelJob={() => {
                  finalCancelJob();
                }}
                finalCancelContractJob={(id) => {
                  finalCancelContractJob(id);
                }}
                contractId={contractId}
                handleClose={() => setDrawerOpen(false)}
              />
            ) : drawerPage === "cancelContract" ? (
              <FixedContractRequest
                handleClose={() => setDrawerOpen(false)}
                contractId={contractId}
                cancelJob={(id, data) => {
                  cancelJob(id, data);
                }}
              />
            ) : (
              <FixedContract contractId={contractId} isEmployee />
            )}
          </>
        }
        width="80%"
      />
      {revisedContract && (
        <div id="overlay">
          <div className="overlayImageStyle">
            {revisedContract && (
              <div
                style={{
                  width: "68.64vw",
                  height: "39vw",
                  backgroundColor: "white",
                  position: "absolute",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <RevisedMileStonePopup
                  cancelBtn={() => {
                    setRevisedContract(false);
                  }}
                  getListFixedJobs={getListFixedJobs}
                  item={item}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const CustomMenu = ({ item, anchorEl, handleClick, setDrawerPage, setDrawerOpen, setRevisedContract, isMileStoneUpdated }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const handleClose = () => {
    setIsMenuOpen(false);
  };
  return (
    <React.Fragment>
      <MoreHorizIcon
        onClick={(e) => {
          handleClick(e, item);
          setIsMenuOpen(true);
        }}
        style={{
          fill: "#9A9A9A",
          border: "2px solid #9A9A9A",
          borderRadius: "50%",
          height: 34,
          width: 34,
        }}
      />
      <Menu id={`simple-menu-${item._id}`} anchorEl={anchorEl} keepMounted open={isMenuOpen} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setDrawerPage("viewContract");
            setDrawerOpen(true);
            handleClose();
          }}
        >
          View Contract
        </MenuItem>
        {moment(item.jobEndOn) > moment() && (
          <>
            <MenuItem
              onClick={() => {
                setDrawerPage("cancelContract");
                setDrawerOpen(true);
                handleClose();
              }}
              disabled={item.status !== "STARTED"}
            >
              Cancel Job
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDrawerPage("viewCancellationContract");
                setDrawerOpen(true);
                // setCancelReason(true);
                handleClose();
              }}
              disabled={
                (item.status !== "CANCELLATION_REQUESTED" && item.cancelBy !== "EMPLOYER") ||
                (item.status === "CANCELLATION_REQUESTED" && item.cancelBy !== "EMPLOYER") ||
                item.status === "CANCELLED" ||
                item.status === "STARTED"
              }
            >
              View cancellation request
            </MenuItem>
            {isMileStoneUpdated && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setRevisedContract(true, item);
                }}
              >
                Review Milestone Update
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </React.Fragment>
  );
};
export default FixedFeeJobs;
