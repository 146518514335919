import React, { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { APIHelper } from '../../utils/ApiHelper';
import Button from '../../Components/Button/Button';
import API, { STRIPE_PAYMENT_REFRESH_URL, STRIPE_PAYMENT_RETURN_URL } from '../../utils/Urls';
import CloseIcon from '@material-ui/icons/Close';

const BankingModule = (props) => {
    const [showBanking, setShowBanking] = useState(false);
    const [isBankExist, setIsBankExit] = useState(false);
    const [loader, setLoader] = useState(true);
    const [isPaymentIntagreted, setPaymentIntagreted] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [bankNumber, setBankNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankStatus, setBankStatus] = useState(undefined);
    const [stripeConnectId, setStripeConnectID] = useState('');
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [showDeleteBanking, setShowDeleteBanking] = useState(false);
    const [activeContract, setActiveContract] = useState();

    const getAccountDetails = () => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        setLoader(true);
        APIHelper.getMethod(API.GET_STRIPE_ACCOUNT_DETAILS, headers)
            .then((res) => {
                setLoader(false);
                if (res && res.isPaymentMethodConnect === 'false') {
                    setPaymentIntagreted(false);
                } else {
                    setPaymentIntagreted(true);
                }
                setStripeConnectID(res.stripe_connect_id);
                if (res && res.banks && res.banks.length > 0) {
                    setBankNumber(res.banks[0].last4);
                    setBankName(res.banks[0].bank_name);
                    setBankStatus(res.stripe_status);
                } else {
                    setBankNumber('');
                    setBankName('');
                    setBankStatus('');
                }
            })
            .catch((error) => {
                setLoader(false);
            });

        if (localStorage.getItem("userType") === "EMPLOYER") {
            APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_METHOD, headers)
                .then((res) => {
                    setActiveContract(res.result)
                    console.log(res);
                })
                .catch((error) => {
                    setLoader(false);
                });
        } else {
            APIHelper.getMethod(API.GET_EMPLOYEE_PAYMENT_METHOD, headers)
                .then((res) => {
                    setActiveContract(res.result)
                })
                .catch((error) => {
                    setLoader(false);
                });
        }
    };

    useEffect(() => {
        getAccountDetails();
    }, []);

    useEffect(() => {
        if (bankStatus !== undefined && bankStatus !== '' && props.refreshStatus) {
            setTimeout(() => {
                props.onRefreshUpdate();
            }, 500);
        }
    }, [bankStatus]);

    useEffect(() => {
        if (stripeConnectId !== undefined && stripeConnectId !== '' && props.refreshStatus) {
            refreshStatus();
        }
    }, [stripeConnectId]);

    const verifyStripeConnection = () => {
        setUpdateLoading(true);
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        setBtnLoading(true);

        const refresh_url = STRIPE_PAYMENT_REFRESH_URL;
        const return_url = STRIPE_PAYMENT_RETURN_URL;

        APIHelper.postMethod(
            API.GET_STRIPE_ACCOUNT_ID,
            { refresh_url, return_url, ActionTypeStr: 'create' },
            headers
        )
            .then((res) => {
                const link = res?.data?.url;
                window.location.href = link;
            })
            .catch((error) => {
                setBtnLoading(false);
                setUpdateLoading(false);
            });
    };

    const removeStripeConnection = () => {
        setLoader(true);
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.postMethod(API.STRIPE_REMOVE_CONNECT_ACCOUNT, {}, headers)
            .then((res) => {
                setShowDeleteBanking(false);
                setLoader(false);
                getAccountDetails();
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    const refreshStatus = () => {
        setRefreshLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(
            API.GET_STRIPE_ACCOUNT_LINK_STATUS,
            { status: true, account_id: stripeConnectId },
            headers
        )
            .then((res) => {
                setBankStatus(res.data.status);
                setRefreshLoading(false);
                if (props.refreshStatus) {
                    getAccountDetails();
                }
            })
            .catch((error) => {
                console.log(error);
                setRefreshLoading(false);
            });
    };

    return (
        <>
            <Card
                style={{
                    width: '100%',
                    // height: '17vw',
                    borderRadius: '1.041vw',
                    margin: 'auto',
                    marginLeft: 'auto',
                    backgroundColor: '#F5F5F5',
                    marginTop: 40,
                    padding: 20,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        height: 30,
                        alignItems: 'center',
                        // marginTop: 32,
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                    }}
                >
                    <p
                        style={{
                            fontFamily: 'Libre Franklin',
                            fontSize: 18,
                            color: '#9A9A9A',
                            marginTop: 6,
                        }}
                    >
                        Deposit Account Details
                    </p>
                    <Button
                        text="Add account"
                        height="48px"
                        // width={'140px'}
                        color={['#2699fb', '#134d7e']}
                        disabled={isPaymentIntagreted}
                        onClick={() => !isPaymentIntagreted && verifyStripeConnection()}
                        loading={btnLoading}
                    />
                    {/* <p
                        style={{
                            fontSize: 18,
                            fontWeight: 500,
                            marginLeft: 6,
                            color: !isPaymentIntagreted ? '#070707' : '#C4C4C4',
                        }}
                        onClick={() => !isBankExist && setShowBanking(true)}
                    >
                        Add a Banking deposit Amount
                    </p> */}
                </div>
                <div className="EditDetailsBottomLineStyles" style={{ marginTop: 20 }} />
                <div style={{ marginTop: 50, marginLeft: 6 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ fontSize: 16, fontWeight: 500, color: '#707070' }}>
                            Current Bank Info
                        </p>
                        <p style={{ fontSize: 16, fontWeight: 500, color: '#707070', width: 200 }}>
                            Stripe Account Status
                        </p>
                    </div>
                    <div
                        style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}
                    >
                        <p style={{ fontSize: 16, fontWeight: 500, color: '#707070' }}>
                            Deposit Ending in ***** {bankNumber} {bankName} {!bankName && 'Bank'}
                        </p>
                        <p style={{ width: 200 }}>
                            {bankStatus !== undefined && bankName && bankNumber && (
                                <span style={{ color: bankStatus === 'pending' ? 'red' : 'green' }}>
                                    ({bankStatus})
                                </span>
                            )}
                        </p>
                        {/* <p
                            style={{
                                marginLeft: 16,
                                marginRight: 16,
                                color: isPaymentIntagreted ? '#070707' : '#C4C4C4',
                                cursor: 'pointer',
                            }}
                            onClick={() => isPaymentIntagreted && verifyStripeConnection()}
                        >
                            {isPaymentIntagreted && updateLoading ? 'Loading...' : 'Update'}
                        </p>
                        <p
                            style={{
                                color: isPaymentIntagreted ? '#070707' : '#C4C4C4',
                                cursor: 'pointer',
                            }}
                            onClick={() => isPaymentIntagreted && removeStripeConnection()}
                        >
                            Remove
                        </p>
                        <p
                            style={{
                                // color: isPaymentIntagreted ? '#070707' : '#C4C4C4',
                                cursor: 'pointer',
                                marginLeft: 16,
                            }}
                            onClick={() => refreshStatus()}
                        >
                            {refreshLoading ? 'Loading...' : 'Refresh'}
                        </p> */}
                    </div>
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}
                    >
                        <div style={{ display: 'flex', marginBottom: 15 }}>
                            <Button
                                text="Change"
                                width={'140px'}
                                disabled={!isPaymentIntagreted}
                                height="46px"
                                onClick={() => isPaymentIntagreted && verifyStripeConnection()}
                                color={['#2699fb', '#134d7e']}
                            />
                            <div style={{ marginLeft: 20 }}>
                                <Button
                                    text="Remove"
                                    width={'140px'}
                                    disabled={!isPaymentIntagreted || activeContract || !bankNumber}
                                    height="46px"
                                    onClick={() => isPaymentIntagreted && setShowDeleteBanking(true)}
                                    color={['#2699fb', '#134d7e']}
                                />
                            </div>
                        </div>
                        <div style={{ width: 200 }}>
                            <Button
                                text="Refresh Status"
                                height="46px"
                                // width={'140px'}
                                color={['#2699fb', '#134d7e']}
                                onClick={() => refreshStatus()}
                                loading={refreshLoading}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            {showBanking && (
                <div id="overlay">
                    <div
                        style={{
                            width: '38vw',
                            height: '15vw',
                            backgroundColor: 'white',
                            position: 'absolute',
                            borderRadius: '10px',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%,-50%)',
                            padding: 20,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: 14,
                            }}
                        >
                            <h2 style={{ fontFamily: 'Libre Franklin' }}>Stripe Registration</h2>
                            <CloseIcon onClick={() => setShowBanking(false)} />
                        </div>
                        <hr />
                        <p style={{ marginTop: 24 }}>
                            You can create an account on stripe as an Individual. To do so select
                            "Individual/ Sole Partnership" on Stripe page. You can use your own name
                            and phone number for "Business Registered Name" and "Customer Support
                            Number". You can use the URL to your Profile on Empolink as an
                            alternative for your Website.{' '}
                        </p>
                    </div>
                </div>
            )}
            {showDeleteBanking && (
                <div id="overlay">
                    <div
                        style={{
                            width: '38vw',
                            height: '15vw',
                            backgroundColor: 'white',
                            position: 'absolute',
                            borderRadius: '10px',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%,-50%)',
                            padding: 20,
                            textAlign: 'center',
                        }}
                    >
                        <p style={{ marginTop: 24 }}>
                            Are you sure you want to delete your Deposit Account?
                        </p>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
                            <div style={{ marginRight: 10 }}>
                                <Button
                                    text="No"
                                    color={['#2699fb', '#134d7e']}
                                    height="56px"
                                    width={'12vw'}
                                    onClick={() => setShowDeleteBanking(false)}
                                />
                            </div>
                            <Button
                                text="Yes"
                                height="56px"
                                width={'12vw'}
                                variant="outlined"
                                color={['#2699fb', '#134d7e']}
                                onClick={() => {
                                    removeStripeConnection();

                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default BankingModule;
