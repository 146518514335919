import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';

import {useEffect, useState , useRef} from 'react';

export default function DrawerMenuMobile(props) {

  const [selectionValue, setSelectionValue] = React.useState(0);

  useEffect(() => {
      setSelectionValue(props.selectedValue);
    }, [props.selectedValue]);


  return (
    <List>
    </List>
    );
}
