import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

const CheckboxComponent = (props) => {
    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        style={{
                            color: props.color || '#018786',
                            padding: props.padding ? props.padding : null,
                        }}
                        checked={props.checked}
                        onChange={props.onChange}
                        name={props.name}
                        disabled={props.disabled}
                    />
                }
                label={
                    <div style={{ color: '#9a9a9a' }} className="rememberMe">
                        {props.label}
                    </div>
                }
            />
        </>
    );
};

export default CheckboxComponent;
