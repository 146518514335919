import React from 'react';
import ContainedButtons from '../../../../Components/Button/Button';
import Dropdown from '../../../../Components/DropdownComponent/Dropdown.component';
import TextField from '../../../../Components/TextField/Textfield';

const CancellationReasonPopup = (props) => {
    const [reason, setReason] = React.useState('');
    const [reaonErr, setReasonErr] = React.useState(false);
    const [reasonType, setReasonType] = React.useState('Change of Availability');
    const [reasonTypeErr, setReasonTypeErr] = React.useState(false);

    const onWithdrawConfirm = () => {
        var isValid = true;
        if (!reasonType) {
            isValid = false;
            setReasonTypeErr(true);
        }

        if (reasonType === 'Other') {
            if (reason.trim() === '') {
                isValid = false;
                setReasonErr(true);
            }
        }

        if (reasonType && isValid) {
            props.yesButtonClick({
                reason,
                reasonType,
            });
        }
    };

    return (
        <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
            <div style={{ display: 'flex' }}>
                <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>Cancellation Reason</h3>
            </div>
            <div style={{ marginTop: 50 }}>
                <div style={{ width: '50%' }}>
                    <Dropdown
                        options={[
                            {
                                label: 'Emergency Situation',
                                value: 'Emergency Situation',
                            },
                            {
                                label: 'Change of Availability',
                                value: 'Change of Availability',
                            },
                            { label: 'Other', value: 'Other' },
                        ]}
                        placeholder="Select job cancellation type"
                        onChange={(e) => {
                            setReasonType(e.value);
                            setReasonTypeErr(false);
                            setReason("");
                        }}
                        error={reasonTypeErr}
                        value={{ label: reasonType, value: reasonType }}
                    />
                </div>
                <div
                    style={{
                        marginTop: 30,
                    }}
                >
                    <TextField
                        rows={8}
                        multiline
                        width={'96%'}
                        label={'Reason'}
                        value={reason}
                        onChange={(e) => {
                            setReason(e.target.value);
                            setReasonErr(false);
                        }}
                        disabled={reasonType !== 'Other'}
                        error={reaonErr}
                    />
                </div>
                <div style={{ marginTop: 30 }}>
                    <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                        Are you sure you want to cancel the job?
                    </p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <div style={{ marginRight: 10 }}>
                        <ContainedButtons
                            text="No"
                            color={['#a4c772', '#4e8400']}
                            height="56px"
                            width={"12vw"}
                            onClick={() => props.cancelBtn()}
                        />
                    </div>
                    <ContainedButtons
                        text="Yes"
                        height="56px"
                        width={"12vw"}
                        variant="outlined"
                        color={['#a4c772', '#4e8400']}
                        onClick={() => onWithdrawConfirm()}
                    />
                </div>
            </div>
        </div>
    );
};

export default CancellationReasonPopup;
