import React, { useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Swal from 'sweetalert2';
import '../HiringProcess.scss';

import CentralizeRangeDatePicker from '../../JobPostPages/components/centralize_range_date_picker';
import ContainedButtons from '../../../Components/Button/Button';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import { checkIfDuplicateExists } from '../../../utils/commonFunction';

const MileStone = (props) => {
    const [mileStoneData, setMileStoneData] = React.useState(props.data);
    const [dateSchedule,setDateSchedule] = React.useState();
    const [isActive,setIsActive] = React.useState(true);
    const [isUpdateData,setIsUpdateData] = React.useState();
    useEffect(() => { 
        const tempDate = []
        props?.data?.forEach((item)=> {
            if(item?.dateSchedule){
                tempDate.push(moment(item.dateSchedule).format('YYYY-MM-DD HH:mm:ss'))   
            }
        })
        setDateSchedule(tempDate);
    }, [props.data]);

    useEffect(()=>{
        if(isUpdateData){
            if(!dateSchedule.includes(isUpdateData)){
                setIsActive(false);
            }
        }
    },[isUpdateData])

    // useEffect(() => {
    //     setMileStoneStatus();
    // }, [props.data]);

    // const setMileStoneStatus = () => {
        // let initialData = mileStoneData;
        // let flag = false;
        // let data =
        //     initialData &&
        //     initialData.length > 0 &&
        //     initialData.map((item) => {
        //         if (moment(props.startDate, 'YYYY-MM-DDT00:00:00.000Z') > moment()) {
        //             return { ...item, status: 'Not Started' };
        //         } else if (
        //             moment(item.dateSchedule) >=
        //                 moment(props.startDate, 'YYYY-MM-DDT00:00:00.000Z') &&
        //             moment(item.dateSchedule) < moment()
        //         ) {
        //             return { ...item, status: 'Completed' };
        //         } else if (
        //             !flag &&
        //             moment(item.dateSchedule) >
        //                 moment(props.startDate, 'YYYY-MM-DDT00:00:00.000Z') &&
        //             moment(item.dateSchedule) >= moment()
        //         ) {
        //             flag = true;
        //             return { ...item, status: 'In Progress' };
        //         } else {
        //             return { ...item, status: 'Not Started' };
        //         }
        //     });
            
        // setMileStoneData(mileStoneData);
    // };

    const handleDateChange = (date, index) => {
        let updatedObj = Object.assign({}, mileStoneData[index], { proposedDate: date });
        setMileStoneData((r) => [...r.slice(0, index), updatedObj, ...r.slice(index + 1)]);
    };

    const updateMileStone = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const newMileStoneFilterArray = mileStoneData.map((m) =>
            m.proposedDate
                ? moment(m.proposedDate).utc(0).format('MMM DD YYYY')
                : moment(m.dateSchedule).utc(0).format('MMM DD YYYY')
        );

        if (!checkIfDuplicateExists(newMileStoneFilterArray)) {
            let data = {
                milestone: mileStoneData.map((item) => {
                    return {
                        ...item,
                        proposedDate: item.proposedDate
                            ? moment(item.proposedDate).utc(0).format('YYYY-MM-DD')
                            : null,
                    };
                }),
            };
            APIHelper.putMethod(`${API.UPDATE_MILESTONES}${props.contractId}`, data, headers)
                .then((res) => {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: 'Milestones are updated successfully!',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((swapProp) => {
                        if (swapProp.isConfirmed) {
                            props.backButton();
                        }
                    });
                    props.getListFixedJobs();
                    // setMileStoneStatus();
                })
                .catch((error) => console.log(error));
        } else {
            Swal.fire({
                customClass: 'Alert-Class',
                title: 'Error!',
                text: 'Milestone payment dates duplicate.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1876D2',
            });
        }
    };

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="editDetailsContentHeading">Milestones</div>
                <CloseIcon onClick={() => props.backButton()} style={{ cursor: 'pointer' }} />
            </div>
            <div style={{ display: 'flex', marginTop: 23 }}>
                <p style={{ fontSize: 20, color: '#9A9A9A' }}>Job Title:</p>
                <p style={{ fontSize: 20, marginLeft: 6 }}>{props.jobTitle}</p>
            </div>
            <div style={{ display: 'flex', marginTop: 17 }}>
                <p style={{ fontSize: 20, color: '#9A9A9A' }}>Talent Name:</p>
                <p style={{ fontSize: 20, marginLeft: 6 }}>{props.employeeName}</p>
            </div>
            <div style={{ fontSize: 20, marginTop: 17, color: '#1876D2' }}>
                <p>
                    You don’t need to approve each milestone unless you want to delay a milestone.
                </p>
            </div>
            <div
                style={{
                    width: '75%',
                    marginTop: 17,
                    border: '2px solid #c7c7c7',
                    borderRadius: 10,
                    paddingTop: 10,
                    textAlign: 'center',
                }}
            >
                <div
                    style={{
                        flex: 1,
                        display: 'flex',
                        height: 58,
                        borderBottom: '2px solid #c7c7c7',
                        lineHeight: '58px',
                    }}
                >
                    <th style={{ flex: 1 }}>Milestones</th>
                    <th style={{ flex: 1 }}>Status</th>
                    <th style={{ flex: 1 }}>Amount</th>
                    <th style={{ flex: 1 }}>Payment Date</th>
                    <th style={{ flex: 0.5 }}></th>
                </div>
                {mileStoneData !== null ? (
                    mileStoneData.map((item, index) => (
                        <DataRow
                            key={index}
                            setIsUpdateData={setIsUpdateData}
                            mileStone={item.description}
                            status={item.status}
                            amount={item.amount}
                            paymentDate={
                                item.proposedDate
                                    ? moment(item.proposedDate).utc(0).format('MMM DD YYYY')
                                    : moment(item.dateSchedule).utc(0).format('MMM DD YYYY')
                            }
                            handleDateChange={(date) => {
                                handleDateChange(date, index);
                            }}
                            startDate={props.startDate}
                            endDate={props.endDate}
                            index={index}
                            mileStoneData={mileStoneData}
                        />
                    ))
                ) : (
                    <div>
                        <p style={{ fontSize: '24px' }}>No Milestones Found...</p>
                    </div>
                )}
            </div>
            <div
                style={{
                    width: '75%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 67,
                    marginBottom: 100,
                }}
            >
                <p
                    style={{
                        fontSize: 20,
                        color: '#1e7ac9',
                        textDecoration: 'underline',
                        lineHeight: '48px',
                        cursor: 'pointer',
                    }}
                    onClick={() => props.backButton()}
                ></p>
                <ContainedButtons
                    onClick={() => {
                        updateMileStone();
                    }}
                    disabled={isActive}
                    text="Save and notify talent"
                    color={['#2493F1', '#15568D']}
                    height={48}
                />
            </div>
        </React.Fragment>
    );
};

export const DataRow = (props) => {
    // const [calenderShow, setCalenderShow] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleDateChange = (dates) => {
        let date = new Date(dates[0].year, dates[0].month.number - 1, dates[0].day);
        props.handleDateChange(moment(date).format('YYYY-MM-DD HH:mm:ss'));
        props.setIsUpdateData(moment(date).format('YYYY-MM-DD HH:mm:ss'));
        handleClose();
    };

    let today = new Date().toISOString();
    let startDate=props.startDate;
    let minimum_date = null;
    const previous_index = props.index - 1;
    if (previous_index >= 0) {
        const item = props.mileStoneData[previous_index];
        minimum_date = item.proposedDate
            ? new Date(item.proposedDate) >= new Date()
                ? new Date(item.proposedDate)
                : new Date()
            : new Date(item.dateSchedule) >= new Date()
            ? new Date(item.dateSchedule)
            : new Date();
    item.proposedDate ?
        minimum_date.setDate(minimum_date.getDate() + 1)
        : minimum_date.setDate(minimum_date.getDate() + 2);
    } else {
        minimum_date = new Date((startDate<=today?today:startDate))
        minimum_date.setDate(minimum_date.getDate() + 2);
    }

    let paymentDate = new Date(props.paymentDate).toISOString()
    
    let maximum_date
    const item = props.mileStoneData[props.index +1 ];
    if(item)
    {
        maximum_date = item.proposedDate
            ? new Date(item.proposedDate) >= new Date()
                ? new Date(item.proposedDate)
                : new Date()
            : new Date(item.dateSchedule) >= new Date()
            ? new Date(item.dateSchedule)
            : new Date();
            item.proposedDate ?
            maximum_date.setDate(maximum_date.getDate() - 1)
            : maximum_date.setDate(maximum_date.getDate());
    }
    else{
        maximum_date = new Date(props.endDate)
    }
    let datePlus1=(date)=>{
    let newDate = new Date(date);
    let datePlus1 = new Date(newDate.getTime());
    datePlus1.setDate(datePlus1.getDate() + 1);
    return datePlus1.toISOString();
}

    return (
        <React.Fragment>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    height: 58,
                    borderBottom: '2px solid #c7c7c7',
                    lineHeight: '58px',
                }}
                className="mileStoneRow"
            >
                <td style={{ flex: 1, overflow: 'clip' }}>{props.mileStone}</td>
                <td style={{ flex: 1 }}>{props.status}</td>
                <td style={{ flex: 1 }}>${props.amount}</td>
                <td style={{ flex: 1 }}>{props.paymentDate}</td>
                <td style={{ flex: 0.5 }}>
                    {(
                        <React.Fragment>
                            <div>
                                <EditIcon style={{ color: '#c7c7c7' }} onClick={!(paymentDate<today)? handleClick : ()=>{}} />
                            </div>
                            <Popover
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                            >
                                <CentralizeRangeDatePicker
                                    // key={this.state.refreshData}
                                    onChangeCallback={(dates) => {
                                        handleDateChange(dates);
                                    }}
                                    minDate={minimum_date}
                                    maxDate={maximum_date}
                                />
                            </Popover>
                        </React.Fragment>
                    )}
                </td>
            </div>
        </React.Fragment>
    );
};

export default MileStone;
