export const identificationLevel = [
    { label: 'Bussiness Licence Number', value: 'Bussiness Licence Number' },
    { label: "Driver's Licence Number", value: "BDriver's Licence Number" },
    { label: 'Passport Number', value: 'Passport Number' },
];
 
export const notificationSettings = [
    {
        title: "When I am invited for a job",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When a job offer is cancelled",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When I receive a message",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When my bid is approved",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "Send me a copy of my contract",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When a payment is processed",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When an initial payment is pre-authorized",
        subTitle: "",
        email: false,
        mobile: false
    },
    {
        title: "When client cancles/terminates a job/contract",
        subTitle: "",
        email: false,
        mobile: false
    }
];
