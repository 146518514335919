
export  const LOADING = () => ({
});

export const ERROR = (errorMessage) => ({
  errorMessage : errorMessage
});

export const SUCCESS = (data,userid) => ({
    data : data,
    userid : userid
});
