import React from "react";
// import ReactTags from "react-tag-autocomplete";
import CreatableSelect from 'react-select/creatable';
import "./MultiTagSelect.scss";

const createOption = (label) => ({
  label,
  value: label,
});

class MultiTagSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      value: [],
    }
  }

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state;

    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case ',':
        if (inputValue.split(' ').length <= 3 && this.props.speciality) {

          const isExist = this.props.tags.some((e) => e.name.toLowerCase() === inputValue.toLowerCase());
          if (!isExist) {
            const newValue = [...value, createOption(inputValue)];
            const size = 20 - this.props.size;
            if (newValue.length <= size) {
              this.setState({
                inputValue: '',
                value: newValue,
              }, () => {
                this.props.onChange(this.state.value);
              });
            }
          }
        }
        event.preventDefault();
    }
  };

  handleChange = (value, actionMeta) => {
    const size = 20 - this.props.size;
    if (value.length <= size && this.props.speciality) {
      this.setState({ value }, () => {
        this.props.onChange(this.state.value);
      });
    }
  };

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        minHeight: 56,
        overflow: 'auto',
        borderRadius: this.props.borderRadius ? this.props.borderRadius : 10,
        borderColor: this.props.error ? "red" : "rgba(0, 0, 0, 0.23)",
      }),
      menu: (base) => ({
        ...base,
        zIndex: 10,
        width: "max-content",
        minWidth: "100%",
        borderRadius: 14,
      }),
      menuList: (base) => ({
        ...base,
        borderRadius: 14,
      }),
      placeholder: (base) => ({
        ...base,
        color: this.props.error ? "red" : base.color,
      }),
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: '#a4c772',
          padding: 8,
          paddingTop: 12,
          paddingBottom: 12,
          borderRadius: 16,
        };
      },
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
        marginRight: 4,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: '#a4c772',
        backgroundColor: '#7a9354',
        cursor: 'pointer',
        borderRadius: 12,
        ':hover': {
          backgroundColor: data.color,
          color: 'white',
        },
      }),
    };

    return (
      // <ReactTags
      //   tags={this.props.tags}
      //   suggestions={this.props.suggestions}
      //   onDelete={this.props.onDelete}
      //   onAddition={this.props.onAddition}
      //   allowNew
      //   placeholderText={"More Skills (comma separated, Max 10 total)"}
      //   ariaLabelText="Which languages do you speak?*"
      //   minQueryLength={1}
      //   delimiters={['Enter', ","]}
      // />
      <CreatableSelect
        isMulti
        isClearable
        options={this.props.suggestions}
        placeholder="More Skills (comma separated or use TAB)"
        inputValue={this.state.inputValue}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        value={this.state.value}
        onChange={this.handleChange}
        styles={customStyles}
      />
    );
  }
}

export default MultiTagSelect;


