import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';

const stripePromise = loadStripe(
    'pk_test_51JiKbiH9qqKxRW8byfz0vlEqDPW8oj2WTPTYiLLYM6IdXE1SZNTYHLwKMl5vwTtEf2VMlagI9mutFKOOBpNknJ0j00b6AHDrhe'
);

const CreatePayment = (props) => {
    const { stripeConnectID } = props;
    const [clientSec, setClientSecret] = useState('');
    const [key, setKey] = useState(0);

    useEffect(async () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(API.PAYMENT_GENERATE, { stripeConnectID, amount: 100 }, headers).then(
            (response) => {
                setClientSecret(response?.paymentIntent?.client_secret);
                setKey(Math.random());
            }
        );
    }, []);

    return (
        <>
            {clientSec !== '' && (
                <Elements stripe={stripePromise} options={{ clientSecret: clientSec }} key={key}>
                    <form>
                        <PaymentElement />
                        <button>Submit</button>
                    </form>
                </Elements>
            )}
        </>
    );
};

export default CreatePayment;
