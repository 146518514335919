import './App.scss';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './reducers/root_reducer';

import Header from './Components/Header/Header'
import LoginPage from './Pages/LoginPage/LoginPage'
import EmployeeSignUp from './Pages/EmployeeRegister/EmployeeSignUp'
import EmployerSignup from './Pages/Register/EmployerSignup'
import ForgotPassword from './Pages/ForgotPasswordPages/ForgotPassword'
import UpdatePasswordPage from './Pages/ForgotPasswordPages/UpdatePasswordPage/UpdatePasswordPage'
import ThankyouMessage from './Pages/Register/ThankyouMessage/ThankyouMessage'
import ProtectedRoute from './Components/routes/ProtectedRoute'
import EmployerVerifyEmail from './Pages/EmployeeRegister/EmployeeRegisterVerifyEmail/EmployeeRegisterOTP';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Home from './Pages/InitialPages/Home/Home'
import ChooseEmployerEmployee from './Pages/InitialPages/ChooseEmployerEmployee/ChooseEmployerEmployee';
import TalentSearch from './Pages/InitialPages/TalentSearch/TalentSearch';
import EmployerTalentSearchAdvanced from './Pages/EmployerTalentSearchAdvanced/EmployerTalentSearchAdvanced'
import SingleEmployeeInviteDetails from './Pages/SingleEmployeeInviteCards/SingleEmployeeInviteDetails'
import Footer from './Components/Footer/Footer'
import UserAccountDetails from './Pages/UserAccountDetails/UserAccountDetails'
import { useHistory } from "react-router-dom";



import { connect } from 'react-redux'
import EmployeeAccountDetails from './Pages/EmployeeAccountDetails/EmployeeAccountDetails';
import HiringProcess from './Pages/EmployerHiringProcess/HiringProcess';
import EmployeeHiringProcess from './Pages/EmployeeHiringProcess/EmployeeHiringProcess';
import EmployeeSearchTalent from './Pages/EmployeeSearch/EmployeeSearchTalent/EmployeeSearchTalent';
import EmployeeAdvancedSearchTalent from './Pages/EmployeeSearch/EmployeeAdvancedSearchTalent/EmployeeAdvancedSearchTalent';
import './utils/interceptor';
import DashboardOverview from './Components/DashboardOverview/DashboardOverview';
import EmployeeDashboardOverview from './Components/EmployeeDashboardOverview/EmployeeDashboardOverview';
import StripePaymentConnect from './Pages/StripePaymentConnect/StripePaymentConnect';
import PublicProfile from './Pages/PublicProfile';
import HelpAndSupport from './Pages/HelpAndSupport/HelpAndSupport';
import Messages from './Pages/Messages';
import ScrollToTop from './Components/ScrollToTop';
import Pricing from './Pages/InitialPages/Pricing/Pricing';
import adminjobs from './Pages/ExtendedPages/adminjobs';
import devjobs from './Pages/ExtendedPages/devjobs';
import engjobs from './Pages/ExtendedPages/engjobs';
import financejobs from './Pages/ExtendedPages/financejobs';
import foodservicesjobs from './Pages/ExtendedPages/foodservicesjobs';
import hospitalityjobs from './Pages/ExtendedPages/hospitalityjobs';
import itsupportjobs from './Pages/ExtendedPages/itsupportjobs';
import mediajobs from './Pages/ExtendedPages/mediajobs';
import retailjobs from './Pages/ExtendedPages/retailjobs';
import technicianjobs from './Pages/ExtendedPages/technicianjobs';



/* Job Post View */
import PostJobs from './Pages/JobPostPages/post_job'
import Invoices from './Pages/Invoices/Invoices';
import CONSTANTS from './utils/constants';
import './Assets/style.css';
// import './Assets/common.css';

const store = createStore(RootReducer, applyMiddleware(thunk))

function App(props) {
  const history = useHistory();
  // const signInPage = () => {
  //   history.push("/login")
  // }
  // const signUpPage = () => {
  //   history.push("/ChooseEmployeeEmployer")
  // }
  // const employeePage = () => {
  //   history.push("/employee/register")
  // }
  // const employerPage = () => {
  //   history.push("/register")
  // }

  const isAuthenticated = localStorage.getItem('token');

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* <Provider store = { store }> */}
      {/* <LoginPage/> */}

      <Router>
        <ScrollToTop />
        <header className="Header Row">
          {((props.shouldShowHeader || (CONSTANTS.VISIBLE_HEADER_FOOTER_LIST.indexOf(window.location.pathname) > -1)) && isAuthenticated) ? <Header /> : null}
        </header>
        <section className="content Row Expand">
          <Switch>
            <Route exact path="/empolink" component={Home} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/signup" component={ChooseEmployerEmployee} />
            <Route exact path="/register" component={EmployerSignup} />
            <Route exact path="/employee/register" component={EmployeeSignUp} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/update-password" component={UpdatePasswordPage} />
            <Route exact path="/verify-email" component={EmployerVerifyEmail} />
            <Route exact path="/thankyou-message" component={ThankyouMessage} />
            <Route exact path="/account/:id" component={PublicProfile} />
            <Route exact path="/help" component={HelpAndSupport} />
            <Route exact path="/help/clients" component={HelpAndSupport} />
            <Route exact path="/help/talents" component={HelpAndSupport} />
            <Route exact path="/pricing" component={Pricing} /> 
            <Route exact path="/adminjobs" component={adminjobs} />
            <Route exact path="/devjobs" component={devjobs} />
            <Route exact path="/engjobs" component={engjobs} />
            <Route exact path="/financejobs" component={financejobs} />
            <Route exact path="/foodservicesjobs" component={foodservicesjobs} />
            <Route exact path="/hospitalityjobs" component={hospitalityjobs} />
            <Route exact path="/itsupportjobs" component={itsupportjobs} />
            <Route exact path="/mediajobs" component={mediajobs} />
            <Route exact path="/retailjobs" component={retailjobs} />
            <Route exact path="/technicianjobs" component={technicianjobs} />

            {/* <Route exact path="/choose-signin-and-signup" component={ChooseSignInSignUp} /> */}
            <ProtectedRoute exact path="/talent-search" component={TalentSearch} />
            <ProtectedRoute exact path="/talent-search/advance" component={EmployerTalentSearchAdvanced} />
            <ProtectedRoute exact path="/single-employee/invite-details" component={SingleEmployeeInviteDetails} />
            <ProtectedRoute exact path="/new" component={<div>This is not protected</div>} />
            <ProtectedRoute exact path="/job-post" component={PostJobs} />
            <ProtectedRoute exact path={CONSTANTS.INVOICES} component={Invoices} />
            <ProtectedRoute exact path={CONSTANTS.ROUTES_USER_DETAILS} component={UserAccountDetails} />
            <ProtectedRoute exact path="/messages" component={Messages} />

            {/* Employee Route */}
            <ProtectedRoute exact path={CONSTANTS.ROUTE_EMPLOYEE_DETAILS} component={EmployeeAccountDetails} />
            <ProtectedRoute exact path={CONSTANTS.HIRING_PROCESS_EMPLOYER} component={HiringProcess} />
            <ProtectedRoute exact path={CONSTANTS.HIRING_PROCESS_EMPLOYEE} component={EmployeeHiringProcess} />
            <ProtectedRoute exact path={CONSTANTS.EMPLOYEE_SEARCH_TALENT} component={EmployeeSearchTalent} />
            <ProtectedRoute exact path={CONSTANTS.EMPLOYEE_ADVANCED_SEARCH_TALENT} component={EmployeeAdvancedSearchTalent} />
            <ProtectedRoute exact path={CONSTANTS.EMPLOYER_DASHBOARD} component={DashboardOverview} />
            <ProtectedRoute exact path={CONSTANTS.EMPLOYEE_DASHBOARD} component={EmployeeDashboardOverview} />
            {/* <Route exact path="/payment" component={Payment} /> */}
            <ProtectedRoute exact path="/verification" component={StripePaymentConnect} />
            <Route exact path="/" component={Home} />
          </Switch>
        </section>
        {isAuthenticated && <Footer className="Row" />}
      </Router>
    </div>
  );
}


const mapStateToProps = function (state) {
  return {
    shouldShowHeader: state.talentSearchReducer.showHeader,
  }
}

export default connect(mapStateToProps)(App);
