import React from 'react';
import { connect } from 'react-redux';
import Spacer from '../../../components/spacer';
import Text from '../../../components/text';
import InputText from '../../../components/centralize_input_text';
import DropDownList from '../../../components/centralize_dropdown';
import ToggleButton from '../../../components/centralize_toggle_button';
import Constant from '../../../Constant/constant';

import {
    IS_HOURLY,
    HOURLY_RATE,
    FEE_AMOUNT,
    PAYMENT_METHOD,
    PAYMENT_TIME,
    REFRESH_DATE_FORM_DATA,
    HOURLY_RATE_MIN,
    HOURLY_RATE_MAX,
} from '../../../../../action/form_action';

import {
    VERTICAL_NORMAL,
    VERTICAL_EXTRA,
    VERTICAL_SMALL,
    VERTICAL_EXTRA_LARGE,
    HORIZONTAL_NORMAL,
    HORIZONTAL_EXTRA,
} from '../../../../../utils/enums';

class FeePayment extends React.Component {
    render() {
        const {
            isHourlyValue,
            feeTypeValue,
            hourlyRateMinValue,
            hourlyRateMaxValue,
            feeAmountValue,
            paymentMethodValue,
            paymentTimeValue,
            error,
        } = this.props;

        function handleDecimalsOnValue(value) {
            const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
            return value.match(regex)[0];
        }

        return (
            <div>
                <Text
                    fontSize={Constant.FONT_SIZE_WEB}
                    color={Constant.COLOR_BLACK_500}
                    text="Fee Type"
                />
                <Spacer spaceType={VERTICAL_SMALL} />
                <DropDownList
                    dropDownList={[{ lable: 'Hourly $' }, { lable: 'Fixed fee $' }]}
                    label={'Hourly $'}
                    text={feeTypeValue}
                    fontSize={Constant.FONT_SIZE_WEB}
                    width="40%"
                    menuWidth="17%"
                    fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                    onSelectedValueCallback={(value) => {
                        if (value === 'Hourly $') {
                            if (!isHourlyValue) {
                                this.props.refreshFormData();
                            }
                            this.props.feeAmount('');
                            this.props.feeType(true, value);
                        } else {
                            if (isHourlyValue) {
                                this.props.refreshFormData();
                            }
                            this.props.hourlyRateMin('');
                            this.props.hourlyRateMax('');
                            this.props.feeType(false, value);
                        }
                    }}
                />
                <Spacer spaceType={VERTICAL_EXTRA} />
                <Text
                    fontSize={Constant.FONT_SIZE_WEB}
                    color={Constant.COLOR_BLACK_500}
                    text={`Hourly Rate: (${this.props.jobPostLocation === 'Within my Country'
                        ? localStorage.getItem('localCurrency')
                        : 'USD'
                        })`}
                />
                <Spacer spaceType={VERTICAL_SMALL} />
                <div style={{ display: 'flex' }}>
                    {' '}
                    {/* TODO */}
                    <InputText
                        id="hourlyRate"
                        label={'$ Min'}
                        disabled={!isHourlyValue ? true : false}
                        fontSize={Constant.FONT_SIZE_WEB}
                        error={error.hourlyRateMin || false}
                        width="15%"
                        text={hourlyRateMinValue}
                        newProps={{
                            inputProps: {
                                maxLength: 13,
                                step: "1"
                            }
                        }}
                        onChangeCallback={(event) => {
                            this.props.hourlyRateMin(handleDecimalsOnValue(event))
                        }}
                    />
                    <div style={{ marginLeft: 10, width: '100%' }}>
                        <InputText
                            id="hourlyRate"
                            label={'$ Max'}
                            disabled={!isHourlyValue ? true : false}
                            fontSize={Constant.FONT_SIZE_WEB}
                            width="15%"
                            error={error.hourlyRateMax || false}
                            text={hourlyRateMaxValue}
                            newProps={{
                                inputProps: {
                                    maxLength: 13,
                                    step: "1"
                                }
                            }}
                            onChangeCallback={(event) => {
                                this.props.hourlyRateMax(handleDecimalsOnValue(event));
                            }}
                        />
                    </div>
                </div>
                <Spacer spaceType={VERTICAL_EXTRA} />
                <Text
                    fontSize={Constant.FONT_SIZE_WEB}
                    color={Constant.COLOR_BLACK_500}
                    text={`Fee amount-Fixed Fee Jobs: (${this.props.jobPostLocation === 'Within my Country'
                        ? localStorage.getItem('localCurrency')
                        : 'USD'
                        })`}
                />
                <Spacer spaceType={VERTICAL_SMALL} />
                <InputText
                    id="feeAmount"
                    label={feeAmountValue === '' ? '$ 0.00' : 'Fee amount'}
                    disabled={isHourlyValue ? true : false}
                    text={feeAmountValue}
                    newProps={{
                        inputProps: {
                            maxLength: 13,
                            step: "1"
                        }
                    }}
                    onChangeCallback={(event) => this.props.feeAmount(handleDecimalsOnValue(event))}
                    fontSize={Constant.FONT_SIZE_WEB}
                    width="40%"
                    error={error.feeAmount || false}
                />
                <Spacer spaceType={VERTICAL_EXTRA} />
                <Text
                    fontSize={Constant.FONT_SIZE_WEB}
                    color={Constant.COLOR_BLACK_500}
                    text="Payment Method"
                />
                <Spacer spaceType={VERTICAL_SMALL} />
                <ToggleButton
                    leftName={'Empolink'}
                    rightName={'Directly'}
                    defaultSelectedValue={paymentMethodValue === 'Empolink' ? 1 : 2}
                    onSelectedValueCallback={(value) => {
                        this.props.paymentMethod(value);
                    }}
                    disabled={this.props.jobPostLocation === 'Internationally'}
                />
                <Spacer spaceType={VERTICAL_SMALL} />
                <ToggleButton
                    leftName={'Weekly'}
                    rightName={'Bi-Weekly'}
                    defaultSelectedValue={paymentTimeValue === 'Weekly' ? 1 : 2}
                    onSelectedValueCallback={(value) => {
                        this.props.paymentTime(value);
                    }}
                    disabled={!isHourlyValue}
                    isGreyColor={!isHourlyValue}
                />
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        isHourlyValue: state.formReducer.isHourly,
        feeTypeValue: state.formReducer.feeType,
        hourlyRateMinValue: state.formReducer.hourlyRateMin,
        hourlyRateMaxValue: state.formReducer.hourlyRateMax,
        feeAmountValue: state.formReducer.feeAmount,
        paymentMethodValue: state.formReducer.paymentMethod,
        paymentTimeValue: state.formReducer.paymentTime,
        error: state.formReducer.error || {},
        jobPostLocation: state.formReducer.jobPostLocation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        feeType: (isHourly, value) =>
            dispatch({ type: IS_HOURLY, isHourly: isHourly, feeType: value }),
        hourlyRateMin: (value) => dispatch({ type: HOURLY_RATE_MIN, hourlyRate: value }),
        hourlyRateMax: (value) => dispatch({ type: HOURLY_RATE_MAX, hourlyRate: value }),
        feeAmount: (value) => dispatch({ type: FEE_AMOUNT, feeAmount: value }),
        paymentMethod: (value) => dispatch({ type: PAYMENT_METHOD, paymentMethod: value }),
        paymentTime: (value) => dispatch({ type: PAYMENT_TIME, paymentTime: value }),
        refreshFormData: () => dispatch({ type: REFRESH_DATE_FORM_DATA }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeePayment);
