import React from 'react';
import { useState, useEffect } from 'react';
import moment from 'moment';
import ContainedButtons from '../../Components/Button/Button';
import InvoicesPDFView from './InvoicesPDFView';
import { savePDF } from '@progress/kendo-react-pdf';
import { APIHelper } from '../../utils/ApiHelper';
import EmpolinkLogo from '../../Assets/images/login-logo1.png'
import API from '../../utils/Urls';

const ContractInvoice = (props) => {

    const { employerProfile, contractInfo, jobData, milestone, employer } = props;

    const pdfExportComponent = React.useRef(null);
    const [pdfExport, setPdfExport] = React.useState(false);
    const [address, setAddress] = useState({})
    const [chargingData, setChargingData] = useState();
    const invoiceDate = moment(chargingData?.charged_on_by_stripe).utc(0).format('MMM DD,YYYY');
    const milestoneDate = moment(milestone?.dateSchedule).format("ll");
    const workPeriod = chargingData?.description.split(", ")[1]

    const handlePDFSave = (event) => {
        setPdfExport(true);
        setTimeout(() => {
            savePDF(pdfExportComponent.current, { paperSize: "A4", fileName: "Invoices.pdf" });
            setPdfExport(false);
        }, 1000);
    }
    useEffect(() => {
        if ((props.userType !== undefined && props.userType !== null && props.userType !== '' && contractInfo !== undefined && contractInfo !== null && contractInfo !== '')) {
            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };
            if (props.userType === "EMPLOYER") {
                APIHelper.getMethod(`${API.GET_JOB_ONE}${props.contractInfo.jobDetail?._id}`, headers)
                    .then((response) => {
                        setAddress(response.employer.address[0]);
                    })
                    .catch((err) => {
                    });
            }
            else {
                APIHelper.getMethod(`${API.GET_EMPLOYEE_BY_ID}${localStorage.getItem('userId')}`, headers)
                    .then((response) => {
                        setAddress(response.employee.address[0]);
                    })
                    .catch((err) => {
                    });
            }

            APIHelper.getMethod(`${API.GET_CHARGING_DATA}${props.contractInfo.contractDetail._id}`, headers)
                .then((response) => {
                    if (props.type === "HOURLY") {
                        const data = response.find(o => o._id === props.contractInfo.chargeId);
                        setChargingData(data);
                    }
                    else {
                        // const index = response.map(m => m.description).indexOf(`contractNumber=${props.contractInfo.contractDetail.contractNumber}, ${props.milestone.description}`);
                        const index = response.map(m => m._id).indexOf(props.contractInfo.chargeId);
                        setChargingData(response[index]);
                    }
                })
                .catch((err) => {
                });
        }

    }, [contractInfo, props.userType]);

    return (
        <>
            <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                <div className="empoHeaderHeading">
                    <img src={EmpolinkLogo} alt="Empolink" />
                </div>
                <div style={{ marginTop: 60, color: "#1c1c1c", display: 'flex', borderBottom: "2px solid #9a9a9a", paddingBottom: 20 }}>
                    <div style={{ width: '60%' }}>
                        {props.userType !== "EMPLOYEE" ?
                            <>
                                <p style={{ fontSize: 28, marginBottom: 15 }}>{employerProfile.company?.name}</p>
                                <p style={{ fontSize: 16 }}>{localStorage.getItem('fullName')}</p>
                            </> : <p style={{ fontSize: 24 }}>{localStorage.getItem('fullName')}</p>
                        }
                        {address.street_address &&
                            <p style={{ width: '387px', marginTop: 20, fontSize: 20 }}>
                                {address.street_address}, {address.unit_number}, {address.city},
                                {" "}{address.state} {address.postal_code} {address.country}
                            </p>}
                    </div>
                    <div>
                        <p style={{ fontSize: 22 }}>Invoice</p>
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <p style={{ width: 150, color: "#9a9a9a" }}>Contract No:</p>
                            <p style={{ color: '#1c1c1c' }}>{contractInfo.contractDetail.contractNumber}</p>
                        </div>
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <p style={{ width: 150, color: "#9a9a9a" }}>Invoice No:</p>
                            <p style={{ color: '#1c1c1c' }}>{chargingData?.invoice_no}</p>

                        </div>
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <p style={{ width: 150, color: "#9a9a9a" }}>Invoice Date:</p>
                            <p style={{ color: '#1c1c1c' }}>{invoiceDate}</p>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 40 }}>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <p style={{ color: '#9a9a9a', width: '40%' }}>Job Title:</p>
                        <p style={{ color: '#1c1c1c' }}>{jobData.title}</p>
                    </div>
                    {props.userType === "EMPLOYEE" ?
                        <>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Client:</p>
                                <p style={{ color: '#1c1c1c' }}>{employerProfile.company?.name}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Client Name:</p>
                                <p style={{ color: '#1c1c1c' }}>{employer.firstName + " " + employer.lastName}</p>
                            </div>
                        </>
                        :
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <p style={{ color: '#9a9a9a', width: '40%' }}>Talent Name:</p>
                            <p style={{ color: '#1c1c1c' }}>{contractInfo.employeeDetail?.firstName} {contractInfo.employeeDetail?.lastName}</p>
                        </div>
                    }
                    {props.type === "HOURLY" &&
                        <>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Work Period:</p>
                                <p style={{ color: '#1c1c1c' }}>{workPeriod}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Total Hours:</p>
                                <p style={{ color: '#1c1c1c' }}>{chargingData?.cycle_hours}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Rate:</p>
                                <p style={{ color: '#1c1c1c' }}>${parseFloat(contractInfo.contractDetail.proposeHourlyRate).toFixed(2)}</p>
                            </div>
                        </>
                    }
                    {props.type === "FIXED" &&
                        <>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Milestone Name:</p>
                                <p style={{ color: '#1c1c1c' }}>{milestone.description}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 20 }}>
                                <p style={{ color: '#9a9a9a', width: '40%' }}>Milestone Date:</p>
                                <p style={{ color: '#1c1c1c' }}>{milestoneDate}</p>
                            </div>
                        </>
                    }

                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <p style={{ color: '#9a9a9a', width: '40%' }}>Client Payment Method:</p>
                        <p style={{ color: '#1c1c1c' }}>{contractInfo.jobDetail?.paymentMethod === "DIRECT" ? "Directly by Client" : "Through Empolink"}</p>
                    </div>
                    {props.type === "HOURLY" &&
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <p style={{ color: '#9a9a9a', width: '40%' }}>Cycle Value:</p>
                            <p style={{ color: '#1c1c1c' }}>${parseFloat(chargingData?.BaseFee).toFixed(2)}</p>
                        </div>
                    }

                    {props.type === "FIXED" &&
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <p style={{ color: '#9a9a9a', width: '40%' }}>Milestone Amount:</p>
                            <p style={{ color: '#1c1c1c' }}>${parseFloat(milestone.amount).toFixed(2)}</p>
                        </div>
                    }

                    <div style={{ display: 'flex', marginTop: 20, borderBottom: "2px solid #9a9a9a", paddingBottom: 65 }}>
                        <p style={{ color: '#9a9a9a', width: '40%' }}>Empolink Service Fee:</p>

                        {props.type === "HOURLY" &&
                            <p style={{ color: '#1c1c1c' }}>
                                ${props.userType === "EMPLOYER" ? (parseFloat(chargingData?.EmployerServiceFee).toFixed(2) || 0) : (parseFloat(chargingData?.EmployeeServiceFee).toFixed(2) || 0)}
                            </p>
                        }
                        {props.type === "FIXED" &&
                            <p style={{ color: '#1c1c1c' }}>
                                ${props.userType === "EMPLOYER" ? (parseFloat(chargingData?.EmployerServiceFee).toFixed(2) || 0) : (parseFloat(chargingData?.EmployeeServiceFee).toFixed(2) || 0)}
                            </p>
                        }
                    </div>

                    {props.userType === "EMPLOYER" &&
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <p style={{ color: '#9a9a9a', width: '40%' }}>Total amount charged to your account:</p>
                            {props.type === "HOURLY" &&
                                <p style={{ color: '#1c1c1c' }}>
                                    ${contractInfo.jobDetail?.paymentMethod === "DIRECT" ?
                                        (parseFloat(chargingData?.EmployerServiceFee).toFixed(2) || 0) :
                                        (parseFloat(chargingData?.BaseFee + chargingData?.EmployerServiceFee).toFixed(2) || 0)}
                                </p>
                            }
                            {props.type === "FIXED" &&
                                <p style={{ color: '#1c1c1c' }}>
                                    ${contractInfo.jobDetail?.paymentMethod === "DIRECT" ?
                                        (parseFloat(chargingData?.EmployerServiceFee).toFixed(2) || 0) :
                                        (parseFloat(chargingData?.BaseFee + chargingData?.EmployerServiceFee).toFixed(2) || 0)}
                                </p>
                            }
                        </div>
                    }

                    {props.userType === "EMPLOYEE" &&
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <p style={{ color: '#9a9a9a', width: '40%' }}>{contractInfo.jobDetail?.paymentMethod === "DIRECT" ? (`Total amount charged to your account:`) : (`Total amount payable to your account:`)}</p>
                            {props.type === "HOURLY" &&
                                <p style={{ color: '#1c1c1c' }}>
                                    ${contractInfo.jobDetail?.paymentMethod === "DIRECT" ?
                                        (parseFloat(chargingData?.EmployeeServiceFee).toFixed(2) || 0) :
                                        (parseFloat(chargingData?.BaseFee - chargingData?.EmployeeServiceFee).toFixed(2) || 0)}
                                </p>
                            }
                            {props.type === "FIXED" &&

                                <p style={{ color: '#1c1c1c' }}>
                                    ${contractInfo.jobDetail?.paymentMethod === "DIRECT" ?
                                        (parseFloat(chargingData?.EmployeeServiceFee).toFixed(2) || 0) :
                                        (parseFloat(chargingData?.BaseFee - chargingData?.EmployeeServiceFee).toFixed(2) || 0)}
                                </p>
                            }
                        </div>
                    }
                </div>
            </div>
            <div ref={pdfExportComponent}>
                {pdfExport ? <InvoicesPDFView {...props} /> : null}
            </div>
            <div style={{ paddingLeft: 100, paddingBottom: 50 }}>
                {props.userType === "EMPLOYEE" ?
                    <ContainedButtons
                        disabled={true}
                        text="Save PDF"
                        // color={['#a4c772', '#4e8400']}
                        color={['#D3D3D3', '#D3D3D3']}
                        height={48}
                        width={"12vw"}
                        onClick={(e) => {
                            handlePDFSave(e);
                        }}
                    /> :
                    <ContainedButtons
                        disabled={true}
                        text="Save PDF"
                        // color={['#2493F1', '#15568D']}
                        color={['#D3D3D3', '#D3D3D3']}
                        height={48}
                        width={"12vw"}
                        onClick={(e) => {
                            handlePDFSave(e);
                        }}
                    />
                }
            </div>
        </>
    );
};

export default ContractInvoice;
