import React, { Component } from 'react'
import Select from 'react-select'
import { APIHelper } from "../../utils/ApiHelper";
import API from '../../utils/Urls'
import CreatableSelect from 'react-select/creatable';


export default class AutoCompleteCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectOptions: [],
            id: "",
            name: '',
            isLoading: false,
            isAPICalled: false,
        }
    }

    handleChange(e) {
        if (e.length > 2 && !this.state.isAPICalled) {
            this.setState({
                isAPICalled: true,
                isLoading: true
            })

            APIHelper.getMethod(
                API.TALENT_SEARCH_BY_SEARCH_TERM + e
            ).then((response) => {
                console.log(response);

                if (response["status"] === 200) {
                    this.setState({
                        isAPICalled: false,
                        isLoading: false
                    })
                }
                if (response["status"] === 0) {
                    this.setState({
                        isAPICalled: false,
                        isLoading: false
                    })
                }
            }).catch((err) => {
                this.setState({
                    isAPICalled: false,
                    isLoading: false
                })
            })
        }
    }

    render() {
        var advancedStyle = {};
        if (this.props.isSearchJob) {
            advancedStyle = {
                placeholder: (base) => ({
                    ...base,
                    top: "66%"
                }),
                singleValue: (base) => ({
                    ...base,
                    color: "#707070",
                    fontSize: 14
                }),
                control: base => ({
                    ...base,
                    border: 0,
                    boxShadow: "none",
                    backgroundColor: (this.props.active || this.props.isEmployee) ? "#ffffff" : "#f7f7f7", //TODO
                    borderRadius: 14,
                }),
                option: (provided, state) => ({
                    ...provided,
                    borderBottom: '1px solid #EBEBEB',
                    color: state.isSelected ? '#FFFFFF' : '#9A9A9A',
                    backgroundColor: state.isSelected ? '#1876D2' : '#FFF',
                    borderRadius: state.isSelected ? 10 : 0,
                    margin: 1,
                    "&:hover": {
                        backgroundColor: "#7CB2E7",
                        borderRadius: 10,
                        color: '#FFFFFF'
                    }
                }),
                menuList: (base) => ({
                    ...base,
                    borderRadius: 14,
                    padding: 6,
                }),
                menu: provided => ({ ...provided, zIndex: 9999, borderRadius: 14 }),
            }
        }

        const style = {
            control: base => ({
                ...base,
                border: 0,
                boxShadow: "none",
            }),
            menu: provided => ({ ...provided, zIndex: 9999 }),
            ...advancedStyle
        }

        const { menuIsOpen, onMenuClose, onMenuOpen, autoFocus, options, onInputChange, placeholder, onChange, value, onFocus, inputValue, loading, disabled } = this.props;

        return (
            <div>
                {this.props.createSelect ? (
                    <CreatableSelect
                        inputValue={inputValue}
                        menuIsOpen={menuIsOpen}
                        onMenuClose={onMenuClose}
                        onMenuOpen={onMenuOpen}
                        autoFocus={autoFocus}
                        options={options}
                        onInputChange={onInputChange}
                        placeholder={<div style={{ fontSize: "0.9375vw", marginTop: "-0.5vw" }}>{placeholder}</div>}
                        isSearchable
                        styles={style}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        // menuPosition={'fixed'}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        onFocus={onFocus}
                        onSelectResetsInput={false}
                        isLoading={loading}
                        isDisabled={disabled}
                    />
                ) : (
                    <Select
                        inputValue={inputValue}
                        menuIsOpen={menuIsOpen}
                        onMenuClose={onMenuClose}
                        onMenuOpen={onMenuOpen}
                        autoFocus={autoFocus}
                        options={options}
                        onInputChange={onInputChange}
                        placeholder={<div style={{ fontSize: "0.9375vw", marginTop: "-0.5vw" }}>{placeholder}</div>}
                        isSearchable
                        styles={style}
                        onChange={onChange}
                        value={value}
                        menuPortalTarget={document.body}
                        // menuPosition={'fixed'}
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                        onFocus={onFocus}
                        onSelectResetsInput={false}
                        isLoading={loading}
                        isDisabled={disabled}
                    />
                )}
            </div >
        )
    }
}

