import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import DatePicker from '../../../components/centralize_date_picker';
import RangeDatePicker from '../../../../JobPostPages/components/centralize_range_date_picker';
import TimePicker from '../../../components/centralize_time_picker';
import RadioGroup from '../../../components/custom_radio_group';
import Button from '../../../components/centralize_button';
import DataTable from '../../../../JobPostPages/components/centralize_data_table';
import Swal from 'sweetalert2'
import Constant from '../../../Constant/constant'
import { ADD_WSCHEDULE_HLIST, UPDATE_WSCHEDULE_HLIST, DATE_REFRESH, JOB_POST_CLOSE_DATE } from '../../../../../action/form_action';
import {
  VERTICAL_EXTRA,
  VERTICAL_SMALL, 
   HORIZONTAL_ULTRA_SMALL,
} from '../../../../../utils/enums';

class WorkScheduleHourly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshDate: false,
      dates: [],
      startTime: "",
      endTime: "",
      nPayableTime: "0.5",
      showAlert: false
    }
  }

  addToList = (dates, startTime, endTime, nPayableTime) => {
    if (dates.length !== 0) {
      this.props.addToWScheduleList(dates, startTime, endTime, nPayableTime);
    }
  }

  resetValue = () => {
    this.setState({
      dates: [],
      startTime: "",
      endTime: "",
      errors: {},
      nPayableTime: "0.5",
    })
  };

  render() {
    const { workScheduleHourlyList, refreshData, jobPostCloseDateFormat, error } = this.props;

    return (
      <div >
        <Text fontSize={Constant.FONT_SIZE_MOBILE} color={Constant.COLOR_BLACK_500} text="Provide Schedule of Work" />
        <Spacer spaceType={VERTICAL_SMALL} />
        <div className="Row-Parent-Start">
          <RangeDatePicker
            startDate={new Date()}
            key={refreshData}
            onChangeCallback={dates => {
              this.setState({
                dates,
              });
            }}
            refreshCallback={() => {
              this.props.refreshDate();
            }}
            minDate={new Date(moment().add(3, "d").toLocaleString())}
            maxDate= {new Date(moment().add(3, "months").toISOString())}
          />
          <Spacer spaceType={HORIZONTAL_ULTRA_SMALL} />
          <div>
            <TimePicker id={"startTime"} label={"Start Time"}
              fontSize={Constant.FONT_SIZE_MOBILE}
              error={this.state.error && this.state.error.startTime || false}
              fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_MOBILE}
              fontSizeButton={Constant.BUTTON_FONT_SIZE_MOBILE}
              widthParent={"100%"}
              width={"100%"}
              refreshData={refreshData}
              onChangeCallback={time => {
                this.setState({
                  startTime: time,
                });
              }} />
            <Spacer spaceType={VERTICAL_EXTRA} />
            <TimePicker id={"endTime"} label={"End Time"}
              error={this.state.error && this.state.error.endTime || false}
              fontSize={Constant.FONT_SIZE_MOBILE}
              fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_MOBILE}
              fontSizeButton={Constant.BUTTON_FONT_SIZE_MOBILE}
              widthParent={"100%"}
              width={"100%"}
              refreshData={refreshData}
              onChangeCallback={time => {
                this.setState({
                  endTime: time,
                });
              }} />
            <Spacer spaceType={VERTICAL_EXTRA} />
            <Text fontSize={Constant.FONT_SIZE_MOBILE} color={Constant.COLOR_BLACK_500} text="Non-Payable Time 2" />
            <Spacer spaceType={VERTICAL_SMALL} />
            <RadioGroup
              fontSize={Constant.FONT_SIZE_MOBILE}
              color={Constant.COLOR_BLACK_300}
              cursor={"pointer"}
              lable1={"1/2 hours"}
              id1={"0.5"}
              lable2={"1 hour"}
              id2={"1"}
              lable3={"None"}
              id3={"0"}
              onChangeCallback={value => {
                this.setState({
                  nPayableTime: value,
                });
              }} />
            <Spacer spaceType={VERTICAL_SMALL} />
            <Button lable={"Add to scheduled"}
              fontSize={Constant.BUTTON_FONT_SIZE_MOBILE}
              onClickCallback={value => {
                if (this.state.dates.length > 0 && this.state.startTime !== "" && this.state.endTime !== "") {
                  this.addToList(this.state.dates, this.state.startTime, this.state.endTime, this.state.nPayableTime);
                  this.resetValue();
                } else {
                    if(this.state.dates.length === 0){ this.setState(prevState => ({...prevState, error: { ...this.state.error, dates:true  } }))}
                    else if (this.state.startTime === ""){ this.setState(prevState => ({...prevState, error: { ...this.state.error, startTime:true  }})) }
                    else if (this.state.endTime === ""){ this.setState(prevState => ({...prevState, error: { ...this.state.error, endTime:true  }})) }
                    else { this.setState(prevState => ({...prevState, error: { ...this.state.error, endTime:true  }})) }
                }
              }} />
          </div>
        </div>
        <Spacer spaceType={VERTICAL_EXTRA} />
        <DataTable
          width={"92%"}
          height={"420px"}
          fontSize={Constant.FONT_SIZE_MOBILE}
          color={Constant.COLOR_BLACK_500}
          fontSizeTotal={Constant.FONT_SIZE_MOBILE}
          colorTotal={Constant.COLOR_BLACK_500}
          borderTotal={"1px solid  #B6B7B6"}
          paddingTotal={"10px 15px 10px 15px"}
          borderRadiusTotal={"10px"}
          rowsData={workScheduleHourlyList}
          updateListCallback={(list) => {
            this.props.updateWScheduleList(list);
          }}
        />
        <Spacer spaceType={VERTICAL_EXTRA} />
        <Text fontSize={Constant.FONT_SIZE_MOBILE} color={Constant.COLOR_BLACK_500} text="Specify when job will expire: " />
        <Spacer spaceType={VERTICAL_SMALL} />
        <DatePicker
          fontSize={Constant.FONT_SIZE_MOBILE}
          width="60%"
          error={error.jobPostCloseDateFormat || false}
          startDate={new Date()}
          lable={jobPostCloseDateFormat === undefined ? "Select Job Closing Date" : "Job Closing Date"}
          selectedDate={jobPostCloseDateFormat}
          onChangeCallback={date => {
            this.props.jobPostCloseDate(date);
          }}
        />
      </div>
    );
  }
}


const mapStateToProps = function (state) {
  return {
    refreshData: state.formReducer.refreshData,
    selectionValue: state.formReducer.selectionValue,
    workScheduleHourlyList: state.formReducer.workScheduleHourlyList,
    jobPostCloseDateFormat: state.formReducer.jobPostCloseDateFormat,
    error: state.formReducer.error || {},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToWScheduleList: (dates, startTime, endTime, nPayableTime) => dispatch({
      type: ADD_WSCHEDULE_HLIST, dates: dates, startTime: startTime,
      endTime: endTime, nPayableTime: nPayableTime
    }),
    updateWScheduleList: (list) => dispatch({ type: UPDATE_WSCHEDULE_HLIST, workScheduleHourlyList: list }),
    refreshDate: () => dispatch({ type: DATE_REFRESH }),
    jobPostCloseDate: (value) => dispatch({ type: JOB_POST_CLOSE_DATE, jobPostCloseDate: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkScheduleHourly);