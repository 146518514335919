import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './FooterPublic.scss';
//import '../../../Assets/dev-style.scss';
//import EmpolinkLogo from '../../../Assets/images/logo.svg';
import EmpolinkLogo from '../../Assets/images/logo.svg';

class FooterPublic extends React.Component {
    render() {
        return (
            <>
                 <footer className="footer-public">
                    <div class="top-footer">
                        <div class="container">
                            <div class="footer-wrapper">
                                <div class="fst-col">
                                    <div class="footer-logo">
                                        <a href="/">
                                            <img src={EmpolinkLogo} alt="Empolink" />
                                        </a>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="https://www.linkedin.com/company/empolink" target="_blank">
                                                <i class="fa-brands fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/" target="_blank">
                                                <i class="fa-brands fa-x-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/" target="_blank">
                                                <i class="fa-brands fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/" target="_blank">
                                                <i class="fa-brands fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div></div>
                                {/*<div class="quick-link">
                                    <h3>About</h3>
                                    <ul>
                                        <li>
                                            <div className='link' onClick={() => this.props.history.push("/help")}>About Us</div>
                                        </li>
                                        <li>
                                            <div className='link' onClick={() => this.props.history.push("/help")}>How it works</div>
                                        </li>
                                        <li>
                                            <div className='link' onClick={() => this.props.history.push("/help")}>Contact Us</div>
                                        </li>
                                    </ul>
                                </div>*/}
                                <div class="quick-link">
                                    <h3>Support</h3>
                                    <ul>
                                        <li>
                                            <div className='link' onClick={() => window.open("https://forum.empolink.com", "_blank")}>Forum</div>
                                        </li>
                                        <li>
                                            <div className='link' onClick={() => this.props.history.push("/help")}>Help & Support</div>
                                        </li>
                                        <li>
                                            <div className='link' onClick={() => this.props.history.push("/Pricing")}>Fees</div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="quick-link">
                                    <h3>Legal</h3>
                                    <ul>
                                        <li>
                                            <a target="_blank" href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Privacy+Policy.pdf">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Terms+of+Service.pdf">Terms & Conditions</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copyright">
                        <div class="container">© 2024 Empowering Link Technologies Inc.</div>
                    </div>
                </footer>
            </>
        );
    }
}

export default withRouter(FooterPublic);
