import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { FILE_EXTENSION } from './enums';
import JpegIcon from '../Assets/icons/jpeg.png';
import PdfIcon from '../Assets/icons/pdf.png';
import DocIcon from '../Assets/icons/doc.png';
import XlsIcon from '../Assets/icons/xls.png';
import CsvIcon from '../Assets/icons/csv.png';
import ZipIcon from '../Assets/icons/zip.png';
import FileIcon from '../Assets/icons/file.png';
import PngIcon from '../Assets/icons/png.png';

export const getTimeDifferent = (startTime, endTime, nonpaySecond) => {
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var hours = parseInt(duration.asHours());

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;


    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    console.log('nonpaySecond', nonpaySecond);
    console.log('hours', hours);
    console.log('minutes', minutes);
    const seconds = ((hours * 60 * 60) + (minutes * 60)) - (nonpaySecond * 60 * 60);

    var divisor_for_minutes = seconds % (60 * 60);
    var min = Math.floor(divisor_for_minutes / 60);

    return `${Math.floor(seconds / 3600)}:${min > 9 ? min : `0${min}`}`;
    // return hours + ':' + minutes;
}

export const getTotalWorkingHours = (times, nonpaySecond) => {
    var hours = 0;
    var mintues = 0;

    times.forEach((time) => {
        const data = time.split(":");
        if (data.length === 2) {
            hours += parseInt(data[0]);
            mintues += parseInt(data[1]);
        }
    });

    const seconds = ((hours * 60 * 60) + (mintues * 60));

    var divisor_for_minutes = seconds % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    return `${Math.floor(seconds / 3600)}:${minutes > 9 ? minutes : `0${minutes}`}`;
}

export const timeToDecimal = (t) => {
    if (t.length > 1) {
        var arr = t.split(':');
        var dec = parseInt((arr[1] / 6) * 10, 10);

        return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
    }
}

export const intervalTime = (str) => {
    if (str === "1 hour") {
        return 1
    }
    if (str === "1/2 hour") {
        return 0.5
    }
    if (str === "none") {
        return 0
    }
}



export const getFileName = (filename) => {
    const empolinkId = localStorage.getItem('userId');

    const ext = /^.+\.([^.]+)$/.exec(filename);
    const fileExtension = (ext === null) ? "" : ext[1];
    const fileNameGenerate = `${empolinkId}-${uuidv4()}.${fileExtension}`;
    return fileNameGenerate;
}

export const getDates = (startDate, endDate) => {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }
    while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays.call(currentDate, 1)
    }
    return dates
}
export const getFileExtension = (fileName) => {
    const ext = /^.+\.([^.]+)$/.exec(fileName);
    const fileExtension = (ext === null) ? "" : ext[1];

    if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
        return 'image';
    } else if (fileExtension === 'pdf') {
        return 'pdf';
    } else if (fileExtension === 'xls') {
        return 'Excel';
    }
    return fileExtension;
}

export const convertFrom24To12Format = (hour, min) => {
    var time_part_array = hour;
    var ampm = 'AM';

    if (time_part_array >= 12) {
        ampm = 'PM';
    }

    if (time_part_array > 12) {
        time_part_array = time_part_array - 12;
    }
    var mintues = min > 9 ? min : `0${min}`;
    var formatted_time = time_part_array + ':' + mintues;
    return `${formatted_time} ${ampm}`;
}

export const getAttachmentIcon = (fileName) => {
    const extension = /^.+\.([^.]+)$/.exec(fileName);
    const fileExtension = (extension === null) ? "" : extension[1];

    const ext = fileExtension ? fileExtension.toLowerCase() : '';
    if (ext === FILE_EXTENSION.JPEG || ext === FILE_EXTENSION.JPG) {
        return JpegIcon;
    } else if (ext === FILE_EXTENSION.PNG) {
        return PngIcon;
    } else if (ext === FILE_EXTENSION.PDF) {
        return PdfIcon;
    } else if (ext === FILE_EXTENSION.DOC || ext === FILE_EXTENSION.DOCX) {
        return DocIcon;
    } else if (ext === FILE_EXTENSION.XLS || ext === FILE_EXTENSION.XLSX) {
        return XlsIcon;
    } else if (ext === FILE_EXTENSION.CSV) {
        return CsvIcon;
    } else if (ext === FILE_EXTENSION.ZIP) {
        return ZipIcon;
    } else {
        return FileIcon;
    }
}

export const JobpostAndInvitationExpiry = (time) => {
    const expiryDateAndTime = moment(time).format('DD-MM-YYYY');
    const finalDate = moment(expiryDateAndTime, 'DD-MM-YYYY');
    var nowtime = moment();
    var duration = moment.duration(finalDate.diff(nowtime));
    var seconds = duration.asSeconds();

    var divisor_for_minutes = seconds % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    return { timeRemaing: `${Math.floor(seconds / 3600)}:${minutes > 9 ? minutes : `0${minutes}`}`, hours: Math.floor(duration.asHours()) };
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate);
    var lastDate = moment(endDate);

    dates.push(startDate);
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        console.log(currDate.toDate());
        dates.push(currDate.clone().toDate());
    }
    dates.push(endDate);

    return dates;
};

export const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length
}
  
export const deepCopy = (value) => JSON.parse(JSON.stringify(value));
//function for first letter capitalization
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
