import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import moment from "moment";
import ReactS3 from "react-s3";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import Swal from "sweetalert2";

import { Chip, IconButton } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import queryString from "query-string";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import CheckboxComponent from "../../Components/CheckboxComponent/CheckboxComponent";
import CustomChip from "../../Components/Chip/Chip";
import MultiTagSelect from "../../Components/AutoComplete/MultiTagSelect";
import { Log } from "../../utils/logger";
import {
  identificationLevel,
  notificationSettings,
} from "./EmployeeAccountDetails.constant";
import EditDetailsComponent from "../../Components/EditDetailsComponent/EditDetailsComponent";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import Textfield from "../../Components/TextField/Textfield";
import Button from "../../Components/Button/Button";
import "./EmployeeAccountDetails.scss";
import CONSTANTS from "../../utils/constants";
import API from "../../utils/Urls";
import { APIHelper } from "../../utils/ApiHelper";
import { getFieldOfWork } from "../../api/field_of_work";
import { getSpecialityList } from "../../api/speciality_list";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { config } from "../../Components/FileUpload/FileUpload";
import DrawerComponent from "../../Components/Drawer/Drawer";
import EmployeeDetail from "./EmployeeDetail";
import { cardType, S3_BUCKET_TYPE } from "../../utils/enums";
import {
  getFileExtension,
  getFileName,
  getAttachmentIcon,
} from "../../utils/commonFunction";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import MonthYearPicker from "../../Components/MonthPicker/MonthPicker";
import ContainedButtons from "../../Components/Button/Button";
import StripeConnect from "../../Components/StripeConnect/StripeConnect";
import BankingModule from "../../Components/StripeConnect/BankingModule";
import PaymentModule from "../../Components/StripeConnect/PaymentModule";
import AWS from 'aws-sdk'

import axios from 'axios';
import InstructionModal from "../../Components/InstructionModal/InstructionModal";  //This is for Instruction Pop-ups

const selectedHeadingstyles = {
  marginTop: "2.16vw",
  fontSize: "1.25vw",
  fontWeight: "600",
  letterSpacing: "0.96px",
  color: "#1c1c1c",
  backgroundColor: "rgba(164,199,114,0.64)",
};

const error = {
  border: "1px solid red",
  borderRadius: "4px",
};

AWS.config.update({
  accessKeyId: CONSTANTS.ACCESS_KEY_ID,
  secretAccessKey: CONSTANTS.SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
  params: { Bucket: CONSTANTS.BUCKET_NAME },
  region: CONSTANTS.REGION,
})

const DirName = process.env.NODE_ENV == "production" ? "Prod" : "Stage";

class EmployeeAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.upload = React.createRef();
    this.uploadDoc = React.createRef();
    let tempPortfolios = [];
    for (let index = 0; index < 6; index++) {
      tempPortfolios.push({
        file_name: "",
        file_name_original: "",
        file_url: "",
        file_type: "",
      });
    }
    this.state = {
      page: 0,
      paymentPopup: false,
      employeeID: "",
      preferredName: "",
      otpError: false,
      otp: "",
      firstName: "",
      lastName: "",
      address: {
        city: "",
        country: "",
        state: "",
        unit_number: "",
        postal_code: "",
        street_address: "",
      },
      mobileNumber: "",
      selectedLanguages: [],
      rate: 0,
      internationalRate: 0,
      internationalRateError: false,
      isWorkInternationally: false,
      isLanguageLoading: false,
      isInternationEditable: false,
      isAddressLoading: false,
      isRateEditable: false,
      isHighlightEditable: false,
      isCompanyEditable: false,
      isEducationEditable: false,
      isCertificateEditable: false,
      languageOptions: [],
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      selectedCountry: {},
      selectedState: {},
      selectedCity: {},
      pinCode: "",
      streetAddress: "",
      emailAddress: "",
      password: "",
      highlight: "",
      isEmailAddressEditable: false,
      isPasswordEditable: false,
      isFieldOfWorkEditable: false,
      isYearOfExperienceEditable: false,
      fieldOfWork: {},
      specialityDefaultValueDropDown: null,
      specialitiesList: [],
      drivingLicenceNumber: "",
      identificationType: "",
      document: "",
      notificationsArr: [],
      linkedIn: "",
      oldlinkedIn: "",
      companyIndex: -1,
      educationIndex: -1,
      certificateIndex: -1,
      companyName: "",
      position: "",
      from: "",
      to: "",
      highlights: "",
      employeementHistory: [],
      educationHistory: [],
      certificates: [],
      yearOfExperience: 0,
      specialitiesOptions: [],
      speciality: {},
      selectedSkillOptions: [],
      extraSkills: [],
      skillOptions: [],
      topSkillOptions: [],
      topSkillNum: 6,
      skillSet: [],
      isSkillError: false,
      schoolName: "",
      major: "",
      degree: "",
      fromEducation: "",
      toEducation: "",
      isLinkedInEditable: "",
      photo: "",
      drawerOpen: false,
      isAccountInfoEditable: false,
      key: 0,
      firstNameOfLetter: false,
      cardTitle: "",
      cardNumber: 0,
      portfolios: tempPortfolios,
      isEmailExist: false,
      documentFileName: "",
      currentWorking: false,
      isMobileNumberConfirmed: false,
      editYearOfExperience: false,
      newPassword: "",
      confirmPassword: "",
      currentPassword: "",
      isValidCurrentPassword: true,
      isValidnewPassword: true,
      isValidConfirmPassword: true,
      error: {},
      showDeleteConfirmationModel: false,
      confirmDelete: false,
      employmentDeleteIndex: null,
      certificationsDeleteIndex: null,
      educationDeleteIndex: null,
      atLeastSixCharPassword: true,
      setStripeConnetcID: "",
      stripeCustomerID: "",
      cardExipred: false,
      stripeStatus: "",
      newNotifications: [],
      refreshStatus: false,
      refreshStatusPopUp: false,
    };
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    if (params && Object.keys(params).length > 0 && params["payment"]) {
      this.setState({
        page: 5,
      });
    }
    this.getEmployeeDetails();
    this.props.getFieldOfWork();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getPreviousData();
    }
  }

  getPreviousData = () => {
    this.getEmployeeDetails();
    this.getEmployeePortfolio();
    this.getEmployeeNotification();
    this.props.getFieldOfWork();
  };

  getEmployeePortfolio = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(
      `${API.GET_EMPLOYEE_PORTFOLIO}${localStorage.getItem("userId")}`,
      headers
    ).then((response) => {
      const portfolio = [...response.employee.portfolio];
      for (let i = 0; i < 6 - response.employee.portfolio.length; i++) {
        portfolio.push({
          file_name: "",
          file_name_original: "",
          file_url: "",
          file_type: "",
        });
      }

      this.setState({
        portfolios: portfolio,
      });

    });
  };

  getEmployeeNotification = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(API.GET_NOTIFICATION, headers).then((response) => {
      if (response.notification) {
        this.setState({
          notificationsArr: response.notification,
          user_preference: response.user_preference
        });
      }
    });
  };

  getEmployeeDetails = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.getMethod(API.GET_EMPLOYEES_ACTIVE_USER, headers).then(
      (response) => {
        const { employeeModal, employee } = response;

        const skills = [];
        if (employee.speciality && employee.speciality.length > 0) {
          employee.speciality.forEach((spec) => {
            const skillArr = employee.skills.filter(
              (skill) => skill.speciality_id === spec.id
            );
            const skilles = skillArr.map((skill) => {
              return {
                speciality_id: skill.speciality_id,
                _id: skill.skills_id,
                name: skill.name,
              };
            });
            const obj = {
              speciality: spec.name,
              id: spec.id,
              skill: skilles,
            };
            skills.push(obj);
          });
        }

        this.setState({
          employeeID: employeeModal._id,
          firstName: employeeModal.firstName,
          lastName: employeeModal.lastName,
          address: employee.address && employee.address[0],
          mobileNumber: employeeModal.mobile_number,
          selectedLanguages: employee.languages,
          emailAddress: employeeModal.email,
          preferredName: employeeModal.preferred_name,
          firstNameOfLetter: employeeModal.is_only_show_fname,
          rate: employee.rate && employee.rate,
          internationalRate:
            employee.international_rate && employee.international_rate,
          isWorkInternationally: employee.is_international_worker,
          yearOfExperience:
            employee.work_experience && employee.work_experience,
          fieldOfWork: employee.field && {
            id: employee.field[0].id,
            value: employee.field[0].id,
            lable: employee.field[0].name,
            _id: employee.field[0]._id,
          },
          specialityDefaultValueDropDown: employee.speciality?.length > 0 && {
            lable: employee.speciality[0].name,
            value: employee.speciality[0].id,
          },
          speciality: employee.speciality[0],
          linkedIn: employee.linkedin_link,
          oldlinkedIn: employee.linkedin_link,
          photo: employee.photo,
          employeementHistory: employee.employement,
          educationHistory: employee.education,
          certificates: employee.certifications,
          skillSet: skills,
          highlight: employee.description,
          isMobileNumberConfirmed: employeeModal.isMobileNumberConfirmed,
          stripeCustomerID: employee.stripe_customer_id,
          cardExipred: employee.card_expired,
          stripeStatus: employee.stripe_status,
        });
        if (employee.field && employee.field.length > 0) {
          this.getFieldList(employee.field[0].id);
        }

        // after refreshStatus call to show message
        if (((this.state.stripeStatus !== undefined && this.state.stripeStatus !== null && this.state.stripeStatus !== '') && this.state.stripeStatus !== 'completed') && (this.state.refreshStatus && this.state.refreshStatusPopUp)) {
          this.setState({ paymentPopup: true, refreshStatus: false, refreshStatusPopUp: false });
        }
        if ((this.state.stripeStatus !== undefined && this.state.stripeStatus !== null && this.state.stripeStatus !== '') && this.state.stripeStatus !== 'completed') {
          this.setState({ refreshStatus: true });
        }

        if (((this.state.stripeStatus === undefined || this.state.stripeStatus === null || this.state.stripeStatus === '' || this.state.stripeStatus !== 'completed') || (localStorage.getItem("card_expired") === "" || localStorage.getItem("card_expired") === undefined || localStorage.getItem("card_expired") === "true")) && !this.state.refreshStatus) {
          this.setState({ paymentPopup: true });
        }

        // if ((this.state.stripeStatus !== undefined && this.state.stripeStatus !== null) && (this.state.stripeStatus === 'pending' || this.state.stripeStatus !== 'completed') && (!this.state.refreshStatusPopUp && !this.state.refreshStatus)) {
        //   this.setState({ refreshStatus: true });
        // } else if (localStorage.getItem("card_expired") === "true" && (this.state.refreshStatus && this.state.refreshStatusPopUp)) {
        //   this.setState({ paymentPopup: true, refreshStatus: false, refreshStatusPopUp: false });
        // } else if (localStorage.getItem("card_expired") === "true" && (!this.state.refreshStatus && !this.state.refreshStatusPopUp)) {
        //   this.setState({ paymentPopup: true });
        // } else if (localStorage.getItem("card_expired") === "false" && (this.state.refreshStatus && this.state.refreshStatusPopUp)) {
        //   this.setState({ paymentPopup: true, refreshStatus: false, refreshStatusPopUp: false });
        // }
      }
    );
  };

  refreshUpdate = () => {
    this.setState({ refreshStatusPopUp: true });
    this.getEmployeeDetails();
  };

  saveNotification = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const request = {
      user_preference: this.state.newNotifications,
    };

    APIHelper.putMethod(API.EDIT_NOTIFICATION, request, headers).then(
      (response) => {
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Notification setting saved successfully!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      }
    );
  };

  saveProfile = () => {
    const {
      employeeID,
      firstName,
      lastName,
      emailAddress,
      mobileNumber,
      rate,
      internationalRate,
      yearOfExperience,
      selectedLanguages,
      highlight,
      isWorkInternationally,
      photo,
      linkedIn,
      address,
      educationHistory,
      employeementHistory,
      certificates,
      fieldOfWork,
      skillSet,
      preferredName,
      firstNameOfLetter,
    } = this.state;
    // const field = [{ id: fieldOfWork.id, name: fieldOfWork.lable, _id: fieldOfWork._id }];
    var fieldWork;
    if (fieldOfWork.value && fieldOfWork._id) {
      fieldWork = [
        { id: fieldOfWork._id, name: fieldOfWork.lable, _id: fieldOfWork._id },
      ];
    } else {
      const fieldFindData = this.props.dataFieldOfWorkDropdown.filter(
        (item) => item.lable === fieldOfWork.lable
      );
      if (fieldFindData.length > 0) {
        fieldWork = [
          {
            id: fieldFindData[0]._id,
            name: fieldFindData[0].lable,
            _id: fieldFindData[0]._id,
          },
        ];
      }
    }
    const skills = [];
    const speciality = [];
    skillSet &&
      skillSet.length > 0 &&
      skillSet.forEach((spec) => {
        speciality.push({ id: spec.id, name: spec.speciality });
        spec.skill.forEach((skill) => {
          skills.push({
            speciality_id: skill.speciality_id,
            skills_id: skill._id,
            name: skill.name,
          });
        });
      });

    const request = {
      employeeModel: {
        user_type: "EMPLOYEE",
        _id: employeeID,
        firstName,
        lastName,
        email: emailAddress,
        mobile_number: mobileNumber,
        is_only_show_fname: firstNameOfLetter,
        preferred_name: preferredName,
      },
      employeeProfile: {
        rate,
        international_rate: internationalRate,
        work_experience: yearOfExperience,
        languages: selectedLanguages,
        description: highlight,
        is_international_worker: isWorkInternationally,
        photo,
        linkedin_link: linkedIn,
        address,
        education: educationHistory,
        employement: employeementHistory,
        certifications: certificates,
        userid: employeeID,
        field: fieldWork,
        skills,
        speciality,
        is_student: false,
      },
    };

    if (
      firstName !== "" &&
      lastName !== "" &&
      firstName.match("^[a-zA-Z ]*$") !== null &&
      lastName.match("^[a-zA-Z ]*$")
    ) {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      APIHelper.putMethod(API.EDIT_EMPLOYEES, request, headers).then(
        (response) => {
          // console.log("success", response);
          this.setState({ photo: response.photo })
        }
      );
      this.setState({ isAccountInfoEditable: false });
    } else {
      console.log("here coming");
      this.setState({ error: { firstName: true, lastName: true } });
    }
  };

  verifyMyID = () => {
    const payload = {
      id_verification: {
        legal_id_type: this.state.identificationType,
        legal_id: this.state.drivingLicenceNumber,
        file_url: this.state.document,
        is_id_verified: false,
      },
      user_id: localStorage.getItem("userId"),
    };

    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.putMethod(API.EDIT_IDENTITY, payload, headers)
      .then((response) => {
        if (response.ok === 1) {
          Swal.fire({
            customClass: "Alert-Class",
            title: "Success!",
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        } else {
          Swal.fire({
            customClass: "Alert-Class",
            title: "Error!",
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      });
  };

  uploadDocument = (event) => {
    const configS3 = {
      ...config,
      dirName: `${S3_BUCKET_TYPE.DOCUMENTS}/profile`,
    };
    const fileNameGenerate = getFileName(event.target.files[0].name);
    const file = event.target.files[0];

    this.setState({
      documentFileName: file.name,
    });
    Object.defineProperty(file, "name", {
      writable: true,
      value: fileNameGenerate,
    });

    ReactS3.uploadFile(file, configS3)
      .then((data) => {
        console.log(data);
        // props.setFile(data.location);
        this.setState(
          {
            document: data.location,
          },
          () => {
            this.saveProfile();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  profileIconUpdate = async (event) => {
    const file = event.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append('files', file, file.name);
    bodyFormData.append('userId', localStorage.getItem("userId"));
    bodyFormData.append('type', 'profile');
    let headersFile = {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    const response = await APIHelper.postMethod(API.EMPLOYEE_UPLOAD_FILES, bodyFormData, headersFile);
    for (var i = 0; i < response.data.length; i++) {
      this.setState({ photo: response.data[i] },
        () => {
          this.saveProfile();
        }
      );
    }
  };

  getFieldList = async (id) => {
    APIHelper.getMethod(API.GET_SPECIALITY_BY_FIELDID + `?field_id=${id}`).then(
      (response) => {
        if (response["status"] === 1) {
          const data = response.data.map((speciality) => ({
            ...speciality,
            label: speciality.name,
            value: speciality._id,
          }));
          this.setState({
            specialitiesOptions: data,
          });
        }
      }
    );
  };

  getSpecialityList = (id) => {
    APIHelper.getMethod(
      API.GET_SKILLS_BY_SPECIALITYID + `?speciality_id=${id}`
    ).then((response) => {
      if (response["status"] === 1) {
        const skillOptions = response.data.map((skill) => ({
          ...skill,
          label: skill.name,
          value: skill.name,
          skills_id: skill._id,
        }));
        const arr = skillOptions.filter((skill, id) => {
          return id < 6;
        });
        this.setState({
          skillOptions: skillOptions,
          topSkillNum: 6,
          topSkillOptions: arr,
        });
      }
    });
  };

  removeTopSkill = (skill) => {
    const { topSkillOptions, topSkillNum, skillOptions } = this.state;
    const arr = topSkillOptions.filter((currentSkill) => {
      return skill.name !== currentSkill.name;
    });
    if (topSkillNum < skillOptions.length) {
      this.setState({
        topSkillNum: topSkillNum + 1,
        topSkillOptions: [...arr, skillOptions[topSkillNum]],
      });
    } else {
      this.setState({
        topSkillOptions: [...arr],
      });
    }
  };

  removeSelectedTopSkill = (skill) => {
    const { selectedSkillOptions } = this.state;
    const arr = selectedSkillOptions.filter((currentSkill) => {
      return skill.name !== currentSkill.name;
    });
    this.setState({
      selectedSkillOptions: arr,
    });
  };

  isNotInArray = (arr, str) => {
    let i = 0;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].name === str) return "false";
    }
    return "true";
  };

  onAddition = (items) => {
    this.setState({
      extraSkills: [],
    });
    const tag = [];
    const existingSkill = [];
    items.forEach((item) => {
      if (item.skills_id === undefined || item.skills_id === null || item.skills_id === '') {
        tag.push({
          _id: "000000000000000000000000",
          speciality_id: this.state.specialityDefaultValueDropDown.value,
          field_id: this.state.speciality && this.state.speciality.field_id,
          name: item.label,
          label: item.label,
          value: item.label,
          skills_id: "000000000000000000000000",
        });
      }
      if (item.skills_id !== undefined && item.skills_id !== null && item.skills_id !== '') {
        existingSkill.push(item);
      }
    });
    this.setState({
      extraSkills: tag,
      selectedSkillOptions: [...this.state.selectedSkillOptions, ...existingSkill]
    });
  };

  alreadyPresent = (speciality) => {
    const { skillSet } = this.state;
    const arr = skillSet.filter((set) => {
      return set.speciality === speciality;
    });
    if (arr.length === 0) return false;
    else return true;
  };

  addSkillSet = () => {
    const { speciality, selectedSkillOptions, extraSkills, skillSet, specialityDefaultValueDropDown } =
      this.state;

    var arrLength = 0;

    //check speciality already exists or no
    if (this.alreadyPresent(speciality.name)) {
      // check speciality skills already exists or not
      const updateNewSkills = skillSet.map((obj, index) => {
        const tempSkills = selectedSkillOptions.find(o => o.speciality_id === obj.id);
        if (tempSkills !== undefined && tempSkills !== null && tempSkills !== '') {
          const tempListSkills = [...obj.skill, ...selectedSkillOptions, ...extraSkills];
          obj.skill = tempListSkills.filter((ele, ind) => ind === tempListSkills.findIndex(elem => elem.name === ele.name));
        } else {
          if (obj.id === specialityDefaultValueDropDown.value) {
            const tempListSkills = [...obj.skill, ...extraSkills];
            obj.skill = tempListSkills.filter((ele, ind) => ind === tempListSkills.findIndex(elem => elem.name === ele.name));
          }
        }
        return obj;
      });
      this.setState({ skillSet: updateNewSkills });
    } else {
      this.setState({
        skillSet: [
          ...skillSet,
          {
            speciality: specialityDefaultValueDropDown.name,
            speciality_id: specialityDefaultValueDropDown.value,
            id: speciality._id,
            skill: [...selectedSkillOptions, ...extraSkills],
          },
        ],
      });
    }

    // Log("Called addSkillSet", "Called addSkillSet");
    let skillCount = 0;
    skillSet.map((item, i) => {
      skillCount += item.skill.length;
    });
    skillCount += arrLength;
    // Log("skillCount ", skillCount);

    if (skillCount >= 100) {
      this.setState({
        isSkillError: true,
      });
    }
  };

  removeSkillSet = (name) => {
    const { skillSet } = this.state;
    const arr = skillSet.filter((set) => {
      return set.speciality !== name;
    });
    this.setState({
      skillSet: arr,
    });
    Log("Called addSkillSet", "Called addSkillSet");
    let skillCount = 0;
    skillSet.map((item, i) => {
      skillCount += item.skill.length;
    });

    Log("skillCount ", skillCount);

    if (skillCount >= 100) {
      this.setState({
        isSkillError: true,
      });
    } else {
      this.setState({
        isSkillError: false,
      });
    }
  };

  removeSkill = (speciality, skill) => {
    const { skillSet } = this.state;
    const arr = skillSet.map((currentSet) => {
      if (currentSet.speciality === speciality) {
        const modifiedSkills = currentSet.skill.filter((currentSkill) => {
          return skill !== currentSkill.name;
        });

        return { ...currentSet, skill: modifiedSkills };
      } else {
        return currentSet;
      }
    });

    this.setState({
      skillSet: arr,
    });

    Log("Called addSkillSet", "Called addSkillSet");
    let skillCount = 0;
    skillSet.map((item, i) => {
      skillCount += item.skill.length;
    });

    Log("skillCount ", skillCount);

    if (skillCount >= 100) {
      this.setState({
        isSkillError: true,
      });
    } else {
      this.setState({
        isSkillError: false,
      });
    }
    this.setState({
      key: Math.random(),
    });
  };

  onEditLanguages = () => {
    this.setState({ isLanguageLoading: true });
    APIHelper.getMethod(API.GET_LANGUAGES).then((response) => {
      if (response["status"] === 1) {
        let languages = response.data.map(function (row) {
          return { value: row.name, label: row.name, id: row._id };
        });
        this.setState({
          languageOptions: languages,
          isLanguageLoading: false,
          isLanguageEditable: !this.state.isLanguageEditable,
        });
      }
    });
  };

  onEditAddress = () => {
    this.setState({ isAddressLoading: true });

    APIHelper.getMethod(API.GET_COUNTRY).then((response) => {
      if (response["status"] === 1) {
        const countries = response.data.map(function (row) {
          return {
            value: row.name,
            label: row.name,
            id: row._id,
            Id: row.country_id,
          };
        });
        this.setState({
          countryOptions: countries,
          isAddressLoading: false,
          isBusinessAddressEditable: !this.state.isBusinessAddressEditable,
        });
      }
    });
  };

  onEditFieldOfWork = () => {
    this.setState((state) => ({
      isFieldOfWorkEditable: !state.isFieldOfWorkEditable,
    }));
  };

  handleCheckboxList = (checkedItems) => {
    const { dataSpecialityDropdown } = this.props;
    var intersection =
      dataSpecialityDropdown &&
      dataSpecialityDropdown.filter(function (e) {
        return checkedItems.indexOf(e.lable) > -1;
      });
    let selectedValue = intersection.map(function (row) {
      return { name: row.lable, id: row._id };
    });
    this.setState({
      specialitiesList: selectedValue,
    });
  };

  changeCountry = (id) => {
    APIHelper.getMethod(API.GET_STATES + `?country_id=${id}`).then(
      (response) => {
        if (response["status"] === 1) {
          const states = response.data.map((state) => ({
            ...state,
            label: state.name,
            value: state.name,
          }));
          this.setState({
            stateOptions: states,
          });
        }
      }
    );
  };

  changeState = (id) => {
    APIHelper.getMethod(API.GET_CITIES + `?state_id=${id}`).then((response) => {
      if (response["status"] === 1) {
        const cities = response.data.map((city) => ({
          ...city,
          label: city.name,
          value: city.name,
        }));
        this.setState({
          cityOptions: cities,
        });
      }
    });
  };

  educationDelete = (index) => {
    const { educationHistory } = this.state;

    const newArr = [...educationHistory];
    newArr.splice(index, 1);

    this.setState(
      {
        educationHistory: newArr,
      },
      () => {
        this.saveProfile();
      }
    );
  };

  certificateDelete = (index) => {
    const { certificates } = this.state;

    const newArray = [...certificates];
    newArray.splice(index, 1);

    this.setState(
      {
        certificates: newArray,
      },
      () => {
        this.saveProfile();
      }
    );
  };
  employeeHistoryDelete = (index) => {
    const { employeementHistory } = this.state;

    const newArr = [...employeementHistory];
    newArr.splice(index, 1);

    this.setState(
      {
        employeementHistory: newArr,
      },
      () => {
        this.saveProfile();
      }
    );
  };

  accountInformationDetails = () => {
    const contentField = (
      <>
        <div style={{ width: "100%", marginTop: "63px" }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">
              {CONSTANTS.FIRST_NAME_LABEL}
            </div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Textfield
              width={"100%"}
              value={this.state.firstName}
              onChange={(event) =>
                this.setState({ firstName: event.target.value })
              }
              error={
                (this.state.error?.firstName &&
                  !(this.state.firstName !== "")) ||
                !this.state.firstName.match("^[a-zA-Z ]*$")
              }
            />
          </div>
        </div>
        <div style={{ width: "100%", marginTop: 28 }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">
              {CONSTANTS.LAST_NAME_LABEL}
            </div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Textfield
              width={"100%"}
              value={this.state.lastName}
              onChange={(event) =>
                this.setState({ lastName: event.target.value })
              }
              error={
                (this.state.error?.lastName && !(this.state.lastName !== "")) ||
                !this.state.lastName.match("^[a-zA-Z ]*$")
              }
            />
          </div>
        </div>
        <div style={{ width: "100%", marginTop: 28 }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">
              {CONSTANTS.PREFERRED_NAME}
            </div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Textfield
              width={"100%"}
              value={this.state.preferredName}
              onChange={(event) =>
                this.setState({ preferredName: event.target.value })
              }
            />
          </div>
        </div>
        <div style={{ marginTop: "28px" }}>
          <CheckboxComponent
            checked={this.state.firstNameOfLetter}
            name={"firstLetterOfName"}
            label={"Only show first letter of my last name"}
            onChange={() => {
              this.setState({
                firstNameOfLetter: !this.state.firstNameOfLetter,
              });
            }}
            color="#a4c772"
          />
          <div className="EditDetailsBottomLineStyles" />
        </div>
        <div style={{ width: "100%", marginTop: 28 }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">
              {CONSTANTS.BUSSINESS_ADDRESS_LABEL}
            </div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <div className="dropdown-container">
              <label className="floating-label">Country *</label>
              <Dropdown
                placeholder={"Country *"}
                options={this.state.countryOptions}
                disabledDropdownIndicator={false}
                value={
                  this.state.address && {
                    label: this.state.address.country,
                    value: this.state.address.country,
                  }
                }
                onChange={(event) => {
                  this.changeCountry(event.Id);
                  const address = this.state.address;
                  address.country = event.value;
                  address.state = undefined;
                  address.city = undefined;
                  this.setState({ address });
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <div className="dropdown-container">
                <label className="floating-label">State/Province *</label>
                <Dropdown
                  placeholder={"State/Province *"}
                  options={this.state.stateOptions}
                  value={
                    this.state.address && {
                      label: this.state.address.state,
                      value: this.state.address.state,
                    }
                  }
                  disabledDropdownIndicator={false}
                  onChange={(event) => {
                    this.changeState(event.state_id);
                    const address = this.state.address;
                    address.state = event.value;
                    address.city = undefined;
                    this.setState({ address });
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <div className="dropdown-container">
                <label className="floating-label">City *</label>
                <Dropdown
                  placeholder={"City *"}
                  options={this.state.cityOptions}
                  disabledDropdownIndicator={false}
                  value={
                    this.state.address && {
                      label: this.state.address.city,
                      value: this.state.address.city,
                    }
                  }
                  onChange={(event) => {
                    const address = this.state.address;
                    address.city = event.value;
                    this.setState({ address });
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Textfield
                width={"100%"}
                placeholder="Street Address *"
                value={this.state.address && this.state.address.street_address}
                onChange={(event) => {
                  const address = this.state.address;
                  address.street_address = event.target.value;
                  this.setState({ address });
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Textfield
                width={"100%"}
                placeholder="Unit Number"
                value={this.state.address && this.state.address.unit_number}
                onChange={(event) => {
                  const address = this.state.address;
                  address.unit_number = event.target.value;
                  this.setState({ address });
                }}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Textfield
                width={"100%"}
                placeholder="Zip/Postal Code *"
                value={this.state.address && this.state.address.postal_code}
                onChange={(event) => {
                  const address = this.state.address;
                  address.postal_code = event.target.value;
                  this.setState({ address });
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ width: "100%", marginTop: 28 }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">
              {CONSTANTS.LANGUAGES_LABEL}
            </div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Dropdown
              placeholder={"Language(s)"}
              isMulti
              value={this.state.selectedLanguages.map((lang) => {
                return { label: lang, value: lang };
              })}
              options={this.state.languageOptions}
              disabledDropdownIndicator={true}
              onChange={(event) => {
                const data = event.map((lang) => lang.value);
                this.setState({
                  selectedLanguages: data,
                });
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              this.saveProfile();
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
        <div
          className="EditDetailsBottomLineStyles"
          style={{ marginBottom: 60, marginTop: 65 }}
        />
      </>
    );

    return (
      <div className="editDetails">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="editDetailsContentHeading">
            {CONSTANTS.ACCOUNT_INFORMATION_LABEL}
          </div>
          {this.state.isAccountInfoEditable ? (
            <CloseIcon
              style={{ color: "#a4c772" }}
              onClick={() => {
                this.getPreviousData();
                this.setState({
                  isAccountInfoEditable: false,
                });
              }}
            />
          ) : (
            <EditOutlinedIcon
              style={{ color: "#a4c772" }}
              onClick={() => {
                this.onEditAddress();
                this.onEditLanguages();
                this.setState({
                  isAccountInfoEditable: true,
                });
              }}
            />
          )}
        </div>
        <p
          style={{
            marginTop: 10,
            color: "#9A9A9A",
            fontFamily: "Libre Franklin",
          }}
        >
          {" "}
        </p>
        <br />
        <br />
        {this.state.isAccountInfoEditable ? (
          contentField
        ) : (
          <>
            <div style={{ marginTop: "20px" }}>
              <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading">
                  {" "}
                  {CONSTANTS.FIRST_NAME_LABEL}{" "}
                </div>
              </div>
              <div className="EditDetailsCompDetailsStyles">
                <div style={{ width: "100%" }}>{this.state.firstName}</div>
              </div>
              <div className="EditDetailsBottomLineStyles" />
            </div>
            <div style={{ marginTop: "35.5px" }}>
              <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading">
                  {" "}
                  {CONSTANTS.LAST_NAME_LABEL}{" "}
                </div>
              </div>
              <div className="EditDetailsCompDetailsStyles">
                <div style={{ width: "100%" }}>{this.state.lastName}</div>
              </div>
              <div className="EditDetailsBottomLineStyles" />
            </div>
            <div style={{ marginTop: "35.5px" }}>
              <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading">
                  {" "}
                  {CONSTANTS.PREFERRED_NAME}{" "}
                </div>
              </div>
              <div className="EditDetailsCompDetailsStyles">
                <div style={{ width: "100%" }}>{this.state.preferredName}</div>
              </div>
              <div className="EditDetailsBottomLineStyles" />
            </div>
            <div style={{ marginTop: "35.5px" }}>
              <CheckboxComponent
                checked={this.state.firstNameOfLetter}
                name={"firstLetterOfName"}
                label={"Only show first letter of my last name"}
                onChange={() => {
                  this.setState({
                    firstNameOfLetter: !this.state.firstNameOfLetter,
                  });
                }}
                color="#a4c772"
              />
              <div className="EditDetailsBottomLineStyles" />
            </div>
            <div style={{ marginTop: "35.5px" }}>
              <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading">
                  {" "}
                  {CONSTANTS.ADDRESS_LABEL}{" "}
                </div>
              </div>
              <div className="EditDetailsCompDetailsStyles">
                <div style={{ width: "100%" }}>
                  {`${this.state.address.unit_number &&
                    this.state.address.unit_number + ","
                    } ${this.state.address.street_address
                      ? (this.state.address.street_address || "") + ","
                      : ""
                    } ${this.state.address.city}, ${this.state.address.state}, ${this.state.address.postal_code
                    }, ${this.state.address.country}`}
                </div>
              </div>
              <div className="EditDetailsBottomLineStyles" />
            </div>
            <div style={{ marginTop: "35.5px" }}>
              <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading">
                  {" "}
                  {CONSTANTS.LANGUAGES_LABEL}{" "}
                </div>
              </div>
              <div className="EditDetailsCompDetailsStyles">
                <div style={{ width: "100%" }}>
                  {this.state.selectedLanguages.map((lang) => lang).join(", ")}
                </div>
              </div>
            </div>
            <div
              className="EditDetailsBottomLineStyles"
              style={{ marginBottom: 70, marginTop: 70 }}
            />
          </>
        )}
        <div style={{ marginTop: "35.5px", marginBottom: "85.5px" }}>
          <EditDetailsComponent
            isEmployee
            otherChangeType={
              <div
                style={{
                  fontSize: "20px",
                  color: "#1876d2",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.decideCardToDisplay(cardType.UPDATE_MOBILE);
                  this.setState({
                    isCardDisplayed: true,
                  });
                }}
              >
                {CONSTANTS.MOBILE_VERIFICATION_LABEL}
              </div>
            }
            label={CONSTANTS.MOBILE_NUMBER_LABEL}
            editFields={<div>Test Data</div>}
            verifiedIcon={this.state.isMobileNumberConfirmed}
            unverifiedIcon={!this.state.isMobileNumberConfirmed}
            content={this.state.mobileNumber}
          />
        </div>
      </div>
    );
  };

  passwordSecurityDetails = () => {
    const editEmailAddress = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          value={this.state.emailAddress}
          onChange={(event) =>
            this.setState({ emailAddress: event.target.value })
          }
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              this.saveProfile();
              this.setState({ isEmailAddressEditable: false });
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const editPassword = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          type="password"
          width={"100%"}
          value={this.state.password}
          onChange={(event) => this.setState({ password: event.target.value })}
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => this.setState({ isPasswordEditable: false })}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );
    return (
      <div className="editDetails">
        <div className="editDetailsContentHeading">
          {CONSTANTS.PASSWORD_AND_SECURITY_LABEL}
        </div>
        <div style={{ marginTop: "63px" }}>
          <EditDetailsComponent
            isEmployee
            showBorderBottom={true}
            label={CONSTANTS.EMAIL_ADDRESS}
            editFields={editEmailAddress}
            content={this.state.emailAddress}
            isEditButtonClicked={this.state.isEmailAddressEditable}
            onEditClick={() => {
              this.decideCardToDisplay(cardType.UPDATE_EMAIL);
              this.setState({
                // isEmailAddressEditable: !this.state.isEmailAddressEditable,
                isCardDisplayed: true,
              });
            }}
          // this.setState({ isEmailAddressEditable: !this.state.isEmailAddressEditable })}
          />
        </div>
        <div style={{ marginTop: "63px" }}>
          <EditDetailsComponent
            isEmployee
            showBorderBottom={false}
            label={CONSTANTS.PASSWORD}
            editFields={editPassword}
            content={this.state.password}
            encrypt={true}
            isEditButtonClicked={this.state.isPasswordEditable}
            // onEditClick={() => this.setState({ isPasswordEditable: !this.state.isPasswordEditable })}
            onEditClick={() => {
              this.decideCardToDisplay(cardType.UPDATE_PASSWORD);
              this.setState({
                // isPasswordEditable: !this.state.isPasswordEditable,
                isCardDisplayed: true,
              });
            }}
          />
        </div>
      </div>
    );
  };

  handleUpdatePassword = () => {
    if (
      this.state.newPassword !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.currentPassword !== ""
    ) {
      if (Number(this.state.newPassword.length) >= 6) {
        this.setState({ atLeastSixCharPassword: true });
        if (this.state.newPassword !== this.state.confirmPassword) {
          this.setState({ passwordDoesNotMatch: true });
        } else {
          let body = {
            email: localStorage.getItem("username"),
            new_password: this.state.newPassword,
            old_password: this.state.currentPassword,
          };

          let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };

          APIHelper.postMethod(API.CHANGE_PASSWORD, body, headers)
            .then((response) => {
              Swal.fire({
                customClass: "Alert-Class",
                title: "Information!",
                text: response.message,
                icon:
                  response.message === "Password Successfully updated"
                    ? "success"
                    : "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1876D2",
              }).then((res) => {
                if (res) {
                  localStorage.removeItem("token");
                  localStorage.removeItem("hourlyRateMin");
                  localStorage.removeItem("hourlyRateMax");
                  localStorage.removeItem("talentSearchData");
                  localStorage.removeItem("calendarDates");
                  localStorage.removeItem("userId");
                  localStorage.removeItem("fieldData");
                  window.location = "/login";
                }
              });
              if (response.status === 1) {
                this.setState({
                  isCardDisplayed: false,
                });
              }
            })
            .catch((err) => {
              Swal.fire({
                customClass: "Alert-Class",
                title: "Information!",
                text: "Failed",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1876D2",
              });
            });
        }
      } else {
        this.setState({ atLeastSixCharPassword: false });
      }
    } else {
      this.setState({
        isValidCurrentPassword: false,
        isValidnewPassword: false,
        isValidConfirmPassword: false,
      });
    }
  };

  handleUpdateEmail = () => {
    const params = new URLSearchParams();
    params.append("email", this.state.newEmail);
    let headers = {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    };

    APIHelper.postMethod(API.CHECK_EMAIL, params, headers)
      .then((response) => {
        if (response.data.isEmailExist) {
          this.setState({ isEmailExist: true });
        } else {
          let tokenHeader = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          };
          const paramsPost = new URLSearchParams();
          paramsPost.append("newEmail", this.state.newEmail);
          paramsPost.append("currentEmail", this.state.emailAddress);
          APIHelper.postMethod(API.UPDATE_EMAIL, paramsPost, tokenHeader)
            .then((response) => {
              if (response.status === 1) {
                Swal.fire({
                  customClass: "Alert-Class",
                  title: "Information!",
                  text: response.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#1876D2",
                });
                this.decideCardToDisplay(cardType.VERIFY_EMAIL);
                this.setState({
                  isCardDisplayed: true,
                });
              } else {
                Swal.fire({
                  customClass: "Alert-Class",
                  title: "Error!",
                  text: response.message,
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#1876D2",
                });
              }
            })
            .catch((err) => {
              // this.setState({ isEmailExist: false });
            });
          this.setState({ isEmailExist: false });
        }
      })
      .catch((err) => {
        this.setState({ isEmailExist: false });
      });
  };

  resendOtp = async () => {
    console.log("in employee resend otp");
    axios
      .post(API.RESEND_VERIFY_OTP, {
        email: this.state.newEmail,
      })
      .then((response) => {
        if (response.status === 1) {
          alert('OTP resend');
        }
      })
      .catch((err) => console.log(err));
  };

  updateEmail = () => {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          height: "20vw",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "50%",
            marginLeft: "25%",
            marginTop: "58px",
            marginBottom: "18px",
          }}
        >
          <Textfield
            placeholder={"Current Email"}
            width={"100%"}
            value={this.state.emailAddress}
            disabled
          // onChange={(event) => this.setState({ currentEmail: event.target.value })}
          />
        </div>
        <div style={{ width: "50%", marginLeft: "25%", marginBottom: "18px" }}>
          <Textfield
            placeholder={"New Email"}
            width={"100%"}
            value={this.state.newEmail}
            onChange={(event) =>
              this.setState({ newEmail: event.target.value })
            }
          />
        </div>
        <div
          style={{
            fontFamily: "Libre Franklin",
            fontSize: "18px",
            color: "#707070",
            width: "50%",
            marginLeft: "25%",
          }}
        >
          You will be logged out from your account until you have verified your
          new email.
        </div>
        <div style={{ marginTop: "49px", marginBottom: "60px" }}>
          {this.state.isEmailExist && (
            <div className="errorMsg" style={{ marginBottom: 10 }}>
              New email already exists.{" "}
            </div>
          )}
          <Button
            text="Verify Email"
            width={"50%"}
            height="56px"
            onClick={() => this.handleUpdateEmail()}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );
  };

  updateMobile = () => {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          height: "20vw",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "50%",
            marginLeft: "25%",
            marginBottom: "18px",
            marginTop: "127px",
          }}
        >
          <PhoneInput
            // country={'in'}
            specialLabel={""}
            value={this.state.mobileNumber}
            onChange={(phone) => this.setState({ mobileNumber: phone })}
          />
        </div>
        <div style={{ marginTop: "53px", marginBottom: "127px" }}>
          <Button
            text="Verify Mobile Number"
            width={"50%"}
            height="56px"
            onClick={() => this.handleUpdateMobileNumber()}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );
  };

  handleUpdateMobileNumber = () => {
    const payload = {
      mobileNumber: this.state.mobileNumber,
      userId: localStorage.getItem("userId"),
    };

    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.postMethod(API.UPDATE_MOBILE, payload, headers)
      .then((response) => {
        if (response.status === 1) {
          this.decideCardToDisplay(cardType.VERIFY_MOBILE);
          // this.setState({
          //     isCardDisplayed: false
          // })
          Swal.fire({
            customClass: "Alert-Class",
            title: "Success!",
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        } else {
          Swal.fire({
            customClass: "Alert-Class",
            title: "Error!",
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      });
  };

  handleVerifyMobileNumber = () => {
    const payload = {
      otp: this.state.otp,
      userId: localStorage.getItem("userId"),
    };

    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.postMethod(API.VERIFY_MOBILE, payload, headers)
      .then((response) => {
        if (response.status === 1) {
          this.saveProfile();
          this.setState({
            isCardDisplayed: false,
          });
          Swal.fire({
            customClass: "Alert-Class",
            title: "Success!",
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        } else {
          this.setState({
            otpError: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          otpError: true,
        });
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      });
  };

  handleVerifyEmail = () => {
    const payload = {
      otp: this.state.otp,
      email: this.state.newEmail,
    };

    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.postMethod(API.VERIFY_OTP, payload, headers)
      .then((response) => {
        if (response.status === 1) {
          this.saveProfile();
          this.setState({
            isCardDisplayed: false,
          });
          Swal.fire({
            customClass: "Alert-Class",
            title: "Success!",
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          }).then((res) => {
            localStorage.removeItem("token");
            localStorage.removeItem("hourlyRateMin");
            localStorage.removeItem("hourlyRateMax");
            localStorage.removeItem("talentSearchData");
            localStorage.removeItem("calendarDates");
            localStorage.removeItem("userId");
            localStorage.removeItem("fieldData");
            window.location = "/login";
          });
        } else {
          this.setState({
            otpError: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          otpError: true,
        });
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      });
  };

  verifyMobile = (isMobile) => {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
          height: "20vw",
          overflow: "auto",
        }}
      >
        <OtpInput
          value={this.state.otp}
          onChange={(e) => {
            this.setState({
              otp: e,
              otpError: false,
            });
          }}
          containerStyle={{
            width: "100%",
            marginTop: "85px",
            justifyContent: "space-around",
          }}
          inputStyle={{
            width: "82px",
            fontSize: "22px",
            height: "100px",
            borderRadius: "10px",
            justifyContent: "space-around",
            border: `1px ${this.state.otpError ? "red" : "#707070"} solid`,
          }}
          numInputs={4}
        />
        {this.state.otpError ? (
          <div className="errorMsg">The otp you entered does not match!</div>
        ) : null}
        <div style={{ paddingTop: "37px" }}>
          <Button
            text="Confirm"
            width={"458px"}
            height="56px"
            onClick={() =>
              isMobile
                ? this.handleVerifyMobileNumber()
                : this.handleVerifyEmail()
            }
            color={["#a4c772", "#4e8400"]}
          />
        </div>
        <div className="verifyEmailFooterWrapper">
          <span className="verifyEmailHeading">
            {isMobile
              ? "Haven’t received the code?"
              : "Haven’t received the email?"}{" "}
            <span
              className="resendOtp"
              onClick={() =>
                isMobile ? this.handleUpdateMobileNumber() : this.resendOtp()
              }
              style={{ color: "#1d78c6", textDecoration: "underline" }}
            >
              Resend Code.
            </span>
          </span>
        </div>
      </div>
    );
  };

  updatePasswordCard = () => {
    return (
      <div style={{ height: "19.5vw", overflow: "auto" }}>
        <div style={{ marginTop: "39.5px", marginBottom: "30px" }}>
          <Textfield
            placeholder={"Current password"}
            value={this.state.currentPassword}
            type={this.state.showCurrentPassword ? "text" : "password"}
            onChange={(event) =>
              this.setState({
                currentPassword: event.target.value,
                isValidCurrentPassword: true,
              })
            }
            error={!this.state.isValidCurrentPassword}
            width={"50%"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      this.setState({
                        showCurrentPassword: !this.state.showCurrentPassword,
                      })
                    }
                    edge="end"
                  >
                    {this.state.showCurrentPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ float: "left", marginLeft: "25%" }}>
          Must include at least 6 characters.
        </div>
        <div style={{ marginBottom: "18px" }}>
          <Textfield
            placeholder={"New password"}
            required={true}
            value={this.state.newPassword}
            type={this.state.showNewPassword ? "text" : "password"}
            onChange={(event) =>
              this.setState({
                newPassword: event.target.value,
                isValidnewPassword: true,
                passwordDoesNotMatch: false,
              })
            }
            width={"50%"}
            error={
              this.state.passwordDoesNotMatch ||
              !this.state.isValidnewPassword ||
              !this.state.atLeastSixCharPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      this.setState({
                        showNewPassword: !this.state.showNewPassword,
                        passwordDoesNotMatch: false,
                      })
                    }
                    edge="end"
                  >
                    {this.state.showNewPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Textfield
            placeholder={"Re-enter new password"}
            required={true}
            value={this.state.confirmPassword}
            type={this.state.showConfirmPassword ? "text" : "password"}
            onChange={(event) =>
              this.setState({
                confirmPassword: event.target.value,
                isValidConfirmPassword: true,
                passwordDoesNotMatch: false,
              })
            }
            width={"50%"}
            error={
              this.state.passwordDoesNotMatch ||
              !this.state.isValidConfirmPassword
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      this.setState({
                        showConfirmPassword: !this.state.showConfirmPassword,
                        passwordDoesNotMatch: false,
                      })
                    }
                    edge="end"
                  >
                    {this.state.showConfirmPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {this.state.passwordDoesNotMatch ? (
          <div className="errorMsg">Passwords does not match.</div>
        ) : null}
        <div style={{ marginTop: "36px" }}>
          <Button
            text="Update Password"
            width={"50%"}
            height="56px"
            onClick={() => {
              this.handleUpdatePassword();
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );
  };

  myRateDetails = () => {
    const contentField = (
      <>
        <div style={{ width: "100%", marginTop: "63px" }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">{CONSTANTS.RATE}</div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Textfield
              width={"100%"}
              type="number"
              value={this.state.rate}
              onChange={(event) => this.setState({ rate: event.target.value })}
            />
          </div>
        </div>
        <div className="EditDetailsBottomLineStyles" />
        <div style={{ marginTop: "32px" }}>
          <CheckboxComponent
            checked={this.state.isWorkInternationally}
            name={"isWorkInternationally"}
            label={"I can work internationally"}
            onChange={() => {
              this.setState({
                isWorkInternationally: !this.state.isWorkInternationally,
                isInternationEditable: !this.state.isWorkInternationally,
                internationalRate: "",
                internationalRateError: false
              });
            }}
            color="#a4c772"
          />
        </div>
        <div style={{ width: "100%", marginTop: "35px" }}>
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">
              Specify below your desired rate in USD:
            </div>
          </div>
          <div style={{ marginTop: "14px" }}>
            <Textfield
              disabled={!this.state.isWorkInternationally}
              width={"100%"}
              type="number"
              value={this.state.internationalRate}
              onChange={(event) => {
                if (event.target.value < 5) {
                  this.setState({
                    internationalRate: event.target.value,
                    internationalRateError: true
                  });
                }
                else {
                  this.setState({
                    internationalRate: event.target.value,
                    internationalRateError: false
                  });
                }
              }}
              error={this.state.internationalRateError}
            />
          </div>
        </div>
        <div style={{ marginTop: "20px", marginBottom: "85.5px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              if (this.state.isWorkInternationally && this.state.internationalRate < 5) {
                this.setState({ internationalRateError: true });
                Swal.fire({
                  customClass: "Alert-Class",
                  title: "Error!",
                  text: "International rate value should be more than 5",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#1876D2",
                });
              }
              else if (!this.state.internationalRateError && this.state.rate >= 5) {
                this.saveProfile();
                this.setState({ isRateEditable: false });
              } else {
                Swal.fire({
                  customClass: "Alert-Class",
                  title: "Error!",
                  text: "Local rate or International rate value should be more than 5",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#1876D2",
                });
              }
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </>
    );

    return (
      <div className="editDetails">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="editDetailsContentHeading">{CONSTANTS.MY_RATE}</div>
          {this.state.isRateEditable ? (
            <CloseIcon
              style={{ color: "#a4c772" }}
              onClick={() => {
                this.getPreviousData();
                this.setState({
                  isRateEditable: false,
                });
              }}
            />
          ) : (
            <EditOutlinedIcon
              style={{ color: "#a4c772" }}
              onClick={() => {
                this.onEditLanguages();
                this.setState({
                  isRateEditable: true,
                });
              }}
            />
          )}
        </div>
        {this.state.isRateEditable ? (
          contentField
        ) : (
          <div style={{ marginTop: "63px" }}>
            <div className="EditDetailsCompHeadingStyles">
              <div className="editDetailsCompHeading"> {CONSTANTS.RATE} </div>
            </div>
            <div className="EditDetailsCompDetailsStyles">
              <div style={{ width: "100%" }}>${this.state.rate}</div>
            </div>
            <div className="EditDetailsBottomLineStyles" />
            <div style={{ marginTop: "32px" }}>
              <CheckboxComponent
                checked={this.state.isWorkInternationally}
                name={"isWorkInternationally"}
                label={"I can work internationally"}
                // onChange={() => {
                //     this.setState({
                //         isWorkInternationally: !this.state.isWorkInternationally,
                //         isInternationEditable: !this.state.isWorkInternationally,
                //     });
                // }}
                disabled
                color="#a4c772"
              />
            </div>
            {this.state.isWorkInternationally && (<div style={{ marginTop: "35px" }}>
              <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading">
                  Specify below your desired international rate in USD:
                </div>
              </div>
              <div className="EditDetailsCompDetailsStyles">
                <div style={{ width: "100%" }}>
                  ${this.state.internationalRate} USD
                </div>
              </div>
            </div>)}
          </div>
        )}
      </div>
    );
  };

  idVerificationDetails = () => {
    const individualType = (
      <div style={{ marginTop: "30px" }}>
        <p style={{ marginBottom: 15, color: "#1C1C1C", fontSize: 20 }}>
          Identification Type:
        </p>
        <Dropdown
          placeholder={"Driver's Licence/Passport"}
          options={identificationLevel}
          disabledDropdownIndicator={false}
          onChange={(event) => {
            this.setState({ identificationType: event.value });
          }}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
        />
        <div style={{ marginTop: 15, marginBottom: 20 }}>
          <Textfield
            placeholder="ID Number"
            width={"100%"}
            value={this.state.drivingLicenceNumber}
            onChange={(event) =>
              this.setState({ drivingLicenceNumber: event.target.value })
            }
          />
        </div>
      </div>
    );

    return (
      <div className="editDetails">
        <div className="editDetailsContentHeading">
          {CONSTANTS.ID_VERIFICATION_LABEL}
        </div>
        <div style={{ marginTop: 40 }}>{individualType}</div>
        <div style={{ marginTop: "36px" }}>
          <input
            id="myProfile"
            type="file"
            ref={(ref) => (this.uploadDoc = ref)}
            style={{ display: "none" }}
            onChange={this.uploadDocument}
          />
          <Button
            text="Upload Doc"
            width={"180px"}
            height="48px"
            onClick={() => this.uploadDoc.click()}
            color={["#a4c772", "#4e8400"]}
          />
          <p>{this.state.documentFileName}</p>
        </div>
        <p
          style={{
            marginTop: 53,
            fontSize: 19,
            color: "#1C1C1C",
            fontFamily: "Libre Franklin",
          }}
        >
          By clicking on verify my ID, you agree to terms and conditions of
          Empolink. A small charge will apply for verification process.
        </p>
        <div style={{ marginTop: "20px", marginBottom: 100 }}>
          <Button
            text="Verify My ID"
            width={"180px"}
            height="48px"
            onClick={this.verifyMyID}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );
  };

  notificationSettingDetails = () => {
    this.state.newNotifications = [...this.state.notificationsArr];
    this.state.notificationsArr.map((i, index) => {
      if (this.state.user_preference.filter((k) => k.title === i.title).length > 0) {
        this.state.newNotifications[index] = this.state.user_preference.filter((k) => k.title === i.title)[0]
      }
      this.state.notificationsArr.length === index &&
        this.setState({
          newNotifications: this.state.newNotifications,
        })
    }
    )
    return (
      <div className="editDetails">
        <div className="editDetailsContentHeading">
          {CONSTANTS.NOTIFICATION_SETTINGS_LABEL}
        </div>
        <div style={{ marginTop: 40 }}>
          {this.state.newNotifications.map((notification, index) => (
            <div key={notification.title}>
              <div
                style={{
                  height: 20,
                  display: "flex",
                  lineHeight: "20px",
                  marginTop: 20,
                }}
              >
                <p
                  style={{ lineHeight: "40px", fontSize: 20, color: "#1C1C1C" }}
                >
                  {notification.title}
                </p>
                <div
                  style={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  <CheckboxComponent
                    checked={notification.isEmail}
                    name={"Email"}
                    label={"Email"}
                    onChange={() => {
                      const array = [...this.state.newNotifications];
                      array[index].isEmail = !notification.isEmail;
                      this.setState({
                        newNotifications: array,
                      });
                    }}
                    color="#a4c772"
                  />
                  <CheckboxComponent
                    checked={notification.isText}
                    name={"Mobile"}
                    label={"Mobile"}
                    onChange={() => {
                      const array = [...this.state.newNotifications];
                      array[index].isText = !notification.isText;
                      this.setState({
                        newNotifications: array,
                      });
                    }}
                    color="#a4c772"
                  />
                </div>
              </div>
              {/* {index + 1 !== notificationSettings.length && ( */}
              <div className="EditDetailsBottomLineStyles" />
              {/* )} */}
            </div>
          ))}
          <div style={{ marginTop: 50, marginBottom: 70 }}>
            <Button
              text="Save"
              width={"140px"}
              height="48px"
              onClick={() => this.saveNotification()}
              color={["#a4c772", "#4e8400"]}
            />
          </div>
        </div>
      </div>
    );
  };

  paymentMethodDetails = () => {
    const faqURL = `${API.FRONT_HOST}help/talents`;
    return (
      <>
        {this.state.paymentPopup &&
          <div id="overlay">
            <div
              style={{
                width: '35vw',
                maxWidth: '800px',
                height: 'auto',
                backgroundColor: 'white',
                position: 'absolute',
                borderRadius: '10px',
                left: '58%',
                top: '50%',
                transform: 'translate(-50%,-50%)',
                padding: '2.0em',
                textAlign: 'left',
                boxSizing: 'border-box', // Include padding and border in element's total width and height
              }}
            >

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CloseIcon
                  style={{ color: '#2699fb' }}
                  onClick={() => {
                    this.setState({
                      paymentPopup: false,
                    });
                  }}
                />
              </div>
              <p style={{ marginTop: '0.5em', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                To apply for a job you are required to set up your Deposit Method.
                This is a one-time only process in order to  receive payments into
                your bank account. <br />
                You can complete the set up now or at any time before applying for a job.
              </p>

            </div>
          </div>}

        <div className="editDetails">
          <div className="editDetailsContentHeading">Deposit Method</div>
          <br />
          <p
            style={{
              marginTop: 8,
              color: "#404040",
              fontFamily: "Libre Franklin",
            }}
          >
            Set up your Deposit Method to receive payments into your bank account.
            <br />
            <br />
            To add your banking account, follow these steps:
            <br />
            1- Click on "Add Account" and follow the Stripe registration.
            <br />
            2- Look for an email sent by Stripe to verify your email address.
            <br />
            3- Log in to your{" "}
            <a
              style={{ color: "#0479FF" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/"
            >
              Stripe
            </a>{" "}
            account, go to settings and click on{" "}
            <a
              style={{ color: "#0479FF" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://dashboard.stripe.com/settings/update"
            >
              Business Details
            </a>{" "}
            and ensure any <span style={{ whiteSpace: "nowrap" }}>missing required information</span> is cleared.
            <br />
            4- For more information, see our{" "}
            <a
              style={{ color: "#0479FF" }}
              target="_blank"
              rel="noopener noreferrer"
              href={faqURL}
            >
              FAQ
            </a>
            .
          </p>

          <BankingModule
            refreshStatus={this.state.refreshStatus}
            onRefreshUpdate={this.refreshUpdate}
          />
          {/* <div
            className="EditDetailsBottomLineStyles"
            style={{ marginBottom: 50 }}
          />
          <div className="editDetailsContentHeading">
            {CONSTANTS.PAYMENT_METHOD_LABEL}
          </div>
          
          <div style={{ marginBottom: 60 }}>
            <PaymentModule />
          </div> */}
          <div
            className="EditDetailsBottomLineStyles"
            style={{ marginBottom: 25 }}
          />
          <p
            style={{
              marginTop: 5,
              color: "#404040",
              fontFamily: "Libre Franklin",
            }}
          >
            Your security is important to us. We do not store or have access to
            your Banking information. We use Stripe to securely handle all
            financial transactions. Stripe is a Validated Level 1 PCI DSS Compliant Service
            Provider.
            <br />
            <br />
          </p>
        </div>
      </>
    );
  };

  setUpPageDetails = () => {
    const { dataFieldOfWorkDropdown, getSpecialityList } = this.props;

    const highlights = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          value={this.state.highlight}
          rows={10}
          multiline
          onChange={(event) => this.setState({ highlight: event.target.value })}
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              this.saveProfile();
              this.setState({ isHighlightEditable: false });
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const editFieldOfWork = (
      <div style={{ marginTop: "20px" }}>
        <div>
          <Dropdown
            placeholder={"Field of Work *"}
            options={dataFieldOfWorkDropdown}
            disabledDropdownIndicator={false}
            value={this.state.fieldOfWork}
            onChange={(event) => {
              this.getFieldList(event._id);
              this.setState({
                fieldOfWork: event,
                topSkillOptions: [],
                skillSet: []
              });
              getSpecialityList(event);
            }}
            isSearchable={false}
            getOptionLabel={(option) => option.lable}
            getOptionValue={(option) => option.value}
          />
        </div>
        <div className="EditDetailsBottomLineStyles" />
          <div className="EditDetailsCompHeadingStyles">
            <div className="editDetailsCompHeading">Specialties & Skills</div>
          </div>
        {(this.state.specialityDefaultValueDropDown !== null) && (
          <div style={{ marginTop: "33px", width: "100%" }}>
            {/*<div className="editDetailsCompHeading">Specialities & Skills</div>*/}
            <Dropdown
              placeholder="Specialties"
              defaultValue={
                this.state.specialitiesOptions.find((option => option.value === this.state.specialityDefaultValueDropDown?.value)) ?? this.state.specialitiesOptions[0]
              }
              key="Specialties"
              onChange={(e) => {
                this.getSpecialityList(e._id);
                this.setState({
                  selectedSkillOptions: [],
                  extraSkills: [],
                  speciality: e,
                  specialityDefaultValueDropDown: e,
                  key: Math.random(),
                });
              }}
              options={this.state.specialitiesOptions}
            />
          </div>
        )}
        <div style={{ marginTop: "20px" }}>
          {this.state.topSkillOptions.map((skill) => {
            return (
              <CustomChip
                onClick={() => {
                  if (
                    this.isNotInArray(
                      this.state.selectedSkillOptions,
                      skill.name
                    ) === "true"
                  ) {
                    const arr = [
                      ...this.state.selectedSkillOptions,
                      { ...skill },
                    ];
                    this.setState({
                      selectedSkillOptions: arr,
                    });
                  } else {
                    this.removeSelectedTopSkill(skill);
                  }
                }}
                outlined={this.isNotInArray(
                  this.state.selectedSkillOptions,
                  skill.name
                )}
                key={skill._id}
                style={{
                  padding: "18px 8px 17px 12px",
                  borderRadius: "20px",
                  height: "auto",
                  width: "auto",
                  marginRight: "20px",
                }}
                label={skill.name}
                onDelete={() => {
                  this.removeSelectedTopSkill(skill);
                  this.removeTopSkill(skill);
                }}
              />
            );
          })}

          <div style={{ marginTop: "20px" }}>
            <MultiTagSelect
              tags={this.state.extraSkills}
              suggestions={this.state.skillOptions.filter((skill) => {
                return (
                  this.isNotInArray(this.state.topSkillOptions, skill.name) ===
                  "true"
                );
              })}
              onChange={(items) => this.onAddition(items)}
              size={
                this.state.selectedSkillOptions &&
                this.state.selectedSkillOptions.length
              }
              key={this.state.key}
              speciality={this.state.speciality}
            />
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              marginBottom: "18px",
            }}
          >
            {/*<IconButton
              onClick={this.addSkillSet}
              component={"span"}
              style={{
                backgroundColor: "rgba(164,199,114,0.64)",
                marginRight: "5px",
                color: "#707070",
              }}
            >
              <Add style={{ color: "#fff" }} />
            </IconButton>*/}

            {/*added modal Instruction*/}
            <InstructionModal>
              <p>
                Select a speciality then select your skills from suggested ones or type new skills in the box. <br />
                Next click "Add Speciality and Skills" to save them to your profile.<br />
                You can add more specialities and skills by repeating this.
              </p>
            </InstructionModal>

            <Button
            text="Add Speciality and Skills"
            width='360px'
            height="48px"
            onClick={this.addSkillSet}
            color={["#a4c772", "#4e8400"]}
          />
            {/*<p
              style={{
                color: "#9a9a9a",
                marginLeft: "5px",
                fontSize: "20px",
              }}
            >
              Click on the plus sign to add the selected Specialty and skills
            </p>*/}
          </div>
          <div
            id="skillContainer"
            style={{
              borderRadius: "25px",
              border: "1px solid #707070",
              padding: "10px",
              marginBottom: "5px",
              minHeight:"195PX",
              maxHeight: "800px",
              overflow: "auto",
              height: "auto",
            }}
          >
            {this.state.skillSet.map((set, id) => {
              return (
                <div key={id} style={{ marginBottom: "5px" }}>
                  <div
                    style={{
                      display: "flex",
                      color: "#454545",
                      alignItems: "center",
                    }}
                  >
                    {set.speciality}{" "}
                    <Delete
                      style={{ cursor: "pointer" }}
                      onClick={() => this.removeSkillSet(set.speciality)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      color: "#454545",
                      marginBottom: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    {set.skill.map((skill, id) => (
                      <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                        <Chip
                          height={"27px"}
                          backgroundColor={"#a4c772"}
                          borderRadius={"8px"}
                          // width={'27px'}
                          label={skill.name}
                          color={"#ffffff"}
                          deleteIcon={
                            <div
                              style={{
                                color: "#ffffff",
                                fontSize: "18px",
                                marginTop: "3px",
                              }}
                            >
                              <ClearIcon fontSize="inherit" color="inherit" />
                            </div>
                          }
                          onDelete={() => {
                            this.removeSkill(set.speciality, skill.name);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            id="skillErrorMessage"
            style={{
              display: this.state.isSkillError ? "block" : "none",
              color: "red",
            }}
          >
            you can only add upto 100 skills
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              this.saveProfile();
              this.setState({ isFieldOfWorkEditable: false });
              this.setState({ error: {} });
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const editEmployeementHistory = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          value={this.state.companyName}
          error={
            this.state.error?.companyName && !(this.state.companyName !== "")
          }
          placeholder="Company"
          onChange={(event) =>
            this.setState({ companyName: event.target.value })
          }
        />
        <div style={{ marginTop: 16 }}>
          <Textfield
            width={"100%"}
            value={this.state.position}
            error={this.state.error?.position && !(this.state.position !== "")}
            placeholder="Title/Position"
            onChange={(event) =>
              this.setState({ position: event.target.value })
            }
          />
        </div>
        <div style={{ display: "flex", marginTop: 16 }}>
          <div style={{ paddingRight: 10, width: "100%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MonthYearPicker
                startMonth={1}
                startYear={1970}
                fieldLabel={"From"}
                endMonth={new Date().getMonth() + 1}
                endYear={new Date().getFullYear()}
                onSelected={(val) => {
                  this.setState({ from: val });
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ paddingLeft: 10, width: "100%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MonthYearPicker
                fieldLabel="To"
                startMonth={1}
                startYear={1970}
                endMonth={new Date().getMonth() + 1}
                endYear={new Date().getFullYear()}
                onSelected={(val) => {
                  this.setState({ to: val });
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <CheckboxComponent
            checked={this.state.currentWorking}
            name={"currentWorking"}
            label={"Currently Working for this client"}
            onChange={() => {
              this.setState({
                currentWorking: !this.state.currentWorking,
                to: null,
              });
            }}
            color="#a4c772"
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <Textfield
            width={"100%"}
            value={this.state.highlights}
            placeholder="Highlights/Summary"
            multiline
            rows={8}
            onChange={(event) =>
              this.setState({ highlights: event.target.value })
            }
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              const {
                companyIndex,
                employeementHistory,
                companyName,
                to,
                from,
                highlights,
                position,
                currentWorking,
              } = this.state;
              const newArr = [...employeementHistory];
              if (
                companyName &&
                (to || currentWorking) &&
                from &&
                position &&
                highlights
              ) {
                if (companyIndex === -1) {
                  const obj = {
                    company: companyName,
                    position: position,
                    date_join: from,
                    date_left: to,
                    description: highlights,
                    location: "",
                  };
                  newArr.push(obj);
                } else {
                  newArr[companyIndex].company = companyName;
                  newArr[companyIndex].position = position;
                  newArr[companyIndex].date_left = to;
                  newArr[companyIndex].date_join = from;
                  newArr[companyIndex].description = highlights;
                  newArr[companyIndex].location = "";
                }
                this.setState(
                  {
                    isCompanyEditable: false,
                    employeementHistory: newArr,
                  },
                  () => {
                    this.saveProfile();
                    this.setState({ error: {} });
                  }
                );
              } else {
                this.setState({ error: { companyName: true, position: true } });
              }
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const employeementHistoryContent = (
      <>
        {this.state.employeementHistory.map((empHistory, index) => (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p
                  className="paragraph"
                  style={{ fontFamily: "Libre Franklin" }}
                >
                  {empHistory.company}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#a4c772",
                }}
              >
                <DeleteIcon
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      showDeleteConfirmationModel: true,
                      employmentDeleteIndex: index,
                    });
                  }}
                  style={{ cursor: "pointer" }}
                />
                <div style={{ marginLeft: 8 }}>
                  <EditIcon
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({
                        companyIndex: index,
                        companyName: empHistory.company,
                        position: empHistory.position,
                        from: empHistory.date_join,
                        to: empHistory.date_left,
                        highlights: empHistory.description,
                        isCompanyEditable: true,
                        currentWorking: !empHistory.date_left,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 13,
              }}
            >
              <div>
                <p className="paragraph">{empHistory.position}</p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {empHistory.date_left && empHistory.date_left != null ? (
                  <p className="paragraph">
                    {moment(empHistory.date_join, "MM-YYYY").format("MMM YY")} -{" "}
                    {moment(empHistory.date_left, "MM-YYYY").format("MMM YY")}
                  </p>
                ) : (
                  <p className="paragraph">
                    {moment(empHistory.date_join, "MM-YYYY").format("MMM YY") +
                      " - Present"}
                  </p>
                )}
              </div>
            </div>
            <div style={{ marginTop: 13 }}>
              <p className="paragraph">{empHistory.description}</p>
            </div>
            {index + 1 !== this.state.employeementHistory.length && (
              <div
                className="EditDetailsBottomLineStyles"
                style={{ marginBottom: 33 }}
              />
            )}
          </>
        ))}
      </>
    );

    const editYearOfExperience = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          type="number"
          value={this.state.yearOfExperience}
          onChange={(event) => {
            if (parseFloat(event.target.value) >= 0) {
              this.setState({ yearOfExperienceErr: false });
            } else {
              this.setState({ yearOfExperienceErr: true });
            }
            this.setState({ yearOfExperience: event.target.value });
          }}
          error={this.state.yearOfExperienceErr}
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              if (parseFloat(this.state.yearOfExperience) >= 0) {
                this.saveProfile();
                this.setState({
                  isYearOfExperienceEditable: false,
                  yearOfExperienceErr: false,
                });
              } else {
                this.setState({
                  yearOfExperienceErr: true,
                });
              }
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const editEmployeementEducation = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          value={this.state.schoolName}
          placeholder="School Name"
          error={
            this.state.error?.schoolName && !(this.state.schoolName !== "")
          }
          onChange={(event) =>
            this.setState({ schoolName: event.target.value })
          }
        />
        <div style={{ marginTop: 16 }}>
          <Textfield
            width={"100%"}
            value={this.state.major}
            error={this.state.error?.major && !(this.state.major !== "")}
            placeholder="Major"
            onChange={(event) => this.setState({ major: event.target.value })}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <Textfield
            width={"100%"}
            value={this.state.degree}
            error={this.state.error?.degree && !(this.state.degree !== "")}
            placeholder="Degree/Certificate"
            onChange={(event) => this.setState({ degree: event.target.value })}
          />
        </div>
        <div style={{ display: "flex", marginTop: 16 }}>
          <div style={{ paddingRight: 10, width: "100%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={["year"]}
                label="From"
                inputVariant="outlined"
                value={this.state.fromEducation}
                style={
                  this.state.error.fromEducation &&
                    !(
                      this.state.fromEducation !== "" &&
                      this.state.fromEducation !== null
                    )
                    ? error
                    : {}
                }
                helperText=""
                onChange={(newValue) =>
                  this.setState({
                    fromEducation: newValue,
                    error: { ...this.state.error, fromEducation: false },
                  })
                }
                renderInput={(props) => (
                  <TextField {...props} variant="outlined" />
                )}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ paddingLeft: 10, width: "100%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                views={["year"]}
                label="To"
                inputVariant="outlined"
                value={this.state.toEducation}
                error={false}
                style={
                  this.state.error.toEducation &&
                    !(
                      this.state.toEducation !== "" &&
                      this.state.toEducation !== null
                    )
                    ? error
                    : {}
                }
                helperText=""
                onChange={(newValue) =>
                  this.setState({
                    toEducation: newValue,
                    error: { ...this.state.error, toEducation: false },
                  })
                }
                renderInput={(props) => (
                  <TextField {...props} variant="outlined" />
                )}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              const {
                educationHistory,
                educationIndex,
                schoolName,
                major,
                toEducation,
                fromEducation,
                degree,
              } = this.state;
              const newArr = [...educationHistory];
              if (
                schoolName !== "" &&
                toEducation !== null &&
                toEducation !== "" &&
                fromEducation !== null &&
                fromEducation !== "" &&
                major !== "" &&
                degree !== ""
              ) {
                if (educationIndex === -1) {
                  const obj = {
                    school_name: schoolName,
                    major: major,
                    startdate: fromEducation,
                    enddate: toEducation,
                    degree: degree,
                  };
                  newArr.push(obj);
                } else {
                  newArr[educationIndex].school_name = schoolName;
                  newArr[educationIndex].major = major;
                  newArr[educationIndex].enddate = toEducation;
                  newArr[educationIndex].startdate = fromEducation;
                  newArr[educationIndex].degree = degree;
                }
                this.setState(
                  { isEducationEditable: false, educationHistory: newArr },
                  () => {
                    this.saveProfile();
                  }
                );
              } else {
                this.setState({
                  error: {
                    schoolName: true,
                    major: true,
                    degree: true,
                    fromEducation: true,
                    toEducation: true,
                  },
                });
              }
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const employeementEducationContent = (
      <>
        {this.state.educationHistory.map((school, index) => (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p className="paragraph">{school.school_name}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#a4c772",
                }}
              >
                <DeleteIcon
                  color="inherit"
                  onClick={() =>
                    this.setState({
                      showDeleteConfirmationModel: true,
                      educationDeleteIndex: index,
                    })
                  }
                  style={{ cursor: "pointer" }}
                />
                <div style={{ marginLeft: 8 }}>
                  <EditIcon
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({
                        educationIndex: index,
                        fromEducation: school.startdate,
                        toEducation: school.enddate,
                        degree: school.degree,
                        major: school.major,
                        schoolName: school.school_name,
                        isEducationEditable: true,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: 13 }}>
              <p className="paragraph">{school.major}</p>
            </div>
            <div style={{ marginTop: 13 }}>
              <p className="paragraph">{school.degree}</p>
            </div>
            <div style={{ marginTop: 13, display: "flex" }}>
              <p className="paragraph">
                From: {moment(school.startdate).format("YYYY")}
              </p>
              <p className="paragraph" style={{ marginLeft: 50 }}>
                To: {moment(school.enddate).format("YYYY")}
              </p>
            </div>
            <div
              className="EditDetailsBottomLineStyles"
              style={{ marginBottom: 33 }}
            />
          </>
        ))}
      </>
    );

    const certificationContent = (
      <>
        {this.state.certificates.map((certificate, index) => (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: 16,
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="paragraph">{certificate.name}</p>
                <p className="paragraph" style={{ marginLeft: 50 }}>
                  {certificate.year}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#a4c772",
                }}
              >
                <div style={{ marginLeft: 8 }}>
                  <DeleteIcon
                    color="inherit"
                    onClick={() =>
                      this.setState({
                        showDeleteConfirmationModel: true,
                        certificationsDeleteIndex: index,
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                  <EditIcon
                    color="inherit"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({
                        certificateIndex: index,
                        certificate: certificate.name,
                        yearIssued: certificate.year,
                        isCertificateEditable: true,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="EditDetailsBottomLineStyles"
              style={{ marginBottom: 25 }}
            />
          </>
        ))}
      </>
    );

    const editCertification = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          value={this.state.certificate}
          placeholder="Certificate"
          error={
            this.state.error?.certificate && !(this.state.certificate !== "")
          }
          onChange={(event) =>
            this.setState({ certificate: event.target.value })
          }
        />
        <div style={{ marginTop: 16 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              views={["year"]}
              minDate={new Date("01/01/1970")}
              label="Year Issued"
              inputVariant="outlined"
              value={this.state.yearIssued}
              error={false}
              helperText=""
              onChange={(newValue) => this.setState({ yearIssued: newValue })}
              renderInput={(props) => (
                <TextField {...props} variant="outlined" />
              )}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              const {
                certificateIndex,
                certificate,
                yearIssued,
                certificates,
              } = this.state;
              const newArr = certificates;
              // let isPresent = newArr.filter(ele=> ele._id = certificateIndex);
              // isPresent.length === 0 ?
              // newArr.push( {
              //     name: certificate,
              //     year: moment(yearIssued).year() || moment().year(),
              // }):
              // newArr.map((cer)=>{
              //     if (cer.name && cer.name === certificate){
              //         cer = Object.assign({
              //             name : certificate.name,
              //             year : moment(yearIssued).year() || moment().year()
              //         })
              //     }
              //     console.log(cer);
              //     return cer;
              // });
              // this.setState({
              //     isCertificateEditable: false,
              //    // certificates: newArr
              // }, () => {
              //     this.saveProfile();
              // });

              if (certificate !== "" && certificate) {
                if (certificateIndex === -1) {
                  const obj = {
                    name: certificate,
                    year: moment(yearIssued).year() || moment().year(),
                  };
                  newArr.push(obj);
                } else {
                  newArr.map((cer, index) => {
                    if (index === certificateIndex) {
                      cer.name = certificate;
                      cer.year = moment(yearIssued).year() || moment().year();
                    }
                    console.log(cer);
                    return cer;
                  });
                }
                this.setState(
                  {
                    isCertificateEditable: false,
                  },
                  () => {
                    this.saveProfile();
                  }
                );
              } else {
                this.setState({ error: { certificate: true } });
              }
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );
    const editLinkedIn = (
      <div style={{ marginTop: "20px" }}>
        <Textfield
          width={"100%"}
          value={this.state.linkedIn}
          onChange={(event) => this.setState({ linkedIn: event.target.value })}
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => {
              this.saveProfile();
              this.setState({ isLinkedInEditable: false });
            }}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    const editLanguages = (
      <div style={{ marginTop: "20px" }}>
        <Dropdown
          placeholder={"Language(s)"}
          isMulti
          value={this.state.selectedLanguages.map((lang) => {
            return { label: lang, value: lang };
          })}
          options={this.state.languageOptions}
          disabledDropdownIndicator={true}
          onChange={(event) => {
            const data = event.map((lang) => lang.value);
            this.setState({
              selectedLanguages: data,
            });
          }}
        />
        <div style={{ marginTop: "20px" }}>
          <Button
            text="Save"
            width={"120px"}
            height="48px"
            onClick={() => this.setState({ isLanguageEditable: false })}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
      </div>
    );

    return (
      <div
        className="editDetails"
        style={{ fontFamily: "Libre Franklin !important" }}
      >
        <div
          className="editDetailsContentHeading"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {CONSTANTS.MY_PROFILE}
          <Button
            text="View my public profile"
            height="48px"
            onClick={() => this.setState({ drawerOpen: true })}
            color={["#a4c772", "#4e8400"]}
          />
        </div>
        <DrawerComponent
          open={this.state.drawerOpen}
          onClose={() => this.setState({ drawerOpen: false })}
          component={<EmployeeDetail history={this.props.history} />}
          width="90%"
        />
        <div style={{ marginTop: 40, marginBottom: 70 }}>
          <Card style={{ padding: 40 }}>
            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{
                    height: "100px",
                    width: "100px",
                    backgroundImage: `url('${this.state.photo || ""}')`,
                    backgroundSize: "cover",
                    borderRadius: 50,
                    border: "1px solid #1c1c1c",
                  }}
                >
                  <div
                    onClick={() => {
                      this.upload.click();
                    }}
                    style={{
                      display: "inline-block",
                      backgroundColor: "#FFF",
                      border: "1px solid #a4c772",
                      height: 28,
                      width: 28,
                      marginTop: 70,
                      marginLeft: 68,
                      borderRadius: 26,
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CreateOutlinedIcon style={{ color: "#a4c772" }} />
                  </div>
                </div>
              </div>
              <input
                id="myProfile"
                type="file"
                accept=".jpeg,.png,.jpg"
                ref={(ref) => (this.upload = ref)}
                style={{ display: "none" }}
                onChange={this.profileIconUpdate}
              />
              <div style={{ marginLeft: 26 }}>
                <h3
                  style={{
                    margin: 0,
                    fontSize: 22,
                    fontFamily: "Libre Franklin",
                  }}
                >
                  {this.state.firstName} {this.state.lastName}
                </h3>
                <p style={{ fontFamily: "Libre Franklin" }}>
                  {this.state.fieldOfWork && this.state.fieldOfWork.lable}
                </p>
                <p style={{ fontFamily: "Libre Franklin" }}>
                  {this.state.address && this.state.address.state},{" "}
                  {this.state.address && this.state.address.country}
                </p>
                <p style={{ marginTop: 8, fontFamily: "Libre Franklin" }}>
                  Experience:{" "}
                  {parseFloat(this.state.yearOfExperience) >= 20
                    ? "20+"
                    : this.state.yearOfExperience}{" "}
                  years
                </p>
              </div>
            </div>
            <div style={{ marginTop: 26 }}>
              <EditDetailsComponent
                isEmployee
                showBorderBottom={true}
                // label={CONSTANTS.HIGHLIGHTS}
                editFields={highlights}
                content={
                  <p
                    className="paragraph"
                    style={{
                      borderRadius: 10,
                      border: "1.25px solid rgba(0, 0, 0, 0.23)",
                      padding: 14,
                      paddingTop: 8,
                      height: 195,
                      overflow: "hidden",
                      fontFamily: "Libre Franklin",
                    }}
                  >
                    {this.state.highlight}
                  </p>
                }
                isEditButtonClicked={this.state.isHighlightEditable}
                onEditClick={() => {
                  if (this.state.isHighlightEditable) {
                    this.getPreviousData();
                  }
                  this.setState({
                    isHighlightEditable: !this.state.isHighlightEditable,
                  });
                }}
              />
            </div>
          </Card>
        </div>
        <div style={{ width: "70%" }}>
          <div style={{ marginBottom: 50 }}>
            <EditDetailsComponent
              isEmployee
              label={CONSTANTS.FIELD_OF_WORK}
              editFields={editFieldOfWork}
              content={this.state.fieldOfWork?.lable || ""}
              isEditButtonClicked={this.state.isFieldOfWorkEditable}
              onEditClick={() => {
                if (this.state.isFieldOfWorkEditable) {
                  this.getPreviousData();
                }
                if (this.state.skillSet?.length > 0) {
                  this.getSpecialityList(this.state.skillSet[0].id);
                } else {
                  this.getSpecialityList(this.state.specialitiesOptions[0]?._id);
                  this.setState({
                    speciality: this.state.specialitiesOptions[0],
                    specialityDefaultValueDropDown: this.state.specialitiesOptions[0]
                  });
                }
                this.onEditFieldOfWork();
              }}
            />
            {!this.state.isFieldOfWorkEditable && (
              <div style={{ marginTop: "50px" }}>
                <div className="EditDetailsCompHeadingStyles">
                  <div className="editDetailsCompHeading">Specialties & Skills</div>
                  <div style={{ marginTop: "50px" }}></div>
                </div>
                <div>
                  {this.state.skillSet.map(function (set, id) {
                    return (
                      <div
                        key={id}
                        style={{ marginBottom: "5px", marginTop: "8px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            color: "#454545",
                            alignItems: "center",
                          }}
                        >
                          {set.speciality}{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            color: "#454545",
                            marginBottom: "5px",
                            flexWrap: "wrap",
                          }}
                        >
                          {set.skill.map((skill, id) => (
                            <div
                              style={{
                                marginLeft: "5px",
                                marginTop: "5px",
                              }}
                            >
                              <Chip
                                height={"27px"}
                                backgroundColor={"#a4c772"}
                                borderRadius={"8px"}
                                label={skill.name}
                                color={"#ffffff"}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="EditDetailsBottomLineStyles" />
          <div style={{ marginTop: 50 }}>
            <div style={{ width: "100%" }}>
              <div
                className="EditDetailsCompHeadingStyles"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "Libre Franklin",
                }}
              >
                <div className="editDetailsCompContainer">
                  <div className="editDetailsCompHeading">
                    Employment History
                  </div>
                  <IconButton
                    onClick={() =>
                      this.setState({
                        isCompanyEditable: !this.state.isCompanyEditable,
                        companyName: "",
                        position: "",
                        from: "",
                        to: "",
                        highlights: "",
                        companyIndex: -1,
                        currentWorking: false,
                      })
                    }
                    component={"span"}
                    style={{
                      backgroundColor: "rgba(164,199,114,0.64)",
                      marginLeft: "12px",
                      color: "#707070",
                    }}
                  >
                    <Add style={{ color: "#fff" }} />
                  </IconButton>
                </div>
                {this.state.isCompanyEditable && (
                  <div
                    onClick={() =>
                      this.setState({
                        isCompanyEditable: false,
                      })
                    }
                    style={{ color: "#c5dba5" }}
                  >
                    <CloseIcon style={{ color: "#c5dba5" }} />
                  </div>
                )}
              </div>
              {this.state.isCompanyEditable ? (
                editEmployeementHistory
              ) : (
                <div className="EditDetailsCompDetailsStyles">
                  <div style={{ width: "100%" }}>
                    {employeementHistoryContent}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: 50 }}>
            <EditDetailsComponent
              isEmployee
              label={"Years of experience"}
              editFields={editYearOfExperience}
              content={
                <p className="paragraph">{this.state.yearOfExperience}</p>
              }
              isEditButtonClicked={this.state.isYearOfExperienceEditable}
              onEditClick={() => {
                if (this.state.isYearOfExperienceEditable) {
                  this.getPreviousData();
                }
                this.setState({
                  isYearOfExperienceEditable:
                    !this.state.isYearOfExperienceEditable,
                });
              }}
            />
          </div>
          <div className="EditDetailsBottomLineStyles" />
          <div style={{ marginTop: "40px" }}>
            <div style={{ width: "100%" }}>
              <div
                className="EditDetailsCompHeadingStyles"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="editDetailsCompContainer">
                  <div className="editDetailsCompHeading">Education</div>
                  <IconButton
                    onClick={() =>
                      this.setState({
                        isEducationEditable: !this.state.isEducationEditable,
                        educationIndex: -1,
                        schoolName: "",
                        major: "",
                        degree: "",
                        fromEducation: null,
                        toEducation: null,
                      })
                    }
                    component={"span"}
                    style={{
                      backgroundColor: "rgba(164,199,114,0.64)",
                      marginLeft: "12px",
                      color: "#707070",
                    }}
                  >
                    <Add style={{ color: "#fff" }} />
                  </IconButton>
                </div>

                {this.state.isEducationEditable && (
                  <div
                    onClick={() =>
                      this.setState({
                        isEducationEditable: false,
                      })
                    }
                    style={{ color: "#c5dba5" }}
                  >
                    <CloseIcon style={{ color: "#c5dba5" }} />
                  </div>
                )}
              </div>
              {this.state.isEducationEditable ? (
                <div style={{ marginBottom: "50px" }}>
                  {editEmployeementEducation}
                </div>
              ) : (
                <div className="EditDetailsCompDetailsStyles">
                  <div style={{ width: "100%" }}>
                    {employeementEducationContent}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div style={{ marginTop: "20px" }}>
              <div
                className="EditDetailsCompHeadingStyles"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="editDetailsCompContainer">
                  <div className="editDetailsCompHeading">Certifications</div>
                  <IconButton
                    onClick={() =>
                      this.setState({
                        isCertificateEditable:
                          !this.state.isCertificateEditable,
                        certificateIndex: -1,
                        certificate: "",
                        yearIssued: moment(new Date()).format("YYYY"),
                      })
                    }
                    component={"span"}
                    style={{
                      backgroundColor: "rgba(164,199,114,0.64)",
                      marginLeft: "12px",
                      color: "#707070",
                    }}
                  >
                    <Add style={{ color: "#fff" }} />
                  </IconButton>
                </div>

                {this.state.isCertificateEditable && (
                  <div
                    onClick={() =>
                      this.setState({
                        isCertificateEditable: false,
                      })
                    }
                    style={{ color: "#c5dba5" }}
                  >
                    <CloseIcon style={{ color: "#c5dba5" }} />
                  </div>
                )}
              </div>
              {this.state.isCertificateEditable ? (
                <div style={{ marginBottom: "50px" }}>{editCertification}</div>
              ) : (
                <div className="EditDetailsCompDetailsStyles">
                  <div style={{ width: "100%" }}>{certificationContent}</div>
                </div>
              )}
            </div>
            <div
              className="EditDetailsBottomLineStyles"
              style={{ marginBottom: 33 }}
            />
            <div style={{ marginTop: "30px", marginBottom: 40 }}>
              <EditDetailsComponent
                isEmployee
                label={"Languages"}
                editFields={editLanguages}
                content={
                  <p className="paragraph">
                    {this.state.selectedLanguages
                      .map((lang) => lang)
                      .join(",  ")}
                  </p>
                }
                isEditButtonClicked={this.state.isLanguageEditable}
                onEditClick={() => {
                  if (this.state.isLanguageEditable) {
                    this.getPreviousData();
                  }
                  this.onEditLanguages();
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="EditDetailsBottomLineStyles"
          style={{ marginBottom: 33 }}
        />
        <div style={{ marginTop: "30px" }}>
          <div style={{ width: "100%" }}>
            <div
              className="EditDetailsCompHeadingStyles"
              style={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <div className="editDetailsCompHeading">Portfolio</div>
            </div>
            <Card style={{ height: 445, marginTop: 26 }}>
              <div className="row" style={{ flexFlow: "wrap" }}>
                {this.state.portfolios.map((item, index) => (
                  <PortFolioCard
                    onFileUpload={(value) => this.handlePortFolio(value, index)}
                    item={item}
                    onDeletePortfolio={() => this.onDeletePortfolio(index)}
                  />
                ))}
              </div>
            </Card>
          </div>
        </div>
        <div
          className="EditDetailsBottomLineStyles"
          style={{ marginBottom: 33 }}
        />
        <div style={{ width: "70%" }}>
          <div style={{ marginTop: "30px", marginBottom: 60 }}>
            <EditDetailsComponent
              isEmployee
              label={"Linkedin (optional)"}
              editFields={editLinkedIn}
              content={<p className="paragraph">{this.state.linkedIn}</p>}
              isEditButtonClicked={this.state.isLinkedInEditable}
              onEditClick={() => {
                if (this.state.isLinkedInEditable) {
                  this.setState({ linkedIn: this.state.oldlinkedIn });
                }
                this.setState({
                  isLinkedInEditable: !this.state.isLinkedInEditable,
                });
              }}
            />
          </div>
        </div>
        {this.state.showDeleteConfirmationModel ? (
          <div id="overlay">
            <div
              style={{
                position: "relative",
                top: "12vw",
                left: "85%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div
                style={{
                  width: "35vw",
                  height: "15vw",
                  backgroundColor: "white",
                  position: "absolute",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  Are you sure want to delete this item?
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5vh",
                  }}
                >
                  <div style={{ marginRight: "2vw" }}>
                    <Button
                      text="Yes"
                      width={"80px"}
                      height="45px"
                      onClick={() => {
                        this.setState({
                          showDeleteConfirmationModel: false,
                        });
                        if (
                          !this.state.isCompanyEditable &&
                          this.state.employmentDeleteIndex !== null
                        ) {
                          this.employeeHistoryDelete(
                            this.state.employmentDeleteIndex
                          );
                        }
                        if (
                          !this.state.isCertificateEditable &&
                          this.state.certificationsDeleteIndex !== null
                        ) {
                          this.certificateDelete(
                            this.state.certificationsDeleteIndex
                          );
                        }
                        if (
                          !this.state.isEducationEditable &&
                          this.state.educationDeleteIndex !== null
                        ) {
                          this.educationDelete(this.state.educationDeleteIndex);
                        }
                        this.setState({
                          employmentDeleteIndex: null,
                          certificationsDeleteIndex: null,
                          educationDeleteIndex: null,
                        });
                      }}
                      variant="outline"
                      color={["#a4c772"]}
                    />
                  </div>
                  <Button
                    text="No"
                    width={"80px"}
                    height="45px"
                    onClick={() => {
                      this.setState({ showDeleteConfirmationModel: false });
                    }}
                    color={["#a4c772", "#4e8400"]}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  onDeletePortfolio = (index) => {
    const newPortfolio = [...this.state.portfolios];
    newPortfolio.splice(index, 1);
    const data = newPortfolio.filter(
      (p) => p.file_name !== "" && p.file_name_original !== ""
    );

    const request = {
      portfolio: data,
      user_id: localStorage.getItem("userId"),
    };

    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.putMethod(API.EDIT_PORTFOLIO, request, headers).then(
      (response) => {
        let tempRes = [];
        for (let index = 0; index < this.state.portfolios.length; index++) {
          tempRes.push({
            file_name: response.portfolio[index]?.file_name ?? "",
            file_name_original: response.portfolio[index]?.file_name_original ?? "",
            file_url: response.portfolio[index]?.file_url ?? "",
            file_type: response.portfolio[index]?.file_type ?? ""
          });
        }
        this.setState({ portfolios: tempRes });
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Portfolio deleted successfully.",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      }
    );
  };

  handlePortFolio = (value, item) => {
    const newPortfolio = [...this.state.portfolios];
    newPortfolio[item] = value;
    this.setState(
      {
        portfolios: newPortfolio,
      },
      () => {
        const data = this.state.portfolios.filter(
          (p) => p.file_name !== "" && p.file_name_original !== ""
        );

        const request = {
          portfolio: data,
          user_id: localStorage.getItem("userId"),
        };

        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        APIHelper.putMethod(API.EDIT_PORTFOLIO, request, headers).then(
          (response) => {
            let tempRes = [];
            for (let index = 0; index < this.state.portfolios.length; index++) {
              tempRes.push({
                file_name: response.portfolio[index]?.file_name ?? "",
                file_name_original: response.portfolio[index]?.file_name_original ?? "",
                file_url: response.portfolio[index]?.file_url ?? "",
                file_type: response.portfolio[index]?.file_type ?? ""
              });
            }
            this.setState({ portfolios: tempRes });
            Swal.fire({
              customClass: "Alert-Class",
              title: "Success!",
              text: "Portfolio saved successfully.",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#1876D2",
            });
          });
      }
    );
  };

  decideCardToDisplay = (card) => {
    switch (card) {
      case cardType.UPDATE_PASSWORD:
        this.setState({
          cardTitle: "Update password",
          cardNumber: 0,
        });
        break;

      case cardType.UPDATE_EMAIL:
        this.setState({
          cardTitle: "Update Email",
          cardNumber: 1,
        });
        break;

      case cardType.VERIFY_EMAIL:
        this.setState({
          cardTitle:
            "You haven’t verified your email yet, please enter verification code",
          cardNumber: 2,
        });
        break;

      case cardType.UPDATE_MOBILE:
        this.setState({
          cardTitle: "Verify Mobile Number",
          cardNumber: 3,
        });
        break;

      case cardType.VERIFY_MOBILE:
        this.setState({
          cardTitle: "Mobile Number Verification",
          cardNumber: 4,
        });
        break;

      default:
      // code block
    }
  };

  render() {
    const accountDetailPages = [
      this.accountInformationDetails(),
      this.passwordSecurityDetails(),
      this.myRateDetails(),
      this.idVerificationDetails(),
      this.notificationSettingDetails(),
      this.paymentMethodDetails(),
      this.setUpPageDetails(),
    ];

    const profileUpdateCards = [
      this.updatePasswordCard(),
      this.updateEmail(),
      this.verifyMobile(false),
      this.updateMobile(),
      this.verifyMobile(true),
    ];

    return (
      <div style={{ display: "flex" }}>
        {this.state.isCardDisplayed && (
          <div id="overlay">
            <div
              style={{ left: "75%", top: "10vw" }}
              className="overlayImageStyle"
            >
              <div
                style={{
                  width: "47.08vw",
                  height: "26.041vw",
                  backgroundColor: "white",
                  position: "absolute",
                  borderRadius: "10px",
                }}
              >
                <div className="overlayCardTitle">
                  <div style={{ paddingTop: "1.6vw", display: "inline-block" }}>
                    {this.state.cardTitle}
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ isCardDisplayed: false });
                    }}
                    style={{
                      paddingTop: "1.6vw",
                      display: "inline-block",
                      float: "right",
                      paddingRight: "1.5vw",
                      pointer: "cursor",
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  {profileUpdateCards[this.state.cardNumber]}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            width: "24.8%",
            minHeight: "79.4vh",
            backgroundColor: "#f2f2f2",
          }}
        >
          <div style={{ marginTop: "5.24vw", marginBottom: 100 }}>
            <div
              onClick={() => this.setState({ page: 0 })}
              style={this.state.page === 0 ? selectedHeadingstyles : {}}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <PersonOutlineOutlinedIcon />
                <p className="StepperHeader">
                  {CONSTANTS.ACCOUNT_INFORMATION_LABEL}
                </p>
              </div>
            </div>
            <div
              onClick={() => this.setState({ page: 1 })}
              style={this.state.page === 1 ? selectedHeadingstyles : {}}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <LockOutlinedIcon />
                <p className="StepperHeader">
                  {CONSTANTS.PASSWORD_AND_SECURITY_LABEL}
                </p>
              </div>
            </div>
            <div
              onClick={() => this.setState({ page: 2 })}
              style={this.state.page === 2 ? selectedHeadingstyles : {}}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <MonetizationOnOutlinedIcon />
                <p className="StepperHeader">{CONSTANTS.MY_RATE}</p>
              </div>
            </div>
            <div
              // onClick={() => this.setState({ page: 3 })}
              // style={this.state.page === 3 ? selectedHeadingstyles : {}}
              style={{ cursor: "not-allowed" }}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <VerifiedUserOutlinedIcon />
                <p className="StepperHeader">
                  {CONSTANTS.ID_VERIFICATION_LABEL}
                </p>
              </div>
            </div>
            <div
              onClick={() => this.setState({ page: 4 })}
              style={this.state.page === 4 ? selectedHeadingstyles : {}}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <NotificationsNoneOutlinedIcon />
                <p className="StepperHeader">
                  {CONSTANTS.NOTIFICATION_SETTINGS_LABEL}
                </p>
              </div>
            </div>
            <div
              onClick={() => this.setState({ page: 5 })}
              style={this.state.page === 5 ? selectedHeadingstyles : {}}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <AccountBalanceOutlinedIcon />
                <p className="StepperHeader">
                  {CONSTANTS.DEPOSIT_METHOD_LABEL}
                </p>
              </div>
            </div>
            <div
              onClick={() => this.setState({ page: 6 })}
              style={this.state.page === 6 ? selectedHeadingstyles : {}}
              className="defaultStepperLabelStyles"
            >
              <div className="defaultStepperInternalHeading">
                <BusinessCenterOutlinedIcon />
                <p className="StepperHeader">{CONSTANTS.MY_PROFILE}</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "75.2%" }}>
          {this.state.page === 6 || this.state.page === 4 ? (
            <div
              style={{ width: "72%", marginLeft: "14.12%", marginTop: "6.9vw" }}
            >
              {accountDetailPages[this.state.page]}
            </div>
          ) : this.state.page === 5 ? (
            <div
              style={{ width: "60%", marginLeft: "14.12%", marginTop: "6.9vw" }}
            >
              {accountDetailPages[this.state.page]}
            </div>
          ) : (
            <div
              style={{ width: "50%", marginLeft: "14.12%", marginTop: "6.9vw" }}
            >
              {accountDetailPages[this.state.page]}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    dataFieldOfWorkDropdown: state.fieldOfWorkReducer.data,
    dataSpecialityDropdown: state.specialityReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFieldOfWork: () => {
      dispatch(getFieldOfWork());
    },
    getSpecialityList: (value) => {
      dispatch(getSpecialityList(value));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeAccountDetails);

export const PortFolioCard = (props) => {
  const inputFile = React.useRef(null);
  const onButtonClick = () => {
    inputFile && inputFile.current && inputFile.current.click();
  };

  const onFileUploadClicked = async (event) => {
    const realName = event.target.files[0].name;
    const fileType = getFileExtension(realName);
    const file = event.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append('files', file, file.name);
    bodyFormData.append('userId', localStorage.getItem("userId"));
    bodyFormData.append('type', 'portfolio');
    let headersFile = {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    const response = await APIHelper.postMethod(API.EMPLOYEE_UPLOAD_FILES, bodyFormData, headersFile);
    const fileName = response.data.length > 0 ? response.data[0] : '';
    props.onFileUpload({
      file_type: fileType,
      file_name_original: realName,
      file_name: fileName,
      file_url: '',
    });
  };

  return (
    <div className="col-md-4">
      <Card
        style={{
          height: 175,
          margin: 10,
          cursor:
            !props.item.file_name_original && !props.isPublicView
              ? "pointer"
              : "none",
          padding: 20,
          textAlign: "center",
        }}
        onClick={() =>
          props.item.file_name_original && props.item.file_url
            ? null
            : onButtonClick()
        }
      >
        {props.item.file_name_original && (
          <img src={`${props.item.file_url}`} style={{ height: "90%" }} />
        )}
        {props.item.file_name_original && !props.isDisplay && (
          <div>
            <EditOutlinedIcon
              style={{
                color: "#a4c772",
                height: 24,
                cursor: "pointer",
              }}
              onClick={() => onButtonClick()}
            />
            <DeleteOutlineOutlinedIcon
              style={{
                color: "#a4c772",
                height: 24,
                marginLeft: 10,
                cursor: "pointer",
              }}
              onClick={() => props.onDeletePortfolio()}
            />
          </div>
        )}
      </Card>
      {!props.isPublicView && (
        <input
          style={{ display: "none" }}
          accept=".jpeg,.png,.jpg"
          ref={inputFile}
          onChange={(e) => onFileUploadClicked(e)}
          type="file"
        />
      )}
    </div>
  );
};
