import React from 'react';
import { useEffect, useState, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

export default function CentralizeChipsSelection(props) {
    const [chipsListData, setChipsList] = useState([]);

    useEffect(() => {
        if (props.selectedChipList !== undefined && props.selectedChipList.length > 0) {
            setChipsList(props.selectedChipList);
        } else {
            setChipsList(props.chipsList);
        }
    }, [props.chipsList, props.selectedChipList]);

    const updateChips = (label) => {
        const result = chipsListData.map((item) => {
            if (item.label === label) {
                const updatedItem = {
                    ...item,
                    isSelected: !item.isSelected,
                };

                return updatedItem;
            }
            return item;
        });

        if (props.onSelectedValueCallback !== undefined) {
            props.onSelectedValueCallback(result);
        }

        setChipsList(result);
    };

    const deleteChips = (index) => {
        const newChips = [...chipsListData];
        newChips.splice(index, 1);
        setChipsList(newChips);
    };

    return (
        <div className="Centralise-Chips-Selected-List-Parent">
            {chipsListData.map((element, index) => (
                <>
                    {index < 8 && (
                        <div
                            key={index}
                            className={
                                element.isSelected
                                    ? 'Centralise-Chips-Parent-Selected'
                                    : 'Centralise-Chips-Parent'
                            }
                        >
                            {/* <Tooltip title={element.label} aria-label={element.label} arrow> */}
                            <Chip
                                label={element.label}
                                variant={element.isSelected ? 'outlined' : 'outlined'}
                                style={{ width: 155 }}
                                onDelete={() => {
                                    deleteChips(index);
                                }}
                                clickable={true}
                                onClick={() => updateChips(element.label)}
                            />
                            {/* </Tooltip> */}
                        </div>
                    )}
                </>
            ))}
        </div>
    );
}
