import React from 'react'
import './Footer.scss'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useHistory } from "react-router-dom";
import CONSTANTS from '../../utils/constants'
import EmpolinkLogo from '../../Assets/images/logo.svg';


const Footer = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            color: "#ffffff"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        toolbar: {
            height: "6.77vw",
            backgroundColor: "#233975",
            color: "#ffffff"
        },
    }));

    const history = useHistory();

    const classes = useStyles();

    const footer = (
        <footer class="page-footer">
            <div class="top-footer">
                <div class="container">
                    <div class="footer-wrapper">
                        <div class="fst-col">
                            <div class="footer-logo">
                                <a href="/">
                                    <img src={EmpolinkLogo} alt="Empolink" />
                                </a>
                            </div>
                            <ul>
                                <li>
                                    <a href="https://www.linkedin.com/company/empolink" target="_blank">
                                        <i class="fa-brands fa-linkedin"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="/" target="_blank">
                                        <i class="fa-brands fa-x-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="/" target="_blank">
                                        <i class="fa-brands fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="/" target="_blank">
                                        <i class="fa-brands fa-youtube"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div></div>
                        {/*<div class="quick-link">
                            <h3>About</h3>
                            <ul>
                                <li>
                                    <div className='link' onClick={() => history.push("/help")}>About Us</div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => history.push("/help")}>How it works</div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => history.push("/help")}>Contact Us</div>
                                </li>
                            </ul>
                        </div>*/}
                        <div class="quick-link">
                            <h3>Support</h3>
                            <ul>
                                <li>
                                    <div className='link' onClick={() => window.open("https://forum.empolink.com", "_blank")}>Forum</div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => history.push("/help")}>Help & Support</div>
                                </li>
                                <li>
                                    <div className='link' onClick={() => history.push("/Pricing")}>Fees</div>
                                </li>
                            </ul>
                        </div>
                        <div class="quick-link">
                            <h3>Legal</h3>
                            <ul>
                                <li>
                                    <a target="_blank" href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Privacy+Policy.pdf">Privacy Policy</a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Terms+of+Service.pdf">Terms & Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="container">© 2024 Empowering Link Technologies Inc.</div>
            </div>
        </footer>
    )

    return (
        <div>
            {CONSTANTS.VISIBLE_HEADER_FOOTER_LIST.indexOf(history.location.pathname) > -1 ? footer : null}
        </div>
    )

}

export default Footer;
