import React, { Component } from "react";
import "./EmployeeRegister.scss";
import TextBox from "../../Components/TextField/Textfield";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import { withRouter } from "react-router-dom";
import CheckboxComponent from "../../Components/CheckboxComponent/CheckboxComponent";
import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import SignUpLogo from '../../Assets/images/signup-img.png';

const GreenSwitch = withStyles({
  switchBase: {
    color: "#a4c772",
    "&$checked": {
      color: "#a4c772",
    },
    "&$checked + $track": {
      backgroundColor: "#a4c772",
    },
  },
  checked: {},
  track: {},
})(Switch);

class EmployeeRegisterPageSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myRate: "",
      myRateError: false,
      isStudent: false,
      workInternationally: true,
      internationalRate: "",
      termsAndConditions: false,
      notificationEmail: false,
      notificationText: false,
      submitted: false,
      myLessRateError: false,
      internationalRateError: false,
      termsAndConditionsText: ["I agree with the ", <a style={{ color: "#9a9a9a" }} target="_blank" href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Terms+of+Service.pdf"> Terms and Conditions</a>, " and ", <a style={{ color: "#9a9a9a" }} target="_blank" href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Privacy+Policy.pdf">Privacy Policy.</a>]
    };
  }
  onSubmitClicked() {
    this.props.handleRegisterData({
      myRate: this.state.myRate,
      isStudent: this.state.isStudent,
      workInternationally: this.state.workInternationally,
      internationalRate: this.state.internationalRate,
      termsAndConditions: this.state.termsAndConditions,
      notificationEmail: this.state.notificationEmail,
      notificationText: this.state.notificationText,
    });
    this.setState({ submitted: true });
  }
  onNextClicked() {
    if (this.state.myRate === "") {
      this.setState({ myRateError: true });
    }
    else if (this.state.workInternationally && this.state.internationalRate === "") {
      this.setState({ internationalRateError: true });
    }
    else if (!this.state.myLessRateError && !this.state.myRateError &&
      !this.state.internationalRateError) {
      this.props.registerAccount();
    }
  }


  render() {
    return (
      <>
        <div className="login-wrapper-step sign-up-body signUp-1">
          <div className="container">
            <div className="row row-center">
              <div className="col-md-4">
                <div className="login-img">
                  <a className="login-logo" href={void (0)} style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push("/") }}>
                    <img src={EmpolinkLogo} alt="Empolink" />
                  </a>
                </div>
              </div>
              <div className="col-md-8">
                <div className="login-right">
                  <div>
                    <div>
                      <h1 className="signUpHeading">Sign Up</h1>
                      <div
                        className="signUpSubHeading"
                        style={{ paddingBottom: "26px" }}
                      >
                        Personal Information
                      </div>
                    </div>
                    <div style={{ width: "100%", height: "100%" }}>
                      <p
                        style={{
                          color: "#9a9a9a",
                          marginLeft: "5px",
                          fontSize: "20px",
                          margin: "0",
                          paddingBottom: "18px",
                        }}
                      >
                        Specify below your desired hourly rate in local currency
                      </p>
                      <div style={{ paddingBottom: "18px" }}>
                        <TextBox
                          required={true}
                          type="number"
                          label={"My hourly rate (Min. 5)"}
                          onChange={(event) => {
                            this.setState({
                              myRate: event.target.value,
                            });
                            if (parseFloat(event.target.value) < 5) {
                              this.setState({
                                myLessRateError: true,
                                myRateError: true,
                              });
                            }
                            else {
                              this.setState({
                                myLessRateError: false,
                                myRateError: false,
                              });
                            }
                            // this.props.handleRegisterData(this.state)
                          }}
                          value={this.state.myRate}
                          error={this.state.myRateError || this.state.myLessRateError}
                          width={"100%"}
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          paddingBottom: "18px",
                          width: "100%",
                        }}
                      >
                        {/* <CheckboxComponent
                      checked={this.state.isStudent}
                      onChange={() => {
                        this.setState({ isStudent: !this.state.isStudent });
                        // this.props.handleRegisterData(this.state)
                      }}
                      name={"Is Student"}
                      label={"I am a student"}
                    /> */}
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          paddingBottom: "18px",
                          width: "100%",
                        }}
                      >
                        <CheckboxComponent
                          checked={this.state.workInternationally}
                          onChange={() => {
                            this.setState({
                              workInternationally: !this.state.workInternationally,
                              internationalRate: "",
                              internationalRateError: false
                            });
                            // this.props.handleRegisterData(this.state)
                          }}
                          name={"Work Intentional"}
                          label={"I accept international remote working"}
                          color="#a4c772"
                        />
                      </div>
                      <div style={{ paddingBottom: "68px" }}>
                        <TextBox
                          required={this.state.workInternationally}
                          disabled={!this.state.workInternationally}
                          type="number"
                          label={"My international rate in USD (Min. 5)"}
                          onChange={(event) => {
                            if (event.target.value >= 0) {
                              if (event.target.value < 5) {
                                this.setState({
                                  internationalRate: event.target.value,
                                  internationalRateError: true
                                });
                              }
                              else {
                                this.setState({
                                  internationalRate: event.target.value,
                                  internationalRateError: false
                                });
                              }
                            }
                            // this.props.handleRegisterData(this.state)
                          }}
                          value={this.state.internationalRate}
                          width={"100%"}
                          error={this.state.internationalRateError}
                        />
                      </div>
                      {/* <p
                    style={{
                      color: "#9a9a9a",
                      marginLeft: "5px",
                      fontSize: "20px",
                      margin: "0",
                      paddingBottom: "18px",
                    }}
                  >
                    How would you like to receive notifications?
                  </p> */}
                      {/* <div style={{ paddingBottom: "18px" }}>
                    <FormControlLabel
                      className="custom__switch"
                      control={
                        <GreenSwitch
                          // checked={this.state.notificationEmail}
                          checked={true}
                          onChange={() => {
                            this.setState({
                              notificationEmail: !this.state.notificationEmail,
                            });
                            // this.props.handleRegisterData(this.state)
                          }}
                        />
                      }
                      labelPlacement="start"
                      style={{
                        margin: "0",
                        color: "#707070",
                        fontSize: "20px",
                      }}
                      label="Email"
                    />
                  </div> */}
                      {/* <div style={{ paddingBottom: "18px" }}>
                    <FormControlLabel
                      className="custom__switch"
                      control={
                        <GreenSwitch
                          // checked={this.state.notificationText}
                          checked={false}
                          onChange={() => {
                            this.setState({
                              notificationText: !this.state.notificationText,
                            });
                            // this.props.handleRegisterData(this.state)
                          }}
                        />
                      }
                      labelPlacement="start"
                      style={{
                        margin: "0",
                        color: "#707070",
                        fontSize: "20px",
                      }}
                      label="Text message"
                    />
                  </div> */}

                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "18px",
                          justifyContent: "center",
                        }}
                      >
                        <CheckboxComponent
                          checked={this.state.termsAndConditions}
                          onChange={() => {
                            this.setState({
                              termsAndConditions: !this.state.termsAndConditions,
                            });
                            this.props.handleRegisterData(this.state);
                          }}
                          name={"Is Student"}
                          // label={"I agree with the terms and conditions"}
                          label={this.state.termsAndConditionsText}
                          color="#a4c772"
                        />
                      </div>
                    </div>
                    <div className="signUp-btn">
                      <Button
                        text={"Agree and join"}
                        width={"100%"}
                        height="56px"
                        disabled={!this.state.termsAndConditions}
                        onClick={() => this.onNextClicked()}
                        color={["#a4c772", "#4e8400"]}
                        loading={this.props.registerLoading}
                      />
                    </div>
                    <h6 style={{ textAlign: "center", color: "red" }}>
                      {this.props.registerError && this.props.registerError}
                    </h6>
                    <div style={{ textAlign: "center", paddingTop: "47px" }}>
                      <Stepper steps={5} activeStep={4} color={"#a4c772"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EmployeeRegisterPageSix);
