import React from "react";
import moment from "moment";

import "../HiringProcess.scss";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import Pagination from "@material-ui/lab/Pagination";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DrawerComponent from "../../../Components/Drawer/Drawer";
import FixedContract from "./Component/FixedContract";
import ContractRequest from "./Component/ContractRequest";
import JobDescription from "./Component/JobDescription";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import Dropdown from "../../../Components/DropdownComponent/Dropdown.component";
import MileStone from "./MileStone";
import { APIHelper } from "../../../utils/ApiHelper";
import API from "../../../utils/Urls";
import CancelApplicationFormFixed from "./Component/CancelApplicationFormFixed";
import Swal from "sweetalert2";

const FixedFeeJobs = (props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [jobLoading, setJobLoading] = React.useState(false);
  const [jobData, setJobData] = React.useState({});
  const [page, setPage] = React.useState("JobAndPost");
  const [pageCount, setPageCount] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [sortBy, setSortBy] = React.useState({ label: "Start Date", value: "startDate" });
  const [milestone, setMilestone] = React.useState([]);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [contractId, setContractId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [totalData, setTotalData] = React.useState(0);
  const [employeeName, setEmployeeName] = React.useState("");
  const [isJobDetails, setJobDetails] = React.useState(false);
  const [jobTitle, setJobTitle] = React.useState("");

  React.useEffect(() => {
    getListFixedJobs();
  }, [pageCount, sortBy]);

  const getListFixedJobs = () => {
    setLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(API.GET_EMPLOYER_FIXED_ONGOING_JOB + `${pageCount}&sort=${sortBy ? sortBy.value : ""}`, headers)
      .then((res) => {
        setData(res[0].data);
        setTotalData(res[0].total);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const jobDetails = (id) => {
    setDrawerOpen(true);
    setJobLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
      .then((response) => {
        setJobData(response.job);
        setJobLoading(false);
      })
      .catch((err) => {
        setJobLoading(false);
      });
  };

  return (
    <>
      <>
        <div className="editDetailsContentHeading">Fixed Fee Jobs</div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <p className="SortByDropdown">Sort by:</p>
          <div style={{ width: "170px" }}>
            <Dropdown
              placeholder="Select sorting"
              options={[
                { label: "Start Date", value: "startDate" },
                { label: "Job Title", value: "jobTitle" },
              ]}
              value={sortBy}
              onChange={(e) => {
                setPageCount(1);
                setSortBy(e);
              }}
            />
          </div>
        </div>
        <table
          style={{
            width: "100%",
            textAlign: "left",
            borderCollapse: "collapse",
            fontFamily: "Libre Franklin",
          }}
        >
          <thead>
            <tr style={{ borderBottom: "2px solid #C4C4C4", height: 60 }}>
              <th style={{ width: "5%" }}></th>
              <th style={{ width: "30%", color: "#1c1c1c" }}>Job Title</th>
              <th style={{ width: "15%", color: "#1c1c1c" }}>Start date</th>
              <th style={{ width: "15%", color: "#1c1c1c" }}>End date</th>
              <th style={{ width: "25%", color: "#1c1c1c" }}>Status</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          {loading ? (
            <tr style={{ textAlign: "center", height: 100 }}>
              <td colSpan="6">
                <CircularProgress size={40} />
              </td>
            </tr>
          ) : null}
          {!loading && (
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <>
                    <DataRow
                      title={item._id.job && item._id.job.length > 0 && item._id.job[0].title}
                      startDate={
                        item._id.job && item._id.job.length > 0 && moment(item._id.job[0].startDate).utc(0).format("MMM DD, YY")
                      }
                      endDate={
                        item._id.job && item._id.job.length > 0 && moment(item._id.job[0].endDate).utc(0).format("MMM DD, YY")
                      }
                      key={index}
                      subData={item.contract}
                      setPage={setPage}
                      setMilestone={setMilestone}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      setContractId={setContractId}
                      getListFixedJobs={getListFixedJobs}
                      jobDetails={() => {
                        setJobDetails(true);
                        jobDetails(item._id.job[0]._id);
                      }}
                      cancelBy={item.cancelBy}
                      setEmployeeName={setEmployeeName}
                      setJobTitle={setJobTitle}
                      status={item.contract && item.contract.length > 0 && item.contract[0].status}
                    />
                  </>
                ))
              ) : (
                <tr style={{ textAlign: "center", height: 100 }}>
                  <td colSpan="6">
                    <p>No data found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
        {!loading && data.length > 0 && (
          <div className="advancedSearchPagination" style={{ marginTop: 80 }}>
            <Pagination
              count={Math.ceil(totalData / 10)}
              page={pageCount}
              onChange={(e, value) => setPageCount(value)}
              style={{ justifyContent: "center" }}
              size="large"
            />
          </div>
        )}
      </>
      <DrawerComponent
        open={page === "MileStone"}
        onClose={() => setPage("JobAndPost")}
        component={
          <div style={{ padding: 100, paddingTop: 70, fontFamily: "Libre Franklin" }}>
            <MileStone
              data={milestone}
              startDate={startDate}
              endDate={endDate}
              contractId={contractId}
              backButton={() => setPage("JobAndPost")}
              getListFixedJobs={getListFixedJobs}
              employeeName={employeeName}
              jobTitle={jobTitle}
            />
          </div>
        }
        width="80%"
      />
      <DrawerComponent
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        component={<JobDescription jobData={jobData} jobLoading={jobLoading} />}
        width="80%"
      />
    </>
  );
};

const DataRow = (props) => {
  const pageIndex = 2;
  const [open, setOpen] = React.useState(false);
  const [itemShow, setItemShow] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [drawerPage, setDrawerPage] = React.useState("contractCancel");
  const [cancelReason, setCancelReason] = React.useState(false);
  const [contractId, setContractID] = React.useState();

  const newArray = React.useMemo(() => {
    return props.subData.slice(0, itemShow * pageIndex);
  }, [itemShow]);

  const isShowMore = React.useMemo(() => {
    return newArray.length !== props.subData.length;
  }, [newArray]);

  const handleOpen = (event, item) => {
    setContractID(item._id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelJob = (id, data) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.putMethod(`${API.CANCEL_CONTRACTS}${id}`, data, headers)
      .then((res) => {
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Job cancel successfully!",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        }).then((result) => {
          if (result) {
            setDrawerOpen(false);
            props.getListFixedJobs();
          }
        });
      })
      .catch((error) => console.log(error));
  };

  const finalCancelJob = (body) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.putMethod(`${API.FINAL_CANCEL_CONTRACTS}${contractId}`, body, headers)
      .then((res) => {
        setDrawerOpen(false);
        props.getListFixedJobs();
      })
      .catch((error) => console.log(error));
  };

  const finalCancelContractJob = (id) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.postMethod(`${API.CONTRACT_NOT_CANCELLED}`, { contract_id: id }, headers)
      .then((res) => {
        setDrawerOpen(false);
        props.getListFixedJobs();
      })
      .catch((error) => console.log(error));
  };

  const getReturnStatus = (status, startDate, endDate, cancelBy) => {
    switch (status) {
      case "COMPLETED":
        return { status: "COMPLETED" };

      case "CANCELLATION_REQUESTED":
        if (cancelBy === "EMPLOYEE") {
          return { status: "CANCELLED_BY" };
        }
        return { status: "CANCELLED" };

      case "CANCELLED":
        return { status: "CANCELLED_FINAL" };

      case "STARTED":
        const isStart = moment(startDate).utc(0) >= moment();
        const isEnd = moment(endDate).utc(0) < moment();

        if (isStart) {
          return { status: "NOT_STARTED" };
        } else if (isEnd) {
          return { status: "COMPLETED" };
        } else {
          return { status: "IN_PROGRESS" };
        }

      default:
        return { status: "IN_PROGRESS" };
    }
  };

  const getJobStatus = (startDate, endDate, status) => {
    const isStart = moment(startDate).utc(0) >= moment();
    const isEnd = moment(endDate).utc(0) < moment();

    if (status === "CANCELLED") {
      return "CANCELLED";
    } else if (isStart) {
      return "NOT_STARTED";
    } else if (isEnd) {
      return { status: "COMPLETED" };
    } else {
      return "IN_PROGRESS";
    }
  };

  return (
    <>
      <tr
        style={{
          height: 120,
          borderBottom: "2px solid #C4C4C4",
          backgroundColor: open ? "#F2F2F2" : "inherit",
        }}
      >
        <td style={{ width: "5%" }}>
          {open ? (
            <RemoveOutlinedIcon
              style={{
                width: "32px",
                height: "32px",
                color: "#A3C771",
                cursor: "pointer",
              }}
              onClick={() => setOpen(!open)}
            />
          ) : (
            <AddOutlinedIcon
              style={{
                width: "32px",
                height: "32px",
                color: "#A3C771",
                cursor: "pointer",
              }}
              onClick={() => setOpen(!open)}
            />
          )}
        </td>
        <td style={{ width: "30%", color: "#1c1c1c" }}>{props.title}</td>
        <td style={{ width: "15%", color: "#1c1c1c" }}>{props.startDate}</td>
        <td style={{ width: "15%", color: "#1c1c1c" }}>{props.endDate}</td>
        <td style={{ width: "25%", color: "#1c1c1c" }}>
          <ProgressBar type="FIXED" variant={getJobStatus(props.startDate, props.endDate, props.status)} />
        </td>
        <td style={{ width: "10%", textAlign: "right", paddingRight: 15, color: "#1c1c1c" }}>
          {/* <VisibilityOutlinedIcon
                        style={{
                            width: '32px',
                            height: '32px',
                            background: '#1876D2',
                            color: '#FFF',
                            padding: 4,
                            borderRadius: 20,
                        }}
                        onClick={() => props.jobDetails()}
                    /> */}
        </td>
      </tr>
      {open && (
        <>
          {newArray.map((item) => (
            <tr
              style={{
                borderBottom: "2px solid #C4C4C4",
                marginTop: 15,
                marginBottom: 15,
                height: 148,
              }}
            >
              <td style={{ width: "5%" }}></td>
              <td style={{ width: "30%" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    {item.profile && item.profile.length > 0 && item.profile[0].photo ? (
                      <img
                        alt=""
                        src={item.profile && item.profile[0] && item.profile[0].photo}
                        style={{
                          width: 70,
                          height: 70,
                          borderRadius: 70,
                        }}
                      />
                    ) : (
                      <AccountCircleIcon
                        style={{
                          height: "70px",
                          width: "70px",
                        }}
                      />
                    )}
                  </div>
                  <div style={{ marginLeft: 26 }}>
                    <h3 className="fixedFeeEmployeeName">
                      {item.user && item.user.length > 0 && `${item.user[0].firstName} ${item.user[0].lastName}`}
                    </h3>
                    <p className="fixedFeeEmployeeDetails">
                      {item.profile &&
                        item.profile.length > 0 &&
                        `${item.profile[0].address[0].state} ${item.profile[0].address[0].country}`}
                    </p>
                  </div>
                </div>
              </td>
              <td style={{ width: "15%" }}>
                <p>{`$${item.finalFee}`}</p>
              </td>
              <td style={{ width: "15%" }}></td>
              <td style={{ width: "25%" }}>
                <ProgressBar
                  type="HOURLY"
                  variant={getReturnStatus(item.status, item.contractStartedOn, item.jobEndOn, item.cancelBy).status}
                />
              </td>
              <td style={{ width: "10%", textAlign: "right", paddingRight: 15 }}>
                <MoreHorizIcon
                  style={{
                    width: "28px",
                    height: "28px",
                    border: "1px solid #9A9A9A",
                    color: "#9A9A9A",
                    padding: 4,
                    borderRadius: 20,
                  }}
                  onClick={(e) => handleOpen(e, item)}
                />
                {/*TODO status base MenuItem*/}
                <Menu
                  id={`simple-menu-${item._id}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      setDrawerPage("viewContract");
                      setDrawerOpen(true);
                      handleClose();
                    }}
                  >
                    View Contract
                  </MenuItem>

                  {moment(item.jobEndOn).utc(0) > moment() && (
                    <>
                      <MenuItem
                        onClick={() => {
                          setDrawerPage("cancelContract");
                          setDrawerOpen(true);
                          handleClose();
                        }}
                        disabled={item.status !== "STARTED"}
                      >
                        Cancel Job
                      </MenuItem>
                      {item.status !== "CANCELLED" && (
                        <MenuItem
                          onClick={() => {
                            props.setMilestone(item.milestone);
                            props.setStartDate(item.job && item.job.length > 0 && item.job[0].startDate);
                            props.setEndDate(item.job && item.job.length > 0 && item.job[0].endDate);
                            props.setContractId(item._id);
                            props.setPage("MileStone");
                            props.setEmployeeName(
                              item.user && item.user.length > 0 && `${item.user[0].firstName} ${item.user[0].lastName}`
                            );
                            props.setJobTitle(item.job && item.job.length > 0 && item.job[0].title);
                            handleClose();
                          }}
                        >
                          Modify Milestone Date
                        </MenuItem>
                      )}
                      {/* {item.status === 'CANCELLATION_REQUESTED' &&
                                        item.cancelBy === 'EMPLOYEE' && ( */}
                      <MenuItem
                        onClick={() => {
                          setDrawerPage("viewCancellationContract");
                          setDrawerOpen(true);
                          handleClose();
                        }}
                        disabled={
                          (item.status !== "CANCELLATION_REQUESTED" && item.cancelBy !== "EMPLOYEE") ||
                          (item.status === "CANCELLATION_REQUESTED" && item.cancelBy !== "EMPLOYEE") ||
                          item.status === "CANCELLED" ||
                          item.status === "STARTED"
                        }
                      >
                        View Cancellation request
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </td>
            </tr>
          ))}

          {isShowMore && (
            <tr style={{ textAlign: "center", height: 70, marginBottom: 20 }}>
              <td colSpan="6">
                <div
                  style={{
                    height: 52,
                    backgroundColor: "#F2F2F2",
                    lineHeight: "52px",
                  }}
                  onClick={() => setItemShow(itemShow + 1)}
                >
                  <p>Show more results</p>
                </div>
              </td>
            </tr>
          )}
          {console.log(newArray.map((i) => i.jobStartedOn < moment()))}
          <DrawerComponent
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
              setDrawerPage("contractCancel");
            }}
            component={
              <>
                {drawerPage === "viewContract" ? (
                  <FixedContract contractId={contractId} />
                ) : drawerPage === "cancelContract" ? (
                  <ContractRequest
                    page="FIXED"
                    handleClose={() => setDrawerOpen(false)}
                    contractId={contractId}
                    cancelJob={(id, body) => {
                      if (newArray.map((i) => i.jobStartedOn > moment())) {
                        cancelJob(id, body);
                        console.log("cancelJob");
                      } else {
                        console.log("finalCancelJob");
                        finalCancelJob(id);
                      }
                    }}
                    // cancelJob={(id, data) => {
                    //   cancelJob(id, data);
                    // }}
                  />
                ) : (
                  <CancelApplicationFormFixed
                    contractId={contractId}
                    handleClose={() => setDrawerOpen(false)}
                    finalCancelJob={(body) => {
                      finalCancelJob(body);
                    }}
                    finalCancelContractJob={(id) => {
                      finalCancelContractJob(id);
                    }}
                  />
                )}
              </>
            }
            // component={< />}
            width="80%"
          />
        </>
      )}
    </>
  );
};

export default FixedFeeJobs;
