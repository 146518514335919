import {
  INCREMENT, DECREMENT, JOB_TITLE, JOB_DESCRIPTION, ATTACHMENT_URL, REMOVE_ATTACHMENT_LIST, ADDITIONAL_INSTRUCTION, SET_ERRORS,

  FIELD_OF_WORK, SPECIALITY, SUGGESTED_SKILLS, NEW_ADDITIONAL_SKILLS, REMOVE_NEW_ADDITIONAL_SKILLS, EXPERIENCE_RANGE,

  NEXT_BUTTON_ENABLE, ADD_WSCHEDULE_HLIST, UPDATE_WSCHEDULE_HLIST, DATE_REFRESH,

  IS_HOURLY, HOURLY_RATE, FEE_AMOUNT, PAYMENT_METHOD, PAYMENT_TIME, JOB_POST_CLOSE_DATE,
  JOB_START_DATE, JOB_CLOSE_DATE, REFRESH_DATE_FORM_DATA,

  LOCATION_LIST, REMOVE_LOCATION, ADDRESS_TYPE, ADDRESS, RESET, LOADER, CLEAR_SKILLS_SELECTION, CLEAR_CHIPS_SELECTION, HOURLY_RATE_MAX, HOURLY_RATE_MIN, TOTAL_WORKING, DATE_DUPLICATE_SCHEDULE, JOB_POST_ADDRESS, JOB_LOCATIONS
} from '../action/form_action';

import * as moment from 'moment';
import Swal from 'sweetalert2'

function createData(date, start, end, npayable, whours, key, dateObject) {
  return { date, start, end, npayable, whours, key, dateObject };
}

const initialState = {
  screenWidth: 1280,
  selectionValue: 0,
  jobTitle: "",
  jobDescription: "",
  attachmentURL: [],
  additionalUserInstruction: "",
  fieldOfWork: "",
  fieldOfWorkObject: undefined,
  speciality: "",
  specialityObject: undefined,
  suggestedSkills: [],
  additionalSkills: [],
  experienceRange: [5, 15],

  isNextButtonDisable: true,

  refreshData: false,
  isHourly: true,
  feeType: "Hourly $",
  hourlyRate: "",
  hourlyRateMin: "",
  hourlyRateMax: "",
  feeAmount: "",
  paymentMethod: "Directly",
  paymentTime: "Weekly",
  totalWorking: "",

  workScheduleHourlyList: [],
  jobPostCloseDate: "",
  jobStartDate: "",
  jobCloseDate: "",
  jobPostCloseDateFormat: undefined,
  jobStartDateFormat: undefined,
  jobCloseDateFormat: undefined,

  locationList: [],
  addressType: "",
  address: "",
  showLoader: false,
  clearChipsSelection: false,
  error: {},
  isWorkingDataDuplicate: false,
  jobPostLocation: "Within my Country",
  jobLocations: [{
    label: localStorage.getItem('country'),
    value: localStorage.getItem('country'),
    _id: '00000000000000'
  }]
}

function formReducer(state = initialState, action) {
  switch (action.type) {
    case INCREMENT:
      var canMoveNext = false;
      let error = {};

      {
        (() => {
          switch (state.selectionValue) {
            case 0:
              if (state.jobTitle === "") {
                canMoveNext = false;
                error.jobTitle = true;
              } else if (state.jobDescription === "") {
                canMoveNext = false;
                error.jobDescription = true;
              } else {
                canMoveNext = true;
              }
              break;
            case 2:
              if (state.fieldOfWork === "") {
                canMoveNext = false;
                error.fieldOfWork = true;
              } else if (state.speciality === "") {
                canMoveNext = false;
                error.speciality = true;
              } else if (state.additionalSkills.length < 3 &&
                state.suggestedSkills.filter(item => item.isSelected).length < 3) {

                var additionalSkillsLength = state.additionalSkills.length;
                var suggestedSkillsLength = state.suggestedSkills.filter(item => item.isSelected).length;

                var totalLength = additionalSkillsLength + suggestedSkillsLength;

                if (totalLength < 3) {
                  canMoveNext = false;
                  error.additionalSkills = true;
                } else {
                  canMoveNext = true;
                }
              } else {
                canMoveNext = true;
              }
              break;
            case 3:
              if (state.feeType !== "Fixed fee $") {
                if (state.hourlyRateMin === "") {
                  canMoveNext = false;
                  error.hourlyRateMin = true;
                } else if (parseFloat(state.hourlyRateMin) < 5) {
                  canMoveNext = false;
                  error.hourlyRateMin = true;
                } else if (state.hourlyRateMax === "") {
                  canMoveNext = false;
                  error.hourlyRateMax = true;
                } else if (parseFloat(state.hourlyRateMin) > parseFloat(state.hourlyRateMax)) {
                  canMoveNext = false;
                  error.hourlyRateMax = true;
                } else {
                  canMoveNext = true;
                }
              } else if (state.feeAmount === "") {
                canMoveNext = false;
                error.feeAmount = true;
              } else {
                canMoveNext = true;
              }
              break;
            case 4:

              if (!state.isHourly) {
                if (state.jobStartDateFormat === undefined) {
                  canMoveNext = false;
                  error.jobStartDateFormat = true;
                } else if (state.jobCloseDateFormat === undefined) {
                  canMoveNext = false;
                  error.jobCloseDateFormat = true;
                } else if (state.jobPostCloseDateFormat === undefined) {
                  canMoveNext = false;
                  error.jobPostCloseDateFormat = true;
                } else {
                  canMoveNext = true;
                }
              } else {
                if (state.workScheduleHourlyList.length == 0) {
                  canMoveNext = false;
                  error.workScheduleHourlyList = true;
                } else if (state.jobPostCloseDateFormat === undefined) {
                  canMoveNext = false;
                  error.jobPostCloseDateFormat = true;
                } else {
                  canMoveNext = true;
                }
              }

              break;
            case 1:
              if (state.addressType === "") {
                canMoveNext = false;
                error.addressType = true;
              } else if (state.address && !state.address.country) {
                canMoveNext = false;
                error.country = true;
              } else if (state.address && !state.address.state) {
                canMoveNext = false;
                error.state = true;
              } else if (state.address && !state.address.city) {
                canMoveNext = false;
                error.city = true;
              } else if (state.address && !state.address.street_address) {
                canMoveNext = false;
                error.street_address = true;
              } else if (state.address && !state.address.postal_code) {
                canMoveNext = false;
                error.postal_code = true;
              } else {
                canMoveNext = true;
              }
              break;
          }
        })()
      }
      if (canMoveNext) {
        return { ...state, selectionValue: state.selectionValue + 1, error: error }
      } else {
        return { ...state, selectionValue: state.selectionValue, error: error }
      }
    case DECREMENT:
      return { ...state, selectionValue: state.selectionValue - 1 }
    case JOB_TITLE:
      return { ...state, jobTitle: action.jobTitle, error: { ...state.error, jobTitle: false } }
    case JOB_DESCRIPTION:
      return { ...state, jobDescription: action.jobDescription, error: { ...state.error, jobDescription: false } }
    case ATTACHMENT_URL:
      return { ...state, attachmentURL: [...state.attachmentURL, ...action.attachmentURL] }
    case REMOVE_ATTACHMENT_LIST:
      return { ...state, attachmentURL: state.attachmentURL.filter(item => item.name !== action.attachmentURL.name) }

    case ADDITIONAL_INSTRUCTION:
      return { ...state, additionalUserInstruction: action.additionalUserInstruction }

    case SET_ERRORS:
      return { ...state, error: action.error }

    case FIELD_OF_WORK:
      return { ...state, fieldOfWork: action.fieldOfWork, fieldOfWorkObject: action.fieldOfWorkObject, error: { ...state.error, fieldOfWork: false } }

    case SPECIALITY:
      return { ...state, speciality: action.speciality, specialityObject: action.specialityObject, error: { ...state.error, speciality: false } }

    case SUGGESTED_SKILLS:
      return { ...state, suggestedSkills: action.suggestedSkills }

    case NEW_ADDITIONAL_SKILLS:
      return { ...state, additionalSkills: [...state.additionalSkills, action.additionalSkills], error: { ...state.error, additionalSkills: false } }

    case REMOVE_NEW_ADDITIONAL_SKILLS:
      return { ...state, additionalSkills: [...state.additionalSkills.filter(item => item.lable !== action.additionalSkills.lable)] }

    case EXPERIENCE_RANGE:
      return { ...state, experienceRange: action.experienceRange }

    case NEXT_BUTTON_ENABLE:
      {
        switch (action.screenType) {
          case 1:
            if (state.jobTitle.length > 5) {
              return { ...state, isNextButtonDisable: false }
            } else {
              return { ...state, isNextButtonDisable: true }
            }
            break;
          default:
            return { ...state, isNextButtonDisable: true }
        }
      }

    case ADD_WSCHEDULE_HLIST:
      var currentDate;
      var stopDate;
      var dateArray = [];
      var finalArray = [];

      // var startTimeFloat = parseFloat(action.startTime);
      var startTimeSeconds = 0;
      var datas = action.startTime.split(":");
      if (datas.length === 2) {
        startTimeSeconds += (parseInt(datas[0]) * 60 * 60) + (parseInt(datas[1]) * 60);
      }

      var endTimeSeconds = 0;
      var dataEnd = action.endTime.split(":");
      if (dataEnd.length === 2) {
        endTimeSeconds += (parseInt(dataEnd[0]) * 60 * 60) + (parseInt(dataEnd[1]) * 60);
      }
      var diffrenceTime;
      if (startTimeSeconds > endTimeSeconds) {
        diffrenceTime = startTimeSeconds - endTimeSeconds;
      } else {
        diffrenceTime = endTimeSeconds - startTimeSeconds;
      }
      diffrenceTime = diffrenceTime - (action.nPayableTime * 60 * 60);
      var divisor_for_minutes = diffrenceTime % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);

      const diff = `${Math.floor(diffrenceTime / 3600)}:${minutes > 9 ? minutes : `0${minutes}`}`;

      for (var i = 0; i < action.dates.length; i++) {
        const dateObject = createData(moment(action.dates[i].month + "/" + action.dates[i].day + "/" + action.dates[i].year).format('MMM DD,YYYY'), action.startTime, action.endTime, action.nPayableTime,
          diff, (i + state.workScheduleHourlyList.length + 1), action.dates[i])
        finalArray = [...finalArray, dateObject]
      };

      let isValid = true;
      finalArray.forEach((item) => {
        const data = state.workScheduleHourlyList.findIndex((tblData) => tblData.date === item.date && tblData.start === item.start && tblData.end === item.end);
        if (data > -1) {
          isValid = false;
        }
      })
      if (isValid) {
        return { ...state, workScheduleHourlyList: [...state.workScheduleHourlyList, ...finalArray], refreshData: true, isWorkingDataDuplicate: false }
      } else {
        return { ...state, isWorkingDataDuplicate: true }
      }
    case DATE_REFRESH:
      return { ...state, refreshData: false }

    case UPDATE_WSCHEDULE_HLIST:
      return { ...state, workScheduleHourlyList: action.workScheduleHourlyList }

    case TOTAL_WORKING:
      return { ...state, totalWorking: action.totalWorking }

    case DATE_DUPLICATE_SCHEDULE:
      return { ...state, isWorkingDataDuplicate: action.isWorkingDataDuplicate }

    case IS_HOURLY:
      return { ...state, isHourly: action.isHourly, feeType: action.feeType }

    case HOURLY_RATE:
      return { ...state, hourlyRate: action.hourlyRate }

    case HOURLY_RATE_MAX:
      return { ...state, hourlyRateMax: action.hourlyRate, error: { ...state.error, hourlyRateMax: false } }

    case HOURLY_RATE_MIN:
      return { ...state, hourlyRateMin: action.hourlyRate, error: { ...state.error, hourlyRateMin: false } }

    case FEE_AMOUNT:
      return { ...state, feeAmount: action.feeAmount }

    case PAYMENT_METHOD:
      return { ...state, paymentMethod: action.paymentMethod }

    case JOB_POST_ADDRESS:
      return { ...state, jobPostLocation: action.jobPostLoctionValue }

    case JOB_LOCATIONS:
      return { ...state, jobLocations: action.jobLocations }

    case PAYMENT_TIME:
      return { ...state, paymentTime: action.paymentTime }

    case JOB_POST_CLOSE_DATE:
      return { ...state, jobPostCloseDateFormat: action.jobPostCloseDate, jobPostCloseDate: moment(action.jobPostCloseDate.month + "/" + action.jobPostCloseDate.day + "/" + action.jobPostCloseDate.year).format('MMM DD,YYYY'), error: { ...state.error, jobPostCloseDate: false } }

    case JOB_START_DATE:
      return { ...state, jobStartDateFormat: action.jobStartDate, jobStartDate: moment(action.jobStartDate.month + "/" + action.jobStartDate.day + "/" + action.jobStartDate.year).format('MMM DD,YYYY'), error: { ...state.error, jobStartDate: false } }

    case JOB_CLOSE_DATE:
      return { ...state, jobCloseDateFormat: action.jobCloseDate, jobCloseDate: moment(action.jobCloseDate.month + "/" + action.jobCloseDate.day + "/" + action.jobCloseDate.year).format('MMM DD,YYYY'), error: { ...state.error, jobCloseDate: false } }

    case REFRESH_DATE_FORM_DATA:
      return {
        ...state, workScheduleHourlyList: [], jobPostCloseDate: "", jobStartDate: "", jobCloseDate: "",
        jobPostCloseDateFormat: undefined, jobStartDateFormat: undefined, jobCloseDateFormat: undefined
      }

    case LOCATION_LIST:
      return { ...state, locationList: [...state.locationList, action.locationList] }

    case REMOVE_LOCATION:
      return { ...state, locationList: state.locationList.filter(item => item.lable !== action.locationList.lable) }

    case ADDRESS_TYPE:
      return { ...state, addressType: action.addressType, error: { addressType: false } }

    case ADDRESS:
      return { ...state, address: action.address, error: {} }

    case LOADER:
      return { ...state, showLoader: action.showLoader }

    case CLEAR_SKILLS_SELECTION:
      return { ...state, suggestedSkills: [], additionalSkills: [] }

    case CLEAR_CHIPS_SELECTION:
      return { ...state, clearChipsSelection: action.clearChipsSelection }

    case RESET:
      return {
        ...state, selectionValue: 0, jobTitle: "", jobDescription: "",
        attachmentURL: [], additionalUserInstruction: "", fieldOfWork: "", speciality: "",
        suggestedSkills: [], additionalSkills: [], experience: "", isNextButtonDisable: true,
        refreshData: false, isHourly: true, feeType: "Hourly $", hourlyRate: "", feeAmount: "",
        paymentMethod: "Directly", paymentTime: "Weekly", workScheduleHourlyList: [], jobPostCloseDate: "",
        jobStartDate: "", jobCloseDate: "", jobPostCloseDateFormat: undefined, jobStartDateFormat: undefined,
        jobCloseDateFormat: undefined, locationList: [], addressType: "",
        address: "", showLoader: false
      }
    default:
      return state
  }
}
export default formReducer
