import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import API from '../../utils/Urls';
import { APIHelper } from '../../utils/ApiHelper';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import socketIO from 'socket.io-client';
import { Howl } from 'howler';
import './Header.scss';
import HeaderDropdown from '../../Components/MaterialDropdownComponent/HeaderDropdown';
import CONSTANTS from '../../utils/constants';
import { SHOW_UNREAD_MESSAGES, HIDE_UNREAD_MESSAGES } from "../../action/messages_action";
import DrawerComponent from '../../Components/Drawer/Drawer';
import ContractInvoice from '../Invoice/ContractInvoice';
import EmpolinkLogo from '../../Assets/images/logo.svg';
const socket = socketIO.connect(API.SOCKET_URL);

export const logoutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('hourlyRateMin');
    localStorage.removeItem('hourlyRateMax');
    localStorage.removeItem('talentSearchData');
    localStorage.removeItem('calendarDates');
    localStorage.removeItem('userId');
    localStorage.removeItem('fieldData');
    localStorage.removeItem('specialityData');
    localStorage.removeItem('skillsData');
    localStorage.removeItem('minExperienceData');
    localStorage.removeItem('maxExperienceData');
    localStorage.removeItem('localCurrency');
    localStorage.removeItem('username');
    localStorage.removeItem('country');
    localStorage.removeItem('userType');
    localStorage.removeItem('is_suspend');
    localStorage.removeItem('suspended_date');
    window.location = '/login';
};

const StyledBadge = withStyles((theme) => ({
    badge: {
        // backgroundColor: '#44b700',
        // color: '#44b700',
        height: '15px',
        minWidth: '15px',
        right: '-10px',
        borderRadius: '50%',
        backgroundColor: (props) => props.customBackground,
        color: (props) => props.customBackground,
        // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            // animation: '$ripple 1.2s infinite ease-in-out',
            // border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const Header = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            color: '#ffffff',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        toolbar: {
            height: '95px',
            backgroundColor: '#233975',
            color: '#ffffff',
        },
    }));

    const history = useHistory();
    const classes = useStyles();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorMessageEl, setAnchorMessageEl] = useState(null);
    const [anchorDashboardEl, setAnchorDashboardEl] = useState(null);
    const [anchorWorkspaceEl, setAnchorWorkspaceEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [invoicesType, setInvoicesType] = useState();
    const [isNewMessage, setNewMessage] = useState(false);
    const currentUserId = localStorage.getItem('userId');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    const getUnreadMessageCount = () => {
        APIHelper.getMethod(`${API.GET_UNREAD_MESSAGES}`, headers)
            .then((response) => {
                if (response.success === true) {
                    if (response.result?.total_count > 0) {
                        setNewMessage(true);
                    } else {
                        setNewMessage(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const args = {
            user_id: currentUserId,
        };
        socket.emit('join-conversation', args);

        getUnreadMessageCount();

        return () => {
            socket.emit('disconnect');
            socket.off();
        };
    }, []);

    useEffect(() => {
        socket.on('new-message', (response) => {
            if (location?.pathname !== '/messages') {
                setNewMessage(true);
                setTimeout(() => {
                    const sound = new Howl({
                        src: [
                            `${process.env.PUBLIC_URL}message-received.mp3`
                        ]
                    });
                    sound.play();
                }, 500);
            }
        });
    }, [socket]);

    useEffect(() => {
        if (props.shouldShowUnreadMessages) {
            getUnreadMessageCount();
            props?.hideUnreadMessages();
        }
    }, [props.shouldShowUnreadMessages]);

    const headerBar = (
        <div>
            <AppBar color="#233975" position="relative">
                <Toolbar className={classes.toolbar}>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <div
                            className="empoHeaderHeading"
                            onClick={() => {
                                localStorage.getItem('userType') === 'EMPLOYEE'
                                    ? history.push(CONSTANTS.EMPLOYEE_SEARCH_TALENT)
                                    : history.push(CONSTANTS.ROUTES_TALENT_SEARCH);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <div class="logo" style={{ display: "flex" }}>
                                <img src={EmpolinkLogo} alt="Empolink" />
                            </div>
                        </div>
                        <div
                            style={{ marginLeft: '6.77vw', cursor: 'pointer' }}
                            className="headerDashboard"
                        >
                            <div onClick={(event) => setAnchorWorkspaceEl(event.currentTarget)}>
                                <div
                                    className="headerDashboardAccountStyles"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Workspace
                                </div>
                            </div>
                            <Menu
                                id="simple-menu1"
                                anchorEl={anchorWorkspaceEl}
                                keepMounted
                                open={Boolean(anchorWorkspaceEl)}
                                onClose={() => setAnchorWorkspaceEl(null)}
                                style={{ marginTop: "9px", marginLeft: `${localStorage.getItem('userType') === 'EMPLOYER' ? "0px" : "-9px"}` }}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ horizontal: "center" }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        localStorage.getItem('userType') === 'EMPLOYEE'
                                            ? history.push('/employee/hiring-process')
                                            : history.push('/hiring-process');
                                        setAnchorWorkspaceEl(null);
                                    }}
                                >
                                    Workspace
                                </MenuItem>
                                {localStorage.getItem('userType') === 'EMPLOYEE' && (
                                    <MenuItem
                                        onClick={() => {
                                            setAnchorWorkspaceEl(null);
                                            history.push(CONSTANTS.EMPLOYEE_SEARCH_TALENT);
                                        }}
                                    >
                                        Job Search
                                    </MenuItem>
                                )}
                                {localStorage.getItem('userType') !== 'EMPLOYEE' && (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorWorkspaceEl(null);
                                                localStorage.setItem(
                                                    'talentSearchData',
                                                    JSON.stringify('')
                                                );
                                                history.push(CONSTANTS.ROUTES_TALENT_SEARCH);
                                            }}
                                        >
                                            Talent Search
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorWorkspaceEl(null);
                                                localStorage.setItem(
                                                    'talentSearchData',
                                                    JSON.stringify('')
                                                );
                                                history.push(CONSTANTS.ROUTES_JOB_POST);
                                            }}
                                        >
                                            Job Posting
                                        </MenuItem>
                                    </>
                                )}
                            </Menu>
                        </div>
                        <div
                            style={{ marginLeft: '5.77vw', cursor: 'pointer' }}
                            className="headerDashboard"
                        >
                            <div onClick={(event) => setAnchorDashboardEl(event.currentTarget)}>
                                <div className="headerDashboardAccountStyles">Dashboard</div>
                            </div>
                            <Menu
                                id="simple-menu1"
                                anchorEl={anchorDashboardEl}
                                keepMounted
                                open={Boolean(anchorDashboardEl)}
                                onClose={() => setAnchorDashboardEl(null)}
                                style={{ marginTop: "9px", marginLeft: "-10px" }}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ horizontal: "center" }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setAnchorDashboardEl(null);
                                        localStorage.getItem('userType') === 'EMPLOYEE'
                                            ? history.push({ pathname: '/employee/overview', state: { page: 0 } })
                                            : history.push({ pathname: '/overview', state: { page: 0 } });
                                        localStorage.setItem('page', 0);
                                    }}
                                >
                                    Dashboard
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorDashboardEl(null);
                                        localStorage.getItem('userType') === 'EMPLOYEE'
                                            ? history.push({ pathname: '/employee/overview', state: { page: 2 } })
                                            : history.push({ pathname: '/overview', state: { page: 2 } });
                                        localStorage.setItem('page', 2);
                                    }}
                                >
                                    Finance
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorDashboardEl(null);
                                        localStorage.getItem('userType') === 'EMPLOYEE'
                                            ? history.push({ pathname: '/employee/overview', state: { page: 1 } })
                                            : history.push({ pathname: '/overview', state: { page: 1 } });
                                        localStorage.setItem('page', 1);
                                    }}>Statistics</MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setAnchorDashboardEl(null);
                                        localStorage.getItem('userType') === 'EMPLOYEE'
                                            ? history.push({ pathname: '/employee/overview', state: { page: 3 } })
                                            : history.push({ pathname: '/overview', state: { page: 3 } });
                                        localStorage.setItem('page', 3);
                                    }}
                                >
                                    Invoices
                                </MenuItem>
                                {/*                                 <MenuItem onClick={()=>{setAnchorDashboardEl(null);setDrawerOpen(true); setInvoicesType("FIXED") }}>Fixed Invoices</MenuItem>
                                <MenuItem onClick={()=>{setAnchorDashboardEl(null);setDrawerOpen(true); setInvoicesType("HOURLY")}}>Hourly Invoices</MenuItem> */}
                            </Menu>
                        </div>
                        <DrawerComponent
                            open={drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                            component={
                                <ContractInvoice
                                    onClose={() => setDrawerOpen(false)}
                                    type={invoicesType}
                                    userType={localStorage.getItem('userType')}
                                />
                            }
                            width="80%"
                        />
                        <div
                            style={{ marginLeft: '5.77vw', cursor: 'pointer' }}
                            className="headerDashboard"
                        >
                            {/* <div onClick={(event) => setAnchorMessageEl(event.currentTarget)}>
                                <div className="headerDashboardAccountStyles">Messages</div>
                            </div>
                            <Menu
                                id="simple-menu1"
                                anchorEl={anchorMessageEl}
                                keepMounted
                                open={Boolean(anchorMessageEl)}
                                onClose={() => setAnchorMessageEl(null)}
                                style={{ marginTop: "9px" }}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                transformOrigin={{ horizontal: "center" }}
                            >
                                <MenuItem
                                // onClick={() => {
                                //     handleClose();
                                //     localStorage.getItem('userType') === "EMPLOYEE" ? history.push("/employee/account-details") : history.push("/account-details");
                                // }}
                                >
                                    Messages
                                </MenuItem>
                                <MenuItem>Notifications</MenuItem>
                            </Menu> */}
                            <div
                                onClick={(event) => {
                                    history.push({ pathname: '/messages' })
                                }}
                            >
                                <StyledBadge
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    variant="dot"
                                    customBackground={isNewMessage ? "#f20a2d" : ""}
                                >
                                    <div className="headerDashboardAccountStyles">Messages</div>
                                </StyledBadge>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginLeft: 'auto', alignItems: "center" }}>
                        <div style={{ display: 'flex', marginLeft: '7vw', cursor: 'pointer', alignItems: "center" }}>
                            <div className='icon-wrap' style={{ display: 'flex' }}>
                                <HelpIcon />
                            </div>
                            <div
                                className="headerDashboardAccountStyles"
                                style={{ marginLeft: '0.8vw' }}
                            >
                                Help
                            </div>
                        </div>
                        <div
                            onClick={handleClick}
                            style={{ display: 'flex', marginLeft: '3vw', cursor: 'pointer', alignItems: "center" }}
                        >
                            <div className='icon-wrap' style={{ display: 'flex' }}>
                                <AccountCircleIcon />
                            </div>
                            <div
                                className="headerDashboardAccountStyles"
                                style={{ marginLeft: '0.8vw' }}
                            >
                                Account
                            </div>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{ marginLeft: "-15px" }}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ horizontal: "center" }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    localStorage.getItem('userType') === 'EMPLOYEE'
                                        ? history.push('/employee/account-details')
                                        : history.push('/account-details');
                                }}
                            >
                                My account
                            </MenuItem>
                            <MenuItem onClick={() => logoutHandler()}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );

    return (
        <div>
            {CONSTANTS.VISIBLE_HEADER_FOOTER_LIST.indexOf(history.location.pathname) > -1
                ? headerBar
                : null}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        showUnreadMessages: () => dispatch({ type: SHOW_UNREAD_MESSAGES }),
        hideUnreadMessages: () => dispatch({ type: HIDE_UNREAD_MESSAGES }),
    };
};

const mapStateToProps = function (state) {
    return {
        shouldShowUnreadMessages: state.messagesReducer.showUnreadMessages,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
