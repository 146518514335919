export const HEADING = () => ({
  type: "Heading"
});
export const SUBHEADING = () => ({
  type: "SubHeading"
})
export const SUBHEADING_WITH_BORDER = () => ({
  type: "SubHeading_with_border"
})
export const SUBHEADING_WITH_BORDER_FIXED_WIDTH = () => ({
  type: "SubHeading_with_border_fixed_width"
})

export const LIGHTHEADING = () => ({
  type: "LightHeading"
})

export const LIGHTHEADING_CURSOR = () => ({
  type: "LightHeading-cursor"
})

export const VERTICAL_ULTRA_SMALL = () => ({
  type: "Ultra-Small-Space"
})

export const VERTICAL_SMALL = () => ({
  type: "Vertical-Small"
})

export const VERTICAL_NORMAL = () => ({
  type: "Normal-Space"
})

export const VERTICAL_EXTRA = () => ({
  type: "Extra-Space"
})

export const VERTICAL_EXTRA_LARGE = () => ({
  type: "Extra-Large-Space"
})

export const HORIZONTAL_ULTRA_SMALL = () => ({
  type: "Horizontal-Ultra-Small-Space-Between"
})

export const HORIZONTAL_SMALL = () => ({
  type: "Horizontal-Small-Space-Between"
})

export const HORIZONTAL_NORMAL = () => ({
  type: "Horizontal-Normal-Space-Between"
})

export const HORIZONTAL_EXTRA = () => ({
  type: "Horizontal-Extra-Space-Between"
})

export const DASHBOARD = Object.freeze({
  AUTO_COMPLETE: "AUTO_COMPLETE",
  DATE_PICKER: "DATE_PICKER",
  HOUR_RATE: "HOUR_RATE"
});

export const cardType = {
  UPDATE_PASSWORD:"update password",
  UPDATE_EMAIL:"update email",
  VERIFY_EMAIL:"verify email",
  UPDATE_MOBILE:"update mobile",
  VERIFY_MOBILE:"verify mobile"
}

export const S3_BUCKET_TYPE = Object.freeze({
  PHOTOS: 'photos',
  DOCUMENTS: 'documents',
  PORTFOLIOS: 'portfolio'
});

export const INVITATION_TYPE = Object.freeze({
  JOB_INVITATION: 'JOB_INVITATION',
  JOB_POST: 'JOB_POST'
});

export const JOB_TYPE = Object.freeze({
  HOURLY: 'HOURLY',
  FIXED: 'FIXED'
});

export const JOB_STATUS = Object.freeze({
  FINAL_APPROVED: 'APPROVED',
  PENDING: 'PENDING'
});

export const FILE_EXTENSION = Object.freeze({
  CSV: 'csv',
  DOC: 'doc',
  DOCX: 'docx',
  JPEG: 'jpeg',
  JPG: 'jpg',
  PDF: 'pdf',
  PNG: 'png',
  XLS: 'xls',
  XLSX: 'xlsx',
  ZIP: 'zip',
  BMP: 'bmp',
  GIF: 'gif'
});
