import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Swal from 'sweetalert2';
import ContainedButtons from '../../../Components/Button/Button';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import ScoreChart from '../../../Components/GaugeChart/GaugeChart';
import MessageIcon from '../../../Assets/Message.svg';
import EmployeeDetail from '../../../Components/WideProfileCard/EmployeeDetail';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import SingleEmployeeInviteDetails from '../../../Pages/SingleEmployeeInviteCards/SingleEmployeeInviteDetails';

const Favorites = (props) => {
    const history = useHistory();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState();
    const [userID, setUserID] = React.useState('');
    const [showInvite, setShowInvite] = React.useState(false);
    const [profileData, setProfileData] = React.useState();
    const [isPaymentMethodVerified, setIsPaymentMethodVerified] = React.useState(false);
    const [isChatInitLoader, setChatInitLoader] = useState(false);
    React.useEffect(() => {
        getListFavoritesEmplyoee();
    }, []);

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_ACTIVE, headers)
            .then((res) => {
                setIsPaymentMethodVerified(res.result);
                console.log("aaaaa" + res.result);
            })
            .catch((error) => { });
    }, []);

    const removeFromFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.deleteMethod(`${API.REMOVE_FAVORITE}${id}`, headers)
            .then((res) => {
                console.log(res);
                getListFavoritesEmplyoee();
            })
            .catch((error) => {
                console.log(error);
            });

        APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_ACTIVE, headers)
            .then((res) => {
                setIsPaymentMethodVerified(res.result);
            })
            .catch((error) => { });
    };

    const getListFavoritesEmplyoee = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_FAVORITES_EMPLOYEE, headers)
            .then((res) => {
                setData(res);
                setLoading(false);
                console.log(data[0]?.userid.firstName);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const onChatInit = (userId) => {
        setChatInitLoader(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        const body = {
            partnerId: userId,
        };
        APIHelper.postMethod(API.ADD_NEW_CONVERSATIONS, body, headers)
            .then((response) => {
                setChatInitLoader(false);
                if (response?.success) {
                    history.push('/messages');
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Error!',
                        text: response.error,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                }
            })
            .catch((err) => {
                setChatInitLoader(false);
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Error!',
                    text: err.errors,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    const is_suspend = localStorage.getItem('is_suspend') || false;
    const disabledDate = moment(localStorage.getItem('suspended_date')) || moment();
    const month_data = disabledDate.add(1, 'months');
    const disabledBtn = is_suspend === 'true' ? month_data.isAfter(moment()) : false;

    return (
        <React.Fragment>
            <div className="editDetailsContentHeading">My Favorites</div>
            <table
                style={{
                    width: '100%',
                    textAlign: 'left',
                    borderCollapse: 'collapse',
                    marginTop: '50px',
                    fontFamily: 'Libre Franklin',
                }}
                className="table-small-row-font"
            >
                <thead>
                    <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                        <th style={{ width: '25%', color: '#1c1c1c' }}>Talent Name</th>
                        <th style={{ width: '15%', color: '#1c1c1c' }}>Score</th>
                        <th style={{ width: '15%', color: '#1c1c1c' }}>Rate</th>
                        <th style={{ width: '35%' }}></th>
                    </tr>
                </thead>
                {loading && (
                    <tr style={{ textAlign: 'center', height: 100 }}>
                        <td colSpan="4">
                            <CircularProgress size={40} />
                        </td>
                    </tr>
                )}
                {!loading && (
                    <tbody>
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <tr
                                    style={{
                                        height: 157,
                                        borderBottom: '2px solid #C4C4C4',
                                        backgroundColor: 'inherit',
                                    }}
                                    key={index}
                                >
                                    <td style={{ width: '30%', color: '#1c1c1c' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {item?.photo && item.photo ? (
                                                <img
                                                    src={item.photo}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: 70,
                                                    }}
                                                />
                                            ) : (
                                                <AccountCircleIcon
                                                    style={{
                                                        height: '70px',
                                                        width: '70px',
                                                    }}
                                                />
                                            )}
                                            <div style={{ marginLeft: 10 }}>
                                                <p
                                                    style={{
                                                        fontSize: 20,
                                                        color: '#1c1c1c',
                                                        fontWeight: 600,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setUserID(item.userid._id);
                                                        setDrawerData(item);
                                                        setDrawerOpen(true);
                                                    }}
                                                >{`${item.userid.firstName} ${item.userid.lastName}`}</p>
                                                <p
                                                    style={{
                                                        fontSize: 20,
                                                        color: '#9a9a9a',
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {item.address[0].country}
                                                </p>
                                                <p
                                                    style={{
                                                        fontSize: 20,
                                                        color: '#9a9a9a',
                                                        marginTop: 8,
                                                    }}
                                                >{`${item.address[0].state}, ${item.address[0].country}`}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ width: '15%', color: '#1c1c1c' }}>
                                        <ScoreChart
                                            score={parseInt(props.score)}
                                            totalScore={1000}
                                        />
                                    </td>
                                    <td style={{ width: '15%' }}>${item.rate}/hr</td>
                                    <td
                                        style={{
                                            width: '40%',
                                            color: '#1c1c1c',
                                            display: 'flex',
                                            lineHeight: '157px',
                                        }}
                                    >
                                        {!isChatInitLoader ? (
                                            <img
                                                src={MessageIcon}
                                                style={{ pointer: 'q', cursor: 'pointer' }}
                                                alt="Message"
                                                onClick={() => onChatInit(item.userid._id)}
                                            />
                                        ) : (<CircularProgress size={40} />)}
                                        {/* <div style={{ marginLeft: 35 }}>
                                            <ContainedButtons
                                                height={48}
                                                text="Re-Hire"
                                                color={['#2493F1', '#15568D']}
                                                width={'12vw'}
                                                // onClick={() => removeFavorite(item.userid._id)}
                                            />
                                        </div> */}
                                        <div style={{ marginLeft: 35 }}>
                                            <ContainedButtons
                                                height={48}
                                                text="Invite"
                                                color={['#2493F1', '#15568D']}
                                                width={'12vw'}
                                                onClick={() => {
                                                    setShowInvite(true);
                                                    setProfileData(item);
                                                }}
                                                disabled={disabledBtn}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr style={{ fontSize: '24px', margin: 'auto', height: 100 }}>
                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                    No records found
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <EmployeeDetail
                        data={{ ...drawerData, isFav: true }}
                        userID={userID}
                        addToFavorite={() => { }}
                        onClose={() => setDrawerOpen(false)}
                        removeFromFavorite={removeFromFavorite}
                        isFavorite={true}
                        isPublicView
                        invite={() => {
                            setDrawerOpen(false);
                            setShowInvite(true);
                            setProfileData(drawerData);
                            // let id = cardsData.findIndex((a) => a._id === drawerData._id);
                            // setInviteFromEmplyoeeDrawer(true);
                            // setShowSingleInviteCard(true);
                            // setDrawerOpen(false);
                            // setSingleInviteProfileId(id);
                        }}
                        history={history}
                    />
                }
            />
            {showInvite ? (
                <div id="overlay">
                    <div className="overlayImageStyle">
                        <div
                            style={{
                                width: '68.64vw',
                                height: '39vw',
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                            }}
                        >
                            <SingleEmployeeInviteDetails
                                onCloseClick={() => setShowInvite(false)}
                                profileData={profileData}
                                selectedSpeciality={[]}
                                selectedSkills={[]}
                                experience={[0, 0]}
                                fromFav={true}
                                isPaymentMethodVerified={isPaymentMethodVerified}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
};
export default Favorites;
