import {LOADING, ERROR,  SUCCESS} from '../action/field_of_work_action';
import { APIHelper } from '../utils/ApiHelper';
import API from '../utils/Urls'

export const getFieldOfWork = () => {

    return async dispatch => {
        try {
            dispatch({ type: LOADING })

            APIHelper.getMethod(
             API.GET_FIELDS
            ).then((response) => {
              if (response["status"] === 1) {

                let options = response.data.map((field) => ({
                  ...field,
                  lable: field.field_name,
                  value: field.field_id,
                  id : field._id
                }))

                dispatch({ type: SUCCESS, data : options })
              }else{
                dispatch({ type: ERROR, errorMessage : "Unable to fetch field" })
              }
            })


          } catch(error) {
              dispatch({ type: ERROR , errorMessage : error})
          }
    }
  }
