import React from "react";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import ContainedButtons from "../../../../Components/Button/Button";
import { INVITATION_TYPE, JOB_TYPE, JOB_STATUS } from "../../../../utils/enums";
import Textfield from "../../../../Components/TextField/Textfield";
import CheckboxComponent from "../../../../Components/CheckboxComponent/CheckboxComponent";
import * as moment from "moment";
import { APIHelper } from "../../../../utils/ApiHelper";
import API from "../../../../utils/Urls";
import Swal from "sweetalert2";
import { convertFrom24To12Format, getAttachmentIcon } from "../../../../utils/commonFunction";

const JobOfferApply = ({
  selectedJob,
  // status,
  setDrawerOpen,
  applicationData,
  onRefreshGrid,
  isPaymentMethodVerified,
}) => {
  const [hour, setHour] = React.useState(0);
  const [proposedHourRate, setProposedHourRate] = React.useState(0);
  const [counterOffer, setCounterOffer] = React.useState(0);
  const [isCounterOffer, setIsCounterOffer] = React.useState(false);
  const [answer, setAnswer] = React.useState("");
  const [mileStones, setMileStone] = React.useState(selectedJob.milestone || []);
  const [throughEmpolinkRate, setThroughEmpolinkRate] = React.useState(1);
  const [empolinkServiceFee, setEmpolinkServiceFee] = React.useState(0);
  const [directEmpolinkRate, setDirectEmpolinkRate] = React.useState(1);
  const [isProposedRateError, setIsPorposedRateError] = React.useState(false);
  const [counterOfferError, setCounterOfferError] = React.useState(false);
  const [directEmpolinkRateEmployer, setDirectEmpolinkRateEmployer] = React.useState(1);
  const [throughEmpolinkRateEmployer, setThroughEmpolinkRateEmployer] = React.useState(1);
  const { job } = selectedJob;
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  React.useEffect(() => {
    getEmpolinkServiceData();
  }, []);

  React.useEffect(() => {
    empolinkService();
  }, [proposedHourRate, counterOffer]);

  const empolinkService = () => {
    if (job) {
      if (job.jobType === JOB_TYPE.FIXED) {
        const fixedFee = counterOffer || (job.JobValue && job.JobValue) || 0;
        if (job.paymentMethod === "DIRECT") {
          const serviceFee = (parseFloat(fixedFee) * parseFloat(directEmpolinkRate)) / 100;
          setEmpolinkServiceFee(serviceFee);
        } else {
          const serviceFee = (parseFloat(fixedFee) * parseFloat(throughEmpolinkRate)) / 100;
          setEmpolinkServiceFee(serviceFee);
        }
      } else {
        if (job.paymentMethod === "DIRECT") {
          const serviceFee =
            (parseFloat(job.hourlyTotalHours) * parseFloat(proposedHourRate) * parseFloat(directEmpolinkRate)) / 100;
          setEmpolinkServiceFee(serviceFee);
        } else {
          const serviceFee =
            (parseFloat(job.hourlyTotalHours) * parseFloat(proposedHourRate) * parseFloat(throughEmpolinkRate)) / 100;
          setEmpolinkServiceFee(serviceFee);
        }
      }
    }
  };

  const getEmpolinkServiceData = () => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    APIHelper.getMethod(`${API.GET_APPLICATION_VARIABLE}`, headers)
      .then((response) => {
        const direct = response.filter((res) => res.name === "feesrvB1");
        if (direct && direct.length > 0) {
          setDirectEmpolinkRate(parseFloat(direct[0].value));
        }
        const through = response.filter((res) => res.name === "feesrvB2");
        if (through && through.length > 0) {
          setThroughEmpolinkRate(parseFloat(through[0].value));
        }
        const directEmployer = response.filter((res) => res.name === "feesrvA1");
        if (directEmployer && directEmployer.length > 0) {
          setDirectEmpolinkRateEmployer(parseFloat(directEmployer[0].value));
        }
        const throughEmployer = response.filter((res) => res.name === "feesrvA2");
        if (throughEmployer && throughEmployer.length > 0) {
          setThroughEmpolinkRateEmployer(parseFloat(throughEmployer[0].value));
        }
      })
      .catch((err) => { });
  };

  const ApplyForJob = () => {
    if (isCounterOffer && parseFloat(counterOffer) <= 0) {
      setCounterOfferError(true);
      return;
    }

    const fixedFee = counterOffer || (selectedJob.job.JobValue && selectedJob.job.JobValue) || 0;

    var isValid = true;

    if (
      (proposedHourRate === "" || parseFloat(proposedHourRate) <= 0) &&
      selectedJob.job.jobPostType === INVITATION_TYPE.JOB_POST &&
      selectedJob.job.jobType === JOB_TYPE.HOURLY &&
      !selectedJob.isRehiring
    ) {
      setIsPorposedRateError(true);
      isValid = false;
    }

    if (selectedJob.job.jobType === JOB_TYPE.FIXED) {
      empolinkService();
    }

    let payload = {
      job: selectedJob.job._id,
      employee: selectedJob.employee || localStorage.getItem("userId"),
      workStartDate: "2020-10-21T13:28:06.419+00:00",
      workEndDate: "2022-10-21T13:28:06.419+00:00",
      question: answer,
    };

    if (!selectedJob.isRehiring) {
      let employerFees = 0;
      let employeeFees = 0;
      if (selectedJob.job.jobType === JOB_TYPE.HOURLY) {
        const totalJobAmount =
          selectedJob.job.hourlyTotalHours *
          (selectedJob.job.jobPostType === INVITATION_TYPE.JOB_INVITATION
            ? parseFloat(selectedJob.proposeHourlyRate)
            : parseFloat(proposedHourRate));
        if (selectedJob.job.paymentMethod === "DIRECT") {
          employeeFees = (totalJobAmount * directEmpolinkRate) / 100;
          employerFees = (totalJobAmount * directEmpolinkRateEmployer) / 100;
        } else {
          employeeFees = (totalJobAmount * throughEmpolinkRate) / 100;
          employerFees = (totalJobAmount * throughEmpolinkRateEmployer) / 100;
        }
      } else {
        if (selectedJob.job.paymentMethod === "DIRECT") {
          employeeFees = (fixedFee * directEmpolinkRate) / 100;
          employerFees = (fixedFee * directEmpolinkRateEmployer) / 100;
        } else {
          employeeFees = (fixedFee * throughEmpolinkRate) / 100;
          employerFees = (fixedFee * throughEmpolinkRateEmployer) / 100;
        }
      }

      payload =
        selectedJob.job.jobType === JOB_TYPE.FIXED
          ? {
            ...payload,
            proposedFeeByEmployer: selectedJob.job.JobValue ? selectedJob.job.JobValue || 0 : 0,
            proposedFeeByEmployee: fixedFee,
            empolinkServiceFee:
              (selectedJob.empolinkServiceFeeByEmployee || employeeFees) +
              (selectedJob.empolinkServiceFeeByEmployer || employerFees),
            empolinkServiceFeeByEmployee: selectedJob.empolinkServiceFeeByEmployee || employeeFees,
            empolinkServiceFeeByEmployer: selectedJob.empolinkServiceFeeByEmployer || employerFees,
            employeeCompensation: fixedFee - parseFloat(selectedJob.empolinkServiceFeeByEmployee || employeeFees || 0),
          }
          : {
            ...payload,
            proposeHourlyRate:
              selectedJob.job.jobPostType === INVITATION_TYPE.JOB_INVITATION
                ? parseFloat(selectedJob.proposeHourlyRate)
                : parseFloat(proposedHourRate),
            empolinkServiceFee:
              selectedJob.job.jobPostType === INVITATION_TYPE.JOB_INVITATION
                ? selectedJob.empolinkServiceFee
                : empolinkServiceFee || 0,
            empolinkServiceFeeByEmployee: selectedJob.empolinkServiceFeeByEmployee || employeeFees,
            empolinkServiceFeeByEmployer: selectedJob.empolinkServiceFeeByEmployer || employerFees,
            employeeCompensation:
              selectedJob.job.jobPostType === INVITATION_TYPE.JOB_INVITATION
                ? selectedJob.employeeCompensation
                : parseFloat(selectedJob.job.hourlyTotalHours || 1) *
                (selectedJob.job.jobPostType === INVITATION_TYPE.JOB_INVITATION
                  ? parseFloat(selectedJob.proposeHourlyRate)
                  : parseFloat(proposedHourRate)) -
                parseFloat(empolinkServiceFee || 0),
          };
    } else {
      payload = {
        ...payload,
        proposeHourlyRate: selectedJob.proposeHourlyRate,
        empolinkServiceFee: selectedJob.empolinkServiceFee || 0,
        employeeCompensation: selectedJob.employeeCompensation,
        empolinkServiceFeeByEmployee: selectedJob.empolinkServiceFeeByEmployee,
        empolinkServiceFeeByEmployer: selectedJob.empolinkServiceFeeByEmployer,
        isRehiring: true,
      };
    }

    const endPoint = selectedJob.job.jobType === JOB_TYPE.FIXED ? API.APPLY_TO_FIX_JOB : API.APPLY_TO_HOURLY_JOB;

    if (!isProposedRateError && isValid) {
      APIHelper.postMethod(endPoint, payload, headers)
        .then((response) => {
          if (typeof response === "object") {
            setDrawerOpen(false);
            Swal.fire({
              customClass: "Alert-Class",
              title: "Information!",
              text: "Applied for Job successfully",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#1876D2",
            });
          } else {
            setDrawerOpen(false);
            Swal.fire({
              customClass: "Alert-Class",
              title: "Information!",
              text: response.displayMessage || response,
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#1876D2",
            });
          }
          onRefreshGrid();
        })
        .catch((error) => {
          setDrawerOpen(false);
          Swal.fire({
            customClass: "Alert-Class",
            title: "Information!",
            text: "Failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        });
    }
  };

  const AcceptFinalJob = () => {
    if (selectedJob.job.jobType === JOB_TYPE.FIXED) {
      APIHelper.putMethod(`${API.ACCEPT_JOB_FIXED_FROM_EMPLOYEE_SIDE}${applicationData._id}`, null, headers).then((response) => {
        setDrawerOpen(false);
        onRefreshGrid();
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: "Offer accepted successfully",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      });
    } else {
      APIHelper.putMethod(`${API.ACCEPT_JOB_FROM_EMPLOYEE_SIDE}${applicationData._id}`, null, headers).then((response) => {
        if (typeof response === "object") {
          setDrawerOpen(false);
          onRefreshGrid();
          Swal.fire({
            customClass: "Alert-Class",
            title: "Information!",
            text: "Offer accepted successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        }
      });
    }
  };

  const DeclineForJob = () => {
    APIHelper.putMethod(`${API.JOB_APPLICATION_DECLINED}${selectedJob._id}?user_type=EMPLOYEE`, null, headers)
      .then((response) => {
        setDrawerOpen(false);
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: "Declined job successfully",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
        onRefreshGrid();
      })
      .catch((error) => {
        setDrawerOpen(false);
        Swal.fire({
          customClass: "Alert-Class",
          title: "Information!",
          text: "Something went wrong, please try again",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      });
  };

  const handleDecimalsOnValue = (value) => {
    const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
    return value.match(regex)[0];
  }

  return (
    <>
      {selectedJob.job && selectedJob.job.jobPostType === INVITATION_TYPE.JOB_INVITATION && (
        <div style={{ padding: 100, paddingTop: 70, fontFamily: "Libre Franklin" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3 style={{ margin: 0, fontSize: 36, fontWeight: "500" }}>Job Offer</h3>
          </div>
          <div style={{ marginTop: 64, fontSize: 20 }}>
            <div style={{ display: "flex" }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Job Title:</p>
              <p style={{ color: "#1c1c1c" }}>{selectedJob.job.title || ""}</p>
            </div>
            <div style={{ display: "flex", marginTop: 45, alignItems: "center" }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Client:</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AccountCircleIcon style={{ height: 52, width: 52, marginRight: 10 }} />
                <p style={{ color: "#1c1c1c" }}>
                  {(selectedJob.employerProfile &&
                    selectedJob.employerProfile.company &&
                    selectedJob.employerProfile.company.name) ||
                    ""}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Client Name:</p>
              <p style={{ color: "#1c1c1c" }}>
                {(selectedJob.employer && selectedJob.employer.firstName + " " + selectedJob.employer.lastName) || ""}
              </p>
            </div>
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Field of Work:</p>
              <p style={{ color: "#1c1c1c" }}>{(selectedJob.job.field && selectedJob.job.field.name) || ""}</p>
            </div>
            <div className="EditDetailsBottomLineStyles" />
            <div style={{ marginBottom: 50 }}>
              <p style={{ color: "#9a9a9a", marginTop: 50 }}>Job Description</p>
              <pre
                style={{
                  marginTop: 10,
                  color: "#1c1c1c",
                  fontsize: 20,
                  fontFamily: "Libre Franklin",
                  whiteSpace: "pre-wrap",
                }}
              >
                {selectedJob.job.description || ""}
              </pre>
            </div>
            {selectedJob.job.additionalDetails && (
              <>
                <div className="EditDetailsBottomLineStyles" />
                <div style={{ marginBottom: 50 }}>
                  <p style={{ color: "#9a9a9a", marginTop: 50 }}>Additional Instruction</p>
                  <pre
                    style={{
                      marginTop: 10,
                      color: "#1c1c1c",
                      fontsize: 20,
                      fontFamily: "Libre Franklin",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {selectedJob.job.additionalDetails || ""}
                  </pre>
                </div>
              </>
            )}
            <div className="EditDetailsBottomLineStyles" />
            <div style={{ display: "flex", marginTop: 50 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Offer Expiry Date:</p>
              <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.jobExpiryDate).utc(0).format("MMM DD, YY") || ""}</p>
            </div>
            <div style={{ display: "flex", marginTop: 30, marginBottom: 50 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Work Address:</p>
              <p style={{ color: "#1c1c1c" }}>
                {selectedJob.job.remoteWorking
                  ? "Remote Working"
                  : selectedJob.job.workAddress &&
                  `${selectedJob.job.workAddress.unit_number + ", " ?? ""}${selectedJob.job.workAddress.street_address + ", " ?? ""
                  }${selectedJob.job.workAddress.city + ", " ?? ""}${selectedJob.job.workAddress.state + ", " ?? ""}${selectedJob.job.workAddress.postal_code + ", " ?? ""
                  }${selectedJob.job.workAddress.country ?? ""}`}
              </p>
            </div>
            <div className="EditDetailsBottomLineStyles" />
            <p
              style={{
                width: 350,
                color: "#9a9a9a",
                marginTop: 60,
                marginBottom: 10,
              }}
            >
              Work Schedule
            </p>
            <div style={{ height: "350px", overflow: "auto", width: "100%" }}>
              <table
                style={{
                  width: "100%",
                  textAlign: "right",
                  marginTop: 10,
                  border: "1px solid #c7c7c7",
                  borderRadius: 10,
                  padding: 30,
                  borderCollapse: "separate",
                  borderSpacing: 0,
                  fontSize: 18,
                }}
              >
                <thead>
                  <tr style={{ height: 60 }}>
                    <th
                      style={{
                        width: "13%",
                        textAlign: "left",
                        borderBottom: "1px solid #9a9a9a",
                        fontSize: 18,
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        width: "19%",
                        borderBottom: "1px solid #9a9a9a",
                        fontSize: 18,
                      }}
                    >
                      Start Time
                    </th>
                    <th
                      style={{
                        width: "19%",
                        borderBottom: "1px solid #9a9a9a",
                        fontSize: 18,
                      }}
                    >
                      End Time
                    </th>
                    <th
                      style={{
                        width: "25%",
                        borderBottom: "1px solid #9a9a9a",
                        fontSize: 18,
                      }}
                    >
                      Non-Payable(hr)
                    </th>
                    <th
                      style={{
                        width: "24%",
                        borderBottom: "1px solid #9a9a9a",
                        fontSize: 18,
                      }}
                    >
                      Working Hours
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(selectedJob.job.hourlySchedule || []).map((item) => (
                    <tr style={{ height: 60 }}>
                      <td
                        style={{
                          width: "13%",
                          textAlign: "left",
                          fontSize: 18,
                          borderBottom: "1px solid #9a9a9a",
                        }}
                      >
                        {moment(item.dateSchedule || Date.now())
                          .utc(0)
                          .format("MMM DD, YY")}
                      </td>
                      <td
                        style={{
                          width: "19%",
                          fontSize: 18,
                          borderBottom: "1px solid #9a9a9a",
                        }}
                      >
                        {convertFrom24To12Format(item.startTime.hours, item.startTime.minutes)}
                      </td>
                      <td
                        style={{
                          width: "19%",
                          fontSize: 18,
                          borderBottom: "1px solid #9a9a9a",
                        }}
                      >
                        {convertFrom24To12Format(item.endTime.hours, item.endTime.minutes)}
                      </td>
                      <td
                        style={{
                          width: "25%",
                          fontSize: 18,
                          borderBottom: "1px solid #9a9a9a",
                        }}
                      >
                        {item.nonPayableTime || 0}
                      </td>
                      <td
                        style={{
                          width: "25%",
                          fontSize: 18,
                          borderBottom: "1px solid #9a9a9a",
                        }}
                      >
                        {item.totalHours || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 23,
                marginBottom: 60,
                alignItems: "center",
              }}
            >
              <p style={{ color: "#707070", fontSize: 20 }}>Total working Hours</p>
              <div style={{ width: 100 }}>
                <Textfield
                  width={"90px"}
                  value={selectedJob.job.hourlyTotalHours || 0}
                  // onChange={(event) => setHour(event.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="EditDetailsBottomLineStyles" />
            <div style={{ display: "flex", marginTop: 60 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Hourly Rate:</p>
              <p style={{ color: "#1c1c1c" }}>{`$${selectedJob.proposeHourlyRate}`}</p>
            </div>
            {/* <div style={{ display: 'flex', marginTop: 14, alignItems: 'center' }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Proposed Hourly Rate:</p>
                            <div style={{ width: 100 }}>
                                <Textfield
                                    width={'90px'}
                                    value={proposedHourRate}
                                    onChange={(event) => setProposedHourRate(event.target.value)}
                                />
                            </div>
                        </div> */}
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Job Value:</p>
              <p style={{ color: "#1c1c1c" }}>
                $
                {(selectedJob.job &&
                  selectedJob.job.hourlyTotalHours &&
                  parseFloat(selectedJob.job.hourlyTotalHours) * parseFloat(selectedJob.proposeHourlyRate)) ||
                  "00.00"}
              </p>
            </div>
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Empolink Service Fee*:</p>
              <p style={{ color: "#1c1c1c" }}>${selectedJob.empolinkServiceFeeByEmployee.toFixed(2) || "00.00"}</p>
            </div>
            <p style={{ color: "#9a9a9a", fontSize: 18, marginTop: 10 }}>
              {selectedJob.job.paymentMethod === "DIRECT"
                ? "* Empolink service fee (%X of the Job Value) will be charged to talent account."
                : "* %X of the Job Value."}
            </p>
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Talent Compensation*:</p>
              <p style={{ color: "#1c1c1c" }}>${selectedJob.employeeCompensation.toFixed(2)}</p>
            </div>
            <p style={{ color: "#9a9a9a", fontSize: 18, marginTop: 10 }}>
              {selectedJob.job.paymentMethod === "DIRECT"
                ? "* Talent compensation is equal to job value that client will pay talent directly."
                : "* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink."}
            </p>
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Client Payment Method:</p>
              <p style={{ color: "#1c1c1c" }}>
                {selectedJob.job.paymentMethod === "DIRECT" ? "Directly by Client" : "Through Empolink"}
              </p>
            </div>
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Payment Cycle:</p>
              <p style={{ color: "#1c1c1c" }}>{selectedJob.job.paymentFrequency === "WEEKLY" ? "Weekly" : "Bi-weekly"}</p>
            </div>
            {!selectedJob.isRehiring && (
              <>
                <div className="EditDetailsBottomLineStyles" />
                <div style={{ display: "flex", marginTop: 50 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Attachments</p>
                </div>
                <div
                  style={{
                    height: 210,
                    border: "1px solid #c7c7c7",
                    marginTop: 10,
                    borderRadius: 10,
                    padding: 20,
                  }}
                >
                  {selectedJob.job &&
                    selectedJob.job.attachments &&
                    selectedJob.job.attachments.map((attach) => (
                      <a href={attach.url} download target="_blank" rel="noreferrer">
                        <img
                          src={getAttachmentIcon(attach.name)}
                          style={{
                            width: 60,
                            height: 60,
                            marginRight: 10,
                          }}
                          alt="jobOfferApply"
                        />
                      </a>
                    ))}
                </div>
              </>
            )}
            {!isPaymentMethodVerified && (
              <p
                style={{
                  marginBottom: 8,
                  marginTop: 24,
                  color: "red",
                }}
              >
                Note: Your payment method is either expired or not verified. Please update your payment information.
              </p>
            )}
            <div style={{ display: "flex", marginTop: 50 }}>
              <div style={{ marginRight: 10 }}>
                <ContainedButtons
                  text="Decline"
                  color={["#a4c772", "#4e8400"]}
                  height="56px"
                  width={"12vw"}
                  variant="outlined"
                  onClick={() => isPaymentMethodVerified && DeclineForJob()}
                />
              </div>
              <ContainedButtons
                text="Apply"
                color={["#a4c772", "#4e8400"]}
                height="56px"
                width={"12vw"}
                onClick={() => isPaymentMethodVerified && ApplyForJob()}
              />
            </div>
          </div>
        </div>
      )}
      {selectedJob.job && selectedJob.job.jobPostType === INVITATION_TYPE.JOB_POST && (
        <div style={{ padding: 100, paddingTop: 70, fontFamily: "Libre Franklin" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {selectedJob.job && selectedJob.job.jobType === JOB_TYPE.FIXED ? (
              <h3 style={{ margin: 0, fontSize: 36, fontWeight: "500" }}>Job Offer (fixed fee)</h3>
            ) : (
              <h3 style={{ margin: 0, fontSize: 36, fontWeight: "500" }}>Job Offer</h3>
            )}
          </div>
          <div style={{ marginTop: 64, fontSize: 20 }}>
            <div style={{ display: "flex" }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Job Title:</p>
              <p style={{ color: "#1c1c1c" }}>{selectedJob.job.title || ""}</p>
            </div>
            <div style={{ display: "flex", marginTop: 45, alignItems: "center" }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Client:</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AccountCircleIcon style={{ height: 52, width: 52, marginRight: 10 }} />
                <p style={{ color: "#1c1c1c" }}>
                  {(selectedJob.employerProfile && selectedJob.employerProfile.company.name) || ""}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 20 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Client Name:</p>
              <p style={{ color: "#1c1c1c" }}>
                {(selectedJob.employer && selectedJob.employer.firstName + " " + selectedJob.employer.lastName) || ""}
              </p>
            </div>
            <div style={{ display: "flex", marginTop: 30 }}>
              <p style={{ width: 350, color: "#9a9a9a" }}>Field of Work:</p>
              <p style={{ color: "#1c1c1c" }}>{selectedJob.job.field.name || ""}</p>
            </div>
            {!selectedJob.isRehiring && (
              <>
                <div style={{ display: "flex", marginTop: 30 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Speciality:</p>
                  <p style={{ color: "#1c1c1c" }}>{selectedJob.job.speciality.name || ""}</p>
                </div>
                <div style={{ display: "flex", marginTop: 30 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Skills:</p>
                  <div>
                    <div style={{ marginBottom: 8 }}>
                      {selectedJob &&
                        selectedJob.job.skills.map((item) => (
                          <Chip label={item.name || ""} style={{ marginRight: 6, width: 120 }} variant="outlined" />
                        ))}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 30, marginBottom: 50 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Experience:</p>
                  <p style={{ color: "#1c1c1c" }}>
                    {(selectedJob.job.experience.min || "0") + " - " + (selectedJob.job.experience.max || "0")} years
                  </p>
                </div>
              </>
            )}
            <div className="EditDetailsBottomLineStyles" />
            <div style={{ marginBottom: 50 }}>
              <p style={{ color: "#9a9a9a", marginTop: 50 }}>Job Description</p>
              <pre
                style={{
                  marginTop: 10,
                  color: "#1c1c1c",
                  fontsize: 20,
                  fontFamily: "Libre Franklin",
                  whiteSpace: "pre-wrap",
                }}
              >
                {selectedJob.job.description || ""}
              </pre>
            </div>
            {selectedJob.job.additionalDetails && (
              <>
                <div className="EditDetailsBottomLineStyles" />
                <div style={{ marginBottom: 50 }}>
                  <p style={{ color: "#9a9a9a", marginTop: 50 }}>Additional Instruction</p>
                  <pre
                    style={{
                      marginTop: 10,
                      color: "#1c1c1c",
                      fontsize: 20,
                      fontFamily: "Libre Franklin",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {selectedJob.job.additionalDetails || ""}
                  </pre>
                </div>
              </>
            )}
            <div className="EditDetailsBottomLineStyles" />
            {applicationData.status === JOB_STATUS.FINAL_APPROVED && (
              <>
                {selectedJob.job && selectedJob.job.jobType === JOB_TYPE.FIXED && (
                  <>
                    <div style={{ marginBottom: 50 }}>
                      <p style={{ color: "#9a9a9a", marginTop: 50 }}>Talent Questions</p>
                      <p style={{ marginTop: 10, color: "#1c1c1c" }}>
                        {applicationData &&
                          applicationData.QuestionAnswer &&
                          applicationData.QuestionAnswer.length > 0 &&
                          applicationData.QuestionAnswer[0].message}
                      </p>
                    </div>
                    <div style={{ marginBottom: 30, marginBottom: 50 }}>
                      <p style={{ color: "#9a9a9a" }}>Client Response</p>
                      <p style={{ marginTop: 10, color: "#1c1c1c" }}>
                        {applicationData && applicationData.QuestionAnswer && applicationData.QuestionAnswer.length > 1
                          ? applicationData.QuestionAnswer[1].message
                          : "None"}
                      </p>
                    </div>
                    <div className="EditDetailsBottomLineStyles" />
                  </>
                )}
                <div style={{ display: "flex", marginTop: 50 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Offer Expiry Date:</p>
                  <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.jobExpiryDate).utc(0).format("MMM DD, YY") || ""}</p>
                </div>
                <div style={{ display: "flex", marginTop: 30 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Work Address:</p>
                  <p style={{ color: "#1c1c1c" }}>
                    {selectedJob.job.remoteWorking
                      ? "Remote Working"
                      : selectedJob.job.workAddress &&
                      `${selectedJob.job.workAddress.unit_number + ", " ?? ""}${selectedJob.job.workAddress.street_address + ", " ?? ""
                      }${selectedJob.job.workAddress.city + ", " ?? ""}${selectedJob.job.workAddress.state + ", " ?? ""}${selectedJob.job.workAddress.postal_code + ", " ?? ""
                      }${selectedJob.job.workAddress.country ?? ""}`}
                  </p>
                </div>
                {selectedJob.job && selectedJob.job.jobType === JOB_TYPE.HOURLY && (
                  <>
                    <div className="EditDetailsBottomLineStyles" />
                    <p
                      style={{
                        width: 350,
                        color: "#9a9a9a",
                        marginTop: 60,
                        marginBottom: 10,
                      }}
                    >
                      Work Schedule
                    </p>
                    <div
                      style={{
                        height: "350px",
                        overflow: "auto",
                        width: "100%",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: 10,
                          border: "1px solid #c7c7c7",
                          borderRadius: 10,
                          padding: 30,
                          borderCollapse: "separate",
                          borderSpacing: 0,
                          fontSize: 18,
                        }}
                      >
                        <thead>
                          <tr style={{ height: 60 }}>
                            <th
                              style={{
                                width: "13%",
                                textAlign: "left",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                width: "19%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Start Time
                            </th>
                            <th
                              style={{
                                width: "19%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              End Time
                            </th>
                            <th
                              style={{
                                width: "25%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Non-Payable(hr)
                            </th>
                            <th
                              style={{
                                width: "24%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Working Hours
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selectedJob.job.hourlySchedule || []).map((item) => (
                            <tr style={{ height: 60 }}>
                              <td
                                style={{
                                  width: "13%",
                                  textAlign: "left",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {moment(item.dateSchedule || Date.now())
                                  .utc()
                                  .format("MMM DD, YY")}
                              </td>
                              <td
                                style={{
                                  width: "19%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {convertFrom24To12Format(item.startTime.hours, item.startTime.minutes)}
                              </td>
                              <td
                                style={{
                                  width: "19%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {convertFrom24To12Format(item.endTime.hours, item.endTime.minutes)}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {item.nonPayableTime || 0}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {item.totalHours || 0}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 23,
                        marginBottom: 60,
                        alignItems: "center",
                      }}
                    >
                      <p style={{ color: "#707070", fontSize: 20 }}>Total working Hours</p>
                      <div style={{ width: 100 }}>
                        <Textfield
                          width={"90px"}
                          value={selectedJob.job.hourlyTotalHours || 0}
                          // onChange={(event) => setHour(event.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Job Value:</p>
                      <p style={{ color: "#1c1c1c" }}>
                        ${parseFloat(selectedJob.job.hourlyTotalHours) * parseFloat(applicationData.proposeHourlyRate) || "00.00"}
                      </p>
                    </div>
                    {selectedJob.job.paymentMethod === "DIRECT" && (
                      <>
                        <div style={{ display: "flex", marginTop: 30 }}>
                          <p style={{ width: 350, color: "#9a9a9a" }}>Empolink Service Fee*:</p>
                          <p style={{ color: "#1c1c1c" }}>${empolinkServiceFee.toFixed(2) || "00.00"}</p>
                        </div>
                        <p
                          style={{
                            color: "#9a9a9a",
                            fontSize: 18,
                            marginTop: 10,
                          }}
                        >
                          {selectedJob.job.paymentMethod === "DIRECT"
                            ? "* Empolink service fee (%X of the Job Value) will be charged to talent account."
                            : "* %X of the Job Value."}
                        </p>
                        <div style={{ display: "flex", marginTop: 30 }}>
                          <p style={{ width: 350, color: "#9a9a9a" }}>Talent Compensation*:</p>
                          <p style={{ color: "#1c1c1c" }}>
                            $
                            {(parseFloat(selectedJob.job.hourlyTotalHours) * parseFloat(applicationData.proposeHourlyRate) -
                              parseFloat(empolinkServiceFee)).toFixed(2)}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
                {selectedJob.job && selectedJob.job.jobType === JOB_TYPE.FIXED && (
                  <>
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Work Start Date:</p>
                      <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.startDate).utc(0).format("MMM DD, YY") || ""}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 30,
                        marginBottom: 50,
                      }}
                    >
                      <p style={{ width: 350, color: "#9a9a9a" }}>Work End Date:</p>
                      <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.endDate).utc(0).format("MMM DD, YY") || ""}</p>
                    </div>

                    <div className="EditDetailsBottomLineStyles" />
                    <p style={{ width: 350, color: "#9a9a9a", marginTop: 59 }}>Payment Schedule</p>
                    <div
                      style={{
                        width: "100%",
                        height: 379,
                        borderRadius: 10,
                        border: "solid 2px #c7c7c7",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          marginTop: "1.5vw",
                          marginLeft: "1.5vw",
                          marginRight: "1.5vw",
                          display: "flex",
                        }}
                      >
                        <div style={{ flex: 4, textAlign: "left" }} className="scheduleTableHeading">
                          Milestones
                        </div>
                        <div style={{ flex: 3 }} className="scheduleTableHeading">
                          Milestone Name
                        </div>
                        <div style={{ flex: 3 }} className="scheduleTableHeading">
                          Payment Date
                        </div>
                        <div style={{ flex: 4, textAlign: "right" }} className="scheduleTableHeading">
                          Amount
                        </div>
                      </div>
                      <div className="scheduleTableHeadingsBottom"></div>
                      <div
                        style={{
                          height: "270px",
                          overflowX: "auto",
                          marginTop: "10px",
                        }}
                      >
                        {mileStones.map((item, id) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                borderBottom: "1px solid #c7c7c7",
                                marginLeft: "1.5vw",
                                width: "93%",
                                marginTop: "5px",
                                paddingBottom: "5px",
                                height: 40,
                                alignItems: "center",
                              }}
                            >
                              <div className="sceduleListDate" style={{ flex: 4 }}>
                                {id + 1}
                              </div>
                              <div className="sceduleListStartTime" style={{ flex: 3 }}>
                                {item.description}
                              </div>
                              <div className="sceduleListStartTime" style={{ flex: 3 }}>
                                {moment(item.dateSchedule).utc(0).format("MMM DD, YY")}
                              </div>
                              <div
                                className="sceduleListStartTime"
                                style={{
                                  flex: 4,
                                  textAlign: "right",
                                }}
                              >
                                ${item.amount}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "1.5vw",
                          marginRight: "1.5vw",
                          borderTop: "2px solid #707070",
                          paddingTop: 10,
                        }}
                      >
                        <p style={{ color: "#1c1c1c", fontSize: 18 }}>Total Amount</p>
                        <p style={{ color: "#1c1c1c", fontSize: 18 }}>
                          $
                          {mileStones.reduce(function (accumulator, item) {
                            return accumulator + parseFloat(item.amount);
                          }, 0)}
                        </p>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Job Value:</p>
                      <p style={{ color: "#1c1c1c" }}>
                        {selectedJob.job.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                        {applicationData.finalFee.toFixed(2) || "00.00"}{''}{selectedJob.job.currency}</p>
                    </div>
                    <>
                      <div style={{ display: "flex", marginTop: 30 }}>
                        <p style={{ width: 350, color: "#9a9a9a" }}>Empolink Service Fee*:</p>
                        <p style={{ color: "#1c1c1c" }}>
                          {selectedJob.job.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                          {selectedJob.empolinkServiceFeeByEmployee.toFixed(2) || "00.00"}{''}{selectedJob.job.currency}</p>
                      </div>
                      <p
                        style={{
                          color: "#9a9a9a",
                          fontSize: 18,
                          marginTop: 10,
                        }}
                      >
                        {selectedJob.job.paymentMethod === "DIRECT"
                          ? "* Empolink service fee (%X of the Job Value) will be charged to talent account."
                          : "* %X of the Job Value."}
                      </p>
                      <div style={{ display: "flex", marginTop: 30 }}>
                        <p style={{ width: 350, color: "#9a9a9a" }}>Talent Compensation*:</p>
                        <p style={{ color: "#1c1c1c" }}>
                          {selectedJob.job.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                          {(applicationData.finalFee - selectedJob.empolinkServiceFeeByEmployee).toFixed(2)}
                          {''}{selectedJob.job.currency}</p>
                      </div>
                      <p
                        style={{
                          color: "#9a9a9a",
                          fontSize: 18,
                          marginTop: 10,
                        }}
                      >
                        {selectedJob.job.paymentMethod === "DIRECT"
                          ? "* Talent compensation is equal to job value that client will pay talent directly."
                          : "* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink. "}
                      </p>
                    </>
                  </>
                )}
                <div style={{ display: "flex", marginTop: 58 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Client Payment Method:</p>
                  <p style={{ color: "#1c1c1c" }}>
                    {selectedJob.job && selectedJob.job.paymentMethod === "DIRECT" ? "Directly by Client" : "Through Empolink"}
                  </p>
                </div>
                <div className="EditDetailsBottomLineStyles" />
              </>
            )}
            {applicationData.status === JOB_STATUS.PENDING && (
              <>
                <div style={{ display: "flex", marginTop: 50 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Offer Expiry Date:</p>
                  <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.jobExpiryDate).utc(0).format("MMM DD, YY") || ""}</p>
                </div>
                <div style={{ display: "flex", marginTop: 30 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Work Address:</p>
                  <p style={{ color: "#1c1c1c" }}>
                    {selectedJob.job.remoteWorking
                      ? "Remote Working"
                      : selectedJob.job.workAddress &&
                      `${selectedJob.job.workAddress.unit_number + ", " ?? ""}${selectedJob.job.workAddress.street_address + ", " ?? ""
                      }${selectedJob.job.workAddress.city + ", " ?? ""}${selectedJob.job.workAddress.state + ", " ?? ""}${selectedJob.job.workAddress.postal_code + ", " ?? ""
                      }${selectedJob.job.workAddress.country ?? ""}`}
                  </p>
                </div>
                {selectedJob.job && selectedJob.job.jobType === JOB_TYPE.FIXED && (
                  <>
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Work Start Date:</p>
                      <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.startDate).utc(0).format("MMM DD, YY") || ""}</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 30,
                        marginBottom: 50,
                      }}
                    >
                      <p style={{ width: 350, color: "#9a9a9a" }}>Work End Date:</p>
                      <p style={{ color: "#1c1c1c" }}>{moment(selectedJob.job.endDate).utc(0).format("MMM DD, YY") || ""}</p>
                    </div>
                    <div className="EditDetailsBottomLineStyles" />
                    <div style={{ display: "flex", marginTop: 50 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Fixed Fee:</p>
                      <p style={{ color: "#1c1c1c" }}>
                        {console.log("selee", selectedJob.job.jobLocationType)}
                        {selectedJob.job.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                        {selectedJob && selectedJob.job && selectedJob.job.JobValue &&
                          ((`${selectedJob.job.JobValue.toFixed(2) || "00.00"} ${selectedJob.job.currency}`))}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 20,
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CheckboxComponent
                          checked={isCounterOffer}
                          onChange={() => {
                            setIsCounterOffer(!isCounterOffer);
                          }}
                          color="#a4c772"
                        />
                        <p style={{ width: 306, color: "#9a9a9a" }}>Counter Offer:</p>
                      </div>
                      <div style={{ width: 100 }}>
                        <Textfield
                          width={"90px"}
                          value={counterOffer}
                          newProps={{
                            inputProps: {
                              maxLength: 13,
                              step: "1"
                            }
                          }}
                          onChange={(event) => {
                            setCounterOffer(handleDecimalsOnValue(event.target.value));
                            setCounterOfferError(false);
                          }}
                          error={counterOfferError}
                          disabled={!isCounterOffer}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: 24 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Client Payment Method:</p>
                      <p style={{ color: "#1c1c1c" }}>
                        {selectedJob.job && selectedJob.job.paymentMethod === "DIRECT"
                          ? "Directly by Client"
                          : "Through Empolink"}
                      </p>
                    </div>
                    <div className="EditDetailsBottomLineStyles" />
                    <p style={{ color: "#9a9a9a", marginTop: 50 }}>Write any questions you have to the client</p>
                    <div
                      style={{
                        marginBottom: 50,
                        marginTop: 10,
                        color: "#9A9A9A",
                      }}
                    >
                      <Textfield
                        width={"100%"}
                        value={answer}
                        onChange={(event) => setAnswer(event.target.value)}
                        rows={7}
                        multiline
                      />
                    </div>
                  </>
                )}
                {selectedJob.job && selectedJob.job.jobType === JOB_TYPE.HOURLY && (
                  <>
                    <div className="EditDetailsBottomLineStyles" />
                    <p
                      style={{
                        width: 350,
                        color: "#9a9a9a",
                        marginTop: 60,
                        marginBottom: 10,
                      }}
                    >
                      Work Schedule
                    </p>
                    <div
                      style={{
                        height: "350px",
                        overflow: "auto",
                        width: "100%",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          textAlign: "right",
                          marginTop: 10,
                          border: "1px solid #c7c7c7",
                          borderRadius: 10,
                          padding: 30,
                          borderCollapse: "separate",
                          borderSpacing: 0,
                          fontSize: 18,
                        }}
                      >
                        <thead>
                          <tr style={{ height: 60 }}>
                            <th
                              style={{
                                width: "13%",
                                textAlign: "left",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                width: "19%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Start Time
                            </th>
                            <th
                              style={{
                                width: "19%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              End Time
                            </th>
                            <th
                              style={{
                                width: "25%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Non-Payable(hr)
                            </th>
                            <th
                              style={{
                                width: "24%",
                                borderBottom: "1px solid #9a9a9a",
                                fontSize: 18,
                              }}
                            >
                              Working Hours
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(selectedJob.job.hourlySchedule || []).map((item) => (
                            <tr style={{ height: 60 }}>
                              <td
                                style={{
                                  width: "13%",
                                  textAlign: "left",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {moment(item.dateSchedule || Date.now())
                                  .utc(0)
                                  .format("MMM DD, YY")}
                              </td>
                              <td
                                style={{
                                  width: "19%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {convertFrom24To12Format(item.startTime.hours, item.startTime.minutes)}
                              </td>
                              <td
                                style={{
                                  width: "19%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {convertFrom24To12Format(item.endTime.hours, item.endTime.minutes)}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {item.nonPayableTime || 0}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  fontSize: 18,
                                  borderBottom: "1px solid #9a9a9a",
                                }}
                              >
                                {item.totalHours || 0}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 23,
                        marginBottom: 60,
                        alignItems: "center",
                      }}
                    >
                      <p style={{ color: "#707070", fontSize: 20 }}>Total working Hours</p>
                      <div style={{ width: 100 }}>
                        <Textfield
                          width={"90px"}
                          value={selectedJob.job.hourlyTotalHours || 0}
                          onChange={(event) => setHour(event.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                    {!selectedJob.isRehiring ? (
                      <>
                        <div className="EditDetailsBottomLineStyles" />
                        <div style={{ display: "flex", marginTop: 60 }}>
                          <p style={{ width: 350, color: "#9a9a9a" }}>Job Post Hourly Rate:</p>
                          <p>
                            {selectedJob.job &&
                              selectedJob.job.rate &&
                              `$${selectedJob.job.rate.min} - $${selectedJob.job.rate.max}`}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 24,
                            alignItems: "center",
                          }}
                        >
                          <p style={{ width: 350, color: "#9a9a9a" }}>Proposed Hourly Rate*:</p>
                          <div style={{ width: 150 }}>
                            <Textfield
                              width={"150px"}
                              value={proposedHourRate}
                              newProps={{
                                inputProps: {
                                  maxLength: 13,
                                  step: "1"
                                }
                              }}
                              onChange={(event) => {
                                setProposedHourRate(handleDecimalsOnValue(event.target.value));

                                if (
                                  selectedJob.job &&
                                  selectedJob.job.rate &&
                                  parseFloat(selectedJob.job.rate.min) <= parseFloat(event.target.value) &&
                                  parseFloat(selectedJob.job.rate.max) >= parseFloat(event.target.value)
                                ) {
                                  setIsPorposedRateError(false);
                                } else {
                                  setIsPorposedRateError(true);
                                }
                              }}
                              error={isProposedRateError}
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            color: "#9a9a9a",
                            fontSize: 18,
                            marginTop: 10,
                          }}
                        >
                          * Only hourly rates that are within the above range can be entered.
                        </p>
                        <div style={{ display: "flex", marginTop: 30 }}>
                          <p style={{ width: 350, color: "#9a9a9a" }}>Job Value:</p>
                          <p>
                            $
                            {selectedJob &&
                              selectedJob.job &&
                              parseFloat(proposedHourRate || 0) * parseFloat(selectedJob.job.hourlyTotalHours || 0)}
                          </p>
                        </div>
                        {/* {selectedJob.job.paymentMethod === 'DIRECT' && ( */}
                        <>
                          <div style={{ display: "flex", marginTop: 30 }}>
                            <p style={{ width: 350, color: "#9a9a9a" }}>Empolink Service Fee*:</p>
                            <p>${empolinkServiceFee.toFixed(2) || "00.00"}</p>
                          </div>
                          <p
                            style={{
                              color: "#9a9a9a",
                              fontSize: 18,
                              marginTop: 10,
                            }}
                          >
                            {selectedJob.job.paymentMethod === "DIRECT"
                              ? "* Empolink service fee (%X of the Job Value) will be charged to talent account."
                              : "* %X of the Job Value."}
                          </p>
                          <div style={{ display: "flex", marginTop: 30 }}>
                            <p style={{ width: 350, color: "#9a9a9a" }}>Talent Compensation*:</p>
                            <p>
                              $
                              {selectedJob &&
                                selectedJob.job &&
                                (parseFloat(proposedHourRate || 0) * parseFloat(selectedJob.job.hourlyTotalHours || 0) -
                                  parseFloat(empolinkServiceFee || 0)).toFixed(2)}
                            </p>
                          </div>
                          <p
                            style={{
                              color: "#9a9a9a",
                              fontSize: 18,
                              marginTop: 10,
                            }}
                          >
                            {selectedJob.job.paymentMethod === "DIRECT"
                              ? "* Talent compensation is equal to job value that client will pay talent directly."
                              : "* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink. "}
                          </p>
                        </>
                      </>
                    ) : (
                      <>
                        <div className="EditDetailsBottomLineStyles" />
                        <div style={{ display: "flex", marginTop: 60 }}>
                          <p style={{ width: 350, color: "#9a9a9a" }}>Hourly Rate:</p>
                          <p>${selectedJob.proposeHourlyRate}</p>
                        </div>
                        <div style={{ display: "flex", marginTop: 30 }}>
                          <p style={{ width: 350, color: "#9a9a9a" }}>Job Value:</p>
                          <p>
                            ${parseFloat(selectedJob.proposeHourlyRate || 0) * parseFloat(selectedJob.job.hourlyTotalHours || 0)}
                          </p>
                        </div>
                        <>
                          <div style={{ display: "flex", marginTop: 30 }}>
                            <p style={{ width: 350, color: "#9a9a9a" }}>Empolink Service Fee*:</p>
                            <p>${selectedJob.empolinkServiceFee.toFixed(2) || "00.00"}</p>
                          </div>
                          <p
                            style={{
                              color: "#9a9a9a",
                              fontSize: 18,
                              marginTop: 10,
                            }}
                          >
                            {selectedJob.job.paymentMethod === "DIRECT"
                              ? "* Empolink service fee (%X of the Job Value) will be charged to talent account."
                              : "* %X of the Job Value."}
                          </p>
                          <div style={{ display: "flex", marginTop: 30 }}>
                            <p style={{ width: 350, color: "#9a9a9a" }}>Talent Compensation*:</p>
                            <p>${selectedJob.employeeCompensation.toFixed(2)}</p>
                          </div>
                          <p
                            style={{
                              color: "#9a9a9a",
                              fontSize: 18,
                              marginTop: 10,
                            }}
                          >
                            {selectedJob.job.paymentMethod === "DIRECT"
                              ? "* Talent compensation is equal to job value that client will pay talent directly."
                              : "* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink. "}
                          </p>
                        </>
                      </>
                    )}
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Client Payment Method:</p>
                      <p>{selectedJob.job.paymentMethod === "DIRECT" ? "Directly by Client" : "Through Empolink"}</p>
                    </div>
                    <div style={{ display: "flex", marginTop: 30 }}>
                      <p style={{ width: 350, color: "#9a9a9a" }}>Payment Cycle:</p>
                      <p>{selectedJob.job.paymentFrequency === "WEEKLY" ? "Weekly" : "Bi-weekly"}</p>
                    </div>
                  </>
                )}
              </>
            )}
            {!selectedJob.isRehiring && (
              <>
                <div className="EditDetailsBottomLineStyles" />
                <div style={{ display: "flex", marginTop: 50 }}>
                  <p style={{ width: 350, color: "#9a9a9a" }}>Attachments</p>
                </div>
                <div
                  style={{
                    height: 210,
                    border: "1px solid #c7c7c7",
                    marginTop: 10,
                    borderRadius: 10,
                    padding: 20,
                  }}
                >
                  {selectedJob.job &&
                    selectedJob.job.attachments &&
                    selectedJob.job.attachments.map((attach) => (
                      <a href={attach.url} download target="_blank" rel="noreferrer">
                        <img
                          src={getAttachmentIcon(attach.name)}
                          style={{
                            width: 60,
                            height: 60,
                            marginRight: 10,
                          }}
                        />
                      </a>
                    ))}
                </div>
              </>
            )}
            {!isPaymentMethodVerified && (
              <p
                style={{
                  marginBottom: 8,
                  marginTop: 24,
                  color: "red",
                }}
              >
                Note: Your payment method is either expired or not verified. Please update your payment information.
              </p>
            )}
            <div style={{ display: "flex", marginTop: 50 }}>
              <div style={{ marginRight: 10 }}>
                <ContainedButtons
                  text="Decline"
                  color={["#a4c772", "#4e8400"]}
                  height="56px"
                  width={"12vw"}
                  variant="outlined"
                  onClick={() => isPaymentMethodVerified && DeclineForJob()}
                />
              </div>
              <ContainedButtons
                text={applicationData.status === JOB_STATUS.FINAL_APPROVED ? "Accept Offer" : "Apply"}
                color={["#a4c772", "#4e8400"]}
                height="56px"
                width={"12vw"}
                onClick={() =>
                  (applicationData.status === JOB_STATUS.FINAL_APPROVED ? AcceptFinalJob() : ApplyForJob())
                }
                disabled={!isPaymentMethodVerified}

              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobOfferApply;
