import React from "react";

import Select, {
  createFilter
} from 'react-select';

const Dropdown = (props) => {
  let disabledDropdownIndicator = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => null,
  };

  let disabledIndicatorSeparator = {
    IndicatorSeparator: () => null,
  };

  let components = props.disabledDropdownIndicator
    ? disabledDropdownIndicator
    : disabledIndicatorSeparator;

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: props.height ? props.height : 56,
      // maxHeight:108,
      minHeight: 30,
      overflow: 'auto',
      borderRadius: props.borderRadius ? props.borderRadius : 10,
      borderColor: props.error ? "red" : "rgba(0, 0, 0, 0.23)",
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "100%",
      borderRadius: 14,
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: 14,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    placeholder: (base) => ({
      ...base,
      color: props.error ? "red" : base.color,
    }),
    root: (base) => ({
      ...base
    })
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  // specify what you'd like to use from the filter options
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    //stringify: option => `${option.label} ${option.value}`,
    trim: true,
    //matchFrom: this.state.matchFromStart ? 'start' : 'any',
    matchFrom: 'start'
  };

  return (
    <Select
      styles={customStyles}
      value={props.value}
      options={props.options}
      formatGroupLabel={formatGroupLabel}
      onChange={props.onChange}
      components={components}
      menuIsOpen={props.menuIsOpen}
      onInputChange={props.onInputChange}
      placeholder={props.placeholder}
      onMenuOpen={props.onMenuOpen}
      isMulti={props.isMulti}
      maxMenuHeight={props.maxMenuHeight}
      isClearable={false}  
      getOptionLabel={props.getOptionLabel}
      getOptionValue={props.getOptionValue}
      filterOption={
        createFilter(filterConfig)
      }
      menuPortalTarget={document.body}
      {...props}
    />
  );
};

export default Dropdown;
