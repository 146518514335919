import React from 'react';
import ScreenSize from '../../components/screen_size'
import WorkScheduleMobile from './components/work_schedule_mobile'
import WorkScheduleWeb from './components/work_schedule_web'

export default class WorkSchedule extends React.Component{
   render() {

   return (
     <ScreenSize
       mobile  = {<WorkScheduleMobile/>}
       tablet = {<WorkScheduleMobile/>}
       web = {<WorkScheduleWeb/>}/>
   );
  }
 }
