import React from 'react';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

import { convertFrom24To12Format } from '../../../../utils/commonFunction';
import ContainedButtons from '../../../../Components/Button/Button';
import { INVITATION_TYPE, JOB_TYPE } from '../../../../utils/enums';
import Textfield from '../../../../Components/TextField/Textfield';
import CheckboxComponent from '../../../../Components/CheckboxComponent/CheckboxComponent';
import Dropdown from '../../../../Components/DropdownComponent/Dropdown.component';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAttachmentIcon } from '../../../../utils/commonFunction';
import API from '../../../../utils/Urls';
import { APIHelper } from '../../../../utils/ApiHelper';

const ApplicationJobApproval = (props) => {
    const { jobData, employee, employeeModel, jobLoading } = props;
    const [mileStoneName, setMileStoneName] = React.useState(undefined);
    const [paymentDate, setPaymentDate] = React.useState(undefined);
    const [amount, setAmount] = React.useState(undefined);
    const [mileStoneErr, setMileStoneErr] = React.useState(false);
    const [paymentDateErr, setPaymentDateErr] = React.useState(false);
    const [amountErr, setAmountErr] = React.useState(false);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [checkedRow, setCheckedRow] = React.useState([]);
    const [agreeProposedRate, setAgreeProposedRate] = React.useState(true);
    const [directEmpolinkRate, setDirectEmpolinkRate] = React.useState(0);
    const [throughEmpolinkRate, setThroughEmpolinkRate] = React.useState(0);
    const [selectedJobPost, setSelectedJobPost] = React.useState({
        label: 'I Agree with talent proposed fee',
        value: 'I Agree with talent proposed fee',
    });
    const [answerQuestionErr, setAnswerQuestionErr] = React.useState(false);

    React.useEffect(() => {
        props.setFinalFee(props.applicationData && props.applicationData.proposedFeeByEmployee);
    }, [props.applicationData]);

    React.useEffect(() => {
        getEmpolinkServiceData();
    }, []);

    const getEmpolinkServiceData = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_APPLICATION_VARIABLE}`, headers)
            .then((response) => {
                const direct = response.filter((res) => res.name === 'feesrvA1');
                if (direct && direct.length > 0) {
                    setDirectEmpolinkRate(parseFloat(direct[0].value));
                }
                const through = response.filter((res) => res.name === 'feesrvA2');
                if (through && through.length > 0) {
                    setThroughEmpolinkRate(parseFloat(through[0].value));
                }
            })
            .catch((err) => { });
    };

    const addMileStone = () => {
        if (!mileStoneName) {
            setMileStoneErr(true);
        }
        if (!paymentDate) {
            setPaymentDateErr(true);
        }
        if (!amount) {
            setAmountErr(true);
        }
        if (checkedRow.includes(mileStoneName)) {
            setMileStoneErr(true);
        }
        if (mileStoneName && paymentDate && amount && !checkedRow.includes(mileStoneName)) {
            const body = {
                dateSchedule: paymentDate,
                startTime: { hours: 12, minutes: 15 }, // TODO
                endTime: { hours: 17, minutes: 15 }, // TODO
                description: mileStoneName,
                amount: parseFloat(amount),
            };
            props.setMileStones([...props.mileStones, body]);
            setMileStoneName('');
            setPaymentDate('');
            setAmount('');
        }
    };

    const onCheckBoxClicked = (id) => {
        const exist = checkedRow.findIndex((e) => e === id);
        if (exist > -1) {
            const newArray = checkedRow.slice(exist, 1);
            setCheckedRow(newArray);
        } else {
            setCheckedRow([id, ...checkedRow]);
        }
    };

    const handleDelete = () => {
        const newMileStone = props.mileStones.filter(
            (mile) => !checkedRow.includes(mile.description)
        );
        props.setMileStones([...newMileStone]);
        setCheckedRow([]);
    };

    const handleDecimalsOnValue = (value) => {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }

    React.useEffect(() => {
        const total = props.mileStones.reduce((n, { amount }) => n + amount, 0);
        setTotalAmount(total);
    }, [props.mileStones]);

    var mileStoneAmountDate = null;
    if (props.mileStones.length > 0) {
        const mileStoneObj = props.mileStones[props.mileStones.length - 1];
        mileStoneAmountDate = moment(mileStoneObj.dateSchedule)
            .utc(0)
            .add('d', 1)
            .format('YYYY-MM-DD');
    } else {
        mileStoneAmountDate = moment(jobData.startDate).utc(0).format('YYYY-MM-DD');
    }

    return (
        <>
            {jobData && jobData.jobPostType === INVITATION_TYPE.JOB_INVITATION && (
                <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                            Job Application
                        </h3>
                    </div>
                    {jobLoading ? (
                        <CircularProgress size={50} />
                    ) : (
                        <div style={{ marginTop: 64, fontSize: 20 }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                                <p style={{ color: '#1c1c1c' }}>{jobData.title}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 69 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Talent:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {employee && `${employee.firstName} ${employee.lastName}`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Field of Work:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.field && jobData.field.name}{' '}
                                </p>
                            </div>

                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ marginBottom: 50 }}>
                                <p style={{ color: '#9a9a9a', marginTop: 50 }}>Job Description</p>
                                <pre
                                    style={{
                                        marginTop: 10,
                                        color: '#1c1c1c',
                                        fontsize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {jobData && jobData.description}
                                </pre>
                            </div>
                            {jobData && jobData.additionalDetails && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginBottom: 50 }}>
                                        <p style={{ color: '#9a9a9a', marginTop: 50 }}>
                                            Additional Instruction
                                        </p>
                                        <pre
                                            style={{
                                                marginTop: 10,
                                                color: '#1c1c1c',
                                                fontSize: 20,
                                                fontFamily: 'Libre Franklin',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {jobData && jobData.additionalDetails}
                                        </pre>
                                    </div>
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Offer Expiry Date:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.jobExpiryDate &&
                                        moment(jobData.jobExpiryDate).utc(0).format('MMM DD, YY')}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.remoteWorking
                                        ? 'Remote Working'
                                        : jobData.workAddress &&
                                        `${jobData.workAddress.unit_number + ', ' ?? ''}${jobData.workAddress.street_address + ', ' ?? ''
                                        }${jobData.workAddress.city + ', ' ?? ''}${jobData.workAddress.state + ', ' ?? ''
                                        }${jobData.workAddress.postal_code + ', ' ?? ''}${jobData.workAddress.country ?? ''
                                        }`}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <p
                                style={{
                                    width: 350,
                                    color: '#9a9a9a',
                                    marginTop: 60,
                                    marginBottom: 10,
                                }}
                            >
                                Work Schedule
                            </p>
                            <div style={{ height: '350px', overflow: 'auto', width: '100%' }}>
                                <table
                                    style={{
                                        width: '100%',
                                        textAlign: 'right',
                                        marginTop: 10,
                                        fontSize: '18px !important',
                                        border: '1px solid #9a9a9a',
                                        borderRadius: 10,
                                        padding: 30,
                                        borderCollapse: 'separate',
                                        borderSpacing: 0,
                                    }}
                                >
                                    <thead>
                                        <tr style={{ height: 60 }}>
                                            <th
                                                style={{
                                                    width: '13%',
                                                    textAlign: 'left',
                                                    fontSize: 18,
                                                    borderBottom: '1px solid #9a9a9a',
                                                }}
                                            >
                                                Date
                                            </th>
                                            <th
                                                style={{
                                                    width: '19%',
                                                    fontSize: 18,
                                                    borderBottom: '1px solid #9a9a9a',
                                                }}
                                            >
                                                Start Time
                                            </th>
                                            <th
                                                style={{
                                                    width: '19%',
                                                    fontSize: 18,
                                                    borderBottom: '1px solid #9a9a9a',
                                                }}
                                            >
                                                End Time
                                            </th>
                                            <th
                                                style={{
                                                    width: '25%',
                                                    fontSize: 18,
                                                    borderBottom: '1px solid #9a9a9a',
                                                }}
                                            >
                                                Non-Payable(hr)
                                            </th>
                                            <th
                                                style={{
                                                    width: '24%',
                                                    fontSize: 18,
                                                    borderBottom: '1px solid #9a9a9a',
                                                }}
                                            >
                                                Working Hours
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobData &&
                                            jobData.hourlySchedule &&
                                            jobData.hourlySchedule.map((item) => (
                                                <tr style={{ height: 58 }}>
                                                    <td
                                                        style={{
                                                            width: '13%',
                                                            textAlign: 'left',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {moment(item.dateSchedule)
                                                            .utc(0)
                                                            .format('MMM DD, YY')}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '19%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {convertFrom24To12Format(
                                                            item.startTime.hours,
                                                            item.startTime.minutes
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '19%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {convertFrom24To12Format(
                                                            item.endTime.hours,
                                                            item.endTime.minutes
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '25%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {item.nonPayableTime}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '25%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {item.totalHours}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 23,
                                    marginBottom: 60,
                                    alignItems: 'center',
                                }}
                            >
                                <p style={{ color: '#707070', fontSize: 20 }}>
                                    Total working Hours
                                </p>
                                <div style={{ width: 100 }}>
                                    <Textfield
                                        width={'90px'}
                                        value={jobData.hourlyTotalHours}
                                        disabled
                                    // onChange={(event) => setHour(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 60 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Hourly Rate:</p>
                                <p
                                    style={{ color: '#1c1c1c' }}
                                >{`$${props.applicationData.proposeHourlyRate}`}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Value:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    $
                                    {props.applicationData
                                        ? parseFloat(jobData.hourlyTotalHours) *
                                        parseFloat(props.applicationData.proposeHourlyRate)
                                        : '00.00'}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Client Service Fee*:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    $
                                    {(props.applicationData &&
                                        props.applicationData.empolinkServiceFeeByEmployer) ||
                                        '00.00'}
                                </p>
                            </div>
                            <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 10 }}>
                                {props.jobData.paymentMethod === 'DIRECT'
                                    ? '* Client account will be charged for Empolink service fee for %X of the Job Value.'
                                    : '* %X of the Job Value.'}
                            </p>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Total Cost:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    $
                                    {props.applicationData &&
                                        props.applicationData.proposeHourlyRate &&
                                        jobData.hourlyTotalHours &&
                                        parseFloat(jobData.hourlyTotalHours) *
                                        parseFloat(props.applicationData.proposeHourlyRate) +
                                        parseFloat(
                                            props.applicationData
                                                .empolinkServiceFeeByEmployer || 0
                                        )}
                                </p>
                            </div>
                            <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 10 }}>
                                {props.jobData.paymentMethod === 'DIRECT'
                                    ? '* Total cost is equal to job value plus Empolink service fee. Client shall directly pay an amount equal to job value to the talent.'
                                    : '* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink. '}
                            </p>
                            <div style={{ display: 'flex', marginTop: 60 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Client Payment Method:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? 'Directly by Client'
                                        : 'Through Empolink'}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Payment Cycle:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.paymentFrequency === 'WEEKLY' ? 'Weekly' : 'Bi-weekly'}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                            </div>
                            <div
                                style={{
                                    height: 210,
                                    border: '1px solid #c7c7c7',
                                    marginTop: 10,
                                    borderRadius: 10,
                                    padding: 20,
                                }}
                            >
                                {jobData.attachments.map((attach) => (
                                    <a
                                        href={attach.url}
                                        download
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={getAttachmentIcon(attach.name)}
                                            style={{
                                                width: 60,
                                                height: 60,
                                                marginRight: 10,
                                            }}
                                        />
                                    </a>
                                ))}
                            </div>
                            {props.applicationData &&
                                props.applicationData.status !== 'EMPLOYEE_ACCEPT' && (
                                    <p style={{ color: '#9a9a9a', marginTop: 30 }}>
                                        Note: By choosing "Hire" the hiring process will be
                                        completed and an official contract will be in place.
                                    </p>
                                )}
                            {!props.isPaymentMethodVerified && (
                                <p
                                    style={{
                                        marginBottom: 8,
                                        marginTop: 24,
                                        color: 'red',
                                    }}
                                >
                                    Note: Your payment method is either expired or not verified.
                                    Please update your payment information.
                                </p>
                            )}
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <div style={{ marginRight: 10 }}>
                                    <ContainedButtons
                                        text="Decline"
                                        color={['#2493F1', '#15568D']}
                                        height="56px"
                                        width={'12vw'}
                                        variant="outlined"
                                        onClick={props.DeclineForJob}
                                    />
                                </div>
                                <ContainedButtons
                                    text={
                                        props.applicationData &&
                                            props.applicationData.status === 'EMPLOYEE_ACCEPT'
                                            ? 'Accept'
                                            : 'Hire'
                                    }
                                    color={['#2493F1', '#15568D']}
                                    height="56px"
                                    width={'12vw'}
                                    onClick={() =>
                                        props.applicationData &&
                                            props.applicationData.status === 'EMPLOYEE_ACCEPT'
                                            ? props.onReviewAndAccept()
                                            : props.approvalJob()
                                    }
                                    loading={props.buttonLoading}
                                    loadingMaskSize={30}
                                    disabled={!props.isPaymentMethodVerified || props.buttonLoading}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            {jobData &&
                jobData.jobPostType === INVITATION_TYPE.JOB_POST &&
                jobData.jobType === JOB_TYPE.HOURLY && (
                    <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                                Job Application
                            </h3>
                        </div>
                        <div style={{ marginTop: 64, fontSize: 20 }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                                <p style={{ color: '#1c1c1c' }}>{jobData && jobData.title}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 69 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Talent:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {employee && `${employee.firstName} ${employee.lastName}`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Field of Work:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData && jobData.field && jobData.field.name}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Speciality:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData && jobData.speciality && jobData.speciality.name}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ minWidth: 350, color: '#9a9a9a' }}>Skills:</p>
                                <div>
                                    <div style={{ marginBottom: 8 }}>
                                        {jobData &&
                                            jobData.skills &&
                                            jobData.skills.map((j) => (
                                                <Chip
                                                    label={j.name}
                                                    style={{
                                                        marginTop: 15,
                                                        marginRight: 6,
                                                        width: 120,
                                                    }}
                                                    variant="outlined"
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Experience:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.experience &&
                                        `${jobData.experience.min}-${jobData.experience.max} years`}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ marginBottom: 50 }}>
                                <p style={{ color: '#9a9a9a', marginTop: 50 }}>Job Description</p>
                                <pre
                                    style={{
                                        marginTop: 10,
                                        color: '#1c1c1c',
                                        fontSize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {jobData.description}
                                </pre>
                            </div>
                            {jobData.additionalDetails && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginBottom: 50 }}>
                                        <p style={{ color: '#9a9a9a', marginTop: 50 }}>
                                            Additional Instruction
                                        </p>
                                        <pre
                                            style={{
                                                marginTop: 10,
                                                color: '#1c1c1c',
                                                fontSize: 20,
                                                fontFamily: 'Libre Franklin',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {jobData.additionalDetails}
                                        </pre>
                                    </div>
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Job Post Expiry Date:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>{jobData.jobExpiryDate &&
                                    moment(jobData.jobExpiryDate).utc(0).format('MMM DD, YY')}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.remoteWorking
                                        ? 'Remote Working'
                                        : jobData.workAddress &&
                                        `${jobData.workAddress.unit_number + ', ' ?? ''}${jobData.workAddress.street_address + ', ' ?? ''
                                        }${jobData.workAddress.city + ', ' ?? ''}${jobData.workAddress.state + ', ' ?? ''
                                        }${jobData.workAddress.postal_code + ', ' ?? ''}${jobData.workAddress.country ?? ''
                                        }`}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <p
                                style={{
                                    width: 350,
                                    color: '#9a9a9a',
                                    marginTop: 60,
                                    marginBottom: 10,
                                }}
                            >
                                Work Schedule
                            </p>
                            <div style={{ height: '350px', overflow: 'auto', width: '100%' }}>
                                <table
                                    style={{
                                        width: '100%',
                                        textAlign: 'right',
                                        marginTop: 10,
                                        border: '1px solid #c7c7c7',
                                        borderRadius: 10,
                                        padding: 30,
                                        borderCollapse: 'separate',
                                        borderSpacing: 0,
                                        fontSize: 18,
                                    }}
                                >
                                    <thead>
                                        <tr style={{ height: 60 }}>
                                            <th
                                                style={{
                                                    width: '13%',
                                                    textAlign: 'left',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Date
                                            </th>
                                            <th
                                                style={{
                                                    width: '19%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Start Time
                                            </th>
                                            <th
                                                style={{
                                                    width: '19%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                End Time
                                            </th>
                                            <th
                                                style={{
                                                    width: '25%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Non-Payable(hr)
                                            </th>
                                            <th
                                                style={{
                                                    width: '24%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Working Hours
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobData &&
                                            jobData.hourlySchedule &&
                                            jobData.hourlySchedule.map((item) => (
                                                <tr style={{ height: 58 }}>
                                                    <td
                                                        style={{
                                                            width: '13%',
                                                            textAlign: 'left',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {moment(item.dateSchedule)
                                                            .utc(0)
                                                            .format('MMM DD, YY')}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '19%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {convertFrom24To12Format(
                                                            item.startTime.hours,
                                                            item.startTime.minutes
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '19%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {convertFrom24To12Format(
                                                            item.endTime.hours,
                                                            item.endTime.minutes
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '25%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {item.nonPayableTime}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '25%',
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {item.totalHours}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 23,
                                    marginBottom: 60,
                                    alignItems: 'center',
                                }}
                            >
                                <p style={{ color: '#707070', fontSize: 20 }}>
                                    Total working Hours
                                </p>
                                <div style={{ width: 100 }}>
                                    <Textfield
                                        width={'90px'}
                                        value={jobData.hourlyTotalHours}
                                        disabled
                                    // onChange={(event) => setHour(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 60 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Job Post Hourly Rate:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData &&
                                        jobData.rate &&
                                        `$${jobData.rate.min}-$${jobData.rate.max}`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Proposed Hourly Rate:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    $
                                    {props.applicationData &&
                                        props.applicationData.proposeHourlyRate}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Value:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    $
                                    {props.applicationData &&
                                        props.applicationData.proposeHourlyRate &&
                                        jobData.hourlyTotalHours &&
                                        parseFloat(jobData.hourlyTotalHours) *
                                        parseFloat(props.applicationData.proposeHourlyRate)}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Empolink Service Fee*:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {props.jobData.paymentMethod === 'DIRECT' ? (
                                        <>
                                            ${' '}
                                            {(props.applicationData &&
                                                props.applicationData
                                                    .empolinkServiceFeeByEmployer.toFixed(2)) ||
                                                '00.00'}
                                            {/* {(props.applicationData &&
                                                jobData.hourlyTotalHours &&
                                                props.applicationData.proposeHourlyRate &&
                                                (parseFloat(jobData.hourlyTotalHours) *
                                                    parseFloat(
                                                        props.applicationData.proposeHourlyRate
                                                    ) *
                                                    directEmpolinkRate) /
                                                    100) ||
                                                '00.00'} */}
                                        </>
                                    ) : (
                                        <>
                                            ${' '}
                                            {(props.applicationData &&
                                                props.applicationData
                                                    .empolinkServiceFeeByEmployer.toFixed(2)) ||
                                                '00.00'}
                                            {/* {(props.applicationData &&
                                                jobData.hourlyTotalHours &&
                                                props.applicationData.proposeHourlyRate &&
                                                (parseFloat(jobData.hourlyTotalHours) *
                                                    parseFloat(
                                                        props.applicationData.proposeHourlyRate
                                                    ) *
                                                    throughEmpolinkRate) /
                                                    100) ||
                                                '00.00'} */}
                                        </>
                                    )}
                                </p>
                            </div>
                            <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 10 }}>
                                {props.jobData.paymentMethod === 'DIRECT'
                                    ? '* Client account will be charged for Empolink service fee for %X of the Job Value.'
                                    : '* %X of the Job Value.'}
                            </p>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Total Cost*:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    ${' '}
                                    {(props.applicationData &&
                                        props.applicationData.proposeHourlyRate &&
                                        jobData.hourlyTotalHours &&
                                        parseFloat(jobData.hourlyTotalHours) *
                                        parseFloat(props.applicationData.proposeHourlyRate) +
                                        parseFloat(
                                            props.applicationData.empolinkServiceFeeByEmployer
                                        )) ||
                                        0}
                                </p>
                            </div>
                            <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 10 }}>
                                {props.jobData.paymentMethod === 'DIRECT'
                                    ? '* Total cost is equal to job value plus Empolink service fee. Client shall directly pay an amount equal to job value to the talent.'
                                    : '* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink. '}
                            </p>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Client Payment Method:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? 'Directly by Client'
                                        : 'Through Empolink'}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Payment Cycle:</p>
                                <p>{jobData.paymentFrequency === 'WEEKLY' ? 'Weekly' : 'Bi-weekly'}</p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />

                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                            </div>
                            <div
                                style={{
                                    height: 210,
                                    border: '1px solid #c7c7c7',
                                    marginTop: 10,
                                    borderRadius: 10,
                                    padding: 20,
                                }}
                            >
                                {jobData &&
                                    jobData.attachments &&
                                    jobData.attachments.map((attach) => (
                                        <a
                                            href={attach.url}
                                            download
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={getAttachmentIcon(attach.name)}
                                                style={{ width: 60, height: 60, marginRight: 10 }}
                                            />
                                        </a>
                                    ))}
                            </div>
                            <p style={{ color: '#9a9a9a', marginTop: 30 }}>
                                Note: By choosing "Hire" the hiring process will be completed and an
                                official contract will be in place.
                            </p>
                            {!props.isPaymentMethodVerified && (
                                <p
                                    style={{
                                        marginBottom: 8,
                                        marginTop: 24,
                                        color: 'red',
                                    }}
                                >
                                    Note: Your payment method is either expired or not verified.
                                    Please update your payment information.
                                </p>
                            )}
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <div style={{ marginRight: 10 }}>
                                    <ContainedButtons
                                        text="Decline"
                                        color={['#2493F1', '#15568D']}
                                        height="56px"
                                        width={'12vw'}
                                        variant="outlined"
                                        onClick={props.DeclineForJob}
                                    />
                                </div>
                                <ContainedButtons
                                    text="Hire"
                                    color={['#2493F1', '#15568D']}
                                    height="56px"
                                    width={'12vw'}
                                    onClick={props.approvalJob}
                                    loading={props.buttonLoading}
                                    loadingMaskSize={30}
                                    disabled={!props.isPaymentMethodVerified || props.buttonLoading}
                                />
                            </div>
                        </div>
                    </div>
                )}
            {jobData &&
                jobData.jobPostType === INVITATION_TYPE.JOB_POST &&
                jobData.jobType === JOB_TYPE.FIXED && (
                    <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                                Job Application
                            </h3>
                        </div>
                        <div style={{ marginTop: 64, fontSize: 20 }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                                <p style={{ color: '#1c1c1c' }}>{jobData.title}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 69 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Talent:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {employee && `${employee.firstName} ${employee.lastName}`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Field of Work:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.field && jobData.field.name}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Speciality:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData && jobData.speciality && jobData.speciality.name}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ minWidth: 350, color: '#9a9a9a' }}>Skills:</p>
                                <div>
                                    <div style={{ marginBottom: 8 }}>
                                        {jobData &&
                                            jobData.skills &&
                                            jobData.skills.map((j) => (
                                                <Chip
                                                    label={j.name}
                                                    style={{
                                                        marginTop: 15,
                                                        marginRight: 6,
                                                        width: 120,
                                                    }}
                                                    variant="outlined"
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Experience:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.experience &&
                                        `${jobData.experience.min}-${jobData.experience.max} years`}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ marginBottom: 50 }}>
                                <p style={{ color: '#9a9a9a', marginTop: 50 }}>Job Description</p>
                                <pre
                                    style={{
                                        marginTop: 10,
                                        color: '#1c1c1c',
                                        fontSize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {jobData.description}
                                </pre>
                            </div>
                            {jobData && jobData.additionalDetails && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginBottom: 50 }}>
                                        <p style={{ color: '#9a9a9a', marginTop: 50 }}>
                                            Additional Instruction
                                        </p>
                                        <pre
                                            style={{
                                                marginTop: 10,
                                                color: '#1c1c1c',
                                                fontFamily: 'Libre Franklin',
                                                fontSize: 20,
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {jobData && jobData.additionalDetails}
                                        </pre>
                                    </div>
                                </>
                            )}

                            <div className="EditDetailsBottomLineStyles" />
                            <div>
                                <p style={{ color: '#9a9a9a', marginTop: 50 }}>
                                    Talent Questions
                                </p>
                                <p style={{ marginTop: 30, color: '#1c1c1c' }}>
                                    {(props.QuestionAnswer &&
                                        props.QuestionAnswer.length > 0 &&
                                        props.QuestionAnswer[0].message) ||
                                        'No Question'}
                                </p>
                            </div>
                            <div style={{ marginBottom: 50 }}>
                                <p
                                    style={{
                                        color: '#9a9a9a',
                                        marginTop: 40,
                                        marginBottom: 10,
                                    }}
                                >
                                    Client Response
                                </p>
                                {props.applicationData &&
                                    props.applicationData.status === 'EMPLOYEE_ACCEPT' ? (
                                    <p style={{ marginTop: 30, color: '#1c1c1c' }}>
                                        {(props.QuestionAnswer &&
                                            props.QuestionAnswer.length > 1 &&
                                            props.QuestionAnswer[1].message) ||
                                            'No Response'}
                                    </p>
                                ) : (
                                    <Textfield
                                        width={'100%'}
                                        placeHolder={'Please type the response'}
                                        rows={10}
                                        multiline
                                        value={props.answerQuestion}
                                        onChange={(event) => {
                                            props.setAnswerQuestion(event.target.value)
                                            setAnswerQuestionErr(false)
                                        }
                                        }
                                        error={answerQuestionErr}
                                    />
                                )}
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.remoteWorking
                                        ? 'Remote Working'
                                        : jobData.workAddress &&
                                        `${jobData.workAddress.street_address + ', ' ?? ''}
                                        ${jobData.workAddress.unit_number + ', ' ?? ''}
                                        ${jobData.workAddress.city + ', ' ?? ''}${jobData.workAddress.state + ', ' ?? ''
                                        }${jobData.workAddress.postal_code + ', ' ?? ''}${jobData.workAddress.country ?? ''
                                        }`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Start Date:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {moment(jobData.startDate).utc(0).format('MMM DD, YY')}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work End Date:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {moment(jobData.endDate).utc(0).format('MMM DD, YY')}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            {props.applicationData &&
                                props.applicationData.status !== 'EMPLOYEE_ACCEPT' && (
                                    <>
                                        <div style={{ display: 'flex', marginTop: 50 }}>
                                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                                Original Fee
                                            </p>
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                {props.applicationData &&
                                                    props.applicationData.proposedFeeByEmployer.toFixed(2)}
                                                {' '}{props.jobData.currency}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: 30 }}>
                                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                                Proposed Fee by Talent:
                                            </p>
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                {props.applicationData &&
                                                    props.applicationData.proposedFeeByEmployee.toFixed(2)}
                                                {' '}{props.jobData.currency}
                                            </p>
                                        </div>
                                    </>
                                )}
                            {props.applicationData &&
                                props.applicationData.status !== 'EMPLOYEE_ACCEPT' && (
                                    <div style={{ width: 420, marginTop: 10 }}>
                                        <Dropdown
                                            height={'54px'}
                                            borderRadius={'0.5vw'}
                                            placeholder={'Select proposed type'}
                                            options={[
                                                {
                                                    label: 'I Agree with talent proposed fee',
                                                    value: 'I Agree with talent proposed fee',
                                                },
                                                {
                                                    label: 'Propose a final fee',
                                                    value: 'Propose a final fee',
                                                },
                                            ]}
                                            onChange={(e) => {
                                                setSelectedJobPost(e);
                                                if (
                                                    e.value === 'I Agree with talent proposed fee'
                                                ) {
                                                    props.setFinalFee(
                                                        props.applicationData &&
                                                        props.applicationData
                                                            .proposedFeeByEmployee
                                                    );
                                                    setAgreeProposedRate(true);
                                                    props.setFinalFeeErr(false);
                                                } else {
                                                    props.setFinalFee(undefined);
                                                    setAgreeProposedRate(false);
                                                }
                                            }}
                                            disabled={
                                                props.applicationData &&
                                                props.applicationData.status === 'EMPLOYEE_ACCEPT'
                                            }
                                            value={selectedJobPost}
                                        />
                                    </div>
                                )}
                            {agreeProposedRate && props.finalFeeErr && (
                                <div className="errorMsg">Select proposed type</div>
                            )}
                            {props.applicationData &&
                                props.applicationData.status !== 'EMPLOYEE_ACCEPT' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: 30,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Final Offer:</p>
                                        <div style={{ width: 100 }} key={agreeProposedRate}>
                                            {props.applicationData &&
                                                props.applicationData.status === 'EMPLOYEE_ACCEPT' ? (
                                                <p>${props.applicationData.finalFee}</p>
                                            ) : (
                                                <Textfield
                                                    width={'120px'}
                                                    placeHolder={'$00.00'}
                                                    value={props.finalFee}
                                                    newProps={{
                                                        inputProps: {
                                                            maxLength: 13,
                                                            step: "1"
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        props.setFinalFeeErr(false);
                                                        props.setFinalFee(handleDecimalsOnValue(event.target.value));
                                                    }}
                                                    error={props.finalFeeErr}
                                                    disabled={agreeProposedRate}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            {props.applicationData &&
                                props.applicationData.status === 'EMPLOYEE_ACCEPT' && (
                                    <>
                                        <div style={{ display: 'flex', marginTop: 30 }}>
                                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                                Job Value:
                                            </p>
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                {props.applicationData
                                                    ? props.applicationData.finalFee.toFixed(2)
                                                    : '00.00'}
                                                {' '}{props.jobData.currency}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: 30 }}>
                                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                                Empolink Service Fee*:
                                            </p>
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                {(props.applicationData &&
                                                    props.applicationData
                                                        .empolinkServiceFeeByEmployer.toFixed(2)) ||
                                                    '00.00'}
                                                {' '}{props.jobData.currency}
                                            </p>
                                        </div>
                                        <p
                                            style={{
                                                color: '#9a9a9a',
                                                fontSize: 18,
                                                marginTop: 10,
                                            }}
                                        >
                                            {props.jobData.paymentMethod === 'DIRECT'
                                                ? '* Client account will be charged for Empolink service fee for %X of the Job Value.'
                                                : '* %X of the Job Value.'}
                                        </p>
                                        <div style={{ display: 'flex', marginTop: 30 }}>
                                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                                Total Cost*:
                                            </p>
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                {props.applicationData &&
                                                    (parseFloat(props.applicationData.finalFee) +
                                                        parseFloat(props.applicationData.empolinkServiceFeeByEmployer || 0)).toFixed(2)}
                                                {' '}{props.jobData.currency}
                                            </p>
                                        </div>
                                        <p
                                            style={{
                                                color: '#9a9a9a',
                                                fontSize: 18,
                                                marginTop: 10,
                                            }}
                                        >
                                            {props.jobData.paymentMethod === 'DIRECT'
                                                ? '* Total cost is equal to job value plus Empolink service fee. Client shall directly pay an amount equal to job value to the talent.'
                                                : '* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink. '}
                                        </p>
                                    </>
                                )}
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Client Payment Method:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? 'Directly by Client'
                                        : 'Through Empolink'}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    {props.applicationData &&
                                        props.applicationData.status === 'EMPLOYEE_ACCEPT'
                                        ? 'Payment Schedule'
                                        : 'Create Payment Schedule'}
                                </p>
                            </div>
                            {props.applicationData &&
                                props.applicationData.status !== 'EMPLOYEE_ACCEPT' && (
                                    <>
                                        <div className="row" style={{ marginTop: 30 }}>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        width: 350,
                                                        color: '#707070',
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    Milestone Name
                                                </p>
                                                <Textfield
                                                    width={'80%'}
                                                    placeHolder={'$00.00'}
                                                    value={mileStoneName}
                                                    onChange={(event) => {
                                                        setMileStoneErr(false);
                                                        setMileStoneName(event.target.value);
                                                    }}
                                                    error={mileStoneErr}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        width: 350,
                                                        color: '#707070',
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    Payment Date
                                                </p>
                                                <Textfield
                                                    width={'80%'}
                                                    placeHolder={'$00.00'}
                                                    value={paymentDate}
                                                    onChange={(event) => {
                                                        setPaymentDateErr(false);
                                                        setPaymentDate(event.target.value);
                                                    }}
                                                    type="date"
                                                    inputProps={{
                                                        min: mileStoneAmountDate,
                                                        max: moment(jobData.endDate).format('YYYY-MM-DD')
                                                    }}
                                                    error={paymentDateErr}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        width: 350,
                                                        color: '#707070',
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    Amount
                                                </p>
                                                <Textfield
                                                    width={'80%'}
                                                    placeHolder={'$00.00'}
                                                    value={amount}
                                                    newProps={{
                                                        inputProps: {
                                                            maxLength: 13,
                                                            step: "1"
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        setAmountErr(false);
                                                        setAmount(handleDecimalsOnValue(event.target.value));
                                                    }}
                                                    error={amountErr}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 30 }}>
                                            <ContainedButtons
                                                text="Add to schedule"
                                                color={['#2493F1', '#15568D']}
                                                height="56px"
                                                onClick={addMileStone}
                                            />
                                        </div>
                                    </>
                                )}
                            <div className="scheduleTable" style={{ width: '100%' }}>
                                <div
                                    style={{
                                        marginTop: '1.5vw',
                                        marginLeft: '1.5vw',
                                        marginRight: '1.5vw',
                                    }}
                                    className="scheduleTableHeadings"
                                >
                                    {props.applicationData &&
                                        props.applicationData.status !== 'EMPLOYEE_ACCEPT' && (
                                            <div
                                                style={{ flex: 1 }}
                                                className="scheduleTableHeading"
                                            >
                                                <DeleteIcon
                                                    style={{
                                                        color: '#9A9A9A',
                                                        width: 22,
                                                        height: 22,
                                                        cursor: 'pointer',
                                                    }}
                                                    fontSize="inherit"
                                                    onClick={handleDelete}
                                                />
                                            </div>
                                        )}
                                    <div style={{ flex: 4 }} className="scheduleTableHeading">
                                        Milestones
                                    </div>
                                    <div style={{ flex: 3 }} className="scheduleTableHeading">
                                        Milestone Name
                                    </div>
                                    <div style={{ flex: 3 }} className="scheduleTableHeading">
                                        Payment Date
                                    </div>
                                    <div style={{ flex: 4 }} className="scheduleTableHeading">
                                        Amount
                                    </div>
                                </div>
                                <div className="scheduleTableHeadingsBottom"></div>
                                <div
                                    style={{
                                        height: '270px',
                                        overflowX: 'auto',
                                        marginTop: '10px',
                                    }}
                                >
                                    {props.mileStones
                                        .sort(
                                            (a, b) =>
                                                new Date(a.dateSchedule) - new Date(b.dateSchedule)
                                        )
                                        .map((item, id) => {
                                            return (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        borderBottom: '1px solid #c7c7c7',
                                                        marginLeft: '1.5vw',
                                                        width: '93%',
                                                        marginTop: '5px',
                                                        paddingBottom: '5px',
                                                        height: 40,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {props.applicationData &&
                                                        props.applicationData.status !==
                                                        'EMPLOYEE_ACCEPT' && (
                                                            <div style={{ flex: 1 }}>
                                                                <CheckboxComponent
                                                                    // checked={this.isSelected(id)}
                                                                    padding="0px"
                                                                    onChange={() =>
                                                                        onCheckBoxClicked(
                                                                            item.description
                                                                        )
                                                                    }
                                                                    style={{ margin: 0 }}
                                                                    color="#2699fb"
                                                                />
                                                            </div>
                                                        )}
                                                    <div
                                                        className="sceduleListDate"
                                                        style={{ flex: 4 }}
                                                    >
                                                        {id + 1}
                                                    </div>
                                                    <div
                                                        className="sceduleListStartTime"
                                                        style={{ flex: 3 }}
                                                    >
                                                        {item.description}
                                                    </div>
                                                    <div
                                                        className="sceduleListStartTime"
                                                        style={{ flex: 3 }}
                                                    >
                                                        {moment(item.dateSchedule)
                                                            .utc(0)
                                                            .format('MMM DD, YY')}
                                                    </div>
                                                    <div
                                                        className="sceduleListStartTime"
                                                        style={{ flex: 4 }}
                                                    >
                                                        $ {item.amount}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginLeft: '1.5vw',
                                        marginRight: '1.5vw',
                                        borderTop: '2px solid #707070',
                                        paddingTop: 10,
                                    }}
                                >
                                    <p style={{ color: '#1c1c1c', fontSize: 18 }}>Total Amount</p>
                                    <p style={{ color: '#1c1c1c', fontSize: 18 }}>${totalAmount}</p>
                                </div>
                            </div>

                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                            </div>
                            <div
                                style={{
                                    height: 210,
                                    border: '1px solid #c7c7c7',
                                    marginTop: 10,
                                    borderRadius: 10,
                                    padding: 20,
                                    display: 'flex',
                                }}
                            >
                                {jobData &&
                                    jobData.attachments &&
                                    jobData.attachments.map((attach) => (
                                        <a
                                            href={attach.url}
                                            download
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={getAttachmentIcon(attach.name)}
                                                style={{ width: 60, height: 60, marginRight: 10 }}
                                            />
                                        </a>
                                    ))}
                            </div>
                            {props.applicationData &&
                                props.applicationData.status === 'EMPLOYEE_ACCEPT' && (
                                    <p style={{ color: '#9a9a9a', marginTop: 30 }}>
                                        Note: By choosing "Hire" the hiring process will be
                                        completed and an official contract will be in place.
                                    </p>
                                )}
                            {!props.isPaymentMethodVerified && (
                                <p
                                    style={{
                                        marginBottom: 8,
                                        marginTop: 24,
                                        color: 'red',
                                    }}
                                >
                                    Note: Your payment method is either expired or not verified.
                                    Please update your payment information.
                                </p>
                            )}
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <div style={{ marginRight: 10 }}>
                                    <ContainedButtons
                                        text="Decline"
                                        color={['#FFF', '#FFF']}
                                        height="56px"
                                        width={'12vw'}
                                        variant="outlined"
                                        onClick={props.DeclineForJob}
                                    />
                                </div>
                                <ContainedButtons
                                    text={
                                        props.applicationData &&
                                            props.applicationData.status === 'EMPLOYEE_ACCEPT'
                                            ? 'Hire'
                                            : 'Send Offer'
                                    }
                                    color={['#2493F1', '#15568D']}
                                    height="56px"
                                    width={'12vw'}
                                    onClick={() =>
                                        props.answerQuestion === "" && !props.QuestionAnswer &&
                                            !(props.QuestionAnswer.length > 0)
                                            ? setAnswerQuestionErr(true) : (
                                                props.applicationData && (
                                                    props.applicationData.status === 'EMPLOYEE_ACCEPT' ? (
                                                        props.onReviewAndAccept()) : props.approvalJob())
                                            )
                                    }
                                    loading={props.buttonLoading}
                                    loadingMaskSize={30}
                                    disabled={!props.isPaymentMethodVerified || props.buttonLoading}
                                />
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default ApplicationJobApproval;
