import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Card from '@material-ui/core/Card';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory } from "react-router-dom";
import CONSTANTS from '../../utils/constants'
import { useEffect } from "react";

const BootstrapInput = withStyles((theme) => ({
    root: {
        color:"#ffffff",
        display:"inline-block",
        "label + &": {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        position: "relative",
        // backgroundColor: theme.palette.background.paper,
        // border: "1px solid white",
        fontSize: "1vw",
        // padding: "0.520vw 1.354vw 0.520vw 0.625vw"
        // Use the system font instead of the default Roboto font.
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
    },
    icon: {
        left: "16.66vw",
        top: "1.04166vw",
        display:"none"
    }
}));

const useStylesCard = makeStyles({
    root: {
        // width: "18.80vw",
        // height: "4.791vw",
    //   backgroundColor: '#ffffff',
    //   borderRadius:"1.041vw",

    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

export default function HeaderDropdown() {

    useEffect(()=>{
        console.log(history.location.pathname)

        if(history.location.pathname == CONSTANTS.ROUTES_JOB_POST){
            history.push(CONSTANTS.ROUTES_JOB_POST)
            setMenuOptions("JOB STUFF")
        }
        if(history.location.pathname === CONSTANTS.ROUTES_TALENT_SEARCH){
            history.push(CONSTANTS.ROUTES_TALENT_SEARCH)
            setMenuOptions("TALENT SEARCH")
        }
    },[])



    const classes = useStyles();
    const cardClasses = useStylesCard();
    const [menuOptions, setMenuOptions] = React.useState("TALENT SEARCH")
    const history = useHistory();
    const handleChange = (event) => {
        setMenuOptions(event.target.value)

        if(event.target.value === "JOB STUFF"){
            history.push(CONSTANTS.ROUTES_JOB_POST)
        }
        if(event.target.value === "TALENT SEARCH"){
            history.push(CONSTANTS.ROUTES_TALENT_SEARCH)
        }
    };
    return (
        <div>
                <FormControl style={{display:"inline-block"}} className={classes.margin}>
                    {/* <div className="arrowDropdownIcon"> */}
                    {/* </div> */}
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        labelWidth={0}
                        value={menuOptions}
                        onChange={handleChange}
                        classes={{ icon: classes.icon }}
                        input={<BootstrapInput />}
                        // onInputChange={()=>{console.log("")}}
                    >
                        <MenuItem value={"JOB STUFF"}>JOB STUFF</MenuItem>
                        {/* <MenuItem value={"POST JOB"}>TALENT SE</MenuItem> */}
                        <MenuItem value={"TALENT SEARCH"}>TALENT SEARCH</MenuItem>
                        
                    </Select>
                    <div style ={{display:"inline-block", position:"absolute", marginTop:"5px", marginLeft:"-15px"}}><ArrowDropDownIcon/></div>

                </FormControl>
                <div style={{width:"100%", borderBottom:"1px solid white", marginTop:"-10px"}}></div>

        </div>
    );
}
