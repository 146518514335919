import { APIHelper } from '../utils/ApiHelper';
import API from '../utils/Urls';
import ReactS3 from 'react-s3';
import * as moment from 'moment';
import { LOADING, ERROR, SUCCESS } from '../action/job_post_action';
import { config } from '../Components/FileUpload/FileUpload';
import { getFileName } from '../utils/commonFunction';
import { INVITATION_TYPE } from '../utils/enums';

export const postJobData = (postDataObject) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOADING });
            var attachment = [];
            if (postDataObject.attachmentURL.length > 0) {
                var bodyFormData = new FormData();
                for (var i = 0; i < postDataObject.attachmentURL.length; i++) {
                    bodyFormData.append('files', postDataObject.attachmentURL[i], postDataObject.attachmentURL[i].name);
                }
                bodyFormData.append('userId', postDataObject.userid);
                bodyFormData.append('type', 'jobs');
                let headersFile = {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                };
                const response = await APIHelper.postMethod(API.JOB_UPLOAD_FILES, bodyFormData, headersFile);
                for (var i = 0; i < response.data.length; i++) {
                    attachment.push({ name: response.data[i] });
                }
            }

            var skills = [];

            if (postDataObject.suggestedSkillsValue.length > 0) {
                for (var i = 0; i < postDataObject.suggestedSkillsValue.length; i++) {
                    if (postDataObject.suggestedSkillsValue[i].isSelected) {
                        skills = [...skills, postDataObject.suggestedSkillsValue[i]];
                    }
                }
            }

            if (postDataObject.additionalSkillsValue.length > 0) {
                for (var i = 0; i < postDataObject.additionalSkillsValue.length; i++) {
                    skills.push({
                        _id: '000000000000000000000000',
                        speciality_id:
                            postDataObject.specialityObject && postDataObject.specialityObject.id,
                        field_id:
                            postDataObject.fieldOfWorkObject && postDataObject.fieldOfWorkObject.id,
                        name: postDataObject.additionalSkillsValue[i].name,
                        label: postDataObject.additionalSkillsValue[i].name,
                        value: postDataObject.additionalSkillsValue[i].name,
                        skills_id: '000000000000000000000000',
                    });
                }
            }

            var hourlySchedule = [];
            var totalHours = 0;
            if (postDataObject.workScheduleHourlyList.length > 0) {
                for (var i = 0; i < postDataObject.workScheduleHourlyList.length; i++) {
                    var workScheduleHourly = postDataObject.workScheduleHourlyList[i];

                    const startTimeArray = workScheduleHourly.start.split(':');
                    const endTimeArray = workScheduleHourly.end.split(':');

                    const hour = workScheduleHourly.whours.split(':');
                    var totals = 0;
                    if (hour.length === 2) {
                        totals = parseInt(hour[0]);
                        if (hour[1] !== '00') {
                            totals += 0.5;
                        }
                    }

                    var hourlyScheduleObject = {
                        dateSchedule:
                            workScheduleHourly.dateObject !== undefined
                                ? moment(
                                    workScheduleHourly.dateObject.month +
                                    '/' +
                                    workScheduleHourly.dateObject.day +
                                    '/' +
                                    workScheduleHourly.dateObject.year
                                ).format('YYYY-MM-DD')
                                : '',
                        startTime: { hours: startTimeArray[0], minutes: startTimeArray[1] },
                        endTime: { hours: endTimeArray[0], minutes: endTimeArray[1] },
                        nonPayableTime: workScheduleHourly.npayable,
                        totalHours: totals,
                    };

                    totalHours = totalHours + workScheduleHourly.whours;
                    hourlySchedule = [...hourlySchedule, hourlyScheduleObject];
                }
            }

            var totalWorks = 0;
            const totalArr = postDataObject.totalWorking.split(':');
            if (totalArr.length === 2) {
                totalWorks = parseInt(totalArr[0]);
                if (totalArr[1] !== '00') {
                    totalWorks += 0.5;
                }
            }

            var sharedCountry =
                postDataObject.locations && postDataObject.locations.map((loc) => loc.value);
            if (sharedCountry.includes(null)) {
                sharedCountry = [localStorage.getItem('country')];
            }

            const body = {
                title: postDataObject.jobTitle,
                field:
                    postDataObject.fieldOfWorkObject !== undefined
                        ? postDataObject.fieldOfWorkObject.id
                        : '',
                speciality:
                    postDataObject.specialityObject !== undefined
                        ? postDataObject.specialityObject.id
                        : '',
                skills: skills,
                description: postDataObject.jobDescription,
                additionalDetails: postDataObject.additionalUserInstruction,
                jobType: postDataObject.isHourly ? 'HOURLY' : 'FIXED',
                rate: {
                    min: parseFloat(postDataObject.hourlyRateMinValue),
                    max: parseFloat(postDataObject.hourlyRateMaxValue),
                },
                JobValue:
                    postDataObject.feeAmount === '' ? 0 : parseFloat(postDataObject.feeAmount),
                experience: {
                    min: postDataObject.experienceValue[0],
                    max: postDataObject.experienceValue[1],
                },
                // "studentCoop": false,
                attachments: attachment,

                hourlySchedule: hourlySchedule,
                listOfInvitees: [],
                hourlyTotalHours: totalWorks,
                instructions: postDataObject.additionalUserInstruction,
                workAddress: postDataObject.address,
                jobExpiryDate:
                    postDataObject.jobPostCloseDateFormat !== undefined
                        ? moment(
                            postDataObject.jobPostCloseDateFormat.month +
                            '/' +
                            postDataObject.jobPostCloseDateFormat.day +
                            '/' +
                            postDataObject.jobPostCloseDateFormat.year
                        ).format('YYYY-MM-DD')
                        : '',
                startDate:
                    postDataObject.jobStartDateFormat !== undefined
                        ? moment(
                            postDataObject.jobStartDateFormat.month +
                            '/' +
                            postDataObject.jobStartDateFormat.day +
                            '/' +
                            postDataObject.jobStartDateFormat.year
                        ).format('YYYY-MM-DD')
                        : '',
                endDate:
                    postDataObject.jobCloseDateFormat !== undefined
                        ? moment(
                            postDataObject.jobCloseDateFormat.month +
                            '/' +
                            postDataObject.jobCloseDateFormat.day +
                            '/' +
                            postDataObject.jobCloseDateFormat.year
                        ).format('YYYY-MM-DD')
                        : '',
                paymentMethod:
                    postDataObject.paymentMethod.toUpperCase() === 'EMPOLINK'
                        ? postDataObject.paymentMethod.toUpperCase()
                        : 'DIRECT',
                paymentFrequency: postDataObject.isHourly
                    ? postDataObject.paymentTime.toUpperCase() === 'WEEKLY'
                        ? postDataObject.paymentTime.toUpperCase()
                        : 'BIWEEKLY'
                    : 'MILESTONE',
                user: postDataObject.userid,
                jobPostType: INVITATION_TYPE.JOB_POST,
                jobLocationType:
                    postDataObject.jobPostLocation.toUpperCase() === 'WITHIN MY COUNTRY'
                        ? 'LOCAL'
                        : 'INTERNATIONAL',
                remoteWorking: postDataObject.addressType === 'Remote working',
                sharedCountries: sharedCountry,
                currency: postDataObject.jobPostLocation.toUpperCase() === 'WITHIN MY COUNTRY' ? localStorage.getItem('localCurrency') : 'USD',
            };

            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };
            APIHelper.postMethod(API.JOB_INVITE_POST, body, headers).then((response) => {
                if (response.data !== undefined) {
                    if (postDataObject.isHourly) {
                        localStorage.setItem(
                            'hourlyRateMin',
                            parseFloat(postDataObject.hourlyRateMinValue || 0)
                        );
                        localStorage.setItem(
                            'hourlyRateMax',
                            parseFloat(postDataObject.hourlyRateMaxValue || 30)
                        );
                    }
                    if (postDataObject.specialityObject) {
                        localStorage.setItem(
                            'specialityData',
                            JSON.stringify({
                                label: postDataObject.specialityObject.lable,
                                value: postDataObject.specialityObject.value,
                                _id: postDataObject.specialityObject.value,
                            })
                        );
                    }
                    localStorage.setItem('skillsData', JSON.stringify(skills));
                    localStorage.setItem(
                        'minExperienceData',
                        JSON.stringify(postDataObject.experienceValue[0] || 0)
                    );
                    localStorage.setItem(
                        'maxExperienceData',
                        JSON.stringify(postDataObject.experienceValue[1] || 20)
                    );
                    dispatch({ type: SUCCESS, data: response });
                } else {
                    dispatch({ type: ERROR, errorMessage: 'Error while posting job' });
                }
            });
        } catch (error) {
            dispatch({ type: ERROR, errorMessage: error });
        }
    };
};
