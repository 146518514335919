import React from 'react';
import ScreenSize from '../../components/screen_size'
import LocationMobile from './components/location_mobile'
import LocationtWeb from './components/location_web'

export default class Location extends React.Component {
  render() {
    return (
      <ScreenSize
        mobile  = {<LocationMobile/>}
        tablet = {<LocationMobile/>}
        web = {<LocationtWeb/>}/>
    );
  }
}
