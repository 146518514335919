import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Text from './text'

import {LIGHTHEADING_CURSOR} from '../../../utils/enums';

import {useEffect, useState, useRef } from 'react';


export default  function CustomRadioGroup(props) {

  const [selectedValue, setSelectedValue] = React.useState(props.id1);
  const [fontSize, setFontSize] = useState("2.0vw");
  const [color, setColor] = useState("#070707");
  const [alignment, setAlignment] = useState("left");
  const [fontWeight, setFontWeight] = useState("normal");
  const [cursor, setCursor] = useState("text");

  useEffect(() => {
    if(props.fontSize !== undefined)
      setFontSize(props.fontSize);
    }, [props.fontSize]);

    useEffect(() => {
      if(props.color !== undefined)
        setColor(props.color);
    }, [props.color]);

    useEffect(() => {
      if(props.alignment !== undefined)
        setAlignment(props.alignment);
    }, [props.alignment]);


    useEffect(() => {
      if(props.fontWeight !== undefined)
        setFontWeight(props.fontWeight);
    }, [props.fontWeight]);


    useEffect(() => {
      if(props.cursor !== undefined)
        setCursor(props.cursor);
    }, [props.cursor]);

  const handleChange = (event) => {
      setSelectedValue(event.target.value);
      if(props.onChangeCallback != undefined){
        props.onChangeCallback(event.target.value);
      }
    };


    const handleChangeValue = (value) => {
        setSelectedValue(value);
        if(props.onChangeCallback != undefined){
          props.onChangeCallback(value);
        }

      };


  return (

    <div className ="Radio-Button-Parent">
     <div className="Row-Parent">

          <Radio
             checked={selectedValue === props.id1}
             onChange={handleChange}
             value= {props.id1}
             inputProps={{ 'aria-label': props.id1 }}
           />

           <Text
             fontSize ={fontSize}
             color = {color}
             alignment = {alignment}
             fontWeight = {fontWeight}
             cursor = {cursor}
             text = {props.lable1}
           onClickCallback={() => {
             handleChangeValue(props.id1);
           }}/>

           <Radio
              checked={selectedValue === props.id2}
              onChange={handleChange}
              value= {props.id2}
              inputProps={{ 'aria-label': props.id2 }}
            />

            <Text
              fontSize ={fontSize}
              color = {color}
              alignment = {alignment}
              fontWeight = {fontWeight}
              cursor = {cursor}
              text = {props.lable2}
            onClickCallback={() => {
              handleChangeValue(props.id2);
            }}/>

            <Radio
               checked={selectedValue === props.id3}
               onChange={handleChange}
               value= {props.id3}
               inputProps={{ 'aria-label': props.id3 }}
             />

             <Text
               fontSize ={fontSize}
               color = {color}
               alignment = {alignment}
               fontWeight = {fontWeight}
               cursor = {cursor}
               text = {props.lable3}
             onClickCallback={() => {
               handleChangeValue(props.id3);
             }}/>

      </div>
      </div>
    );
}
