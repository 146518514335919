import React from 'react';

import './EmployeeHiringProgress.scss';

import AppliedJobs from './ProcessStep/AppliedJobs';
import JobOffers from "./ProcessStep/JobOffers";
import HourlyJobs from "./ProcessStep/HourlyJobs";
import FixedFeeJobs from './ProcessStep/FixedFeeJobs';
import WorkHistory from './ProcessStep/WorkHistory';
import CurrentCalender from './ProcessStep/CurrentCalender';
import CalenderSetup from './ProcessStep/CalenderSetup';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
const selectedHeadingstyles = {
    fontSize: "1.25vw",
    fontWeight: "600",
    letterSpacing: "0.96px",
    color: "#1c1c1c",
    backgroundColor: "rgba(164,199,114,0.64)"
}

export class EmployeeHiringProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props.history.location.state?.page || 0,
        }
    }

    render() {

        const pages = [
            <JobOffers />,
            <AppliedJobs />,
            <HourlyJobs />,
            <FixedFeeJobs />,
            <WorkHistory />,
            <CurrentCalender />,
            <CalenderSetup />
        ]

        return (
            <div style={{ display: "flex", flex: 1 }}>
                <div style={{ width: "24.8%", minHeight: "79.4vh", backgroundColor: "#f2f2f2" }}>
                    <div style={{ marginTop: "5.24vw" }}>
                        <p className="hiringHeader">Job Applications</p>
                        <div onClick={() => this.setState({ page: 0 })} style={this.state.page === 0 ? selectedHeadingstyles : {}} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Job Invitations</div></div>
                        <div onClick={() => this.setState({ page: 1 })} style={this.state.page === 1 ? selectedHeadingstyles : {}} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Applied Jobs</div></div>
                        <p className="hiringHeader">Ongoing Jobs</p>
                        <div onClick={() => this.setState({ page: 2 })} style={this.state.page === 2 ? selectedHeadingstyles : {}} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Hourly Jobs</div></div>
                        <div onClick={() => this.setState({ page: 3 })} style={this.state.page === 3 ? selectedHeadingstyles : {}} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Fixed Fee Jobs</div></div>
                        <p className="hiringHeader">Completed Jobs</p>
                        <div onClick={() => this.setState({ page: 4 })} style={this.state.page === 4 ? selectedHeadingstyles : {}} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Work History</div></div>
                        <p className="hiringHeader">My Calendar</p>
                        <div onClick={() => this.setState({ page: 5 })} style={this.state.page === 5 ? selectedHeadingstyles : {}} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Current Calendar</div></div>
                        <div onClick={() => this.setState({ page: 6 })} style={this.state.page === 6 ? { ...selectedHeadingstyles, marginBottom: 50 } : { marginBottom: 50 }} className="hiringStepperLabelStyles"><div className="hiringStepperInternalHeading">Calendar Set up</div></div>
                    </div>
                </div>
                <div style={{ width: "75.2%", padding: 20 }}>
                    <div style={{ width: "100%", marginTop: "3.3vw" }}>
                        {pages[this.state.page]}
                    </div>
                </div>
            </div>
        )
    }
}

export default EmployeeHiringProcess;
