import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import './post_job.scss';
import { connect } from 'react-redux';
import moment from 'moment';
import { INCREMENT, DECREMENT, RESET, LOADER, SET_ERRORS } from '../../action/form_action';
import { CLEAR } from '../../action/job_post_action';

import DrawerMenu from './components/drawer/drawer_menu';
import JobDescription from './tabs/JobDescription/job_description';
import SkillsExperience from './tabs/SkillsExperience/skills_experience';
import FeePayment from './tabs/FeePayment/fee_payment';
import WorkScheduleHourly from './tabs/WorkScheduleHourly/work_schedule_hourly';
import WorkSchedule from './tabs/WorkSchedule/work_schedule';
import Location from './tabs/Location/location';

import Constant from './Constant/constant';

import ScreenSize from './components/screen_size';
import ScreenSizeDiv from './components/screen_size_dv';

import Spacer from './components/spacer';
import Text from './components/text';
import CentralizeButton from './components/centralize_button';
import Loader from './components/loader';

import {
    VERTICAL_NORMAL,
    VERTICAL_EXTRA,
    VERTICAL_SMALL,
    VERTICAL_EXTRA_LARGE,
    HORIZONTAL_NORMAL,
} from '../../utils/enums';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
import { getProfileData } from '../../api/profile';
import { postJobData } from '../../api/post_a_job';
import Swal from 'sweetalert2';

class PostJob extends React.Component {
    constructor(props) {
        super(props);
        this.props.reset();
        this.props.getProfileData();
    }

    render() {
        const {
            selectionValue,
            jobTitle,
            jobDescription,
            attachmentURL,
            additionalUserInstruction,
            fieldOfWork,
            isHourly,
            feeType,
            hourlyRate,
            feeAmount,
            paymentMethod,
            paymentTime,
            workScheduleHourlyList,
            jobPostCloseDate,
            jobStartDate,
            jobCloseDate,
            jobPostCloseDateFormat,
            jobStartDateFormat,
            jobCloseDateFormat,
            locationList,
            addressType,
            address,
            isNextButtonDisable,
            specialityValue,
            suggestedSkillsValue,
            additionalSkillsValue,
            experienceValue,
            refreshData,
            fieldOfWorkObject,
            specialityObject,
            showLoaderValue,
            statusJobPost,
            dataJobPost,
            errorMessageJobPost,
            userid,
            hourlyRateMinValue,
            hourlyRateMaxValue,
            totalWorking,
            jobPostLocation,
            locations,
        } = this.props;

        const validateLastStep = () => {
            let canMoveNext = true;
            let errors = {};

            if (!isHourly) {
                if (jobStartDateFormat === undefined) {
                    canMoveNext = false;
                    errors.jobStartDateFormat = true;
                } else if (jobCloseDateFormat === undefined) {
                    canMoveNext = false;
                    errors.jobCloseDateFormat = true;
                } else if (jobPostCloseDateFormat === undefined) {
                    canMoveNext = false;
                    errors.jobPostCloseDateFormat = true;
                } else {
                    canMoveNext = true;
                    errors = {};
                }
            } else {
                if (workScheduleHourlyList.length === 0) {
                    canMoveNext = false;
                    errors.workScheduleHourlyList = true;
                } else if (jobPostCloseDateFormat === undefined) {
                    canMoveNext = false;
                    errors.jobPostCloseDateFormat = true;
                } else {
                    canMoveNext = true;
                    errors = {};
                }
            }

            this.props.setError(errors);
            return canMoveNext;
        };

    const is_suspend = localStorage.getItem('is_suspend') || false;
    const disabledDate = moment(localStorage.getItem('suspended_date')) || moment();
    const month_data = disabledDate.add(1, 'months');
    const disabledBtn = is_suspend === 'true' ? month_data.isAfter(moment()) : false;

        return (
            <div>
                {(() => {
                    switch (statusJobPost) {
                        case 'LOADING':
                            break;
                        case 'ERROR':
                            setTimeout(() => {
                                if (this.props.loader) {
                                    this.props.loader(false);
                                    this.props.jobPostDone();
                                    Swal.fire({
                                        customClass: 'Alert-Class',
                                        title: 'Error!',
                                        text: 'Error while posting a job',
                                        icon: 'error',
                                        confirmButtonText: 'Ok',
                                        confirmButtonColor: '#1876D2',
                                    });
                                }
                            }, 0);
                            break;
                        case 'SUCCESS':
                            setTimeout(() => {
                                if (this.props.loader) {
                                    this.props.loader(false);
                                    this.props.jobPostDone();
                                    Swal.fire({
                                        customClass: 'Alert-Class',
                                        title: 'Success!',
                                        text: 'Job Posted Successfully',
                                        icon: 'success',
                                        confirmButtonText: 'Ok',
                                        confirmButtonColor: '#1876D2',
                                    }).then((result) => {
                                        if (result) {
                                            window.location = '/talent-search/advance';
                                        }
                                    });
                                }
                                // Navigate here to any page
                            }, 0);
                            break;
                        default:
                    }
                })()}
                <ScreenSizeDiv
                    mobile="Root-Mobile"
                    tablet="Root-Tablet"
                    web="Root"
                    child={
                        <>
                            <Drawer variant="permanent" anchor="left" className="Drawer">
                                <DrawerMenu
                                    selectedValue={selectionValue}
                                    data={[
                                        'Job Decription',
                                        'Location',
                                        'Skills & Experience',
                                        'Fee & Payment',
                                        'Works Schedule',
                                    ]}
                                />
                            </Drawer>

                            <ScreenSizeDiv
                                mobile="Container-Style-Mobile"
                                tablet="Container-Style-Tablet"
                                web="Container-Style"
                                child={
                                    <>
                                        <Spacer spaceType={VERTICAL_NORMAL} />

                                        <div>
                                            {(() => {
                                                switch (selectionValue) {
                                                    case 0:
                                                        return (
                                                            <ScreenSize
                                                                mobile={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Job Description"
                                                                    />
                                                                }
                                                                tablet={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Job Description"
                                                                    />
                                                                }
                                                                web={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_WEB
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Job Description"
                                                                    />
                                                                }
                                                            />
                                                        );
                                                        break;
                                                    case 1:
                                                        return (
                                                            <ScreenSize
                                                                mobile={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Location"
                                                                    />
                                                                }
                                                                tablet={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Location"
                                                                    />
                                                                }
                                                                web={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_WEB
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Location"
                                                                    />
                                                                }
                                                            />
                                                        );
                                                        break;
                                                    case 2:
                                                        return (
                                                            <ScreenSize
                                                                mobile={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Skills & Experience"
                                                                    />
                                                                }
                                                                tablet={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Skills & Experience"
                                                                    />
                                                                }
                                                                web={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_WEB
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Skills & Experience"
                                                                    />
                                                                }
                                                            />
                                                        );
                                                        break;
                                                    case 3:
                                                        return (
                                                            <ScreenSize
                                                                mobile={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Fee & Payment"
                                                                    />
                                                                }
                                                                tablet={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Fee & Payment"
                                                                    />
                                                                }
                                                                web={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_WEB
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Fee & Payment"
                                                                    />
                                                                }
                                                            />
                                                        );
                                                        break;
                                                    case 4:
                                                        return (
                                                            <ScreenSize
                                                                mobile={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Work Schedule"
                                                                    />
                                                                }
                                                                tablet={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_MOBILE
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Work Schedule"
                                                                    />
                                                                }
                                                                web={
                                                                    <Text
                                                                        fontSize={
                                                                            Constant.HEADER_WEB
                                                                        }
                                                                        color={
                                                                            Constant.COLOR_BLACK_500
                                                                        }
                                                                        text="Work Schedule"
                                                                    />
                                                                }
                                                            />
                                                        );
                                                        break;
                                                }
                                            })()}
                                        </div>

                                        <Spacer spaceType={VERTICAL_EXTRA_LARGE} />

                                        <div className="Fill-Remaining-Space">
                                            {(() => {
                                                switch (selectionValue) {
                                                    case 0:
                                                        return <JobDescription />;
                                                        break;
                                                    case 1:
                                                        return <Location />;
                                                        break;
                                                    case 3:
                                                        return <FeePayment />;
                                                        break;
                                                    case 4:
                                                        return isHourly ? (
                                                            <WorkScheduleHourly />
                                                        ) : (
                                                            <WorkSchedule />
                                                        );
                                                        break;
                                                    case 2:
                                                        return <SkillsExperience />;
                                                        break;
                                                }
                                            })()}
                                        </div>
                                        <Spacer spaceType={VERTICAL_EXTRA_LARGE} />
                                        <div className="Bottom-Buttons-Parent">
                                            {selectionValue !== 0 ? (
                                                <div className="Back-Button-Parent">
                                                    <ScreenSize
                                                        mobile={
                                                            <Text
                                                                fontSize={Constant.FONT_SIZE_MOBILE}
                                                                color={Constant.COLOR_BLACK_300}
                                                                text="Back"
                                                                cursor={'pointer'}
                                                                padding={'20px 0px 0px 0px'}
                                                                onClickCallback={() => {
                                                                    this.props.decrement();
                                                                }}
                                                            />
                                                        }
                                                        tablet={
                                                            <Text
                                                                fontSize={Constant.FONT_SIZE_MOBILE}
                                                                color={Constant.COLOR_BLACK_300}
                                                                text="Back"
                                                                cursor={'pointer'}
                                                                padding={'20px 0px 0px 0px'}
                                                                onClickCallback={() => {
                                                                    this.props.decrement();
                                                                }}
                                                            />
                                                        }
                                                        web={
                                                            <Text
                                                                fontSize={Constant.FONT_SIZE_WEB}
                                                                color={Constant.COLOR_BLACK_300}
                                                                text="Back"
                                                                cursor={'pointer'}
                                                                padding={'10px 0px 0px 0px'}
                                                                onClickCallback={() => {
                                                                    this.props.decrement();
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div className="Back-Button-Parent"> </div>
                                            )}

                                            <ScreenSize
                                                mobile={
                                                    <CentralizeButton
                                                        fontSize={Constant.BUTTON_FONT_SIZE_MOBILE}
                                                        lable={
                                                            selectionValue === 4
                                                                ? 'Post Job'
                                                                : 'Next'
                                                        }
                                                        isButtonDisable={selectionValue === 4 ? disabledBtn : false}
                                                        onClickCallback={(value) => {
                                                            if (selectionValue !== 4) {
                                                                this.props.increment();
                                                            }
                                                            if (selectionValue == 4) {
                                                                const postDataObject = {
                                                                    jobTitle,
                                                                    jobDescription,
                                                                    attachmentURL,
                                                                    additionalUserInstruction,
                                                                    fieldOfWork,
                                                                    isHourly,
                                                                    feeType,
                                                                    hourlyRate,
                                                                    feeAmount,
                                                                    paymentMethod,
                                                                    paymentTime,
                                                                    workScheduleHourlyList,
                                                                    jobPostCloseDate,
                                                                    jobStartDate,
                                                                    jobCloseDate,
                                                                    jobPostCloseDateFormat,
                                                                    jobStartDateFormat,
                                                                    jobCloseDateFormat,
                                                                    locationList,
                                                                    addressType,
                                                                    address,
                                                                    specialityValue,
                                                                    suggestedSkillsValue,
                                                                    additionalSkillsValue,
                                                                    experienceValue,
                                                                    fieldOfWorkObject,
                                                                    specialityObject,
                                                                    userid,
                                                                    hourlyRateMinValue,
                                                                    hourlyRateMaxValue,
                                                                    totalWorking,
                                                                    locations
                                                                };
                                                                if (validateLastStep()) {
                                                                    this.props.loader(true);
                                                                    this.props.postJobData(
                                                                        postDataObject
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                                tablet={
                                                    <CentralizeButton
                                                        fontSize={Constant.BUTTON_FONT_SIZE_MOBILE}
                                                        lable={
                                                            selectionValue === 4
                                                                ? 'Post Job'
                                                                : 'Next'
                                                        }
                                                        isButtonDisable={selectionValue === 4 ? disabledBtn : false}
                                                        onClickCallback={(value) => {
                                                            if (selectionValue !== 4) {
                                                                this.props.increment();
                                                            }
                                                            if (selectionValue === 4) {
                                                                const postDataObject = {
                                                                    jobTitle,
                                                                    jobDescription,
                                                                    attachmentURL,
                                                                    additionalUserInstruction,
                                                                    fieldOfWork,
                                                                    isHourly,
                                                                    feeType,
                                                                    hourlyRate,
                                                                    feeAmount,
                                                                    paymentMethod,
                                                                    paymentTime,
                                                                    workScheduleHourlyList,
                                                                    jobPostCloseDate,
                                                                    jobStartDate,
                                                                    jobCloseDate,
                                                                    jobPostCloseDateFormat,
                                                                    jobStartDateFormat,
                                                                    jobCloseDateFormat,
                                                                    locationList,
                                                                    addressType,
                                                                    address,
                                                                    specialityValue,
                                                                    suggestedSkillsValue,
                                                                    additionalSkillsValue,
                                                                    experienceValue,
                                                                    fieldOfWorkObject,
                                                                    specialityObject,
                                                                    userid,
                                                                    hourlyRateMinValue,
                                                                    hourlyRateMaxValue,
                                                                    totalWorking,
                                                                    locations
                                                                };
                                                                if (validateLastStep()) {
                                                                    this.props.loader(true);
                                                                    this.props.postJobData(
                                                                        postDataObject
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                                web={
                                                    <CentralizeButton
                                                        fontSize={Constant.BUTTON_FONT_SIZE_WEB}
                                                        lable={
                                                            selectionValue === 4
                                                                ? 'Post Job'
                                                                : 'Next'
                                                        }
                                                        isButtonDisable={selectionValue === 4 ? disabledBtn : false}
                                                        onClickCallback={(value) => {
                                                            if (selectionValue !== 4) {
                                                                this.props.increment();
                                                            }
                                                            if (selectionValue === 4) {
                                                                const postDataObject = {
                                                                    jobTitle,
                                                                    jobDescription,
                                                                    attachmentURL,
                                                                    additionalUserInstruction,
                                                                    fieldOfWork,
                                                                    isHourly,
                                                                    feeType,
                                                                    hourlyRate,
                                                                    feeAmount,
                                                                    paymentMethod,
                                                                    paymentTime,
                                                                    workScheduleHourlyList,
                                                                    jobPostCloseDate,
                                                                    jobStartDate,
                                                                    jobCloseDate,
                                                                    jobPostCloseDateFormat,
                                                                    jobStartDateFormat,
                                                                    jobCloseDateFormat,
                                                                    locationList,
                                                                    addressType,
                                                                    address,
                                                                    specialityValue,
                                                                    suggestedSkillsValue,
                                                                    additionalSkillsValue,
                                                                    experienceValue,
                                                                    fieldOfWorkObject,
                                                                    specialityObject,
                                                                    userid,
                                                                    hourlyRateMinValue,
                                                                    hourlyRateMaxValue,
                                                                    totalWorking,
                                                                    jobPostLocation,
                                                                    locations,
                                                                };
                                                                if (validateLastStep()) {
                                                                    this.props.loader(true);
                                                                    this.props.postJobData(
                                                                        postDataObject
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </div>

                                        <Spacer spaceType={VERTICAL_EXTRA_LARGE} />
                                    </>
                                }
                            />
                        </>
                    }
                ></ScreenSizeDiv>

                <Loader loaderState={showLoaderValue}></Loader>
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        selectionValue: state.formReducer.selectionValue,

        jobTitle: state.formReducer.jobTitle,
        jobDescription: state.formReducer.jobDescription,
        attachmentURL: state.formReducer.attachmentURL,
        additionalUserInstruction: state.formReducer.additionalUserInstruction,
        fieldOfWork: state.formReducer.fieldOfWork,
        fieldOfWorkObject: state.formReducer.fieldOfWorkObject,
        specialityObject: state.formReducer.specialityObject,

        isHourly: state.formReducer.isHourly,
        feeType: state.formReducer.feeType,
        hourlyRateMinValue: state.formReducer.hourlyRateMin,
        hourlyRateMaxValue: state.formReducer.hourlyRateMax,
        feeAmount: state.formReducer.feeAmount,
        paymentMethod: state.formReducer.paymentMethod,
        paymentTime: state.formReducer.paymentTime,

        workScheduleHourlyList: state.formReducer.workScheduleHourlyList,
        jobPostCloseDate: state.formReducer.jobPostCloseDate,
        jobStartDate: state.formReducer.jobStartDate,
        jobCloseDate: state.formReducer.jobCloseDate,
        jobPostCloseDateFormat: state.formReducer.jobPostCloseDateFormat,
        jobStartDateFormat: state.formReducer.jobStartDateFormat,
        jobCloseDateFormat: state.formReducer.jobCloseDateFormat,

        locationList: state.formReducer.locationList,
        addressType: state.formReducer.addressType,
        address: state.formReducer.address,

        isNextButtonDisable: state.formReducer.isNextButtonDisable,
        specialityValue: state.formReducer.speciality,
        suggestedSkillsValue: state.formReducer.suggestedSkills,
        additionalSkillsValue: state.formReducer.additionalSkills,
        experienceValue: state.formReducer.experienceRange,

        refreshData: state.formReducer.refreshData,
        showLoaderValue: state.formReducer.showLoader,

        statusJobPost: state.jobPostReducer.status,
        dataJobPost: state.jobPostReducer.data,
        errorMessageJobPost: state.jobPostReducer.errorMessage,

        userid: state.profileReducer.userid,
        totalWorking: state.formReducer.totalWorking,
        isHourlyValue: state.formReducer.isHourly,

        jobPostLocation: state.formReducer.jobPostLocation,
        locations: state.formReducer.jobLocations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        jobPostDone: () => dispatch({ type: CLEAR }),
        postJobData: (postDataObject) => {
            dispatch(postJobData(postDataObject));
        },
        getProfileData: () => {
            dispatch(getProfileData());
        },
        reset: () => dispatch({ type: RESET }),
        loader: (value) => dispatch({ type: LOADER, showLoader: value }),
        increment: () => dispatch({ type: INCREMENT }),
        decrement: () => dispatch({ type: DECREMENT }),
        setError: (value) => dispatch({ type: SET_ERRORS, error: value }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);
