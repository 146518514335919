import React from "react";
import TextField from "@material-ui/core/TextField/TextField";
import moment from "moment";
import { Select, MenuItem, Avatar } from "@material-ui/core";
import ContainedButtons from "../../../../Components/Button/Button";
import TextBox from "../../../../Components/TextField/Textfield";
import Dropdown from "../../../../Components/DropdownComponent/Dropdown.component";
import { deepCopy } from "../../../../utils/commonFunction";

const TimeSheetForm = ({
  reasonWorkModify,
  setReasonWorkModify,
  updateTimeSheet,
  timeSheetData,
}) => {
  const [data, setData] = React.useState(deepCopy(timeSheetData));
  const [hourError, setHourError] = React.useState(false);

  const onSelectChange = (selected, index) => {
    data[index].modifyReason = selected.value;
    setData([...data]);
  };

  const onChange = (e, index) => {
    data[index].totalHours = parseInt(e.target.value);
    setData([...data]);

    const errorIndex = data.findIndex(
      (item, index) =>
        item?.totalHours > timeSheetData[index].totalHours ||
        item?.totalHours < 0
    );

    if (errorIndex !== -1) setHourError(true);
    else setHourError(false);
  };

  return (
    <div style={{ marginLeft: 60, marginRight: 60, marginTop: 75 }}>
      <h3
        style={{ fontSize: 26, fontFamily: "Libre Franklin", fontWeight: 400 }}
      >
        Modify Actual Worked Hours
      </h3>
      <div style={{ marginTop: 30, marginRight: 20, marginBottom: 23 }}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 20,
              }}
            >
              <Avatar
                style={{ backgroundColor: "#1876D2", height: 54, width: 54 }}
              >
                {moment
                  .utc(item.dateSchedule)
                  .format("dddd")[0]
                  .toUpperCase() || "M"}
              </Avatar>
              <TextField
                error={
                  item?.totalHours > timeSheetData[index].totalHours ||
                  item?.totalHours < 0
                }
                required={false}
                label=""
                type="number"
                defaultValue={item?.totalHours || ""}
                variant="outlined"
                style={{ width: "25%", marginLeft: 15 }}
                onChange={(e) => {
                  onChange(e, index);
                }}
                placeholder=""
                value={item?.totalHours || ""}
              />
              <div style={{ width: "75s%", marginLeft: 20, flex: "1" }}>
                <Dropdown
                  value={{
                    label: item?.modifyReason,
                    value: item?.modifyReason,
                  }}
                  onChange={(value) => onSelectChange(value, index)}
                  options={[
                    { label: "No show up", value: "No show up" },
                    {
                      label: "Late show up/Early leave",
                      value: "Late show up/Early leave",
                    },
                    {
                      label: "Emergency situation",
                      value: "Emergency situation",
                    },
                    { label: "Other", value: "Other" },
                  ]}
                />
              </div>
            </div>
          ))}
      </div>
      {hourError && (
        <p
          style={{
            marginBottom: 8,
            marginTop: 24,
            color: "red",
          }}
        >
         Hours should be less than scheduled hours
        </p>
      )}

      <div>
        <TextBox
          label={"Other reasons for time modifications:"}
          onChange={(e) => {
            setReasonWorkModify(e.target.value);
          }}
          value={reasonWorkModify}
          multiline={true}
          rows={4}
          width={"96%"}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: 36,
          marginBottom: 50,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ContainedButtons
          text="Apply"
          onClick={() => {
            updateTimeSheet(data);
          }}
          color={["#2493F1", "#15568D"]}
          height={48}
          width={190}
          disabled={hourError}
        />
      </div>
    </div>
  );
};

export default TimeSheetForm;
