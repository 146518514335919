import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Chip from '@material-ui/core/Chip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Swal from 'sweetalert2'
import Text from './text'
import ScreenSize from './screen_size'
import Constant from '../Constant/constant'


const Attachment = (props) => {
  const [chipsList, setChipList] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const inputRef = useRef()

  useEffect(() => {
    if (props.selectedList !== undefined && props.selectedList.length > 0) {
      setChipList(props.selectedList);
    }
  }, [props.searchList]);


  const changeHandler = (event) => {

    var fileList = event.target.files;

    var fileSize = 0;
    for (var i = 0; i < fileList.length; i++) {
      fileSize = fileSize + fileList[i].size;
    }

    // Keep below comments for now ---
    // for (var i = 0; i < chipsList.length; i++) {
    //   fileSize =  fileSize + chipsList[i].size;
    // }

    var totalSizeMB = fileSize / Math.pow(1000, 2)

    if (totalSizeMB <= 10) {
      if (chipsList.length < 3) {
        if (props.onSelectedValueCallback !== undefined) {
          props.onSelectedValueCallback(fileList);
        }
        setChipList([...chipsList, ...fileList]);
      } else {
        Swal.fire({
          customClass: "Alert-Class",
          title: 'Information!',
          text: "Maximum 3 file attachment allow",
          icon: 'info',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#1876D2',
        });
      }
    } else {
      Swal.fire({
        customClass: "Alert-Class",
        title: 'Information!',
        text: "Exceed maximun file size of 10 MB",
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#1876D2',
      });
    }
  };


  const handleDelete = (data) => {
    setChipList(chipsList.filter(item => item.name !== data.name));
    if (props.onRemoveValueCallback !== undefined) {
      props.onRemoveValueCallback(data);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    var fileList = e.dataTransfer.files;

    var fileSize = 0;
    for (var i = 0; i < fileList.length; i++) {
      fileSize = fileSize + fileList[i].size;
    }
    for (var i = 0; i < chipsList.length; i++) {
      fileSize = fileSize + chipsList[i].size;
    }

    var totalSizeMB = fileSize / Math.pow(1000, 2)

    if (totalSizeMB <= 10) {
      if (chipsList.length < 3 && fileList.length < 3) {
        if (props.onSelectedValueCallback !== undefined) {
          props.onSelectedValueCallback(fileList);
        }
        setChipList([...chipsList, ...fileList]);
      } else {
        Swal.fire({
          customClass: "Alert-Class",
          title: 'Information!',
          text: "Maximum 3 file attachment allow",
          icon: 'info',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#1876D2',
        });
      }
    } else {
      Swal.fire({
        customClass: "Alert-Class",
        title: 'Information!',
        text: "Exceed maximun file size of 10 MB",
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#1876D2',
      });
    }

  };

  return (
    <div className="Dropbox-Wrapper" onClick={() => inputRef.current.click()}
      onDrop={e => handleDrop(e)}>
      <input multiple type="file" style={{ display: 'none' }} ref={inputRef} onChange={changeHandler} />
      <div className="fileAttachment-Parent">
        <input type="file" id="filePicker" style={{ visibility: "hidden" }} multiple onChange={changeHandler} />

        <ScreenSize
          mobile={<Text fontSize={Constant.FONT_SIZE_MOBILE} color={Constant.COLOR_BLACK_400} alignment={"center"} text="Drag and drop or Upload a maximum of 3 files up to 10MB each" />}
          tablet={<Text fontSize={Constant.FONT_SIZE_MOBILE} color={Constant.COLOR_BLACK_400} alignment={"center"} text="Drag and drop or Upload a maximum of 3 files up to 10MB each" />}
          web={<Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_400} alignment={"center"} text="Drag and drop or Upload a maximum of 3 files up to 10MB each" />}
        />

        <ScreenSize
          mobile={<div style={{ textAlign: "center", color: Constant.COLOR_ATTACHMENT, fontSize: Constant.ATTACHMENT_ICON_SIZE_MOBILE, marginTop: "5px" }}><CloudDownloadIcon fontSize={"inherit"} /></div>}
          tablet={<div style={{ textAlign: "center", color: Constant.COLOR_ATTACHMENT, fontSize: Constant.ATTACHMENT_ICON_SIZE_MOBILE, marginTop: "5px" }}><CloudDownloadIcon fontSize={"inherit"} /></div>}
          web={<div style={{ textAlign: "center", color: Constant.COLOR_ATTACHMENT, fontSize: Constant.ATTACHMENT_ICON_SIZE_WEB, marginTop: "5px" }}><CloudDownloadIcon fontSize={"inherit"} /></div>}
        />

      </div>
      <div className="Centralize-Search-Chips-List-Parent-Small">
        {chipsList.map((data, index) => (
          <div style={{ marginLeft:"2%",maxWidth:"30%"}} key={index} className={props.customChipsClassName ?? "Centralize-Search-Chips-Parent-Small"}>
            <Chip key={index}
              onClick={() => {
                handleDelete(data);
              }}
              label={data.name}
              onDelete={() => {
                handleDelete(data);
              }} />
          </div>

        ))}
      </div>
    </div>
  );
}

export default Attachment;
