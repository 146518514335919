import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './extendedpages.scss';
//import '../../../Assets/dev-style.scss';
import EmpolinkLogo from '../../Assets/images/logo.svg';
import InvisibleLogo from '../../Assets/images/invisable-logo.svg';
import FooterPublic from '../../Components/FooterPublic/FooterPublic';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import { Helmet } from 'react-helmet';

class adminjobs extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink - Your source for admin jobs</title>
                    <meta name="keywords" content="Administrative Assistant Jobs, Business Analyst Jobs, Business Operation Assistant Jobs, Client Service Administrator Jobs, Compliance Officer Jobs, Data Entry Operator Jobs, Desktop Publisher Jobs, Employment Programs Analyst Jobs, Human Resources Administrator Jobs, Logistician Jobs, Market Research Analyst Jobs, Medical Office Assistant Jobs, Meeting & Event Planner Jobs, Occupational Health & Safety Specialist Jobs, Office Assistant Jobs, Office Supervisor Jobs, Order Filler Jobs, Organizational Development Planner Jobs, Paralegal & Legal Assistant Jobs, Postal Service Clerk Jobs, Quality Controller Jobs, Receptionist Jobs, Sign Language Interpreter Jobs, Training and Development Officer Jobs, Translator Jobs" />
                    <meta name="description" content="Empolink connects clients and talents quickly and efficiently. Discover a platform designed for seamless interactions and services." />
                    <meta property="og:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta property="og:description" content="Empolink bridges the gap between clients and talents, providing a streamlined platform for finding and offering services." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@@empolink" />
                    <meta name="twitter:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta name="twitter:description" content="Explore diverse freelance opportunities and connect with clients who need your skills on Empolink." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>

                </Helmet>
                <HeaderPublic /> {/* From HeaderPublic component */}
                <section class="image-text image-text--right section-padding">
                    <div class="container">
                        <div class="row flex-row flex-direction">
                            <div className="col-md-6 image-text__half">
                                    <div className="specialtieslist-box">
                                        <ul>
                                        <li>Administrative Assistant</li>
                                        <li>Business Operation Assistant</li>
                                        <li>Client Service Administrator</li>
                                        <li>Compliance Officer</li>
                                        <li>Data Entry Operator</li>
                                        <li>Desktop Publisher</li>
                                        <li>Human Resources Administrator</li>
                                        <li>Market Research Analyst</li>
                                        <li>Medical Office Assistant</li>
                                        <li>Meeting & Event Planner</li>
                                        <li>Occupational Health & Safety Specialist</li>
                                        <li>Office Assistant</li>
                                        <li>Paralegal & Legal Assistant</li>
                                        <li>Postal Service Clerk</li>
                                        <li>Quality Controller</li>
                                        <li>Receptionist</li>
                                        <li>Sign Language Interpreter</li>
                                        <li>Training and Development Officer</li>
                                        <li>Translator</li>
                                        </ul>
                                    </div>
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    READY TO EXCEL IN AN <span>OFFICE</span> ROLE?
                                </h2>
                                <p>Join our platform to connect with clients seeking skilled professionals in Office Management and Administration. Whether you're an efficient Executive Assistant, detail-oriented Data Entry Specialist, organized Office Manager, or have expertise in other administrative areas, your next rewarding opportunity is just a click away! Leverage your organizational talents and enjoy the flexibility of freelancing in the essential world of office administration.</p>
                                <p></p>
                                <p></p>                                
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                                <p></p>
                                
                            </div>
                            
                        </div>
                    </div>
                </section>

                <FooterPublic />
            </>
        );
    }
}

export default withRouter(adminjobs);
