import React, { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";

import "../HiringProcess.scss";

import Dropdown from "../../../Components/DropdownComponent/Dropdown.component";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import ContainedButtons from "../../../Components/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DrawerComponent from "../../../Components/Drawer/Drawer";
import TimeSheetForm from "./Component/TimeSheetForm";
import { APIHelper } from "../../../utils/ApiHelper";
import API from "../../../utils/Urls";

const TimeSheetReview = (props) => {
  const [reviewData, setReviewData] = useState({});
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [reasonWorkModify, setReasonWorkModify] = useState("");
  const [week, setWeek] = useState("");
  const [weekCount, setWeekCount] = useState(1);
  const [editTimeSheet, setEditTimeSheet] = useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(1);
  const [scheduleDate, setScheduleDate] = React.useState([]);
  const [weekStarts, setWeekStart] = React.useState(moment());
  const [weekEnds, setWeekEnd] = React.useState(moment());
  const [sortBy, setSortBy] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [currentTimeSheetDate, setCurrentTimeSheetDate] = React.useState([]);
  const [modifiedButtonDisabled, setModifiedButtonDisabled] =
    React.useState(false);

  useEffect(() => {
    setWeek(getCurrentWeek());
  }, [weekCount]);

  useEffect(() => {
    setScheduleDate(reviewData.schedule);
    setTimeSheetData(reviewData.timesheet);
  }, [reviewData]);

  // useEffect(() => {
  //     setWeek(getCurrentWeekCount());
  // }, [weekCount]);

  useEffect(() => {
    let dates = enumerateDaysBetweenDates(weekStarts, weekEnds);
    setCurrentTimeSheetDate(dates);
  }, [weekStarts, weekEnds, reviewData.schedule]);

  let schHrs = [];
  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];
    reviewData &&
      reviewData.schedule &&
      reviewData.schedule.length > 0 &&
      reviewData.schedule.forEach((date) => {
        if (
          (moment.utc(date.dateSchedule, 'YYYY-MM-DDT00:00:00:000Z').format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD'))
          &&
          (moment.utc(date.dateSchedule, 'YYYY-MM-DDT00:00:00:000Z').format('YYYY-MM-DD') <= moment(endDate).format('YYYY-MM-DD'))
        ) {
          dates.push(date);
        }
        // if (
        //   moment(date.dateSchedule) >= moment(startDate).utc(0) &&
        //   (moment(
        //     moment
        //       .utc(date.dateSchedule, "YYYY-MM-DDT00:00:00:000Z")
        //       .format("YYYY-MM-DD")
        //   ) <= moment(moment(endDate).utc(0).format("YYYY-MM-DD")) ||
        //     moment.utc(date.dateSchedule).format("DD-MM-YYYY") ===
        //     moment(endDate).utc(0).format("DD-MM-YYYY"))
        // ) {
        //   dates.push(date);
        // }
      });
    reviewData &&
      reviewData.timesheet &&
      reviewData.timesheet.length > 0 &&
      reviewData.timesheet.forEach((date) => {
        if (
          (moment.utc(date.dateSchedule, 'YYYY-MM-DDT00:00:00:000Z').format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD'))
          &&
          (moment.utc(date.dateSchedule, 'YYYY-MM-DDT00:00:00:000Z').format('YYYY-MM-DD') <= moment(endDate).format('YYYY-MM-DD'))
        ) {
          schHrs.push(date.totalHours);
        }
        // if (
        //   moment(date.dateSchedule) >= moment(startDate) &&
        //   (moment(
        //     moment
        //       .utc(date.dateSchedule, "YYYY-MM-DDT00:00:00:000Z")
        //       .format("YYYY-MM-DD")
        //   ) <= moment(moment(endDate).utc(0).format("YYYY-MM-DD")) ||
        //     moment.utc(date.dateSchedule).format("DD-MM-YYYY") ===
        //     moment(endDate).utc(0).format("DD-MM-YYYY"))
        // ) {
        //   schHrs.push(date.totalHours)
        // }
      });
    return dates;
  };

  React.useEffect(() => {
    getListTimeSheet();
  }, [page, sortBy]);

  // const getReturnStatus = (status, startDate, timeLeft) => {
  //     switch (status) {
  //         case 'COMPLETED':
  //             return { status: 'COMPLETED' };
  //         case 'CANCELLATION_REQUESTED':
  //             return { status: 'CANCELLED' };
  //         case 'CANCELLED':
  //             return { status: 'CANCELLED' };
  //         case 'STARTED':
  //             const isStart = moment(startDate) >= moment();
  //             if (isStart) {
  //                 return { status: 'NOT_STARTED' };
  //             } else {
  //                 return { status: 'IN_PROGRESS' };
  //             }
  //         default:
  //             return { status: 'IN_PROGRESS' };
  //     }
  // };

  const getListTimeSheet = () => {
    setLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(
      API.GET_LIST_TIMESHEET_JOBS +
      `page=${page}&sort=${sortBy ? sortBy.value : ""}`,
      headers
    )
      .then((res) => {
        setData(res[0].data);
        setCount(res[0].total);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // const getCurrentWeekCount = () => {
  //     const weekStart = moment().subtract(weekCount, 'weeks').startOf('week').add(1, 'day');
  //     const weekEnd = moment().subtract(weekCount, 'weeks').endOf('week').add(1, 'day');
  //     setWeekStart(weekStart);
  //     setWeekEnd(weekEnd);

  //     return weekStart.format('MMM D, YY') + ' - ' + weekEnd.format('MMM D, YY');
  // };

  const getCurrentWeek = () => {
    const wks = GetLastWeekStart().utc();
    const wke = GetLastWeekEnd().utc();

    // const weekStart = moment().subtract(weekCount, 'weeks').startOf('week').add(1, 'day');
    // const weekEnd = moment().subtract(weekCount, 'weeks').endOf('week').add(1, 'day');
    setWeekStart(wks);
    setWeekEnd(wke);

    return wks.utc().format("MMM D, YY") + " - " + wke.utc().format("MMM D, YY");
  };

  const GetLastWeekStart = () => {
    var today = moment.utc();

    var daystoLastSunday = 0 - (7 - today.isoWeekday()) + 7;
    var lastSunday = today.subtract(
      "days",
      daystoLastSunday + (weekCount === 1 ? 0 : (weekCount - 1) * 7)
    );

    // var daystoLastMonday = 0 - (1 - today.isoWeekday()) + 6;
    var lastMonday = lastSunday.subtract("days", 6);

    return lastMonday;
  };

  const GetLastWeekEnd = () => {
    var lastMonday = GetLastWeekStart();

    var lastSunday = lastMonday.add("days", 6);

    return lastSunday;
  };

  // const onSelectChange = (selected, index) => {
  //     const data = currentTimeSheetDate;
  //     data[index].selectedValue = selected.value;
  //     setCurrentTimeSheetDate([...data]);
  //     console.log('updated', currentTimeSheetDate);
  // };

  // const onChange = (e, index) => {
  //     const data = currentTimeSheetDate;
  //     data[index].totalHours = e.target.value;
  //     setCurrentTimeSheetDate([...data]);
  //     console.log('updated', currentTimeSheetDate);
  // };

  const updateTimeSheet = (timeSheetData) => {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    let data = {
      timesheet: timeSheetData,
      otherModifyReason: reasonWorkModify,
    };
    APIHelper.putMethod(
      `${API.UPDATE_TIMESHEET}${reviewData._id}`,
      data,
      headers
    )
      .then((res) => {
        setReviewData(res);
        setEditTimeSheet(false);
        Swal.fire({
          customClass: "Alert-Class",
          title: "Success!",
          text: "Timesheet reviews are updated successfully.",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1876D2",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReviewButtonEnabled = (item) => {
    var dates = [];
    item &&
      item.timesheet &&
      item.timesheet.length > 0 &&
      item.timesheet.forEach((date) => {
        if (
          moment.utc(date?.dateSchedule) >= moment.utc(weekStarts).add("1", "day") &&
          (moment.utc(date?.dateSchedule) <= moment.utc(weekEnds) ||
            moment.utc(date?.dateSchedule).format("DD-MM-YYYY") ===
              moment.utc(weekEnds).format("DD-MM-YYYY"))
        ) {
          dates.push(date);
        }
      });
    return dates.length === 0;
  };

  const timeLefttoModify = () => {
    const currentDay = moment().day();
    if (currentDay > 3 || currentDay < 1) {
      return { status: "TIME_LEFT", time: "" };
    } else {
      const today = moment.utc();
      const endDate = moment.utc().isoWeekday(3).set({ hour: 23, minute: 59, second: 59 });
      var duration = moment.duration(endDate.diff(today));
      var seconds = duration.asSeconds();
      var divisor_for_minutes = seconds % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
      const time = `${Math.floor(seconds / 3600)}:${minutes > 9 ? minutes : `0${minutes}`}`;
      return { status: "TIME_MODIFIED", time };
    }
  };

  return (
    <>
      <div className="editDetailsContentHeading">Time Sheet Review</div>
      <div className="timeSheetInstrction">
        You don’t need to approve weekly timesheet unless you want to report a
        modified worked time.
      </div>
      {!reviewData.timesheet && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <p className="SortByDropdown">Sort by:</p>
            <div style={{ width: "170px" }}>
              <Dropdown
                options={[
                  { label: "Start Date", value: "startDate" },
                  { label: "Job Title", value: "jobTitle" },
                ]}
                placeholder="Select sorting"
                value={sortBy}
                onChange={(e) => {
                  setPage(1);
                  setSortBy(e);
                }}
              />
            </div>
          </div>
          <table
            style={{
              width: "100%",
              textAlign: "left",
              borderCollapse: "collapse",
              fontFamily: "Libre Franklin",
            }}
            className="table-small-row-font"
          >
            <thead>
              <tr style={{ borderBottom: "2px solid #C4C4C4", height: 60 }}>
                <th style={{ width: "25%", color: "#1c1c1c" }}>
                  Talent Name
                </th>
                <th style={{ width: "15%", color: "#1c1c1c" }}>Job Title</th>
                <th style={{ width: "15%", color: "#1c1c1c" }}>Start Date</th>
                <th style={{ width: "15%", color: "#1c1c1c" }}>Status</th>
                <th style={{ width: "23%" }}></th>
              </tr>
            </thead>
            {loading ? (
              <tr style={{ textAlign: "center", height: 100 }}>
                <td colSpan="5">
                  <CircularProgress size={40} />
                </td>
              </tr>
            ) : (
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        height: 86,
                        borderBottom: "2px solid #C4C4C4",
                        backgroundColor: "inherit",
                      }}
                    >
                      <td style={{ width: "25%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            {item.profiles &&
                              item.profiles.length > 0 &&
                              item.profiles[0].photo ? (
                              <img
                                src={
                                  item.profiles &&
                                  item.profiles[0] &&
                                  item.profiles[0].photo
                                }
                                style={{
                                  height: 70,
                                  borderRadius: 70,
                                }}
                              />
                            ) : (
                              <AccountCircleIcon
                                style={{ height: 70, width: 70 }}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              margin: "auto",
                              marginLeft: 10,
                              opacity: 0.78,
                            }}
                          >
                            <p className="hiringEmployeeName">
                              {item.users &&
                                item.users.length > 0 &&
                                `${item.users[0].firstName} ${item.users[0].lastName}`}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "10%", color: "#1c1c1c" }}>
                        {item.jobs &&
                          item.jobs.length > 0 &&
                          item.jobs[0].title}
                      </td>
                      <td style={{ width: "15%", color: "#1c1c1c" }}>
                        {moment(item.jobStartedOn).utc(0).format("MMM DD, YY")}
                        {/* {item.schedule &&
                                                    item.schedule.length > 0 &&
                                                    moment(item.schedule[0].dateSchedule).format(
                                                        'MMM DD, YY'
                                                    )} */}
                      </td>
                      <td style={{ width: "25%", color: "#1c1c1c" }}>
                        <ProgressBar
                          type="TimeSheetReview"
                          variant={timeLefttoModify().status}
                          time={timeLefttoModify().time}
                        />
                      </td>
                      <td
                        style={{
                          width: "5%",
                          textAlign: "end",
                          color: "#1c1c1c",
                        }}
                      >
                        <ContainedButtons
                          height={48}
                          text="Review"
                          color={["#2493F1", "#15568D"]}
                          width={150}
                          onClick={() => {
                            setWeekCount(1);
                            setReviewData(item);
                          }}
                          disabled={getReviewButtonEnabled(item)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ textAlign: "center", height: 100 }}>
                    <td colSpan="5">
                      <p>No data found.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
          {!loading && data.length > 0 && (
            <div className="advancedSearchPagination" style={{ marginTop: 80 }}>
              <Pagination
                count={Math.ceil(count / 10)}
                page={page}
                onChange={(e, value) => setPage(value)}
                style={{ justifyContent: "center" }}
                size="large"
              />
            </div>
          )}
        </>
      )}
      {reviewData.timesheet && (
        <>
          <div className="timeSheetReviewScreenTop">
            <span style={{ color: "#B3B3B3" }}> Job Title:</span>{" "}
            <span>
              {reviewData.jobs &&
                reviewData.jobs.length > 0 &&
                reviewData.jobs[0].title}
            </span>{" "}
          </div>
          <div className="timeSheetReviewScreen">
            {" "}
            <span style={{ color: "#B3B3B3" }}> Talent Name:</span>{" "}
            <span>
              {reviewData.users &&
                reviewData.users.length > 0 &&
                `${reviewData.users[0].firstName} ${reviewData.users[0].lastName}`}
            </span>{" "}
          </div>
          <div
            style={{
              marginTop: 42,
              width: "70%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", height: 56 }}>
              <ArrowBackIosIcon
                style={{ fill: "#9A9A9A", cursor: "pointer", marginTop: 18 }}
                onClick={() => setWeekCount(weekCount + 1)}
              />
              <div className="time-sheet-week" style={{}}>
                <div style={{ textAlign: "center", marginTop: 12 }}>{week}</div>
              </div>
              <ArrowForwardIosIcon
                style={{
                  fill: "#9A9A9A",
                  marginLeft: 5,
                  cursor: "pointer",
                  marginTop: 18,
                }}
                onClick={() =>
                  weekCount > 1 ? setWeekCount(weekCount - 1) : null
                }
              />
            </div>
            <ContainedButtons
              text="Modify"
              height={48}
              color={["#2493F1", "#15568D"]}
              width={150}
              onClick={() => {
                setEditTimeSheet(true);
              }}
              disabled={
                !(weekCount === 1 && moment().utc().day() <= 3 && moment().utc().day() > 0)
              }
            />
          </div>
          <div
            style={{
              padding: 10,
              width: "70%",
              borderRadius: 8,
              marginTop: 20,
              border: "2px solid #B3B3B3",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <table
              style={{
                width: "100%",
                textAlign: "left",
                borderCollapse: "collapse",
                fontFamily: "Libre Franklin",
              }}
            >
              <thead>
                <tr style={{ borderBottom: "2px solid #C4C4C4", height: 50 }}>
                  <th style={{ width: "20%" }}>Start</th>
                  <th style={{ width: "15%" }}>Date</th>
                  <th style={{ width: "30%", textAlign: "end" }}>
                    Scheduled Hours
                  </th>
                  <th style={{ width: "35%", textAlign: "end" }}>
                    Actual Worked Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {enumerateDaysBetweenDates(weekStarts, weekEnds).length > 0 &&
                  enumerateDaysBetweenDates(weekStarts, weekEnds).map(
                    (item, index) => (
                      <tr
                        key={index}
                        className="review-table-row"
                        style={{
                          height: 58,
                          borderBottom: "2px solid #C4C4C4",
                          backgroundColor: "inherit",
                        }}
                      >
                        <td style={{ width: "20%" }}>
                          {moment.utc(item.dateSchedule).format("dddd")}
                        </td>
                        <td style={{ width: "15%" }}>
                          {moment.utc(item.dateSchedule).format("MMM DD, YY")}
                        </td>
                        <td style={{ width: "30%", textAlign: "end" }}>
                          {/* {parseInt(item.totalHours) +
                            parseFloat(item.nonPayableTime)} */}
                          {item.totalHours}

                        </td>
                        <td style={{ width: "35%", textAlign: "end" }}>
                          {/* {item.totalHours} */}
                          {schHrs[index]}

                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
          <div
            style={{
              color: "#1E7AC9",
              cursor: "pointer",
              marginTop: 32,
              fontSize: 20,
              textDecoration: "underline",
              marginBottom: 80,
            }}
            onClick={() => {
              setWeekCount(1);
              setReviewData({});
            }}
          >
            Back to Timesheet List
          </div>
        </>
      )}
      <DrawerComponent
        open={editTimeSheet}
        onClose={() => {
          setEditTimeSheet(false);
          enumerateDaysBetweenDates(weekStarts, weekEnds);
        }}
        component={
          <TimeSheetForm
            reasonWorkModify={reasonWorkModify}
            setReasonWorkModify={setReasonWorkModify}
            timeSheetData={enumerateDaysBetweenDates(weekStarts, weekEnds)}
            updateTimeSheet={updateTimeSheet}
          />
        }
        width="33%"
      />
    </>
  );
};

export default TimeSheetReview;
