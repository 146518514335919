import React from 'react';
import { connect } from 'react-redux';
import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import InputText from '../../../components/centralize_input_text'
import DropDownList from '../../../components/centralize_dropdown'
import ToggleButton from '../../../components/centralize_toggle_button'
import Constant from '../../../Constant/constant'

import { IS_HOURLY,HOURLY_RATE,FEE_AMOUNT,PAYMENT_METHOD, PAYMENT_TIME, REFRESH_DATE_FORM_DATA } from '../../../../../action/form_action';

import { VERTICAL_NORMAL, VERTICAL_EXTRA,
VERTICAL_SMALL, VERTICAL_EXTRA_LARGE, HORIZONTAL_NORMAL,HORIZONTAL_EXTRA  } from '../../../../../utils/enums';

class FeePayment extends React.Component{

   render() {
     const {isHourlyValue, feeTypeValue,hourlyRateValue, feeAmountValue, paymentMethodValue, paymentTimeValue} = this.props;

   return (
     <div >

     <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text = "Fee Type"/>
     <Spacer spaceType = {VERTICAL_SMALL} />


       <DropDownList dropDownList = {[{lable : 'Hourly $'}, {lable : 'Fixed fee $'}]} label = {"Hourly $"}
              text={feeTypeValue}
              fontSize = {Constant.FONT_SIZE_MOBILE}
              width = "80%"
              menuWidth = "40%"
              fontSizeDropDown = {Constant.FONT_SIZE_DROPDOWN_MOBILE}
              onSelectedValueCallback={value => {
                if(value === "Hourly $"){
                  if(!isHourlyValue){
                    this.props.refreshFormData();
                  }
                  this.props.feeAmount("");
                  this.props.feeType(true, value);
                }else{
                  if(isHourlyValue){
                    this.props.refreshFormData();
                  }
                  this.props.hourlyRate("");
                  this.props.feeType(false, value);
                }
              }}/>

     <Spacer spaceType = {VERTICAL_EXTRA} />

     <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text = "Hourly Rate:"/>
     <Spacer spaceType = {VERTICAL_SMALL} />

       <InputText
            id = "hourlyRate" label = {hourlyRateValue === "" ? "$ 0.00" : "Hourly Rate"}
            disabled = {!isHourlyValue ? true : false}
            fontSize = {Constant.FONT_SIZE_MOBILE}
            width = "80%"
            text={hourlyRateValue}
             onChangeCallback={event =>this.props.hourlyRate(event)}
           />


        <Spacer spaceType = {VERTICAL_EXTRA} />

        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text = "Fee amount:"/>
        <Spacer spaceType = {VERTICAL_SMALL} />


          <InputText
              id = "feeAmount" label = {feeAmountValue === "" ? "$ 0.00" : "Fee amount"}
              disabled = {isHourlyValue ? true : false}
               text = {feeAmountValue}
               onChangeCallback={event => this.props.feeAmount(event)}
               fontSize = {Constant.FONT_SIZE_MOBILE}
               width = "80%"
              />


          <Spacer spaceType = {VERTICAL_EXTRA} />


          <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text = "Payment Method"/>
          <Spacer spaceType = {VERTICAL_SMALL} />
          <ToggleButton  leftName = {"Empolink"} rightName = {"Directly"}
          defaultSelectedValue = {paymentMethodValue === "Empolink" ? 1 : 2}
          onSelectedValueCallback={value => {
            this.props.paymentMethod(value);
          }}/>

          <Spacer spaceType = {VERTICAL_SMALL} />
          <ToggleButton  leftName = {"Weekly"} rightName = {"Bi-Weekly"}
            defaultSelectedValue = {paymentTimeValue === "Weekly" ? 1 : 2}
          onSelectedValueCallback={value => {
            this.props.paymentTime(value);
          }}/>

     </div>
   );
  }
 }


 const mapStateToProps = function(state) {
   return {
     isHourlyValue : state.formReducer.isHourly,
     feeTypeValue : state.formReducer.feeType,
     hourlyRateValue : state.formReducer.hourlyRate,
     feeAmountValue : state.formReducer.feeAmount,
     paymentMethodValue : state.formReducer.paymentMethod,
     paymentTimeValue : state.formReducer.paymentTime,
     error: state.formReducer.error || {},
   }
 }

 const mapDispatchToProps = (dispatch) => {
  return {
    feeType: (isHourly, value) => dispatch({ type: IS_HOURLY,  isHourly : isHourly, feeType : value}),
    hourlyRate: (value) => dispatch({ type: HOURLY_RATE,  hourlyRate : value}),
    feeAmount: (value) => dispatch({ type: FEE_AMOUNT,  feeAmount : value}),
    paymentMethod: (value) => dispatch({ type: PAYMENT_METHOD,  paymentMethod : value}),
    paymentTime: (value) => dispatch({ type: PAYMENT_TIME,  paymentTime : value}),
    refreshFormData: () => dispatch({ type: REFRESH_DATE_FORM_DATA}),
  }
 }

 export default connect(mapStateToProps,mapDispatchToProps)(FeePayment);
