import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarHalfIcon from '@material-ui/icons/StarHalf';

const Rating = (props) => {

    const populateStars = (score) => {
        const iWholeStars = Math.floor(score);
        const blnHalfStar = (iWholeStars < score);
        const blankStar = 5 - (blnHalfStar ? iWholeStars + 1 : iWholeStars);

        return (
            <>
                {Array.from({ length: iWholeStars }).map(() => (
                    <StarIcon style={{ color: props.color || '#1876D2' }} />
                ))}
                {blnHalfStar && <StarHalfIcon style={{ color: props.color || '#1876D2' }} />}
                {Array.from({ length: blankStar }).map(() => (
                    <StarBorderIcon style={{ color: props.color || '#1876D2' }} />
                ))}
            </>
        )
    }

    return (
        <div>
            {populateStars(props.rating || 0)}
            {props.reviews > -1 && (
                <p style={{ textAlign: 'center' }}>({props.reviews} Reviews)</p>
            )}
        </div>
    )
};

export default Rating;
