import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './extendedpages.scss';
//import '../../../Assets/dev-style.scss';
import EmpolinkLogo from '../../Assets/images/logo.svg';
import InvisibleLogo from '../../Assets/images/invisable-logo.svg';
import FooterPublic from '../../Components/FooterPublic/FooterPublic';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import { Helmet } from 'react-helmet';

class financejobs extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink - Your source for finance jobs</title>
                    <meta name="keywords" content="Accountant Jobs, Auditor Jobs, Bank Teller Jobs, Biller Jobs, Bookkeeper Jobs, Broker Jobs, Business Analyst Jobs, Compliance Officer Jobs, Credit Analyst Jobs, Customer Service Representative Jobs, Financial Advisor Jobs, Financial Analyst Jobs, Financial Clerk Jobs, Financial Sales Agent Jobs, Insurance Claim Associate Jobs, Insurance Claim Clerk Jobs, Insurance Representative Jobs, Loan and Mortgage Specialist Jobs, Market Research Analyst Jobs, Payroll Administrator Jobs, Tax Preparer Jobs" />
                    <meta name="description" content="Empolink connects clients and talents quickly and efficiently. Discover a platform designed for seamless interactions and services." />
                    <meta property="og:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta property="og:description" content="Empolink bridges the gap between clients and talents, providing a streamlined platform for finding and offering services." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@@empolink" />
                    <meta name="twitter:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta name="twitter:description" content="Explore diverse freelance opportunities and connect with clients who need your skills on Empolink." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>

                </Helmet>
                <HeaderPublic /> {/* From HeaderPublic component */}
                <section class="image-text image-text--right section-padding">
                    <div class="container">
                        <div class="row flex-row flex-direction">
                            <div className="col-md-6 image-text__half">
                                    <div className="specialtieslist-box">
                                        <ul>
                                        <li>Accountant</li>
                                        <li>Auditor</li>
                                        <li>Biller</li>
                                        <li>Bookkeeper</li>
                                        <li>Business Analyst</li>
                                        <li>Compliance Officer</li>
                                        <li>Credit Analyst</li>
                                        <li>Customer Service Representative</li>
                                        <li>Financial Advisor/ Analyst</li>
                                        <li>Financial Clerk</li>
                                        <li>Financial Sales Agent</li>
                                        <li>Insurance Claim Associate/Clerk</li>
                                        <li>Insurance Representative</li>
                                        <li>Loan and Mortgage Specialist</li>
                                        <li>Market Research Analyst</li>
                                        <li>Payroll Administrator</li>
                                        <li>Tax Preparer</li>
                                        </ul>
                                    </div>
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    SEEKING <span>FINANCE</span> OPPORTUNITIES?
                                </h2>
                                <p>Join our platform to connect with clients seeking talented professionals in Finance and Insurance. Whether you're an Accountant, Business Analyst, Financial Advisor, Insurance Specialist, or have expertise in other related fields, your next great job is just a click away! Unlock a world of freelance opportunities and take control of your financial career today.</p>
                                <p></p>
                                <p></p>                                
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                                <p></p>
                                
                            </div>
                            
                        </div>
                    </div>
                </section>

                <FooterPublic />
            </>
        );
    }
}

export default withRouter(financejobs);
