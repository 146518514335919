import React, { Component } from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RegisterMainPage from "./RegisterMainPage/RegisterMainPage";
import BusinessInfoPageOne from "./BusinessInfoPageOne";
import BusinessInfoPageTwo from "./BusinessInfoPageTwo";
import BusinessInfoPageThree from "./BusinessInfoPageThree";
import ThankyouMessage from "./ThankyouMessage/ThankyouMessage";
import RegisterOtpPage from "./RegisterOtpPage/RegisterOtpPage";
import TalentSearch from "../InitialPages/TalentSearch/TalentSearch";
import { withRouter } from "react-router-dom";
import axios from "axios";
import API from "../../utils/Urls";
import Swal from 'sweetalert2'
import { APIHelper } from "../../utils/ApiHelper";
import CONSTANTS from "../../utils/constants";

class EmployerSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      firstName: "",
      lastName: "",
      companyName: "",
      mobileNumber: "",
      languages: [],
      country: "",
      streetAddress: "",
      unitNumber: "",
      city: "",
      state: "",
      postalcode: "",
      selectedField: {},
      googleApiData: {},
      pages: 0,
      otpError: undefined,
      otpLoading: false,
      registerLoading: false,
      countryLocation: "",
      ip: "",
    };
  }

  componentDidMount() {
    APIHelper.getMethod(CONSTANTS.GEO_LOCATION_API).then(
      (response) => {
        this.setState({
          ip: response.ip,
          countryLocation: response.country_name
        });
      }
    );
  }

  render() {
    const onMainPageSignUpClicked = (username, password, type, data) => {
      this.setState(
        {
          googleApiData: data,
        },
        () => {
          console.log(this.state.googleApiData);
        }
      );
      console.log("Main PAge");
      if (type === "manual") {
        this.setState({
          username: username,
          password: password,
          pages: 1,
        });
        // this.props.history.push('/register/2');
      }
      if (type === "google") {
        this.setState(
          {
            username: username,
            googleApiData: data,
            pages: 1,
          },
          () => { }
        );
        // this.props.history.push('/register/2');
      }
    };

    const onPageOneNextClicked = (
      firstName,
      lastName,
      companyName,
      mobileNumber,
      languages
    ) => {
      this.setState({
        firstName: firstName,
        lastName: lastName,
        companyName: companyName,
        mobileNumber: mobileNumber,
        languages: languages,
        pages: 2,
      });
      // this.props.history.push('/register/3');
    };

    const onPageTwoNextClicked = (
      country,
      streetAddress,
      unitNumber,
      city,
      state,
      postalcode
    ) => {
      this.setState({
        country: country,
        streetAddress: streetAddress,
        unitNumber: unitNumber,
        city: city,
        state: state,
        postalcode: postalcode,
        pages: 3,
      });
      // this.props.history.push('/register/4');
    };

    const onAgreeAndSignUpClicked = async (
      selectedField,
      selectedSpecialities
    ) => {
      this.setState({
        selectedField: selectedField,
        registerLoading: true
      }, () => { });
      const body = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password.trim(),
        email: this.state.username.trim(),
        phone: this.state.mobileNumber,
        registration_type: "EMAIL",
        company: { _id: "000000000000000000000000", name: this.state.companyName },
        languages: this.state.languages,
        location: {
          ip: this.state.ip,
          country: this.state.countryLocation,
        },
        address: {
          unit_number: this.state.unitNumber,
          street_number: this.state.streetAddress,
          city: this.state.city.value,
          state: this.state.state.value,
          country: this.state.country,
          postal_code: this.state.postalcode,
        },

        field: selectedField,
        specialities: selectedSpecialities,
        is_terms_accepted: "true",
      };

      axios
        .post(API.REGISTER_EMPLOYER, body)
        .then((response) => {
          console.log(response.data)
          this.setState({ registerLoading: false });
          this.setState({ pages: 4 });
        })
        .catch((err) => {
          Swal.fire({
            customClass: "Alert-Class",
            title: 'Information!',
            text: err.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#1876D2',
          });
        });
    };

    const onOtpClicked = (otp) => {
      axios
        .post(API.VERIFY_OTP, {
          email: this.state.username,
          otp,
        })
        .then((response) => {
          if (response.data.status === 1) {
            // alert(response.message)
            // this.setState({ pages: 5 });
            this.setState({ otpError: false })
            Swal.fire({
              customClass: 'Alert-Class',
              title: 'Success!',
              text: 'OTP Verified Successfully! Your verification process is complete. Thank you for confirming your email.',
              icon: 'success',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#1876D2',
            }).then((re) => {
              this.props.history.push("/login");
            });
            // redirect user to talent search pahe he successfully verified the OTP
            // this.props.history.push("/TalentSearch")
          } else {
            //Tango Please Show the error message here in case API give invalid response.
            this.setState({ otpError: true })
          }
        })
        .catch((err) => {
          this.setState({ otpError: true })
        });
    };

    const resendOtp = async () => {
      axios
        .post(API.RESEND_VERIFY_OTP, {
          email: this.state.username,
        })
        .then((response) => {
          if (response.data.status === 1) {
            Swal.fire({
              customClass: "Alert-Class",
              title: "Success!",
              text: response.data.message,
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#1876D2",
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const pages = [
      <RegisterMainPage onSignUpClicked={onMainPageSignUpClicked} />,
      <BusinessInfoPageOne
        apiData={this.state.googleApiData}
        onPageOneNextClicked={onPageOneNextClicked}
        handleBackButtonClick={() => this.setState({ pages: 0 })}
      />,
      <BusinessInfoPageTwo onPageTwoNextClicked={onPageTwoNextClicked} handleBackButtonClick={() => this.setState({ pages: 1 })} />,
      <BusinessInfoPageThree
        onAgreeAndSignUpClicked={onAgreeAndSignUpClicked}
        registerLoading={this.state.registerLoading}
        handleBackButtonClick={() => this.setState({ pages: 2 })}
      />,
      <RegisterOtpPage
        onOtpClicked={onOtpClicked}
        otpError={this.state.otpError}
        otpLoading={this.state.otpLoading}
        resendOtp={resendOtp}
        otpChanged={() => this.setState({ otpError: false })}
      />,
      <ThankyouMessage />,
      <TalentSearch />,
    ];

    return <>{pages[this.state.pages]}</>;
  }
}
export default withRouter(EmployerSignup);
