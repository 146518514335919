import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const HelpAndSupport = () => {
  const location = useLocation();
  let path = 'pages/Help Center.html';
  if (location.pathname === '/help/clients') {
    path = '/pages/for Clients.html';
  }
  if (location.pathname === '/help/talents') {
    path = '/pages/for Talents.html';
  }

  const [htmlContent, setHTML] = useState(null);

  useEffect(() => {
    fetch(path)
      .then(result => result.text())
      .then(page => {
        setHTML(page);
      });
  }, [path]);

  useEffect(() => {
    if (htmlContent) {
      const scriptTagPattern = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;
      const scripts = [];
      let match;
      while ((match = scriptTagPattern.exec(htmlContent)) !== null) {
        scripts.push(match[1]);
      }
      scripts.forEach((scriptContent) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = scriptContent;
        document.body.appendChild(script);
      });
    }
  }, [htmlContent]);

  return (
    <>
      <section dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </>
  );
};

export default HelpAndSupport;