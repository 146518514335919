import React from 'react';
import './EmployeeDashboardOverview.scss';

import Overview from './Component/Overview';
import Statistics from './Component/Statistics';
import Finance from './Component/Finance';
import Invoices from './Component/Invoices';

export class EmployeeDashboardOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props.history.location.state.page,
            userType: localStorage.getItem('userType'),
            selectedHeadingstyles : {
                fontSize: '1.25vw', 
                fontWeight: '600',
                letterSpacing: '0.96px',
                color: '#1c1c1c',
                backgroundColor: 'rgba(164,199,114,0.64)',
            }
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let page = localStorage.getItem('page');
        if(page !== null && prevState.page !== page) {
            this.setState({page: parseInt(page)});
            localStorage.removeItem('page');
        }
    }
    render() {
        const pages = [
            <Overview gotoFinancePage={() => this.setState({ page: 2 })}/>,
            <Statistics />,
            <Finance />,
            <Invoices />,
        ];

        return (
            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ width: '24.8%', minHeight: '79.4vh', backgroundColor: '#f2f2f2' }}>
                    <div style={{ marginTop: '5.24vw' }}>
                        <div
                            onClick={() => {localStorage.setItem('page', 0); this.setState({refreshKey:Math.random()})}}
                            style={this.state.page === 0 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Overview</div>
                        </div>
                        <div
                            onClick={() => {localStorage.setItem('page', 1); this.setState({refreshKey:Math.random()})}}
                            style={this.state.page === 1 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">
                                Statistics
                            </div>
                        </div>
                        <div
                            onClick={() => {localStorage.setItem('page', 2); this.setState({refreshKey:Math.random()})}}
                            style={this.state.page === 2 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Finance</div>
                        </div>
                        <div
                            onClick={() => {localStorage.setItem('page', 3); this.setState({refreshKey:Math.random()})}}
                            style={this.state.page === 3 ? this.state.selectedHeadingstyles : {}}
                            className="hiringStepperLabelStyles"
                        >
                            <div className="hiringStepperInternalHeading">Invoice</div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '75.2%', padding: 20 }}>
                    <div style={{ width: '100%', marginTop: '3.3vw' }}>
                        {pages[this.state.page]}
                    </div>
                </div>
            </div>
        );
    }
}

export default EmployeeDashboardOverview;
