import React from "react";
import moment from "moment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContainedButtons from "../../../../Components/Button/Button";
import Dropdown from "../../../../Components/DropdownComponent/Dropdown.component";
import TextField from "../../../../Components/TextField/Textfield";
import { APIHelper } from "../../../../utils/ApiHelper";
import API from "../../../../utils/Urls";
import CircularProgress from "@material-ui/core/CircularProgress";

const FixedContractRequest = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [reasonType, setReasonType] = React.useState("Emergency Situation");
  const [reason, setReason] = React.useState();
  const [isValidinProgressMilestone, setIsValidinProgressMilestone] = React.useState(false);
  const [resonTypeError, setReasonTypeError] = React.useState(false);
  const [resonError, setReasonError] = React.useState(false);
  const [inProgressMilestone, setInProgressMilestone] = React.useState(0);
  const [data, setData] = React.useState();
  const [mileStone, setMileStone] = React.useState([]);
  const [mileStoneAmount, setMileStoneAmount] = React.useState(0);
  const [isDisable, setIsDisable] = React.useState(false);

  React.useLayoutEffect(() => {
    setLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
      .then((res) => {
        setData(res[0]);
        setLoading(false);

        // Disable % inpput
        if (moment(res[0].jobStartedOn) >= moment()) {
          setIsDisable(true);
          setInProgressMilestone("0");
        }

        if (res[0].milestone && res[0].milestone.length === 0) {
          setInProgressMilestone("0");
          setIsDisable(true);
        }

        if (res && res.length > 0 && res[0].milestone && res[0].milestone.length > 0 && res[0].jobs && res[0].jobs.length > 0) {
          const startDate = moment(res[0].jobs[0].startDate);
          const milestone = res[0].milestone;

          if (startDate < moment()) {
            const currentMileStone = milestone.filter(
              (x) => moment(x.dateSchedule) >= startDate && moment(x.dateSchedule) >= moment()
            );

            if (currentMileStone && currentMileStone.length > 0) {
              const amount = currentMileStone && currentMileStone.length > 0 && currentMileStone.map((x) => x.amount);
              setMileStoneAmount(amount[0]);
              setMileStone([currentMileStone[0]]);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const cancelJob = () => {
    if (!reasonType) {
      setReasonTypeError(true);
    }

    if (reasonType === "Other") {
      if (reason.trim() === "") {
        setReasonError(true);
      }
    }

    if (inProgressMilestone !== "" && inProgressMilestone !== undefined) {
      setIsValidinProgressMilestone(false);
      let body = {
        reasonType: reasonType,
        finalMileStonePercentageByEmployer: inProgressMilestone,
        inProgressMilestoneByEmployee: `${inProgressMilestone}%`,
        cancellationMilestone : mileStone[0]?.description,
        finalMileStoneAmountByEmployee: parseFloat(inProgressMilestone) * parseFloat(mileStoneAmount) * parseFloat(0.01),
        finalMileStoneAmountByEmployer:0        
      };
      if (reasonType === "Other") {
        body.reason = reason;
        if (reason.trim() === "") {
          setReasonError(true);
        }
      } else {
        body.reason = "";
      }
      if (reasonType && (reasonType === "Other" ? reason !== "" : true)) {
        props.cancelJob(props.contractId, body);
      }
    } else {
      setIsValidinProgressMilestone(true);
    }
  };

  return (
    <div style={{ padding: 100, paddingTop: 70, fontFamily: "Libre Franklin" }}>
      <div style={{ display: "flex" }}>
        <h3 style={{ margin: 0, fontSize: 36, fontWeight: "500" }}>Job Cancellation</h3>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: 200 }}>
          <CircularProgress size={40} />
        </div>
      ) : (
        <div style={{ marginTop: 64, fontSize: 20 }}>
          {data && (
            <React.Fragment>
              <div style={{ display: "flex" }}>
                <p style={{ width: 350, color: "#9a9a9a" }}>Contract No:</p>
                <p>{data.contractNumber}</p>
              </div>
              {data.employerProfileData && data.employerProfileData.length > 0 && (
                <div style={{ display: "flex", marginTop: 30 }}>
                  <p style={{ width: 350, color: "#9a9a9a", lineHeight: "70px" }}>Client:</p>
                  <AccountCircleIcon
                    style={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <div style={{ lineHeight: "70px", marginLeft: "10px" }}>
                    {data.employerProfileData &&
                      data.employerProfileData.length > 0 &&
                      data.employerProfileData[0].company &&
                      data.employerProfileData[0].company.name}
                  </div>
                </div>
              )}
              <div style={{ display: "flex", marginTop: 14 }}>
                <p style={{ width: 350, color: "#9a9a9a" }}>Client Name:</p>
                <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
              </div>
              <div style={{ display: "flex", marginTop: 30 }}>
                <p style={{ width: 350, color: "#9a9a9a" }}>Talent Name:</p>
                <p>{`${data.employeeData[0].firstName} ${data.employeeData[0].lastName}`}</p>
              </div>
              <div style={{ display: "flex", marginTop: 30 }}>
                <p style={{ width: 350, color: "#9a9a9a" }}>Job Title:</p>
                <p>{data.jobs[0].title}</p>
              </div>
              <div style={{ display: "flex", marginTop: 30 }}>
                <p style={{ width: 350, color: "#9a9a9a" }}>Work Start Date:</p>
                <p>{moment(data.jobs[0].startDate).utc(0).format("MMM DD, YYYY")}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 30,
                  borderBottom: "2px solid #C4C4C4",
                  paddingBottom: 83,
                }}
              >
                <p style={{ width: 350, color: "#9a9a9a" }}>Work End Date:</p>
                <p>{moment(data.jobs[0].endDate).utc(0).format("MMM DD, YYYY")}</p>
              </div>
              <div style={{ marginTop: 79 }}>
                <p style={{ width: 350, color: "#9a9a9a" }}>Cancellation reason</p>
                <div style={{ width: "50%", marginTop: 30 }}>
                  <Dropdown
                    options={[
                      {
                        label: "Unsafe Workplace",
                        value: "Unsafe Workplace",
                      },
                      {
                        label: "Emergency Situation",
                        value: "Emergency Situation",
                      },
                      { label: "Other", value: "Other" },
                    ]}
                    placeholder="Select reason"
                    // value={sortBy}
                    onChange={(e) => {
                      setReasonType(e.value);
                      setReason("");
                      setReasonTypeError(false);
                    }}
                    error={resonTypeError}
                    value={{ label: reasonType, value: reasonType }}
                  />
                </div>
                <div
                  style={{
                    marginTop: 30,
                    paddingBottom: 59,
                    borderBottom: "2px solid #C4C4C4",
                  }}
                >
                  <TextField
                    disabled={reasonType !== "Other"}
                    rows={8}
                    multiline
                    width={"96%"}
                    label={"Reason"}
                    value={reason}
                    onChange={(e) => {
                      setReasonError(false);
                      setReason(e.target.value);
                    }}
                    error={resonError}
                  />
                </div>
                <div
                  style={{
                    marginTop: 59,
                    paddingBottom: 59,
                    borderBottom: "2px solid #C4C4C4",
                  }}
                >
                  <p style={{ color: "#9a9a9a", fontSize: 20 }}>Current Milestone</p>
                  <div
                    style={{
                      border: "2px solid #C4C4C4",
                      borderRadius: "15px",
                      marginTop: "15px",
                      paddingRight: "10px",
                      paddingLeft: "20px",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        textAlign: "left",
                        borderCollapse: "collapse",
                        fontFamily: "Libre Franklin",
                      }}
                      className="table-small-row-font"
                    >
                      <thead>
                        <tr
                          style={{
                            height: 60,
                            borderBottom: "2px solid #C4C4C4",
                          }}
                        >
                          <th style={{ width: "25%", color: "#1c1c1c" }}>Milestones</th>
                          <th style={{ width: "25%", color: "#1c1c1c" }}>Status</th>
                          <th
                            style={{
                              width: "20%",
                              color: "#1c1c1c",
                            }}
                          >
                            Amount
                          </th>
                          <th style={{ width: "25%", color: "#1c1c1c" }}>Payment Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mileStone.map((element, index) => {
                          return (
                            <tr
                              style={{
                                height: 60,
                                borderBottom: "2px solid #C4C4C4",
                                backgroundColor: "inherit",
                              }}
                            >
                              <td
                                style={{
                                  width: "25%",
                                  color: "#1c1c1c",
                                }}
                              >
                                {element.description}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  color: "#1c1c1c",
                                }}
                              >
                                In Progress
                              </td>
                              <td
                                style={{
                                  width: "20%",
                                  color: "#1c1c1c",
                                }}
                              >
                                ${element.amount}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  color: "#1c1c1c",
                                }}
                              >
                                {moment(element.dateSchedule).utc().format("MMM, DD YYYY")}
                              </td>
                            </tr>
                          );
                        })}
                        {mileStone && mileStone.length === 0 && (
                          <tr style={{ height: 60 }}>
                            <td colspan="4" style={{ textAlign: "center" }}>
                              No milestone under in-progress
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ borderBottom: "2px solid #C4C4C4", paddingBottom: 59 }}>
                  <p style={{ marginTop: 35, color: "#9A9A9A" }}>
                    Specify percentage of work completed for “In Progress” milestone
                  </p>
                  <div style={{ marginTop: 30 }}>
                    <TextField
                      width={"25%"}
                      label={"0%-100%"}
                      value={inProgressMilestone}
                      required={true}
                      error={isValidinProgressMilestone}
                      max={100}
                      type="number"
                      onChange={(e) => {
                        var value = parseFloat(e.target.value);
                        if (value > 100) {
                          value = 100;
                        }
                        if (value < 0) {
                          value = 0;
                        }
                        setInProgressMilestone(value);
                        setIsValidinProgressMilestone(false);
                      }}
                      disabled={true}
                      // disabled={isDisable}
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: 30 }}>
                    <p style={{ color: "#9A9A9A", width: 350 }}>Proposed Milestone Value:</p>
                    <p style={{ color: "#1C1C1C" }}>
                      ${parseFloat(inProgressMilestone) * parseFloat(mileStoneAmount) * parseFloat(0.01) || `00.00`}
                    </p>
                  </div>
                  <p style={{ color: "#9A9A9A", marginTop: 10 }}>
                    (Percentage of work completed multiplied by current milestone amount)
                  </p>
                  <p style={{ color: "#9A9A9A", marginTop: 10 }}>
                    This value will be submitted to client for review. Client has a choice to overwrite this when required.{" "}
                  </p>
                </div>
                <div
                  style={{
                    padding: "41px 30px",
                    backgroundColor: "#d5d5d5",
                    borderRadius: 10,
                    marginTop: 59,
                  }}
                >
                  <p style={{ fontSize: 20, color: "#010101", fontWeight: 600 }}>
                    Warning: This cancellation is final once sent. It is highly recommended that cancellation shall not happen
                    unless in extreme circumstances where talent has no other choice. Cancellation will lead to a negative point
                    for talent unless it is mutually agreed by client and talent.
                  </p>
                </div>
                <div style={{ marginTop: 60 }}>
                  <p style={{ fontSize: 20, color: "#010101", fontWeight: 600 }}>Are you sure you want to cancel the job?</p>
                </div>
                <div style={{ display: "flex", marginTop: 30 }}>
                  <div style={{ marginRight: 10 }}>
                    <ContainedButtons
                      text="No"
                      color={["#a4c772", "#4e8400"]}
                      height="56px"
                      width={"12vw"}
                      onClick={props.handleClose}
                    />
                  </div>
                  <ContainedButtons
                    text="Yes"
                    color={["#a4c772", "#4e8400"]}
                    height="56px"
                    variant="outlined"
                    width={"12vw"}
                    onClick={cancelJob}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default FixedContractRequest;
