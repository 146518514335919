import React from 'react';
import { Card } from '@material-ui/core';
import Swal from 'sweetalert2';
import moment from 'moment';

import ContainedButtons from '../../../Components/Button/Button';
import TimePicker from '../../JobPostPages/components/centralize_time_picker';
import Constant from '../../JobPostPages/Constant/constant';
import MultipleDatePicker from '../../../Components/MultipleDatePicker/MultipleDatePicker';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import { enumerateDaysBetweenDates } from '../../../utils/commonFunction';

const CalenderSetup = (props) => {
    const [workingDay, setWorkingDay] = React.useState([
        {
            dayName: 'mon',
            startTime: null,
            endTime: null,
        },
        {
            dayName: 'tue',
            startTime: null,
            endTime: null,
        },
        {
            dayName: 'wed',
            startTime: null,
            endTime: null,
        },
        {
            dayName: 'thu',
            startTime: null,
            endTime: null,
        },
        {
            dayName: 'fri',
            startTime: null,
            endTime: null,
        },
        {
            dayName: 'sat',
            startTime: null,
            endTime: null,
        },
        {
            dayName: 'sun',
            startTime: null,
            endTime: null,
        },
    ]);
    const [nonWorkingDateArr, setNonWorkingDateArr] = React.useState([]);
    const [calenderKey, setCalenderKey] = React.useState(0);
    const [pageKey, setPageKey]= React.useState(0);

    React.useEffect(() => {
        getCalenderSetupDay();
    }, []);

    const getCalenderSetupDay = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_EMPLOYEE_CALENDER}`, headers)
            .then((response) => {
                if (response.working_days && response.working_days.length > 0) {
                    const workData = response.working_days.map((work) => {
                        return {
                            ...work,
                            startTime: work.startTime
                                ? `${work.startTime.hours === 0 ? '00' : work.startTime.hours}:${
                                      work.startTime.minutes === 0 ? '00' : work.startTime.minutes
                                  }`
                                : null,
                            endTime: work.endTime
                                ? `${work.endTime.hours === 0 ? '00' : work.endTime.hours}:${
                                      work.endTime.minutes === 0 ? '00' : work.endTime.minutes
                                  }`
                                : null,
                        };
                    });
                    setWorkingDay(workData);
                }
            })
            .catch((err) => {});
    };

    const calenderStartSettingHandler = (dayName, startTime) => {
        const newData = [...workingDay];
        newData.forEach((i, index) => {
            if (i.dayName === dayName) {
                newData[index].startTime = startTime;
            }
        });
        setWorkingDay(newData);
    };

    const calenderEndSettingHandler = (dayName, endTime) => {
        const newData = [...workingDay];
        newData.forEach((i, index) => {
            if (i.dayName === dayName) {
                newData[index].endTime = endTime;
            }
        });
        setWorkingDay(newData);
    };

    const updateCalender = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const workingDays = workingDay.map((work) => {
            return {
                ...work,
                startTime: work.startTime
                    ? { hours: work.startTime.split(':')[0], minutes: work.startTime.split(':')[1] }
                    : null,
                endTime: work.endTime
                    ? { hours: work.endTime.split(':')[0], minutes: work.endTime.split(':')[1] }
                    : null,
            };
        });

        APIHelper.postMethod(`${API.UPDATE_CALENDER_LOG}`, { calender: workingDays }, headers)
            .then(() => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Success!',
                    text: 'Calender time log saved successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
                APIHelper.getMethod(`${API.TRIGGER_EMPLOYEE_CALENDER}`, headers);
            })
            .catch((err) => {});
    };

    const nonWorkingDays = () => {
        if (nonWorkingDateArr && nonWorkingDateArr.length > 0) {
            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };
            const dateArr = nonWorkingDateArr.map((d) => moment(d).format('YYYY-MM-DD'));
            APIHelper.postMethod(`${API.UPDATE_NON_WORKING_DAY}`, { non_working: dateArr }, headers)
                .then((res) => {
                    if (res === 'Some dates already non-working on calender.') {
                        Swal.fire({
                            customClass: 'Alert-Class',
                            title: 'Information!',
                            text: 'Some dates already blocked on calender.',
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#1876D2',
                        });
                    } else {
                        Swal.fire({
                            customClass: 'Alert-Class',
                            title: 'Success!',
                            text: 'Calender hoiliday saved successfully!',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#1876D2',
                        });
                        setNonWorkingDateArr(null);
                        setCalenderKey(Math.random());
                    }
                })
                .catch((err) => {});
        }
    };

    const handleMultipleDateSelection = (dates) => {
        let momentDate = [];
        if (dates.length > 0 && dates !== undefined) {
            dates.map((item) => {
                momentDate.push(
                    moment(new Date(`${item.month.number}-${item.day}-${item.year}`)).format(
                        'MM-DD-YYYY'
                    )
                );
            });
        }

        setNonWorkingDateArr(momentDate);
    };

    const handleResetCalender = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(`${API.RESET_CALENDER}`, {}, headers)
            .then(() => {
                setPageKey(Math.random());
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Success!',
                    text: 'Calender reset successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
                // getCalenderSetupDay();
                setWorkingDay([
                    {
                        dayName: 'mon',
                        startTime: null,
                        endTime: null,
                    },
                    {
                        dayName: 'tue',
                        startTime: null,
                        endTime: null,
                    },
                    {
                        dayName: 'wed',
                        startTime: null,
                        endTime: null,
                    },
                    {
                        dayName: 'thu',
                        startTime: null,
                        endTime: null,
                    },
                    {
                        dayName: 'fri',
                        startTime: null,
                        endTime: null,
                    },
                    {
                        dayName: 'sat',
                        startTime: null,
                        endTime: null,
                    },
                    {
                        dayName: 'sun',
                        startTime: null,
                        endTime: null,
                    },
                ]);
            })
            .catch((err) => {});
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="editDetailsContentHeading">Calender Set up</div>
                <ContainedButtons
                    text="View My Calender"
                    color={['#a4c772', '#4e8400']}
                    height={48}
                />
            </div>
            <h3
                style={{
                    color: '#1c1c1c',
                    fontSize: 26,
                    fontFamily: 'Libre Franklin',
                    fontWeight: 'normal',
                    marginTop: 67,
                }}
            >
                Default Working Times:
            </h3>
            <p
                style={{
                    marginTop: 24,
                    fontSize: 20,
                    fontFamily: 'Libre Franklin',
                    color: '#9a9a9a',
                }}
            >
                Your calendar always shows a three months look ahead. By default you are always
                shown available to work everyday.
            </p>
            <p
                style={{
                    marginTop: 24,
                    fontSize: 20,
                    fontFamily: 'Libre Franklin',
                    color: '#9a9a9a',
                }}
            >
                You can customize your calendar by changing the default setting in table below. If
                you choose to do so moving forward the new setting will be applied automatically to
                your calendar.
            </p>
            <h3
                style={{
                    color: '#1c1c1c',
                    fontSize: 26,
                    fontFamily: 'Libre Franklin',
                    fontWeight: 'normal',
                    marginTop: 86,
                }}
            >
                Set up your typical working week
            </h3>
            <Card style={{ width: '60%', padding: 56 }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <p style={{ marginRight: 40, fontSize: 20, color: '#1c1c1c' }}>Mon</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'mon') &&
                            workingDay.find((work) => work.dayName === 'mon').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('mon', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'mon') &&
                            workingDay.find((work) => work.dayName === 'mon').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('mon', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 23,
                    }}
                >
                    <p style={{ marginRight: 47, fontSize: 20, color: '#1c1c1c' }}>Tue</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'tue') &&
                            workingDay.find((work) => work.dayName === 'tue').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('tue', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'tue') &&
                            workingDay.find((work) => work.dayName === 'tue').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('tue', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 23,
                    }}
                >
                    <p style={{ marginRight: 40, fontSize: 20, color: '#1c1c1c' }}>Wed</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'wed') &&
                            workingDay.find((work) => work.dayName === 'wed').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('wed', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'wed') &&
                            workingDay.find((work) => work.dayName === 'wed').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('wed', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 23,
                    }}
                >
                    <p style={{ marginRight: 40, fontSize: 20, color: '#1c1c1c' }}>Thur</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'thu') &&
                            workingDay.find((work) => work.dayName === 'thu').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('thu', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'thu') &&
                            workingDay.find((work) => work.dayName === 'thu').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('thu', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 23,
                    }}
                >
                    <p style={{ marginRight: 55, fontSize: 20, color: '#1c1c1c' }}>Fri</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'fri') &&
                            workingDay.find((work) => work.dayName === 'fri').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('fri', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'fri') &&
                            workingDay.find((work) => work.dayName === 'fri').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('fri', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 23,
                    }}
                >
                    <p style={{ marginRight: 47, fontSize: 20, color: '#1c1c1c' }}>Sat</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'sat') &&
                            workingDay.find((work) => work.dayName === 'sat').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('sat', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'sat') &&
                            workingDay.find((work) => work.dayName === 'sat').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('sat', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 23,
                    }}
                >
                    <p style={{ marginRight: 40, fontSize: 20, color: '#1c1c1c' }}>Sun</p>
                    <TimePicker
                        id={'startTime'}
                        label={'Start Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'sun') &&
                            workingDay.find((work) => work.dayName === 'sun').startTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderStartSettingHandler('sun', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                    <TimePicker
                        id={'endTime'}
                        label={'End Time'}
                        widthParent={'60%'}
                        width={'80%'}
                        fontSize={Constant.FONT_SIZE_WEB}
                        fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                        fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                        value={
                            workingDay.find((work) => work.dayName === 'sun') &&
                            workingDay.find((work) => work.dayName === 'sun').endTime
                        }
                        onChangeCallback={(time, timeObject, time24hr) => {
                            calenderEndSettingHandler('sun', time24hr);
                        }}
                        refreshData={pageKey}
                    />
                </div>
            </Card>
            <div style={{ marginTop: 23 }}>
                <ContainedButtons
                    text="Update Calender"
                    color={['#a4c772', '#4e8400']}
                    height={48}
                    onClick={() => updateCalender()}
                />
            </div>
            <div
                className="EditDetailsBottomLineStyles"
                style={{ marginTop: 80, marginBottom: 85 }}
            />
            <h3
                style={{
                    color: '#1c1c1c',
                    fontSize: 26,
                    fontFamily: 'Libre Franklin',
                    fontWeight: 'normal',
                    marginTop: 86,
                }}
            >
                Additional Non-working Days
            </h3>
            <p
                style={{
                    marginTop: 24,
                    fontSize: 20,
                    fontFamily: 'Libre Franklin',
                    color: '#9a9a9a',
                }}
            >
                In addition to the above settings (default or customized calendar) you can designate
                more days as non-working in table below:
            </p>
            <div style={{ marginTop: 40 }}>
                <MultipleDatePicker
                    range={false}
                    onChange={(e) => handleMultipleDateSelection(e)}
                    // value={selectedDates}
                    style={{ height: 54, width: 350, marginTop: 17 }}
                    placeholder="Select Dates"
                    // onOpen={() => setActivePart(DASHBOARD.DATE_PICKER)}
                    minDate={new Date()}
                    key={calenderKey}
                />
                <div style={{ marginTop: 30 }}>
                    <ContainedButtons
                        text="Mark Selected Days as Non-working"
                        color={['#a4c772', '#4e8400']}
                        onClick={() => nonWorkingDays()}
                        height={48}
                    />
                </div>
            </div>
            <div
                className="EditDetailsBottomLineStyles"
                style={{ marginTop: 79, marginBottom: 85 }}
            />
            <h3
                style={{
                    color: '#1c1c1c',
                    fontSize: 26,
                    fontFamily: 'Libre Franklin',
                    fontWeight: 'normal',
                    marginTop: 86,
                }}
            >
                Reset Calendar
            </h3>
            <p
                style={{
                    marginTop: 24,
                    fontSize: 20,
                    fontFamily: 'Libre Franklin',
                    color: '#9a9a9a',
                }}
            >
                By resetting your calender all the non working days will be removed. The three
                months look ahead calender will change back to the default status which will show
                you available for work on everyday.
            </p>
            <div style={{ marginTop: 30, marginBottom: 86 }}>
                <ContainedButtons
                    text="Reset My Calender"
                    color={['#a4c772', '#4e8400']}
                    height={48}
                    onClick={() => handleResetCalender()}
                />
            </div>
        </div>
    );
};

export default CalenderSetup;
