import React from "react";
import moment from "moment";

import Dropdown from "../../../Components/DropdownComponent/Dropdown.component";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContainedButtons from "../../../Components/Button/Button";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import DrawerComponent from "../../../Components/Drawer/Drawer";
import JobOfferApply from "./Component/JobOfferApply";
import { APIHelper } from "../../../utils/ApiHelper";
import API from "../../../utils/Urls";

const JobOffers = (props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [jobOfferData, setJobOfferData] = React.useState([]);
  const [selectedJob, setSelectedJob] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [applicationData, setApplicationData] = React.useState({});
  const [sortBy, setSortBy] = React.useState({ label: "Start Date", value: "startDate" });
  const [loading, setLoading] = React.useState(false);
  const [isPaymentMethodVerified, setIsPaymentMethodVerified] = React.useState(false);

  React.useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(API.GET_EMPLOYEE_PAYMENT_ACTIVE, headers)
      .then((res) => {
        setIsPaymentMethodVerified(res.result);
      })
      .catch((error) => {});
  }, []);

  React.useEffect(() => {
    getListEmployeeOffer();
  }, [page, sortBy]);

  const getListEmployeeOffer = () => {
    setLoading(true);
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    APIHelper.getMethod(API.GET_LIST_EMPLOYEE_OFFER + `page=${page}&sort=${sortBy ? sortBy.value : ""}`, headers)
      .then((res) => {
        if (res && res.employeeListOffer) {
          setJobOfferData(res.employeeListOffer);
          setTotal(res.employeeListOfferCount);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getTimeLeftToExpire = (jobExpiryDate) => {
    var duration = moment.duration(moment(jobExpiryDate, "YYYY-MM-DD").diff(moment()));
    var hours = duration.asHours();
    if (hours > 24) {
      return { label: "TIME_LEFT", time: `${Math.ceil(duration.asDays())}` };
    } else {
      var h = parseInt(duration.asSeconds() / 3600);
      var m = parseInt(duration.asSeconds() / 60) % 60;
      if (h > 0 && m > 0) {
        var result = h + ":" + m;
        return { label: "TIME_LEFT", time: result };
      } else {
        return { label: "TIME_EXPIRED", time: "" };
      }
    }
  };

  const is_suspend = localStorage.getItem("is_suspend") || false;
  const disabledDate = moment(localStorage.getItem("suspended_date")) || moment();
  const month_data = disabledDate.add(1, "months");
  const disabledBtn = is_suspend === "true" ? month_data.isAfter(moment()) : false;
  return (
    <>
      <div className="editDetailsContentHeading">Job Invitations</div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "30px",
        }}
      >
        <p className="SortByDropdown">Sort by:</p>
        <div style={{ width: "170px" }}>
          <Dropdown
            options={[
              { label: "Start Date", value: "startDate" },
              { label: "Company A-Z", value: "companyAtoZ" },
            ]}
            placeholder="Select sorting"
            value={sortBy}
            onChange={(e) => {
              setPage(1);
              setSortBy(e);
            }}
          />
        </div>
      </div>
      <table
        style={{
          width: "100%",
          textAlign: "left",
          borderCollapse: "collapse",
          fontFamily: "Libre Franklin",
        }}
      >
        <thead>
          <tr style={{ borderBottom: "2px solid #C4C4C4", height: 60 }}>
            <th style={{ width: "25%", color: "#1c1c1c" }}>Company Name</th>
            <th style={{ width: "15%", color: "#1c1c1c" }}>Job Title</th>
            <th style={{ width: "15%", color: "#1c1c1c" }}>Start Date</th>
            <th style={{ width: "15%", color: "#1c1c1c" }}>Fee/Rate</th>
            <th style={{ width: "15%", color: "#1c1c1c" }}>Status</th>
            <th style={{ width: "30%" }}></th>
          </tr>
        </thead>
        {loading ? (
          <tr style={{ textAlign: "center", height: 100 }}>
            <td colSpan="6">
              <CircularProgress size={40} />
            </td>
          </tr>
        ) : (
          <tbody>
            {jobOfferData.length > 0 ? (
              jobOfferData.map((item) => {
                const status = getTimeLeftToExpire(item.job.jobExpiryDate);
                return (
                  <tr
                    style={{
                      height: 86,
                      borderBottom: "2px solid #C4C4C4",
                      backgroundColor: "inherit",
                    }}
                  >
                    <td style={{ width: "25%" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                          <AccountCircleIcon style={{ height: "70px", width: "70px" }} />
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            marginLeft: 10,
                            opacity: 0.78,
                          }}
                        >
                          <p className="hiringEmployeeName">
                            {(item.employerProfile && item.employerProfile.company?.name) || ""}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style={{ width: "15%", color: "#1c1c1c" }}>{item.job && item.job.title}</td>
                    <td style={{ width: "15%", color: "#1c1c1c" }}>
                      {item.job && item.job.jobType === "FIXED"
                        ? moment(item.startDate).utc(0).format("MMM DD, YY")
                        : item.schedule && item.schedule.length > 0
                        ? moment(item.schedule[0].dateSchedule).utc(0).format("MMM DD, YY")
                        : item.job &&
                          item.job.hourlySchedule &&
                          item.job.hourlySchedule.length > 0 &&
                          item.job.hourlySchedule[0].dateSchedule &&
                          moment(item.job.hourlySchedule[0].dateSchedule).utc(0).format("MMM DD, YY")}
                    </td>
                    <td style={{ width: "15%", color: "#1c1c1c" }}>
                      {item.job && item.job.jobPostType === "JOB_INVITATION"
                        ? `$${item.proposeHourlyRate}`
                        : item.job && item.job.jobType === "HOURLY"
                        ? item.job.rate === 0
                          ? "$0"
                          : item.job.rate.min === item.job.rate.max
                          ? `$${item.job.rate.min}`
                          : `$${item.job.rate.min} - $${item.job.rate.max}`
                        : item.job && `$${item.job.JobValue}`}
                    </td>
                    <td style={{ width: "30%", paddingRight: 42, color: "#1c1c1c" }}>
                      <ProgressBar type="TimeSheetReview" variant={status.label} time={status.time} />
                    </td>
                    <td style={{ width: "15%", textAlign: "end", color: "#1c1c1c" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ContainedButtons
                          text={item.status === "APPROVED" ? "Accept" : "Apply"}
                          color={["#a4c772", "#4e8400"]}
                          height={48}
                          width={"12vw"}
                          onClick={() => {
                            setApplicationData(item);
                            setDrawerOpen(true);
                            setSelectedJob(item);
                          }}
                          disabled={disabledBtn}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr style={{ textAlign: "center", height: 100 }}>
                <td colSpan="6">
                  <p>No data found.</p>
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      {!loading && jobOfferData.length > 0 && (
        <div className="advancedSearchPagination" style={{ marginTop: 85, marginBottom: 86 }}>
          <Pagination
            count={Math.ceil(total / 10)}
            page={page}
            onChange={(e, value) => setPage(value)}
            style={{ justifyContent: "center" }}
            size="large"
          />
        </div>
      )}
      <DrawerComponent
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        component={
          <JobOfferApply
            selectedJob={selectedJob}
            setDrawerOpen={setDrawerOpen}
            applicationData={applicationData}
            onRefreshGrid={getListEmployeeOffer}
            isPaymentMethodVerified={isPaymentMethodVerified}
          />
        }
        width="80%"
      />
    </>
  );
};

export default JobOffers;
