import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';



const DrawerComponent = (props) => {

    const useStyles = makeStyles({
        paper: {
            width: `calc(${props.width || '90%'})`,
        }
    });

    const [open, setOpen] = React.useState(props.open || false);
    const classes = useStyles();

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
        if (!open) {
            props.onClose(false);
        }
    };

    return (
        <React.Fragment>
            <Drawer
                anchor={"right"}
                open={open}
                onClose={toggleDrawer(false)}
                classes={{
                    paper: clsx(classes.paper),
                }}
                style={{ zIndex: 100 }}
                transitionDuration={800}
            >
                <div
                    style={{ width: '100%', marginTop: 50 }}
                    role="presentation"
                >
                    {props.component}
                </div>
            </Drawer>
        </React.Fragment>
    );
}

export default DrawerComponent;