import React from 'react';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
} from 'recharts';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import CheckIcon from '@material-ui/icons/Check';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import EmailIcon from '@material-ui/icons/Email';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import CircularProgress from '@material-ui/core/CircularProgress';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const Overview = () => {
    const [overviewData, setOverviewData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [currentSchedule, setCurrentSchedule] = React.useState([]);

    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_EMPLOYEE_OVERVIEW, headers)
            .then((res) => {
                setOverviewData(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        loadCalendar();
    }, []);

    const loadCalendar = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_BLOCK_DATE, headers).then((response) => {
            const data = response;
            let allSchedule = [];
            data &&
                data['block_days'] &&
                data['block_days'].forEach((contract) => {
                    allSchedule.push({
                        date: moment(contract.date).utc(0).format('YYYY-MM-DD'),
                        backgroundColor: '#deb136'
                    });
                });
            setCurrentSchedule(allSchedule);
        });
        return false;
    };

    return (
        <React.Fragment>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress size={40} />
                </div>
            ) : (
                <>
                    <div className="editDetailsContentHeading">Overview</div>
                    <div className="row" style={{ marginTop: 97, height: 402 }}>
                        <div className="col-md-4">
                            <Card style={{ width: '93%' }}>
                                <FullCalendar
                                    plugins={[dayGridPlugin]}
                                    initialView="dayGridMonth"
                                    headerToolbar={{
                                        left: 'prev,next',
                                        center: 'title',
                                        right: '',
                                    }}
                                    events={currentSchedule}
                                />
                            </Card>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-4">
                                    <Card style={{ width: '90%' }}>
                                        <div style={{ height: 380 }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Libre Franklin',
                                                    color: '#070707',
                                                    textAlign: 'center',
                                                    marginTop: 20,
                                                }}
                                            >
                                                Job offers expiring soon
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Libre Franklin',
                                                    color: '#1c1c1c',
                                                    textAlign: 'center',
                                                    marginTop: 37,
                                                }}
                                            >
                                                Time left to expiration
                                            </p>
                                            <div
                                                style={{
                                                    height: 284,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: 28,
                                                    paddingTop: 4,
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        className="row"
                                                        style={{ width: '100%', height: '100%' }}
                                                    >
                                                        <div className="col-md-2">
                                                            <p
                                                                style={{
                                                                    writingMode: 'vertical-rl',
                                                                    textOrientation: 'mixed',
                                                                }}
                                                            >
                                                                48+ Hrs
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-10"
                                                            style={{
                                                                backgroundColor: '#DEB136',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.7rem',
                                                            }}
                                                        >
                                                            {overviewData &&
                                                                overviewData.timeLeftToExpiration
                                                                    .morethan48hours}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        className="row"
                                                        style={{ width: '100%', height: '100%' }}
                                                    >
                                                        <div className="col-md-2">
                                                            <p
                                                                style={{
                                                                    writingMode: 'vertical-rl',
                                                                    textOrientation: 'mixed',
                                                                }}
                                                            >
                                                                48 Hrs
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-10"
                                                            style={{
                                                                backgroundColor: '#DB6224',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.7rem',
                                                            }}
                                                        >
                                                            {overviewData &&
                                                                overviewData.timeLeftToExpiration
                                                                    .hour48}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-4">
                                    <Card style={{ width: '90%' }}>
                                        <div style={{ height: 380 }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Libre Franklin',
                                                    color: '#070707',
                                                    textAlign: 'center',
                                                    marginTop: 20,
                                                }}
                                            >
                                                Cancellation Requests <br /> (Hourly Jobs)
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Libre Franklin',
                                                    color: '#1c1c1c',
                                                    textAlign: 'center',
                                                    marginTop: 20,
                                                }}
                                            >
                                                Time left to review
                                            </p>
                                            <div
                                                style={{
                                                    height: 284,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: 28,
                                                    paddingTop: 4,
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        className="row"
                                                        style={{ width: '100%', height: '100%' }}
                                                    >
                                                        <div className="col-md-2">
                                                            <p
                                                                style={{
                                                                    writingMode: 'vertical-rl',
                                                                    textOrientation: 'mixed',
                                                                }}
                                                            >
                                                                24+ Hrs
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-10"
                                                            style={{
                                                                backgroundColor: '#DEB136',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.7rem',
                                                            }}
                                                        >
                                                            {overviewData &&
                                                                overviewData.cancellationReview
                                                                    .hourlyMorethan24hrsRemain}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        className="row"
                                                        style={{ width: '100%', height: '100%' }}
                                                    >
                                                        <div className="col-md-2">
                                                            <p
                                                                style={{
                                                                    writingMode: 'vertical-rl',
                                                                    textOrientation: 'mixed',
                                                                }}
                                                            >
                                                                24 Hrs
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-10"
                                                            style={{
                                                                backgroundColor: '#DB6224',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.7rem',
                                                            }}
                                                        >
                                                            {overviewData &&
                                                                overviewData.cancellationReview
                                                                    .hourly24hrsRemain}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-4">
                                    <Card style={{ width: '90%' }}>
                                        <div style={{ height: 380 }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Libre Franklin',
                                                    color: '#070707',
                                                    textAlign: 'center',
                                                    marginTop: 20,
                                                }}
                                            >
                                                Cancellation Requests <br /> (Fixed-Fee Jobs)
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    fontFamily: 'Libre Franklin',
                                                    color: '#1c1c1c',
                                                    textAlign: 'center',
                                                    marginTop: 20,
                                                }}
                                            >
                                                Time left to review
                                            </p>
                                            <div
                                                style={{
                                                    height: 284,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: 28,
                                                    paddingTop: 4,
                                                }}
                                            >
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        className="row"
                                                        style={{ width: '100%', height: '100%' }}
                                                    >
                                                        <div className="col-md-2">
                                                            <p
                                                                style={{
                                                                    writingMode: 'vertical-rl',
                                                                    textOrientation: 'mixed',
                                                                }}
                                                            >
                                                                24+ Hrs
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-10"
                                                            style={{
                                                                backgroundColor: '#DEB136',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.7rem',
                                                            }}
                                                        >
                                                            {overviewData &&
                                                                overviewData.cancellationReview
                                                                    .fixedMorethan24hrsRemain}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <div
                                                        className="row"
                                                        style={{ width: '100%', height: '100%' }}
                                                    >
                                                        <div className="col-md-2">
                                                            <p
                                                                style={{
                                                                    writingMode: 'vertical-rl',
                                                                    textOrientation: 'mixed',
                                                                }}
                                                            >
                                                                24 Hrs
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-md-10"
                                                            style={{
                                                                backgroundColor: '#DB6224',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#ffffff',
                                                                fontWeight: 'bold',
                                                                fontSize: '1.7rem',
                                                            }}
                                                        >
                                                            {overviewData &&
                                                                overviewData.cancellationReview
                                                                    .fixed24hrsRemain}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 24, height: 350 }}>
                        <div className="col-md-7">
                            <Card style={{ width: '96%' }}>
                                <div style={{ height: 350 }}>
                                    <p
                                        style={{
                                            fontSize: 19,
                                            fontFamily: 'Libre Franklin',
                                            color: '#070707',
                                            textAlign: 'center',
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        Hourly Jobs
                                    </p>
                                    <ResponsiveContainer width="100%" height={290}>
                                        <BarChart
                                            data={overviewData && overviewData.hourlyJobs}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="Local" fill="#621EEE" />
                                            <Bar dataKey="International" fill="#CFB2F9" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                <div className={'col-md-12'}>
                                    <Card style={{ width: '90%' }}>
                                        <div style={{ height: 350 }}>
                                            <div style={{ height: 72, backgroundColor: '#f5f5f5' }}>
                                                <p
                                                    style={{
                                                        fontSize: 19,
                                                        fontFamily: 'Libre Franklin',
                                                        color: '#070707',
                                                        textAlign: 'center',
                                                        lineHeight: '35px',
                                                    }}
                                                >
                                                    Messages <br /> & Notifications
                                                </p>
                                            </div>
                                            <div
                                                style={{
                                                    height: 278,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-evenly',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div className="tag">5</div>
                                                    <p style={{ fontSize: 14 }}>Unread messages</p>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div className="tag">10+</div>
                                                    <p style={{ fontSize: 14 }}>
                                                        Unread Notifications
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 24, height: 350 }}>
                        <div className="col-md-7">
                            <Card style={{ width: '96%' }}>
                                <div style={{ height: 350 }}>
                                    <p
                                        style={{
                                            fontSize: 19,
                                            fontFamily: 'Libre Franklin',
                                            color: '#070707',
                                            textAlign: 'center',
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        Total Job Value
                                    </p>
                                    <ResponsiveContainer width="99%" height={290}>
                                        <LineChart
                                            width={500}
                                            height={300}
                                            data={overviewData && overviewData.totalLocalCurrency}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line
                                                type="monotone"
                                                dataKey="Local (Local currency)"
                                                stroke="#55D8FE"
                                                activeDot={{ r: 10 }}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-5">
                            <Card style={{ width: '95%' }}>
                                <div style={{ height: 350 }}>
                                    <p
                                        style={{
                                            fontSize: 19,
                                            fontFamily: 'Libre Franklin',
                                            color: '#070707',
                                            textAlign: 'center',
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        Fixed Fee Projects <br />
                                        Local Jobs ({localStorage.getItem('localCurrency')})
                                    </p>
                                    <ResponsiveContainer width="99%" height={270}>
                                        <BarChart
                                            data={overviewData && overviewData.fixedFeeProjects}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="total" fill="#621EEE" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 24, height: 350 }}>
                        <div className="col-md-3">
                            <Card style={{ width: '90%' }}>
                                <div style={{ height: 350 }}>
                                    <div style={{ height: 72, backgroundColor: '#f5f5f5' }}>
                                        <p
                                            style={{
                                                fontSize: 19,
                                                fontFamily: 'Libre Franklin',
                                                color: '#070707',
                                                textAlign: 'center',
                                                lineHeight: '72px',
                                            }}
                                        >
                                            Account Status
                                        </p>
                                    </div>
                                    <div style={{ height: 278 }}>
                                        <p
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 10,
                                                fontWeight: 700,
                                            }}
                                        >
                                            Verifications
                                        </p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: '0 15px',
                                                marginTop: 10,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <PersonOutlineOutlinedIcon
                                                style={{ color: '#1876D2' }}
                                            />
                                            <p style={{ flex: 1, color: '#9a9a9a', marginLeft: 5 }}>
                                                Account Active
                                            </p>
                                            {overviewData &&
                                                overviewData.userAccountDetails.accountActive && (
                                                    <CheckIcon style={{ color: '#1876D2' }} />
                                                )}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: '0 15px',
                                                marginTop: 10,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <VerifiedUserOutlinedIcon
                                                style={{ color: '#1876D2' }}
                                            />
                                            <p style={{ flex: 1, color: '#9a9a9a', marginLeft: 5 }}>
                                                Identity Verified
                                            </p>
                                            {overviewData &&
                                                overviewData.userAccountDetails
                                                    .identityVerified && (
                                                    <CheckIcon style={{ color: '#1876D2' }} />
                                                )}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: '0 15px',
                                                marginTop: 10,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <PaymentOutlinedIcon style={{ color: '#1876D2' }} />
                                            <p style={{ flex: 1, color: '#9a9a9a', marginLeft: 5 }}>
                                                Payment Verified
                                            </p>
                                            {overviewData &&
                                                overviewData.userAccountDetails.paymentVerified && (
                                                    <CheckIcon style={{ color: '#1876D2' }} />
                                                )}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: '0 15px',
                                                marginTop: 10,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <LocalPhoneIcon style={{ color: '#1876D2' }} />
                                            <p style={{ flex: 1, color: '#9a9a9a', marginLeft: 5 }}>
                                                Phone Verified
                                            </p>
                                            {overviewData &&
                                                overviewData.userAccountDetails.phoneVerified && (
                                                    <CheckIcon style={{ color: '#1876D2' }} />
                                                )}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: '0 15px',
                                                marginTop: 10,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <EmailIcon style={{ color: '#1876D2' }} />
                                            <p style={{ flex: 1, color: '#9a9a9a', marginLeft: 5 }}>
                                                Email Verified
                                            </p>
                                            {overviewData &&
                                                overviewData.userAccountDetails.emailVerified && (
                                                    <CheckIcon style={{ color: '#1876D2' }} />
                                                )}
                                        </div>
                                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                                            <a href="/employee/account-details" style={{ color: '#9a9a9a' }}>
                                                Account Settings
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        {/*<div className="col-md-3">
                            <Card style={{ width: '90%' }}>
                                <div style={{ height: 350 }}>
                                    <div style={{ height: 72, backgroundColor: '#f5f5f5' }}>
                                        <p
                                            style={{
                                                fontSize: 19,
                                                fontFamily: 'Libre Franklin',
                                                color: '#070707',
                                                textAlign: 'center',
                                                lineHeight: '72px',
                                            }}
                                        >
                                            Help & Support
                                        </p>
                                    </div>
                                    <div style={{ marginLeft: 33 }}>
                                        <p
                                            style={{
                                                color: '#9A9A9A',
                                                marginTop: 48,
                                                fontSize: 16,
                                                fontFamily: 'Libre Franklin',
                                            }}
                                        >
                                            How it works
                                        </p>
                                        <p
                                            style={{
                                                color: '#9A9A9A',
                                                marginTop: 25,
                                                fontSize: 16,
                                                fontFamily: 'Libre Franklin',
                                            }}
                                        >
                                            FAQ
                                        </p>
                                        <p
                                            style={{
                                                color: '#9A9A9A',
                                                marginTop: 25,
                                                fontSize: 16,
                                                fontFamily: 'Libre Franklin',
                                            }}
                                        >
                                            Feedback
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </div>*/}
                    </div>
                </>
            )}
        </React.Fragment>
    );
};

export const CustomPieChart = (props) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={props.data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={props.renderCustomizedLabel}
                    outerRadius={props.radius}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {props.data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={props.COLORS[index % props.COLORS.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};
export default Overview;
