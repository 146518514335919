import axios from 'axios';
import { logoutHandler } from '../Components/Header/Header';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if(error && error.response && error.response.status === 401 && error.response.data.message !== "We don’t recognize your username and/or password. Please try again or Forgot.") {
            logoutHandler();
            window.location = '/login';
        }
        return Promise.reject(error.message);
    }
);
