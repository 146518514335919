import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { COLOR_TYPE } from "./ProgressBar.constant";

const ProgressBar = (props) => {
  const [color, setColor] = React.useState("");
  const [background, setBackground] = React.useState("");
  const [label, setLabel] = React.useState(props.label || "");

  const value = React.useMemo(() => {
    var hours = 0;
    var mintues = 0;
    var percentage = 0;
    if (props.time) {
      const data = props.time && props.time.split(":");
      if (data.length === 2) {
        hours += parseInt(data[0]);
        mintues += parseInt(data[1]);
        const seconds = hours * 60 * 60 + mintues * 60;
        percentage = 100 - (seconds * 100) / 86400;
      }
    }
    if (props.type) {
      if (props.type === "HOURLY" || props.type === "FIXED") {
        if (props.variant === "IN_PROGRESS") {
          setColor(COLOR_TYPE.GREEN);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Work in progress");
          return 100;
        }
        if (props.variant === "NOT_STARTED") {
          setColor(COLOR_TYPE.GREY);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Work not started");
          return 100;
        }
        if (props.variant === "CANCELLED") {
          setColor(COLOR_TYPE.ORANGE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Cancelled");
          return 100;
        }
        if (props.variant === "CANCELLED_BY") {
          setColor(COLOR_TYPE.ORANGE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Cancellation requested");
          return 100;
        }
        if (props.variant === "CANCELLED_FINAL") {
          setColor(COLOR_TYPE.ORANGE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Cancelled");
          return 100;
        }
        if (props.variant === "TIME_LEFT") {
          setColor(COLOR_TYPE.RED);
          setBackground(COLOR_TYPE.ORANGE);
          setLabel("Time left to review cancellation");
          return percentage;
        }
        if (props.variant === "COMPLETED") {
          setColor(COLOR_TYPE.BLUE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Completed");
          return 100;
        }
        if (props.variant === "COMPLETED") {
          setColor(COLOR_TYPE.BLUE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Completed");
          return 100;
        }
        if (props.variant === "MILESTONE_REVIEW") {
          setColor(COLOR_TYPE.ORANGE);
          setBackground(COLOR_TYPE.ORANGE);
          setLabel("Review Milestone Update");
          return 100;
        }
      }

      if (props.type === "JobPostAndInvitation") {
        if (props.variant === "JOB_EXPIRED") {
          setColor(COLOR_TYPE.ORANGE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Expiring soon");
          return 100;
        }
        if (props.variant === "TIME_LEFT") {
          setColor(COLOR_TYPE.RED);
          setBackground(COLOR_TYPE.ORANGE);
          setLabel("Time left to expire");
          return percentage;
        }
        if (props.variant === "ACTIVE") {
          setColor(COLOR_TYPE.GREEN);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Active");
          return 100;
        }
        if (props.variant === "IN_PROGRESS") {
          setColor(COLOR_TYPE.GREEN);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Work in progress");
          return 100;
        }
      }

      if (props.type === "TimeSheetReview") {
        if (props.variant === "TIME_LEFT") {
          setColor(COLOR_TYPE.RED);
          setBackground(COLOR_TYPE.ORANGE);
          setLabel("Time left to expire");
          return percentage;
        }
        if (props.variant === "TIME_MODIFIED") {
          setColor(COLOR_TYPE.ORANGE);
          setBackground(COLOR_TYPE.GREY);
          setLabel("Time left to modify timesheet");
          return percentage;
        }
      }
    }
    return 0;
  }, [props.time]);

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: background,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: color,
    },
  }))(LinearProgress);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 6 }}>
        {label !== "" && <p style={{ fontSize: 16, fontFamily: "Libre Franklin", color: "#1c1c1c" }}>{label}</p>}
        {props.time !== "00:00" && (
          <p style={{ fontSize: 16, fontFamily: "Libre Franklin", color: "#1c1c1c", float: "right" }}>
            {props.time && (props.time.split(":").length === 2 ? `${props.time}hr` : props.time && `${props.time}d`)}
          </p>
        )}
      </div>
      <BorderLinearProgress variant="determinate" value={value} />
    </>
  );
};

export default ProgressBar;
