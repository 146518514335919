import React, { useEffect } from 'react';
import './EmployeeAdvancedSearchTalent.scss';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import Pagination from '@material-ui/lab/Pagination';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '../../../Components/Slider/Slider';
import WideProfileCard from '../../../Components/WideProfileCard/WideProfileCard';
import ShortProfileCard from '../../../Components/ShortProfileCard/ShortProfileCard';
import SingleEmployeeInviteDetails from '../../SingleEmployeeInviteCards/SingleEmployeeInviteDetails';
import Button from '../../../Components/Button/Button';
import MultipleInviteCard from '../../MultipleInviteCard/MultipleInviteCard';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import API from '../../../utils/Urls';
import { APIHelper } from '../../../utils/ApiHelper';
import AutoCompleteCard from '../../../Components/AutoCompleteCard/AutoCompleteCard';
import MultipleDatePicker from '../../../Components/MultipleDatePicker/MultipleDatePicker';
import Chip from '../../../Components/Chip/CustomizableChip';
import { getDates } from '../../../utils/commonFunction';
import WideJobCard from '../../../Components/WideJobCard/WideJobCard';
import CheckboxComponent from '../../../Components/CheckboxComponent/CheckboxComponent';
import Textfield from '../../../Components/TextField/Textfield';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import JobApplicationApply from './Component/JobApplicationApply';

const useStylesCard = makeStyles({
    root: {
        borderRadius: '1.041vw',
        marginLeft: '4.04vw',
        marginTop: '6.4vw',
        width: '50vw',
        display: 'inline-block',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const EmployeeAdvancedSearchTalent = (props) => {
    const [hourlyRate, setHourlyRate] = React.useState([0, 100]);
    const [fixedMinAmount, setFixedMinAmount] = React.useState();
    const [fixedMaxAmount, setfixedMaxAmount] = React.useState();
    const [isJobHourly, setIsJobHourly] = React.useState(false);
    const [isJobFixed, setIsJobFixed] = React.useState(false);
    const [cardsData, setCardsData] = React.useState([]);
    const [isLocationSearchCalled, setIsLocationSearchCalled] = React.useState(false);
    const [locationOptions, setLocationOptions] = React.useState([]);
    const [location, setLocation] = React.useState([
        {
            label: 'Worldwide',
            value: 'Worldwide',
            _id: '00000000000000',
        },
    ]);
    const cardClasses = useStylesCard();
    const [selectedDates, setSelectedDates] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedSearchTerm, setSelectedSearchTerm] = React.useState({});
    const [searchOptions, setSearchOptions] = React.useState([]);
    const [specialityOptions, setSpecialityOptions] = React.useState([]);
    const [skillsOptions, setSkillsOptions] = React.useState([]);
    const [userFieldData, setUserFieldData] = React.useState({});
    const [selectedSpeciality, setSelectedSpeciality] = React.useState({});
    const [selectedSkills, setSelectedSkills] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [textFilter, setTextFilter] = React.useState(
        JSON.parse(localStorage.getItem('talentSearchData'))
    );
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [jobData, setJobData] = React.useState({});
    const [count, setCount] = React.useState(0);
    const [key, setKey] = React.useState(0);
    const [sortBy, setSortBy] = React.useState(undefined);
    const [jobLoading, setJobLoading] = React.useState(false);
    const [skillSelect, setSkillSelect] = React.useState(null);
    const [whereLocation, setWhereLocation] = React.useState(null);
    const [remoteWorking, setRemoteWorking] = React.useState(null);
    const [isPaymentMethodVerified, setIsPaymentMethodVerified] = React.useState(false);
    const [speciality, setSpeciality] = React.useState({
        label: 'All',
        value: 'All',
        _id: '0000000000000',
    });
    const history = useHistory();

    const getUpdatedCardsData = () => {
        let dates = [];
        setIsLoading(true);
        if (selectedDates.length > 0 && typeof selectedDates[0] == 'string') {
            dates = selectedDates.map((item) => {
                var d = item;
                var date = d.split('/');
                var f = new Date(date[2], date[0] - 1, date[1]);
                return f;
            });
        } else {
            dates = selectedDates.map((item) => {
                return moment(
                    `${item.day}-${item.month.number}-${item.year}`,
                    'DD-MM-YYYY'
                ).toDate();
            });
        }

        // let selectedDatesArr = getDates(dates[0], dates[1]);

        // let dates = [];

        // if (selectedDates.length > 0 && typeof selectedDates[0] == 'string') {
        //     dates = selectedDates.map((item) => {
        //         var d = item;
        //         var date = d.split('/');
        //         var f = new Date(date[2], date[0] - 1, date[1]);
        //         return f;
        //     });
        // } else {
        //     dates = selectedDates.map((item) => {
        //         return moment(
        //             `${item.day}-${item.month.number}-${item.year}`,
        //             'DD-MM-YYYY'
        //         ).toDate();
        //     });
        //     dates= dates.map((d) => moment(d).format('DD-MM-YYYY'));
        // }

        let body = {
            skills: selectedSkills.map((item) => item.label),
            searchTerm: textFilter,
            specialities: selectedSpeciality._id === '0000000000000' ? '' : selectedSpeciality._id,
            location: location.map((item) => item.label),
            dateRange:
                dates.length > 0
                    ? [
                          moment(dates[0])
                              .add('day', 1)
                              .utcOffset(0)
                              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
                          moment(dates[1])
                              .add('day', 1)
                              .utcOffset(0)
                              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
                      ]
                    : [],
            hourly: isJobHourly,
            rate: {
                min: parseInt(hourlyRate[0] || 0),
                max: parseInt(hourlyRate[1] || 100),
            },
            fixed: isJobFixed,
            fixedRate: {
                min: parseInt(fixedMinAmount),
                max: parseInt(fixedMaxAmount),
            },
            sortBy: sortBy ? sortBy.value : '',
            remoteWorking,
        };

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(`${API.TALENT_FILTER_ADVANCE_EMPLOYEE}${page}`, body, headers)
            .then((response) => {
                if (response.message === 'jwt malformed') {
                    history.push('/login');
                } else {
                    setCardsData(response[0].data);
                    setCount(response[0].total);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_EMPLOYEE_PAYMENT_ACTIVE, headers)
            .then((res) => {
                setIsPaymentMethodVerified(res.result);
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        handleSpecialityInputChange();
    }, [userFieldData]);

    useEffect(() => {
        setHourlyRate([
            localStorage.getItem('hourlyRateMin') || 0,
            localStorage.getItem('hourlyRateMax') || 100,
        ]);
        if (JSON.parse(localStorage.getItem('fieldData'))) {
            const data = JSON.parse(localStorage.getItem('fieldData'));
            if (Array.isArray(data)) {
                setUserFieldData(data[0]);
            } else {
                setUserFieldData(data);
            }
        } else {
            console.log('Unable to fetch field from the local storage');
        }

        if (localStorage.getItem('calendarDates')) {
            let calendarDates = localStorage.getItem('calendarDates').split(',');
            let dates = calendarDates.map((item) => {
                return item;
            });
            setSelectedDates(dates);
        } else {
            console.log('Unable to fetch calendarDates from the local storage');
        }
        setSelectedSearchTerm(JSON.parse(localStorage.getItem('talentSearchData')));
        getUpdatedCardsData();
    }, [sortBy]);

    useEffect(() => {
        getUpdatedCardsData();
    }, [page, isJobFixed, isJobHourly, selectedSpeciality, remoteWorking, location]);

    const handleSliderChange = (event, newValue) => {
        setHourlyRate(newValue);
    };

    const displayRateRange = (
        <div className="displayDynamicRateStyles">
            {'$' + hourlyRate[0] + ' - ' + '$' + hourlyRate[1]}
        </div>
    );

    const handleLocationChange = (e) => {
        if (e.length > 2) {
            setIsLocationSearchCalled(true);

            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            APIHelper.getMethod(API.GET_LOCATION_SEARCH_BY_SEARCH_TERM + e, headers)
                .then((response) => {
                    if (response.length > 0) {
                        setIsLocationSearchCalled(false);
                        let locationOptions = response.map((item) => {
                            return { label: item.name, value: item.name, _id: item._id };
                        });
                        locationOptions.push({
                            label: 'Worldwide',
                            value: 'Worldwide',
                            _id: '00000000000000',
                        });
                        setLocationOptions(locationOptions);
                    }
                    if (response['status'] === 0) {
                        setIsLocationSearchCalled(false);
                    }
                })
                .catch((err) => {
                    setIsLocationSearchCalled(false);
                });
        }
    };

    const handleSpecialityInputChange = (e) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(
            API.GET_SPECIALITY_BY_FIELDID + '?field_id=' + userFieldData.id,
            headers
        )
            .then((response) => {
                if (response.data.length > 0) {
                    let specialityOptions = response.data.map((item) => {
                        return { label: item.name, value: item.name, _id: item._id };
                    });
                    // setSpecialityOptions(specialityOptions)
                    setSpecialityOptions([
                        { label: 'All', value: 'All', _id: '0000000000000' },
                        ...specialityOptions,
                    ]);
                }
            })
            .catch((err) => {});
    };

    const handleSpecialityChange = (e) => {
        setSelectedSpeciality(e);
        setSkillsOptions([]);
        setSelectedSkills([]);
        APIHelper.getMethod(API.GET_SKILLS_BY_SPECIALITYID + '?speciality_id=' + e._id)
            .then((response) => {
                if (response.data.length > 0) {
                    let skillsOptions = response.data.map((item) => {
                        return { label: item.name, value: item.name, _id: item._id };
                    });
                    setSkillsOptions(skillsOptions);
                }
            })
            .catch((err) => {});
        setPage(1);
        // getUpdatedCardsData();
    };

    const handleMultipleDateSelection = (date) => {
        // let dat  es = date.map(item => {
        //     return (
        //         moment(`${item.day}-${item.month.number}-${item.year}`, "DD-MM-YYYY")
        //     )
        // })

        setSelectedDates(date);
    };

    const handleSearchChange = (e) => {
        if (e.length > 2) {
            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            APIHelper.getMethod(API.TALENT_SEARCH_BY_SEARCH_TERM + e, headers)
                .then((response) => {
                    if (response.length > 0) {
                        // setIsSearchTermCalled(false)
                        let searchOptions = response.map((item) => {
                            return { label: item.name, value: item.name, _id: item._id };
                        });

                        setSearchOptions(searchOptions);
                    }
                    if (response['status'] === 0) {
                        // setIsSearchTermCalled(false)
                    }
                })
                .catch((err) => {});
        } else {
            setSearchOptions([]);
        }
    };

    const handleSkillsSelect = (e) => {
        if (selectedSkills.length < 9) {
            let skills = selectedSkills;

            if (skills.filter((obj) => obj.label === e.label).length < 1) {
                skills.push(e);
            }

            setSelectedSkills(skills);
            setPage(1);
            getUpdatedCardsData();
        }
    };

    const handleLocationSelect = (e) => {
        if (location.length < 6) {
            let loc = [...location];

            if (location.find((l) => l.label === 'Worldwide')) {
                loc = [];
            }

            if (loc.filter((obj) => obj.label === e.label).length < 1) {
                loc.push(e);
            }

            setLocation(loc);
            setPage(1);
            // getUpdatedCardsData();
        }
    };

    const jobDetails = (id) => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                setJobData(response);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    const is_suspend = localStorage.getItem('is_suspend') || false;
    const disabledDate = moment(localStorage.getItem('suspended_date')) || moment();
    const month_data = disabledDate.add(1, 'months');
    const disabledBtn = is_suspend === 'true' ? month_data.isAfter(moment()) : false;

    return (
        <div>
            <div>
                <div>
                    <div
                        style={{
                            width: '29.63vw',
                            position: 'relative',
                            borderRight: 'solid 1px #dedede',
                            height: 'auto',
                            float: 'left',
                        }}
                    >
                        <div className="advancedSearchHeading">Advanced Search</div>
                        <div className="fieldOfWorkHeading">Field of work :</div>
                        {/* <div className="fieldOfWorkSelected">App, Software Development & Web Design</div> */}
                        <div className="fieldOfWorkSelected">{userFieldData?.name}</div>

                        <div style={{ width: '100%', marginTop: '67px' }}>
                            <Card
                                style={{
                                    width: '70%',
                                    height: '4.79vw',
                                    margin: 'auto',
                                    marginLeft: 'auto',
                                    borderRadius: '1.041vw',
                                }}
                            >
                                <div
                                    style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                    className="specialityDropdownLabel"
                                >
                                    {'All Speciality'}
                                </div>
                                <div className="inputBaseWrapperStyle">
                                    <AutoCompleteCard
                                        placeholder={''}
                                        // onInputChange={(e) => handleSpecialityInputChange(e)}
                                        options={specialityOptions}
                                        onChange={(e) => {
                                            setSpeciality(e);
                                            handleSpecialityChange(e);
                                        }}
                                        value={speciality}
                                    />
                                </div>
                            </Card>
                        </div>
                        <div style={{ width: '100%', marginTop: '67px' }}>
                            <Card
                                style={{
                                    width: '70%',
                                    height: '4.79vw',
                                    borderRadius: '1.041vw',
                                    margin: 'auto',
                                    marginLeft: 'auto',
                                }}
                            >
                                <div
                                    style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                    className="specialityDropdownLabel"
                                >
                                    {'Skills'}
                                </div>
                                <div className="inputBaseWrapperStyle">
                                    <AutoCompleteCard
                                        placeholder={'Search by Skills'}
                                        options={skillsOptions}
                                        onChange={(e) => {
                                            setSkillSelect(null);
                                            handleSkillsSelect(e);
                                        }}
                                        createSelect
                                        value={skillSelect}
                                    />
                                </div>
                            </Card>
                            <div style={{ width: '70%', margin: 'auto', marginTop: 10 }}>
                                {selectedSkills.map((item, index) => {
                                    return (
                                        <Chip
                                            // height={"1.66vw"}
                                            // fontSize={"0.729vw"}
                                            style={{
                                                width: '31%',
                                                height: 32,
                                                fontSize: 12,
                                                marginTop: 9,
                                            }}
                                            label={item.label}
                                            key={`skill_${item.label}`}
                                            onDelete={() => {
                                                let existingSkills = selectedSkills;
                                                existingSkills.splice(index, 1);
                                                setSelectedSkills(existingSkills);
                                                setPage(1);
                                                getUpdatedCardsData();
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: '67px' }}>
                            <Card
                                style={{
                                    width: '70%',
                                    height: '4.79vw',
                                    margin: 'auto',
                                    marginLeft: 'auto',
                                    borderRadius: '1.041vw',
                                }}
                            >
                                <div
                                    style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                    className="specialityDropdownLabel"
                                >
                                    {'Where'}
                                </div>
                                <div className="inputBaseWrapperStyle">
                                    <AutoCompleteCard
                                        placeholder={'City, Province/State, or Country'}
                                        onInputChange={(e) => handleLocationChange(e)}
                                        options={locationOptions}
                                        onChange={(e) => {
                                            setWhereLocation(null);
                                            handleLocationSelect(e);
                                        }}
                                        value={whereLocation}
                                        loading={isLocationSearchCalled}
                                        // createSelect
                                    />
                                </div>
                            </Card>
                            <div style={{ width: '70%', margin: 'auto', marginTop: 10 }}>
                                {location.map((item, index) => {
                                    return (
                                        <Chip
                                            label={item.label}
                                            style={{
                                                width: '31%',
                                                height: 32,
                                                fontSize: 12,
                                                marginTop: 9,
                                            }}
                                            key={`skill_${item.label}`}
                                            onDelete={() => {
                                                let existingLocation = [...location];
                                                existingLocation.splice(index, 1);
                                                if (existingLocation.length === 0) {
                                                    setLocation([
                                                        {
                                                            label: 'Worldwide',
                                                            value: 'Worldwide',
                                                            _id: '00000000000000',
                                                        },
                                                    ]);
                                                } else {
                                                    setLocation(
                                                        existingLocation || [
                                                            {
                                                                label: 'Worldwide',
                                                                value: 'Worldwide',
                                                                _id: '00000000000000',
                                                            },
                                                        ]
                                                    );
                                                }
                                                // setLocation(existingLocation);
                                                setPage(1);
                                                // getUpdatedCardsData();
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div style={{ width: '100%', marginTop: 32 }}>
                            <div className="maps"></div>
                        </div>
                        <div
                            style={{
                                width: '70%',
                                margin: 'auto',
                                marginTop: 67,
                            }}
                        >
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="worktype"
                                    name="worktype"
                                    value={
                                        remoteWorking === null
                                            ? 'remoteAndNonRemoteWork'
                                            : remoteWorking
                                            ? 'onlyRemoteWork'
                                            : 'onlyNonRemoteWork'
                                    }
                                >
                                    <FormControlLabel
                                        value="remoteAndNonRemoteWork"
                                        control={<Radio style={{ color: '#a4c772' }} />}
                                        label="Show remote and non-remote working jobs"
                                        onChange={(e) => setRemoteWorking(null)}
                                    />
                                    <FormControlLabel
                                        value="onlyRemoteWork"
                                        control={<Radio style={{ color: '#a4c772' }} />}
                                        label="Show only remote working jobs"
                                        onChange={(e) => setRemoteWorking(true)}
                                    />
                                    <FormControlLabel
                                        value="onlyNonRemoteWork"
                                        control={<Radio style={{ color: '#a4c772' }} />}
                                        label=" Show only non-remote working jobs"
                                        onChange={(e) => setRemoteWorking(false)}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                width: '70%',
                                margin: 'auto',
                                marginTop: 67,
                            }}
                        >
                            <CheckboxComponent
                                checked={isJobHourly}
                                onChange={() => {
                                    setPage(1);
                                    setIsJobHourly(!isJobHourly);
                                }}
                                name={'Hourly Jobs'}
                                label={'Hourly Jobs'}
                                color="#a4c772"
                            />
                        </div>
                        {isJobHourly && (
                            <div style={{ width: '100%', marginTop: 33 }}>
                                <div className="hourlyRateSlider">
                                    <Slider
                                        heading={'Hourly Rate'}
                                        value={hourlyRate}
                                        handleChange={handleSliderChange}
                                        rangeDisplay={displayRateRange}
                                        onChangeCommitted={() => {
                                            setPage(1);
                                            getUpdatedCardsData();
                                        }}
                                        min={0}
                                        max={100}
                                        marks={[
                                            {
                                                value: 0,
                                                label: '$0',
                                            },
                                            {
                                                value: 50,
                                                label: '$50',
                                            },
                                            {
                                                value: 100,
                                                label: '$100+',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        )}
                        <div
                            style={{
                                width: '70%',
                                margin: 'auto',
                                marginTop: 67,
                            }}
                        >
                            <CheckboxComponent
                                checked={isJobFixed}
                                onChange={() => {
                                    setPage(1);
                                    setIsJobFixed(!isJobFixed);
                                }}
                                name={'Fixed Fee Jobs'}
                                label={'Fixed Fee Jobs'}
                                color="#a4c772"
                            />
                        </div>
                        {isJobFixed && (
                            <div
                                style={{
                                    width: '70%',
                                    margin: 'auto',
                                    marginTop: 67,
                                    display: 'flex',
                                    marginBottom: 99,
                                }}
                            >
                                <Textfield
                                    width={'100px'}
                                    placeholder="$ Min"
                                    onChange={(e) => setFixedMinAmount(e.target.value)}
                                />
                                <Textfield
                                    width={'100px'}
                                    placeholder="$ Max"
                                    onChange={(e) => setfixedMaxAmount(e.target.value)}
                                />
                                <Button
                                    text="Go"
                                    width={'80px'}
                                    height="54px"
                                    onClick={() => {
                                        setPage(1);
                                        getUpdatedCardsData();
                                    }}
                                    color={['#a4c772', '#4e8400']}
                                    variant="outlined"
                                />
                            </div>
                        )}
                    </div>
                    {/* <WideProfileCard/> */}
                    <div style={{ marginLeft: '29.63vw', position: 'relative' }}>
                        <Card className={cardClasses.root}>
                            <div style={{ display: 'flex' }} className="topProfileSearchBar">
                                <div style={{ display: 'inline-block' }}>
                                    <Card
                                        style={{
                                            width: '32.7vw',
                                            marginLeft: '0px',
                                            marginTop: '0.1vw',
                                            height: '4.79vw',
                                        }}
                                        className={cardClasses.root}
                                    >
                                        <div
                                            style={{ marginLeft: '1.2vw', marginTop: '0.6vw' }}
                                            className="specialityDropdownLabel"
                                        >
                                            {'What are you looking for?'}
                                        </div>
                                        <div className="inputBaseWrapperStyle" key={key}>
                                            <AutoCompleteCard
                                                inputValue={textFilter}
                                                placeholder={'What job are you looking for?'}
                                                onInputChange={(value, action) => {
                                                    if (action.action === 'input-change') {
                                                        setTextFilter(value);
                                                        localStorage.setItem(
                                                            'talentSearchData',
                                                            JSON.stringify(value)
                                                        );
                                                        handleSearchChange(value);
                                                        setSelectedSearchTerm(null);
                                                    }
                                                }}
                                                key={`dropdown`}
                                                options={searchOptions}
                                                value={selectedSearchTerm}
                                                onChange={(e) => {
                                                    setTextFilter(e.label);
                                                    setSelectedSearchTerm(e);
                                                    setKey(Math.random());
                                                    localStorage.setItem(
                                                        'talentSearchData',
                                                        JSON.stringify(e.label)
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Card>
                                </div>
                                <div className="specifyDatesCardsPage">
                                    <div>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                position: 'absolute',
                                            }}
                                        >
                                            <div
                                                style={{ marginTop: '0.7vw', marginLeft: '1.8vw' }}
                                                className="specialityDropdownLabel"
                                            >
                                                Start Date
                                            </div>
                                            <div style={{ marginTop: '0.5vw' }}>
                                                <MultipleDatePicker
                                                    range
                                                    value={selectedDates}
                                                    style={{
                                                        border: '1px solid #f2f2f2',
                                                        width: '10.4vw',
                                                        backgroundColor: '#f2f2f2',
                                                    }}
                                                    onChange={(e) => handleMultipleDateSelection(e)}
                                                    minDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        onClick={() => {
                                            setPage(1);
                                            getUpdatedCardsData();
                                        }}
                                        className="searchIcon"
                                        style={{
                                            display: 'inline-block',
                                            cursor: 'pointer',
                                            marginLeft: '0vw',
                                        }}
                                    >
                                        <SearchIcon fontSize={'inherit'} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div
                            style={{
                                marginLeft: '4.04vw',
                                marginTop: '2.68vw',
                                marginRight: '4.04vw',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    marginBottom: '1vw',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <div className="sortByStyles">Sort by:</div>
                                    <div style={{ width: '13.6vw' }}>
                                        <Dropdown
                                            placeholder={'Sort By'}
                                            options={[
                                                { label: 'Score (High to low)', value: 'scoreHTL' },
                                                {
                                                    label: 'Hourly rate (Hight to low)',
                                                    value: 'hourlyHTL',
                                                },
                                                {
                                                    label: 'Hourly rate (Low to high)',
                                                    value: 'hourlyLTH',
                                                },
                                                {
                                                    label: 'Experience (High to low)',
                                                    value: 'experienceHTL',
                                                },
                                            ]}
                                            height={'3vw'}
                                            value={sortBy}
                                            onChange={(e) => {
                                                setPage(1);
                                                setSortBy(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 1, display: 'flex', marginBottom: 16 }}>
                                <div style={{ flex: 1.8 }}>
                                    <p style={{ fontSize: 20, color: '#707070', fontWeight: 600 }}>
                                        Company Name
                                    </p>
                                </div>
                                <div style={{ flex: 1.5 }}>
                                    <p style={{ fontSize: 20, color: '#707070', fontWeight: 600 }}>
                                        Job Title
                                    </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <p style={{ fontSize: 20, color: '#707070', fontWeight: 600 }}>
                                        Start Date
                                    </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <p style={{ fontSize: 20, color: '#707070', fontWeight: 600 }}>
                                        Fee/Rate
                                    </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <p style={{ fontSize: 20, color: '#707070', fontWeight: 600 }}>
                                        Location
                                    </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <p style={{ fontSize: 20, color: '#707070', fontWeight: 600 }}>
                                        Expiry
                                    </p>
                                </div>
                                <div style={{ flex: 1 }}></div>
                            </div>
                            <div
                                style={{
                                    height: 2,
                                    backgroundColor: '#c4c4c4',
                                    opacity: 0.78,
                                    marginBottom: 20,
                                }}
                            />
                            {isLoading ? (
                                <div style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div>
                                    {cardsData.length > 0 ? (
                                        cardsData.map((item, id) => {
                                            return (
                                                <WideJobCard
                                                    setDrawerOpen={setDrawerOpen}
                                                    jobTitle={item.title}
                                                    companyName={item.profiles.company.name || ''}
                                                    ratings={item.profiles.ratings || 0}
                                                    reviewCount={item.profiles.review_count || 0}
                                                    speciality={item.speciality.name || ''}
                                                    startDate={item.startDate}
                                                    address={
                                                        item.remoteWorking?
                                                        "Remote Working":
                                                        item.workAddress && `${item.workAddress.state}, ${item.workAddress.country}`
                                                    }
                                                    applicationLastDate={item.jobExpiryDate}
                                                    skills={item.skills}
                                                    jobDetails={() => jobDetails(item._id)}
                                                    rate={
                                                        item.jobType === 'HOURLY'
                                                            ? `$${item.rate && item.rate.max}/hr`
                                                            : `$${
                                                                  item.JobValue
                                                                      ? item.JobValue
                                                                      : '00.00'
                                                              }`
                                                    }
                                                    disabledBtn={disabledBtn}
                                                />
                                            );
                                        })
                                    ) : (
                                        <p
                                            style={{
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                color: '#1c1c1c',
                                            }}
                                        >
                                            No record found.
                                        </p>
                                    )}
                                </div>
                            )}
                            {!isLoading && cardsData.length > 0 && (
                                <div className="advancedSearchPagination">
                                    <Pagination
                                        count={Math.ceil(count / 10)}
                                        page={page}
                                        onChange={(e, value) => setPage(value)}
                                        style={{ justifyContent: 'center' }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <JobApplicationApply
                        jobData={jobData.job}
                        jobLoading={jobLoading}
                        employer={{ ...jobData.employerModel, ...jobData.employer }}
                        onClose={() => setDrawerOpen(false)}
                        isPaymentMethodVerified={isPaymentMethodVerified}
                    />
                }
                width="80%"
            />
        </div>
    );
};
export default EmployeeAdvancedSearchTalent;
