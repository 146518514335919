import {LOADING, ERROR,  SUCCESS} from '../action/field_of_work_action';


const initialState = {
    status : "Ideal",
    data : [],
    errorMessage : ""
}

function fieldOfWorkReducer(state = initialState, action){
  switch (action.type) {
    case LOADING:
        return { ...state, status :  "LOADING" }

    case ERROR:
          return { ...state,status :  "ERROR", errorMessage : action.errorMessage}

    case SUCCESS:
            return { ...state,status :   "SUCCESS" ,data : action.data}

    default:
      return state
  }
}
export default fieldOfWorkReducer
