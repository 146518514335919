import React from 'react';
import Swal from 'sweetalert2';
import DataTable from '../../../Components/DataTable/DataTable';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import JobDescription from './Component/JobDescription';
import Loader from './Component/loader';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import CancellationReasonPopup from './Component/CancellationReasonPopup';

const JobPostAndInvitation = (props) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState([]);
    const [jobData, setJobData] = React.useState({});
    const [jobLoading, setJobLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [total, setTotal] = React.useState(1);
    const [sortBy, setSortBy] = React.useState(undefined);
    const [cancelReason, setCancelReason] = React.useState(false);
    const [jobID, setJobID] = React.useState(0);

    React.useEffect(() => {
        getJobPostDetails();
    }, [page, sortBy]);

    const getJobPostDetails = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(
            `${API.GET_JOB_POST_AND_INVITATION}${page}&sort=${sortBy ? sortBy.value : ''}`,
            headers
        )
            .then((response) => {
                setData(response.data);
                setTotal(response.total);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const onPaginationChange = (page) => {
        setPage(page);
    };

    const jobDetails = (id) => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                console.log('response', response);
                setJobData(response.job);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    const deleteJobPost = (obj) => {
        setDrawerOpen(false);
        setDeleteLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.deleteMethod(`${API.DELETE_JOB_POST}${jobID}`, headers, obj)
            .then((response) => {
                setCancelReason(false);
                setDeleteLoading(false);
                getJobPostDetails();
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Success!',
                    text: 'Job deleted successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            })
            .catch((err) => {
                setDeleteLoading(false);
                setJobLoading(false);
            });
    };

    return (
        <>
            <div className="editDetailsContentHeading">Job Posts and Invitations</div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '30px',
                }}
            >
                <p className="SortByDropdown">Sort by:</p>
                <div style={{ width: '170px' }}>
                    <Dropdown
                        placeholder="Select sorting"
                        options={[
                            { label: 'Start Date', value: 'startDate' },
                            { label: 'Job Title', value: 'jobTitle' },
                        ]}
                        value={sortBy}
                        onChange={(e) => {
                            setPage(1);
                            setSortBy(e);
                        }}
                    />
                </div>
            </div>
            <div style={{ fontFamily: 'Libre Franklin' }}>
                <DataTable
                    setDrawerOpen={(id) => {
                        jobDetails(id);
                        setDrawerOpen(true);
                    }}
                    data={data}
                    onPaginationChange={onPaginationChange}
                    loading={loading}
                    total={total}
                    getJobPostDetails={getJobPostDetails}
                />
            </div>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <JobDescription
                        jobData={jobData}
                        cancelReason={cancelReason}
                        jobLoading={jobLoading}
                        deleteJobPost={(id) => {
                            setCancelReason(true);
                            setJobID(id);
                        }}
                        yesButtonClick={(obj) => deleteJobPost(obj)}
                        noButtonClick={() => setCancelReason(false)}
                        isDeleteJobPost
                    />
                }
                width="80%"
            />
            {/*             {cancelReason && (
                <div id="overlay" style={{ zIndex: 999 }}>
                    <div className="overlayImageStyle">
                        <div
                            style={{
                                width: '68.64vw',
                                height: '39vw',
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                            }}
                        >
                            <CancellationReasonPopup
                                
                            />
                        </div>
                    </div>
                </div>
        )} */}
            <Loader loaderState={deleteLoading}></Loader>
        </>
    );
};

export default JobPostAndInvitation;
