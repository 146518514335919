import React from 'react';
import ReactS3 from "react-s3";
import Textfield from '../TextField/Textfield';
import Button from '../Button/Button';
import { config } from '../FileUpload/FileUpload';
import { getFileName } from '../../utils/commonFunction';
import { S3_BUCKET_TYPE } from '../../utils/enums';

const FileUploadForTextField = (props) => {
    const [file, setFile] = React.useState(undefined);
    const handleUpload = () => {
        const fileNameGenerate = getFileName(file.target.files[0].name);

        props.setFileName(file.target.files[0].name);
        // console.log((event.target.files[0]))

        const files = file.target.files[0];

        Object.defineProperty(files, 'name', {
            writable: true,
            value: fileNameGenerate
        });

        ReactS3.uploadFile(files, { ...config, dirName: `${S3_BUCKET_TYPE.PHOTOS}/logo` })
            .then((data) => {
                // console.log(data)
                console.log('data', data);
                props.setFile(data.location);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: "100%", flex: 1 }}>
                <Textfield
                    type="file"
                    width={"90%"}
                    onChange={(event) => setFile(event)}
                />
            </div>
            <div>
                <Button
                    text="Upload"
                    width={"140px"}
                    height="48px"
                    onClick={handleUpload}
                    color={["#2699fb", "#134d7e"]} />
            </div>
        </div>
    )
}

export default FileUploadForTextField;
