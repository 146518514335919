import React, { useEffect } from "react";
import MonthPicker from "react-month-picker";
import "react-month-picker/scss/month-picker.scss";
import TextBox from '../TextField/Textfield';
import PropTypes from 'prop-types'
import moment from 'moment';
MonthYearPicker.propTypes = {
  startMonth: PropTypes.number,
  startYear: PropTypes.number,
  endMonth: PropTypes.number,
  endYear: PropTypes.number,
  fieldLabel: PropTypes.string,
  onSelected: PropTypes.func,
  isReadOnly: PropTypes.bool,
}

export default function MonthYearPicker({ startMonth, startYear, endMonth, endYear, fieldLabel, onSelected, isReadOnly }) {
  const [value, setValue] = React.useState({ year: moment().year(), month: 0 });
  const [maxMonthYear, setMaxMonth] = React.useState({ year: moment().format('YYYY'), month: moment().format('M') });
  const [displayValue, setDisplayValue] = React.useState('MM/YYYY');
  const monthPickerRef = React.useRef(null);

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current && !isReadOnly) {
      monthPickerRef.current.show();
    }
  };

  const hidePicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  useEffect(() => {
    if (startMonth !== undefined && startYear !== undefined) {
      setDisplayValue(`0${startMonth}-${startYear}`);
    }
  }, [startMonth, startYear]);

  useEffect(() => {
    if (endMonth !== undefined && endYear !== undefined) {
      setDisplayValue(`0${endMonth}-${endYear}`);
    }
  }, [endMonth, endYear]);

  const handlePickerChange = (...args) => {
    setValue({ year: args[0], month: args[1] });
    setDisplayValue(`${("0" + args[1]).slice(-2)}-${args[0]}`);
    hidePicker();
    onSelected(`${("0" + args[1]).slice(-2)}-${args[0]}`);
  };

  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    from: "From",
    to: "To"
  };

  return (
    <MonthPicker
      lang={lang.months}
      ref={monthPickerRef}
      value={value}
      years={{ max: { year: maxMonthYear.year, month: maxMonthYear.month } }}
      onChange={handlePickerChange}
    >
      <TextBox
        required
        id="outlined-required"
        label={fieldLabel}
        readOnly={true}
        disabled={isReadOnly}
        value={displayValue}
        onClick={showPicker}
      />
    </MonthPicker>
  );
}
