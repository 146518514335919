import React, { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import { APIHelper } from "../../utils/ApiHelper";
import CheckboxList from "../../Components/CheckboxList/CheckboxList";
import CheckboxComponent from "../../Components/CheckboxComponent/CheckboxComponent";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import API from "../../utils/Urls";
import { connect } from 'react-redux'
import { FIELD_OF_WORK, SPECIALITY_OPTIONS_LIST, TERMS_AND_CONDITION_ACCEPTED } from '../../action/employer_registration_action'
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import { useHistory } from "react-router-dom";

function BusinessInfoPageThree(props) {
  const [fieldsList, setFieldsList] = useState([]);
  const [specialitiesList, setSpecialityList] = useState([]);
  const [field, setField] = useState({});
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [agreedToTermsCondition, setAgreedToTermsCondition] = useState(false);
  const [isField, setIsField] = useState(true);

  const history = useHistory();

  useEffect(() => {
    APIHelper.getMethod(API.GET_FIELDS).then(
      (response) => {
        if (response["status"] === 1) {
          const options = response.data.map(function (row) {
            return {
              value: row.field_name,
              label: row.field_name,
              id: row._id,
            };
          });
          setFieldsList(options);
        }
      }
    );
  }, []);

  const onFieldSelect = (item) => {
    props.handleFieldOfWork(item)
    setIsField(true);
    setField(item);
    // let body = {"field_id":item.id}
    APIHelper.getMethod(
      API.GET_SPECIALITY_BY_FIELDID + `?field_id=${item.id}`
    ).then((response) => {
      if (response["status"] === 1) {
        const options = response.data.map(function (row) {
          return { value: row.name, label: row.name, id: row._id };
        });
        props.handleSpecialityOptionsList(options)
        setSpecialityList(options);
        console.log(options);
      }
    });
  };

  const handleCheckboxList = (checkedItems) => {
    // let wrapper = specialitiesList.map( function (row){
    //     let inner = checkedItems.map( function (item){
    //         if(row.label === item ){
    //             return { value: row.value, label: row.label, id: row.id }
    //         }

    //     })

    //     console.log('inner')
    //     console.log(inner)
    // })

    // let wrapper = specialitiesList.map( function (row){
    //     if(checkedItems.indexOf(row.value) > -1){
    //         if(row.label && row.label != 'undefined'){
    //             console.log(row);
    //             return { name: row.label, id: row.id }
    //         }

    //     }
    // })

    // let wrapper = specialitiesList.map( function (row){
    //     if(checkedItems.indexOf(row.value) > -1){
    //         if(row.label && row.label != 'undefined'){
    //             console.log(row);
    //             return { name: row.label, id: row.id }
    //         }

    //     }
    // })
    var intersection = specialitiesList.filter(function (e) {
      return checkedItems.indexOf(e.value) > -1;
    });
    let selectedValue = intersection.map(function (row) {
      return { name: row.label, id: row.id };
    });

    setSelectedSpecialities(selectedValue);
  };

  const onAgreeAndSignUpClicked = () => {
    if (Object.keys(field).length === 0) {
      setIsField(false);
    } else if (props.isTermsAndConditionAccepted && Object.keys(props.field).length > 0) {
      props.onAgreeAndSignUpClicked(props.field, selectedSpecialities);
    }
  };

  return (
    <>
      <div style={{ width: "100%", height: "1080px", position: "absolute" }}>
        <div
          style={{
            background: "#E2F5FF",
            width: "24.16%",
            height: "100%",
            position: "absolute",
          }}
        >
          <div class="logo">
            <img src={EmpolinkLogo} alt="Empolink" style={{ marginTop: "111px", marginLeft: "156px", cursor: 'pointer' }}
              onClick={() => history.push("/")} />
          </div>
        </div>
        {/* <div onClick={props.handleBackButtonClick} className="backIconButtonStyles"><div><ArrowBackIosOutlinedIcon color="inherit" /></div></div> */}
        <div
          style={{
            float: "right",
            background: "white",
            height: "100%",
            width: "75.84%",
          }}
        >
          <div
            style={{
              marginLeft: "34.27%",
              marginRight: "34.27%",
              height: "100%",
              width: "31.46%",
            }}
          >
            <div>
              <div>
                <h1 className="signUpHeading">Sign Up</h1>
                <div
                  style={{ paddingBottom: "47px" }}
                  className="signUpSubHeading"
                >
                  Business Information
                </div>
              </div>
              <Dropdown
                options={fieldsList}
                onChange={onFieldSelect}
                // focus={handleFieldApiNotCalled}
                value={props.field}
                placeholder={"Field of work *"}
                error={!isField}
              />
              <div className="registerSpecialityHeadingStyles">
                What specialities you usually look for? (Optional)
              </div>
              <CheckboxList
                options={props.specialityOptions}
                onNewSelected={(checked) => handleCheckboxList(checked)}
                color="#2699fb"
              />
              <div
                style={{
                  paddingLeft: "7px",
                  paddingTop: "22px",
                  paddingBottom: "22px",
                }}
              >
                <CheckboxComponent
                  checked={props.isTermsAndConditionAccepted}
                  onChange={() => {
                    props.handleTermsAndCondition(props.isTermsAndConditionAccepted)

                  }}
                  name={"Only show first letter of my last name"}
                  label={
                    <div>
                      I agree with the &nbsp;
                      <a
                        style={{ color: "#9a9a9a" }}
                        target="_blank"
                        href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Terms+of+Service.pdf"
                      >
                        Terms and Conditions
                      </a>
                      &nbsp; and &nbsp;
                      <a
                        style={{ color: "#9a9a9a" }}
                        target="_blank"
                        href="https://forum-empolink.s3.amazonaws.com/Empolink/TOS/Empolink-Privacy+Policy.pdf"
                      >
                        Privacy Policy.
                      </a>
                    </div>
                  }
                  color="#2699fb"
                />
              </div>

              <Button
                text="Agree and join"
                width={"100%"}
                height="56px"
                disabled={!props.isTermsAndConditionAccepted}
                onClick={() => onAgreeAndSignUpClicked()}
                color={["#2699fb", "#134d7e"]}
              // loading={this.props.registerLoading}
              />

              <div style={{ textAlign: "center", paddingTop: "47px" }}>
                <Stepper steps={3} activeStep={2} color={"#1876d2"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleFieldOfWork: (event) => dispatch({ type: FIELD_OF_WORK, field: event }),
    handleSpecialityOptionsList: (event) => dispatch({ type: SPECIALITY_OPTIONS_LIST, specialityOptions: event }),
    handleTermsAndCondition: (event) => dispatch({ type: TERMS_AND_CONDITION_ACCEPTED, isTermsAndConditionAccepted: event }),
  }
}
const mapStateToProps = function (state) {
  return {
    field: state.employerRegisterReducer.field,
    specialityOptions: state.employerRegisterReducer.specialityOptions,
    isTermsAndConditionAccepted: state.employerRegisterReducer.isTermsAndConditionAccepted,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoPageThree)
