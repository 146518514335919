import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import InputText from '../../../components/centralize_input_text'
import DropDownList from '../../../components/centralize_dropdown'
import ToggleButton from '../../../components/centralize_toggle_button'
import DatePicker from '../../../components/centralize_date_picker';
import RangeDatePicker from '../../../components/centralize_range_date_picker';
import TimePicker from '../../../components/centralize_time_picker';
import RadioGroup from '../../../components/custom_radio_group';
import Button from '../../../components/centralize_button';
import DataTable from '../../../components/centralize_data_table';
import Swal from 'sweetalert2'
import Constant from '../../../Constant/constant'

import { NEXT_BUTTON_ENABLE, ADD_WSCHEDULE_HLIST, UPDATE_WSCHEDULE_HLIST, DATE_REFRESH, JOB_POST_CLOSE_DATE, TOTAL_WORKING, DATE_DUPLICATE_SCHEDULE } from '../../../../../action/form_action';

import {
  VERTICAL_NORMAL, VERTICAL_EXTRA,
  VERTICAL_SMALL, VERTICAL_EXTRA_LARGE, HORIZONTAL_NORMAL, HORIZONTAL_EXTRA,
  HORIZONTAL_SMALL, HORIZONTAL_ULTRA_SMALL, SUBHEADING_WITH_BORDER
} from '../../../../../utils/enums';


class WorkScheduleHourly extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      refreshDate: false,
      dates: [],
      startTime: "",
      endTime: "",
      nPayableTime: "0.5",
      showAlert: false,
      isStartDateError: false,
      isEndDateError: false,
      isDatesError:false,
      isStartEndDateError: false,
    }
  }


  addToList = (dates, startTime, endTime, nPayableTime) => {
    if (dates.length !== 0) {
      this.props.addToWScheduleList(dates, startTime, endTime, nPayableTime);
    }

    
  }

  resetValue = () => {
    this.setState({
      dates: [],
      startTime: "",
      endTime: "",
      errors: {},
      isStartDateError: false,
      isEndDateError: false,
      isDatesError:false,
      isStartEndDateError:false,
      isTimeOverlap: false,
      // nPayableTime: "0.5",
    })
  };

  checkOverlap = (start1,end1,start2,end2) =>{
    return ((start1) <=  (end2) && (start2) < (end1) ? true : false);
  }
  componentDidUpdate(prevProps) {
    if(this.props.isWorkingDataDuplicate) {
      Swal.fire({
        customClass: "Alert-Class",
        title: 'Information!',
        text: "Selected date and time is duplicate",
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#1876D2',
      }).then((item) => {
        if(item.isConfirmed || item.isDenied || item.isDismissed) {
          console.log('change the status of state');
          this.props.setWorkingDataDuplicate(false);
        };
      });
    }
    console.log("list",this.props.workScheduleHourlyList);
    if (this.props.workScheduleHourlyList !== prevProps.workScheduleHourlyList) {
      this.props.workScheduleHourlyList.map((item)=>{
        let date = item.date;
        let filterData = this.props.workScheduleHourlyList.filter(a=>a.date === date);
        if(filterData.length >= 2){
          filterData.forEach((element,index) => {
            if(!(filterData.length - 1 === index)){
                let first = filterData[index];
                let second = filterData[index+1];
                console.log("first",first);
                console.log("second",second);
                if(this.checkOverlap(first.start, first.end, second.start, second.end)){
                  this.setState({isTimeOverlap: true});
                }else{
                  this.setState({isTimeOverlap: false});
                }
              }
            });
        }else{
          this.setState({isTimeOverlap: false});
        }
        });
      if(this.props.workScheduleHourlyList.length === 0){
          this.setState({isTimeOverlap: false});
      }
    }
  }


  render() {
    const { workScheduleHourlyList, refreshData, jobPostCloseDateFormat, error } = this.props;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
      <div >

        <Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_500} text="Provide Schedule of Work" />

        <Spacer spaceType={VERTICAL_SMALL} />

        <div className="Row-Parent-Start">

          <RangeDatePicker
            startDate={new Date()}
            refreshData={refreshData}
            onChangeCallback={dates => {
              console.log(dates);
              this.setState({
                dates: dates,
                isDatesError: false,
              })
            }}
            refreshCallback={() => {
              this.props.refreshDate();
            }}
            minDate={new Date(moment().add(3, "d").toLocaleString())}
            maxDate= {new Date(moment().add(3, "months").toISOString())}
            error={this.state.isDatesError}
          />


          <div className="Row-Time-Parent">
            <div className="Row-Parent-Time-Full">

              <Spacer spaceType={HORIZONTAL_ULTRA_SMALL} />

              <TimePicker id={"startTime"} label={"Start Time"}
                widthParent={"60%"}
                width={"80%"}
                error={this.state.isStartDateError}
                fontSize={Constant.FONT_SIZE_WEB}
                fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                refreshData={refreshData}
                onChangeCallback={(time, timeObject, time24hr) => {
                  this.setState({
                    startTime: time24hr,
                    isStartDateError: false,
                  })
                }} />


              <TimePicker id={"endTime"} label={"End Time"}
                widthParent={"60%"}
                width={"80%"}
                error={this.state.isEndDateError}
                fontSize={Constant.FONT_SIZE_WEB}
                fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                refreshData={refreshData}
                onChangeCallback={(time, timeObject, time24hr) => {
                  this.setState({
                    endTime: time24hr,
                    isEndDateError: false,
                  })
                }} />


            </div>
            {this.state.isStartEndDateError ? <div style={{marginLeft:50}} className="errorMsg">Start time should not be greater than end time.</div> : null }
            <Spacer spaceType={VERTICAL_EXTRA} />

            <div className="Row-Parent-Time-Full">

              <Spacer spaceType={HORIZONTAL_ULTRA_SMALL} />

              <Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_500} text="Non-Payable Time" />
            </div>

            <Spacer spaceType={VERTICAL_SMALL} />

            <div className="Row-Parent-Time-Full">

              <Spacer spaceType={HORIZONTAL_ULTRA_SMALL} />

              <RadioGroup
                fontSize={Constant.FONT_SIZE_WEB}
                color={Constant.COLOR_BLACK_300}
                cursor={"pointer"}
                lable1={"1/2 hours"}
                id1={"0.5"}
                lable2={"1 hour"}
                id2={"1"}
                lable3={"None"}
                id3={"0"}
                onChangeCallback={value => {
                  this.setState({
                    nPayableTime: value
                  })
                }} />
            </div>

            <Spacer spaceType={VERTICAL_SMALL} />

            <div className="Row-Parent-Time-Full">

              <Spacer spaceType={HORIZONTAL_ULTRA_SMALL} />

              <Button
                lable={"Add to scheduled"}
                width="300px"
                fontSize={Constant.BUTTON_FONT_SIZE_WEB}
                onClickCallback={value => {
                  if (this.state.dates.length > 0 && this.state.startTime !== "" && this.state.endTime !== "" && (this.state.startTime.split(':')[0] < this.state.endTime.split(':')[0])) {
                    this.addToList(this.state.dates, this.state.startTime, this.state.endTime, this.state.nPayableTime);
                    this.resetValue();
                  } else {
                    if (this.state.endTime === ""){ 
                      this.setState({isEndDateError: true});
                    }else{
                      this.setState({isEndDateError: false});
                    }
                    if (this.state.startTime === ""){ 
                      this.setState({isStartDateError: true});
                    }else{
                      this.setState({isStartDateError: false});
                    }
                    if(this.state.startTime !== "" && this.state.endTime !== "" && (this.state.startTime.split(':')[0] > this.state.endTime.split(':')[0])){
                      this.setState({isStartEndDateError: true});
                    }else{
                      this.setState({isStartEndDateError: false});
                    }
                    if(this.state.dates.length <= 0){ 
                      this.setState({isDatesError: true});
                    }else{
                      this.setState({isDatesError: false});
                    }
                  }
                }}
              />
            </div>

          </div>
        </div>

        <Spacer spaceType={VERTICAL_EXTRA} />

        <DataTable
          width={"75%"}
          height={"320px"}
          fontSize={Constant.FONT_SIZE_WEB}
          color={Constant.COLOR_BLACK_500}
          fontSizeTotal={Constant.FONT_SIZE_WEB}
          colorTotal={Constant.COLOR_BLACK_500}
          borderTotal={"1px solid  #B6B7B6"}
          paddingTotal={"10px 15px 10px 15px"}
          borderRadiusTotal={"10px"}
          rowsData={workScheduleHourlyList}
          updateListCallback={(list) => {
            this.props.updateWScheduleList(list);
          }}
          setTotalWorking={(value) => this.props.setTotalWorking(value)}
        />
        {this.state.isTimeOverlap ? <p className="errorMsg">There is a conflict but still job can be posted.</p> : null}
        <Spacer spaceType={VERTICAL_EXTRA} />

        <Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_500} text="Specify when job will expire: " />
        <Spacer spaceType={VERTICAL_SMALL} />
        <DatePicker
          fontSize={Constant.FONT_SIZE_WEB}
          width="60%"
          error={error.jobPostCloseDateFormat || false}
          lable={jobPostCloseDateFormat === undefined ? "Select Job Expiry Date" : "Job Expiry Date"}
          selectedDate={jobPostCloseDateFormat}
          startDate={tomorrow}
          endDate={workScheduleHourlyList && workScheduleHourlyList.length > 0 && new Date(new Date(workScheduleHourlyList[0].date) - 1).toISOString()}
          onChangeCallback={date => {
            this.props.jobPostCloseDate(date);
          }}
        />
      </div>
    );
  }
}


const mapStateToProps = function (state) {
  return {
    refreshData: state.formReducer.refreshData,
    selectionValue: state.formReducer.selectionValue,
    workScheduleHourlyList: state.formReducer.workScheduleHourlyList,
    jobPostCloseDateFormat: state.formReducer.jobPostCloseDateFormat,
    error: state.formReducer.error || {},
    isWorkingDataDuplicate: state.formReducer.isWorkingDataDuplicate,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToWScheduleList: (dates, startTime, endTime, nPayableTime) => dispatch({
      type: ADD_WSCHEDULE_HLIST, dates: dates, startTime: startTime,
      endTime: endTime, nPayableTime: nPayableTime
    }),
    updateWScheduleList: (list) => dispatch({ type: UPDATE_WSCHEDULE_HLIST, workScheduleHourlyList: list }),
    refreshDate: () => dispatch({ type: DATE_REFRESH }),
    jobPostCloseDate: (value) => dispatch({ type: JOB_POST_CLOSE_DATE, jobPostCloseDate: value }),
    setTotalWorking: (value) => dispatch({ type: TOTAL_WORKING, totalWorking: value }),
    setWorkingDataDuplicate: (value) => dispatch({ type: DATE_DUPLICATE_SCHEDULE, isWorkingDataDuplicate: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkScheduleHourly);
