import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export default function CustomizableChip(props) {

  const useStyles = makeStyles((theme) => ({
    chip: {
      height: props.height,
      fontSize: props.fontSize,
      backgroundColor: props.backgroundColor,
      borderRadius: props.borderRadius,
      color: props.color,
      '&:active': {
        background: props.backgroundColor,
      },
      marginRight: 5,
    },
  }));

  const classes = useStyles();
  return (
    <Chip
      label={props.label}
      onDelete={props.onDelete}
      className={classes.chip}
      deleteIcon={props.deleteIcon}
      style={props.style}
    />
  );
}