import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useEffect, useState } from 'react';
import Text from './text'
import CircularProgress from '@material-ui/core/CircularProgress';


export default function Loader(props) {
    const [showLoader, setShowLoader] = React.useState(false);

    useEffect(() => {
        if(props.loaderState !== undefined){
          setShowLoader(props.loaderState);
        }
      }, [props.loaderState]);


    return (
      <div>
      {showLoader ?
        <div className = "Loader">
          <div> <CircularProgress />   <div className = "Loader-Text">{props.loaderText ?? "Posting Job"}</div> </div>
        </div>
        :
        <div></div>
      }
    </div>
    );
}
