import React from 'react';
import { connect } from 'react-redux';
import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import InputText from '../../../components/centralize_input_text'
import DropDownList from '../../../components/centralize_dropdown'
import ToggleButton from '../../../components/centralize_toggle_button'
import SearchableChips from '../../../components/centralize_searchable_chips'
import Constant from '../../../Constant/constant'

import {LOCATION_LIST, REMOVE_LOCATION,ADDRESS, ADDRESS_TYPE } from '../../../../../action/form_action';

import {
  VERTICAL_NORMAL, VERTICAL_EXTRA,
  VERTICAL_SMALL, VERTICAL_EXTRA_LARGE, HORIZONTAL_NORMAL, HORIZONTAL_EXTRA
} from '../../../../../utils/enums';

import { getLocation } from '../../../../../api/get_location';

class Location extends React.Component {

  constructor(props) {
    super(props);

    if(this.props.addressType === "Same as my profile adddress"){
      this.props.address(this.props.userProfileAddress);
    }
  }

  render() {
    const { statusLocation, dataLocation,
      errorMessageLocation, locationList,
      addressValue, userProfileAddress,addressType, error
    } = this.props;


    return (
      <div >

        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text="Specify loations where your job posting would be shared with:" />
        <Spacer spaceType={VERTICAL_SMALL} />


          <SearchableChips   customChipsClassName="Centralize-Search-Chips-Parent-Grey"
            fontSize = {Constant.FONT_SIZE_MOBILE}
            width = "80%"
            fontSizeDropDown = {Constant.FONT_SIZE_DROPDOWN_MOBILE}
            menuWidthDropDown = "40%"
              selectedList={locationList}
                searchList=
                  {(() => {
                    switch (statusLocation) {
                      case "LOADING":
                      return  []
                        break;
                      case "ERROR":
                      return  []
                        break;
                      case "SUCCESS":
                      return   dataLocation
                        break;
                      default:
                      return []
                      }
                    })()}
                checkOnText = {true}
                id={"locationSearch"}
                label=
                  {(() => {
                    switch (statusLocation) {
                      case "LOADING":
                      return  "Loading ..."
                        break;
                      case "ERROR":
                      return  errorMessageLocation
                        break;
                      case "SUCCESS":
                      return "City, Provience/State or Country"
                        break;
                      default:
                      return "City, Provience/State or Country"
                      }
                    })()}
                onSelectedValueCallback={listSelected => {
                  this.props.addLocation(listSelected);
                }}
                onRemoveValueCallback={removeObject => {
                  this.props.removeALocation(removeObject);
                }}
                onSearchCallback={(location) => {
                    if(location.length > 2){
                      this.props.getLocation(location);
                    }
                }} />

        <Spacer spaceType={VERTICAL_EXTRA} />

        <Text fontSize = {Constant.FONT_SIZE_MOBILE} color = {Constant.COLOR_BLACK_500} text="Work Address" />
        <Spacer spaceType={VERTICAL_SMALL} />

        <DropDownList
          fontSize = {Constant.FONT_SIZE_MOBILE}
          width = "80%"
          menuWidth = "40%"
          fontSizeDropDown = {Constant.FONT_SIZE_DROPDOWN_MOBILE}
          dropDownList={[{ lable: 'Same as my profile adddress' }, { lable: 'Remote working' }, { lable: "Enter new address" }]} label={"Same as profile address"}
          text={addressType}
          onSelectedValueCallback={(selectedValue, data) => {
            this.props.selectAddress(selectedValue);
            if(selectedValue === "Same as my profile adddress"){
              this.props.address(userProfileAddress);
            }else{
              this.props.address("");
            }
          }} />
        <Spacer spaceType={VERTICAL_EXTRA} />


        <InputText
          id="workAddress" 
          label="Work Address"
          fontSize = {Constant.FONT_SIZE_MOBILE}
          width = "80%"
          isMutiline={true}
          row={13}
          text={addressValue}
          disabled = {
          addressType === 'Same as my profile adddress'
          ? (addressValue === "" ? false : true) : false
          }
          onChangeCallback={value => {
            this.props.address(value);
          }}
        />

      </div>
    );
  }
}


const mapStateToProps = function (state) {
  return {
    statusLocation: state.locationReducer.status,
    dataLocation : state.locationReducer.data,
    errorMessageLocation: state.locationReducer.errorMessage,
    locationList : state.formReducer.locationList,
    addressValue : state.formReducer.address,
    addressType : state.formReducer.addressType,
    userProfileAddress : state.profileReducer.data,
    error: state.formReducer.error || {}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLocation: (value) => { dispatch(getLocation(value)); },
    addLocation: (value) => dispatch({ type: LOCATION_LIST, locationList: value }),
    removeALocation: (value) => dispatch({ type: REMOVE_LOCATION, locationList: value }),
    selectAddress: (value) => dispatch({ type: ADDRESS_TYPE, addressType: value }),
    address: (value) => dispatch({ type: ADDRESS, address: value }),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Location);
