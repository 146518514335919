import React from 'react';
import { useEffect, useState } from 'react';
import { Calendar } from "react-multi-date-picker";
import { makeStyles } from "@material-ui/core/styles";


export default function CentralizeRangeDatePicker(props) {
  const [dates, setDates] = useState([])

  const useStyles = makeStyles((theme) => ({
    error: {
      border:'2px solid red',
      borderRadius: '23px'
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    if(props.refreshData){
      setDates([]);
      if(props.refreshCallback !== undefined){
        props.refreshCallback();
      }
    }
  }, [props.refreshData]);

  return (
    <div className= {`${dates.length === 1 ? "React-DatePicker-Single" : "React-DatePicker"} ${props.error ? `${classes.error}` : ``}`}  >
        <Calendar
        value={dates}
        weekStartDayIndex={1}
        minDate={props.minDate}
        maxDate={props.maxDate}
        onChange={dateObjects => {
          setDates(dateObjects)
          if (props.onChangeCallback !== undefined) {
            props.onChangeCallback(dateObjects);
          }
        }}
        mapDays={({ date, today, selectedDate, currentMonth, isSameDate }) => {
          let props = {}
          props.style = {}

          let isSunday = [6].includes(date.weekDay.index)
          if (isSunday) props.style.color = "#A7B9F0"

          let isSaturday = [0].includes(date.weekDay.index)
          if (isSaturday) props.style.color = "#FF6464"



          if (isSameDate(date, today)) props.style.color = "#455997"
          if (isSameDate(date, today)) props.style.background = "rgb(255 255 255)"
          if (isSameDate(date, today)) props.style.border = "1px solid #9A9A9A"

            // if (isSameDate(date, selectedDate[0])) props.style.color = "#FFFFFF"
            // if (isSameDate(date, selectedDate[0])) props.style.background = "#488DFF"

            // if (isSameDate(date, selectedDate[selectedDate.length -1])) props.style.color = "#FFFFFF"
            // if (isSameDate(date, selectedDate[selectedDate.length -1])) props.style.background = "#488DFF"

          return props
        }}

      />
    </div>

  );
}
