import React, { Component } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import TextField from "../../Components/TextField/Textfield";
import "./SingleEmployeeInviteDetails.scss";
import CONSTANTS from "../../utils/constants";
import Button from "../../Components/Button/Button";
import SwitchButton from "../../Components/SwitchButton/SwitchButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Checkbox from "../../Components/CheckboxComponent/CheckboxComponent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import API from "../../utils/Urls";
import { APIHelper } from "../../utils/ApiHelper";
import defaultDp from "../../Assets/blankProfile.png";
import RangeDatePicker from "../../Pages/JobPostPages/components/centralize_range_date_picker";
import Swal from "sweetalert2";
import { getTimeDifferent, getTotalWorkingHours, timeToDecimal, intervalTime } from "../../utils/commonFunction";
import { connect } from "react-redux";
import { getProfileData } from "../../api/profile";
import Attachment from "../../Pages/JobPostPages/components/attachment";
import ReactS3 from "react-s3";
import Constant from "../JobPostPages/Constant/constant";
import TimePicker from "../JobPostPages/components/centralize_time_picker";
import { config } from "../../Components/FileUpload/FileUpload";
import { INVITATION_TYPE, S3_BUCKET_TYPE } from "../../utils/enums";
import { capitalizeFirstLetter } from '../../utils/commonFunction';

const addressTypeLocal = [
  { label: "Same as my profile address", value: "Same as my profile address" },
  { label: "Remote working", value: "Remote working" },
  { label: "Enter new address", value: "Enter new address" },
];

const addressTypeInt = [{ label: "Remote working", value: "Remote working" }];

class SingleEmployeeInviteDetails extends Component {
  constructor(props) {
    super(props);
    this.props.getProfileData();
    this.state = {
      page: 0,
      isEmpolinkPaymentMethodSelected: false,
      startTime: "",
      endTime: "",
      jobDescription: "",
      isWeeklyPaymentSelected: true,
      totalHours: 0,
      nonPayableTime: "1 hour",
      isValidDate: true,
      isValidStartTime: true,
      isValidEndTime: true,
      isValidDifference: true,
      isValidExpiryDate: true,
      isValidAddressType: true,
      isValidCountry: true,
      isValidState: true,
      isValidCity: true,
      isValidStreetAddress: true,
      isValidZipCode: true,
      selectedDate: moment().toDate(),
      currentScheduleData: {
        date: "",
        startTime: "",
        endTime: "",
        nonPayable: 1,
        working: 0,
      },
      tableData: [],
      jobPostOptions: [],
      selectedJobPost: {},
      filesUploaded: [],
      selectedAddressType: undefined,
      isAddressFieldDisabled: true,
      isCountryFieldDisabled: true,
      isStateFieldDisabled: true,
      isCityFieldDisabled: true,
      additionalInstructions: "",
      expiryDate: "",
      jobPostExists: true,
      jobInviteMessage: "",
      isMultpleInviteLoading: false,
      isSingleInviteLoading: false,
      jobTitle: "",
      isJobSelected: true,
      isJobTitleEntered: true,
      isWorkAddressTypeSelected: true,
      refreshData: false,
      selectedDatesArr: [],
      fileUploaded: true,
      attachment: [],
      selected: [],
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      address: {
        city: undefined,
        country: undefined,
        state: undefined,
        unit_number: "",
        postal_code: "",
        street_address: "",
      },
      myAddress: {},
      isOutOfCountry: false,
      isTimeOverlap: false,
      noSchedule: false,
    };
  }

  componentDidMount() {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    let userId = localStorage.getItem("userId");
    APIHelper.getMethod(API.GET_ACTIVE_JOB_POST + userId, headers)
      .then((response) => {
        let jobs = response.map((item) => {
          return {
            label: item.title,
            value: item.title,
            _id: item._id,
            jobLocationType: item.jobLocationType,
          };
        });
        let newArray = [
          {
            label: "New Invitation",
            value: "New Invitation",
            jobLocationType: "INTERNATIONAL",
          },
        ].concat(jobs);
        this.setState({ jobPostOptions: newArray, selectedJobPost: newArray[0] });
      })
      .catch((err) => { });

    APIHelper.getMethod(API.GET_EMPLOYEES_ACTIVE_USER, headers).then((response) => {
      const { employee } = response;

      this.setState({
        myAddress: employee.address && employee.address[0],
      });
    });
    this.getCountryList();

    const countries = this.props.profileData.address[0].country;
    const employerCountry = localStorage.getItem("country");
    const isOutCountry = countries === employerCountry && employerCountry.toLowerCase();

    this.setState({
      isOutOfCountry: !isOutCountry,
      isEmpolinkPaymentMethodSelected: true,
    });
  }

  checkOverlap = (start1, end1, start2, end2) => {
    return start1 <= end2 && start2 < end1 ? true : false;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableData !== this.state.tableData) {
      this.state.tableData.map((item) => {
        let date = moment(item.date).toISOString();
        let filterData = [];
        this.state.tableData.forEach((element) => {
          let tempDate = moment(element.date).toISOString();
          if (tempDate === date) {
            filterData.push(element);
          }
        });
        if (filterData.length >= 2) {
          filterData.forEach((element, index) => {
            if (!(filterData.length - 1 === index)) {
              let first = filterData[index];
              let second = filterData[index + 1];
              if (this.checkOverlap(first.startTime, first.endTime, second.startTime, second.endTime)) {
                this.setState({ isTimeOverlap: true });
              } else {
                this.setState({ isTimeOverlap: false });
              }
            }
          });
        } else {
          this.setState({ isTimeOverlap: false });
        }
      });
      if (this.state.tableData.length === 0) {
        this.setState({ isTimeOverlap: false });
      }
    }
  }
  getCountryList = () => {
    APIHelper.getMethod(API.GET_COUNTRY).then((response) => {
      if (response["status"] === 1) {
        const countries = response.data.map(function (row) {
          return {
            value: row.name,
            label: row.name,
            id: row._id,
            Id: row.country_id,
          };
        });
        this.setState({
          countryOptions: countries,
        });
      }
    });
  };

  changeCountry = (id) => {
    APIHelper.getMethod(API.GET_STATES + `?country_id=${id}`).then((response) => {
      if (response["status"] === 1) {
        const states = response.data.map((state) => ({
          ...state,
          label: state.name,
          value: state.name,
        }));
        this.setState({
          stateOptions: states,
        });
      }
    });
  };

  changeState = (id) => {
    APIHelper.getMethod(API.GET_CITIES + `?state_id=${id}`).then((response) => {
      if (response["status"] === 1) {
        const cities = response.data.map((city) => ({
          ...city,
          label: city.name,
          value: city.name,
        }));
        this.setState({
          cityOptions: cities,
        });
      }
    });
  };

  onEmpolinkTypeClicked = () => {
    if (!this.state.isEmpolinkPaymentMethodSelected) {
      this.setState({
        isEmpolinkPaymentMethodSelected: !this.state.isEmpolinkPaymentMethodSelected,
      });
    }
  };

  handleDateChange = (dates) => {
    let momentDate = [];
    if (dates.length > 0 && dates !== undefined) {
      dates.map((item) => {
        momentDate.push(moment(new Date(`${item.month.number}-${item.day}-${item.year}`)).format("MM-DD-YYYY"));
      });
      this.setState({ selectedDatesArr: momentDate, refreshData: false, isValidDate: true });
    } else {
      this.setState({ isValidDate: false });
    }
  };

  onDirectPaymentClicked = () => {
    if (this.state.isEmpolinkPaymentMethodSelected) {
      this.setState({
        isEmpolinkPaymentMethodSelected: !this.state.isEmpolinkPaymentMethodSelected,
      });
    }
  };

  onWeeklyTypeClicked = () => {
    if (!this.state.isWeeklyPaymentSelected) {
      this.setState({ isWeeklyPaymentSelected: !this.state.isWeeklyPaymentSelected });
    }
  };

  onBiWeeklyTypeClicked = () => {
    if (this.state.isWeeklyPaymentSelected) {
      this.setState({ isWeeklyPaymentSelected: !this.state.isWeeklyPaymentSelected });
    }
  };

  handleStartTimeChange = (time) => {
    let dateTest = moment(time, "HH:mm");
    let currentScheduleData = this.state.currentScheduleData;
    currentScheduleData["startTime"] = moment(time, "HH:mm")._i;
    this.setState({
      startTime: moment(time, "HH:mm"),
      isValidStartTime: true,
      currentScheduleData: currentScheduleData,
    });
  };

  handleEndTimeChange = (time) => {
    let dateTest = moment(time, "HH:mm");

    let currentScheduleData = this.state.currentScheduleData;
    let hours = moment(time, "HH:mm").diff(this.state.startTime, "hours", true).toFixed(2);
    currentScheduleData["endTime"] = moment(time, "HH:mm")._i;
    currentScheduleData["working"] = parseInt(hours);
    this.setState({
      endTime: moment(time, "HH:mm"),
      isValidEndTime: true,
      currentScheduleData: currentScheduleData,
      working: hours,
    });
  };

  handleSingleInvite = async () => {
    if (
      this.state.expiryDate !== "" &&
      !(moment(this.state.tableData[0].date) <= moment(this.state.expiryDate)) &&
      this.state.selectedAddressType &&
      this.state.address.country &&
      this.state.address.state &&
      this.state.address.city &&
      this.state.address.street_address &&
      this.state.address.postal_code
    ) {
      this.setState({ isSingleInviteLoading: true });
      let attachment = [];
      let attachmentURL = this.state.filesUploaded;

      // if (attachmentURL.length > 0) {
      //   this.setState({ fileUploaded: false });
      //   let arrPromises = [];
      //   for (var i = 0; i < attachmentURL.length; i++) {
      //     const fileName = attachmentURL[i].name;
      //     const configs = { ...config, dirName: S3_BUCKET_TYPE.PHOTOS };
      //     arrPromises.push(
      //       await ReactS3.uploadFile(attachmentURL[i], configs)
      //         .then((data) => {
      //           attachment = [...attachment, { name: fileName, url: data.location }];
      //           //return { "name": attachmentURL[i].name, "url": data.location }
      //           //attachment.push({ "url": data.location });
      //           //this.setState({ fileUploaded: true })
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         })
      //     );
      //   }
      //   if (arrPromises) {
      //     await Promise.all(arrPromises).then((res) => {
      //       //call the
      //       attachment.push(res);
      //     });
      //   }
      // }

      if (attachmentURL.length > 0) {
        this.setState({ fileUploaded: false });
        var bodyFormData = new FormData();
        for (var i = 0; i < attachmentURL.length; i++) {
          bodyFormData.append('files', attachmentURL[i], attachmentURL[i].name);
        }
        bodyFormData.append('userId', localStorage.getItem("userId"));
        bodyFormData.append('type', 'invitation');
        let headersFile = {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        const response = await APIHelper.postMethod(API.JOB_UPLOAD_FILES, bodyFormData, headersFile);
        for (var i = 0; i < response.data.length; i++) {
          attachment.push({ name: response.data[i] });
        }
      }

      let schedule = this.state.tableData.map((item) => {
        return {
          dateSchedule: item.date ? moment(item.date).format("YYYY-MM-DD") : "",
          startTime: {
            hours: moment(item.startTime).hours(),
            minutes: moment(item.startTime).minutes(),
          },
          endTime: {
            hours: moment(item.endTime).hours(),
            minutes: moment(item.endTime).minutes(),
          },
          nonPayableTime: intervalTime(item.nonPayable),
          totalHours: timeToDecimal(item.working),
        };
      });
      var fieldID = JSON.parse(localStorage.getItem("fieldData")).id;
      var title = this.state.selectedJobPost.label;

      let body = {
        title: this.state.jobTitle,
        field: JSON.parse(localStorage.getItem("fieldData"))[0].id,
        speciality: this.props.selectedSpeciality._id
          ? this.props.selectedSpeciality._id === "0000000000000"
            ? []
            : this.props.selectedSpeciality._id
          : [],
        skills: this.props.selectedSkills.map((item) => {
          return item._id;
        }),
        description: this.state.jobDescription,
        jobType: "HOURLY",
        rate: { min: 0, max: 0 },
        JobValue: 0,
        experience: {
          min: this.props.experience[0],
          max: this.props.experience[1],
        },
        studentCoop: false,
        attachments: attachment,
        hourlySchedule: schedule,
        listOfInvitees: [this.props.fromFav ? this.props.profileData.userid._id : this.props.profileData.userid],
        hourlyTotalHours: timeToDecimal(this.state.totalHours),
        additionalDetails: this.state.additionalInstructions,
        workAddress: this.state.address,
        jobExpiryDate: this.state.expiryDate,
        paymentMethod: this.state.isEmpolinkPaymentMethodSelected ? "EMPOLINK" : "DIRECT",
        paymentFrequency: this.state.isWeeklyPaymentSelected ? "WEEKLY" : "BIWEEKLY",
        user: localStorage.getItem("userId"),
        jobPostType: INVITATION_TYPE.JOB_INVITATION,
        jobLocationType: this.state.isOutOfCountry ? "INTERNATIONAL" : "LOCAL",
        remoteWorking: this.state.selectedAddressType ? this.state.selectedAddressType.value === "Remote working" : false,
        currency: !this.state.isOutOfCountry ? localStorage.getItem("localCurrency") : "USD",
      };

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      APIHelper.postMethod(API.JOB_INVITE_POST, body, headers)
        .then((response) => {
          this.setState({ isSingleInviteLoading: false });
          Swal.fire({
            customClass: "Alert-Class",
            title: "Information!",
            text: "Profiles invited successfully",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          }).then((result) => {
            if (result.isConfirmed) {
              this.props.onCloseClick();
            }
          });
        })
        .catch((err) => {
          this.setState({ isSingleInviteLoading: false });
          Swal.fire({
            customClass: "Alert-Class",
            title: "Information!",
            text: "Failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        });
    } else {
      if (this.state.expiryDate === "") {
        this.setState({ isValidExpiryDate: false });
      }
      if (!this.state.selectedAddressType) {
        this.setState({ isValidAddressType: false });
      }
      if (!this.state.address.country) {
        this.setState({ isValidCountry: false });
      }
      if (!this.state.address.state) {
        this.setState({ isValidState: false });
      }
      if (!this.state.address.city) {
        this.setState({ isValidCity: false });
      }
      if (!this.state.address.street_address) {
        this.setState({ isValidStreetAddress: false });
      }
      if (!this.state.address.postal_code) {
        this.setState({ isValidZipCode: false });
      }
    }
  };

  resetValue = () => {
    this.setState({
      selectedDatesArr: [],
      refreshData: true,
    });
  };

  addToScheduleHandler = () => {
    let isValid = true;
    this.state.selectedDatesArr.forEach((date) => {
      const data = this.state.tableData.find(
        (tblData) =>
          moment(tblData.date).utc(0).format("DD/MM/YYYY") === moment(date).utc(0).format("DD/MM/YYYY") &&
          moment(tblData.startTime).format("h:mm A") === moment(this.state.startTime).format("h:mm A") &&
          moment(tblData.endTime).format("h:mm A") === moment(this.state.endTime).format("h:mm A")
      );
      if (data) {
        isValid = false;
      }
    });

    if (
      this.state.startTime !== "" &&
      this.state.endTime !== "" &&
      isValid &&
      this.state.nonPayableTime &&
      this.state.selectedDatesArr.length > 0 &&
      moment(this.state.startTime) < moment(this.state.endTime)
    ) {
      let tableData = this.state.tableData;
      let newSchedule = this.state.selectedDatesArr.map((item) => {
        return {
          date: moment(item, "MM-DD-YYYY").format("YYYY-MM-DD"),
          startTime: this.state.startTime,
          endTime: this.state.endTime,
          nonPayable: this.state.nonPayableTime,
          working: getTimeDifferent(this.state.startTime, this.state.endTime, intervalTime(this.state.nonPayableTime)),
        };
      });

      const updatedSchedule = tableData.concat(newSchedule);

      const times = updatedSchedule.map((update) => update.working);
      const NonpayableTime = updatedSchedule.map((update) => update.nonPayable);
      let totalNonPayMin = 0;
      NonpayableTime.forEach((non) => {
        if (non === "1/2 hour") {
          totalNonPayMin += 30;
        } else if (non === "1 hour") {
          totalNonPayMin += 60;
        }
      });
      const totalTime = getTotalWorkingHours(times, totalNonPayMin * 60);
      // totalHours
      const newtable = updatedSchedule.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      this.setState({
        tableData: newtable,
        totalHours: totalTime,
        isValidDifference: true,
        noSchedule: false,
      });
      this.resetValue();
    } else {
      if (this.state.selectedDatesArr.length === 0) {
        this.setState({ isValidDate: false });
      }
      if (this.state.startTime === "") {
        this.setState({ isValidStartTime: false });
      }
      if (this.state.endTime === "") {
        this.setState({ isValidEndTime: false });
      }
      if (moment(this.state.startTime) > moment(this.state.endTime)) {
        this.setState({ isValidDifference: false });
      }
    }
  };

  onBackButtonClick = () => {
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 });
    }
  };

  handleJobDescription = (e) => {
    this.setState({ jobDescription: e.target.value });
  };

  handleNonPayableTime = (e) => {
    let currentScheduleData = this.state.currentScheduleData;

    if (e.target.value === "1 hour") {
      currentScheduleData["nonPayable"] = 1;
    } else if (e.target.value === "1/2 hour") {
      currentScheduleData["nonPayable"] = 0.5;
    } else if (e.target.value === "none") {
      currentScheduleData["nonPayable"] = 0;
    }

    this.setState({
      nonPayableTime: e.target.value,
      currentScheduleData: currentScheduleData,
    });
  };

  handleFileUploadChange = (e) => {
    let uploadedFiles = this.state.filesUploaded;
    uploadedFiles.push(...e);
    this.setState({ filesUploaded: uploadedFiles });

    // let files = this.state.filesUploaded
    // files.push({ name: name, url: e })
    // this.setState({ filesUploaded: files })
  };

  handleRemoveSelectedFile = (e) => {
    let uploadedFiles = this.state.filesUploaded;

    let filteredArr = uploadedFiles.filter((item) => item.name != e.name);
    this.setState({ filesUploaded: filteredArr });
  };

  onCheckBoxClicked = (id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    this.setState({
      selected: newSelected,
    });
  };

  isSelected = (id) => this.state.selected && this.state.selected.indexOf(id) > -1;

  handleDelete = () => {
    const { tableData, selected } = this.state;
    var temp = tableData.filter((item, index) => !selected.find((itemToDelete) => itemToDelete === index));

    if (selected.indexOf(0) > -1) {
      temp.splice(0, 1);
    }

    const times = temp.map((update) => update.working);
    const NonpayableTime = temp.map((update) => update.nonPayable);
    let totalNonPayMin = 0;
    NonpayableTime.forEach((non) => {
      if (non === "1/2 hour") {
        totalNonPayMin += 30;
      } else if (non === "1 hour") {
        totalNonPayMin += 60;
      }
    });
    const totalTime = getTotalWorkingHours(times, totalNonPayMin * 60);

    const newtable = temp.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    this.setState({
      tableData: newtable,
      selected: [],
      totalHours: totalTime,
    });
  };

  workScheduleDetails = () => {
    return (
      <div style={{ height: "30vw", overflow: "auto" }}>
        <div>
          <div style={{ width: "32.7%", display: "inline-block", height: "auto" }}>
            <div className="singleInviteScheduleLabelForModel">{CONSTANTS.SINGLE_CARD_SCHEDULE_LABEL}</div>
            <div style={{ marginLeft: "2vw", overflow: "inherit", marginRight: "2vw" }}>
              <RangeDatePicker
                key={this.state.refreshData}
                onChangeCallback={(dates) => {
                  this.handleDateChange(dates);
                }}
                minDate={new Date(moment().add(3, "d").toLocaleString())}
                maxDate={new Date(moment().add(3, "M").toLocaleString())}
                error={!this.state.isValidDate}
              />
              {this.state.isValidDate ? null : <div className="errorMsg">Please select a date</div>}
            </div>
          </div>
          <div style={{ width: "67.3%", display: "inline-block", float: "right" }}>
            <div style={{ display: "flex", marginTop: "50px" }}>
              {/* <SimpleTimePicker
                                refreshData={this.state.refreshData}
                                onChange={(time) => this.handleStartTimeChange(time)}
                                value={this.state.startTime._i}
                            /> */}

              <TimePicker
                id={"startTime"}
                label={"Start Time"}
                widthParent={"60%"}
                width={"80%"}
                fontSize={Constant.FONT_SIZE_WEB}
                fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                refreshData={this.state.refreshData}
                onChangeCallback={(time, timeObject, time24hr) => {
                  this.handleStartTimeChange(time24hr);
                }}
                error={!this.state.isValidStartTime}
              />

              {/* <SimpleTimePicker
                                refreshData={this.state.refreshData}
                                onChange={(time) => this.handleEndTimeChange(time)}
                                value={this.state.endTime._i}
                            /> */}

              <TimePicker
                id={"endTime"}
                label={"End Time"}
                widthParent={"60%"}
                width={"80%"}
                fontSize={Constant.FONT_SIZE_WEB}
                fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                fontSizeButton={Constant.BUTTON_FONT_SIZE_WEB}
                refreshData={this.state.refreshData}
                onChangeCallback={(time, timeObject, time24hr) => {
                  this.handleEndTimeChange(time24hr);
                }}
                error={!this.state.isValidEndTime}
              />

              <div>
                <FormControl component="fieldset">
                  <div className="nonPayableTimeLabelForModel">Non-Payable Time</div>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={this.state.nonPayableTime}
                    onChange={(e) => this.handleNonPayableTime(e)}
                  >
                    <div>
                      <FormControlLabel value="1/2 hour" control={<Radio color={Constant.COLOR_BLACK_300} />} label="1/2 hour" />
                      <FormControlLabel value="1 hour" control={<Radio color={Constant.COLOR_BLACK_300} />} label="1 hour" />
                      <FormControlLabel value="none" control={<Radio color={Constant.COLOR_BLACK_300} />} label="none" />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div>
              {this.state.isValidDifference ? null : (
                <div className="errorMsg">Start time should not be greater than end time.</div>
              )}
              {this.state.noSchedule && <div className="errorMsg">At least one record is required.</div>}
            </div>
            <div style={{ marginTop: "4px", marginLeft: "0.5vw" }}>
              <Button
                text="Add to schedule"
                height="48px"
                onClick={() => this.addToScheduleHandler()}
                color={["#2699fb", "#134d7e"]}
              />
            </div>
            <div className="scheduleTable">
              <div
                style={{
                  marginTop: "1.5vw",
                  marginLeft: "1.5vw",
                  marginRight: "1.5vw",
                }}
                className="scheduleTableHeadings"
              >
                <div style={{ flex: 1 }} className="scheduleTableHeading">
                  <DeleteIcon style={{ color: "#9A9A9A" }} fontSize="inherit" onClick={() => this.handleDelete()} />
                </div>
                <div style={{ flex: 4 }} className="scheduleTableHeading">
                  Date
                </div>
                <div style={{ flex: 3 }} className="scheduleTableHeading">
                  Start Time
                </div>
                <div style={{ flex: 3 }} className="scheduleTableHeading">
                  End Time
                </div>
                <div style={{ flex: 4 }} className="scheduleTableHeading">
                  Non-Payable (hr)
                </div>
                <div style={{ flex: 4 }} className="scheduleTableHeading">
                  Working Hours
                </div>
              </div>
              <div className="scheduleTableHeadingsBottom"></div>
              <div style={{ height: "310px", overflowX: "auto", marginTop: "10px" }}>
                {this.state.tableData.map((item, id) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid #c7c7c7",
                        marginLeft: "1.5vw",
                        width: "93%",
                        marginTop: "5px",
                        paddingBottom: "5px",
                        height: 40,
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <Checkbox
                          checked={this.isSelected(id)}
                          padding="0px"
                          onChange={() => this.onCheckBoxClicked(id)}
                          color="#2699fb"
                        />
                      </div>
                      <div className="sceduleListDate" style={{ flex: 4 }}>
                        {moment(item.date).utc(0).format("MMM DD, YY")}
                      </div>
                      <div className="sceduleListStartTime" style={{ flex: 3 }}>
                        {moment(item.startTime).format("h:mm A")}
                      </div>
                      <div className="sceduleListStartTime" style={{ flex: 3 }}>
                        {moment(item.endTime).format("h:mm A")}
                      </div>
                      <div className="sceduleListStartTime" style={{ flex: 4 }}>
                        {item.nonPayable}
                      </div>
                      <div className="sceduleListStartTime" style={{ flex: 4 }}>
                        {item.working}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              {this.state.isTimeOverlap ? <p className="errorMsg">There is a conflict but still job can be posted.</p> : null}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "23px",
              }}
            >
              <div className="inviteCardScheduleTotal">Total working Hours</div>
              <div className="inviteCardScheduleTotalValue">{this.state.totalHours}</div>
            </div>
            <div
              style={{
                marginTop: "65px",
                marginBottom: "61px",
                marginLeft: "10.2vw",
              }}
            >
              <Button
                text="Next"
                width={"9.50vw"}
                height="48px"
                onClick={() => {
                  if (this.state.tableData.length > 0) {
                    this.setState({ page: this.state.page + 1 });
                  } else {
                    this.setState({ noSchedule: true });
                  }
                }}
                color={["#2699fb", "#134d7e"]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleExpiryDate = (e) => {
    this.setState({ expiryDate: e });
  };

  handleWorkAddressChange = (e) => {
    if (e.value === "Enter new address") {
      const countryData = this.state.countryOptions.find((con) => con.value === localStorage.getItem("country"));
      if (countryData) {
        this.changeCountry(countryData.Id);
      }
      this.setState({
        isAddressFieldDisabled: false,
        isCountryFieldDisabled: true,
        isStateFieldDisabled: false,
        isCityFieldDisabled: false,
        address: {
          city: undefined,
          country: localStorage.getItem("country"),
          state: undefined,
          unit_number: "",
          postal_code: "",
          street_address: "",
        },
      });
    } else if (e.value === "Remote working") {
      this.setState({
        address: this.state.myAddress,
        isCountryFieldDisabled: true,
        isStateFieldDisabled: true,
        isCityFieldDisabled: true,
        isAddressFieldDisabled: true,
        isValidCountry: true,
        isValidCity: true,
        isValidState: true,
        isValidStreetAddress: true,
        isValidZipCode: true,
      });
    } else {
      this.setState({
        address: this.state.myAddress,
        isAddressFieldDisabled: true,
        isCountryFieldDisabled: true,
        isStateFieldDisabled: true,
        isCityFieldDisabled: true,
        isValidCountry: true,
        isValidCity: true,
        isValidState: true,
        isValidStreetAddress: true,
        isValidZipCode: true,
      });
    }
    this.setState({ selectedAddressType: e });
  };

  jobAddressPaymentDetails = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const is_suspend = localStorage.getItem("is_suspend") || false;
    const disabledDate = moment(localStorage.getItem("suspended_date")) || moment();
    const month_data = disabledDate.add(1, "months");
    const disabledBtn = is_suspend === "true" ? month_data.isAfter(moment()) : false;

    return (
      <div>
        <div style={{ margin: "3vw", display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "23.85vw", height: "100%" }}>
            <div className="inviteCardWorkAddressLabel">{CONSTANTS.SINGLE_CARD_INVITE_WORK_ADDRESS_LEVEL}</div>
            <div style={{ marginTop: "30px" }}>
              <Dropdown
                height={"56px"}
                borderRadius={"10px"}
                placeholder={CONSTANTS.SINGLE_CARD_INVITE_WORK_ADDRESS_DROPDOWN_LABEL}
                options={!this.state.isOutOfCountry ? addressTypeLocal : addressTypeInt}
                onChange={(e) => {
                  this.handleWorkAddressChange(e);
                }}
                value={this.state.selectedAddressType}
              // isDisabled={this.state.isAddressFieldDisabled}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Dropdown
                placeholder={"Country *"}
                options={this.state.countryOptions}
                value={
                  this.state.address &&
                  this.state.address.country && {
                    label: this.state.address.country,
                    value: this.state.address.country,
                  }
                }
                onChange={(event) => {
                  this.changeCountry(event.Id);
                  const address = this.state.address;
                  address.country = event.value;
                  address.state = undefined;
                  address.city = undefined;
                  this.setState({ address });
                  this.setState({ isValidCountry: true });
                }}
                isDisabled={this.state.isCountryFieldDisabled}
                error={!this.state.isValidCountry}
              />
              <div style={{ marginTop: "20px" }}>
                <Dropdown
                  placeholder={"State/Province *"}
                  options={this.state.stateOptions}
                  value={
                    this.state.address && this.state.address.state
                      ? {
                        label: this.state.address.state,
                        value: this.state.address.state,
                      }
                      : null
                  }
                  onChange={(event) => {
                    this.changeState(event.state_id);
                    const address = this.state.address;
                    address.state = event.value;
                    address.city = undefined;
                    this.setState({ address });
                    this.setState({ isValidState: true });
                  }}
                  isDisabled={this.state.isStateFieldDisabled}
                  error={!this.state.isValidState}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <Dropdown
                  placeholder={"City *"}
                  options={this.state.cityOptions}
                  value={
                    this.state.address && this.state.address.city
                      ? {
                        label: this.state.address.city,
                        value: this.state.address.city,
                      }
                      : null
                  }
                  onChange={(event) => {
                    const address = this.state.address;
                    address.city = event.value;
                    this.setState({ address });
                    this.setState({ isValidCity: true });
                  }}
                  isDisabled={this.state.isCityFieldDisabled}
                  error={!this.state.isValidCity}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  width={"100%"}
                  label="Street Address *"
                  value={this.state.address && this.state.address.street_address}
                  onChange={(event) => {
                    const address = this.state.address;
                    address.street_address = event.target.value;
                    this.setState({ address });
                    this.setState({ isValidStreetAddress: true });
                  }}
                  disabled={this.state.isAddressFieldDisabled}
                  error={!this.state.isValidStreetAddress}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  width={"100%"}
                  label="Unit Number"
                  value={this.state.address && this.state.address.unit_number}
                  onChange={(event) => {
                    const address = this.state.address;
                    address.unit_number = event.target.value;
                    this.setState({ address });
                  }}
                  disabled={this.state.isAddressFieldDisabled}
                />
              </div>
              <div style={{ marginTop: "20px" }}>
                <TextField
                  width={"100%"}
                  label="Zip/Postal Code *"
                  value={this.state.address && this.state.address.postal_code}
                  onChange={(event) => {
                    const address = this.state.address;
                    address.postal_code = event.target.value;
                    this.setState({ address });
                    this.setState({ isValidZipCode: true });
                  }}
                  disabled={this.state.isAddressFieldDisabled}
                  error={!this.state.isValidZipCode}
                />
              </div>
            </div>
            {/* <div style={{ marginTop: "30px" }}>
                            <InputText
                                label={"Work Address"}
                                isMutiline={true}
                                row={8}
                                onChangeCallback={(value) => this.setState({ address: value })}
                                disabled={this.state.isAddressFieldDisabled}
                                text={this.state.address}
                                style={{ width: '100%' }}
                            />
                        </div> */}
            <div style={{ marginTop: "30px" }} className="inviteCardWorkAddressLabel">
              {CONSTANTS.SINGLE_CARD_INVITE_PAYMENT_METHOD_HEADING_LABEL}
            </div>
            <div style={{ marginTop: "30px" }}>
              <SwitchButton
                height={"56px"}
                width={"264px"}
                borderRadius={"10px"}
                isPrimarySelected={this.state.isEmpolinkPaymentMethodSelected}
                onLeftClicked={() => this.onEmpolinkTypeClicked()}
                onRightClicked={() => this.onDirectPaymentClicked()}
                primaryLabel={"Empolink"}
                secondaryLabel={"Directly"}
                disabled={this.state.isOutOfCountry}
              />
            </div>
            <div style={{ marginTop: "12px" }}>
              <SwitchButton
                height={"56px"}
                width={"264px"}
                borderRadius={"10px"}
                isPrimarySelected={this.state.isWeeklyPaymentSelected}
                onLeftClicked={() => this.onWeeklyTypeClicked()}
                onRightClicked={() => this.onBiWeeklyTypeClicked()}
                primaryLabel={"Weekly"}
                secondaryLabel={"Bi-Weekly"}
              />
            </div>
          </div>
          <div style={{ width: "23.85vw", height: "100%" }}>
            <div>
              <TextField
                label={"Additional instructions (optional)"}
                multiline={true}
                rows={10}
                width={"100%"}
                onChange={(e) => this.setState({ additionalInstructions: e.target.value })}
                value={this.state.additionalInstructions}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <p
                style={{
                  color: "#707070",
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: 10,
                }}
              >
                Offer expiry date
              </p>
              <TextField
                type="date"
                width={"100%"}
                onChange={(date) => {
                  this.handleExpiryDate(date.target.value);
                  this.setState({ isValidExpiryDate: true });
                }}
                style={{ marginTop: 6 }}
                value={this.state.expiryDate}
                error={!this.state.isValidExpiryDate}
                inputProps={{
                  min: moment(tomorrow).format("YYYY-MM-DD"),
                  max:
                    this.state.tableData.length > 0
                      ? moment(this.state.tableData[0].date).subtract(1, "days").format("YYYY-MM-DD")
                      : null,
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ position: "unset" }} className="profilesInviteMessage">
          {this.state.jobInviteMessage}
        </div>
        <div style={{ marginTop: "1vw", textAlign: "center" }}>
          <Button
            text="Invite"
            loading={this.state.isSingleInviteLoading}
            width={"9.5vw"}
            height="2.5vw"
            onClick={() => this.handleSingleInvite()}
            color={["#2699fb", "#134d7e"]}
            disabled={disabledBtn}
          />
        </div>
      </div>
    );
  };

  jobDescriptionContent = () => {
    return (
      <>
        <div className="inviteCardContent">
          <div style={{ width: "23.85vw", marginTop: "2.91vw", marginLeft: "3.125vw" }}>
            <TextField
              label={"Job Title"}
              width={"100%"}
              onChange={(e) =>
                this.setState({
                //  jobTitle: e.target.value,
                  jobTitle: capitalizeFirstLetter(e.target.value),
                  isJobTitleEntered: true,
                })
              }
              value={this.state.jobTitle}
              error={!this.state.isJobTitleEntered}
            />
          </div>
          {this.state.isJobTitleEntered ? null : (
            <div style={{ marginLeft: "5%" }} className="errorMsg">
              {CONSTANTS.EMPTY_JOB_TITLE_ERROR_MESSAGE}
            </div>
          )}
        </div>
        <div style={{ marginTop: "1.56vw", marginLeft: "3vw" }}>
          <TextField
            rows={5}
            multiline
            width={"96%"}
            label={CONSTANTS.SINGLE_CARD_INVITE_JOB_DESCRIPTION}
            value={this.state.jobDescription}
            onChange={(e) => this.handleJobDescription(e)}
          />
        </div>
        <div>
          <div className="singleInviteDropBoxHeading">Attachments (optional)</div>
          <div style={{ marginLeft: "4.8%" }}>
            {/* <Basic
                            setFile={(e, name) => this.handleFileUploadChange(e, name)}
                            uploaded={this.state.filesUploaded}
                        /> */}
            <Attachment
              selectedList={this.state.filesUploaded}
              onSelectedValueCallback={(listSelected) => {
                this.handleFileUploadChange(listSelected);
              }}
              onRemoveValueCallback={(removeObject) => {
                this.handleRemoveSelectedFile(removeObject);
              }}
            />{" "}
          </div>
        </div>
        <div style={{ marginTop: "1vw", textAlign: "center" }}>
          <Button
            text="Next"
            width={"9.5vw"}
            height="2.5vw"
            onClick={() => {
              if (this.state.jobTitle === CONSTANTS.EMPTY_STRING) {
                this.setState({ isJobTitleEntered: false });
              } else {
                this.setState({ page: this.state.page + 1 });
              }
            }}
            color={["#2699fb", "#134d7e"]}
          />
        </div>
      </>
    );
  };

  onJobChangeHandler = (e) => {
    if (e.value === "New Invitation") {
      this.setState({
        selectedJobPost: e,
        jobPostExists: false,
        jobInviteMessage: "",
        isJobSelected: true,
      });
    } else {
      if (e.jobLocationType === "INTERNATIONAL") {
        this.setState({
          isOutOfCountry: false,
        });
      } else {
        const countries = this.props.profileData.address[0].country;
        const employerCountry = localStorage.getItem("country");
        const isOutCountry = countries === employerCountry && employerCountry.toLowerCase();

        this.setState({
          isOutOfCountry: !isOutCountry,
        });
      }
      this.setState({
        selectedJobPost: e,
        jobPostExists: true,
        jobInviteMessage: "",
        isJobSelected: true,
      });
    }
    this.setState({ selectedJobPost: e });
  };

  onJobSelectionNextClicked = () => {
    if (this.state.selectedJobPost.value === undefined) {
      this.setState({
        isJobSelected: false,
      });
    } else {
      if (this.state.selectedJobPost.value === "New Invitation") {
        this.setState({
          page: this.state.page + 1,
          jobPostExists: false,
        });
      } else {
        this.setState({ isMultpleInviteLoading: true });
        const invitees = [this.props.profileData.userid];

        let existingJobBody = {
          jobId: this.state.selectedJobPost._id,
          employerId: localStorage.getItem("userId"),
          listOfInvitees: invitees,
        };

        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        APIHelper.postMethod(API.JOB_INVITE, existingJobBody, headers)
          .then((response) => {
            if (response !== {} || response !== undefined) {
              this.setState({
                jobInviteMessage: "Profiles invited successfully",
                isMultpleInviteLoading: false,
              });
              Swal.fire({
                customClass: "Alert-Class",
                title: "Information!",
                text: "Profiles invited successfully",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#1876D2",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.props.onCloseClick();
                }
              });
            }
          })
          .catch((err) => {
            this.setState({
              jobInviteMessage: "failed!",
              isMultpleInviteLoading: false,
            });
            Swal.fire({
              customClass: "Alert-Class",
              title: "Information!",
              text: "Failed",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#1876D2",
            });
          });
      }
    }
  };

  jobPostTypeContent = (e) => {
    return (
      <div style={{ height: "30vw", overflow: "auto" }}>
        <div className="profilesInviteMessage">{this.state.jobInviteMessage}</div>
        <div className="inviteCardContent">
          <div style={{ width: "23.85vw", marginTop: "2.91vw", marginLeft: "3.125vw" }}>
            <Dropdown
              height={"3vw"}
              borderRadius={"0.5vw"}
              placeholder={"Job Title"}
              options={this.state.jobPostOptions}
              // onChange={(e) => this.setState({ selectedJobPost: e })}
              onChange={(e) => {
                this.onJobChangeHandler(e);
              }}
              value={this.state.selectedJobPost}
            />
          </div>
          {this.state.isJobSelected ? null : (
            <div style={{ position: "absolute", marginLeft: "5%", marginTop: "1%" }} className="errorMsg">
              {CONSTANTS.SELECT_JOB_ERROR_MSG}
            </div>
          )}
        </div>
        <div style={{ marginTop: "17vw", textAlign: "center" }}>
          {this.state.selectedJobPost && this.state.selectedJobPost.label === "New Invitation" && this.state.isOutOfCountry && (
            <p
              style={{
                width: "70%",
                textAlign: "left",
                marginLeft: 40,
                color: "#707070",
              }}
            >
              Note: At least one of the selected candidates is not in your country. As such the working location will be
              automatically set to remote working, payment method will be through Empolink and the contract currency will be in
              USD.
            </p>
          )}
          {this.state.selectedJobPost &&
            Object.keys(this.state.selectedJobPost).length > 0 &&
            this.state.selectedJobPost.label !== "New Invitation" &&
            this.state.isOutOfCountry && (
              <p
                style={{
                  width: "70%",
                  textAlign: "left",
                  marginLeft: 40,
                  color: "#707070",
                }}
              >
                Note: At least one of the selected candidates is out of your country. The job post you are trying to invite
                candidates to is a local job post.
              </p>
            )}
          {!this.props.isPaymentMethodVerified && (
            <p
              style={{
                width: "75%",
                textAlign: "left",
                marginLeft: 40,
                color: "red",
                marginBottom: 10,
              }}
            >
              Note: Your payment method is either expired or not verified. Please update your payment information.
            </p>
          )}
          <Button
            text={this.state.jobPostExists ? "Invite" : "Next"}
            width={"9.5vw"}
            loading={this.state.isMultpleInviteLoading}
            loadingMaskSize={20}
            height="2.5vw"
            onClick={() =>
              this.props.isPaymentMethodVerified &&
              this.onJobSelectionNextClicked()
            }
            color={["#2699fb", "#134d7e"]}
          // disabled={
          //   this.state.selectedJobPost &&
          //   Object.keys(this.state.selectedJobPost).length > 0 &&
          //   this.state.selectedJobPost.label !== "New Invitation" &&
          //   this.state.isOutOfCountry
          // }
          />
        </div>
      </div>
    );
  };

  cardContent = () => {
    const CardContents = [
      this.jobPostTypeContent(),
      this.jobDescriptionContent(),
      this.workScheduleDetails(),
      this.jobAddressPaymentDetails(),
    ];
    return (
      <div>
        <div className="cardHeader">
          <div onClick={() => this.onBackButtonClick()} className="inviteCardBackArrowStyles">
            <ArrowBackIosIcon />
          </div>
          <div onClick={this.props.onCloseClick} className="inviteCardCrossStyles">
            <ClearIcon />
          </div>
          <div>
            <img
              className="singleInviteCardProfilePicture"
              src={this.props.profileData?.photo ? this.props.profileData.photo : defaultDp}
              alt="Avatar"
              style={{}}
            />
          </div>
          <div>
            <div className="singleInviteCardProfileName">{`${this.props.profileData?.user?.firstName} ${this.props.profileData?.user?.lastName}`}</div>
            <div className="singleInviteFavoriteIcon">
              {this.props.profileData?.isFav ? (
                <FavoriteIcon fontSize={"inherit"} />
              ) : <FavoriteBorderIcon fontSize={"inherit"} />}
            </div>
            <div className="singleInviteProfileDesignation">
              {this.props.profileData.speciality.length > 0 ? this.props.profileData.speciality[0].name : null}
            </div>
            <div className="singleInviteProfileLocation">{`${this.props.profileData.address[0].city}, ${this.props.profileData.address[0].country}`}</div>
          </div>
        </div>
        <div style={{ height: "30vw", overflow: "auto" }}>{CardContents[this.state.page]}</div>
      </div>
    );
  };

  render() {
    return <div className="inviteDetailsCardMain">{this.cardContent()}</div>;
  }
}

const mapStateToProps = function (state) {
  return {
    userProfileAddress: state.profileReducer.data,
    addressValue: state.formReducer.address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: () => {
      dispatch(getProfileData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleEmployeeInviteDetails);
