import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import Text from './text'
import Spacer from './spacer'

import { VERTICAL_SMALL, HORIZONTAL_EXTRA } from '../../../utils/enums';
import { useEffect, useState, useRef } from 'react';
import { convertFrom24To12Format, getTimeDifferent, getTotalWorkingHours } from '../../../utils/commonFunction';

const headCells = [
  { id: 'Date', numeric: false, disablePadding: true, label: 'Date' },
  { id: 'Start Time', numeric: false, disablePadding: false, label: 'Start Time' },
  { id: 'End Time', numeric: false, disablePadding: false, label: 'End Time' },
  { id: 'Non-Payable (hr)', numeric: false, disablePadding: false, label: 'Non-Payable (hr)' },
  { id: 'Working Hours', numeric: false, disablePadding: false, label: 'Working Hours' },
];


export default function CentralizeDataTable(props) {
  const [width, setWidth] = useState("75%");
  const [height, setHeight] = useState("320px");

  const useStyles = makeStyles((theme) => ({
    rootParent: {
      width: width,
      '& .MuiPaper-rounded': {
        borderRadius: "10px"
      },
    },
    root: {
      padding: "30px 15px 10px 15px",
      border: "1px solid #C7C7C7",
      '& .MuiTableContainer-root': {
        height: height
      },
      '& .MuiTableCell-head': {
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        color: "#1C1C1C",
        '& .MuiSvgIcon-root': {
          color: "#9A9A9A",
          padding: "10px 0px 0px 0px"
        },
        '& .MuiCheckbox-root': {
          color: "#9A9A9A"
        },
      },

      '& .MuiTableCell-body': {
        color: "#1C1C1C",
        '& .Mui-checked': {
          color: "#2B7BFF"
        },
      },
      '& .MuiTableCell-stickyHeader': {
        backgroundColor: "#ffffff"
      },
      '& .MuiTableRow-root.Mui-selected': {
        backgroundColor: "rgba(72, 141, 255, 0.3)",
        '&:hover': {
          backgroundColor: "rgba(72, 141, 255, 0.3)"
        }
      },
    },
  }));

  const classes = useStyles();

  const [selected, setSelected] = React.useState([]);
  const [rowData, setRowData] = React.useState([]);
  const [totalWorkingHours, setTotalWorkingHours] = React.useState(0);

  const [fontSize, setFontSize] = useState("2.0vw");
  const [color, setColor] = useState("#070707");
  const [alignment, setAlignment] = useState("left");
  const [fontWeight, setFontWeight] = useState("normal");
  const [cursor, setCursor] = useState("text");

  const [fontSizeTotal, setFontSizeTotal] = useState("2.0vw");
  const [colorTotal, setColorTotal] = useState("#070707");
  const [alignmentTotal, setAlignmentTotal] = useState("left");
  const [fontWeightTotal, setFontWeightTotal] = useState("normal");
  const [cursorTotal, setCursorTotal] = useState("text");
  const [paddingTotal, setPaddingTotal] = useState("0px");
  const [borderTotal, setBorderTotal] = useState("");
  const [borderRadiusTotal, setBorderRadiusTotal] = useState("0px");

  useEffect(() => {

    const newtable = props.rowsData.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    setRowData(newtable);
    workingHours(props.rowsData);
  }, [props.rowsData]);

  useEffect(() => {
    if (props.fontSize !== undefined)
      setFontSize(props.fontSize);
  }, [props.fontSize]);

  useEffect(() => {
    if (props.color !== undefined)
      setColor(props.color);
  }, [props.color]);

  useEffect(() => {
    if (props.alignment !== undefined)
      setAlignment(props.alignment);
  }, [props.alignment]);


  useEffect(() => {
    if (props.fontWeight !== undefined)
      setFontWeight(props.fontWeight);
  }, [props.fontWeight]);

  useEffect(() => {
    if (props.cursor !== undefined)
      setCursor(props.cursor);
  }, [props.cursor]);


  useEffect(() => {
    if (props.fontSizeTotal !== undefined)
      setFontSizeTotal(props.fontSizeTotal);
  }, [props.fontSizeTotal]);

  useEffect(() => {
    if (props.colorTotal !== undefined)
      setColorTotal(props.colorTotal);
  }, [props.colorTotal]);

  useEffect(() => {
    if (props.alignmentTotal !== undefined)
      setAlignmentTotal(props.alignmentTotal);
  }, [props.alignmentTotal]);

  useEffect(() => {
    if (props.fontWeightTotal !== undefined)
      setFontWeightTotal(props.fontWeightTotal);
  }, [props.fontWeightTotal]);

  useEffect(() => {
    if (props.cursorTotal !== undefined)
      setCursorTotal(props.cursorTotal);
  }, [props.cursorTotal]);

  useEffect(() => {
    if (props.paddingTotal !== undefined)
      setPaddingTotal(props.paddingTotal);
  }, [props.paddingTotal]);

  useEffect(() => {
    if (props.borderTotal !== undefined)
      setBorderTotal(props.borderTotal);
  }, [props.borderTotal]);

  useEffect(() => {
    if (props.borderRadiusTotal !== undefined)
      setBorderRadiusTotal(props.borderRadiusTotal);
  }, [props.borderRadiusTotal]);

  useEffect(() => {
    if (props.width !== undefined)
      setWidth(props.width);
  }, [props.width]);

  useEffect(() => {
    if (props.height !== undefined)
      setHeight(props.height);
  }, [props.height]);

  const handleDelete = () => {
    var tempData = [];

    var temp = rowData.filter(item =>
      !selected.find(itemToDelete => itemToDelete === item.key)
    );

    if (props.updateListCallback !== undefined) {
      props.updateListCallback(temp);
    }
    setRowData(temp);
    workingHours(temp);
  };

  const workingHours = (data) => {
    console.log('data', data);
    var workighHours = 0;
    for (var i = 0; i < data.length; i++) {
      const workingSplit = data[i].whours.split(":");
      if (workingSplit.length === 2) {
        workighHours += (parseInt(workingSplit[0]) * 60 * 60) + (parseInt(workingSplit[1]) * 60);
      }
    }

    var divisor_for_minutes = workighHours % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);
    console.log('workighHours', workighHours);
    const totalWork = `${Math.floor(workighHours / 3600)}:${minutes > 9 ? minutes : `0${minutes}`}`;
    if (props.setTotalWorking !== undefined) {
      props.setTotalWorking(totalWork);
    }
    setTotalWorkingHours(totalWork);
  };



  const handleClick = (event, key) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (key) => selected.indexOf(key) !== -1;

  return (
    <div>
      <div className={classes.rootParent}>
        <Paper className={classes.root}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell >
                    <DeleteIcon
                      onClick={(event) => handleDelete()} />
                  </TableCell>
                  {headCells.map((headCell, index) => (
                    <TableCell key={index} padding="normal">
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((row, index) => {
                  const isItemSelected = isSelected(row.key);

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.key)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}>

                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                        />
                      </TableCell>

                      <TableCell >
                        {row.date}
                      </TableCell>
                      <TableCell >{convertFrom24To12Format(parseInt(row.start.split(':')[0]), parseInt(row.start.split(':')[1]))}</TableCell>
                      <TableCell >{convertFrom24To12Format(parseInt(row.end.split(':')[0]), parseInt(row.end.split(':')[1]))}</TableCell>
                      <TableCell >{row.npayable}</TableCell>
                      <TableCell >{row.whours}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      <Spacer spaceType={VERTICAL_SMALL} />

      <div className="Row-Parent-Medium-Space-Between">
        <Text
          fontSize={fontSize}
          color={color}
          alignment={alignment}
          fontWeight={fontWeight}
          cursor={cursor}
          text="Total working Hours" />

        <Spacer spaceType={HORIZONTAL_EXTRA} />

        <Text
          fontSize={fontSizeTotal}
          color={colorTotal}
          alignment={alignmentTotal}
          fontWeight={fontWeightTotal}
          cursor={cursorTotal}
          border={borderTotal}
          padding={paddingTotal}
          borderRadius={borderRadiusTotal}
          text={totalWorkingHours} />
      </div>

    </div>
  );
}
