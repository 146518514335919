import React, { Component } from "react";
import "../Register/RegisterPage.scss";
import TextBox from "../../Components/TextField/Textfield";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import { withRouter } from "react-router-dom";
import { APIHelper } from "../../utils/ApiHelper";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import API  from "../../utils/Urls";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import {FIRST_NAME, LAST_NAME, COMPANY_NAME, MOBILE_NUMBER, LANGUAGE} from '../../action/employer_registration_action'
import {connect} from 'react-redux'
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import { capitalizeFirstLetter } from '../../utils/commonFunction'; // Import the utility function capitalizeFirstLetter

class BusinessInfoPageOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      isFirstNameEmpty: false,
      lastName: "",
      isLastNameEmpty: false,
      companyName: "",
      isCompanyNameEmpty: false,
      mobileNumber: "",
      ismobileNumberValid: true,
      language: [],
      languages: [],
    };
  }

  componentDidMount() {
    APIHelper.getMethod(API.GET_LANGUAGES).then(
      (response) => {
        console.log(response);
        if (response["status"] === 1) {
          const options = response.data.map(function (row) {
            return { value: row.name, label: row.name, id: row._id };
          });
          this.setState({ languages: options });
        }
      }
    );
    console.log("apiData Inside Component did mount");
    console.log(this.props.apiData);
    if (this.props.apiData !== {}) {
      this.setState({
        firstName: this.props.apiData.profileObj?.givenName,
        lastName: this.props.apiData.profileObj?.familyName,
      });
    }
  }

  onNextClicked() {
    if (this.props.firstName === "" || this.props.firstName === undefined) {
      this.setState({ isFirstNameEmpty: true });
    }
    if (this.props.lastName === "" || this.props.lastName === undefined) {
      this.setState({ isLastNameEmpty: true });
    }
    if (this.props.companyName === "") {
      this.setState({ isCompanyNameEmpty: true });
    }
    // var reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    // if(!reg.test(this.props.mobileNumber)) {
    //   this.setState({ ismobileNumberValid: false });
    // } else {
    //   this.setState({ ismobileNumberValid: true });
    // }
    if (
      this.props.firstName !== "" &&
      this.props.lastName !== "" &&
      this.props.companyName !== "" 
      // && this.state.ismobileNumberValid
    ) {
      this.props.onPageOneNextClicked(
        this.props.firstName,
        this.props.lastName,
        this.props.companyName,
        this.props.mobileNumber,
        this.props.language.map((item) => item.label)
      );
    }
  }

  render() {
    return (
      <>
        <div style={{ width: "100%", height: "1080px", position: "absolute" }}>
          <div
            style={{
              background: "#E2F5FF",
              width: "24.16%",
              height: "100%",
              position: "absolute",
            }}
          >
            <div class="logo" style={{ cursor: 'pointer' }} onClick={()=>this.props.history.push("/")}>
              <img src={EmpolinkLogo} alt="Empolink" style={{ marginTop: "111px", marginLeft:"156px" }}/>
            </div>
          </div>
          {/* <div onClick={this.props.handleBackButtonClick} className="backIconButtonStyles"><div><ArrowBackIosOutlinedIcon color="inherit"/></div></div> */}
          <div
            style={{
              float: "right",
              background: "white",
              height: "100%",
              width: "75.84%",
            }}
          >
            <div
              style={{
                marginLeft: "34.27%",
                marginRight: "34.27%",
                height: "100%",
                width: "31.46%",
              }}
            >
              <div>
                <div>
                  <h1 className="signUpHeading">Sign Up</h1>
                  <div className="signUpSubHeading">Business Information</div>
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                  <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      required={true}
                      label={"First Name"}
                      // onChange={(event) => {
                      //   this.setState({
                      //     firstName: event.target.value,
                      //     isFirstNameEmpty: false,
                      //   });
                      // }}
                      onChange = {(event)=>{
                      //  this.props.handleFirstName(event);
                      //  capialize
                          this.props.handleFirstName(capitalizeFirstLetter(event.target.value));
                          this.setState({isFirstNameEmpty: false,})
                      }}
                      value={this.props.firstName}
                      width={"100%"}
                      error={this.state.isFirstNameEmpty}
                    />
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      required={true}
                      label={"Last Name"}
                      onChange={(event) => {
                      //  this.props.handleLastName(event)
                      // add capitalization
                        this.props.handleLastName(capitalizeFirstLetter(event.target.value));
                        this.setState({
                          isLastNameEmpty: false,
                        });
                      }}
                      value={this.props.lastName}
                      width={"100%"}
                      error={this.state.isLastNameEmpty}
                    />
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      required={true}
                      label={"Company/BusinessName"}
                      onChange={(event) => {
                      //  this.props.handleCompanyName(event)
                      //    this.setState({
                      //    companyName: event.target.value,
                      //    isCompanyNameEmpty: false,
                      // });
                      // capitalization
                        this.props.handleCompanyName(capitalizeFirstLetter(event.target.value));
                        this.setState({ isCompanyNameEmpty: false });
                      }}
                      value={this.props.companyName}
                      width={"100%"}
                      error={this.state.isCompanyNameEmpty}
                    />
                  </div>
                  <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      label={"Mobile number"}
                      onChange={(event) => {
                      //  this.props.handleMobileNumber(event)
                        const input = event.target.value;
                        this.props.handleMobileNumber(event.target.value);
                      // sanitize mobile number
                      // Use regex to remove any characters that are not numbers, plus sign, brackets, or hyphen
                        const sanitizedInput = input.replace(/[^\d+()\-\s]/g, '');
                      // Remove any spaces from the sanitized input
                        const sanitizedWithoutSpaces = sanitizedInput.replace(/\s/g, '');
                        this.props.handleMobileNumber(sanitizedWithoutSpaces);  
                      }}
                      value={this.props.mobileNumber}
                      width={"100%"}
                      type={"text"}
                      // error={!this.state.ismobileNumberValid}
                    />
                  </div>
                  <div style={{ paddingBottom: "47px" }}>
                    {/* <TextBox
                                            label={"Language(s)"}
                                            onChange={(event) => { this.setState({languages: event.target.value})}}
                                            value={this.state.languages}
                                            width={"100%"}
                                        /> */}
                    <Dropdown
                      // onChange={(event) =>
                      //   this.setState({
                      //     language: event.map((item) => item.label),
                      //   },() => {console.log(this.state.language)})
                      // }
                      onChange={(event) =>{
                        this.props.handleLanguage(event)

                        this.setState({
                          language: event,
                        },() => {console.log(this.state.language)})}
                      }
                      onInputChange={() => {}}
                      disabledDropdownIndicator={true}
                      options={this.state.languages}
                      value = {this.props.language}
                      isMulti = {true}
                      placeholder={"Language(s)"}
                    />
                  </div>
                </div>
                <Button
                  text="Next"
                  width={"100%"}
                  height="56px"
                  onClick={() => this.onNextClicked()}
                  color={["#2699fb", "#134d7e"]}
                />
                <div style={{ textAlign: "center", paddingTop: "47px" }}>
                  <Stepper steps={3} activeStep={0} color={"#1876d2"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

//const mapDispatchToProps = (dispatch) => {
//  return {
//    handleFirstName: (event) => dispatch({ type: FIRST_NAME, firstName: event.target.value }),
//   handleLastName: (event) => dispatch({ type: LAST_NAME, lastName: event.target.value }),
//    handleCompanyName: (event) => dispatch({ type: COMPANY_NAME, companyName: event.target.value }),
//    handleMobileNumber: (event) => dispatch({ type: MOBILE_NUMBER, mobileNumber: event.target.value }),
//    handleLanguage: (event) => dispatch({ type: LANGUAGE, language: event }),
//  }
//}

const mapDispatchToProps = (dispatch) => {
  return {
    handleFirstName: (firstName) => dispatch({ type: FIRST_NAME, firstName }),
    handleLastName: (lastName) => dispatch({ type: LAST_NAME, lastName }),
    handleCompanyName: (companyName) => dispatch({ type: COMPANY_NAME, companyName }),
    handleMobileNumber: (mobileNumber) => dispatch({ type: MOBILE_NUMBER, mobileNumber }),
    handleLanguage: (language) => dispatch({ type: LANGUAGE, language }),
  }
}
const mapStateToProps = function(state) {
  return {
    firstName : state.employerRegisterReducer.firstName,
    lastName : state.employerRegisterReducer.lastName,
    companyName : state.employerRegisterReducer.companyName,
    mobileNumber : state.employerRegisterReducer.mobileNumber,
    language : state.employerRegisterReducer.language,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessInfoPageOne));
