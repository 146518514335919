

export  const INCREMENT = () => ({
  type: "INCREMENT"
});
export const DECREMENT = () => ({
  type: "DECREMENT"
})

export const SET_ERRORS = () => ({
  type: "SET_ERRORS"
})

export const JOB_TITLE = (jobTitle = "") => ({
  type: "JOB_TITLE",
  jobTitle : jobTitle,
})
export const JOB_DESCRIPTION = (jobDescription = "") => ({
  type: "JOB_DESCRIPTION",
  jobDescription : jobDescription,
})
export const ATTACHMENT_URL = (attachmentURL = []) => ({
  type: "ATTACHMENT_URL",
  attachmentURL : attachmentURL,
})

export const REMOVE_ATTACHMENT_LIST = (attachmentURL = []) => ({
  type: "REMOVE_ATTACHMENT_LIST",
  attachmentURL : attachmentURL,
})

export const ADDITIONAL_INSTRUCTION = (additionalUserInstruction = "") => ({
  type: "ADDITIONAL_INSTRUCTION",
  additionalUserInstruction : additionalUserInstruction,
})


export const FIELD_OF_WORK = (fieldOfWork = "", fieldOfWorkObject) => ({
  type: "FIELD_OF_WORK",
  fieldOfWork : fieldOfWork,
  fieldOfWorkObject : fieldOfWorkObject
})
export const SPECIALITY = (speciality = "", specialityObject) => ({
  type: "SPECIALITY",
  speciality : speciality,
  specialityObject : specialityObject
})
export const SUGGESTED_SKILLS = (suggestedSkills = []) => ({
  type: "SUGGESTED_SKILLS",
  suggestedSkills : suggestedSkills,
})
export const NEW_ADDITIONAL_SKILLS = (additionalSkills = []) => ({
  type: "NEW_ADDITIONAL_SKILLS",
  additionalSkills : additionalSkills,
})
export const REMOVE_NEW_ADDITIONAL_SKILLS = (additionalSkills = []) => ({
  type: "REMOVE_NEW_ADDITIONAL_SKILLS",
  additionalSkills : additionalSkills,
})

export const EXPERIENCE_RANGE = (experienceRange = []) => ({
  type: "EXPERIENCE_RANGE",
  experienceRange : experienceRange
})


export const IS_HOURLY = (isHourly, feeType) => ({
  type: "IS_HOURLY",
  isHourly :isHourly,
  feeType : feeType,
})
export const HOURLY_RATE = (hourlyRate) => ({
  type: "HOURLY_RATE",
  hourlyRate : hourlyRate,
})

export const HOURLY_RATE_MAX = (hourlyRate) => ({
  type: "HOURLY_RATE_MAX",
  hourlyRate : hourlyRate,
})

export const HOURLY_RATE_MIN = (hourlyRate) => ({
  type: "HOURLY_RATE_MIN",
  hourlyRate : hourlyRate,
})

export const FEE_AMOUNT = (feeAmount) => ({
  type: "FEE_AMOUNT",
  feeAmount : feeAmount,
})

export const PAYMENT_METHOD = (paymentMethod) => ({
  type: "PAYMENT_METHOD",
  paymentMethod : paymentMethod,
})

export const JOB_POST_ADDRESS = (jobPostLoctionValue) => ({
  type: "JOB_POST_ADDRESS",
  jobPostLoctionValue : jobPostLoctionValue,
})

export const JOB_LOCATIONS = (jobLocations) => ({
  type: "JOB_LOCATIONS",
  jobLocations : jobLocations,
})

export const PAYMENT_TIME = (paymentTime) => ({
  type: "PAYMENT_TIME",
  paymentTime : paymentTime,
})

export const NEXT_BUTTON_ENABLE = (screenType = 1) => ({
  type: "NEXT_BUTTON_ENABLE",
  screenType : screenType,
})

export const ADD_WSCHEDULE_HLIST = (dates,startTime, endTime, nPayableTime) => ({
  type: "ADD_WSCHEDULE_HLIST",
  dates : dates,
  startTime : startTime,
  endTime : endTime,
  nPayableTime : nPayableTime
})

export const DATE_REFRESH = () => ({
  type: "DATE_REFRESH",
  refreshData : false
})

export const UPDATE_WSCHEDULE_HLIST = (list = []) => ({
  type: "DELETE_WSCHEDULE_HLIST",
  workScheduleHourlyList : list,
});

export const TOTAL_WORKING = (data) => ({
  type: "TOTAL_WORKING",
  totalWorking : data,
});

export const DATE_DUPLICATE_SCHEDULE = (data) => ({
  type: "DATE_DUPLICATE_SCHEDULE",
  isWorkingDataDuplicate : data,
})

export const JOB_START_DATE = (jobStartDate) => ({
  type: "JOB_START_DATE",
  jobStartDate : jobStartDate,
})
export const JOB_CLOSE_DATE = (jobCloseDate) => ({
  type: "JOB_CLOSE_DATE",
  jobCloseDate : jobCloseDate,
})
export const JOB_POST_CLOSE_DATE = (jobPostCloseDate) => ({
  type: "JOB_POST_CLOSE_DATE",
  jobPostCloseDate : jobPostCloseDate,
})
export const REFRESH_DATE_FORM_DATA = () => ({
  type: "REFRESH_DATE_FORM_DATA"
})


export const LOCATION_LIST = (locationList = []) => ({
  type: "LOCATION_LIST",
  locationList : locationList,
})
export const REMOVE_LOCATION = (locationList = []) => ({
  type: "REMOVE_LOCATION",
  locationList : locationList,
})

export const ADDRESS_TYPE = (addressType = "") => ({
  type: "ADDRESS_TYPE",
  addressType : addressType,
})
export const ADDRESS = (address = "") => ({
  type: "ADDRESS",
  address : address,
})
export const RESET = () => ({
  type: "RESET",
})

export const LOADER = (showLoader) => ({
  type: "LOADER",
  showLoader : showLoader
})
export const CLEAR_SKILLS_SELECTION = () => ({
  type: "CLEAR_SKILLS_SELECTION"
})
export const CLEAR_CHIPS_SELECTION = (clearChipsSelection = false) => ({
  type: "CLEAR_CHIPS_SELECTION",
  clearChipsSelection : clearChipsSelection
})
