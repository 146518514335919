import React, { useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";

const format = "MM/DD/YYYY";

const MultipleDatePicker = (props) => {
  return (
    <div>
      <DatePicker
        value={props.value}
        onChange={props.onChange}
        onClose={props.onClose}
        style={props.style}
        multiple
        sort
        format={format}
        calendarPosition="bottom-center"
        placeholder={props.placeholder}
        onOpen={props.onOpen}
        range={props.range}
        minDate={props.minDate}
      />
    </div>
  );
}

export default MultipleDatePicker;
