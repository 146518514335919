import React, { Component } from "react";
import "./EmployeeRegister.scss";
import TextBox from "../../Components/TextField/Textfield";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import { withRouter } from "react-router-dom";
import CheckboxComponent from "../../Components/CheckboxComponent/CheckboxComponent";
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import SignUpLogo from '../../Assets/images/signup-img.png';
class EmployeeRegisterPageOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      firstNameError: false,
      lastName: "",
      lastNameError: false,
      preferredName: "",
      mobileNumber: "",
      mobileNumberError: "",
      isMobileNumberValid: true,
      showFirstLetterOfLastName: false,
    };
  }

  componentDidMount() {
    // console.log(this.props.googleApiData);
    // if (this.props.googleApiData !== {}) {
    //   this.setState({
    //     firstName: this.props.googleApiData.profileObj?.givenName,
    //     lastName: this.props.googleApiData.profileObj?.familyName,
    //   });
    // }
  }

  render() {
    const onNextClicked = () => {
      if (!this.state.firstName || this.state.firstName === "") {
        this.setState({ firstNameError: true });
      }
      if (!this.state.lastName || this.state.lastName === "") {
        this.setState({ lastNameError: true });
      }

      if (this.state.mobileNumber !== "") {
        var reg = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (this.state.mobileNumber !== "") {
          if (!reg.test(this.state.mobileNumber)) {
            this.setState({ isMobileNumberValid: false });
          } else {
            this.setState({ isMobileNumberValid: true });
          }
        }
      }
      if (
        !(!this.state.firstName || this.state.firstName === "") &&
        !(!this.state.lastName || this.state.lastName === "") &&
        !(!this.state.lastName) &&
        this.state.isMobileNumberValid
      ) {
        this.props.setCurrentPage();
        this.props.handleRegisterData(this.state);
      }
    };
    return (
      <>
        <div className="login-wrapper-step sign-up-body signUp-1">
          <div className="container">
            <div className="row row-center">
              <div className="col-md-4">
                <div className="login-img">
                  <a className="login-logo" href={void (0)} style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push("/") }}>
                    <img src={EmpolinkLogo} alt="Empolink" />
                  </a>
                </div>
              </div>
              <div className="col-md-8">
                <div className="login-right">
                  <div>
                    <div>
                      <h1 className="signUpHeading">Sign Up</h1>
                      <div
                        className="signUpSubHeading"
                        style={{ paddingBottom: "80px" }}
                      >
                        Personal Information
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        marginBottom: "29px",
                      }}
                    >
                      <div style={{ paddingBottom: "18px" }}>
                        <TextBox
                          required={true}
                          label={"First Name"}
                          onChange={(event) => {
                            this.setState({ firstNameError: false });
                            //Change first letter to capital
                            const capitalizedFirstName =
                              event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
                            this.setState({ firstName: capitalizedFirstName });
                          }}
                          //  this.setState({ firstName: event.target.value });
                          //}}
                          value={this.state.firstName}
                          error={this.state.firstNameError}
                          width={"100%"}
                        />
                      </div>
                      <div style={{ paddingBottom: "18px" }}>
                        <TextBox
                          label={"Preferred Name"}
                          onChange={(event) => {
                            this.setState({ preferredName: event.target.value });
                          }}
                          value={this.state.preferredName}
                          width={"100%"}
                        />
                      </div>
                      <div style={{ paddingBottom: "18px" }}>
                        <TextBox
                          required={true}
                          label={"Last Name"}
                          onChange={(event) => {
                            this.setState({ lastNameError: false });
                            //change first letter to capital
                            const capitalizedLastName =
                              event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
                            this.setState({ lastName: capitalizedLastName });
                          }}
                          //  this.setState({ lastName: event.target.value });
                          //}}
                          value={this.state.lastName}
                          error={this.state.lastNameError}
                          width={"100%"}
                        />
                      </div>
                      <div
                        style={{ display: "inline-block", paddingBottom: "18px" }}
                      >
                        <CheckboxComponent
                          checked={this.state.showFirstLetterOfLastName}
                          onChange={() => {
                            this.setState({
                              showFirstLetterOfLastName:
                                !this.state.showFirstLetterOfLastName,
                            });
                          }}
                          name={"Only show first letter of my last name"}
                          label={"Only show first letter of my last name"}
                          color="#a4c772"
                        />
                      </div>
                      {/* <div style={{ paddingBottom: "18px" }}>
                    <TextBox
                      label={"Mobile number"}
                      type={"text"}
                      onChange={(event) => {
                        const input = event.target.value;
                        // Use regex to remove any characters that are not numbers, plus sign, brackets, or hyphen
                        const sanitizedInput = input.replace(/[^\d+()\-\s]/g, '');
                        // Remove any spaces from the sanitized input
                        const sanitizedWithoutSpaces = sanitizedInput.replace(/\s/g, '');
                        this.setState({ mobileNumber: sanitizedWithoutSpaces });
                      }}
                      value={this.state.mobileNumber}
                      error={!this.state.isMobileNumberValid}
                      width={"100%"}
                    />
                  </div> */}
                    </div>
                    <div className="signUp-btn">
                      <Button
                        text="Next"
                        width={"100%"}
                        height="56px"
                        onClick={() => onNextClicked()}
                        color={["#a4c772", "#4e8400"]}
                      />
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "47px" }}>
                      <Stepper steps={5} activeStep={0} color={"#a4c772"} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EmployeeRegisterPageOne);
