import React from 'react';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import {useEffect, useState, useRef } from 'react';
import TouchOutside from './touch_outside_hooks';
import { makeStyles } from '@material-ui/core/styles';


export default  function CentralizeDropDown(props) {
  const ref = useRef("CentralizeDropDown");
  const [isDropDownOpen, setDropDownState] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [width, setWidth] = useState("40%");
  const [fontSize, setFontSize] = useState("0.9375vw");
  const [fontSizeDropDown, setFontSizeDropDown] = useState("0.9375vw");
  const [menuWidth, setMenuWidth] = useState("17%");

  const useStyles = makeStyles((theme) => ({
          dropDown :{
            width: width,
            display: "flex",
            justifyContent: "start",
            alignItems: "flex-start",
            flexDirection: "column",
          },

          dropDownSelection :{
            width: "100%",
            height: "56px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "10px",
            border: props.error ? "1px solid red" : "1px solid  #B6B7B6",
            letterSpacing: "normal",
            cursor: "pointer",
            '& .MuiSvgIcon-root': {
              marginRight : "10px",
              color : "#1876D2"
            },
          },

          dropDownText :{
            width: "100%",
            fontFamily: "Libre Franklin",
            fontSize: fontSize,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: props.error ? 'red' : "#707070",
            paddingLeft: "10px",
            paddingRight: "10px",
            whiteSpace : "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          },

          dropDownMenu : {
            width: menuWidth,
            minHeight: "0px",
            maxHeight: "200px",
            position: "absolute",
            marginTop: "70px",
            marginLeft: "5px",
            elevation : "10",
            zIndex : "5",
            borderRadius: "16px",
            overflowY: "scroll"
          },

          dropDownMenuList : {
            '&:hover': {
              width: "100%",
              backgroundColor: "#ffffff",
            }
          },

          dropDownMenuText : {
            width: "100%",
            fontFamily: "Libre Franklin",
            fontSize: fontSizeDropDown,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#707070",
            padding : "10px 10px 10px 10px",
            marginLeft: "5px",
            marginRight: "5px",
            whiteSpace : "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            '&:hover': {
              color: "white",
              backgroundColor: "#1876D2",
              borderRadius: "10px",
              border: "0px solid  #707070"
            }
          }

  }));

  const classes = useStyles();

  TouchOutside(ref, () => setDropDownState(false));

  useEffect(() => {
    if(props.width !== undefined)
      setWidth(props.width);
    }, [props.width]);

    useEffect(() => {
      if(props.fontSize !== undefined)
        setFontSize(props.fontSize);
      }, [props.fontSize]);

      useEffect(() => {
        if(props.fontSizeDropDown !== undefined)
          setFontSizeDropDown(props.fontSizeDropDown);
        }, [props.fontSizeDropDown]);

    useEffect(() => {
        if(props.menuWidth !== undefined)
          setMenuWidth(props.menuWidth);
    }, [props.menuWidth]);

  useEffect(() => {
      if(props.text !== undefined )
       setSelectedValue(props.text);
    }, [props.text]);


  return (
        <div className={classes.dropDown} ref={ref}>

        <div className= {classes.dropDownSelection}
        onClick={() => {
          if(!isDropDownOpen)
              setDropDownState(true);
            else
              setDropDownState(false);
         }}>

        <div className= {classes.dropDownText}>
          {selectedValue === "" ? props.label : selectedValue}
        </div>


        {props.showDropDownIcon  === undefined || props.showDropDownIcon ?
          <ExpandMoreSharpIcon/> : <div></div>}

        </div>

           {isDropDownOpen ?
             <Card elevation={5} className= {classes.dropDownMenu} >
             <List  disablePadding>
               {props.dropDownList.map((data, index) => (

                <div key = {index}>
                 <ListItem button key={index}  disableGutters={true}   disabled={props.disabled}
                  className = {classes.dropDownMenuList}

                    onClick={() => {
                      if(props.onSelectedValueCallback != undefined){
                        props.onSelectedValueCallback(data.lable, data);
                      }

                       setSelectedValue(data.lable);
                       setDropDownState(false);
                     }}>
                    <div className = {classes.dropDownMenuText}>
                     {data.lable}
                    </div>
                 </ListItem>
               {props.dropDownList.length -1 !== index ? <Divider /> : <div/>}
                 </div>
               ))}
             </List>
             </Card>
              : <div/>

           }

        </div>
    );
}
