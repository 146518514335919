import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CustomButton from '../Button/Button'
import './WideProfileCard.scss'
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CustomChip from '../Chip/Chip';
import Button from '../Button/Button'
import ScoreChart from '../GaugeChart/GaugeChart'
import defaultDp from '../../Assets/blankProfile.png'
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DrawerComponent from "../../Components/Drawer/Drawer";
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
import EmployeeDetail from "./EmployeeDetail";
const useStyles = makeStyles({
  root: {
    width: "60.854vw",
    height: "11.406vw",
    backgroundColor: '#ffffff'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const StyledRating = withStyles({
  iconFilled: {
    color: "#1876d2"
  },
  sizeSmall: {
    fontSize: "1.0416vw"
  },
  iconHover: {
    color: "#ff3d47"
  }
})(Rating);

const WideProfileCard = (props) => {
  const classes = useStyles();
  const [isFavorite, setIsFavorite] = React.useState(props.isFavorite);
  
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      {/* <Card className={classes.root}> */}
      <div style={{ width: "60.854vw", height: "11.406vw", borderTop: "2px solid #c4c4c4" }}>
        <div>
          <div className="wideCardProfileDetails">
            <div className="wideCardCheckbox">
              <Checkbox
                style={{
                  color: "#2699fb",
                }}
                iconStyle={{ color: '#00000' }}
                checked={props.isPersonInvited}
                onChange={props.onProfileInviteClicked}
                name="profileSelected"
              />
            </div>
            <div className="wideCardProfilePicture">
              <img className="wideCardImg" src={props.profilePicture ? props.profilePicture : defaultDp} alt="Avatar" style={{}} />
            </div>
            <div className="wideCardProfileInfo">
              <div className="wideCardInfoName">
                <div onClick={() => props.setDrawerOpen(true)} style={{cursor:"pointer", maxWidth:100,overflow:"hidden",textOverflow:"ellipsis"}}>{props.name}</div> 
                {isFavorite ? 
                  <div onClick={()=>{props.removeFromFavorite(props.userid); setIsFavorite(!isFavorite)}}>
                    <FavoriteIcon style={{ color: '#1876D2',fontSize:"18px",marginLeft:"15px",marginTop:5}}/>
                  </div> : 
                  <div onClick={()=>{props.addToFavorite(props.userid); setIsFavorite(!isFavorite)}}>
                    <FavoriteBorderIcon style={{ color: '#1876D2',fontSize:"18px",marginLeft:"15px",marginTop:5 }}/>
                  </div>}
              </div>
              <div className="wideCardInfoDesignation">{props.designation}</div>
              <div className="wideCardInfoLocation">{props.location}</div>
            </div>
          </div>
          <div style={{ display: "inline-block", position: "absolute", marginLeft: "14vw", marginTop: "2.4vw" }} className="wideCardYearsExperiance">{props.experience} years</div>
          {/* <div style={{position:"absolute"}}>650</div> */}
          <div style={{ position: "absolute", display: "inline-block", marginLeft: "23vw", marginTop: "1.5vw" }}>
            <ScoreChart
              score={(props.score !== undefined && props.score !== null && props.score !== '') ? parseInt(props.score) : 0}
              totalScore={1000}
            />
          </div>
          <div style={{ display: "inline-block", position: "absolute", marginLeft: "40vw", marginTop: "2.4vw" }} className="wideCardYearsExperiance">${props.rate}</div>
          <div style={{ display: "inline-block", position: "absolute", marginLeft: "45.1vw", paddingTop: "2.28vw" }}>
            <div className="wideCardNumberOfRatings" style={{ marginRight: "0.4vw" }}>{props.rating}</div>
            <div style={{ display: "inline-block" }}>
              <StyledRating
                name="hover-feedback"
                size={"small"}
                value={props.rating}
                precision={0.5}
                readOnly
                fontSize="inherit" />
            </div>
            <div className="wideCardNumberOfRatings" style={{ marginLeft: "0.4vw" }}>{props.numberOfRatings}</div>
          </div>
        </div>
        <div style={{ marginTop: "2.5vw" }}>
          <div>
            {props.skills !== undefined && props.skills.length > 0 ?
              <CustomChip
                outlined={true}
                style={{
                  height: "1.77vw",
                  borderRadius: "1.0416vw",
                  border: "solid 1px #707070",
                  backgroundColor: "#ffffff",
                  color: "#9a9a9a",
                  fontSize: "0.833vw"
                }}
                label={props.skills !== undefined && props.skills.length > 0 ? props.skills[0].name : "null"}
              /> : null}
              {props.skills !== undefined && props.skills.length > 1 ?
              <CustomChip
                outlined={true}
                style={{
                  height: "1.77vw",
                  borderRadius: "1.0416vw",
                  border: "solid 1px #707070",
                  backgroundColor: "#ffffff",
                  color: "#9a9a9a",
                  fontSize: "0.833vw"
                }}
                label={props.skills[1].name}
              /> : null}
              {props.skills !== undefined && props.skills.length > 2 ?
              <CustomChip
                outlined={true}
                style={{
                  height: "1.77vw",
                  borderRadius: "1.0416vw",
                  border: "solid 1px #707070",
                  backgroundColor: "#ffffff",
                  color: "#9a9a9a",
                  fontSize: "0.833vw"
                }}
                label={props.skills[2].name}
              /> : null}
              {props.skills !== undefined && props.skills.length > 3 ?
              <CustomChip
                outlined={true}
                style={{
                  height: "1.77vw",
                  borderRadius: "1.0416vw",
                  border: "solid 1px #707070",
                  backgroundColor: "#ffffff",
                  color: "#9a9a9a",
                  fontSize: "0.833vw"
                }}
                label={props.skills[3].name}
              /> : null}
              {props.skills !== undefined && props.skills.length > 4 ?
              <CustomChip
                outlined={true}
                style={{
                  height: "1.77vw",
                  borderRadius: "1.0416vw",
                  border: "solid 1px #707070",
                  backgroundColor: "#ffffff",
                  color: "#9a9a9a",
                  fontSize: "0.833vw"
                }}
                label={props.skills[4].name}
              /> : null}
            {/* <CustomChip
              outlined={true}
              style={{
                height: "1.77vw",
                borderRadius: "1.0416vw",
                border: "solid 1px #707070",
                backgroundColor: "#ffffff",
                color: "#9a9a9a",
                fontSize: "0.833vw"
              }}
              label={"skill.name"}
            />
            <CustomChip
              outlined={true}
              style={{
                height: "1.77vw",
                borderRadius: "1.0416vw",
                border: "solid 1px #707070",
                backgroundColor: "#ffffff",
                color: "#9a9a9a",
                fontSize: "0.833vw"
              }}
              label={"skill.name"}
            />
            <CustomChip
              outlined={true}
              style={{
                height: "1.77vw",
                borderRadius: "1.0416vw",
                border: "solid 1px #707070",
                backgroundColor: "#ffffff",
                color: "#9a9a9a",
                fontSize: "0.833vw"
              }}
              label={"skill.name"}
            />
            <CustomChip
              outlined={true}
              style={{
                height: "1.77vw",
                borderRadius: "1.0416vw",
                border: "solid 1px #707070",
                backgroundColor: "#ffffff",
                color: "#9a9a9a",
                fontSize: "0.833vw"
              }}
              label={"skill.name"}
            /> */}
            <div style={{ float: "right" }}>
              <Button
                text="Invite"
                width={"6.40625vw"}
                height="2.5vw"
                onClick={props.handleInvite}
                disabled={props.disabledBtn}
                color={["#2699fb", "#134d7e"]} />
            </div>
          </div>
        </div>
      </div>
      {/* </Card> */}
    </>
  )
}

export default WideProfileCard