import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import API from '../../utils/Urls';
import { APIHelper } from '../../utils/ApiHelper';
import { SHOW_UNREAD_MESSAGES } from "../../action/messages_action";
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Howl } from 'howler';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MessageBackGround from "../../Assets/MessageBg.svg";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import socketIO from 'socket.io-client';
import * as _ from 'lodash';
const socket = socketIO.connect(API.SOCKET_URL);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
        height: '100%'
    },
    headBG: {
        backgroundColor: '#f6f3f3'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    messageArea: {
        height: '65vh',
        overflowY: 'auto',
        // paddingTop: '20%',
    },
    form: {
        width: "100%",
    },
    listItem: {
        display: "flex",
        width: "100%",
    },
    listItemRight: {
        flexDirection: "row-reverse",
    },
    messageBubble: {
        padding: 10,
        border: "1px solid #f3f3f3",
        backgroundColor: "#f3f3f3",
        borderRadius: "10px 10px 10px 10px",
        boxShadow: "-3px 4px 4px 0px rgba(0,0,0,0.08)",
        marginTop: 8,
        maxWidth: "24em",
    },
    messageBubbleRight: {
        padding: 10,
        border: "1px solid #e5f2fb",
        backgroundColor: "#e5f2fb",
        borderRadius: "10px 10px 10px 10px",
        boxShadow: "-3px 4px 4px 0px rgba(0,0,0,0.08)",
        marginTop: 8,
        maxWidth: "24em",
    },
    avatar: {
        margin: theme.spacing(1, 1.5),
    },
    sizeAvatar: {
        height: theme.spacing(6.5),
        width: theme.spacing(6.5),
        marginTop: 6,
    },
    badge: {
        fontSize: 16,
        minWidth: '24px',
        height: '24px'
    },
    chipContainer: {
        // height: '100%',
        // height: theme.spacing(8.5),
        height: '70%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    emptyMessageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '25px',
        // background: '#f1f2f4',
        height: '80vh',
        overflowY: 'auto'
    },
    chatContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        alignItems: 'center',
        height: '100%',
        borderRadius: '0px 4px 4px 0px',
        minWidth: '285px'
    },
    listItemText: {
        marginLeft: '16px',
        fontSize: '0.7em',//Insert your required size
    },
    secondaryItemText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '60%'
    },
    noResult: {
        textAlign: 'center',
        marginTop: '20px',
        fontSize: '1.1em'
    },
    messageInputBox: {
        borderRadius: '22px',
    },
    imageFileIcon: {
        color: 'rgba(255, 255, 255, 0.54)',
    }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
        // backgroundColor: '#44b700',
        // color: '#44b700',
        backgroundColor: (props) => props.customBackground,
        color: (props) => props.customBackground,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            // animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const Messages = (props) => {
    const classes = useStyles();
    const [newMessage, setNewMessage] = useState('');
    const [newMessageReceived, setNewMessageReceived] = useState();
    const [receiverUserId, setReceiverUserId] = useState('');
    const [messages, setMessages] = useState([]);
    const [conversations, setConversations] = useState(null);
    const [conversationsUserInfo, setConversationsUserInfo] = useState('');
    const [searchConversations, setSearchConversations] = useState('');
    const [favouriteConversations, setFavouriteConversations] = useState('');
    const [activeContractConversations, setActiveContractConversations] = useState('');
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const currentUserId = localStorage.getItem('userId');
    let chatBottom = useRef(null);
    const refFile = useRef();
    const fileObj = {
        '.jpg': 'https://forum-empolink.s3.amazonaws.com/icons/image.png',
        '.jpeg': 'https://forum-empolink.s3.amazonaws.com/icons/image.png',
        '.png': 'https://forum-empolink.s3.amazonaws.com/icons/image.png',
        '.svg': 'https://forum-empolink.s3.amazonaws.com/icons/image.png',
        '.pdf': 'https://forum-empolink.s3.amazonaws.com/icons/pdf.png',
        '.docx': 'https://forum-empolink.s3.amazonaws.com/icons/docs.png',
        '.doc': 'https://forum-empolink.s3.amazonaws.com/icons/docs.png',
        '.txt': 'https://forum-empolink.s3.amazonaws.com/icons/docs.png',
        '.csv': 'https://forum-empolink.s3.amazonaws.com/icons/sheets.png',
        '.xlsx': 'https://forum-empolink.s3.amazonaws.com/icons/xlsx.png',
        '.xls': 'https://forum-empolink.s3.amazonaws.com/icons/xlsx.png',
        '.zip': 'https://forum-empolink.s3.amazonaws.com/icons/zip.png',
    };

    let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    const getConversations = () => {
        APIHelper.getMethod(`${API.GET_CONVERSATIONS}?isFavourite=${favouriteConversations}&isActiveContract=${activeContractConversations}`, headers)
            .then((response) => {
                if (response.success === true) {
                    setConversations(response.result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        const args = {
            user_id: currentUserId,
        };
        socket.emit('join-conversation', args);

        // return () => {
        //     socket.emit('disconnect');
        //     socket.off();
        // };
    }, []);

    useEffect(() => {
        // socket.on('connectUser', (res) => {
        //     if (res.user_id !== currentUserId) {
        //         console.log('connectUser ', res.user_id);
        //         // receiverUserId = res.user_id;
        //     }
        // });
        socket.on('new-message', (response) => {
            setNewMessageReceived(response);
        });
        socket.on('get-users', (users) => {
            setOnlineUsers(users);
        });
    }, [socket]);

    const addCon = () => {
        const obj = {
            newUserId: '61587ca9574e0000ae0094cb',
            jobId: '',
        };
        APIHelper.postMethod(API.ADD_CONTACT, obj, headers)
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const addNewConversation = () => {
        console.log('Add new Conversation');
        const body = {
            partnerId: '61587ca9574e0000ae00963c',
            contractId: '645ae02b4ecdb4001f1ecd08'
        };
        APIHelper.postMethod(API.ADD_NEW_CONVERSATIONS, body, headers)
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const scrollToBottom = () => {
        if (chatBottom.current) {
            chatBottom.current.scrollIntoView({
                // behavior: "smooth"
                behavior: "auto",
                block: 'end',
                inline: 'nearest'
            });
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // new message received 
    useEffect(() => {
        if (newMessageReceived !== undefined && newMessageReceived !== '') {
            const { message, sender } = newMessageReceived;
            let msg = setNewConversationToResult(sender).map((item) => {
                const newItem = item;
                const { partnerId } = newItem;
                if (String(partnerId._id) === String(sender)) {
                    newItem.messages = newItem.messages.concat(message);
                    newItem.unreadMessages += 1;
                }
                if (newItem.messages?.length === 0) {
                    getConversations();
                }
                return newItem;
            });
            let tempMsg = {}
            if (message.type === 'text') {
                tempMsg = {
                    dateTime: message.dateTime,
                    message: message.message,
                    type: message.type,
                    currentUserIsSender: false,
                    _id: message._id
                };
            } else {
                tempMsg = {
                    dateTime: message.dateTime,
                    message: message.message,
                    type: message.type,
                    fileName: message.fileName,
                    fileNameUUID: message.fileNameUUID,
                    fileExtension: message.fileExtension,
                    senderId: message.senderId,
                    currentUserIsSender: false,
                    _id: message._id
                };
            }

            // const tempMsg = {
            //     dateTime: message.dateTime,
            //     message: message.message,
            //     currentUserIsSender: false,
            //     _id: message._id
            // };

            const addNewMessage = messages.map((item, index) => {
                if ((messages?.length - 1) === index) {
                    item.items = item.items.concat(tempMsg);
                }
                return item;
            });
            setConversations(msg);
            setMessages(addNewMessage);
            setNewMessageReceived();
            const sound = new Howl({
                src: [
                    `${process.env.PUBLIC_URL}message-received.mp3`
                ]
            });
            sound.play();
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        }
    }, [newMessageReceived]);

    useEffect(() => {
        if ((favouriteConversations !== undefined && favouriteConversations !== null) || (activeContractConversations !== undefined && activeContractConversations !== null)) {
            getConversations();
            // setTimeout(() => {
            //     addNewConversation();
            // }, 500);
        }
    }, [favouriteConversations, activeContractConversations]);

    const checkUserStatus = (partnerId) => {
        const tempOnlineUsers = onlineUsers?.find((user) => user.userId === partnerId);
        return tempOnlineUsers !== undefined ? '#44b700' : '#9aaa97';
    };

    const renderConversations = () => {
        const searchResult = conversations?.filter(person => person.partnerId?.firstName?.toLowerCase().includes(searchConversations.toLowerCase()) || person.partnerId?.lastName?.toLowerCase().includes(searchConversations.toLowerCase()))
        return (
            <>
                <List>
                    {searchResult?.length > 0 && searchResult
                        .map((item, index) => (
                            <>
                                <ListItem
                                    button
                                    key={index}
                                    selected={item.partnerId._id === conversationsUserInfo?.partnerId?._id}
                                    onClick={() => handleGetMessages(item.partnerId._id)}
                                >
                                    {/* <ListItemIcon>
                                        <Avatar alt="Remy Sharp" src="https://material-ui.com/static/images/avatar/1.jpg" />
                                        <Avatar src={item.partnerId?.profile_url} />
                                    </ListItemIcon> */}
                                    <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        variant="dot"
                                        customBackground={checkUserStatus(item.partnerId?._id)}
                                    >
                                        <Avatar src={item.partnerId?.profile_url} />
                                    </StyledBadge>
                                    <ListItemText
                                        // classes={{ primary: classes.listItemText }}
                                        classes={{ root: classes.listItemText, secondary: classes.secondaryItemText }}
                                        primary={
                                            <>
                                                {`${item.partnerId.firstName} ${item.partnerId.lastName}`}
                                                {(!item.isFavourite && localStorage.getItem('userType') !== 'EMPLOYEE') && (
                                                    <FavoriteBorderIcon
                                                        name="favButton"
                                                        style={{ paddingLeft: '3px', paddingTop: '6px' }}
                                                    // onClick={(e) => {
                                                    //     e.stopPropagation();
                                                    //     setFavouriteConversation(true, item.partnerId?._id);
                                                    // }}
                                                    />
                                                )}
                                                {(item.isFavourite && localStorage.getItem('userType') !== 'EMPLOYEE') && (
                                                    <FavoriteIcon
                                                        name="favButton"
                                                        color="primary"
                                                        style={{ paddingLeft: '3px', paddingTop: '6px' }}
                                                    // onClick={(e) => {
                                                    //     e.stopPropagation();
                                                    //     setFavouriteConversation(false, item.partnerId?._id);
                                                    // }}
                                                    />
                                                )}
                                            </>
                                        }
                                        secondary={item?.messages[item?.messages?.length - 1]?.message}
                                    />
                                    {/* <ListItemText secondary="online" align="right"></ListItemText>
                                <Badge
                                    badgeContent={item.unreadMessages}
                                    color="primary"
                                    classes={{ badge: classes.badge }}
                                /> */}
                                    {item.unreadMessages > 0 && (
                                        <Chip label={item.unreadMessages} size="medium" color="primary" />
                                    )}
                                </ListItem>
                                {(index + 1) !== searchResult?.length && (<Divider />)}
                            </>
                        ))}
                    {searchResult?.length === 0 && (
                        <ListItemText
                            classes={{ primary: classes.noResult }}
                            primary="No Contact Found"
                        />
                    )}
                </List>
            </>
        );
    };

    const getSignUrl = async (senderId, fileNameUUID) => {
        const response = await APIHelper.getMethod(`${API.SIGN_URL_CONVERSATIONS}?senderId=${senderId}&fileNameUUID=${fileNameUUID}`, headers)
        window.open(response.result, '_blank', 'noopener,noreferrer');
    };

    const renderMessages = () => {
        return (
            <>
                {messages.map((item, index) => (
                    <>
                        <div className={classes.chipContainer}>
                            <Chip
                                label={item.timeTag}
                                variant="outlined"
                            />
                        </div>
                        {item?.items.map((i, itemIndex) => (
                            <>
                                <ListItem
                                    key={itemIndex}
                                    className={i.currentUserIsSender ? classes.listItemRight : classes.listItem}
                                    alignItems="flex-start"
                                >
                                    <ListItemIcon className={classes.avatar}>
                                        <Avatar
                                            className={classes.sizeAvatar}
                                            src={i.currentUserIsSender ? conversationsUserInfo?.ownerId?.profile_url : conversationsUserInfo?.partnerId?.profile_url}
                                        />
                                    </ListItemIcon>
                                    {i.type === 'text' && (
                                        <ListItemText
                                            align={i.currentUserIsSender ? 'right' : 'left'}
                                            className={i.currentUserIsSender ? classes.messageBubbleRight : classes.messageBubble}
                                            primary={i.message}
                                            secondary={moment(i.dateTime).format('hh:mm A')}
                                        >
                                        </ListItemText>
                                    )}
                                    {i.type === 'file' && (
                                        <ImageListItem
                                            key={itemIndex}
                                        >
                                            <img
                                                src={fileObj[i.fileExtension]}
                                                alt={i.fileName}
                                                style={{ height: '170px', width: '170px' }}
                                            />
                                            <ImageListItemBar
                                                title={i.fileName}
                                                // subtitle={<span>by: test</span>}
                                                actionIcon={
                                                    <IconButton
                                                        aria-label="download"
                                                        className={classes.imageFileIcon}
                                                        onClick={() => getSignUrl(i.senderId, i.fileNameUUID)}
                                                    >
                                                        <GetAppIcon />
                                                    </IconButton>
                                                }
                                            />
                                        </ImageListItem>
                                    )}
                                </ListItem>
                                <div ref={chatBottom} />
                            </>
                        ))}
                    </>
                ))}
            </>
        )
    };

    const getItems = (items) => {
        const messages = [];
        const aggregatedItems = _.groupBy(items, (item) => {
            return moment(item.dateTime).utc().startOf('day').format();
        });

        _.mapKeys(aggregatedItems, (value, key) => {
            const item = {
                items: value
            };

            const diff = moment(new Date()).diff(moment(key), 'days');

            if (diff <= 6) {
                if (diff === 0) {
                    item.timeTag = 'Today';
                } else if (diff === 1) {
                    item.timeTag = 'Yesterday';
                } else {
                    item.timeTag = moment(key).format('dddd');
                }
            } else {
                item.timeTag = moment(key).format('DD/MM/YYYY');
            }

            messages.push(item);
        });

        return messages;
    };

    const handleGetMessages = (partnerId) => {
        APIHelper.getMethod(`${API.GET_MESSAGES}?partnerId=${partnerId}`, headers)
            .then((response) => {
                if (response.success === true) {
                    setReceiverUserId(partnerId);
                    setMessages(getItems(response.result));
                    const convUserInfo = conversations.find(item => String(item.partnerId._id) === String(partnerId));
                    if (convUserInfo !== undefined && convUserInfo !== '') {
                        setConversationsUserInfo(convUserInfo);
                    }
                    scrollToBottom();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const setNewConversationToResult = (partnerId) => {
        return conversations.find(item => String(item.partnerId._id) === String(partnerId))
            ? conversations
            : conversations.concat([{
                partnerId: partnerId,
                messages: [],
                unreadMessages: 0
            }]);
    };

    const setConversationIsRead = () => {
        if (receiverUserId !== undefined && receiverUserId !== null && receiverUserId !== '') {
            const isUnReadMessages = conversations.find(item => String(item.partnerId._id) === String(receiverUserId) && item.unreadMessages > 0);
            if (isUnReadMessages !== undefined && isUnReadMessages !== null && isUnReadMessages !== '') {
                const body = {
                    partnerId: receiverUserId,
                    conversation: {
                        unreadMessages: 0
                    }
                };
                APIHelper.putMethod(API.UPDATE_CONVERSATIONS, body, headers)
                    .then((response) => {
                        if (response.success === true) {
                            props?.showUnreadMessages();
                            const setReadConv = conversations.map((item, index) => {
                                if (String(item.partnerId._id) === String(receiverUserId)) {
                                    item.unreadMessages = 0;
                                }
                                return item;
                            });
                            setConversations(setReadConv);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };

    const setFavouriteConversation = (isFavourite, receiverUserId) => {
        if (receiverUserId !== undefined && receiverUserId !== null && receiverUserId !== '') {
            const body = {
                partnerId: receiverUserId,
                conversation: {
                    isFavourite: isFavourite
                }
            };
            APIHelper.putMethod(API.UPDATE_CONVERSATIONS, body, headers)
                .then((response) => {
                    if (response.success === true) {
                        const setReadConv = conversations.map((item, index) => {
                            if (String(item.partnerId._id) === String(receiverUserId)) {
                                item.isFavourite = isFavourite;
                            }
                            return item;
                        });
                        setConversations(setReadConv);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const setFilterConversation = (filter) => {
        if (filter === '') {
            setFavouriteConversations('');
            setActiveContractConversations('');
        }
        if (filter === 1) {
            setActiveContractConversations(true);
            setFavouriteConversations('');
        }
        if (filter === 2) {
            setFavouriteConversations(true);
            setActiveContractConversations('');
        }
        setMessages([]);
        setReceiverUserId('');
        setConversationsUserInfo('');
        handleClose();
    };

    const onFileUploadClicked = async (event) => {
        const file = event.target.files[0];
        const fileSize = file.size;
        const fileMb = fileSize / 1024 ** 2;
        if (fileMb >= 2) {
            Swal.fire({
                customClass: 'Alert-Class',
                title: 'Information!',
                text: 'Please select a file less than 2MB.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1876D2',
            });
            refFile.current.value = "";
            return;
        }
        let bodyFormData = new FormData();
        bodyFormData.append('files', file, file.name);
        bodyFormData.append('receiverId', receiverUserId);
        let headersFile = {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        const response = await APIHelper.postMethod(API.UPLOAD_FILE_CONVERSATIONS, bodyFormData, headersFile);
        refFile.current.value = "";
        const newObj = {
            message: {
                currentUserIsSender: true,
                ...response.result
            },
            partner: {
                _id: response.result.receiverId
            }
        };

        let msg = setNewConversationToResult(response.result.receiverId).map((item) => {
            const newItem = item;
            const { partnerId } = newItem;
            const { message, partner } = newObj;

            if (String(partnerId._id) === String(partner._id)) {
                newItem.messages = newItem.messages.concat(message);
            }
            return newItem;
        });
        const tempMsg = {
            dateTime: response.result.dateTime,
            message: response.result.message,
            type: response.result.type,
            fileName: response.result.fileName,
            fileNameUUID: response.result.fileNameUUID,
            fileExtension: response.result.fileExtension,
            senderId: response.result.senderId,
            currentUserIsSender: true,
            _id: response.result._id
        };
        const addNewMessage = messages.map((item, index) => {
            if ((messages?.length - 1) === index) {
                item.items = item.items.concat(tempMsg);
            }
            return item;
        });

        setConversations(msg);
        setMessages(addNewMessage);
        scrollToBottom();


    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setNewMessage('');
        var obj = {
            message: newMessage,
            receiverId: receiverUserId
        };
        APIHelper.postMethod(API.POST_MESSAGE, obj, headers)
            .then((response) => {
                const newObj = {
                    message: {
                        currentUserIsSender: true,
                        ...response.result
                    },
                    partner: {
                        _id: response.result.receiverId
                    }
                };

                let msg = setNewConversationToResult(response.result.receiverId).map((item) => {
                    const newItem = item;
                    const { partnerId } = newItem;
                    const { message, partner } = newObj;

                    if (String(partnerId._id) === String(partner._id)) {
                        newItem.messages = newItem.messages.concat(message);
                    }
                    return newItem;
                });
                const tempMsg = {
                    dateTime: response.result.dateTime,
                    message: response.result.message,
                    type: response.result.type,
                    currentUserIsSender: true,
                    _id: response.result._id
                };

                let addNewMessage = [];
                if (messages?.length > 0) {
                    addNewMessage = messages.map((item, index) => {
                        if ((messages?.length - 1) === index) {
                            item.items = item.items.concat(tempMsg);
                        }
                        return item;
                    });
                } else {
                    let temp = [];
                    response.result.currentUserIsSender = true;
                    temp.push(response.result);
                    addNewMessage = getItems(temp);
                }
                setConversations(msg);
                setMessages(addNewMessage);
                scrollToBottom();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Grid container component={Paper} className={classes.chatSection}>
                <Grid item xs={3} className={classes.borderRight500}>
                    <Grid item xs={12} style={{ padding: '10px' }}>
                        <TextField
                            id="outlined-basic-email"
                            label="Search"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                                setSearchConversations(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ padding: '10px' }}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            style={{ textTransform: 'none', marginLeft: '18%', fontSize: 16 }}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            All Messages
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            style={{ marginTop: '2%', marginLeft: `${localStorage.getItem('userType') === 'EMPLOYER' ? "0px" : "-9px"}` }}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ horizontal: 'center' }}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => setFilterConversation('')}>All Messages</MenuItem>
                            <MenuItem onClick={() => setFilterConversation(1)}>Show only people with active jobs</MenuItem>
                            {localStorage.getItem('userType') !== 'EMPLOYEE' && (
                                <MenuItem onClick={() => setFilterConversation(2)}>Show only my favourites</MenuItem>
                            )}
                        </Menu>
                    </Grid>
                    <Divider />
                    {renderConversations()}
                </Grid>
                {receiverUserId !== '' && (
                    <Grid item xs={9}>
                        {conversationsUserInfo !== '' && (
                            <ListItem
                                key="user"
                                classes={{ root: classes.headBG }}
                            >
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    variant="dot"
                                    customBackground={checkUserStatus(conversationsUserInfo?.partnerId?._id)}
                                >
                                    <Avatar src={conversationsUserInfo?.partnerId?.profile_url} />
                                </StyledBadge>
                                <ListItemText
                                    // classes={{ secondary: classes.secondaryItemText }}
                                    classes={{ root: classes.listItemText }}
                                    primary={`${conversationsUserInfo?.partnerId?.firstName} ${conversationsUserInfo?.partnerId?.lastName}`}
                                    secondary="Toronto Canada"
                                />
                            </ListItem>
                        )}
                        <List
                            ref={chatBottom}
                            className={classes.messageArea}
                        >
                            <div className="ref">
                                {renderMessages()}
                            </div>
                        </List>
                        <Divider />
                        <form onSubmit={handleSubmit}>
                            <Grid container style={{ padding: '20px' }}>
                                <Grid item xs={11}>
                                    <TextField
                                        id="message"
                                        label="Type Something"
                                        fullWidth
                                        value={newMessage}
                                        variant="outlined"
                                        multiline
                                        onFocus={() => setConversationIsRead()}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                handleSubmit(e);
                                            }
                                        }}
                                        InputProps={{
                                            maxLength: 100,
                                            className: classes.messageInputBox,
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="file upload"
                                                        component="label"
                                                    >
                                                        <AttachFileIcon />
                                                        <input
                                                            hidden
                                                            ref={refFile}
                                                            type="file"
                                                            onChange={(e) => onFileUploadClicked(e)}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />
                                </Grid>
                                <Grid xs={1} align="right">
                                    <Fab type="submit" color="primary" aria-label="add"><SendIcon /></Fab>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                )}
                {receiverUserId === '' && (
                    <Grid item xs={9}>
                        <div className={classes.emptyMessageContainer}>
                            <img src={MessageBackGround} />
                        </div>
                    </Grid>
                )}
            </Grid >
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        showUnreadMessages: () => dispatch({ type: SHOW_UNREAD_MESSAGES }),
    };
};

const mapStateToProps = function (state) {
    return {
        shouldShowUnreadMessages: state.messagesReducer.showUnreadMessages,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
