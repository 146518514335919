import { FIRST_NAME, LAST_NAME, COMPANY_NAME, MOBILE_NUMBER, LANGUAGE } from '../action/employer_registration_action';
import { COUNTRY, STATE, CITY, STREET_ADDRESS, UNIT_NUMBER, POSTAL_CODE, FIELD_OF_WORK, SPECIALITY_OPTIONS_LIST, TERMS_AND_CONDITION_ACCEPTED } from '../action/employer_registration_action';


const initialState = {
    status: "Ideal",
    data: "",
    firstName: "",
    lastName: "",
    companyName: "",
    mobileNumber: "",
    language: [],
    country:null,
    state: null,
    city: null,
    street_address: "",
    unitNumber: "",
    postalCode: "",
    field: null,
    specialityOptions: [],
    isTermsAndConditionAccepted: false
}

function employerRegisterReducer(state = initialState, action) {
    switch (action.type) {
        case FIRST_NAME:
            return { ...state, firstName: action.firstName }
        case LAST_NAME:
            return { ...state, lastName: action.lastName }
        case COMPANY_NAME:
            return { ...state, companyName: action.companyName }
        case MOBILE_NUMBER:
            return { ...state, mobileNumber: action.mobileNumber }
        case LANGUAGE:
            return { ...state, language: action.language }
        case COUNTRY:
            return { ...state, country: action.country }
        case STATE:
            console.log(action.state)
            return { ...state, state: action.state }
        case CITY:
            return { ...state, city: action.city }
        case STREET_ADDRESS:
            return { ...state, street_address: action.street_address }
        case UNIT_NUMBER:
            return { ...state, unitNumber: action.unitNumber }
        case POSTAL_CODE:
            return { ...state, postalCode: action.postalCode }
        case FIELD_OF_WORK:
            return { ...state, field: action.field }
        case SPECIALITY_OPTIONS_LIST:
            return { ...state, specialityOptions: action.specialityOptions }
        case TERMS_AND_CONDITION_ACCEPTED:
            return { ...state, isTermsAndConditionAccepted: !action.isTermsAndConditionAccepted }
        default:
            return state
    }
}
export default employerRegisterReducer
