
export  const LOADING = () => ({
});

export const ERROR = (errorMessage) => ({
  errorMessage : errorMessage
});

export const SUCCESS = (data) => ({
  data : data
});

export const CLEAR = () => ({
});
