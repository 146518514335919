import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = (props) => {
  const [showLoader, setShowLoader] = React.useState(false);

  useEffect(() => {
    if (props.loaderState !== undefined) {
      setShowLoader(props.loaderState);
    }
  }, [props.loaderState]);

  return (
    <div>
      {showLoader ?
        <div className="Loader">
          <div> <CircularProgress />   <div className="Loader-Text">Deleteing Job</div> </div>
        </div>
        :
        <div></div>
      }
    </div>
  );
}

export default Loader;
