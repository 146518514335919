import React, { useLayoutEffect, useState } from 'react';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function ScreenSizeDiv(props) {
  const [width, height] = useWindowSize();
  return (
    <div className = {width < 700 ? props.mobile : (width <= 800 && width > 700) ? props.tablet : props.web}>
      {props.child}
    </div>
  );
}
