import React, { useCallback } from 'react'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import UnverifiedIcon from '../../Assets/unverified.svg';

import './EditDetailsComponent.scss'

const EditDetailsComponent = (props) => {
    const encyptString = useCallback((count) => {
        var value = "";
        for (let i = 0; i < count; i++) {
            value += "*";
        }
        return value;
    }, [props.encrypt]);

    return (
        <div style={{ width: "100%" }}>
            <div className="EditDetailsCompHeadingStyles">
                <div className="editDetailsCompHeading"> {props.label} </div>
                {props.otherChangeType ? props.otherChangeType :
                    <div onClick={props.onEditClick} style={{ color: props.isEmployee ? "#a4c772" : "#1876d2" }}>
                        {!props.isEditButtonClicked ? <EditOutlinedIcon color='inherit' /> : <CloseIcon color='inherit' />}
                    </div>}
            </div>
            {!props.isEditButtonClicked && (
                <div className="EditDetailsCompDetailsStyles">
                    {props.verifiedIcon &&
                        <div style={{ marginRight: "6px", color: "#1876d2" }}>
                            <VerifiedUserOutlinedIcon color='inherit' style={{ height: 26 }} />
                        </div>
                    }
                    {props.unverifiedIcon &&
                        <div style={{ marginRight: "6px", color: "#1876d2" }}>
                            <img src={UnverifiedIcon} style={{ height: 26 }} />
                        </div>
                    }
                    <div style={{ width: '100%' }}>
                        {
                            props.encrypt
                                ? encyptString(props.content?.length)
                                : props.content
                        }
                    </div>
                </div>
            )}
            {props.isLoading && <LinearProgress />}
            {props.isEditButtonClicked &&
                <div>
                    {props.editFields}
                </div>
            }
            {props.showBorderBottom && <div className="EditDetailsBottomLineStyles" />}
        </div>
    )
}

export default EditDetailsComponent;