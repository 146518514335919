import React, { useState } from 'react';
import './CardFlip.scss';

const CardFlip = ({ card }) => {
    const [flipped, setFlipped] = useState(false);

    const handleFlip = () => {
        setFlipped(!flipped);
    };

    return (
        <div className={`card ${flipped ? 'flipped' : ''}`} onClick={handleFlip}>
            <div className="card-front">
                <i className={`fa ${card.icon} icon-color`}></i>
                <p>{card.title}</p>
            </div>
            <div className="card-back">
                <ul>
                    {card.backText.map((text, i) => (
                        <li key={i} dangerouslySetInnerHTML={{ __html: text }} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CardFlip;
