import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { getAttachmentIcon } from '../../../../utils/commonFunction';

const FixedContractPDFView = (props) => {
    const { data } = props;
    return (
        <div
            style={{
                padding: 100,
                paddingTop: 70,
                fontFamily: 'Libre Franklin',
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>Contract (Fixed Fee)</h3>
            </div>
            <div style={{ marginTop: 64, fontSize: 20 }}>
                <div style={{ display: 'flex' }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Contract No:</p>
                    <p>{data.contractNumber}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a', lineHeight: '70px' }}>Client:</p>
                    <AccountCircleIcon
                        style={{
                            height: '70px',
                            width: '70px',
                        }}
                    />
                    <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                        {data.employerProfileData &&
                            data.employerProfileData.length > 0 &&
                            data.employerProfileData[0].company &&
                            data.employerProfileData[0].company.name}
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Client Name:</p>
                    <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Client Address:</p>
                    <p>{data.employerData[0].location.country}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Talent Address:</p>
                    <p>{data.employeeData[0].location.country}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Job Title:</p>
                    <p>{data.jobs && data.jobs[0].title}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Field of Work:</p>
                    <p>{data.jobs && data.jobs.length > 0 && data.jobs[0].field.name}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: 59,
                    }}
                >
                    <p style={{ width: 200, color: '#9a9a9a' }}>Speciality:</p>
                    <p>{data.jobs && data.jobs[0].speciality.name}</p>
                </div>
                <div
                    style={{
                        marginTop: 59,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '50px',
                    }}
                >
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Job Description:</p>
                    <pre
                        style={{
                            marginTop: 30,
                            color: '#1c1c1c',
                            fontsize: 20,
                            fontFamily: 'Libre Franklin',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {data.jobs[0].description}
                    </pre>
                </div>
                <div
                    style={{
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '50px',
                    }}
                >
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Additional Instructions:</p>
                    <pre
                        style={{
                            marginTop: 30,
                            color: '#1c1c1c',
                            fontsize: 20,
                            fontFamily: 'Libre Franklin',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {data.jobs && data.jobs[0].additionalDetails}
                    </pre>
                </div>
                <div style={{ marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Talent Questions:</p>
                    <p style={{ marginTop: 15 }}>
                        {data.applicationData &&
                            data.applicationData[0].QuestionAnswer &&
                            data.applicationData[0].QuestionAnswer.length > 0 &&
                            data.applicationData[0].QuestionAnswer[0].message}
                    </p>
                </div>
                <div
                    style={{
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '50px',
                    }}
                >
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Client Response:</p>
                    <p style={{ marginTop: 15 }}>
                        {data.applicationData &&
                            data.applicationData[0].QuestionAnswer &&
                            data.applicationData[0].QuestionAnswer.length > 1 &&
                            data.applicationData[0].QuestionAnswer[1].message}
                    </p>
                </div>

                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Work Address:</p>
                    <p>
                        {' '}
                        {data.jobs[0].remoteWorking
                            ? 'Remote Working'
                            : `${data.jobs && data.jobs[0].workAddress.street_address}, ${
                                  data.jobs[0].workAddress.unit_number
                              }, ${data.jobs[0].workAddress.city}, ${
                                  data.jobs[0].workAddress.state
                              }, ${data.jobs[0].workAddress.postal_code}`}
                    </p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Work Start Date:</p>
                    <p>
                        {moment(data.jobs && data.jobs[0].startDate)
                            .utc(0)
                            .format('MMM DD, YYYY')}
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: 59,
                    }}
                >
                    <p style={{ width: 200, color: '#9a9a9a' }}>Work End Date:</p>
                    <p>
                        {moment(data.jobs && data.jobs[0].endDate)
                            .utc(0)
                            .format('MMM DD, YYYY')}
                    </p>
                </div>

                <div style={{ marginTop: 59 }}>
                    <p style={{ width: 200, color: '#9a9a9a' }}>Payment Schedule:</p>
                    <div
                        style={{
                            border: '2px solid #C4C4C4',
                            borderRadius: '15px',
                            marginTop: '15px',
                            paddingRight: '10px',
                            paddingLeft: '20px',
                        }}
                    >
                        <table
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                borderCollapse: 'collapse',
                                fontFamily: 'Libre Franklin',
                            }}
                            className="table-small-row-font"
                        >
                            <thead>
                                <tr
                                    style={{
                                        height: 60,
                                        borderBottom: '2px solid #C4C4C4',
                                    }}
                                >
                                    <th
                                        style={{
                                            width: '25%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Milestones
                                    </th>
                                    <th
                                        style={{
                                            width: '25%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Milestone Name
                                    </th>
                                    <th
                                        style={{
                                            width: '25%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Payment Date
                                    </th>
                                    <th
                                        style={{
                                            width: '20%',
                                            color: '#1c1c1c',
                                            textAlign: 'right',
                                        }}
                                    >
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.milestone.map((element, index) => {
                                    return (
                                        <tr
                                            style={{
                                                height: 60,
                                                borderBottom: '2px solid #C4C4C4',
                                                backgroundColor: 'inherit',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    width: '25%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {index + 1}
                                            </td>
                                            <td
                                                style={{
                                                    width: '25%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {element.description}
                                            </td>
                                            <td
                                                style={{
                                                    width: '25%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {moment(element.dateSchedule)
                                                    .utc(0)
                                                    .format('MMM DD, YYYY')}
                                            </td>
                                            <td
                                                style={{
                                                    width: '20%',
                                                    color: '#1c1c1c',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                ${element.amount}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingBottom: '15px',
                                paddingTop: '15px',
                            }}
                        >
                            <p>Total Amount</p>
                            <p>${data.applicationData && data.applicationData[0].finalFee}</p>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ width: 300, color: '#9a9a9a' }}>Job Value:</p>
                    <p>${data.applicationData && data.applicationData[0].finalFee}</p>
                </div>
                {data.jobs.length > 0 && data.jobs[0].paymentMethod === 'DIRECT' ? (
                    <p style={{ color: '#9a9a9a', marginTop: '10px' }}>
                        Client will pay an amount equal to job value directly to talent.
                    </p>
                ) : (
                    <>
                        <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                            Client will pay an amount equal to job value to empolink.
                        </p>
                        <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                            Empolink will pay talent an amount equal to job value deducted by
                            talent service fee.
                        </p>
                    </>
                )}
                <div
                    style={{
                        display: 'flex',
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '50px',
                    }}
                >
                    <p style={{ width: 300, color: '#9a9a9a' }}>Client Payment Method:</p>
                    <p>{data.jobs && data.jobs[0].paymentMethod}</p>
                </div>

                <div style={{ display: 'flex', marginTop: 59 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Date:</p>
                    <p>Jun 20 2021</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '50px',
                    }}
                >
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Time:</p>
                    <p>12:00 AM</p>
                </div>
                {/* <div style={{ marginTop: 59 }}>
                            <p style={{ width: 300, color: '#9a9a9a' }}>Attachments</p>
                            <div
                                style={{
                                    border: '2px solid #C4C4C4',
                                    borderRadius: '4px',
                                    marginTop: '10px',
                                    padding: 40,
                                }}
                            >
                                <p>Document1.jpg</p>
                                <p>Document2.pdf</p>
                            </div>
                            </div> */}
                <div style={{ display: 'flex', marginTop: 59 }}>
                    <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                </div>
                <div
                    style={{
                        height: 210,
                        border: '1px solid #c7c7c7',
                        marginTop: 10,
                        borderRadius: 10,
                        padding: 20,
                    }}
                >
                    {data.jobs[0].attachments.map((attach) => (
                        <a href={attach.url} download target="_blank" rel="noreferrer">
                            <img
                                src={getAttachmentIcon(attach.name)}
                                style={{
                                    width: 60,
                                    height: 60,
                                    marginRight: 10,
                                }}
                            />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FixedContractPDFView;
