import React from 'react';
import ScreenSize from '../screen_size'
import DrawerMenuMobile from './components/drawer_menu_mobile'
import DrawerMenuWeb from './components/drawer_menu_web'
import DrawerMenuTablet from './components/drawer_menu_tablet'
import Constant from '../../Constant/constant'


export default function DrawerMenu(props) {
  return (
        <ScreenSize
           mobile = {
            <DrawerMenuMobile
              selectedValue = {props.selectedValue}
              data = {props.data}/>
           }
           tablet = {
             <DrawerMenuTablet
               selectedValue = {props.selectedValue}
               data = {props.data}/>
           }
           web = {
            <DrawerMenuWeb
              fontSize = {Constant.DRAWER_TEXT_SIZE}
              selectedValue = {props.selectedValue}
              data = {props.data}/>
            }
          />
    );
}
