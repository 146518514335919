
const Constant = {
    DRAWER_TEXT_SIZE : "1.2333333vw",
    HEADER_WEB : "2vw",
    HEADER_MOBILE : "4vw",

    FONT_SIZE_WEB : "1.1vw",
    FONT_SIZE_MOBILE : "2vw",

    FONT_SIZE_DROPDOWN_WEB : "0.9375vw",
    FONT_SIZE_DROPDOWN_MOBILE : "1.6vw",

    ATTACHMENT_ICON_SIZE_WEB : "2.5vw",
    ATTACHMENT_ICON_SIZE_MOBILE : "4.5vw",

    BUTTON_FONT_SIZE_WEB : "1.1vw",
    BUTTON_FONT_SIZE_MOBILE : "2vw",

    COLOR_BLACK_500 : "#070707",
    COLOR_BLACK_400 : "#707070",
    COLOR_BLACK_300 : "#9A9A9A",
    COLOR_ATTACHMENT : "#c7c7c7"
}

export default Constant;
