import React from 'react';
import Button from '@material-ui/core/Button';
import {useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export default  function CentralizeButton(props) {
  const [fontSize, setFontSize] = useState("0.9375vw");
  const [marginRight, setMarginRight] = useState("10%");
  const [borderRadius, setBorderRadius] = useState("5px");
  const [padding, setPadding] = useState("6.8px 35.5px");
  const [backgroundImage, setBackgroundImage] = useState("linear-gradient(to bottom, #2699fb, #134d7e)");
  const [color, setColor] = useState("#ffffff");
  const [fullWidthButton, setFullWidthButton] = useState(false);

  const useStyles = makeStyles((theme) => ({
          root:{
            marginRight : marginRight,
          },
          button :{
            height: "48px",
            width: props.width || "184px",
            padding: padding,
            borderRadius: borderRadius,
            backgroundImage: backgroundImage,
            fontSize: fontSize,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            color: color,
            textTransform: "none",
            flex:"1",
          },

          rootFullWidth:{
            marginRight : marginRight,
            flex:"1",
          },
          buttonFullWidth :{
            height: "48px",
            width : "100%",
            padding: padding,
            borderRadius: borderRadius,
            backgroundImage: backgroundImage,
            fontSize: fontSize,
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            color: color,
            textTransform: "none",
            flex:"1",
          },
  }));

  const classes = useStyles();

  useEffect(() => {
    if(props.fontSize !== undefined)
      setFontSize(props.fontSize);
    }, [props.fontSize]);

    useEffect(() => {
      if(props.marginRight !== undefined)
        setMarginRight(props.marginRight);
      }, [props.marginRight]);

      useEffect(() => {
        if(props.borderRadius !== undefined)
          setBorderRadius(props.borderRadius);
        }, [props.borderRadius]);

        useEffect(() => {
          if(props.padding !== undefined)
            setPadding(props.padding);
          }, [props.padding]);

          useEffect(() => {
            if(props.backgroundImage !== undefined)
              setBackgroundImage(props.backgroundImage);
            }, [props.backgroundImage]);

            useEffect(() => {
              if(props.color !== undefined)
                setColor(props.color);
              }, [props.color]);

              useEffect(() => {
                if(props.fullWidthButton !== undefined)
                  setFullWidthButton(props.fullWidthButton);
                }, [props.fullWidthButton]);

  return (
    <div className = {fullWidthButton ? classes.rootFullWidth : classes.root}>
    <Button variant="contained" className = {fullWidthButton ? classes.buttonFullWidth : classes.button}
      disabled = {props.isButtonDisable}
      onClick={() => {
        if(props.onClickCallback != undefined){
          props.onClickCallback();
        }
       }}>
       {props.lable}
    </Button>
    </div>
    );
}
