import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainedButtons from '../../../../Components/Button/Button';
import { APIHelper } from '../../../../utils/ApiHelper';
import API from '../../../../utils/Urls';
import CircularProgress from '@material-ui/core/CircularProgress';

const CancelApplicationFormHourly = (props) => {
    const [data, setData] = React.useState(props.contractData);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
            .then((res) => {
                setData(res[0]);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <React.Fragment>
            <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                        Job Cancellation Request
                    </h3>
                </div>
                {loading ? (
                    <div style={{ textAlign: 'center', marginTop: 200 }}>
                        <CircularProgress size={40} />
                    </div>
                ) : data !== undefined ? (
                    <div style={{ marginTop: 64, fontSize: 20 }}>
                        <div style={{ display: 'flex' }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Contract No:</p>
                            <p>{data.contractNumber}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Client:</p>
                            {/*TODO*/}
                            {/* <div>ABC Co.</div> */}
                            <AccountCircleIcon
                                style={{
                                    height: '70px',
                                    width: '70px',
                                }}
                            />
                            <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                                {data.employerProfileData &&
                                    data.employerProfileData.length > 0 &&
                                    data.employerProfileData[0].company &&
                                    data.employerProfileData[0].company.name}
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                            <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Talent Name:</p>
                            <p>{`${data.employeeData[0].firstName} ${data.employeeData[0].lastName}`}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                            <p>{data.jobs[0].title}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>
                                Cancellation date and time:
                            </p>
                            <p>{moment(data.updatedAt).format('hh:mm A - MMM DD, YY')}</p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work Start Date:</p>
                            <p>{moment(data.jobs[0].startDate).utc(0).format('MMM DD, YYYY')}</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 30,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: 46,
                            }}
                        >
                            <p style={{ width: 350, color: '#9a9a9a' }}>Work End Date:</p>
                            <p>{moment(data.jobs[0].endDate).utc(0).format('MMM DD, YYYY')}</p>
                        </div>
                        <div
                            style={{
                                marginTop: 59,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: '50px',
                            }}
                        >
                            <p style={{ minWidth: 350, color: '#9a9a9a' }}>Cancellation Reason:</p>
                            <p style={{ marginTop: 15 }}>{data.reasonType && data.reasonType}</p>
                            {data.reason && (
                                <>
                                    <p style={{ minWidth: 350, color: '#9a9a9a', marginTop: 30 }}>
                                        Cancellation reason:
                                    </p>
                                    <p style={{ marginTop: 15 }}>{data.reason}</p>
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                marginTop: 59,
                                borderBottom: '2px solid #C4C4C4',
                                paddingBottom: '59px',
                            }}
                        >
                            <p style={{ marginTop: 15 }}>
                                Talent’s timesheet for the work done to date will be submitted as
                                planned. Please ensure a proper review of it.
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '41px 30px',
                                backgroundColor: '#d5d5d5',
                                borderRadius: 10,
                                marginTop: 59,
                            }}
                        >
                            <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                                Note: This cancellation as submitted by talent is final. You are
                                requested to provide your opinion below as Yes or No as it will
                                impact talent’s credit rating.
                            </p>
                        </div>
                        <div style={{ marginTop: 60 }}>
                            <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                                Are you in agreement with the talent?
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <div style={{ marginRight: 10 }}>
                                <ContainedButtons
                                    text="No"
                                    color={['#2699fb', '#134d7e']}
                                    height="56px"
                                    width={'12vw'}
                                    variant="outlined"
                                    onClick={() => props.finalCancelContractJob(props.contractId)}
                                />
                            </div>
                            <ContainedButtons
                                text="Yes"
                                height="56px"
                                width={'12vw'}
                                color={['#2699fb', '#134d7e']}
                                onClick={() => props.finalCancelJob(props.contractId)}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

export default CancelApplicationFormHourly;
