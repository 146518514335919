import { SHOW_UNREAD_MESSAGES, HIDE_UNREAD_MESSAGES } from '../action/messages_action';

const initialState = {
  showUnreadMessages: false,
}

function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_UNREAD_MESSAGES:
      return { ...state, showUnreadMessages: true }
    case HIDE_UNREAD_MESSAGES:
      return { ...state, showUnreadMessages: false }
    default:
      return state
  }
}
export default messagesReducer;
