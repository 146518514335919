import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import '../../../Pages/Register/ThankyouMessage/ThankyouMessage.scss';
import '../../../Assets/dev-style.scss';
import '../../InitialPages/TalentSearch/TalentSearch.scss';

import AutoCompleteCard from '../../../Components/AutoCompleteCard/AutoCompleteCard'
import { APIHelper } from "../../../utils/ApiHelper";
import API from '../../../utils/Urls'
import { SHOW_HEADER } from '../../../action/talent_search_action'
import { DASHBOARD } from '../../../utils/enums';
import CONSTANTS from '../../../utils/constants';

const EmployeeSearchTalent = (props) => {
    const [isSearchTermCalled, setIsSearchTermCalled] = React.useState(false);
    const [isReloaded, setIsReloaded] = React.useState(false);
    const [searchOptions, setSearchOptions] = React.useState([])
    const [activePart, setActivePart] = React.useState();
    const [autoFocusCard, setAutoFocusCard] = React.useState(false);
    const [textFilter, setTextFilter] = React.useState('');
    const [key, setKey] = React.useState(0);
    const [autoCompleteValue, setautoCompleteValue] = React.useState(null);

    const useStylesCard = makeStyles({
        root: {
            width: "50.10vw",
            backgroundColor: '#f7f7f7',
            borderRadius: "1.041vw",
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });

    useEffect(() => {
        if (!isReloaded) {
            setIsReloaded(true)
        }
        props.showHeader()

        console.log(props.shouldShowHeader)
    }, [])

    const cardClasses = useStylesCard();
    const history = useHistory();

    const handleOnSearchClick = () => {
        if (textFilter === "") {
            setAutoFocusCard(true);
        } else {
            history.push(CONSTANTS.EMPLOYEE_ADVANCED_SEARCH_TALENT);
        }
    }

    const handleSearchChange = (e) => {
        if (e.length >= 2) {
            setIsSearchTermCalled(true)

            let headers = {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }

            APIHelper.getMethod(
                API.TALENT_SEARCH_BY_SEARCH_TERM + e, headers
            ).then((response) => {
                console.log(response);

                if (response.length > 0) {
                    setIsSearchTermCalled(false)
                    let searchOptions = response.map(item => {
                        return { label: item.name, value: item.name, _id: item._id }
                    })

                    setSearchOptions(searchOptions)

                }
                if (response["status"] === 0) {
                    setIsSearchTermCalled(false);
                }
            }).catch((err) => {
                setIsSearchTermCalled(false);
            })
        }
    }

    return (
        <div>
            <div className="myImage">
                <div style={{ paddingTop: "6.45vw", paddingLeft: "25vw" }}>
                    <Card className={cardClasses.root}>
                        <div className="row searchedJob" style={{ height: 75, alignItems: 'center', backgroundColor: '#FFF' }}>
                            <div className="col-md-10 col-sm-12">
                                <div className="autoSearchTextBox" key={key} style={{ width: '96%' }}>
                                    <AutoCompleteCard
                                        isSearchJob
                                        inputValue={textFilter}
                                        autoFocus={autoFocusCard}
                                        key={`dropdown${autoFocusCard}`}
                                        placeholder={"Search for job"}
                                        value={autoCompleteValue}
                                        onInputChange={(value, action) => {
                                            // only set the input when the action that caused the
                                            // change equals to "input-change" and ignore the other
                                            // ones like: "set-value", "input-blur", and "menu-close"
                                            if (action.action === "input-change") {
                                                setTextFilter(value);
                                                localStorage.setItem('talentSearchData', JSON.stringify(value));
                                                handleSearchChange(value);
                                            }
                                        }}
                                        options={searchOptions}
                                        onChange={(e) => {
                                            setautoCompleteValue(e);
                                            setTextFilter(e.label);
                                            setKey(Math.random());
                                            localStorage.setItem('talentSearchData', JSON.stringify(e.label));
                                        }}
                                        onFocus={() => setActivePart(DASHBOARD.AUTO_COMPLETE)}
                                        active={activePart === DASHBOARD.AUTO_COMPLETE}
                                        isEmployee
                                        loading={isSearchTermCalled}
                                    />
                                </div>
                            </div>
                            <div
                                onClick={() => handleOnSearchClick()}
                                className="col-md-2 col-sm-12"
                                style={{ cursor: "pointer", borderLeft: "1px solid #C4C4C4", height: 75, lineHeight: '85px', textAlign: 'center' }}
                            >
                                <SearchIcon fontSize={"inherit"} style={{ width: 22, height: 22 }} />
                            </div>
                        </div>
                    </Card>
                </div>
                <p style={{ backgroundColor: 'transparent', color: '#FFF', fontSize: 50, width: 370, marginLeft: 100, marginBottom: 200, marginTop: '15vw', fontFamily: 'Libre Franklin' }}>Find the perfect job!</p>
            </div>
            {/* <div><Footer/></div> */}
        </div >
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        showHeader: () => dispatch({ type: SHOW_HEADER }),
    }
}

const mapStateToProps = function (state) {
    return {
        shouldShowHeader: state.talentSearchReducer.showHeader,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSearchTalent);
