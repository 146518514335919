// src/Pages/InitialPages/Pricing/Pricing.jsx

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderPublic from '../../../Components/HeaderPublic/HeaderPublic';
import FooterPublic from '../../../Components/FooterPublic/FooterPublic';
import './Pricing.scss';
import { Helmet } from 'react-helmet';

class Pricing extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink Pricing - Affordable and Flexible Plans for Clients and Talents</title>
                    <meta name="keywords" content="Empolink Pricing, Flexible Pricing, Service Fees, Pricing Plans, Affordable Rates, Empolink Plans, No Platform Fee, Platform Commission Fee" />
                    <meta name="description" content="Empolink offers affordable and flexible pricing plans for clients and talents. Explore our subscription options and find the perfect plan for your needs." />

                    <meta property="og:title" content="Empolink Pricing: Affordable and Flexible Plans" />
                    <meta property="og:description" content="Explore Empolink's affordable and flexible pricing options for clients and talents. Find the perfect subscription plan for your needs." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com/Pricing" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Empolink Pricing: Affordable and Flexible Plans" />
                    <meta name="twitter:description" content="Discover Empolink's flexible pricing plans for clients and talents. Find the subscription option that fits your needs." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com/Pricing" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>
                </Helmet>
                <HeaderPublic forceScrolled={false} />
                <div className="pricing-container">
                    <h1>Platform Fees</h1>
                    <div className="pricing-sections">
                        <div className="pricing-box">
                            <h2>For Clients</h2>
                            <p className="description"></p>
                            <p>Platform fee for clients is 5.0% of the contract value.</p>
                            <p>For contracts where payment is defined as "direct", the platform service fee will be charged against your credit card.</p>
                            <p>For contracts where payment is defined as "through empolink", the platform service fee will be added to the contract value and charged against your credit card.</p>
                        </div>
                        <div className="pricing-box">
                            <h2>For Talents</h2>
                            <p className="description"></p>
                            <p>Platform does not charge any service fee for talents.</p>
                            <p>However, for contracts where payment is defined as "through empolink", financial company will charge a service fee to transfer the payment to your Deposit Account.</p>
                            <p>This service fee is about 3.5% of the contract value which will be deducted and the remainder will be deposited into your Deposit Account.</p>
                        </div>
                    </div>
                </div>
                <FooterPublic />
            </>
        );
    }
}

export default withRouter(Pricing);