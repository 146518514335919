import { LOADING, ERROR,  SUCCESS, UPDATE_CHIPS, CLEAR} from '../action/suggested_skills_action';


const initialState = {
    status : "Ideal",
    data : [],
    errorMessage : "",
  }

function suggestedSkillsReducer(state = initialState, action){
  switch (action.type) {

    case LOADING:
        return { ...state,status :  "LOADING"}

    case ERROR:
          return { ...state,status :  "ERROR",errorMessage : action.errorMessage.toString()}

    case SUCCESS:
            return { ...state,status :   "SUCCESS",data : action.data}

    case CLEAR:
        return { ...state, status : "Ideal" ,data : []}

    default:
      return state
  }
}
export default suggestedSkillsReducer
