import React from 'react';
import moment from 'moment';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import TextField from '../../../Components/TextField/Textfield';
import StatisticsReport from './StatisticsReport';
import Card from '@material-ui/core/Card';
import ContainedButtons from '../../../Components/Button/Button';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MonthYearPicker from '../../../Components/MonthPicker/MonthPicker';

import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
} from 'recharts';
import { CustomPieChart } from './Overview';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import DateFnsUtils from '@date-io/date-fns';
import '../DashboardOverview.scss';
const COLORS = ['#DEB136', '#1876D2'];
const COLORS2 = ['#FF7E00', '#621EEE'];

const RADIAN = Math.PI / 180;

const Statistics = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const [startDate, setStartDate] = React.useState('01-1970');
    const [endDate, setEndDate] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [buttonColor, setButtonColor] = React.useState(() => {
        return ['#2493F1', '#15568D'];
    });

    React.useEffect(() => {
        getStatisticsData();
    }, []);

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={'middle'}
                dominantBaseline="central"
                width={75}
            >
                <tspan class="text" x={x} y={y}>
                    {data &&
                        data.jobsPieChartData &&
                        data.jobsPieChartData.local &&
                        data.jobsPieChartData.local[index] &&
                        data.jobsPieChartData.local[index].name}
                </tspan>
                <tspan class="text" x={x} y={y} dy="22">
                    ({localStorage.getItem('localCurrency')}){' '}
                    {data &&
                        data.jobsPieChartData &&
                        data.jobsPieChartData.local &&
                        data.jobsPieChartData.local[index] &&
                        data.jobsPieChartData.local[index].value}
                </tspan>
            </text>
        );
    };

    const renderInternationalCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={'middle'}
                dominantBaseline="central"
                width={75}
            >
                <tspan class="text" x={x} y={y}>
                    {data &&
                        data.jobsPieChartData &&
                        data.jobsPieChartData.international &&
                        data.jobsPieChartData.international[index] &&
                        data.jobsPieChartData.international[index].name}
                </tspan>
                <tspan class="text" x={x} y={y} dy="22">
                    {'$'}
                    {data &&
                        data.jobsPieChartData &&
                        data.jobsPieChartData.international &&
                        data.jobsPieChartData.international[index] &&
                        data.jobsPieChartData.international[index].value}
                </tspan>
            </text>
        );
    };

    const getStatisticsData = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        let start = moment(
            `${parseInt(startDate.split('-')[1])}-${parseInt(startDate.split('-')[0])}-01`
        ).add(1, 'day');
        let end = endDate
            ? new Date(parseInt(endDate.split('-')[1]), parseInt(endDate.split('-')[0]), 1, 0, 0, 0)
            : null;
        const data = {
            startDate: start,
            endDate: end,
        };
        APIHelper.postMethod(`${API.GET_EMPLOYER_STATISTICS}`, data, headers)
            .then((response) => {
                setData(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };


    return (
        <div style={{padding:'0 40px'}}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                }}
            >
                <h1 style={{ fontSize: '36px', color: '#070707', margin: 0, fontWeight: 'normal' }}>
                    Statistics
                </h1>
                <ContainedButtons
                    text="View Report"
                    color={buttonColor}
                    height={48}
                    width={'12vw'}
                    onClick={() => {
                        setDrawerOpen(true);
                    }}
                />
            </div>
            <div className="card-override" style={{ paddingRight: 100 }}>
                <Card style={{ marginTop: '50px', borderRadius: '8px', padding: 30 }}>
                    <div className="row">
                        <div
                            className="col-md-4"
                            style={{ color: '#9a9a9a', display: 'flex', alignItems: 'center' }}
                        >
                            <p style={{ margin: '0' }}>Period Start Date:</p>
                            <div style={{ width: '150px', marginLeft: 10, zIndex:100 }} className='monthYearPicker'>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MonthYearPicker
                                        startMonth={1}
                                        startYear={1970}
                                        fieldLabel={'From'}
                                        endMonth={new Date().getMonth() + 1}
                                        endYear={new Date().getFullYear()}
                                        onSelected={(val) => {
                                            setStartDate(val);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div
                            className="col-md-4"
                            style={{
                                color: '#9a9a9a',
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: 10,
                            }}
                        >
                            <p style={{ margin: '0' }}>Period End Date:</p>
                            <div style={{ width: '150px', marginLeft: 10 }} className='monthYearPicker'>
                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <MonthYearPicker
                                        startMonth={1}
                                        startYear={1970}
                                        fieldLabel={'To'}
                                        endMonth={moment().add(3, 'months').month()}
                                        endYear={moment().add(3, 'months').year()}
                                        onSelected={(val) => {
                                            setEndDate(val);
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="col-md-3" style={{ marginLeft: 85 }}>
                            <ContainedButtons
                                text="Apply"
                                color={buttonColor}
                                height={40}
                                width={'12vw'}
                                onClick={() => {
                                    getStatisticsData();
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </div>
            <div className="row" style={{ marginTop: 50, height: 400 }}>
                <div className="col-md-3">
                    <Card style={{ width: '94%' }}>
                        <div style={{ height: 400, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ flex: 1 }}>
                                <p
                                    style={{
                                        fontSize: 22,
                                        fontFamily: 'Libre Franklin',
                                        color: '#9a9a9a',
                                        textAlign: 'center',
                                        paddingTop: 20,
                                    }}
                                >
                                    Local Jobs
                                </p>
                                <p
                                    style={{
                                        fontSize: 30,
                                        fontFamily: 'Libre Franklin',
                                        color: '#070707',
                                        textAlign: 'center',
                                        paddingTop: 20,
                                    }}
                                >
                                    {data && data.localJobsTotal} <br />(
                                    {localStorage.getItem('localCurrency')})
                                </p>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    borderTop: '1px solid rgba(196, 196, 196, 0.39)',
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 22,
                                        fontFamily: 'Libre Franklin',
                                        color: '#9a9a9a',
                                        textAlign: 'center',
                                        paddingTop: 20,
                                    }}
                                >
                                    International Jobs
                                </p>
                                <p
                                    style={{
                                        fontSize: 30,
                                        fontFamily: 'Libre Franklin',
                                        color: '#070707',
                                        textAlign: 'center',
                                        paddingTop: 20,
                                    }}
                                >
                                    ${data && data.internationalJobsTotal}
                                    (USD)
                                </p>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card style={{ width: '96%' }}>
                        <div style={{ height: 400 }}>
                            <p
                                style={{
                                    fontSize: 22,
                                    fontFamily: 'Libre Franklin',
                                    color: '#070707',
                                    textAlign: 'center',
                                    paddingTop: 20,
                                }}
                            >
                                Local Jobs{' '}
                                <span style={{ color: 'red' }}>
                                    ({localStorage.getItem('localCurrency')})
                                </span>
                            </p>
                            {/* {localJobs.length > 0 && localJobs[0].value === localJobs[1].value ? (
                                <p style={{ textAlign: 'center', marginTop: 50, fontSize: 18 }}>
                                    No data found.
                                </p>
                            ) : ( */}
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={data && data.jobsPieChartData.local}
                                        cx="50%"
                                        cy="50%"
                                        // labelLine={true}
                                        outerRadius={120}
                                        fill="#8884d8"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        dataKey="value"
                                    >
                                        {data &&
                                            data.jobsPieChartData.local.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            {/* )} */}
                        </div>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card style={{ width: '96%' }}>
                        <div style={{ height: 400 }}>
                            <p
                                style={{
                                    fontSize: 22,
                                    fontFamily: 'Libre Franklin',
                                    color: '#070707',
                                    textAlign: 'center',
                                    paddingTop: 20,
                                }}
                            >
                                International Jobs (USD)
                            </p>
                            {/* {internationalJobs.length > 0 &&
                            internationalJobs[0].value === internationalJobs[1].value ? (
                                <p style={{ textAlign: 'center', marginTop: 50, fontSize: 18 }}>
                                    No data found.
                                </p>
                            ) : ( */}
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={data && data.jobsPieChartData.international}
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        fill="#8884d8"
                                        labelLine={false}
                                        label={renderInternationalCustomizedLabel}
                                        dataKey="value"
                                    >
                                        {data &&
                                            data.jobsPieChartData.international.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={COLORS2[index % COLORS2.length]}
                                                    />
                                                )
                                            )}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                            {/* )} */}
                        </div>
                    </Card>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 50,
                    width: '90%',
                }}
            >
                <Card
                    style={{ width: '49%', marginRight: 20, borderRadius: 8, padding: '30px 15px' }}
                >
                    <div style={{ height: 350 }}>
                        <p
                            style={{
                                fontSize: 19,
                                fontFamily: 'Libre Franklin',
                                color: '#070707',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            Local Jobs{' '}
                            <span style={{ color: '#9a9a9a' }}>
                                ({localStorage.getItem('localCurrency')})
                            </span>
                        </p>
                        <ResponsiveContainer width="99%" height={270}>
                            <BarChart
                                data={data && data.monthlyLocalJobs}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#621EEE" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
                <Card style={{ width: '49%', borderRadius: 18, padding: '30px 15px' }}>
                    <div style={{ height: 350 }}>
                        <p
                            style={{
                                fontSize: 19,
                                fontFamily: 'Libre Franklin',
                                color: '#070707',
                                textAlign: 'center',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                        >
                            International Jobs <span style={{ color: '#9a9a9a' }}>(USD)</span>
                        </p>
                        <ResponsiveContainer width="99%" height={270}>
                            <BarChart
                                data={data && data.monthlyInternationalJobs}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#DEB136" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>
            <div
                style={{
                    borderTop: '2px solid rgba(196, 196, 196, 0.39)',
                    width: '90%',
                    margin: '25px 0',
                    paddingTop: 15,
                }}
            >
                <h1 style={{ color: '#070707', fontWeight: 'normal' }}>Hourly Job Statistics</h1>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '38%' }}>
                        <Card style={{ borderRadius: 8, padding: '35px 20px', height: 300 }}>
                            <p style={{ color: '#9a9a9a', fontWeight: 600 }}>
                                Total Hours (Hourly Jobs Only)
                            </p>
                            <div style={{ display: 'flex', margin: '10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                <p className="value">
                                    {data && data.hourlyJobsStatisticsData.totalHoursLocal} hr
                                </p>
                            </div>
                            <div style={{ display: 'flex', margin: '10px 0 50px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                <p className="value">
                                    {data && data.hourlyJobsStatisticsData.totalHoursInternational}{' '}
                                    hr
                                </p>
                            </div>
                            <div style={{ borderTop: '1px solid rgba(196, 196, 196, 0.39)' }}>
                                <p style={{ color: '#9a9a9a', fontWeight: 600, marginTop: 40 }}>
                                    Average Rate
                                </p>
                                <div style={{ display: 'flex', margin: '10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                    <p className="value">{data && parseFloat(data.hourlyJobsStatisticsData.averageLocal).toFixed(2) || 0}</p>
                                </div>
                                <div style={{ display: 'flex', margin: '10px 0 40px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                    <p className="value">{data && parseFloat(data.hourlyJobsStatisticsData.averageInternational).toFixed(2) || 0}</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div style={{ width: '60%' }}>
                        <Card style={{ borderRadius: 8, padding: '24px 15px' }}>
                            <p style={{ textAlign: 'center', paddingBottom: 10 }}>Total Hours</p>
                            <ResponsiveContainer width="100%" height={290}>
                                <BarChart
                                    data={data && data.hourlyJobsStatisticsData.totalHours}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Local" fill="#621EEE" />
                                    <Bar dataKey="International" fill="#CFB2F9" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </div>
                </div>
            </div>
            <div
                style={{
                    borderTop: '2px solid rgba(196, 196, 196, 0.39)',
                    width: '90%',
                    margin: '25px 0',
                    paddingTop: 15,
                }}
            >
                <h1 style={{ color: '#070707', fontWeight: 'normal' }}>Workforce Statistics</h1>
                <div
                    className="row"
                    style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}
                >
                    <div style={{ width: '38%' }}>
                        <Card style={{ borderRadius: 8, padding: '35px 20px', height: 300 }}>
                            <p style={{ color: '#9a9a9a', fontWeight: 500 }}>
                                Total number of employees
                            </p>
                            <div style={{ display: 'flex', margin: '10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                <p className="value">
                                    {data && data.workforceStatistics.totalLocalEmployees}
                                </p>
                            </div>
                            <div style={{ display: 'flex', margin: '10px 0 50px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                <p className="value">
                                    {data && data.workforceStatistics.totalInternationalEmployees}
                                </p>
                            </div>
                        </Card>
                    </div>
                    <div style={{ width: '60%' }}>
                        <Card style={{ borderRadius: 8, padding: '24px 15px' }}>
                            <p style={{ textAlign: 'center', paddingBottom: 10 }}>
                                Number of Talents
                            </p>
                            <ResponsiveContainer width="100%" height={290}>
                                <BarChart
                                    data={data && data.workforceStatistics.workforceBarChartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Local" fill="#621EEE" />
                                    <Bar dataKey="International" fill="#CFB2F9" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </div>
                </div>
            </div>
            <div
                style={{
                    borderTop: '2px solid rgba(196, 196, 196, 0.39)',
                    width: '90%',
                    margin: '25px 0',
                    paddingTop: 15,
                }}
            >
                <h1 style={{ color: '#070707', fontWeight: 'normal' }}>Fixed Fee Job Statistics</h1>
                <div
                    className="row"
                    style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}
                >
                    <div style={{ width: '38%' }}>
                        <Card style={{ borderRadius: 8, padding: '35px 20px', height: 370 }}>
                            <p style={{ color: '#9a9a9a', fontWeight: 600 }}>
                                Total number of fixed fee jobs
                            </p>
                            <div style={{ display: 'flex', margin: '10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                <p className="value">
                                    {data && data.fixedFeeJobsStatistics.totalFixedFeeJobs.local}
                                </p>
                            </div>
                            <div style={{ display: 'flex', margin: '10px 0 10px 0' }}>
                                <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                <p className="value">
                                    {data &&
                                        data.fixedFeeJobsStatistics.totalFixedFeeJobs.international}
                                </p>
                            </div>
                            <div
                                style={{
                                    borderTop: '1px solid rgba(196, 196, 196, 0.39)',
                                    paddingTop: 10,
                                }}
                            >
                                <p style={{ color: '#9a9a9a', fontWeight: 600 }}>
                                    Total number of new fixed fee jobs
                                </p>
                                <div style={{ display: 'flex', margin: '10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                    <p className="value">
                                        {data &&
                                            data.fixedFeeJobsStatistics.totalNewFixedFeeJobs.local}
                                    </p>
                                </div>
                                <div style={{ display: 'flex', margin: '10px 0 10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                    <p className="value">
                                        {data &&
                                            data.fixedFeeJobsStatistics.totalNewFixedFeeJobs
                                                .international}
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    borderTop: '1px solid rgba(196, 196, 196, 0.39)',
                                    paddingTop: 10,
                                }}
                            >
                                <p style={{ color: '#9a9a9a', fontWeight: 600 }}>
                                    Total number of ongoing fee jobs
                                </p>
                                <div style={{ display: 'flex', margin: '10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                    <p className="value">
                                        {data &&
                                            data.fixedFeeJobsStatistics.totalOngoingFixedFeeJobs
                                                .local}
                                    </p>
                                </div>
                                <div style={{ display: 'flex', margin: '10px 0 10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                    <p className="value">
                                        {data &&
                                            data.fixedFeeJobsStatistics.totalOngoingFixedFeeJobs
                                                .international}
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    borderTop: '1px solid rgba(196, 196, 196, 0.39)',
                                    paddingTop: 10,
                                }}
                            >
                                <p style={{ color: '#9a9a9a', fontWeight: 600 }}>
                                    Total number of completed fee jobs
                                </p>
                                <div style={{ display: 'flex', margin: '10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>Local:</p>
                                    <p className="value">
                                        {data &&
                                            data.fixedFeeJobsStatistics.totalCompletedFixedFeeJobs
                                                .local}
                                    </p>
                                </div>
                                <div style={{ display: 'flex', margin: '10px 0 10px 0' }}>
                                    <p style={{ color: '#9a9a9a', width: '80%' }}>International:</p>
                                    <p className="value">
                                        {data &&
                                            data.fixedFeeJobsStatistics.totalCompletedFixedFeeJobs
                                                .international}
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div style={{ width: '60%' }}>
                        <Card style={{ borderRadius: 8, padding: '24px 15px', height: 390 }}>
                            <p style={{ textAlign: 'center', paddingBottom: 50 }}>
                                Number of Fixed Fee Jobs
                            </p>
                            <ResponsiveContainer width="100%" height={290}>
                                <BarChart
                                    data={data && data.fixedFeeJobsStatistics?.fixedFeeJobChartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Local" fill="#621EEE" />
                                    <Bar dataKey="International" fill="#CFB2F9" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card>
                    </div>
                </div>
            </div>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <StatisticsReport
                        onClose={() => setDrawerOpen(false)}
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        COLORS={COLORS}
                        COLORS2={COLORS2}
                        renderCustomizedLabel={renderCustomizedLabel}
                        renderInternationalCustomizedLabel={renderInternationalCustomizedLabel}
                    />
                }
                width="80%"
            />
        </div>
    );
};

export default Statistics;
