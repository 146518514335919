import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CustomChip from '../Chip/Chip';
import Button from '../Button/Button';
import ScoreChart from '../GaugeChart/GaugeChart';
import Chip from '@material-ui/core/Chip';
import defaultDp from '../../Assets/blankProfile.png';

const useStyles = makeStyles({
    root: {
        width: '60.854vw',
        height: '11.406vw',
        backgroundColor: '#ffffff',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const StyledRating = withStyles({
    iconFilled: {
        color: '#1876d2',
    },
    sizeSmall: {
        fontSize: '1.0416vw',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const WideJobCard = (props) => {
    return (
        <>
            <div style={{ flex: 1, display: 'flex', marginBottom: 16, height: 114 }}>
                <div style={{ flex: 2, display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <img
                            className="wideCardImg"
                            src={props.profilePicture ? props.profilePicture : defaultDp}
                            alt="Avatar"
                            style={{ height: 60, width: 60, borderRadius: 70 }}
                        />
                    </div>
                    <div style={{ flex: 2, marginTop: 20, marginLeft: 10 }}>
                        <p style={{ fontSize: 18, color: '#000', opacity: 0.78, fontWeight: 600 }}>
                            {props.companyName}
                        </p>
                        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
                            <StyledRating
                                name="hover-feedback"
                                size={'small'}
                                value={props.ratings}
                                precision={0.5}
                                readOnly
                                fontSize="inherit"
                            />{' '}
                            <span style={{ color: '#9a9a9a', fontSize: 16 }}>
                                ({props.reviewCount})
                            </span>
                        </div>
                        <p style={{ color: '#9a9a9a', fontSize: 16, marginTop: 13 }}>
                            {props.speciality}
                        </p>
                    </div>
                </div>
                <div style={{ flex: 1.5 }}>
                    <p
                        style={{
                            fontSize: 18,
                            color: '#1c1c1c',
                            marginTop: 20,
                            height: 104,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {props.jobTitle}
                    </p>
                </div>
                <div style={{ flex: 1 }}>
                    <p style={{ fontSize: 18, color: '#1c1c1c', marginTop: 20 }}>
                        {moment(props.startDate).utc(0).format('MMM DD, YY')}
                    </p>
                </div>
                <div style={{ flex: 1 }}>
                    <p style={{ fontSize: 18, color: '#1c1c1c', marginTop: 20 }}>{props.rate}</p>
                </div>
                <div style={{ flex: 1 }}>
                    <p style={{ fontSize: 18, color: '#1c1c1c', marginTop: 20 }}>{props.address}</p>
                </div>
                <div style={{ flex: 1 }}>
                    <p style={{ fontSize: 18, color: '#1c1c1c', marginTop: 20 }}>
                        {moment(props.applicationLastDate).utc(0).format('MMM DD, YY')}
                    </p>
                </div>
                <div style={{ flex: 1 }}>
                    <Button
                        text="Apply"
                        width={'150px'}
                        height="48px"
                        onClick={() => {
                            props.setDrawerOpen(true);
                            props.jobDetails();
                        }}
                        color={['#a4c772', '#4e8400']}
                        disabled={props.disabledBtn}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ flex: 1 }}></div>
                <div style={{ display: 'flex', flex: 11, marginBottom: 25 }}>
                    {props.skills.map((skill, index) => {
                        if (index <= 3) {
                            return (
                                <Chip
                                    variant="outlined"
                                    style={{ width: 126, marginRight: 14 }}
                                    label={skill.name}
                                />
                            );
                        }
                    })}
                </div>
            </div>
            <div
                style={{ height: 1, backgroundColor: '#707070', opacity: 0.5, marginBottom: 20 }}
            />
        </>
    );
};

export default WideJobCard;
