import React from 'react';
import { connect } from 'react-redux';
import Spacer from '../../../components/spacer'
import Text from '../../../components/text'
import InputText from '../../../components/centralize_input_text'
import DropDownList from '../../../components/centralize_dropdown'
import ToggleButton from '../../../components/centralize_toggle_button'
import DatePicker from '../../../components/centralize_date_picker';
import RangeDatePicker from '../../../components/centralize_range_date_picker';
import TimePicker from '../../../components/centralize_time_picker';
import RadioGroup from '../../../components/custom_radio_group';
import Button from '../../../components/centralize_button';
import DataTable from '../../../components/centralize_data_table';
import Constant from '../../../Constant/constant'

import * as moment from 'moment';

import { NEXT_BUTTON_ENABLE, JOB_START_DATE, JOB_CLOSE_DATE, JOB_POST_CLOSE_DATE } from '../../../../../action/form_action';

import {
  VERTICAL_NORMAL, VERTICAL_EXTRA,
  VERTICAL_SMALL, VERTICAL_EXTRA_LARGE, HORIZONTAL_NORMAL, HORIZONTAL_EXTRA,
  HORIZONTAL_SMALL, HORIZONTAL_ULTRA_SMALL, SUBHEADING_WITH_BORDER
} from '../../../../../utils/enums';



class WorkSchedule extends React.Component {
  render() {
    const { jobStartDateFormat, jobCloseDateFormat, jobPostCloseDateFormat, error } = this.props;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)

    return (
      <>
        <Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_500} text="Specify start date of project" />
        <Spacer spaceType={VERTICAL_SMALL} />
        <DatePicker
          selectedDate={jobStartDateFormat}
          fontSize={Constant.FONT_SIZE_WEB}
          width="50%"
          startDate={new Date(moment().add(3, "days").toISOString())}
          endDate={new Date(moment().add(3, "months").toISOString())}
          error={error.jobStartDateFormat || false}
          lable={jobStartDateFormat === undefined ? "Select Start Date" : "Start Date"}
          onChangeCallback={date => {
            this.props.jobStartDate(date);
          }} />

        <Spacer spaceType={VERTICAL_EXTRA} />

        <Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_500} text="Specify end date of project" />
        <Spacer spaceType={VERTICAL_SMALL} />

        <DatePicker
          fontSize={Constant.FONT_SIZE_WEB}
          width="50%"
          lable={jobCloseDateFormat === undefined ? "Select End Date" : "End Date"}
          selectedDate={jobCloseDateFormat}
          error={error.jobCloseDateFormat || false}
          endDate={new Date(moment().add(3, "M").toLocaleString())}
          startDate={jobStartDateFormat === undefined ? new Date(moment().toISOString()) : new Date(moment(jobStartDateFormat).toISOString())}
          readOnly={jobStartDateFormat === undefined ? true : false}
          onChangeCallback={date => {
            this.props.jobCloseDate(date);
          }} />

        <Spacer spaceType={VERTICAL_EXTRA} />

        <Text fontSize={Constant.FONT_SIZE_WEB} color={Constant.COLOR_BLACK_500} text="Specify when job will expire: " />
        <Spacer spaceType={VERTICAL_SMALL} />
        <DatePicker
          fontSize={Constant.FONT_SIZE_WEB}
          error={error.jobPostCloseDateFormat || false}
          width="70%"
          lable={jobPostCloseDateFormat === undefined ? "Select Job Closing Date" : "Job Closing Date"}
          selectedDate={jobPostCloseDateFormat}
          startDate={tomorrow}
          endDate={jobStartDateFormat === undefined ? new Date(moment().toISOString()) : new Date(moment(jobStartDateFormat).subtract(1, "days").toISOString())}
          readOnly={jobStartDateFormat === undefined ? true : false}
          onChangeCallback={date => {
            this.props.jobPostCloseDate(date);
          }} />

        <Spacer spaceType={VERTICAL_EXTRA} />
      </>
    );
  }
}


const mapStateToProps = function (state) {
  return {
    jobStartDateFormat: state.formReducer.jobStartDate,
    jobCloseDateFormat: state.formReducer.jobCloseDate,
    jobPostCloseDateFormat: state.formReducer.jobPostCloseDate,
    error: state.formReducer.error || {},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    jobStartDate: (value) => dispatch({ type: JOB_START_DATE, jobStartDate: value }),
    jobCloseDate: (value) => dispatch({ type: JOB_CLOSE_DATE, jobCloseDate: value }),
    jobPostCloseDate: (value) => dispatch({ type: JOB_POST_CLOSE_DATE, jobPostCloseDate: value }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkSchedule);
