import { SHOW_HEADER, HIDE_HEADER } from '../action/talent_search_action';


const initialState = {
    showHeader : false,
  }

function talentSearchReducer(state = initialState, action){
  switch (action.type) {

    case SHOW_HEADER:
        
        return { ...state, showHeader: true}
    case HIDE_HEADER: 

        return { ...state, showHeader: false}
    default:
      return state
  }
}
export default talentSearchReducer;
