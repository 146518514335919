import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { APIHelper } from '../../utils/ApiHelper';
import API from '../../utils/Urls';
import Button from '../../Components/Button/Button';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [apiCall, setApiCall] = useState(false);

    const actionAuthorize = async (event) => {
        event.preventDefault();
        setApiCall(true);
        if (elements == null) {
            return;
        }
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            // console.log(result.error.message);
            setApiCall(false);
            Swal.fire({
                customClass: 'Alert-Class',
                title: 'Error!',
                text: result.error.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1876D2',
            });
        } else {
            //return;
            let exp_month = result.token.card.exp_month;
            let exp_year = result.token.card.exp_year;
            let last_4 = result.token.card.last4;
            let brand = result.token.card.brand;
            //let last_4 = result.token.card.last4;
            await processCardToken(
                result.token.id,
                exp_month,
                exp_year,
                last_4,
                brand
            );
            return;
        }
    };

    async function processCardToken(TokenId, exp_month, exp_year, last_4, brand) {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.postMethod(
            API.ADD_CARD_WITH_STRIPE,
            { card_token: TokenId, card_number: last_4, exp_month, exp_year, card_brand: brand },
            headers
        )
            .then((res) => {
                if (res !== undefined && res !== null && res !== '') {
                    localStorage.setItem("card_expired", "false")
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: 'Card saved successfully.',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((value) => {
                        props.setKey(Math.random());
                        props.closeModel();
                    });
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Error!',
                        text: "Card haven't saved successfully. Please try again later.",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Error!',
                    text: "Card haven't saved successfully. Please try again later.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    }

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#303238',
                fontSize: '16px',
                fontFamily: 'sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                    color: '#303238',
                },
            },
        },
    };
    return (
        <div>
            <div id="cardAddBtn" style={{ display: 'block' }}>
                <form onSubmit={actionAuthorize}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 14,
                        }}
                    >
                        <h2 style={{ fontFamily: 'Libre Franklin' }}>Card Authorization</h2>
                        <CloseIcon onClick={props.closeModel} />
                    </div>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        <Button
                            type="submit"
                            text="Authorize Credit Card"
                            height="56px"
                            color={['#2699fb', '#134d7e']}
                            disabled={!stripe || !elements || apiCall}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CheckoutForm;
