import React from 'react';
import Card from '@material-ui/core/Card';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import Rating from '../../Components/Rating/Rating';
import { APIHelper } from '../../utils/ApiHelper';
import API from "../../utils/Urls";

class UserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: 'Company',
            photo: '',
            address: {
                state: 'Toronto',
                country: 'Canada',
            },
            yearOfExperience: 10,
            numberOfEmployees: 0,
            foundedYear: 1900,
            rating: 0,
            review: 0,
            companyWeb: '',
            companyHighlight: '',
            skills: [],
            fieldOfWork: '',
        };
    }

    componentDidMount() {
        let headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };

        APIHelper.getMethod(
            API.GET_EMPLOYEE_PROFILE, headers
        ).then((response) => {
            const { employer } = response;

            const skills = [];
            if (employer.speciality && employer.speciality.length > 0) {
                employer.speciality.forEach((spec) => {
                    const skillArr = employer.skills.filter((skill) => skill.speciality_id === spec.id);
                    const skilles = skillArr.map((skill) => {
                        return { specialty_id: skill.speciality_id, _id: skill.skills_id, name: skill.name };
                    })
                    const obj = {
                        speciality: spec.name,
                        id: spec.id,
                        skill: skilles
                    };
                    skills.push(obj);
                });
            };

            this.setState({
                address: employer.address && employer.address[0],
                rating: employer.ratings,
                review: employer.review_count,
                skills,
            })
        });

        APIHelper.getMethod(
            `${API.GET_COMPANY_DETAILS}/6110afe27d174819c41dbccf`, headers //TODO for API query params
        ).then((response) => {
            console.log('company-response', response);
            this.setState({
                companyName: response.name,
                numberOfEmployees: response.number_of_employee,
                foundedYear: response.founded_year,
                companyWeb: response.website,
                companyHighlight: response.highlights,
                photo: response.logo,
                fieldOfWork: response.field && response.field.name,
            });
        });
    }

    render() {
        return (
            <Card style={{ margin: 60, padding: 25, }}>
                <div style={{ display: 'flex' }}>
                    <div>
                        {this.state.photo
                            ?
                            <div style={{
                                height: '100px',
                                width: "100px",
                                backgroundImage: `url('${this.state.photo}')`,
                                backgroundSize: 'cover',
                                borderRadius: 50
                            }}>
                            </div>
                            : <AccountCircleIcon style={{ height: '100px', width: "100px" }} />}
                    </div>
                    <div style={{ marginLeft: 26, width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <h3 style={{ margin: 0, fontSize: 22 }}>{this.state.companyName}</h3>
                                <p style={{ color: '#9A9A9A' }}>{this.state.fieldOfWork}</p>
                                <p>{this.state.address && this.state.address.state}, {this.state.address && this.state.address.country}</p>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                                    <LinkedInIcon style={{ color: '#0288D1', marginRight: 6 }} />
                                    <LanguageIcon />
                                    <p style={{ marginLeft: 20 }}>{this.state.companyWeb}</p>
                                </div>
                            </div>
                            <div>
                                <Rating
                                    rating={this.state.rating}
                                    reviews={this.state.review}
                                />
                            </div>
                        </div>
                        <div className="EditDetailsBottomLineStyles" />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                            <div style={{ marginRight: 60 }}>
                                <p style={{ color: '#9A9A9A' }}>Number of Employees</p>
                                <p style={{ marginTop: 10 }}>{this.state.numberOfEmployees}</p>
                            </div>
                            <div>
                                <p style={{ color: '#9A9A9A' }}>Founded</p>
                                <p style={{ marginTop: 10 }}>{this.state.foundedYear}</p>
                            </div>
                        </div>
                        <div className="EditDetailsBottomLineStyles" />
                        <div style={{ marginTop: 25 }}>
                            <p style={{ color: '#9A9A9A' }}>Specialities</p>
                            {
                                this.state.skills.map((item) => (
                                    <>
                                        <p style={{ marginTop: 12, fontWeight: '600' }}>{item.speciality}</p>
                                        <p>{item.skill.map((i) => i.name).join(', ')}</p>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 50 }}>
                    <p style={{ color: '#9A9A9A' }}>Company Highlights (optional)</p>
                    <div style={{ height: 200, width: '100%', border: '1px solid #9A9A9A', borderRadius: 4, padding: 6, marginTop: 10 }}>
                        <p>{this.state.companyHighlight}</p>
                    </div>
                </div>
                <div style={{ marginTop: 50 }}>
                    <p style={{ color: '#9A9A9A' }}>Portfoilo</p>
                    <div className="row">
                        <div className="col-md-4">
                            <Card style={{ height: 200, margin: 10 }}>

                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card style={{ height: 200, margin: 10 }}>

                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card style={{ height: 200, margin: 10 }}>

                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Card style={{ height: 200, margin: 10 }}>

                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card style={{ height: 200, margin: 10 }}>

                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card style={{ height: 200, margin: 10 }}>

                            </Card>
                        </div>
                    </div>
                </div>
            </Card >
        )
    }
};

export default UserDetail;
