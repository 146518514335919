import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import Slider from '@material-ui/core/Slider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import ForumSharpIcon from '@material-ui/icons/ForumSharp';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import { withStyles } from '@material-ui/core/styles';
import Message from '../../../Assets/Message.svg';
import UserDefault from '../../../Assets/UserDefault.svg';
import UserRed from '../../../Assets/UserRed.svg';
import Swal from 'sweetalert2';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorderOutlined';

import AutoCompleteCard from '../../../Components/AutoCompleteCard/AutoCompleteCard';
import Button from '../../../Components/Button/Button';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import ScoreChart from '../../../Components/GaugeChart/GaugeChart';
import Rating from '../../../Components/Rating/Rating';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import ApplicationJobApproval from './Component/ApplicationJobApproval';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import JobDescription from './Component/JobDescription';
import CustomizableChip from '../../../Components/Chip/CustomizableChip';
import { JOB_TYPE } from '../../../utils/enums';

const StyledRating = withStyles({
    iconFilled: {
        color: '#1876d2',
    },
    sizeSmall: {
        fontSize: '1.0416vw',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const ApplicationReview = (props) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [jobLoading, setJobLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState(1);
    const [hourlyRate, setHourlyRate] = React.useState([0, 100]);
    const [rating, setRating] = React.useState([0, 5]);
    const [experience, setExperinece] = React.useState([0, 20]);
    const [isJobDetails, setJobDetails] = React.useState(false);
    const [jobData, setJobData] = React.useState({});
    const [employee, setEmployee] = React.useState({});
    const [employeeModel, setEmployeeModel] = React.useState({});
    const [applicationData, setApplicationData] = React.useState({});
    const [finalFee, setFinalFee] = React.useState();
    const [QuestionAnswer, setQuestionAnswer] = React.useState([]);
    const [answerQuestion, setAnswerQuestion] = React.useState('');
    const [mileStones, setMileStones] = React.useState([]);
    const [finalFeeErr, setFinalFeeErr] = React.useState(false);
    const [newKey, setNewKey] = React.useState(0);
    const [sortBy, setSortBy] = React.useState({ label: 'Score (High to low)', value: 'scoreHTL' });
    const [whereValue, setWhereValue] = React.useState('');
    const [isPaymentMethodVerified, setIsPaymentMethodVerified] = React.useState(false);
    const [location, setLocation] = React.useState([]);
    const [isLocationSearchCalled, setIsLocationSearchCalled] = React.useState(false);
    const [locationOptions, setLocationOptions] = React.useState([]);
    const [throughEmpolinkRate, setThroughEmpolinkRate] = React.useState(1);
    const [directEmpolinkRate, setDirectEmpolinkRate] = React.useState(1);
    const [directEmpolinkRateEmployer, setDirectEmpolinkRateEmployer] = React.useState(1);
    const [throughEmpolinkRateEmployer, setThroughEmpolinkRateEmployer] = React.useState(1);
    const [buttonLoading, setButtonLoading] = React.useState(false)
    React.useEffect(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_ACTIVE, headers)
            .then((res) => {
                setIsPaymentMethodVerified(res.result);
            })
            .catch((error) => { });
    }, []);

    React.useEffect(() => {
        getApplicationReview();
    }, [page, hourlyRate, rating, experience, sortBy, location]);

    React.useEffect(() => {
        getEmpolinkServiceData();
    }, []);

    const getEmpolinkServiceData = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_APPLICATION_VARIABLE}`, headers)
            .then((response) => {
                const direct = response.filter((res) => res.name === 'feesrvB1');
                if (direct && direct.length > 0) {
                    setDirectEmpolinkRate(parseFloat(direct[0].value));
                }
                const through = response.filter((res) => res.name === 'feesrvB2');
                if (through && through.length > 0) {
                    setThroughEmpolinkRate(parseFloat(through[0].value));
                }
                const directEmployer = response.filter((res) => res.name === 'feesrvA1');
                if (directEmployer && directEmployer.length > 0) {
                    setDirectEmpolinkRateEmployer(parseFloat(directEmployer[0].value));
                }
                const throughEmployer = response.filter((res) => res.name === 'feesrvA2');
                if (throughEmployer && throughEmployer.length > 0) {
                    setThroughEmpolinkRateEmployer(parseFloat(throughEmployer[0].value));
                }
            })
            .catch((err) => { });
    };

    const getApplicationReview = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const request = {
            location: location.map((i) => i.label),
            rate: { min: hourlyRate[0], max: hourlyRate[1] },
            ratings: { min: rating[0], max: rating[1] },
            experience: { min: experience[0], max: experience[1] },
            sortBy: sortBy ? sortBy.value : '',
            sortType: 'asc',
        };

        APIHelper.postMethod(
            `${API.GET_LIST_FOR_EMPLOYEE_APLICATION_REVIEW}${page}`,
            request,
            headers
        )
            .then((response) => {
                setData(response.data);
                setTotal(response.count);
                setLoading(false);
                setNewKey(Math.random());
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const jobDetails = (id) => {
        setDrawerOpen(true);
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                setJobData(response.job);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    const getApplicationDetails = (id) => {
        setDrawerOpen(true);
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_APPLICATION_JOB_DETAILS}${id}`, headers)
            .then((response) => {
                if (response && response.length > 0) {
                    setQuestionAnswer(response[0].QuestionAnswer || []);
                    setApplicationData(response[0]);
                    setJobData(response[0] && response[0].job && response[0].job[0]);
                    setEmployee(response[0].employee && response[0].employee[0]);
                    setEmployeeModel(response[0].employeeProfile && response[0].employeeProfile[0]);
                    if (response[0].status === 'EMPLOYEE_ACCEPT') {
                        setMileStones(response[0].milestone);
                    }
                    setJobLoading(false);
                }
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    const DeclineForJob = () => {
        APIHelper.putMethod(
            `${API.JOB_APPLICATION_DECLINED}${applicationData._id}?user_type=EMPLOYER`,
            null,
            headers
        )
            .then((response) => {
                setDrawerOpen(false);
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: 'Declined job successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
                getApplicationReview();
            })
            .catch((error) => {
                setDrawerOpen(false);
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: 'Something went wrong, please try again',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    const approvalJob = () => {
        const totalMileStoneAmount = mileStones.reduce(function (accumulator, item) {
            return accumulator + parseFloat(item.amount);
        }, 0);
        if (!finalFee && jobData.jobType === JOB_TYPE.FIXED) {
            setFinalFeeErr(true);
        } else if (
            jobData.jobType === JOB_TYPE.FIXED &&
            parseFloat(finalFee) !== parseFloat(totalMileStoneAmount)
        ) {
            Swal.fire({
                customClass: 'Alert-Class',
                title: 'Information!',
                text: 'The total amount of milestone payments is not matching the final offer amount.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1876D2',
            });
        } else if (mileStones && mileStones.length === 0 && jobData.jobType === JOB_TYPE.FIXED) {
            Swal.fire({
                customClass: 'Alert-Class',
                title: 'Information!',
                text: 'Please add a milestone with this application, there is nothing any milestone. ',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#1876D2',
            });
        } else {
            const request = {
                finalFee: finalFee || 0,
                milestone: mileStones,
                answer: answerQuestion,
            };

            if (jobData.jobType === JOB_TYPE.FIXED) {
                if (jobData.paymentMethod === 'DIRECT') {
                    const serviceFeeEmployee =
                        (parseFloat(finalFee) * parseFloat(directEmpolinkRate)) / 100;
                    const serviceFeeEmployer =
                        (parseFloat(finalFee) * parseFloat(directEmpolinkRateEmployer)) / 100;
                    request.empolinkServiceFee = serviceFeeEmployee + serviceFeeEmployer;
                    request.empolinkServiceFeeByEmployer = serviceFeeEmployer;
                    request.empolinkServiceFeeByEmployee = serviceFeeEmployee;
                } else {
                    const serviceFeeEmployee =
                        (parseFloat(finalFee) * parseFloat(throughEmpolinkRate)) / 100;
                    const serviceFeeEmployer =
                        (parseFloat(finalFee) * parseFloat(throughEmpolinkRateEmployer)) / 100;
                    request.empolinkServiceFee = serviceFeeEmployee + serviceFeeEmployer;
                    request.empolinkServiceFeeByEmployer = serviceFeeEmployer;
                    request.empolinkServiceFeeByEmployee = serviceFeeEmployee;
                }
            }
            setButtonLoading(true)
            APIHelper.putMethod(
                `${API.JOB_APPLICATION_APPROVE}${applicationData._id}`,
                request,
                headers
            )
                .then((response) => {
                    if (jobData.jobType === JOB_TYPE.FIXED) {
                        setDrawerOpen(false);
                        setButtonLoading(false)
                        Swal.fire({
                            customClass: 'Alert-Class',
                            title: 'Information!',
                            text: 'Job successfully approved',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#1876D2',
                        });
                    }
                    if (jobData.jobType === JOB_TYPE.HOURLY) {
                        APIHelper.putMethod(
                            `${API.ACCEPT_JOB_FROM_EMPLOYEE_SIDE}${applicationData._id}`,
                            null,
                            headers
                        ).then((response) => {
                            if (typeof response === 'object') {
                                setDrawerOpen(false);
                                setButtonLoading(false)
                                Swal.fire({
                                    customClass: 'Alert-Class',
                                    title: 'Information!',
                                    text: 'Job successfully approved',
                                    icon: 'success',
                                    confirmButtonText: 'Ok',
                                    confirmButtonColor: '#1876D2',
                                });
                            }
                        });
                    }
                    getApplicationReview();
                })
                .catch((error) => {
                    setDrawerOpen(false);
                    setButtonLoading(false)
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Information!',
                        text: 'Something went wrong, please try again',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                });
        }
    };

    const handleLocationSelect = (e) => {
        if (location.length < 6) {
            let loc = location;

            if (loc.filter((obj) => obj.label === e.label).length < 1) {
                loc.push(e);
            }

            setLocation(JSON.parse(JSON.stringify(loc)));
        }
    };

    const handleLocationChange = (e) => {
        setWhereValue(e);
        if (e.length > 2) {
            setIsLocationSearchCalled(true);

            let headers = {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            };

            APIHelper.getMethod(API.GET_LOCATION_SEARCH_BY_SEARCH_TERM + e, headers)
                .then((response) => {
                    console.log(response);

                    if (response.length > 0) {
                        setIsLocationSearchCalled(false);
                        let locationOptions = response.map((item) => {
                            return { label: item.name, value: item.name, _id: item._id };
                        });
                        locationOptions.push({
                            label: 'Worldwide',
                            value: 'Worldwide',
                            _id: '00000000000000',
                        });
                        setLocationOptions(locationOptions);
                    }
                    if (response['status'] === 0) {
                        setIsLocationSearchCalled(false);
                    }
                })
                .catch((err) => {
                    setIsLocationSearchCalled(false);
                });
        }
    };

    const onReviewAndAccept = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        setButtonLoading(true)
        APIHelper.putMethod(
            `${API.ACCEPT_JOB_FROM_EMPLOYEE_SIDE}${applicationData._id}`,
            null,
            headers
        )
            .then((response) => {
                if (typeof response === 'object') {
                    setDrawerOpen(false);
                    setButtonLoading(false)
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Information!',
                        text: 'Offer accepted successfully',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((res) => {
                        if (res.isConfirmed) {
                            getApplicationReview();
                            setApplicationData({});
                        }
                    });
                }
            })
            .catch((err) => {
                setJobLoading(false);
                setButtonLoading(false);
            });
    };

    return (
        <>
            <div style={{ fontFamily: 'Libre Franklin' }}>
                <div className="editDetailsContentHeading">Application Review</div>
                <div className="row" style={{ marginTop: 67 }}>
                    <div className="col-md-6 col-sm-12">
                        <Card
                            style={{
                                height: '4.79vw',
                                borderRadius: '1.041vw',
                                width: '90%',
                                display: 'inline-block',
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: '1.2vw',
                                    marginTop: '0.6vw',
                                    fontSize: 18,
                                    fontWeight: 600,
                                }}
                            >
                                {'Where'}
                            </div>
                            <div className="inputBaseWrapperStyle">
                                <AutoCompleteCard
                                    placeholder={'City, Province/State, or Country'}
                                    onInputChange={(e) => handleLocationChange(e)}
                                    options={locationOptions}
                                    value={whereValue}
                                    onChange={(e) => {
                                        handleLocationSelect(e);
                                        setWhereValue('');
                                    }}
                                    loading={isLocationSearchCalled}
                                />
                            </div>
                        </Card>
                        <div style={{ margin: 'auto', marginTop: 10 }}>
                            {location.map((item, index) => {
                                return (
                                    <CustomizableChip
                                        label={item.label}
                                        style={{
                                            width: '31%',
                                            height: 32,
                                            fontSize: 12,
                                            marginTop: 9,
                                        }}
                                        key={index}
                                        onDelete={() => {
                                            let existingLocation = location;
                                            existingLocation.splice(index, 1);
                                            setLocation([...existingLocation]);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Card
                            style={{
                                borderRadius: '1.041vw',
                                width: '90%',
                                display: 'inline-block',
                                padding: 25,
                                paddingBottom: 8,
                            }}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div style={{ display: 'flex', fontSize: 18 }}>
                                        <p
                                            style={{
                                                marginRight: 15,
                                                fontWeight: 600,
                                                color: '#1c1c1c',
                                            }}
                                        >
                                            Hourly Rate:{' '}
                                        </p>
                                        <p style={{ color: '#656363' }}>
                                            {hourlyRate && `$${hourlyRate[0]} - $${hourlyRate[1]}`}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Slider
                                        value={hourlyRate}
                                        onChange={(e, newValue) => {
                                            setPage(1);
                                            setHourlyRate(newValue);
                                        }}
                                        min={0}
                                        max={100}
                                        aria-labelledby="input-slider"
                                        marks={[
                                            {
                                                value: 0,
                                                label: '$0',
                                            },
                                            {
                                                value: 50,
                                                label: '$50',
                                            },
                                            {
                                                value: 100,
                                                label: '$100+',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 25 }}>
                                <div className="col-md-6">
                                    <div style={{ display: 'flex', fontSize: 18 }}>
                                        <p
                                            style={{
                                                marginRight: 15,
                                                fontWeight: 600,
                                                color: '#1c1c1c',
                                            }}
                                        >
                                            Rating:{' '}
                                        </p>
                                        <p style={{ color: '#656363' }}>
                                            {rating && `${rating[0]} - ${rating[1]}`}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Slider
                                        value={rating}
                                        onChange={(e, newValue) => {
                                            setPage(1);
                                            setRating(newValue);
                                        }}
                                        min={0}
                                        max={5}
                                        marks={[
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 1,
                                                label: '1',
                                            },
                                            {
                                                value: 2,
                                                label: '2',
                                            },
                                            {
                                                value: 3,
                                                label: '3',
                                            },
                                            {
                                                value: 4,
                                                label: '4',
                                            },
                                            {
                                                value: 5,
                                                label: '5',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 25 }}>
                                <div className="col-md-6">
                                    <div style={{ display: 'flex', fontSize: 18 }}>
                                        <p
                                            style={{
                                                marginRight: 15,
                                                fontWeight: 600,
                                                color: '#1c1c1c',
                                            }}
                                        >
                                            Experience:{' '}
                                        </p>
                                        <p style={{ color: '#656363' }}>
                                            {rating && `${experience[0]} - ${experience[1]} years`}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Slider
                                        value={experience}
                                        onChange={(e, newValue) => {
                                            setPage(1);
                                            setExperinece(newValue);
                                        }}
                                        min={0}
                                        max={20}
                                        marks={[
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 10,
                                                label: '10',
                                            },
                                            {
                                                value: 20,
                                                label: '20+',
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        marginTop: '30px',
                    }}
                >
                    <p className="SortByDropdown">Sort by:</p>
                    <div style={{ width: '200px' }}>
                        <Dropdown
                            placeholder="Select sorting"
                            options={[
                                { label: 'Score (High to low)', value: 'scoreHTL' },
                                { label: 'Hourly rate (High to low)', value: 'hourlyHTL' },
                                { label: 'Hourly rate (Low to high)', value: 'hourlyLTH' },
                                { label: 'Experience (High to low)', value: 'experienceHTL' },
                            ]}
                            value={sortBy}
                            onChange={(e) => {
                                setPage(1);
                                setSortBy(e);
                            }}
                        />
                    </div>
                </div>
                <table
                    style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}
                    key={newKey}
                >
                    <thead>
                        <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                            <th style={{ width: '5%' }}></th>
                            <th style={{ width: '30%', color: '#1c1c1c' }}>Job Title</th>
                            <th style={{ width: '15%', color: '#1c1c1c' }}>Start date</th>
                            <th style={{ width: '20%', color: '#1c1c1c' }}>No. of Applications</th>
                            <th style={{ width: '30%', color: '#1c1c1c' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr style={{ textAlign: 'center', height: 100 }}>
                                <td colSpan="5">
                                    <CircularProgress size={40} />
                                </td>
                            </tr>
                        ) : null}
                        {!loading && data.length > 0
                            ? data.map((item, index) => (
                                <DataRow
                                    title={item.title}
                                    jobType={item.jobType}
                                    startDate={moment(item.startDate).utc(0).format('MMM DD, YY')}
                                    subData={item.application}
                                    // subData={item.application.filter(a=>a.status !== 'DECLINED')}
                                    setDrawerOpen={setDrawerOpen}
                                    icon={item.icon}
                                    // key={`data-row-${item._id}-${item.updatedAt}`}
                                    key={index}
                                    jobDetails={() => {
                                        setMileStones([]);
                                        setJobDetails(true);
                                        jobDetails(item._id);
                                    }}
                                    onReviewAndApprove={(id) => {
                                        setJobDetails(false);
                                        // jobDetails(item._id);
                                        getApplicationDetails(id);
                                    }}
                                    getApplicationReview={getApplicationReview}
                                />
                            ))
                            : !loading &&
                            data.length === 0 && (
                                <tr style={{ textAlign: 'center', height: 100 }}>
                                    <td colSpan="5">
                                        <p>No data found.</p>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
                {!loading && data.length > 0 && (
                    <div className="advancedSearchPagination" style={{ marginTop: 80 }}>
                        <Pagination
                            count={Math.ceil(total / 10)}
                            page={page}
                            size="large"
                            onChange={(e, value) => setPage(value)}
                            style={{ justifyContent: 'center', fontSize: 18 }}
                        />
                    </div>
                )}
            </div>
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    isJobDetails ? (
                        <JobDescription jobData={jobData} jobLoading={jobLoading} />
                    ) : (
                        <ApplicationJobApproval
                            jobData={jobData}
                            jobLoading={jobLoading}
                            employee={employee}
                            employeeModel={employeeModel}
                            applicationData={applicationData}
                            DeclineForJob={DeclineForJob}
                            approvalJob={approvalJob}
                            finalFee={finalFee}
                            setFinalFee={setFinalFee}
                            QuestionAnswer={QuestionAnswer}
                            answerQuestion={answerQuestion}
                            setAnswerQuestion={setAnswerQuestion}
                            mileStones={mileStones}
                            setMileStones={setMileStones}
                            finalFeeErr={finalFeeErr}
                            setFinalFeeErr={setFinalFeeErr}
                            onReviewAndAccept={onReviewAndAccept}
                            getApplicationReview={getApplicationReview}
                            isPaymentMethodVerified={isPaymentMethodVerified}
                            buttonLoading={buttonLoading}
                        />
                    )
                }
                width="80%"
            />
        </>
    );
};

const DataRow = (props) => {
    const history = useHistory();
    const pageIndex = 6;
    const [open, setOpen] = useState(false);
    const [itemShow, setItemShow] = useState(1);
    const [isChatInitLoader, setChatInitLoader] = useState(false);

    const newArray = React.useMemo(() => {
        return props.subData.slice(0, itemShow * pageIndex);
    }, [itemShow]);

    const isShowMore = React.useMemo(() => {
        return newArray.length !== props.subData.length;
    }, [newArray]);

    const onApplicationDeclined = (applicationID) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.putMethod(
            `${API.JOB_APPLICATION_DECLINED}${applicationID}?user_type=EMPLOYER`,
            null,
            headers
        )
            .then((response) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: 'Declined job successfully',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
                props.getApplicationReview();
            })
            .catch((error) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Information!',
                    text: 'Something went wrong, please try again',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    const removeFromFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.deleteMethod(`${API.REMOVE_FAVORITE}${id}`, headers)
            .then((res) => {
                props.getApplicationReview();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const addToFavorite = (id) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        let data = {
            emp_id: id,
        };

        APIHelper.putMethod(`${API.ADD_TO_FAVORITE}`, data, headers)
            .then((res) => {
                props.getApplicationReview();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onChatInit = (userId) => {
        setChatInitLoader(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        const body = {
            partnerId: userId,
        };
        APIHelper.postMethod(API.ADD_NEW_CONVERSATIONS, body, headers)
            .then((response) => {
                setChatInitLoader(false);
                if (response?.success) {
                    history.push('/messages');
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Error!',
                        text: response.error,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    });
                }
            })
            .catch((err) => {
                setChatInitLoader(false);
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Error!',
                    text: err.errors,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                });
            });
    };

    return (
        <>
            <tr
                style={{
                    height: 120,
                    borderBottom: '2px solid #C4C4C4',
                    backgroundColor: open ? '#F2F2F2' : 'inherit',
                }}
            >
                <td style={{ width: '5%' }}>
                    {open ? (
                        <RemoveOutlinedIcon
                            style={{
                                width: '32px',
                                height: '32px',
                                color: '#A3C771',
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    ) : (
                        <AddOutlinedIcon
                            style={{
                                width: '32px',
                                height: '32px',
                                color: '#A3C771',
                                cursor: 'pointer',
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    )}
                </td>
                <td
                    style={{
                        width: '30%',
                        fontWeight: open ? 700 : 500,
                        fontSize: 20,
                        color: ' #1c1c1c',
                    }}
                >
                    {props.title}
                </td>
                <td
                    style={{
                        width: '15%',
                        fontWeight: open ? 700 : 500,
                        fontSize: 20,
                        color: ' #1c1c1c',
                    }}
                >
                    {props.startDate}
                </td>
                <td style={{ width: '20%', color: '#1c1c1c', fontWeight: open ? 700 : 500 }}>
                    {props.subData.filter((a) => a.status === 'APPLIED').length > 0 && (
                        <span style={{ color: '#B00120' }}>
                            {props.subData.filter((a) => a.status === 'APPLIED').length}
                            {' New '}
                        </span>
                    )}
                    ({props.subData && props.subData.length} Total)
                </td>
                <td style={{ width: '30%', textAlign: 'right', paddingRight: 15 }}>
                    {/* <VisibilityOutlinedIcon
                        style={{
                            width: '36px',
                            height: '36px',
                            background: '#1876D2',
                            color: '#FFF',
                            padding: 4,
                            borderRadius: 30,
                            cursor: 'pointer',
                        }}
                        onClick={props.jobDetails}
                    /> */}
                </td>
            </tr>
            {open && (
                <>
                    {newArray.map((item, index) => (
                        <>
                            <tr style={{ height: 170 }} key={index}>
                                <td></td>
                                <td>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            {item.profiles && item.profiles.photo ? (
                                                <img
                                                    src={item.profiles && item.profiles.photo}
                                                    style={{
                                                        width: 70,
                                                        height: 70,
                                                        borderRadius: 70,
                                                    }}
                                                />
                                            ) : (
                                                <AccountCircleIcon
                                                    style={{ height: '70px', width: '70px' }}
                                                />
                                            )}
                                        </div>
                                        <div style={{ marginLeft: 26 }}>
                                            <h3
                                                className="sub-row-name"
                                                style={{ opacity: 0.78, display: 'inline-flex' }}
                                            >
                                                {item.users &&
                                                    `${item.users.firstName} ${item.users.lastName}`}
                                                {item.isFav ? (
                                                    <div
                                                        onClick={() => {
                                                            removeFromFavorite(item.users._id);
                                                            // this.setState({
                                                            //     isFavorite: !this.state.isFavorite,
                                                            // });
                                                        }}
                                                    >
                                                        <FavoriteIcon
                                                            style={{
                                                                color: '#1876D2',
                                                                marginLeft: 14,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            addToFavorite(item.users._id);
                                                            // this.setState({
                                                            //     isFavorite: !this.state.isFavorite,
                                                            // });
                                                        }}
                                                    >
                                                        <FavoriteBorderIcon
                                                            style={{
                                                                color: '#1876D2',
                                                                marginLeft: 14,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </h3>
                                            <p className="sub-row-title">
                                                {item.profiles &&
                                                    item.profiles.field &&
                                                    item.profiles.field.length > 0 &&
                                                    item.profiles.field[0].name}
                                            </p>
                                            <p className="sub-row-title-second">
                                                {item.profiles &&
                                                    item.profiles.address &&
                                                    item.profiles.address.length > 0 &&
                                                    `${item.profiles.address[0].city}, ${item.profiles.address[0].country}`}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td style={{ fontSize: 20, color: '#1c1c1c' }}>
                                    {item.profiles && item.profiles.work_experience} Years
                                </td>
                                <td style={{ fontSize: 20 }}>
                                    <ScoreChart score={parseInt(props.score)} totalScore={1000} />
                                </td>
                                <td
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        height: 167,
                                    }}
                                >
                                    <p style={{ color: '#1c1c1c' }}>
                                        $
                                        {props.jobType === 'HOURLY'
                                            ? `${item.proposeHourlyRate}/hr`
                                            : item.proposedFeeByEmployee}{' '}
                                    </p>
                                    <p style={{ width: 210, display: 'flex', color: '#9a9a9a' }}>
                                        {item.profiles && item.profiles.ratings}
                                        <StyledRating
                                            name="hover-feedback"
                                            size={'small'}
                                            value={item.profiles && item.profiles.ratings}
                                            readOnly
                                            fontSize="inherit"
                                        />
                                        ({item.profiles && item.profiles.review_count})
                                    </p>
                                </td>
                            </tr>
                            <tr
                                style={{ borderBottom: '2px solid #C4C4C4', height: 90 }}
                                align="top"
                            >
                                <td colSpan="4">
                                    <div style={{ display: 'flex', marginLeft: 160 }}>
                                        {item.profiles &&
                                            item.profiles.skills.length > 0 &&
                                            item.profiles.skills.slice(0, 5).map((i) => {
                                                return (
                                                    <Chip
                                                        label={i.name}
                                                        style={{ marginRight: 6, width: 110 }}
                                                        variant="outlined"
                                                    />
                                                );
                                            })}
                                    </div>
                                </td>
                                <td colSpan="2">
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-evenly' }}
                                    >
                                        {!isChatInitLoader ? (
                                            <img
                                                src={Message}
                                                style={{ height: 48, width: 48, pointer: 'q', cursor: 'pointer' }}
                                                alt="Message"
                                                onClick={() => onChatInit(item.users._id)}
                                            />
                                        ) : (<CircularProgress size={40} />)}
                                        {/* <img
                                            src={UserDefault}
                                            style={{ height: 48, width: 48 }}
                                            alt="User employee"
                                        /> */}
                                        <img
                                            src={UserDefault}
                                            onMouseOver={(e) => (e.currentTarget.src = UserRed)}
                                            onMouseOut={(e) => (e.currentTarget.src = UserDefault)}
                                            alt="User employee"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => onApplicationDeclined(item._id)}
                                        />
                                        {/* {item.status === 'APPROVED' ? (
                                            <img
                                                src={UserDefault}
                                                style={{ height: 48, width: 48 }}
                                                alt="User employee"
                                            />
                                        ) : item.status === 'DECLINED' ? (
                                            <img
                                                src={UserRed}
                                                style={{ height: 48, width: 48 }}
                                                alt="User employee"
                                            />
                                        ) : (
                                            <img
                                                src={UserDefault}
                                                style={{ height: 48, width: 48 }}
                                                alt="User employee"
                                            />
                                        )} */}
                                        {item.status === 'PENDING' || item.status === 'APPLIED' ? (
                                            <Button
                                                text="Review"
                                                // width={"210px"}
                                                height="48px"
                                                onClick={() => {
                                                    props.onReviewAndApprove(item._id);
                                                }}
                                                color={['#2699fb', '#134d7e']}
                                            />
                                        ) : item.status === 'EMPLOYEE_ACCEPT' ? (
                                            <Button
                                                text="Review"
                                                // width={"210px"}
                                                height="48px"
                                                onClick={() => {
                                                    props.onReviewAndApprove(item._id);
                                                }}
                                                color={['#2699fb', '#134d7e']}
                                            />
                                        ) : (
                                            <div style={{ width: 192 }} />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </>
                    ))}

                    {isShowMore && (
                        <tr style={{ textAlign: 'center', height: 70, marginBottom: 20 }}>
                            <td colSpan="5">
                                <div
                                    style={{
                                        height: 52,
                                        backgroundColor: '#F2F2F2',
                                        lineHeight: '52px',
                                    }}
                                    onClick={() => setItemShow(itemShow + 1)}
                                >
                                    <p style={{ fontSize: 20 }}>Show more results</p>
                                </div>
                            </td>
                        </tr>
                    )}
                </>
            )}
        </>
    );
};

export default ApplicationReview;
