import {LOADING, ERROR,  SUCCESS} from '../action/profile_action';


const initialState = {
    status : "Ideal",
    data : "",
    errorMessage : "",
    userid : ""
}

function profileReducer(state = initialState, action){
  switch (action.type) {
    case LOADING:
        return { ...state, status :  "LOADING" }

    case ERROR:
          return { ...state,status :  "ERROR", errorMessage : action.errorMessage}

    case SUCCESS:
            return { ...state,status :   "SUCCESS" ,data : action.data, userid : action.userid}

    default:
      return state
  }
}
export default profileReducer
