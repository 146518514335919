import React, { useEffect, useState } from "react";
import "./EmployeeRegister.scss";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import CustomChip from "../../Components/Chip/Chip";
import Chip from "../../Components/Chip/CustomizableChip";
import { useHistory, withRouter } from "react-router-dom";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import { Add, Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { APIHelper } from "../../utils/ApiHelper";
import API from "../../utils/Urls";
import { Log } from "../../utils/logger";
import ClearIcon from '@material-ui/icons/Clear';
import MultiTagSelect from "../../Components/AutoComplete/MultiTagSelect";
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import InstructionModal from "../../Components/InstructionModal/InstructionModal";  //This is for Instruction Pop-ups
import SignUpLogo from '../../Assets/images/signup-img.png';

const EmployeeRegisterPageFive = ({
  registerData,
  handleRegisterData,
  setCurrentPage,
}) => {
  const [speciality, setSpeciality] = useState();
  const [specialitiesOptions, setSpecialitiesOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [topSkillOptions, setTopSkillOptions] = useState([]);
  const [topSkillNum, setTopSkillNum] = useState(6);
  const [selectedSkillOptions, setSelectedSkillOptions] = useState([]);
  const [selectedSkillCount, setSelectedSkillCount] = useState(0);
  const [extraSkills, setExtraSkills] = useState([]);
  const [skillSet, setSkillSet] = useState([]);
  const [isSkillError, setSkillError] = useState(false);
  const [isSkillSpecialityAdded, setIsSkillSpecialityAdded] = useState(true);
  const [key, setKey] = useState(0);

  const history = useHistory();

  const onNextClicked = () => {
    let specialitiesArr = [];
    let skillArr = [];
    skillSet.forEach((set) => {
      specialitiesArr.push({ id: set.id, name: set.speciality });
      set.skill.forEach((skill) => {
        skillArr.push({ speciality_id: set.id, skills_id: skill.skills_id, name: skill.name });
      });
    });
    console.log(specialitiesArr, skillArr)
    if (specialitiesArr.length < 1 || skillArr.length < 1) {
      setIsSkillSpecialityAdded(false)
    }
    else {
      handleRegisterData({ skillArr, specialitiesArr });
      setCurrentPage();
    }
  };

  useEffect(() => {
    if (registerData.field) {
      APIHelper.getMethod(
        API.GET_SPECIALITY_BY_FIELDID + `?field_id=${registerData.field._id}`
      ).then((response) => {
        if (response["status"] === 1) {
          setSpecialitiesOptions(
            response.data.map((speciality) => ({
              ...speciality,
              label: speciality.name,
              value: speciality._id,
            }))
          );
        }
      });
    }
  }, [registerData.field]);

  useEffect(() => {
    if (speciality) {
      APIHelper.getMethod(
        API.GET_SKILLS_BY_SPECIALITYID + `?speciality_id=${speciality._id}`
      ).then((response) => {
        if (response["status"] === 1) {
          setSkillOptions(
            response.data.map((skill) => ({
              ...skill,
              label: skill.name,
              value: skill.name,
              skills_id: skill._id,
            }))
          );
        }
      });
    }
  }, [speciality]);

  useEffect(() => {
    const arr = skillOptions.filter((skill, id) => {
      return id < 6;
    });
    setTopSkillNum(6);
    setTopSkillOptions(arr);
  }, [skillOptions]);

  const addSkillSet = () => {
    setIsSkillSpecialityAdded(true);
    var arrLength = 0;
    if (!alreadyPresent(speciality.name)) {
      setSkillSet([
        ...skillSet,
        {
          speciality: speciality.name,
          id: speciality._id,
          skill: [...selectedSkillOptions, ...extraSkills],
        },
      ]);
    } else {
      const arr = skillSet.map((set) => {
        if (set.speciality !== speciality.name) return set;
        else
          return {
            speciality: speciality.name,
            id: speciality._id,
            skill: [...selectedSkillOptions, ...extraSkills],
          };
      });
      setSkillSet(arr);
    }

    let skillCount = 0;
    skillSet.map((item, i) => {
      skillCount += item.skill.length;
    })
    skillCount += arrLength;

    // remove selected skill from list
    let ids = selectedSkillOptions.map(c => c.skills_id);
    let cars1 = topSkillOptions.filter(({ skills_id }) => !ids.includes(skills_id));
    if (topSkillNum < skillOptions.length) {
      const tesmp = skillOptions.slice(topSkillNum, (topSkillNum + selectedSkillCount));
      setTopSkillOptions([...cars1, ...tesmp]);
      setTopSkillNum((prv) => prv + selectedSkillCount);
    } else {
      setTopSkillOptions([...cars1]);
    }
    setSelectedSkillCount(0);
  };

  const onAddition = (items) => {
    setExtraSkills([]);
    const tag = [];
    items.forEach((item) => {
      const isExist = tag.some((e) => e.name.toLowerCase() === item.value.toLowerCase());
      if (!isExist && speciality && speciality._id) {
        tag.push({
          _id: "000000000000000000000000",
          speciality_id: speciality._id,
          field_id: speciality.field_id,
          name: item.value && item.value.trim(),
          label: item.value && item.value.trim(),
          value: item.value && item.value.trim(),
          skills_id: "000000000000000000000000"
        });
      }
    });
    setExtraSkills(tag);
  }

  const alreadyPresent = (speciality) => {
    const arr = skillSet.filter((set) => {
      return set.speciality === speciality;
    });
    if (arr.length === 0) return false;
    else return true;
  };

  const removeSkillSet = (name) => {
    const arr = skillSet.filter((set) => {
      return set.speciality !== name;
    });
    setSkillSet(arr);
    let skillCount = 0;
    skillSet.map((item, i) => {
      skillCount += item.skill.length;
    })
    if (skillCount > 20) {
      setSkillError(true);
    } else {
      setSkillError(false);
    }

  };

  const removeSkill = (speciality, skill) => {
    const arr = skillSet.map((currentSet) => {
      if (currentSet.speciality === speciality) {
        const modifiedSkills = currentSet.skill.filter((currentSkill) => {
          return skill !== currentSkill.name;
        });

        return { ...currentSet, skill: modifiedSkills };
      } else {
        return currentSet;
      }
    });
    setSkillSet(arr);
    let skillCount = 0;
    skillSet.map((item, i) => {
      skillCount += item.skill.length;
    })
    if (skillCount > 20) {
      setSkillError(true);
    } else {
      setSkillError(false);
    }
    setKey(Math.random());
  };

  const removeTopSkill = (skill) => {
    const arr = topSkillOptions.filter((currentSkill) => {
      return skill.name !== currentSkill.name;
    });
    if (topSkillNum < skillOptions.length) {
      setTopSkillOptions([...arr, skillOptions[topSkillNum]]);
      setTopSkillNum((prv) => prv + 1);
    } else {
      setTopSkillOptions([...arr]);
    }
  };

  const removeSelectedTopSkill = (skill) => {
    const arr = selectedSkillOptions.filter((currentSkill) => {
      if (skill.name === currentSkill.name) {
        setSelectedSkillCount((prv) => prv - 1);
      }
      return skill.name !== currentSkill.name;
    });
    setSelectedSkillOptions(arr);
  };

  return (
    <div className="login-wrapper-step sign-up-body signUp-1">
      <div className="container">
        <div className="row row-center">
          <div className="col-md-4">
            <div className="login-img">
              <a className="login-logo" href={void (0)} style={{ cursor: 'pointer' }} onClick={() => { history.push("/") }}>
                <img src={EmpolinkLogo} alt="Empolink" />
              </a>
            </div>
          </div>
          <div className="col-md-8">
            <div className="login-right">
              <div>
                <div>
                  <h1 className="signUpHeading">Sign Up</h1>
                  <div
                    className="signUpSubHeading"
                    style={{ paddingBottom: "63px" }}
                  >
                    Personal Information
                  </div>
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                  {/* <p
                style={{
                  color: "#9a9a9a",
                  marginLeft: "5px",
                  fontSize: "20px",
                  paddingBottom: "18px",
                }}
              >
                Select a skill{"("}s{")"} from the suggested list or typw more
                skills below
              </p> */}
                  <div style={{ paddingBottom: "18px" }}>
                    <Dropdown
                      options={specialitiesOptions}
                      placeholder={"Specialties"}
                      onChange={(e) => {
                        setSpeciality(e);
                        setSelectedSkillOptions([]);
                        setExtraSkills([]);
                        setKey(Math.random());
                        setSelectedSkillCount(0);
                      }}
                    />
                  </div>
                  <p
                    style={{
                      color: "#9a9a9a",
                      marginLeft: "5px",
                      fontSize: "20px",
                      paddingBottom: "18px",
                    }}
                  >
                    Select a skill{"("}s{")"} from below suggested list or type more
                    skills
                  </p>
                  <div style={{ paddingBottom: "25px" }}>
                    {topSkillOptions.map((skill) => {
                      return (
                        <CustomChip
                          onClick={() => {
                            if (
                              isNotInArray(selectedSkillOptions, skill.name) ===
                              "true"
                            ) {
                              setSelectedSkillOptions((prv) => [
                                ...prv,
                                { ...skill },
                              ]);
                              setSelectedSkillCount((prv) => prv + 1);
                            } else {
                              removeSelectedTopSkill(skill);
                            }
                          }}
                          outlined={isNotInArray(selectedSkillOptions, skill.name)}
                          key={skill._id}
                          style={{
                            padding: "18px 8px 17px 12px",
                            borderRadius: "20px",
                            height: "auto",
                            width: "auto",
                            marginRight: "20px",
                          }}
                          label={skill.name}
                          onDelete={() => {
                            removeSelectedTopSkill(skill);
                            removeTopSkill(skill);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div style={{ paddingBottom: "44px" }}>
                    {/* <Dropdown
                  placeholder={"More Skills (comma separated, Max 10 total)"}
                  options={skillOptions.filter((skill) => {
                    return isNotInArray(topSkillOptions, skill.name) === "true";
                  })}
                  disabledDropdownIndicator={true}
                  isMulti
                  value={extraSkills}
                  onChange={(event) => {
                    console.log(event)
                    setExtraSkills(event);
                  }}
                /> */}
                    <MultiTagSelect
                      tags={extraSkills}
                      suggestions={skillOptions.filter((skill) => {
                        return isNotInArray(topSkillOptions, skill.name) === "true";
                      })}
                      // onAddition={(item) => onAddition(item)}
                      // onDelete={(index) => onDelete(index)}
                      onChange={(items) => onAddition(items)}
                      size={selectedSkillOptions && selectedSkillOptions.length}
                      key={key}
                      speciality={speciality}
                    />
                  </div>
                  <div
                    style={{
                      paddingBottom: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/*<IconButton
                  onClick={addSkillSet}
                  component={"span"}
                  style={{
                    backgroundColor: "rgba(164,199,114,0.64)",
                    marginRight: "5px",
                    color: "#707070",
                  }}
                >
                  <Add style={{ color: "#fff" }} />
                </IconButton>*/}
                    <InstructionModal>
                      <p>
                        Select a speciality then select your skills from suggested ones or type new skills in the box. <br />
                        Next click "Add Speciality and Skills" to save them to your profile.<br />
                        You can add more specialities and skills by repeating this.
                      </p>
                    </InstructionModal>
                    <div className="signUp-btn">
                      <Button
                        text="Add Speciality and Skills"
                        width='360px'
                        height="48px"
                        onClick={addSkillSet}
                        color={["#a4c772", "#4e8400"]}
                      />
                    </div>
                    {/*<p
                  style={{
                    color: "#9a9a9a",
                    marginLeft: "5px",
                    fontSize: "20px",
                  }}
                >
                  Add above selected specialty and skills to below table
                </p>*/}
                  </div>
                  <div id="skillContainer"
                    style={{
                      borderRadius: "25px",
                      border: isSkillSpecialityAdded ? "1px solid #707070" : "1px solid red",
                      padding: "10px",
                      marginBottom: "5px",
                      maxHeight: "195px",
                      overflow: "auto",
                      height: "195px"
                    }}
                    key={key}
                  >
                    {skillSet.map((set, id) => {
                      return (
                        <div key={id} style={{ marginBottom: "5px" }}>
                          <div
                            style={{
                              display: "flex",
                              color: "#454545",
                              alignItems: "center",
                            }}
                          >
                            {set.speciality}{" "}
                            <Delete
                              style={{ cursor: "pointer" }}
                              onClick={() => removeSkillSet(set.speciality)}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              color: "#454545",
                              marginBottom: "5px",
                              flexWrap: "wrap",
                            }}
                          >
                            {set.skill.map((skill, id) => (
                              <div style={{ marginLeft: "5px", marginTop: "5px" }}>
                                <Chip
                                  // key={id}
                                  // style={{
                                  //   margin: "0 10px 5px 0",
                                  //   padding: "6px 9px",
                                  //   borderRadius: "8px",
                                  //   height: "56px",
                                  //   width: "auto",
                                  // }}
                                  // size={"small"}
                                  height={"27px"}
                                  backgroundColor={"#a4c772"}
                                  borderRadius={"8px"}
                                  // width={"27px"}
                                  label={skill.name}
                                  color={"#ffffff"}
                                  deleteIcon={
                                    <div
                                      style={{ color: "#ffffff", fontSize: "18px", marginTop: "3px" }}
                                    >
                                      <ClearIcon fontSize="inherit" color="inherit" />
                                    </div>
                                  }
                                  onDelete={() => {
                                    removeSkill(set.speciality, skill.name);
                                  }}
                                /></div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {!isSkillSpecialityAdded ? <div className="errorMsg">Please add skills and specialities.</div> : null}
                  <div id="skillErrorMessage" style={{ display: isSkillError ? "block" : "none", color: "red" }}>
                    you can only add upto 100 skills
                  </div>
                  <div>
                    <p
                      style={{
                        color: "#9a9a9a",
                        marginLeft: "5px",
                        fontSize: "20px",
                      }}
                    >
                      You can add as many combinations of specialty and skills as you wish to the above table
                    </p>
                  </div>

                  <div
                    style={{
                      marginTop: "72px",
                    }}
                  >
                    <div className="signUp-btn">
                      <Button
                        text="Next"
                        width={"100%"}
                        height="56px"
                        onClick={onNextClicked}
                        color={["#a4c772", "#4e8400"]}
                        disabled={isSkillError}
                      />
                    </div>
                  </div>
                  <div style={{ textAlign: "center", paddingTop: "47px" }}>
                    <Stepper steps={5} activeStep={3} color={"#a4c772"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EmployeeRegisterPageFive);

const isNotInArray = (arr, str) => {
  let i = 0;
  for (i = 0; i < arr.length; i++) {
    if (arr[i].name === str) return "false";
  }
  return "true";
};
