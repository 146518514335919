import React from 'react';
import ScreenSize from '../../components/screen_size'
import FeePaymentMobile from './components/fee_payment_mobile'
import FeePaymentWeb from './components/fee_payment_web'

 export default class FeePayment extends React.Component{

  render() {
   return (
     <ScreenSize
       mobile  = {<FeePaymentMobile/>}
       tablet = {<FeePaymentMobile/>}
       web = {<FeePaymentWeb/>}/>
   );
  }
 }
