import React, { useState } from "react";
import MessageCard from "../../../Components/MessageCard/MessageCard";
import Button from "../../../Components/Button/Button";
import "./EmployeeRegisterVerifyEmail.scss";
import OtpInput from "react-otp-input";
import axios from "axios";
import API from "../../../utils/Urls";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import EmpolinkLogo from '../../../Assets/images/login-logo1.png';
import SignUpLogo from '../../../Assets/images/signup-img.png';

const EmployeeRegisterOTP = (props) => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const verifyOtp = async () => {
    setLoading(true);
    axios
      .post(API.VERIFY_OTP, { email: props.email ? props.email : localStorage.getItem("username"), otp })
      .then((response) => {
        if (response.data.status === 1) {
          // alert(response.message)
          setLoading(false);
          //this.props.history.push("/Employee Home route will come here")
          // if(props.email){
          //   props.setCurrentPage()
          // }
          // else{
          //   history.push("/login")
          // }
          Swal.fire({
            customClass: 'Alert-Class',
            title: 'Success!',
            text: 'OTP Verified Successfully! Your verification process is complete. Thank you for confirming your email.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#1876D2',
          }).then((re) => {
            history.push("/login");
          });
        }
        else {
          setOtpError(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        setOtpError(true);
        setLoading(false);
      });
  };

  const resendOtp = async () => {
    axios
      .post(API.RESEND_VERIFY_OTP, {
        email: props.email ? props.email : localStorage.getItem("username"),
      })
      .then((response) => {
        if (response.data.status === 1) {
          Swal.fire({
            customClass: "Alert-Class",
            title: "Success!",
            text: response.data.message,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1876D2",
          });
        }
      })
      .catch((err) => console.log(err));
  };



  return (
    <>
      <div className="login-wrapper-step sign-up-body signUp-1">
        <div className="container">
          <div className="row row-center">
            <div className="col-md-4">
              <div className="login-img">
                <a className="login-logo" href={void (0)} style={{ cursor: 'pointer' }} onClick={() => { history.push("/") }}>
                  <img src={EmpolinkLogo} alt="Empolink" />
                </a>
              </div>
            </div>
            <div className="col-md-8">
              <div className="login-right">
                <div>
                  <div className="verifyEmailHeadingWrapper otpHeadline">
                    <span className="verifyEmailHeading">
                      You haven’t verified your email yet, please enter your
                      verification code.
                    </span>
                  </div>
                  <div className="otpWrapper">
                    <OtpInput
                      value={otp}
                      onChange={(e) => {
                        setOtpError(false);
                        setOtp(e);
                      }}
                      className="otpBox"
                      numInputs={4}
                    />
                    {props.otpError ? <div className="errorMsg">The otp you entered does not match!</div> : null}
                  </div>
                  <div style={{ paddingTop: "37px" }} className="signUp-btn">
                    <Button
                      text="Confirm"
                      width='100%'
                      height="48px"
                      onClick={() => verifyOtp()}
                      loading={loading}
                      color={["#a4c772", "#4e8400"]}
                    />
                  </div>
                  <div className="verifyEmailFooterWrapper otpFooter">
                    <span className="verifyEmailHeading">
                      Haven’t received the email?&nbsp;{" "}
                      {/* <span className="resendOtp" onClick={() => props.resendOtp()}> */}
                      <span className="resendOtp" onClick={() => resendOtp()}>
                        Resend Code.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeRegisterOTP;
