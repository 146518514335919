import {LOADING, ERROR,  SUCCESS} from '../action/speciality_action';
import { APIHelper } from '../utils/ApiHelper';
import API from '../utils/Urls'


export const getSpecialityList = (field) => {

    return async dispatch => {
        try {

            dispatch({ type: LOADING })

            APIHelper.getMethod(
              API.GET_SPECIALITY_BY_FIELDID + `?field_id=${field._id}`
            ).then((response) => {
              if (response["status"] === 1) {

                let options = response.data.map((speciality) => ({
                  ...field,
                  lable: speciality.name,
                  value: speciality._id,
                  id: speciality._id,
                }))
                dispatch({ type: SUCCESS, data : options })
              }else{
                dispatch({ type: ERROR, errorMessage : "Unable to fecth Spaciality List" })
              }
            })
          } catch(error) {
              dispatch({ type: ERROR , errorMessage : error})
          }
    }
  }
