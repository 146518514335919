import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './extendedpages.scss';
//import '../../../Assets/dev-style.scss';
import EmpolinkLogo from '../../Assets/images/logo.svg';
import InvisibleLogo from '../../Assets/images/invisable-logo.svg';
import FooterPublic from '../../Components/FooterPublic/FooterPublic';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import { Helmet } from 'react-helmet';

class engjobs extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink - Your source for engineering jobs</title>
                    <meta name="keywords" content="3D Modeler Jobs, Aerospace Engineer Jobs, Aerospace Technician Jobs, Architect Jobs, AutoCAD Specialist Jobs, Biomedical Engineer Jobs, Building Envelope Specialist Jobs, Building Information Modeler (BIM) Jobs, Building Science Specialist Jobs, Chemical Engineer Jobs, Civil Engineer Jobs, Civil Technician Jobs, Computer Hardware Engineer Jobs, Cost Estimator Jobs, Electrical Engineer Jobs, Electronics Engineer Jobs, Environmental Engineer Jobs, Geomatic Specialist Jobs, Geotechnical Engineer Jobs, Industrial Engineer Jobs, Infrastructure Engineer Jobs, Interior Design Architect Jobs, Landscape Architect Jobs, Management Consultant Jobs, Material Engineer Jobs, Mechanical Engineer Jobs, Mining Engineer Jobs, Oil & Gas Engineer Jobs, Power & Energy Engineer Jobs, Project Management Consultant Jobs, Structural Engineer Jobs, Telecommunications Engineer Jobs, Transportation Engineer Jobs, Water Engineer Jobs, Wind Engineer Jobs" />
                    <meta name="description" content="Empolink connects clients and talents quickly and efficiently. Discover a platform designed for seamless interactions and services." />
                    <meta property="og:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta property="og:description" content="Empolink bridges the gap between clients and talents, providing a streamlined platform for finding and offering services." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@@empolink" />
                    <meta name="twitter:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta name="twitter:description" content="Explore diverse freelance opportunities and connect with clients who need your skills on Empolink." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>

                </Helmet>
                <HeaderPublic /> {/* From HeaderPublic component */}
                <section class="image-text image-text--right section-padding">
                    <div class="container">
                        <div class="row flex-row flex-direction">
                            <div className="col-md-6 image-text__half">
                                    <div className="specialtieslist-box">
                                        <ul>
                                        <li>Aerospace Engineer</li>
                                        <li>Architect</li>
                                        <li>Building Envelope Specialist</li>
                                        <li>Building Information Modeler (BIM)</li>
                                        <li>Building Science Specialist</li>
                                        <li>Civil Engineer & Technician</li>
                                        <li>Computer Hardware Engineer</li>
                                        <li>Electrical & Electronics Engineer</li>
                                        <li>Environmental Engineer</li>
                                        <li>Geotechnical Engineer</li>
                                        <li>Industrial Engineer</li>
                                        <li>Interior Design Architect</li>
                                        <li>Landscape Architect</li>
                                        <li>Mechanical Engineer</li>
                                        <li>Mining Engineer</li>
                                        <li>Oil & Gas Engineer</li>
                                        <li>Power & Energy Engineer</li>
                                        <li>Project Management Consultant</li>
                                        <li>Structural Engineer</li>
                                        <li>Telecommunications Engineer</li>
                                        <li>Transportation Engineer</li>
                                        <li>Wind Engineer</li>
                                        </ul>
                                    </div>
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    READY TO SHARE YOUR EXPERTISE AS A <span>CONSULTANT</span>?
                                </h2>
                                <p>Join our platform to connect with clients seeking skilled professional consultants across diverse industries. Whether you're a Engineer, innovative Technology Advisor, experienced Management Consultant, or have expertise in other specialized consulting areas, your next impactful project is just a click away! Transform your knowledge into valuable solutions and enjoy the flexibility of freelance consulting in today's dynamic business landscape.</p>
                                <p></p>
                                <p></p>                                
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                                <p></p>
                                
                            </div>
                            
                        </div>
                    </div>
                </section>

                <FooterPublic />
            </>
        );
    }
}

export default withRouter(engjobs);
