import React from 'react';
import ScreenSize from '../../components/screen_size'
import SkillsExperienceMobile from './components/skills_experience_mobile'
import SkillsExperienceWeb from './components/skills_experience_web'

export default class SkillsExperience extends React.Component {
  render() {
    return (
      <ScreenSize
        mobile  = {<SkillsExperienceMobile/>}
        tablet = {<SkillsExperienceMobile/>}
        web = {<SkillsExperienceWeb/>}/>
    );
  }
}
