import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './extendedpages.scss';
//import '../../../Assets/dev-style.scss';
import EmpolinkLogo from '../../Assets/images/logo.svg';
import InvisibleLogo from '../../Assets/images/invisable-logo.svg';
import FooterPublic from '../../Components/FooterPublic/FooterPublic';
import HeaderPublic from '../../Components/HeaderPublic/HeaderPublic';
import { Helmet } from 'react-helmet';

class mediajobs extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Empolink - Your source for media jobs</title>
                    <meta name="keywords" content="3D Modeler Jobs, Animation Designer Jobs, Audio Technician Jobs, Broadcast Technician Jobs, Commercial Designer Jobs, Content Writer Jobs, Digital Marketing Specialist Jobs, Editor and Proofreader Jobs, Fashion Designer Jobs, Game Designer Jobs, Graphic Designer Jobs, Industrial Designer Jobs, Interior Designer Jobs, Marketing Specialist Jobs, Packaging Designer Jobs, Photographer Jobs, Podcast Production Specialist Jobs, Public Relation Specialist Jobs, SEO Specialist Jobs, Social Media Marketing Specialist Jobs, Sound Engineer Jobs, Technical Writer Jobs, Video Production Technician Jobs" />
                    <meta name="description" content="Empolink connects clients and talents quickly and efficiently. Discover a platform designed for seamless interactions and services." />
                    <meta property="og:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta property="og:description" content="Empolink bridges the gap between clients and talents, providing a streamlined platform for finding and offering services." />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://empolink.com" />
                    <meta property="og:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@@empolink" />
                    <meta name="twitter:title" content="Empolink: Empowering Connections Between Clients and Talents" />
                    <meta name="twitter:description" content="Explore diverse freelance opportunities and connect with clients who need your skills on Empolink." />
                    <meta name="twitter:image" content="https://empolink.com/static/media/invisable-logo.438fca04.svg" />
                    <meta name="twitter:url" content="https://empolink.com" />

                    <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Empolink",
                        "url": "https://empolink.com",
                        "logo": "https://empolink.com/static/media/invisable-logo.438fca04.svg",
                        "sameAs": [
                            "https://www.linkedin.com/company/empolink"
                        ],
                        "description": "Empolink connects businesses with top freelancers and talents for contract and freelance positions. We empower clients and talents with tools to manage their projects and schedules efficiently.",
                        "location": {
                            "@type": "Place",
                            "address": {
                                "@type": "PostalAddress",
                                "addressLocality": "Toronto",
                                "addressRegion": "Ontario",
                                "addressCountry": "Canada"
                            }
                        },
                        "offers": {
                            "@type": "OfferCatalog",
                            "name": "Freelance and Contract Job Opportunities",
                            "itemListElement": [{
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Freelance Jobs",
                                    "description": "Access a wide range of freelance job opportunities that match your skills and availability."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Contract Jobs",
                                    "description": "Find contract job positions with flexible schedules and terms."
                                }
                            }, {
                                "@type": "Offer",
                                "itemOffered": {
                                    "@type": "Service",
                                    "name": "Hiring",
                                    "description": "Get all the tools to plan your job and hire top talent. Choose from a local or global pool for fixed or flexible schedules."
                                }
                            }]
                        }
                    }
                    `}</script>

                </Helmet>
                <HeaderPublic /> {/* From HeaderPublic component */}
                <section class="image-text image-text--right section-padding">
                    <div class="container">
                        <div class="row flex-row flex-direction">
                            <div className="col-md-6 image-text__half">
                                    <div className="specialtieslist-box">
                                        <ul>
                                        <li>3D Modeler</li>
                                        <li>Animation Designer</li>
                                        <li>Audio Technician</li>
                                        <li>Broadcast Technician</li>
                                        <li>Commercial Designer</li>
                                        <li>Content Writer</li>
                                        <li>Digital Marketing Specialist</li>
                                        <li>Editor and Proofreader</li>
                                        <li>Fashion Designer</li>
                                        <li>Game Designer</li>
                                        <li>Graphic Designer</li>
                                        <li>Industrial Designer</li>
                                        <li>Interior Designer</li>
                                        <li>Marketing Specialist</li>
                                        <li>Packaging Designer</li>
                                        <li>Photographer</li>
                                        <li>Podcast Production Specialist</li>
                                        <li>Public Relation Specialist</li>
                                        <li>SEO Specialist</li>
                                        <li>Social Media Marketing Specialist</li>
                                        <li>Sound Engineer</li>
                                        <li>Technical Writer</li>
                                        <li>Video Production Technician</li>
                                        </ul>
                                    </div>
                            </div>
                            <div class="col-md-6 image-text__half image-text__text">
                                <h2>
                                    READY TO UNLEASH YOUR <span>CREATIVE</span> POTENTIAL?
                                </h2>
                                <p>Join our platform to connect with clients seeking talented professionals in Media Creation & Marketing. Whether you're a visionary Graphic Designer, savvy Digital Marketing Specialist, innovative Content Creator, or have expertise in other creative fields, your next inspiring project is just a click away! Transform your passion into profit and enjoy the freedom of freelancing in the dynamic world of media and marketing.</p>
                                <p></p>
                                <p></p>                                
                                <a href="javascript:void(0)" class="btn-primary" onClick={() => this.props.history.push('/signup')}>
                                    SIGN UP NOW
                                </a>
                                <p></p>
                                
                            </div>
                            
                        </div>
                    </div>
                </section>

                <FooterPublic />
            </>
        );
    }
}

export default withRouter(mediajobs);
