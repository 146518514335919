import React from 'react';
import { connect } from 'react-redux';
import Spacer from '../../../components/spacer';
import TextField from '../../../../../Components/TextField/Textfield';
import Text from '../../../components/text';
import InputText from '../../../components/centralize_input_text';
import DropDownList from '../../../components/centralize_dropdown';
import ToggleButton from '../../../components/centralize_toggle_button';
import SearchableChips from '../../../components/centralize_searchable_chips';
import Constant from '../../../Constant/constant';
import API from '../../../../../utils/Urls';
import AutoCompleteCard from '../../../../../Components/AutoCompleteCard/AutoCompleteCard';
import Chip from '../../../../../Components/Chip/CustomizableChip';

import {
    LOCATION_LIST,
    REMOVE_LOCATION,
    ADDRESS,
    ADDRESS_TYPE,
    JOB_POST_ADDRESS,
    JOB_LOCATIONS,
    PAYMENT_METHOD,
} from '../../../../../action/form_action';

import {
    VERTICAL_NORMAL,
    VERTICAL_EXTRA,
    VERTICAL_SMALL,
    VERTICAL_EXTRA_LARGE,
    HORIZONTAL_NORMAL,
    HORIZONTAL_EXTRA,
} from '../../../../../utils/enums';

import { getLocation } from '../../../../../api/get_location';
import Dropdown from '../../../../../Components/DropdownComponent/Dropdown.component';
import { APIHelper } from '../../../../../utils/ApiHelper';

class Location extends React.Component {
    constructor(props) {
        super(props);

        // if (this.props.addressType === "Same as my profile adddress") {
        //   this.props.address(this.props.userProfileAddress);
        // }
        this.state = {
            countryOptions: [],
            stateOptions: [],
            cityOptions: [],
            myAddress: {},
            address: {
                city: undefined,
                country: undefined,
                state: undefined,
                unit_number: '',
                postal_code: '',
                street_address: '',
            },
            locationOptions: [],
            locationKey: 0,
        };
    }

    componentDidMount() {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(API.GET_EMPLOYEES_ACTIVE_USER, headers).then((response) => {
            const { employee } = response;
            this.setState(
                {
                    myAddress:
                        employee.address && employee.address.length > 0 ? employee.address[0] : {},
                },
                () => {
                    if (this.props.addressType !== '') {
                        if (this.props.addressType === 'Enter new address') {
                            this.setState(
                                {
                                    isAddressFieldDisabled: false,
                                    address: {
                                        city: undefined,
                                        country: this.state.myAddress.country,
                                        state: undefined,
                                        unit_number: '',
                                        postal_code: '',
                                        street_address: '',
                                    },
                                },
                                () => {
                                    this.props.address(this.state.address);
                                }
                            );
                        } else {
                            this.setState(
                                {
                                    address: this.state.myAddress,
                                    isAddressFieldDisabled: true,
                                },
                                () => {
                                    this.props.address(this.state.myAddress);
                                }
                            );
                        }
                    } else if (this.props.addressType === '') {
                        this.props.selectAddress('Same as my profile adddress');
                        if (this.props.jobPostLocation === 'Within my Country') {
                            this.setState(
                                {
                                    address: this.state.myAddress,
                                    isAddressFieldDisabled: true,
                                },
                                () => {
                                    this.props.address(this.state.myAddress);
                                }
                            );
                        }
                    }
                }
            );
        });
        this.getCountryList();
    }

    getCountryList = () => {
        APIHelper.getMethod(API.GET_COUNTRY).then((response) => {
            if (response['status'] === 1) {
                const countries = response.data.map(function (row) {
                    return {
                        value: row.name,
                        label: row.name,
                        id: row._id,
                        Id: row.country_id,
                    };
                });
                this.setState({
                    countryOptions: countries,
                });
            }
        });
        // this.props.address(this.state.address);
    };

    changeCountry = (id) => {
        APIHelper.getMethod(API.GET_STATES + `?country_id=${id}`).then((response) => {
            if (response['status'] === 1) {
                const states = response.data.map((state) => ({
                    ...state,
                    label: state.name,
                    value: state.name,
                }));
                this.setState({
                    stateOptions: states,
                });
            }
        });
        // this.props.address(this.state.address);
    };

    changeState = (id) => {
        APIHelper.getMethod(API.GET_CITIES + `?state_id=${id}`).then((response) => {
            if (response['status'] === 1) {
                const cities = response.data.map((city) => ({
                    ...city,
                    label: city.name,
                    value: city.name,
                }));
                this.setState({
                    cityOptions: cities,
                });
            }
        });
        // this.props.address(this.state.address);
    };

    handleLocationSelect = (e) => {
        if (this.props.locations.length < 6) {
            var loc = this.props.locations;
            if (this.props.locations.length === 1) {
                const exist = this.props.locations.findIndex((e) => e.label === 'Worldwide');
                if (exist > -1) {
                    loc = [];
                }
            }

            if (loc.filter((obj) => obj.label === e.label).length < 1) {
                loc.push(e);
            }
            this.props.setLocations(loc);
            this.setState({
                locationKey: Math.random(),
            });
        }
    };

    render() {
        const {
            statusLocation,
            dataLocation,
            errorMessageLocation,
            locationList,
            addressValue,
            userProfileAddress,
            addressType,
            error,
            jobPostLocation,
        } = this.props;

        const fullLocations = [
            { lable: 'Same as my profile adddress' },
            { lable: 'Remote working' },
            { lable: 'Enter new address' },
        ];
        const remoteLocation = [{ lable: 'Remote working' }];
        return (
            <div>
                <Text
                    fontSize={Constant.FONT_SIZE_WEB}
                    color={Constant.COLOR_BLACK_500}
                    text="Specify locations to share your job post:"
                    color="#707070"
                />
                <Spacer spaceType={VERTICAL_SMALL} />
                <ToggleButton
                    leftName={'Within my Country'}
                    rightName={'Internationally'}
                    defaultSelectedValue={jobPostLocation === 'Within my Country' ? 1 : 2}
                    onSelectedValueCallback={(value) => {
                        this.props.jobPostLocationMethod(value);
                        this.props.selectAddress('');
                        if (value === 'Within my Country') {
                            this.props.setLocations([
                                {
                                    label: localStorage.getItem('country'),
                                    value: localStorage.getItem('country'),
                                    _id: '00000000000000',
                                },
                            ]);
                            this.props.selectAddress('Same as my profile adddress');
                        } else {
                            this.props.selectAddress('Remote working');
                            this.props.setLocations([
                                {
                                    label: 'Worldwide',
                                    value: 'Worldwide',
                                    _id: '00000000000000',
                                },
                            ]);
                        }
                        this.props.paymentMethod('Empolink');
                        // this.setState(
                        //     {
                        //         isAddressFieldDisabled: false,
                        //         address: {
                        //             city: undefined,
                        //             country: undefined,
                        //             state: undefined,
                        //             unit_number: '',
                        //             postal_code: '',
                        //             street_address: '',
                        //         },
                        //     },
                        //     () => {
                        //         this.props.address(this.state.myAddress);
                        //     }
                        // );
                        this.setState(
                            {
                                address: this.state.myAddress,
                                isAddressFieldDisabled: true,
                            },
                            () => {
                                this.props.address(this.state.myAddress);
                            }
                        );
                    }}
                />
                <Spacer spaceType={VERTICAL_EXTRA} />
                <Text
                    fontSize={Constant.FONT_SIZE_WEB}
                    color={Constant.COLOR_BLACK_500}
                    text="Specify countries:"
                    color="#707070"
                />
                <div style={{ width: '100%', marginTop: '10px' }} key={jobPostLocation}>
                    <div
                        className="inputBaseWrapperStyle"
                        style={{
                            marginLeft: 0,
                            border: '1px solid #B6B7B6',
                            lineHeight: '44px',
                            height: 56,
                            borderRadius: '4px',
                            width: '40%',
                        }}
                    >
                        <AutoCompleteCard
                            placeholder={'Country'}
                            options={this.state.countryOptions}
                            onChange={(e) => {
                                this.handleLocationSelect(e);
                            }}
                            createSelect={false}
                            disabled={jobPostLocation === 'Within my Country'}
                        />
                    </div>
                    <div style={{ width: '70%', marginTop: 4 }} key={this.state.locationKey}>
                        {this.props.locations &&
                            this.props.locations
                                .filter((x) => x.label !== null)
                                .map((item, index) => {
                                    return (
                                        <Chip
                                            label={item.label}
                                            style={{
                                                width: 200,
                                                height: 32,
                                                fontSize: 12,
                                                marginTop: 9,
                                            }}
                                            key={`skill_${item.label}`}
                                            onDelete={() => {
                                                let existingLocation = this.props.locations;
                                                existingLocation.splice(index, 1);
                                                if (existingLocation.length === 0) {
                                                    if (jobPostLocation === 'Within my Country') {
                                                        this.props.setLocations([
                                                            {
                                                                label: localStorage.getItem(
                                                                    'country'
                                                                ),
                                                                value: localStorage.getItem(
                                                                    'country'
                                                                ),
                                                                _id: '00000000000000',
                                                            },
                                                        ]);
                                                    } else {
                                                        this.props.setLocations([
                                                            {
                                                                label: 'Worldwide',
                                                                value: 'Worldwide',
                                                                _id: '00000000000000',
                                                            },
                                                        ]);
                                                    }
                                                } else {
                                                    this.props.setLocations(existingLocation);
                                                }
                                                this.setState({
                                                    locationKey: Math.random(),
                                                });
                                            }}
                                        />
                                    );
                                })}
                        {this.props.locations &&
                            this.props.locations.length === 0 &&
                            this.props.locations.filter((x) => x.label === null).length === 1 &&
                            this.props.locations
                                .filter((x) => x.label === null)
                                .map((item, index) => {
                                    return (
                                        <Chip
                                            label={item.label}
                                            style={{
                                                width: 200,
                                                height: 32,
                                                fontSize: 12,
                                                marginTop: 9,
                                            }}
                                            key={`skill_${item.label}`}
                                            onDelete={() => {
                                                let existingLocation = this.props.locations;
                                                existingLocation.splice(index, 1);
                                                if (existingLocation.length === 0) {
                                                    if (jobPostLocation === 'Within my Country') {
                                                        this.props.setLocations([
                                                            {
                                                                label: localStorage.getItem(
                                                                    'country'
                                                                ),
                                                                value: localStorage.getItem(
                                                                    'country'
                                                                ),
                                                                _id: '00000000000000',
                                                            },
                                                        ]);
                                                    } else {
                                                        this.props.setLocations([
                                                            {
                                                                label: 'Worldwide',
                                                                value: 'Worldwide',
                                                                _id: '00000000000000',
                                                            },
                                                        ]);
                                                    }
                                                } else {
                                                    this.props.setLocations(existingLocation);
                                                }
                                                this.setState({
                                                    locationKey: Math.random(),
                                                });
                                            }}
                                        />
                                    );
                                })}
                    </div>
                </div>
                <Spacer spaceType={VERTICAL_EXTRA} />
                <p style={{ fontSize: 20, color: '#707070' }}>Specify your work address</p>
                <Spacer spaceType={VERTICAL_SMALL} />
                <DropDownList
                    fontSize={Constant.FONT_SIZE_WEB}
                    width="40%"
                    menuWidth="17%"
                    error={error.addressType || false}
                    fontSizeDropDown={Constant.FONT_SIZE_DROPDOWN_WEB}
                    dropDownList={
                        jobPostLocation === 'Within my Country' ? fullLocations : remoteLocation
                    }
                    label={'Same as'}
                    text={addressType}
                    onSelectedValueCallback={(selectedValue, data) => {
                        this.props.selectAddress(selectedValue);
                        if (selectedValue === 'Enter new address') {
                            this.setState(
                                {
                                    isAddressFieldDisabled: false,
                                    address: {
                                        city: undefined,
                                        country: this.state.myAddress.country,
                                        state: undefined,
                                        unit_number: '',
                                        postal_code: '',
                                        street_address: '',
                                    },
                                },
                                () => {
                                    this.props.address(this.state.address);
                                }
                            );
                            this.changeCountry(
                                this.state.countryOptions.find(
                                    (a) => a.value === this.state.myAddress.country
                                ).Id
                            );
                        } else {
                            this.setState(
                                {
                                    address: this.state.myAddress,
                                    isAddressFieldDisabled: true,
                                },
                                () => {
                                    this.props.address(this.state.myAddress);
                                }
                            );
                        }
                    }}
                />
                <div style={{ marginTop: '20px', width: '40%' }}>
                    <Dropdown
                        placeholder={'Country *'}
                        options={this.state.countryOptions}
                        disabledDropdownIndicator={false}
                        error={error.country || false}
                        value={
                            this.state.address &&
                            this.state.address.country && {
                                label: this.state.address.country,
                                value: this.state.address.country,
                            }
                        }
                        onChange={(event) => {
                            this.changeCountry(event.Id);
                            const address = this.state.address;
                            address.country = event.value;
                            address.state = undefined;
                            address.city = undefined;
                            this.setState({ address }, () => {
                                this.props.address(this.state.address);
                            });
                        }}
                        isDisabled={true}
                    />
                    <div style={{ marginTop: '20px' }}>
                        <Dropdown
                            placeholder={'State/Province *'}
                            options={this.state.stateOptions}
                            error={error.state || false}
                            value={
                                this.state.address && this.state.address.state
                                    ? {
                                          label: this.state.address.state,
                                          value: this.state.address.state,
                                      }
                                    : null
                            }
                            disabledDropdownIndicator={false}
                            onChange={(event) => {
                                this.changeState(event.state_id);
                                const address = this.state.address;
                                address.state = event.value;
                                address.city = undefined;
                                this.setState({ address }, () => {
                                    this.props.address(this.state.address);
                                });
                            }}
                            isDisabled={this.state.isAddressFieldDisabled}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Dropdown
                            placeholder={'City *'}
                            options={this.state.cityOptions}
                            error={error.city || false}
                            disabledDropdownIndicator={false}
                            value={
                                this.state.address && this.state.address.city
                                    ? {
                                          label: this.state.address.city,
                                          value: this.state.address.city,
                                      }
                                    : null
                            }
                            onChange={(event) => {
                                const address = this.state.address;
                                address.city = event.value;
                                this.setState({ address }, () => {
                                    this.props.address(this.state.address);
                                });
                            }}
                            isDisabled={this.state.isAddressFieldDisabled}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <TextField
                            width={'100%'}
                            label="Street Address *"
                            error={error.street_address || false}
                            value={this.state.address && this.state.address.street_address}
                            onChange={(event) => {
                                const address = this.state.address;
                                address.street_address = event.target.value;
                                this.setState({ address }, () => {
                                    this.props.address(this.state.address);
                                });
                            }}
                            disabled={this.state.isAddressFieldDisabled}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <TextField
                            width={'100%'}
                            label="Unit Number"
                            value={this.state.address && this.state.address.unit_number}
                            onChange={(event) => {
                                const address = this.state.address;
                                address.unit_number = event.target.value;
                                this.setState({ address }, () => {
                                    this.props.address(this.state.address);
                                });
                            }}
                            disabled={this.state.isAddressFieldDisabled}
                        />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <TextField
                            width={'100%'}
                            label="Zip/Postal Code *"
                            error={error.postal_code || false}
                            value={this.state.address && this.state.address.postal_code}
                            onChange={(event) => {
                                const address = this.state.address;
                                address.postal_code = event.target.value;
                                this.setState({ address }, () => {
                                    this.props.address(this.state.address);
                                });
                            }}
                            disabled={this.state.isAddressFieldDisabled}
                        />
                    </div>
                </div>

                {/* <InputText
          fontSize={Constant.FONT_SIZE_WEB}
          width="40%"
          id="workAddress" label="Work Address"
          isMutiline={true}
          row={10}
          text={addressValue}
          disabled={
            addressType === 'Same as my profile adddress'
              ? (addressValue === "" ? false : true) : false
          }
          onChangeCallback={value => {
            this.props.address(value);
          }}
        /> */}
            </div>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        statusLocation: state.locationReducer.status,
        dataLocation: state.locationReducer.data,
        errorMessageLocation: state.locationReducer.errorMessage,
        locationList: state.formReducer.locationList,
        addressValue: state.formReducer.address,
        addressType: state.formReducer.addressType,
        userProfileAddress: state.profileReducer.data,
        error: state.formReducer.error || {},
        jobPostLocation: state.formReducer.jobPostLocation,
        locations: state.formReducer.jobLocations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLocation: (value) => {
            dispatch(getLocation(value));
        },
        addLocation: (value) => dispatch({ type: LOCATION_LIST, locationList: value }),
        removeALocation: (value) => dispatch({ type: REMOVE_LOCATION, locationList: value }),
        selectAddress: (value) => dispatch({ type: ADDRESS_TYPE, addressType: value }),
        address: (value) => dispatch({ type: ADDRESS, address: value }),
        jobPostLocationMethod: (value) =>
            dispatch({ type: JOB_POST_ADDRESS, jobPostLoctionValue: value }),
        setLocations: (value) => dispatch({ type: JOB_LOCATIONS, jobLocations: value }),
        paymentMethod: (value) => dispatch({ type: PAYMENT_METHOD, paymentMethod: value }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
