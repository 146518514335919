import React, { Component } from 'react';
// import { GoogleLogin } from 'react-google-login';
import TextBox from '../../../Components/TextField/Textfield';
// import Button from '@material-ui/core/Button';
// import Button from '../../../Components/Button/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import '../../LoginPage/LoginPage.scss';
import './RegisterMainPage.scss';
import { withRouter } from 'react-router-dom';
// import MicrosoftLogin from 'react-microsoft-login';
import { ValidateEmail } from '../../../utils/validations';
import API from '../../../utils/Urls';
import { APIHelper } from '../../../utils/ApiHelper';
// import Swal from 'sweetalert2';

import EmpolinkLogo from '../../../Assets/images/login-logo1.png';
import SignUpLogo from '../../../Assets/images/signup-img.png';
import GoogleLogo from '../../../Assets/images/google.png';
import MicrosoftLogo from '../../../Assets/images/microsoft.png';

// import '../../../Assets/dev-style.scss';

class RegisterMainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            showPassword: false,
            confirmPassword: '',
            showConfirmPassword: false,
            username: null,
            isUsernameValid: true,
            passwordDoesNotMatch: false,
            emailError: false,
            signInWithGoogleData: {},
            isEmailExist: false,
        };
    }

    onLogInClicked() {
        this.props.history.push('/login');
    }

    checkIfEmailExists() {
        const params = new URLSearchParams();
        params.append('email', this.state.username);
        let headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        };

        APIHelper.postMethod(API.CHECK_EMAIL, params, headers)
            .then((response) => {
                console.log(response);
                if (response.data.isEmailExist) {
                    this.setState({ isEmailExist: true });
                } else {
                    this.setState({ isEmailExist: false });
                }
            })
            .catch((err) => {
                this.setState({ isEmailExist: false });
            });
    }

    onSignUpClicked() {
        if (!ValidateEmail(this.state.username)) {
            this.setState({ isUsernameValid: false });
        }

        if (!this.state.password || !this.state.confirmPassword) {
            this.setState({ passwordDoesNotMatch: true });
        }

        if (this.state.password !== this.state.confirmPassword || this.state.password.length < 6) {
            this.setState({ passwordDoesNotMatch: true });
        }

        if (
            this.state.password === this.state.confirmPassword &&
            this.state.password.length >= 6 &&
            ValidateEmail(this.state.username) &&
            !this.state.isEmailExist
        ) {
            this.props.onSignUpClicked(
                this.state.username,
                this.state.password,
                'manual',
                this.state.signInWithGoogleData
            );
        }
    }

    render() {
        const authHandler = (err, data) => {
            console.log(err, data);
        };

        const onNewPasswordEntered = (event) => {
            this.setState({ password: event.target.value });
        };

        const responseGoogle = (response) => {
            this.setState({ signInWithGoogleData: response });
            this.props.onSignUpClicked(
                this.state.username,
                this.state.password,
                'google',
                response
            );
        };

        return (
            <section class="login-wrapper sign-up-body">
                <div class="container">
                    <div class="row row-center">
                        <div class="col-md-6">
                            <div class="login-img">
                                <a
                                    href="javascript:void(0)"
                                    class="login-logo"
                                    onClick={() => this.props.history.push('/')}
                                >
                                    <img src={EmpolinkLogo} alt="empolink-logo" />
                                </a>
                                <img src={SignUpLogo} alt="signup-logo" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="login-right">
                                <h2>Sign up</h2>
                                <div class="form-col">
                                    <div class="form-style form-email">
                                        {/* <input
                                            type="email"
                                            placeholder="Enter your email address"
                                        /> */}
                                        <TextBox
                                            required={true}
                                            label={'Email Address'}
                                            onChange={(event) => {
                                                this.setState({ isUsernameValid: true });
                                                this.setState({ username: event.target.value });
                                            }}
                                            value={this.state.username}
                                            width={'100%'}
                                            error={!this.state.isUsernameValid}
                                            onBlur={(e) => this.checkIfEmailExists()}
                                        />
                                        {this.state.isEmailExist ? (
                                            <div className="errorMsg">Email already exists. </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div class="form-col">
                                    <div class="form-style form-password">
                                        {/* <input type="password" placeholder="Min 6 Characters" />
                                        <i class="toggle-password fa fa-fw fa-eye-slash"></i> */}
                                        <TextBox
                                            label={'Password * (Min. 6 Characters)'}
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            onChange={(event) => {
                                                this.setState({ passwordDoesNotMatch: false });
                                                onNewPasswordEntered(event);
                                            }}
                                            width={'100%'}
                                            error={this.state.passwordDoesNotMatch}
                                            InputProps={{
                                                // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                this.setState({
                                                                    showPassword:
                                                                        !this.state.showPassword,
                                                                })
                                                            }
                                                            edge="end"
                                                            tabIndex="-1" // Exclude from tab order
                                                        >
                                                            {this.state.showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="form-col">
                                    <div class="form-style form-password">
                                        {/* <input type="password" placeholder="Confirm Password" />
                                        <i class="toggle-password fa fa-fw fa-eye-slash"></i> */}
                                        <TextBox
                                            required={true}
                                            label={'Confirm Password'}
                                            type={
                                                this.state.showConfirmPassword ? 'text' : 'password'
                                            }
                                            onChange={(event) => {
                                                this.setState({ passwordDoesNotMatch: false });
                                                this.setState({
                                                    confirmPassword: event.target.value,
                                                });
                                            }}
                                            width={'100%'}
                                            error={this.state.passwordDoesNotMatch}
                                            InputProps={{
                                                // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() =>
                                                                this.setState({
                                                                    showConfirmPassword:
                                                                        !this.state
                                                                            .showConfirmPassword,
                                                                })
                                                            }
                                                            edge="end"
                                                            tabIndex="-1" // Exclude from tab order
                                                        >
                                                            {this.state.showConfirmPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="form-col">
                                    <input
                                        type="submit"
                                        value="Sign up"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.onSignUpClicked()}
                                    />
                                </div>
                                <div class="form-seperator">
                                    <span>Or</span>
                                </div>
                                <div className="login-method">
                                    {/* <a href="/"> */}
                                    <span style={{ display: 'inline-block', cursor: 'not-allowed', pointerEvents: 'auto' }}>
                                        <img src={GoogleLogo} alt="" /> Sign up with Google
                                    </span>
                                    {/* </a> */}
                                </div>
                                <div className="login-method">
                                    {/* <a href="/"> */}
                                    <span style={{ display: 'inline-block', cursor: 'not-allowed', pointerEvents: 'auto' }}>
                                        <img src={MicrosoftLogo} alt="" /> Sign up with Microsoft
                                    </span>
                                    {/* </a> */}
                                    </div>
                                <div class="form-link">
                                    Already have an account ? <a href="/login">Log in</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );

        // return (
        //     <div className="loginPageWrapper">
        //         <div className="empoLinkImgStyles">
        //             <h1
        //                 style={{
        //                     marginLeft: '97px',
        //                     fontSize: '35px',
        //                     letterSpacing: '2.84px',
        //                 }}
        //                 className="empolink"
        //             >
        //                 EmpoLink
        //             </h1>
        //         </div>
        //         <div className="loginPage">
        //             <div className="loginCard">
        //                 <h1 style={{ color: '#414141' }} className="signUpTopHeading">
        //                     Sign Up
        //                 </h1>
        //                 <div style={{ paddingBottom: '18px' }}>
        //                     <TextBox
        //                         required={true}
        //                         label={'Email Address'}
        //                         onChange={(event) => {
        //                             this.setState({ isUsernameValid: true });
        //                             this.setState({ username: event.target.value });
        //                         }}
        //                         value={this.state.username}
        //                         width={'100%'}
        //                         error={!this.state.isUsernameValid}
        //                         onBlur={(e) => this.checkIfEmailExists()}
        //                     />
        //                     {this.state.isEmailExist ? (
        //                         <div className="errorMsg">Email already exists. </div>
        //                     ) : null}
        //                 </div>
        //                 <div style={{ paddingBottom: '18px' }}>
        //                     <TextBox
        //                         label={'Password * (Min. 6 Characters)'}
        //                         type={this.state.showPassword ? 'text' : 'password'}
        //                         onChange={(event) => {
        //                             this.setState({ passwordDoesNotMatch: false });
        //                             onNewPasswordEntered(event);
        //                         }}
        //                         width={'100%'}
        //                         error={this.state.passwordDoesNotMatch}
        //                         InputProps={{
        //                             // <-- This is where the toggle button is added.
        //                             endAdornment: (
        //                                 <InputAdornment position="end">
        //                                     <IconButton
        //                                         aria-label="toggle password visibility"
        //                                         onClick={() =>
        //                                             this.setState({
        //                                                 showPassword: !this.state.showPassword,
        //                                             })
        //                                         }
        //                                         edge="end"
        //                                     >
        //                                         {this.state.showPassword ? (
        //                                             <Visibility />
        //                                         ) : (
        //                                             <VisibilityOff />
        //                                         )}
        //                                     </IconButton>
        //                                 </InputAdornment>
        //                             ),
        //                         }}
        //                     />
        //                 </div>
        //                 <div style={{ paddingBottom: '9px' }}>
        //                     <TextBox
        //                         required={true}
        //                         label={'Confirm Password'}
        //                         type={this.state.showConfirmPassword ? 'text' : 'password'}
        //                         onChange={(event) => {
        //                             this.setState({ passwordDoesNotMatch: false });
        //                             this.setState({ confirmPassword: event.target.value });
        //                         }}
        //                         width={'100%'}
        //                         error={this.state.passwordDoesNotMatch}
        //                         InputProps={{
        //                             // <-- This is where the toggle button is added.
        //                             endAdornment: (
        //                                 <InputAdornment position="end">
        //                                     <IconButton
        //                                         aria-label="toggle password visibility"
        //                                         onClick={() =>
        //                                             this.setState({
        //                                                 showConfirmPassword:
        //                                                     !this.state.showConfirmPassword,
        //                                             })
        //                                         }
        //                                         edge="end"
        //                                     >
        //                                         {this.state.showConfirmPassword ? (
        //                                             <Visibility />
        //                                         ) : (
        //                                             <VisibilityOff />
        //                                         )}
        //                                     </IconButton>
        //                                 </InputAdornment>
        //                             ),
        //                         }}
        //                     />
        //                 </div>
        //                 <div style={{ paddingBottom: '75px', paddingTop: '21px' }}>
        //                     <Button
        //                         text="Sign up"
        //                         width={'100%'}
        //                         height="56px"
        //                         onClick={() => this.onSignUpClicked()}
        //                         color={['#2699fb', '#134d7e']}
        //                     />
        //                 </div>
        //                 <div style={{ width: '100%' }}>
        //                     <hr className="left-line" />
        //                     <div style={{ marginLeft: '3.5%', display: 'inline-block' }}>
        //                         {'Or'}
        //                     </div>
        //                     <hr className="right-line" />
        //                 </div>
        //                 <div style={{ marginTop: '75px' }}>
        //                     {/* <GoogleLogin
        //                         clientId="1080303502452-90u0ouis2kuoljcr8o7mda2s2632i6l7.apps.googleusercontent.com"
        //                         buttonText="Sign in with Google"
        //                         onSuccess={responseGoogle}
        //                         onFailure={responseGoogle}
        //                         cookiePolicy={'single_host_origin'}
        //                         className='googleLogInStyles'
        //                         children={<div className="google-sign-in">Sign up with Google</div>}
        //                     /> */}
        //                     <MicrosoftLogin
        //                         className="microsoftButtonStyles"
        //                         clientId={'87c2f4c7-9e45-4273-ad8f-42da8c77c697'}
        //                         children={
        //                             <div className="googleLogInStyles">
        //                                 <div className="microsoft"></div>
        //                                 <div className="microsoftButtonText">
        //                                     Sign up with Microsoft
        //                                 </div>
        //                             </div>
        //                         }
        //                         authCallback={authHandler}
        //                     />
        //                     <div style={{ marginTop: '18px' }}>
        //                         <GoogleLogin
        //                             clientId="1080303502452-90u0ouis2kuoljcr8o7mda2s2632i6l7.apps.googleusercontent.com"
        //                             buttonText="Sign in with Google"
        //                             onSuccess={() => responseGoogle}
        //                             onFailure={() => responseGoogle}
        //                             cookiePolicy={'single_host_origin'}
        //                             className="googleLogInStyles"
        //                             children={
        //                                 <div className="google-sign-in">Sign up with Google</div>
        //                             }
        //                         />
        //                     </div>
        //                     <div className="sign-up" style={{ paddingTop: '78px' }}>
        //                         <div style={{ display: 'inline-block' }}>
        //                             Already have an account?
        //                         </div>
        //                         <div
        //                             onClick={() => this.onLogInClicked()}
        //                             style={{
        //                                 paddingLeft: '5px',
        //                                 cursor: 'pointer',
        //                                 display: 'inline-block',
        //                                 color: '#5bb2fc',
        //                             }}
        //                         >
        //                             Log In
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // );
    }
}
export default withRouter(RegisterMainPage);
