import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { useEffect, useState, useRef } from 'react';
import SearchMoreSkill from './centralize_input_text';
import Spacer from './spacer';
import { VERTICAL_ULTRA_SMALL } from '../../../utils/enums';
import TouchOutside from './touch_outside_hooks';
import { makeStyles } from '@material-ui/core/styles';

export default function CentralizeSearchableChips(props) {
    const ref = useRef('CentralizeSearchableChips');
    const [searchListMain, setSearchListMain] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [isDropDownOpen, setDropDownState] = useState(false);
    const [chipsList, setChipList] = useState([]);
    const [text, setText] = useState('');
    const [width, setWidth] = useState('40%');
    const [fontSize, setFontSize] = useState('0.9375vw');
    const [clearChipList, setClearChipList] = useState(false);
    const [extraSkills, setExtraSkills] = useState([]);
    const [key, setKey] = useState(0);

    const [fontSizeDropDown, setFontSizeDropDown] = useState('0.9375vw');
    const [menuWidthDropDown, setMenuWidthDropDown] = useState('17%');
    const [marginTopDropDown, setMarginTopDropDown] = useState('20px');

    TouchOutside(ref, () => setDropDownState(false));

    const useStyles = makeStyles((theme) => ({
        dropDownMenu: {
            width: menuWidthDropDown,
            minHeight: '0px',
            maxHeight: '200px',
            position: 'absolute',
            marginLeft: '5px',
            elevation: '10',
            zIndex: '5',
            borderRadius: '16px',
            overflowY: 'scroll',
        },

        dropDownMenuList: {
            '&:hover': {
                width: '100%',
                backgroundColor: '#ffffff',
            },
        },

        dropDownMenuText: {
            width: '100%',
            fontFamily: 'Libre Franklin',
            fontSize: fontSizeDropDown,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#707070',
            padding: '10px 10px 10px 10px',
            marginLeft: '5px',
            marginRight: '5px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                color: 'white',
                backgroundColor: '#1876D2',
                borderRadius: '10px',
                border: '0px solid  #707070',
            },
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (props.fontSizeDropDown !== undefined) setFontSizeDropDown(props.fontSizeDropDown);
    }, [props.fontSizeDropDown]);

    useEffect(() => {
        if (props.menuWidthDropDown !== undefined) setMenuWidthDropDown(props.menuWidthDropDown);
    }, [props.menuWidthDropDown]);

    useEffect(() => {
        if (props.marginTopDropDown !== undefined) setMarginTopDropDown(props.marginTopDropDown);
    }, [props.marginTopDropDown]);

    useEffect(() => {
        if (props.selectedList !== undefined && props.selectedList.length > 0) {
            setChipList(props.selectedList);
            var listFilter = props.searchList.filter(
                (element) =>
                    !props.selectedList.find(
                        (elementToDelete) => elementToDelete.lable === element.lable
                    )
            );
            setSearchList(listFilter);
            setSearchListMain(listFilter);
        } else {
            setSearchList(props.searchList);
            setSearchListMain(props.searchList);
        }
    }, [props.searchList, props.selectedList]);

    useEffect(() => {
        if (props.width !== undefined) setWidth(props.width);
    }, [props.width]);

    useEffect(() => {
        if (props.fontSize !== undefined) setFontSize(props.fontSize);
    }, [props.fontSize]);

    useEffect(() => {
        setText(props.text !== undefined ? props.text : '');
    }, [props.text !== undefined ? props.text : '']);

    useEffect(() => {
        if (props.clearChipList !== undefined && props.clearChipList) {
            setChipList([]);
            if (props.clearChipsSelectionCallback !== undefined) {
                props.clearChipsSelectionCallback();
            }
        }
    }, [props.clearChipList]);

    const handleDelete = (data) => {
        const newData = chipsList.filter((item) => item.lable !== data.lable);
        console.log('newData', newData);
        setChipList([...newData]);
        setSearchList([...searchList, data]);
        setSearchListMain([...searchListMain, data]);
        if (props.onRemoveValueCallback !== undefined) {
            props.onRemoveValueCallback(data);
        }
        setKey(Math.random());
    };

    const handleAdd = (data) => {
        setSearchList(searchList.filter((item) => item.lable !== data.lable));
        setSearchListMain(searchListMain.filter((item) => item.lable !== data.lable));
        setChipList([...chipsList, data]);
        setText('');
        if (props.onSelectedValueCallback !== undefined) {
            props.onSelectedValueCallback(data);
        }
    };

    const handleSearch = (value) => {
        let result = [];
        setExtraSkills([]);
        result = searchListMain.filter((data) => {
            return data.lable.toLowerCase().search(value.toLowerCase()) != -1;
        });

        if (props.checkOnText) {
            if (value.length === 0) {
                setDropDownState(false);
            } else {
                setDropDownState(true);
            }
        } else {
            if (searchListMain.length === 0) {
                setDropDownState(false);
            } else {
                setDropDownState(true);
            }
        }
        let data
        data = searchListMain.filter((data) => {
            return data.lable === value;
        });
        if (data.length === 0) {
            let extraSkills = value.split(',');
            setExtraSkills(extraSkills);
        }
        setSearchList(result);
        setText(value);
        if (props.onSearchCallback !== undefined) {
            props.onSearchCallback(value);
        }
    };

    const handleEnter = () => {
        extraSkills.forEach((element, index) => {
            handleAdd({ index: index, name: element, lable: element });
        });
    };

    return (
        <div>
            <div ref={ref}>
                <SearchMoreSkill
                    id={props.id ?? 'searchMoreSkill'}
                    label={props.label ?? 'Search for more skills'}
                    error={props.error || false}
                    text={text}
                    width={width}
                    fontSize={fontSize}
                    onEnter={() => {
                        handleEnter();
                    }}
                    onChangeCallback={(value) => {
                        handleSearch(value);
                    }}
                    onClickCallback={() => {
                        setSearchList(searchListMain);
                        if (searchListMain.length > 0) {
                            if (props.checkOnText) {
                                if (!isDropDownOpen && text.lenght > 0) {
                                    setDropDownState(true);
                                } else {
                                    setDropDownState(false);
                                }
                            } else {
                                if (!isDropDownOpen) {
                                    setDropDownState(true);
                                } else {
                                    setDropDownState(false);
                                }
                            }
                        }
                    }}
                />
            </div>

            <Spacer spaceType={VERTICAL_ULTRA_SMALL} />

            {isDropDownOpen ? (
                <Card elevation={5} className={classes.dropDownMenu} ref={ref}>
                    <List disablePadding>
                        {searchList.map((data, index) => (
                            <div key={index}>
                                <ListItem
                                    button
                                    key={index}
                                    disableGutters={true}
                                    className={classes.dropDownMenuList}
                                    onClick={() => {
                                        setDropDownState(false);
                                        handleAdd(data);
                                    }}
                                >
                                    <div className={classes.dropDownMenuText}>{data.lable}</div>
                                </ListItem>
                                {searchList.length - 1 !== index ? <Divider /> : <div />}
                            </div>
                        ))}
                    </List>
                </Card>
            ) : (
                <div />
            )}

            <div className="Centralize-Search-Chips-List-Parent">
                {chipsList.map((data, index) => (
                    <div
                        key={key}
                        className={props.customChipsClassName ?? 'Centralize-Search-Chips-Parent'}
                    >
                        <Chip
                            onClick={() => {
                                handleDelete(data);
                            }}
                            label={data.lable}
                            onDelete={() => {
                                handleDelete(data);
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
