import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import ContainedButtons from '../../../../Components/Button/Button';
import { APIHelper } from '../../../../utils/ApiHelper';
import API from '../../../../utils/Urls';

const RevisedMileStonePopup = (props) => {
    const { item } = props;
    const { jobs, contractNumber, users, milestone, profiles } = item;

    const milestoneReviewBtn = (agree) => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.putMethod(`${API.REVIEW_MILESTONE_BY_EMPLOYEE}${item._id}`, { agree }, headers)
            .then((res) => {
                Swal.fire({
                    customClass: 'Alert-Class',
                    title: 'Success!',
                    text: 'Milestones reviewed successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#1876D2',
                }).then((swapProp) => {
                    if (swapProp.isConfirmed) {
                        props.cancelBtn();
                    }
                });
                props.getListFixedJobs();
            })
            .catch((error) => console.log(error));
    };

    return (
        <div style={{ padding: 100, paddingTop: 30, fontFamily: 'Libre Franklin' }}>
            <div>
                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: 20, color: '#1C1C1C', fontWeight: 600 }}>
                        The following changes have been proposed to your contract, please review:
                    </p>
                    <CloseIcon onClick={() => props.cancelBtn()} style={{ cursor: 'pointer' }} />
                </div>
                <div style={{ marginTop: 30 }}>
                    <div style={{ display: 'flex', fontSize: 20 }}>
                        <p style={{ width: 350 }}>Job Title:</p>
                        <p>{item.jobs && item.jobs.length > 0 && item.jobs[0].title}</p>
                    </div>
                    <div style={{ display: 'flex', fontSize: 20, marginTop: 25 }}>
                        <p style={{ width: 350 }}>Contract No:</p>
                        <p>{contractNumber}</p>
                    </div>
                    <div style={{ display: 'flex', fontSize: 20, marginTop: 10 }}>
                        <p style={{ width: 350, lineHeight: '70px' }}>Client:</p>
                        <AccountCircleIcon
                            style={{
                                height: '70px',
                                width: '70px',
                            }}
                        />
                        <p style={{ lineHeight: '70px', marginLeft: 4 }}>
                            {profiles && profiles.length > 0 && `${profiles[0].company?.name}`}
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        paddingTop: 25,
                        paddingBottom: '30px',
                    }}
                >
                    <div
                        style={{
                            border: '2px solid #C4C4C4',
                            borderRadius: '15px',
                            marginTop: '15px',
                            paddingRight: '10px',
                            paddingLeft: '20px',
                        }}
                    >
                        <table
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                borderCollapse: 'collapse',
                                fontFamily: 'Libre Franklin',
                            }}
                            className="table-small-row-font"
                        >
                            <thead>
                                <tr style={{ height: 60, borderBottom: '2px solid #C4C4C4' }}>
                                    <th style={{ width: '14%', color: '#1c1c1c' }}>Milestones</th>
                                    <th style={{ width: '19%', color: '#1c1c1c' }}>
                                        Milestone Name
                                    </th>
                                    <th style={{ width: '19%', color: '#1c1c1c' }}>Status</th>
                                    <th style={{ width: '19%', color: '#1c1c1c' }}>
                                        Original Date
                                    </th>
                                    <th style={{ width: '19%', color: '#1c1c1c' }}>
                                        Proposed Date
                                    </th>
                                    <th style={{ width: '10%', color: '#1c1c1c' }}>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {milestone.map((mile, index) => (
                                    <tr
                                        style={{
                                            height: 59,
                                            borderBottom: '2px solid #C4C4C4',
                                            backgroundColor: 'inherit',
                                            width: '100%',
                                        }}
                                    >
                                        <td style={{ width: '14%', color: '#1c1c1c' }}>
                                            {index + 1}
                                        </td>
                                        <td style={{ width: '19%', color: '#1c1c1c' }}>
                                            {mile.description}
                                        </td>
                                        <td style={{ width: '19%', color: '#1c1c1c' }}>
                                            {mile.status}
                                        </td>
                                        <td style={{ width: '19%', color: '#1c1c1c' }}>
                                            {moment(mile.dateSchedule).utc(0).format('MMM DD, YY')}
                                        </td>
                                        <td style={{ width: '19%', color: '#E91515' }}>
                                            {mile.proposedDate
                                                ? moment(mile.proposedDate).utc(0).format('MMM DD, YY')
                                                : ''}
                                        </td>
                                        <td
                                            style={{
                                                width: '10%',
                                                color: '#1c1c1c',
                                            }}
                                        >
                                            ${mile.amount}
                                        </td>
                                    </tr>
                                ))}
                                <tr
                                    style={{
                                        height: 59,
                                    }}
                                >
                                    <td colspan="5" style={{ fontWeight: 'bold' }}>
                                        Total Amount
                                    </td>
                                    <td>
                                        $
                                        {milestone
                                            .map((x) => x.amount)
                                            .reduce((a, b) => parseFloat(a) + b, 0)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                        Do you agree with the proposed changes?
                    </p>
                </div>
                <div style={{ display: 'flex', marginTop: 20 }}>
                    <div style={{ marginRight: 10 }}>
                        <ContainedButtons
                            text="No"
                            color={['#a4c772', '#4e8400']}
                            height="56px"
                            width={'12vw'}
                            variant="outlined"
                            onClick={() => milestoneReviewBtn(false)}
                        />
                    </div>
                    <ContainedButtons
                        text="Yes"
                        height="56px"
                        width={'12vw'}
                        color={['#a4c772', '#4e8400']}
                        onClick={() => milestoneReviewBtn(true)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RevisedMileStonePopup;
