import React, { useEffect } from 'react';
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';
import '../HiringProcess.scss';

import Textfield from '../../../Components/TextField/Textfield';
import Dropdown from '../../../Components/DropdownComponent/Dropdown.component';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContainedButtons from '../../../Components/Button/Button';
import { APIHelper } from '../../../utils/ApiHelper';
import API from '../../../utils/Urls';
import DrawerComponent from '../../../Components/Drawer/Drawer';
import ReHiring from './ReHiring';
import Swal from 'sweetalert2';
import ContractInformation from '../../EmployeeHiringProcess/ProcessStep/Component/ContractInformation';
import HourlyContract from './Component/HourlyContract';
import FixedContract from './Component/FixedContract';

const StyledRating = withStyles({
    iconFilled: {
        color: '#1876d2',
    },
    sizeSmall: {
        fontSize: '1.8vw',
    },
    iconHover: {
        color: '#1876d2',
    },
})(Rating);

const WorkHistory = (props) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerOpenJobContract, setDrawerOpenJobContract] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [workHistoryData, setWorkHistoryData] = React.useState([]);
    const [sortBy, setSortBy] = React.useState({ label: 'End Date', value: 'endDate' });
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [skills, setSkills] = React.useState(0);
    const [punctuality, setPunctuality] = React.useState(0);
    const [communication, setCommunication] = React.useState(0);
    const [employeeID, setEmployeeID] = React.useState('');
    const [feedBack, setFeedBack] = React.useState('');
    const [jobID, setJobID] = React.useState('');

    const [jobData, setJobData] = React.useState({});
    const [jobLoading, setJobLoading] = React.useState(false);
    const [employer, setEmployer] = React.useState({});
    const [employerProfile, setEmployerProfile] = React.useState({});
    const [applicationData, setApplicationData] = React.useState({});
    const [contractInfo, setContractInfo] = React.useState({});

    const [isPaymentMethodVerified, setIsPaymentMethodVerified] = React.useState(false);

    React.useEffect(() => {
        getListWorkHistory();
    }, [page, sortBy]);

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
            APIHelper.getMethod(API.GET_EMPLOYER_PAYMENT_ACTIVE, headers)
            .then((res) => {
                setIsPaymentMethodVerified(res.result);
                console.log("aaaaa"+res.result);
            })
            .catch((error) => {});
    }, []);

    const getListWorkHistory = () => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(
            API.GET_WORK_HISTORY_END_CONTRACT + `${page}&sort=${sortBy ? sortBy.value : ''}`,
            headers
        )
            .then((res) => {
                if (res) {
                    setWorkHistoryData(res[0].data);
                    setTotal(res[0].total);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const giveRate = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        let body = {
            employeeRatings: {
                punctuality: punctuality,
                skills: skills,
                communication: communication,
            },
            feedback: feedBack,
            job_id: jobID,
        };
        APIHelper.postMethod(`${API.RATE_EMPLOYER}${employeeID}`, body, headers)
            .then((res) => {
                if (res && res === 'Feedback is already submitted') {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Information!',
                        text: 'Feedback has been already submitted!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((result) => {
                        if (result) {
                            setOpen(false);
                        }
                    });
                } else {
                    Swal.fire({
                        customClass: 'Alert-Class',
                        title: 'Success!',
                        text: 'Thank you for rating!',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#1876D2',
                    }).then((result) => {
                        if (result) {
                            setOpen(false);
                        }
                    });
                    getListWorkHistory();
                }
            })
            .catch((error) => {
                console.log(error);
                setOpen(false);
            });
    };

    const jobDetails = (id) => {
        setJobLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        APIHelper.getMethod(`${API.GET_JOB_ONE}${id}`, headers)
            .then((response) => {
                setJobData(response.job);
                setEmployer(response.employerModel);
                setEmployerProfile(response.employer);
                setJobLoading(false);
            })
            .catch((err) => {
                setJobLoading(false);
            });
    };

    return (
        <>
            <div className="editDetailsContentHeading">Work History</div>
            {open ? (
                <div id="overlay">
                    <div className="overlayImageStyle">
                        <div
                            style={{
                                width: '68.64vw',
                                height: 500,
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                            }}
                        >
                            <div onClick={() => setOpen(false)} className="inviteCardCrossStyles">
                                <ClearIcon />
                            </div>
                            <p
                                style={{
                                    marginTop: 35,
                                    textAlign: 'center',
                                    color: '#707070',
                                    fontSize: 30,
                                }}
                            >
                                Client Feedback Form
                            </p>
                            <div
                                style={{
                                    marginTop: 24,
                                    marginLeft: 150,
                                    color: '#1C1C1C',
                                    fontSize: 24,
                                }}
                            >
                                <p>Rate your experience working with this talent?</p>
                            </div>
                            <div style={{ display: 'flex', marginLeft: 150, marginTop: 10 }}>
                                <p style={{ minWidth: 150, color: '#707070', fontWeight: 400 }}>
                                    Punctuality
                                </p>
                                <StyledRating
                                    name="punctuality-feedback"
                                    size={'small'}
                                    value={punctuality}
                                    onChange={(e) => setPunctuality(e.target.value)}
                                    precision={0.5}
                                />
                            </div>
                            <div style={{ display: 'flex', marginLeft: 150, marginTop: 10 }}>
                                <p style={{ minWidth: 150, color: '#707070', fontWeight: 400 }}>
                                    Skills
                                </p>
                                <StyledRating
                                    name="skills-feedback"
                                    size={'small'}
                                    value={skills}
                                    onChange={(e) => setSkills(e.target.value)}
                                    precision={0.5}
                                />
                            </div>
                            <div style={{ display: 'flex', marginLeft: 150, marginTop: 10 }}>
                                <p style={{ minWidth: 150, color: '#707070', fontWeight: 400 }}>
                                    Communication
                                </p>
                                <StyledRating
                                    name="communication-feedback"
                                    size={'small'}
                                    value={communication}
                                    onChange={(e) => setCommunication(e.target.value)}
                                    precision={0.5}
                                />
                            </div>
                            <div
                                style={{
                                    borderBottom: '2px solid #dedede',
                                    width: '70%',
                                    marginLeft: 150,
                                    marginTop: 10,
                                }}
                            ></div>
                            <div style={{ marginLeft: 150, marginTop: 15, width: '70%' }}>
                                <p style={{ color: '#707070' }}>Feedback (optional)</p>
                                <div style={{ marginTop: 8 }}>
                                    <Textfield
                                        value={feedBack}
                                        rows={3}
                                        multiline
                                        onChange={(event) => setFeedBack(event.target.value)}
                                        width={'100%'}
                                    />
                                </div>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: 16 }}>
                                <ContainedButtons
                                    text="Submit"
                                    onClick={() => {
                                        giveRate();
                                    }}
                                    color={['#2493F1', '#15568D']}
                                    height={55}
                                    width={215}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '30px',
                }}
            >
                <p className="SortByDropdown">Sort by:</p>
                <div style={{ width: '170px' }}>
                    <Dropdown
                        options={[
                            { label: 'End Date', value: 'endDate' },
                            { label: 'Talent A-Z', value: 'employeeAtoZ' },
                            { label: 'Job Title', value: 'jobTitle' },
                        ]}
                        placeholder="Select sorting"
                        value={sortBy}
                        onChange={(e) => {
                            setPage(1);
                            setSortBy(e);
                        }}
                    />
                </div>
            </div>

            {!isPaymentMethodVerified && (
            <p
              style={{
                width: "75%",
                textAlign: "left",
                color: "red",
                marginBottom: 10,
              }}
            >
              Note: Your payment method is either expired or not verified. Please update your payment information.
            </p>
          )}
            
            <table
                style={{
                    width: '100%',
                    textAlign: 'left',
                    borderCollapse: 'collapse',
                    fontFamily: 'Libre Franklin',
                }}
            >
                <thead>
                    <tr style={{ borderBottom: '2px solid #C4C4C4', height: 60 }}>
                        <th style={{ width: '25%' }}>Talent Name</th>
                        <th style={{ width: '15%' }}>Job Title</th>
                        <th style={{ width: '15%' }}>End Date</th>
                        <th style={{ width: '15%' }}>Job Type</th>
                        <th style={{ width: '30%' }}></th>
                    </tr>
                </thead>
                {loading ? (
                    <tr style={{ textAlign: 'center', height: 100 }}>
                        <td colSpan="5">
                            <CircularProgress size={40} />
                        </td>
                    </tr>
                ) : (
                    <tbody>
                        {workHistoryData.length > 0 ? (
                            workHistoryData.map((item) => (
                                <tr
                                    style={{
                                        height: 86,
                                        borderBottom: '2px solid #C4C4C4',
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <td style={{ width: '25%', color: '#000', opacity: 0.78 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div>
                                                {item.profiles &&
                                                item.profiles.length > 0 &&
                                                item.profiles[0].photo ? (
                                                    <img
                                                        src={
                                                            item.profiles &&
                                                            item.profiles[0] &&
                                                            item.profiles[0].photo
                                                        }
                                                        style={{
                                                            width: 70,
                                                            height: 70,
                                                            borderRadius: 70,
                                                        }}
                                                    />
                                                ) : (
                                                    <AccountCircleIcon
                                                        style={{ height: '70px', width: '70px' }}
                                                    />
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: 'center',
                                                    margin: 'auto',
                                                    marginLeft: 10,
                                                }}
                                            >
                                                <p className="hiringEmployeeName">
                                                    {item.users &&
                                                        item.users.length > 0 &&
                                                        `${item.users[0].firstName} ${item.users[0].lastName}`}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                            width: '15%',
                                            color: '#1C1C1C',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setContractInfo(item);
                                            jobDetails(item.jobs[0]._id);
                                            setDrawerOpenJobContract(true);
                                            setApplicationData({
                                                proposeHourlyRate: item.proposeHourlyRate,
                                                empolinkServiceFee: item.empolinkServiceFee,
                                                employeeCompensation:
                                                    item.employeeCompensation || 0,
                                            });
                                        }}
                                    >
                                        {item.jobs && item.jobs.length > 0 && item.jobs[0].title}
                                    </td>
                                    <td style={{ width: '15%', color: '#1C1C1C' }}>
                                        {moment(item.jobEndOn).utc(0).format('MMM DD, YYYY')}
                                    </td>
                                    <td style={{ width: '15%', color: '#1C1C1C' }}>
                                        {item.contractType === 'FIXED' ? 'Fixed' : 'Hourly'}
                                    </td>
                                    <td style={{ width: '30%', textAlign: 'end' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <ContainedButtons
                                                text="Re-Hire"
                                                color={['#2493F1', '#15568D']}
                                                height={48}
                                                onClick={() => {
                                                    setJobID(item.job);
                                                    setEmployeeID(item.employee);
                                                    setDrawerOpen(true);
                                                }}
                                                disabled={item.contractType === 'FIXED'||!isPaymentMethodVerified}
                                            />
                                            <div style={{ marginLeft: 20 }}>
                                                <ContainedButtons
                                                    text="Rate Talent"
                                                    color={['#2493F1', '#15568D']}
                                                    disabled={item.feedback.find(
                                                        (x) => x.ratingBy === 'EMPLOYER'
                                                    )}
                                                    height={48}
                                                    onClick={() => {
                                                        setCommunication(0);
                                                        setPunctuality(0);
                                                        setSkills(0);
                                                        setFeedBack('');
                                                        setJobID(item.job);
                                                        setEmployeeID(item.employee);
                                                        setOpen(true);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr style={{ textAlign: 'center', height: 100 }}>
                                <td colSpan="5">
                                    <p>No data found.</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            {!loading && workHistoryData.length > 0 && (
                <div className="advancedSearchPagination" style={{ marginTop: 80 }}>
                    <Pagination
                        count={Math.ceil(total / 10)}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        style={{ justifyContent: 'center' }}
                        size="large"
                    />
                </div>
            )}
            <DrawerComponent
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                component={
                    <ReHiring
                        jobID={jobID}
                        onClose={() => setDrawerOpen(false)}
                        employeeID={employeeID}
                    />
                }
                width="80%"
            />
            <DrawerComponent
                open={drawerOpenJobContract}
                onClose={() => setDrawerOpenJobContract(false)}
                component={
                    // <ContractInformation
                    //     jobData={jobData}
                    //     jobLoading={jobLoading}
                    //     employer={employer}
                    //     employerProfile={employerProfile}
                    //     onClose={() => setDrawerOpenJobContract(false)}
                    //     applicationData={applicationData}
                    //     contractInfo={contractInfo}
                    //     isApplied
                    // />
                    <>
                    {
                    contractInfo.contractType==="HOURLY" ? 
                    <HourlyContract contractId={contractInfo._id}/> :
                    <FixedContract contractId={contractInfo._id} />           
                    }
                    </>
                }
                width="80%"
            />
        </>
    );
};

export default WorkHistory;
