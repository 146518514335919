import react from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { getAttachmentIcon } from '../../../../utils/commonFunction';

const HourlyContractPDFView = (props) => {
    const { data } = props;

    return (
        <div style={{ margin: 100, marginTop: 70, fontFamily: 'Libre Franklin' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>Contract (Hourly)</h3>
            </div>
            <div style={{ marginTop: 64, fontSize: 20 }}>
                <div style={{ display: 'flex' }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Contract No:</p>
                    <p>{data.contractNumber}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a', lineHeight: '70px' }}>Client:</p>
                    <AccountCircleIcon
                        style={{
                            height: '70px',
                            width: '70px',
                        }}
                    />
                    <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                        {data.employerProfileData &&
                            data.employerProfileData.length > 0 &&
                            data.employerProfileData[0].company &&
                            data.employerProfileData[0].company.name}
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Client Name:</p>
                    <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Client Address:</p>
                    <p>{data.employerData[0].location.country}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Talent Address:</p>
                    <p>{data.employeeData[0].location.country}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 200, color: '#9a9a9a' }}>Job Title:</p>
                    <p>{data.jobs[0].title}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ minWidth: 350, color: '#9a9a9a' }}>Field of Work:</p>
                    <p>{data.jobs[0].field && data.jobs[0].field.name}</p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 30,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: 59,
                    }}
                >
                    <p style={{ width: 350, color: '#9a9a9a' }}>Speciality:</p>
                    <p>{data.jobs[0].speciality && data.jobs[0].speciality.name}</p>
                </div>
                <div
                    style={{
                        marginTop: 59,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '50px',
                    }}
                >
                    <p style={{ minWidth: 350, color: '#9a9a9a' }}>Job Description:</p>
                    <pre
                        style={{
                            marginTop: 30,
                            color: '#1c1c1c',
                            fontsize: 20,
                            fontFamily: 'Libre Franklin',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {data.jobs[0].description}
                    </pre>
                </div>
                <div
                    style={{
                        marginTop: 59,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: '59px',
                    }}
                >
                    <p style={{ minWidth: 350, color: '#9a9a9a' }}>Additional Instructions:</p>
                    <pre
                        style={{
                            marginTop: 30,
                            color: '#1c1c1c',
                            fontsize: 20,
                            fontFamily: 'Libre Franklin',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {data.jobs[0].additionalDetails}
                    </pre>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: 59,
                        borderBottom: '2px solid #C4C4C4',
                        paddingBottom: 59,
                    }}
                >
                    <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                    <p>
                        {data.jobs[0].remoteWorking
                            ? 'Remote Working'
                            : `${data.jobs[0].workAddress.street_address}, ${data.jobs[0].workAddress.unit_number}, ${data.jobs[0].workAddress.city}, ${data.jobs[0].workAddress.state}, ${data.jobs[0].workAddress.postal_code}`}
                    </p>
                </div>
                <div style={{ marginTop: 59 }}>
                    <p style={{ width: 350, color: '#9a9a9a' }}>Work Schedule:</p>
                    <div
                        style={{
                            border: '2px solid #C4C4C4',
                            borderRadius: '15px',
                            marginTop: '15px',
                            paddingRight: '10px',
                            paddingLeft: '20px',
                            height: 350,
                            overflow: 'auto',
                        }}
                    >
                        <table
                            style={{
                                width: '100%',
                                textAlign: 'left',
                                borderCollapse: 'collapse',
                                fontFamily: 'Libre Franklin',
                                height: 350,
                                overflow: 'auto',
                            }}
                            className="table-small-row-font"
                        >
                            <thead>
                                <tr
                                    style={{
                                        height: 60,
                                        borderBottom: '2px solid #C4C4C4',
                                    }}
                                >
                                    <th
                                        style={{
                                            width: '12%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Date
                                    </th>
                                    <th
                                        style={{
                                            width: '12%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Start Time
                                    </th>
                                    <th
                                        style={{
                                            width: '12%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        End Time
                                    </th>
                                    <th
                                        style={{
                                            width: '20%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Non-Payable (hr)
                                    </th>
                                    <th
                                        style={{
                                            width: '15%',
                                            color: '#1c1c1c',
                                        }}
                                    >
                                        Working Hours
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.schedule.map((element, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            style={{
                                                height: 60,
                                                borderBottom: '2px solid #C4C4C4',
                                                backgroundColor: 'inherit',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    width: '12%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {moment(element.dateSchedule)
                                                    .utc(0)
                                                    .format('MMM DD, YYYY')}
                                            </td>
                                            <td
                                                style={{
                                                    width: '12%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {moment(element.startTime).format('hh:mm A')}
                                            </td>
                                            <td
                                                style={{
                                                    width: '12%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {moment(element.endTime).format('hh:mm A')}
                                            </td>
                                            <td
                                                style={{
                                                    width: '12%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {element.nonPayableTime}
                                            </td>
                                            <td
                                                style={{
                                                    width: '12%',
                                                    color: '#1c1c1c',
                                                }}
                                            >
                                                {element.totalHours}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'space-between',
                        marginTop: 30,
                        marginBottom: 50,
                    }}
                >
                    <p style={{ width: 350, color: '#9a9a9a' }}>Total Working Hours:</p>
                    <div
                        style={{
                            border: '2px solid #C4C4C4',
                            borderRadius: '5px',
                            marginRight: '50px',
                            padding: '12px',
                        }}
                    >
                        {data.jobs[0].hourlyTotalHours}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        paddingTop: 59,
                        borderTop: '2px solid #C4C4C4',
                    }}
                >
                    <p style={{ width: 300, color: '#9a9a9a' }}>Job Value:</p>
                    <p>${parseFloat(data.finalFee)}</p>
                </div>
                {data.jobs[0].paymentMethod === 'DIRECT' ? (
                    <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                        Client will pay an amount equal to job value directly to talent.
                    </p>
                ) : (
                    <>
                        <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                            Client will pay an amount equal to job value to empolink.
                        </p>
                        <p style={{ color: '#9a9a9a', marginTop: '7px' }}>
                            Empolink will pay talent an amount equal to job value deducted by
                            talent service fee.
                        </p>
                    </>
                )}
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ width: 300, color: '#9a9a9a' }}>Client Payment Method:</p>
                    <p>{data.jobs[0].paymentMethod}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ width: 300, color: '#9a9a9a' }}>Payment Cycle:</p>
                    <p>{data.jobs[0].paymentFrequency}</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ width: 300, color: '#9a9a9a' }}>Date:</p>
                    <p>Jun 20 2021</p>
                </div>
                <div style={{ display: 'flex', marginTop: 30 }}>
                    <p style={{ width: 300, color: '#9a9a9a' }}>Time:</p>
                    <p>12:00 AM</p>
                </div>
            </div>
        </div>
    );
};
export default HourlyContractPDFView;
