import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import TextBox from '../../Components/TextField/Textfield';
// import Button from '@material-ui/core/Button';
import CustomButton from '../../Components/Button/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { APIHelper } from '../../utils/ApiHelper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './LoginPage.scss';
import { withRouter } from 'react-router-dom';
import { ValidateEmail } from '../../utils/validations';
import { MicrosoftLogin } from 'react-microsoft-login';
import CONSTANTS from '../../utils/constants';
import ErrorIcon from '@material-ui/icons/Error';
import API from '../../utils/Urls';
import { connect } from 'react-redux';
import { HIDE_HEADER, SHOW_HEADER } from '../../action/talent_search_action';
import DashboardIcon from '../../Assets/DashboardIcon.svg';
import LoginLogo from '../../Assets/images/login-logo1.png';
import LoginImg from '../../Assets/images/login-img.png';
import GoogleIcon from '../../Assets/images/google.png';
import MicrosoftIcon from '../../Assets/images/microsoft.png';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isRememberMeChecked: false,
            showPassword: false,
            showLoginError: false,
            isUsernameValid: true,
            isPasswordValid: false,
            showPaymentExpired: false,
        };
    }

    onSignUpClicked() {
        this.props.history.push(CONSTANTS.ROUTES_CHOOSE_EMPLOYER_EMPLOYEE);
    }

    componentDidMount() {
        if (localStorage.getItem('empoLinkUsername')) {
            this.setState({ username: localStorage.getItem('empoLinkUsername') });
        }

        this.props.showHeader();
    }

    render() {
        const handleOnLogin = () => {
            if (
                this.state.username !== '' &&
                this.state.password !== '' &&
                this.state.isUsernameValid
            ) {
                let user = this.state.username;
                if (this.state.isRememberMeChecked) {
                    localStorage.setItem('empoLinkUsername', user);
                }

                const params = new URLSearchParams();
                params.append('email', this.state.username?.trim());
                params.append('password', this.state.password?.trim());
                let headers = {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                };
                APIHelper.postMethod(API.LOGIN, params, headers)
                    .then((response) => {
                        if (response['status'] === 1) {
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('username', response.data.email);
                            localStorage.setItem(
                                'fullName',
                                `${response.data.firstName} ${response.data.lastName}`
                            );
                            localStorage.setItem('userType', response.data.userType);
                            localStorage.setItem('fieldData', JSON.stringify(response.data.field));
                            localStorage.setItem('userId', response.data._id);
                            localStorage.setItem('is_suspend', response.data.is_suspend);
                            localStorage.setItem('suspended_date', response.data.suspended_date);
                            localStorage.setItem(
                                'localCurrency',
                                response.data.countryDetail && response.data.countryDetail.currency
                            );
                            localStorage.setItem('local_currency_symbol', response.data?.countryDetail?.currency_symbol)
                            localStorage.setItem(
                                'country',
                                response.data.countryDetail && response.data.countryDetail.name
                            );
                            localStorage.setItem(
                                'card_expired',
                                response.data.card_expired || false
                            );

                            if (response.data.userType === 'EMPLOYEE') {
                                if (response.data.card_expired || response.data.card_expired === undefined ||
                                    response.data.stripe_status === "pending" || response.data.stripe_status === undefined) {
                                    window.location.href = '/employee/account-details?payment=true';
                                }
                                else {
                                    if (response.data.account_status === 'not_confirmed') {
                                        localStorage.setItem('username', this.state.username);
                                        this.props.history.push('/verify-email');
                                    } else {
                                        if (response.data.userType === 'EMPLOYEE') {
                                            this.props.history.push(CONSTANTS.EMPLOYEE_SEARCH_TALENT);
                                        } else {
                                            this.props.history.push(CONSTANTS.ROUTES_TALENT_SEARCH);
                                        }
                                    }
                                }
                            }
                            else {
                                if (response.data.card_expired) {
                                    window.location.href = '/account-details?payment=true';
                                }
                                else {
                                    if (response.data.account_status === 'not_confirmed') {
                                        localStorage.setItem('username', this.state.username);
                                        this.props.history.push('/verify-email');
                                    } else {
                                        if (response.data.userType === 'EMPLOYEE') {
                                            this.props.history.push(CONSTANTS.EMPLOYEE_SEARCH_TALENT);
                                        } else {
                                            this.props.history.push(CONSTANTS.ROUTES_TALENT_SEARCH);
                                        }
                                    }
                                }
                            }
                        }
                        if (response['status'] === 0) {
                            this.setState({ showLoginError: true });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ showLoginError: true });
                    });
            } else {
                this.setState({ isPasswordValid: true });
                this.setState({ isUsernameValid: ValidateEmail(this.state.username) });
            }
        };

        const onUserNameEntered = (event) => {
            this.setState({ username: event.target.value });
        };

        const validemail = (e) => {
            if (this.state.username !== '') {
                this.setState({ isUsernameValid: ValidateEmail(e.target.value) });
            }
        };

        const authHandler = (err, data) => {
            console.log(err, data);
        };

        const responseGoogle = (response) => {
            this.props.history.push('./signup');
        };

        const onForgotPasswordClicked = () => {
            this.props.history.push('/forgot');
        };

        const onPasswordEntered = (event) => {
            this.setState({ isUsernameValid: ValidateEmail(this.state.username) });
            this.setState({ password: event.target.value, isPasswordValid: false });
        };

        return (
            <>
                <section class="login-wrapper">
                    <div class="container">
                        <div class="row row-center">
                            <div class="col-md-6">
                                <div class="login-img">
                                    <a
                                        href="javascript:void(0)"
                                        class="login-logo"
                                        onClick={() => this.props.history.push('/')}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img src={LoginLogo} alt="" />
                                    </a>
                                    <img src={LoginImg} alt="" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="login-right">
                                    <h2>Log in</h2>
                                    <div class="form-col">
                                        <div class="form-style form-email">
                                            {/* <input
                                            type="email"
                                            placeholder="Enter your email address"
                                        /> */}
                                            <TextBox
                                                required={true}
                                                label={'Email'}
                                                onChange={(event) => onUserNameEntered(event)}
                                                onBlur={(e) => validemail(e)}
                                                value={this.state.username}
                                                width={'100%'}
                                                error={!this.state.isUsernameValid}
                                            />
                                        </div>
                                    </div>
                                    <div class="form-col">
                                        <div class="form-style form-password">
                                            {/* <input type="password" placeholder="Enter your password" />
                                        <i class="toggle-password fa fa-fw fa-eye-slash"></i> */}
                                            <TextBox
                                                required={true}
                                                label={'Password'}
                                                error={this.state.isPasswordValid}
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                onChange={(event) => onPasswordEntered(event)}
                                                width={'100%'}
                                                InputProps={{
                                                    // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        showPassword:
                                                                            !this.state
                                                                                .showPassword,
                                                                    })
                                                                }
                                                                edge="end"
                                                            >
                                                                {this.state.showPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div class="form-col">
                                        <div class="form-flex">
                                            <div class="form-left">
                                                {/* <label>
                                                <input type="checkbox" /> Remember Me
                                            </label> */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            style={{
                                                                color: '#2699fb',
                                                            }}
                                                            checked={this.state.isRememberMeChecked}
                                                            onChange={() => {
                                                                this.setState({
                                                                    isRememberMeChecked:
                                                                        !this.state
                                                                            .isRememberMeChecked,
                                                                });
                                                            }}
                                                            name="rememberMe"
                                                        />
                                                    }
                                                    label={
                                                        <div className="rememberMe">
                                                            {' '}
                                                            Remember Me{' '}
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div class="form-right">
                                                <a
                                                    href="javascript:void(0)"
                                                    onClick={() => onForgotPasswordClicked()}
                                                >
                                                    Forgot?
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showLoginError ? (
                                        <div className="loginError">
                                            <ErrorIcon
                                                style={{
                                                    fontSize: '1.1vw',
                                                    marginBottom: '-2.5px',
                                                    marginRight: '2px',
                                                }}
                                            />
                                            We don't recognize your username and/or password.
                                            <br />{' '}
                                            <div
                                                style={{
                                                    marginLeft: '1.4vw',
                                                    display: 'inline-block',
                                                }}
                                            >
                                                Please try again or
                                            </div>
                                            <span
                                                style={{
                                                    textDecoration: 'underline',
                                                    marginLeft: '3px',
                                                }}
                                            >
                                                Forgot
                                            </span>
                                        </div>
                                    ) : null}
                                    <div class="form-col">
                                        <input
                                            type="submit"
                                            value="Log in"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => handleOnLogin()}
                                        />
                                    </div>
                                    <div class="form-seperator">
                                        <span>Or</span>
                                    </div>
                                    <div className="login-method">
                                        {/* <a href="javascript:void(0)"> */}
                                        <span style={{ display: 'inline-block', cursor: 'not-allowed', pointerEvents: 'auto' }}>
                                            <img src={GoogleIcon} alt="" /> Log in with Google
                                        </span>
                                        {/* </a> */}
                                    </div>
                                    <div className="login-method">
                                        {/* <a href="javascript:void(0)"> */}
                                        <span style={{ display: 'inline-block', cursor: 'not-allowed', pointerEvents: 'auto' }}>
                                            <img src={MicrosoftIcon} alt="" /> Log in with Microsoft
                                        </span>
                                        {/* </a> */}
                                    </div>
                                    <div class="form-link">
                                        Don’t have an account yet?{' '}
                                        <a
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                this.onSignUpClicked();
                                            }}
                                        >
                                            Sign up
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* {this.state.showPaymentExpired && (
                    <div id="overlay">
                        <div
                            style={{
                                width: '38vw',
                                height: '15vw',
                                backgroundColor: 'white',
                                position: 'absolute',
                                borderRadius: '10px',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%,-50%)',
                                padding: 20,
                                textAlign: 'center',
                            }}
                        >
                            <p style={{ marginTop: 24 }}>
                                Your payment method is either expired or not verified. Please update
                                your payment information.
                            </p>
                            <div
                                style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}
                            >
                                <CustomButton
                                    text="Update Payment Method"
                                    height="56px"
                                    // width={'12vw'}
                                    variant="outlined"
                                    color={['#2699fb', '#134d7e']}
                                    onClick={() => {
                                        if (localStorage.getItem('userType') === 'EMPLOYEE') {
                                            window.location.href =
                                                '/employee/account-details?payment=true';
                                        } else {
                                            window.location.href = '/account-details?payment=true';
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )} */}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showHeader: () => dispatch({ type: HIDE_HEADER }),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(LoginPage));
