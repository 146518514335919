import React from "react";
const InvoicesPDFView = (props) => {
    return(
        <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
            <div
                className="empoHeaderHeading"
                style={{ cursor: 'pointer',color:"#1876d2" }}
            >
                EMPOLINK
            </div>
            <div style={{marginTop:60,color:"#1c1c1c",display:'flex',borderBottom:"2px solid #9a9a9a", paddingBottom:20}}>
                <div style={{width:500}}>
                    {props.userType !== "EMPLOYEE" ? 
                        <React.Fragment>
                            <p style={{fontSize:28,marginBottom:15}}>ABC Co.</p>
                            <p style={{fontSize:16}}>John smith</p>
                        </React.Fragment> : <p style={{fontSize:24}}>John smith</p>
                    }
                    <p style={{width:'250px',marginTop:20,fontSize:16}}>
                        Street address, Unit number, City, 
                        State ZIP Country
                    </p>
                </div>
                <div>
                    <p style={{fontSize:22}}>Invoice</p>
                    <div style={{display:"flex", marginTop:10}}>
                        <p style={{width:100,color:"#9a9a9a"}}>Contract No:</p>
                        <p style={{color:'#1c1c1c'}}>ABCD-EFGH</p>
                    </div>
                    <div style={{display:"flex", marginTop:10}}>
                        <p style={{width:100,color:"#9a9a9a"}}>Invoice No:</p>
                        <p style={{color:'#1c1c1c'}}>ABC-1234567</p>
                    </div>
                    <div style={{display:"flex", marginTop:10}}>
                        <p style={{width:100,color:"#9a9a9a"}}>Invoice Date:</p>
                        <p style={{color:'#1c1c1c'}}>August 2,2021</p>
                    </div>
                </div>
            </div>

            <div style={{marginTop:40}}>
                <div style={{display:'flex', marginTop:20}}>
                    <p style={{color:'#9a9a9a', width:200}}>Job Title:</p>
                    <p style={{color:'#1c1c1c'}}>UI Designer</p>
                </div>
                {props.userType === "EMPLOYEE" ? 
                    <React.Fragment>
                    <div style={{display:'flex', marginTop:20}}>
                        <p style={{color:'#9a9a9a', width:200}}>Client:</p>
                        <p style={{color:'#1c1c1c'}}>ABC Co.</p>
                    </div>
                    <div style={{display:'flex', marginTop:20}}>
                        <p style={{color:'#9a9a9a', width:200}}>Client Name:</p>
                        <p style={{color:'#1c1c1c'}}>Charles Banks</p>
                    </div>
                    </React.Fragment>
                         : 
                    <div style={{display:'flex', marginTop:20}}>
                        <p style={{color:'#9a9a9a', width:200}}>Talent Name:</p>
                        <p style={{color:'#1c1c1c'}}>Charles Banks</p>
                    </div>     
                }
                {props.type === "HOURLY" ? 
                    <React.Fragment>
                        <div style={{display:'flex', marginTop:20}}>
                            <p style={{color:'#9a9a9a', width:200}}>Work Period:</p>
                            <p style={{color:'#1c1c1c'}}>Jul 15,21 - Nov 25,21</p>
                        </div>
                        <div style={{display:'flex', marginTop:20}}>
                            <p style={{color:'#9a9a9a', width:200}}>Total Hours:</p>
                            <p style={{color:'#1c1c1c'}}>40</p>
                        </div>
                    </React.Fragment> : null
                }
                {props.type === "FIXED" ? 
                    <React.Fragment>
                        <div style={{display:'flex', marginTop:20}}>
                            <p style={{color:'#9a9a9a', width:200}}>Milestone Name:</p>
                            <p style={{color:'#1c1c1c'}}>1</p>
                        </div>
                        <div style={{display:'flex', marginTop:20}}>
                            <p style={{color:'#9a9a9a', width:200}}>Milestone Date:</p>
                            <p style={{color:'#1c1c1c'}}>Jul 15,21</p>
                        </div>
                    </React.Fragment> : null
                }
                <div style={{display:'flex', marginTop:20}}>
                    <p style={{color:'#9a9a9a', width:200}}>Rate:</p>
                    <p style={{color:'#1c1c1c'}}>$30.00</p>
                </div>
                <div style={{display:'flex', marginTop:20}}>
                    <p style={{color:'#9a9a9a', width:200}}>Client Payment Method:</p>
                    <p style={{color:'#1c1c1c'}}>Directly by Client</p>
                </div>
                <div style={{display:'flex', marginTop:20}}>
                    <p style={{color:'#9a9a9a', width:200}}>Job Value:</p>
                    <p style={{color:'#1c1c1c'}}>$A</p>
                </div>
                <div style={{display:'flex', marginTop:20}}>
                    <p style={{color:'#9a9a9a', width:200}}>Empolink Service Fee:</p>
                    <p style={{color:'#1c1c1c'}}>$B</p>
                </div>
                <div style={{display:'flex', marginTop:20}}>
                    <p style={{color:'#9a9a9a', width:200}}>Total amount charged to your account:</p>
                    <p style={{color:'#1c1c1c'}}>$00.00</p>
                </div>
            </div>
        </div>
    )
}

export default InvoicesPDFView;