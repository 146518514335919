import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContainedButtons from '../../../../Components/Button/Button';
import Dropdown from '../../../../Components/DropdownComponent/Dropdown.component';
import TextField from '../../../../Components/TextField/Textfield';
import { APIHelper } from '../../../../utils/ApiHelper';
import API from '../../../../utils/Urls';
import CircularProgress from '@material-ui/core/CircularProgress';

const HourlyContractRequest = (props) => {
    const [reason, setReason] = React.useState('');
    const [reasonType, setReasonType] = React.useState('Emergency Situation');
    const [resonTypeError, setReasonTypeError] = React.useState(false);
    const [resonError, setReasonError] = React.useState(false);

    const [data, setData] = React.useState(props.contractData);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_CONTRACT_DETAILS}${props.contractId}`, headers)
            .then((res) => {
                setData(res[0]);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const cancelJob = () => {
        if (!reasonType) {
            setReasonTypeError(true);
        }

        let body = {
            reasonType: reasonType,
        };

        if (reasonType === 'Other') {
            body.reason = reason;
            if (reason.trim() === '') {
                setReasonError(true);
            }
        } else {
            body.reason = '';
        }

        if (reasonType && (reasonType === 'Other' ? reason !== '' : true)) {
            props.cancelJob(props.contractId, body);
        }
    };
    return (
        <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
            <div style={{ display: 'flex' }}>
                <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>Job Cancellation</h3>
            </div>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: 200 }}>
                    <CircularProgress size={40} />
                </div>
            ) : data !== undefined ? (
                <div style={{ marginTop: 64, fontSize: 20 }}>
                    <div style={{ display: 'flex' }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Contract No:</p>
                        <p>{data.contractNumber}</p>
                    </div>
                    {data.employerProfileData &&
                        data.employerProfileData.length > 0 &&
                        data.employerProfileData[0].company && (
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a', lineHeight: '70px' }}>Client:</p>
                                <AccountCircleIcon
                                    style={{
                                        height: '70px',
                                        width: '70px',
                                    }}
                                />
                                <div style={{ lineHeight: '70px', marginLeft: '10px' }}>
                                    {data.employerProfileData &&
                                        data.employerProfileData.length > 0 &&
                                        data.employerProfileData[0].company &&
                                        data.employerProfileData[0].company.name}
                                </div>
                            </div>
                        )}
                    <div style={{ display: 'flex', marginTop: 14 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                        <p>{`${data.employerData[0].firstName} ${data.employerData[0].lastName}`}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Talent Name:</p>
                        <p>{`${data.employeeData[0].firstName} ${data.employeeData[0].lastName}`}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                        <p>{data.jobs[0].title}</p>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Work Start Date:</p>
                        <p>{moment(data.jobs[0].startDate).utc(0).format('MMM DD, YYYY')}</p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: 30,
                            borderBottom: '2px solid #C4C4C4',
                            paddingBottom: 83,
                        }}
                    >
                        <p style={{ width: 350, color: '#9a9a9a' }}>Work End Date:</p>
                        <p>{moment(data.jobs[0].endDate).utc(0).format('MMM DD, YYYY')}</p>
                    </div>
                    <div style={{ marginTop: 79 }}>
                        <p style={{ width: 350, color: '#9a9a9a' }}>Cancellation reason</p>
                        <div style={{ width: '50%', marginTop: 30 }}>
                            <Dropdown
                                options={[
                                    { label: 'Unsafe Workplace', value: 'Unsafe Workplace' },
                                    { label: 'Emergency Situation', value: 'Emergency Situation' },
                                    { label: 'Other', value: 'Other' },
                                ]}
                                placeholder="Select reason"
                                // value={sortBy}
                                onChange={(e) => {
                                    setReason('');
                                    setReasonType(e.value);
                                    setReasonTypeError(false);
                                }}
                                error={resonTypeError}
                                value={{ label: reasonType, value: reasonType }}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: 30,
                                paddingBottom: 59,
                                borderBottom: '2px solid #C4C4C4',
                            }}
                        >
                            <TextField
                                rows={8}
                                multiline
                                disabled={reasonType !== 'Other'}
                                width={'96%'}
                                label={'Reason'}
                                value={reason}
                                onChange={(e) => {
                                    setReason(e.target.value);
                                    setReasonError(false);
                                }}
                                error={resonError}
                            />
                        </div>
                        <div
                            style={{
                                marginTop: 59,
                                paddingBottom: 59,
                                borderBottom: '2px solid #C4C4C4',
                            }}
                        >
                            <p style={{ color: '#9a9a9a', fontSize: 20 }}>
                                Your timesheet for the work done to date will be submitted as
                                planned.
                            </p>
                        </div>
                        <div
                            style={{
                                padding: '41px 30px',
                                backgroundColor: '#d5d5d5',
                                borderRadius: 10,
                                marginTop: 59,
                            }}
                        >
                            <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                                Warning: This cancellation is final once sent. It is highly
                                recommended that cancellation shall not happen unless in extreme
                                circumstances where talent has no other choice. Cancellation will
                                lead to a negative point for talent unless it is mutually agreed
                                by client and talent.
                            </p>
                        </div>
                        <div style={{ marginTop: 60 }}>
                            <p style={{ fontSize: 20, color: '#010101', fontWeight: 600 }}>
                                Are you sure you want to cancel the job?
                            </p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 30 }}>
                            <div style={{ marginRight: 10 }}>
                                <ContainedButtons
                                    text="No"
                                    color={['#a4c772', '#4e8400']}
                                    height="56px"
                                    width={'12vw'}
                                    onClick={() => {
                                        props.handleClose();
                                    }}
                                />
                            </div>
                            <ContainedButtons
                                text="Yes"
                                color={['#a4c772', '#4e8400']}
                                height="56px"
                                variant="outlined"
                                width={'12vw'}
                                onClick={() => {
                                    cancelJob();
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default HourlyContractRequest;
