import React from 'react'
import GaugeChart from 'react-gauge-chart'

class ScoreChart extends React.Component {
  state = {
    percent: 0.35,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        percent: 0.67,
      })
    }, 10000)

    setTimeout(() => {
      this.setState({
        percent: 0.98,
      })
    }, 20000)
  }

  render() {
    return (
      <div >
        {/* <GaugeChart
          id={`gauge-chart2`}
          style={{ width:"8vw",color:"black"}}
          nrOfLevels={2}
          textColor={"#000000"}
          percent={this.props.score/this.props.totalScore}
          // formatTextValue={() => {return 650} }
          colors={['#2185d0', '#c4c4c4']}
          hideText={true}
        /> */}
        <div style={{ width: "150px" }}>
          <GaugeChart id="gauge-chart2"
            nrOfLevels={20}
            percent={this.props.score/this.props.totalScore}
            />
        </div>

        {/* <div style={{
          position: "absolute",
          marginTop: "-3.2vw",
          marginLeft: "3.35vw",
          fontSize: "0.8vw",
          fontWeight: "700",
        }}>650</div> */}
      </div>
    )
  }
}

export default ScoreChart;
