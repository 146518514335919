import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import { useEffect, useState, useRef } from 'react';
import SearchMoreSkill from './centralize_input_text';
import Spacer from './spacer';
import { VERTICAL_ULTRA_SMALL } from '../../../utils/enums';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function CentralizeToogleButton(props) {
    const [value, setValue] = React.useState(props.defaultSelectedValue ?? 1);

    const [isLeftSelected, setSelected] = React.useState(true);

    const handleAlignment = (event, newValue) => {
        if (newValue) {
            setValue(newValue);

            if (props.onSelectedValueCallback !== undefined) {
                if (newValue == 1) {
                    props.onSelectedValueCallback(props.leftName);
                } else {
                    props.onSelectedValueCallback(props.rightName);
                }
            }
        }
    };

    return (
        <div>
            <ToggleButtonGroup
                className={
                    props.isGreyColor
                        ? value === 1
                            ? 'Centralise-Toggle-Button-Parent-Left-disabled'
                            : 'Centralise-Toggle-Button-Parent-Right-disabled'
                        : value === 1
                        ? 'Centralise-Toggle-Button-Parent-Left'
                        : 'Centralise-Toggle-Button-Parent-Right'
                }
                value={value}
                exclusive
                selected={true}
                onChange={handleAlignment}
                style={props.style}
            >
                <ToggleButton
                    value={1}
                    className="Centralise-Toggle-Button"
                    disabled={props.disabled}
                >
                    {props.leftName}
                </ToggleButton>
                <ToggleButton value={2} disabled={props.disabled} ToggleButton>
                    {props.rightName}
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
}
