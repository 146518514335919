import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './HeaderPublic.scss';
import EmpolinkLogo from '../../Assets/images/logo.svg';
import InvisibleLogo from '../../Assets/images/invisable-logo.svg';
import '../../Assets/dev-style.scss';

class HeaderPublic extends Component {
    constructor(props) {
        super(props);
        this.state = { scrolled: false };
    }

    componentDidMount() {
        if (!this.props.forceScrolled) {
            const handleScroll = () => {
                if (window.pageYOffset > 1) {
                    this.setState({ scrolled: true });
                } else {
                    this.setState({ scrolled: false });
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            this.setState({ scrolled: true });
        }
    }

    render() {
        const isScrolled = this.props.forceScrolled || this.state.scrolled;
        return (
            <header className={`page-header ${isScrolled ? "fixed" : ""}`}>
                <div className="container">
                    <div className="header-wrapper">
                        <div className="logo-public">
                            <a href="/">
                                <img src={EmpolinkLogo} alt="Empolink" />
                                <span className="invisable-img">
                                    <img src={InvisibleLogo} alt="Empolink" />
                                </span>
                            </a>
                        </div>
                        <div className="header-button">
                            <ul>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="signup"
                                        onClick={() => this.props.history.push('/signup')}
                                    >
                                        Sign up
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="login"
                                        onClick={() => this.props.history.push('/login')}
                                    >
                                        Log in
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withRouter(HeaderPublic);
