import React, { useEffect, useState } from "react";
import "./EmployeeRegister.scss";
import TextBox from "../../Components/TextField/Textfield";
import Button from "../../Components/Button/Button";
import Stepper from "../../Components/Stepper/Stepper";
import { useHistory, withRouter } from "react-router-dom";
import Dropdown from "../../Components/DropdownComponent/Dropdown.component";
import { APIHelper } from "../../utils/ApiHelper";
import API from "../../utils/Urls";
import EmpolinkLogo from '../../Assets/images/login-logo1.png';
import SignUpLogo from '../../Assets/images/signup-img.png';

const EmployeeRegisterPageFour = ({ handleRegisterData, setCurrentPage }) => {
  const [fieldOptions, setFieldOptions] = useState([]);
  const [field, setField] = useState({});
  const [fieldError, setFieldError] = useState(false);
  const [fieldWork, setFieldWork] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [yearsOfExperienceError, setYearsOfExperienceError] = useState(false);

  const history = useHistory();
  // }
  useEffect(() => {
    APIHelper.getMethod(API.GET_FIELDS).then((response) => {
      if (response["status"] === 1) {
        setFieldOptions(
          response.data.map((field) => ({
            ...field,
            label: field.field_name,
            value: field.field_id,
          }))
        );
      }
    });
  }, []);

  function onNextClicked() {
    if (Object.keys(field).length === 0) {
      setFieldError(true);
    }
    if (yearsOfExperience === "" || parseFloat(yearsOfExperience) > 99) {
      setYearsOfExperienceError(true);
    } else if (field.field_name !== "" && yearsOfExperience !== "") {
      handleRegisterData({ field, fieldWork, yearsOfExperience });
      setCurrentPage();
    }
  }

  return (
    <>
      <div className="login-wrapper-step sign-up-body signUp-1">
        <div className="container">
          <div className="row row-center">
            <div className="col-md-4">
              <div className="login-img">
                <a className="login-logo" href={void (0)} style={{ cursor: 'pointer' }} onClick={() => { history.push("/") }}>
                  <img src={EmpolinkLogo} alt="Empolink" />
                </a>
              </div>
            </div>
            <div className="col-md-8">
              <div className="login-right">
                <div>
                  <div>
                    <h1 className="signUpHeading">Sign Up</h1>
                    <div
                      className="signUpSubHeading"
                      style={{ paddingBottom: "69.5px" }}
                    >
                      Personal Information
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "82.5px",
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: "49px",
                        display: "grid",
                      }}
                    >
                      <Dropdown
                        options={fieldOptions}
                        placeholder={"Field of work *"}
                        onChange={(item) => {
                          setFieldError(false);
                          setField(item);
                        }}
                        isSearchable={false}
                        error={fieldError}
                      />
                    </div>
                    <div style={{ paddingBottom: "18px" }}>
                      <TextBox
                        required={true}
                        label={"Years of Experience"}
                        type="number"
                        onChange={(event) => {
                          if (parseFloat(event.target.value) >= 0 || event.target.value === "") {
                            setYearsOfExperienceError(false);
                            setYearsOfExperience(event.target.value);
                          } else {
                            setYearsOfExperienceError(true);
                          }
                        }}
                        value={yearsOfExperience}
                        error={yearsOfExperienceError}
                        width={"100%"}
                      />
                    </div>
                    {/* <div style={{ paddingBottom: "18px" }}>
                  <TextBox
                    label={"Professional Highlight"}
                    onChange={(event) => {
                      setFieldWork(event.target.value);
                    }}
                    value={fieldWork}
                    multiline={true}
                    rows={11}
                    width={"100%"}
                  />
                </div> */}
                  </div>
                  <div>
                    <div className="signUp-btn">
                      <Button
                        text="Next"
                        width={"100%"}
                        height="56px"
                        onClick={onNextClicked}
                        color={["#a4c772", "#4e8400"]}
                      />
                    </div>
                  </div>
                  <div style={{ textAlign: "center", paddingTop: "47px" }}>
                    <Stepper steps={5} activeStep={2} color={"#a4c772"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(EmployeeRegisterPageFour);
