import React from 'react';
import Chip from '@material-ui/core/Chip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import moment from 'moment';
import Swal from 'sweetalert2';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContainedButtons from '../../../../Components/Button/Button';
import { JOB_TYPE } from '../../../../utils/enums';
import Textfield from '../../../../Components/TextField/Textfield';
import CheckboxComponent from '../../../../Components/CheckboxComponent/CheckboxComponent';
import API from '../../../../utils/Urls';
import { APIHelper } from '../../../../utils/ApiHelper';
import { convertFrom24To12Format, getAttachmentIcon } from '../../../../utils/commonFunction';

const JobApplicationApply = (props) => {
    const [hour, setHour] = React.useState(0);
    const [counterOffer, setCounterOffer] = React.useState(undefined);
    const [question, setQuestion] = React.useState('');
    const [isCounterOffer, setIsCounterOffer] = React.useState(false);
    const [proposedHourlyRate, setProposedHourlyRate] = React.useState(undefined);
    const [proposedHourlyRateErr, setProposedHourlyRateErr] = React.useState(false);
    const [directEmpolinkRate, setDirectEmpolinkRate] = React.useState(1);
    const [throughEmpolinkRate, setThroughEmpolinkRate] = React.useState(1);
    const [directEmpolinkRateEmployer, setDirectEmpolinkRateEmployer] = React.useState(1);
    const [throughEmpolinkRateEmployer, setThroughEmpolinkRateEmployer] = React.useState(1);
    const [empolinkServiceFee, setEmpolinkServiceFee] = React.useState(0);
    const [empolinkServiceFeeEmployer, setEmpolinkServiceFeeEmployer] = React.useState(0);
    const [counterOfferError, setCounterOfferError] = React.useState(false);
    const { jobData, employer, jobLoading } = props;

    const ApplyForJob = () => {
        if (isCounterOffer && (parseFloat(counterOffer) <= 0 || !counterOffer)) {
            setCounterOfferError(true);
            return;
        }

        var valid = true;
        if (jobData.jobType === JOB_TYPE.HOURLY) {
            if (!proposedHourlyRate) {
                setProposedHourlyRateErr(true);
                valid = false;
            }
        } else {
            empolinkService();
        }

        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        if (!proposedHourlyRateErr && valid) {
            const fixedFee = counterOffer || (jobData.JobValue && jobData.JobValue) || 0;

            let payload = {
                job: jobData._id,
                employee: localStorage.getItem('userId'),
                workStartDate: jobData.startDate,
                workEndDate: jobData.endDate,
                question,
            };

            payload =
                jobData.jobType === JOB_TYPE.FIXED
                    ? {
                        ...payload,
                        proposedFeeByEmployer: jobData.JobValue ? jobData.JobValue || 0 : 0,
                        proposedFeeByEmployee: fixedFee,
                        empolinkServiceFee: empolinkServiceFee + empolinkServiceFeeEmployer || 0,
                        empolinkServiceFeeByEmployee: empolinkServiceFee,
                        empolinkServiceFeeByEmployer: empolinkServiceFeeEmployer,
                        employeeCompensation: fixedFee - parseFloat(empolinkServiceFee || 0),
                    }
                    : {
                        ...payload,
                        proposeHourlyRate: parseFloat(proposedHourlyRate || 0),
                        empolinkServiceFee: empolinkServiceFee + empolinkServiceFeeEmployer || 0,
                        empolinkServiceFeeByEmployee: empolinkServiceFee,
                        empolinkServiceFeeByEmployer: empolinkServiceFeeEmployer,
                        employeeCompensation:
                            parseFloat(jobData.hourlyTotalHours || 1) *
                            parseFloat(proposedHourlyRate) -
                            parseFloat(empolinkServiceFee || 0),
                    };

            const endPoint =
                jobData.jobType === JOB_TYPE.FIXED ? API.APPLY_TO_FIX_JOB : API.APPLY_TO_HOURLY_JOB;

            APIHelper.postMethod(endPoint, payload, headers)
                .then((response) => {
                    props.onClose(false);
                    if (typeof response === 'object') {
                        Swal.fire({
                            customClass: 'Alert-Class',
                            title: 'Information!',
                            text: 'Applied for Job successfully',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#1876D2',
                        });
                    } else {
                        Swal.fire({
                            customClass: 'Alert-Class',
                            title: 'Information!',
                            text: response,
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#1876D2',
                        });
                    }
                })
                .catch((error) => {
                    // props.onClose(false);
                    // Swal.fire({
                    //     customClass: "Alert-Class",
                    //     title: 'Information!',
                    //     text: "Failed",
                    //     icon: 'error',
                    //     confirmButtonText: 'Ok',
                    //     confirmButtonColor: '#1876D2',
                    // });
                });
        }
    };

    React.useEffect(() => {
        getEmpolinkServiceData();
    }, []);

    React.useEffect(() => {
        empolinkService();
    }, [proposedHourlyRate, counterOffer, isCounterOffer]);

    const empolinkService = () => {
        if (jobData) {
            if (jobData.jobType === JOB_TYPE.FIXED) {
                const fixedFee = counterOffer || (jobData.JobValue && jobData.JobValue) || 0;
                if (jobData.paymentMethod === 'DIRECT') {
                    const serviceFeeEmployee =
                        (parseFloat(fixedFee) * parseFloat(directEmpolinkRate)) / 100;
                    setEmpolinkServiceFee(serviceFeeEmployee);
                    const serviceFeeEmployer =
                        (parseFloat(fixedFee) * parseFloat(directEmpolinkRateEmployer)) / 100;
                    setEmpolinkServiceFeeEmployer(serviceFeeEmployer);
                } else {
                    const serviceFeeEmployee =
                        (parseFloat(fixedFee) * parseFloat(throughEmpolinkRate)) / 100;
                    setEmpolinkServiceFee(serviceFeeEmployee);
                    const serviceFeeEmployer =
                        (parseFloat(fixedFee) * parseFloat(throughEmpolinkRateEmployer)) / 100;
                    setEmpolinkServiceFeeEmployer(serviceFeeEmployer);
                }
            } else {
                if (jobData.paymentMethod === 'DIRECT') {
                    const serviceFeeEmployee =
                        (parseFloat(jobData.hourlyTotalHours) *
                            parseFloat(proposedHourlyRate) *
                            parseFloat(directEmpolinkRate)) /
                        100;
                    setEmpolinkServiceFee(serviceFeeEmployee);
                    const serviceFeeEmployer =
                        (parseFloat(jobData.hourlyTotalHours) *
                            parseFloat(proposedHourlyRate) *
                            parseFloat(directEmpolinkRateEmployer)) /
                        100;
                    setEmpolinkServiceFeeEmployer(serviceFeeEmployer);
                } else {
                    const serviceFeeEmployee =
                        (parseFloat(jobData.hourlyTotalHours) *
                            parseFloat(proposedHourlyRate) *
                            parseFloat(throughEmpolinkRate)) /
                        100;
                    setEmpolinkServiceFee(serviceFeeEmployee);
                    const serviceFeeEmployer =
                        (parseFloat(jobData.hourlyTotalHours) *
                            parseFloat(proposedHourlyRate) *
                            parseFloat(throughEmpolinkRateEmployer)) /
                        100;
                    setEmpolinkServiceFeeEmployer(serviceFeeEmployer);
                }
            }
        }
    };

    const getEmpolinkServiceData = () => {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        APIHelper.getMethod(`${API.GET_APPLICATION_VARIABLE}`, headers)
            .then((response) => {
                const direct = response.filter((res) => res.name === 'feesrvB1');
                if (direct && direct.length > 0) {
                    setDirectEmpolinkRate(parseFloat(direct[0].value));
                }
                const through = response.filter((res) => res.name === 'feesrvB2');
                if (through && through.length > 0) {
                    setThroughEmpolinkRate(parseFloat(through[0].value));
                }
                const directEmployer = response.filter((res) => res.name === 'feesrvA1');
                if (directEmployer && directEmployer.length > 0) {
                    setDirectEmpolinkRateEmployer(parseFloat(directEmployer[0].value));
                }
                const throughEmployer = response.filter((res) => res.name === 'feesrvA2');
                if (throughEmployer && throughEmployer.length > 0) {
                    setThroughEmpolinkRateEmployer(parseFloat(throughEmployer[0].value));
                }
            })
            .catch((err) => { });
    };

    const handleDecimalsOnValue = (value) => {
        const regex = /([0-9]*[\.]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }

    return (
        <>
            {props.jobData && props.jobData.jobType === JOB_TYPE.HOURLY && (
                <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>
                            {props.jobData.applicationType === 'INVITATION' ||
                                props.applicationData?.isRehiring
                                ? 'Job Offer'
                                : 'Job Overview'}
                        </h3>
                    </div>
                    {jobLoading ? (
                        <div style={{ textAlign: 'center', marginTop: '250px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{ marginTop: 64, fontSize: 20 }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {props.jobData && props.jobData.title}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 45, alignItems: 'center' }}>
                                {props.employer && props.employer.company && (
                                    <>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Client:</p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <AccountCircleIcon
                                                style={{ height: 52, width: 52, marginRight: 10 }}
                                            />
                                            {/* {/* TODO */}
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.employer &&
                                                    props.employer.company &&
                                                    props.employer.company.name}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div style={{ display: 'flex', marginTop: 16 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                                <p
                                    style={{ color: '#1c1c1c' }}
                                >{`${employer.firstName} ${employer.lastName}`}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Field of Work:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {props.jobData &&
                                        props.jobData.field &&
                                        props.jobData.field.name}
                                </p>
                            </div>
                            {(!props?.applicationData || !props.applicationData?.isRehiring) && (
                                <>
                                    {props.jobData.jobPostType === 'JOB_POST' && (
                                        <>
                                            <div style={{ display: 'flex', marginTop: 30 }}>
                                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                                    Speciality:
                                                </p>
                                                <p style={{ color: '#1c1c1c' }}>
                                                    {props.jobData &&
                                                        props.jobData.speciality &&
                                                        props.jobData.speciality.name}
                                                </p>
                                            </div>
                                            <div style={{ display: 'flex', marginTop: 30 }}>
                                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                                    Skills:
                                                </p>
                                                <div>
                                                    <div style={{ marginBottom: 8 }}>
                                                        {props.jobData.skills &&
                                                            props.jobData.skills.map((sk) => (
                                                                <Chip
                                                                    label={sk.name}
                                                                    style={{
                                                                        marginRight: 6,
                                                                        width: 130,
                                                                        marginBottom: 6,
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    marginTop: 30,
                                                    marginBottom: 50,
                                                }}
                                            >
                                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                                    Experience:
                                                </p>
                                                <p style={{ color: '#1c1c1c' }}>
                                                    {props.jobData.experience &&
                                                        props.jobData.experience.min}
                                                    -
                                                    {props.jobData.experience &&
                                                        props.jobData.experience.max}{' '}
                                                    years
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ marginBottom: 50 }}>
                                <p style={{ color: '#9a9a9a', marginTop: 50 }}>Job Description</p>
                                <pre
                                    style={{
                                        marginTop: 10,
                                        color: '#1c1c1c',
                                        fontsize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {props.jobData.description}
                                </pre>
                            </div>
                            {props.jobData.additionalDetails && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginBottom: 50 }}>
                                        <p style={{ color: '#9a9a9a', marginTop: 50 }}>
                                            Additional Instruction
                                        </p>
                                        <pre
                                            style={{
                                                marginTop: 10,
                                                color: '#1c1c1c',
                                                fontsize: 20,
                                                fontFamily: 'Libre Franklin',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {props.jobData.additionalDetails}
                                        </pre>
                                    </div>
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    {props.jobData.applicationType === 'INVITATION' ||
                                        props.applicationData?.isRehiring
                                        ? 'Offer Expiry Date:'
                                        : 'Job Post Expiry Date:'}
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {moment(jobData.jobExpiryDate).utc(0).format('MMM DD, YY')}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.remoteWorking
                                        ? 'Remote Working'
                                        : jobData.workAddress &&
                                        `${jobData.workAddress.unit_number + ', ' ?? ''}${jobData.workAddress.street_address + ', ' ?? ''
                                        }${jobData.workAddress.city + ', ' ?? ''}${jobData.workAddress.state + ', ' ?? ''
                                        }${jobData.workAddress.postal_code + ', ' ?? ''}${jobData.workAddress.country ?? ''
                                        }`}
                                </p>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            <p
                                style={{
                                    width: 350,
                                    color: '#9a9a9a',
                                    marginTop: 60,
                                    marginBottom: 10,
                                }}
                            >
                                Work Schedule
                            </p>
                            <div style={{ height: '350px', overflow: 'auto', width: '100%' }}>
                                <table
                                    style={{
                                        width: '100%',
                                        textAlign: 'right',
                                        marginTop: 10,
                                        border: '1px solid #c7c7c7',
                                        borderRadius: 10,
                                        padding: 30,
                                        borderCollapse: 'separate',
                                        borderSpacing: 0,
                                        fontSize: 18,
                                    }}
                                >
                                    <thead>
                                        <tr style={{ height: 60 }}>
                                            <th
                                                style={{
                                                    width: '13%',
                                                    textAlign: 'left',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Date
                                            </th>
                                            <th
                                                style={{
                                                    width: '19%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Start Time
                                            </th>
                                            <th
                                                style={{
                                                    width: '19%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                End Time
                                            </th>
                                            <th
                                                style={{
                                                    width: '25%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Non-Payable(hr)
                                            </th>
                                            <th
                                                style={{
                                                    width: '24%',
                                                    borderBottom: '1px solid #9a9a9a',
                                                    fontSize: 18,
                                                }}
                                            >
                                                Working Hours
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobData &&
                                            jobData.hourlySchedule &&
                                            jobData.hourlySchedule.map((item) => (
                                                <tr style={{ height: 60 }}>
                                                    <td
                                                        style={{
                                                            width: '13%',
                                                            textAlign: 'left',
                                                            fontSize: 18,
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {moment(item.dateSchedule)
                                                            .utc(0)
                                                            .format('MMM DD, YY')}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '19%',
                                                            fontSize: 18,
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {convertFrom24To12Format(
                                                            item.startTime.hours,
                                                            item.startTime.minutes
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '19%',
                                                            fontSize: 18,
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {convertFrom24To12Format(
                                                            item.endTime.hours,
                                                            item.endTime.minutes
                                                        )}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '25%',
                                                            fontSize: 18,
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {item.nonPayableTime}
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '25%',
                                                            fontSize: 18,
                                                            borderBottom: '1px solid #9a9a9a',
                                                        }}
                                                    >
                                                        {item.totalHours}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 23,
                                    marginBottom: 60,
                                    alignItems: 'center',
                                }}
                            >
                                <p style={{ color: '#707070', fontSize: 20, fontWeight: 600 }}>
                                    Total working Hours
                                </p>
                                <div
                                    style={{
                                        width: 100,
                                        border: '1px solid rgb(199, 199, 199)',
                                        padding: 14,
                                        textAlign: 'center',
                                        borderRadius: 6,
                                    }}
                                >
                                    <p>{jobData.hourlyTotalHours}</p>
                                </div>
                            </div>
                            <div className="EditDetailsBottomLineStyles" />
                            {jobData.jobPostType === 'JOB_POST' && (
                                <div style={{ display: 'flex', marginTop: 60 }}>
                                    <p style={{ width: 350, color: '#9a9a9a' }}>
                                        Job Post Hourly Rate:
                                    </p>
                                    <p style={{ color: '#1c1c1c' }}>{`$${jobData.rate && jobData.rate.min
                                        } - ${jobData.rate && jobData.rate.max}`}</p>
                                </div>
                            )}
                            {!props.isApplied && (
                                <div
                                    style={{ display: 'flex', marginTop: 14, alignItems: 'center' }}
                                >
                                    <p style={{ width: 350, color: '#9a9a9a' }}>
                                        Proposed Hourly Rate:
                                    </p>
                                    <div style={{ width: 100 }}>
                                        <Textfield
                                            width={'90px'}
                                            value={proposedHourlyRate}
                                            newProps={{
                                                inputProps: {
                                                    maxLength: 13,
                                                    step: "1"
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (
                                                    parseFloat(event.target.value) >=
                                                    parseFloat(jobData.rate.min) &&
                                                    parseFloat(event.target.value) <=
                                                    parseFloat(jobData.rate.max)
                                                ) {
                                                    setProposedHourlyRateErr(false);
                                                } else {
                                                    setProposedHourlyRateErr(true);
                                                }
                                                setProposedHourlyRate(handleDecimalsOnValue(event.target.value));
                                            }}
                                            error={proposedHourlyRateErr}
                                        />
                                    </div>
                                </div>
                            )}
                            {jobData.jobPostType === 'JOB_POST' && !props.isApplied && (
                                <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 2 }}>
                                    Only hourly rates that are within the above range can be
                                    entered.
                                </p>
                            )}
                            {props.isApplied && (
                                <div style={{ display: 'flex', marginTop: 30 }}>
                                    <p style={{ width: 350, color: '#9a9a9a' }}>Hourly Rate:</p>
                                    <p style={{ color: '#1c1c1c' }}>
                                        ${props.applicationData.proposeHourlyRate}
                                    </p>
                                </div>
                            )}
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Value:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    $
                                    {!props.isApplied
                                        ? (proposedHourlyRate &&
                                            jobData.hourlyTotalHours &&
                                            parseFloat(jobData.hourlyTotalHours) *
                                            parseFloat(proposedHourlyRate)) ||
                                        '00.00'
                                        : parseFloat(
                                            props.applicationData &&
                                            (props.applicationData.proposeHourlyRate || 0)
                                        ) * parseFloat(jobData.hourlyTotalHours || 0)}
                                </p>
                            </div>
                            <>
                                <div style={{ display: 'flex', marginTop: 30 }}>
                                    <p style={{ width: 350, color: '#9a9a9a' }}>
                                        Empolink Service Fee*:
                                    </p>
                                    {!props.isApplied ? (
                                        <p style={{ color: '#1c1c1c' }}>
                                            ${empolinkServiceFee.toFixed(2) || '00.00'}
                                        </p>
                                    ) : (
                                        <p style={{ color: '#1c1c1c' }}>
                                            $
                                            {props.applicationData &&
                                                (props.applicationData.empolinkServiceFeeByEmployee.toFixed(2) ||
                                                    '00.00')}
                                        </p>
                                    )}
                                </div>
                                <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 10 }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? '* Empolink service fee (%X of the Job Value) will be charged to talent account.'
                                        : '* %X of the Job Value.'}
                                </p>
                                <div style={{ display: 'flex', marginTop: 30 }}>
                                    <p style={{ width: 350, color: '#9a9a9a' }}>
                                        Talent Compensation*:
                                    </p>
                                    <p style={{ color: '#1c1c1c' }}>
                                        $
                                        {!props.isApplied
                                            ? (proposedHourlyRate &&
                                                jobData.hourlyTotalHours &&
                                                (parseFloat(jobData.hourlyTotalHours) *
                                                    parseFloat(proposedHourlyRate) -
                                                    parseFloat(empolinkServiceFee || 0)).toFixed(2)) ||
                                            '00.00'
                                            : `${props.applicationData &&
                                            props.applicationData.employeeCompensation.toFixed(2)
                                            }`}
                                    </p>
                                </div>
                                <p style={{ color: '#9a9a9a', fontSize: 18, marginTop: 5 }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? '* Talent compensation is equal to job value that client will pay talent directly.'
                                        : '* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink.'}
                                </p>
                            </>
                            {/* )} */}
                            <div style={{ display: 'flex', marginTop: 58 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Client Payment Method:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? 'Directly by Client'
                                        : 'Through Empolink'}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Payment Cycle:</p>
                                <p>
                                    {jobData.paymentFrequency === 'WEEKLY' ? 'Weekly' : 'Bi-weekly'}
                                </p>
                            </div>
                            {(!props?.applicationData || !props.applicationData?.isRehiring) && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ display: 'flex', marginTop: 50 }}>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                                    </div>
                                    <div
                                        style={{
                                            height: 210,
                                            border: '1px solid #c7c7c7',
                                            marginTop: 10,
                                            borderRadius: 10,
                                            padding: 20,
                                        }}
                                    >
                                        {jobData &&
                                            jobData.attachments &&
                                            jobData.attachments.map((attach) => (
                                                <a
                                                    href={attach.url}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={getAttachmentIcon(attach.name)}
                                                        style={{
                                                            width: 60,
                                                            height: 60,
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                </a>
                                            ))}
                                    </div>
                                </>
                            )}
                            {!props.isApplied && (
                                <>
                                    {!props.isPaymentMethodVerified && (
                                        <p
                                            style={{
                                                marginBottom: 8,
                                                marginTop: 24,
                                                color: 'red'
                                            }}
                                        >
                                            Note: Your payment method is either expired or not
                                            verified. Please update your payment information.
                                        </p>
                                    )}
                                    <div style={{ display: 'flex', marginTop: 50 }}>
                                        <ContainedButtons
                                            text="Apply"
                                            color={['#a4c772', '#4e8400']}
                                            height="56px"
                                            width={'200px'}
                                            onClick={() => ApplyForJob()}
                                            disabled={!props.isPaymentMethodVerified}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
            {props.jobData && props.jobData.jobType === JOB_TYPE.FIXED && (
                <div style={{ padding: 100, paddingTop: 70, fontFamily: 'Libre Franklin' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 style={{ margin: 0, fontSize: 36, fontWeight: '500' }}>Job Overview</h3>
                    </div>
                    {jobLoading ? (
                        <div style={{ textAlign: 'center', marginTop: '250px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div style={{ marginTop: 64, fontSize: 20 }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Job Title:</p>
                                <p style={{ color: '#1c1c1c' }}>{jobData && jobData.title}</p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 45, alignItems: 'center' }}>
                                {employer && employer.company && (
                                    <>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Client:</p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <AccountCircleIcon
                                                style={{ height: 52, width: 52, marginRight: 10 }}
                                            />
                                            <p style={{ color: '#1c1c1c' }}>
                                                {props.employer &&
                                                    employer.company &&
                                                    employer.company.name}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div style={{ display: 'flex', marginTop: 16 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Client Name:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {employer && `${employer.firstName} ${employer.lastName}`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Field of Work:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {props.jobData &&
                                        props.jobData.field &&
                                        props.jobData.field.name}
                                </p>
                            </div>
                            {(!props?.applicationData || !props.applicationData?.isRehiring) && (
                                <>
                                    <div style={{ display: 'flex', marginTop: 30 }}>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Speciality:</p>
                                        <p style={{ color: '#1c1c1c' }}>
                                            {props.jobData &&
                                                props.jobData.speciality &&
                                                props.jobData.speciality.name}
                                        </p>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 30 }}>
                                        <p style={{ minWidth: 350, color: '#9a9a9a' }}>Skills:</p>
                                        <div>
                                            <div style={{ marginBottom: 8 }}>
                                                {props.jobData.skills &&
                                                    props.jobData.skills.map((sk) => (
                                                        <Chip
                                                            label={sk.name}
                                                            style={{
                                                                marginTop: 15,
                                                                marginRight: 6,
                                                                width: 130,
                                                                marginBottom: 6,
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}
                                    >
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Experience:</p>
                                        <p style={{ color: '#1c1c1c' }}>
                                            {props.jobData.experience &&
                                                props.jobData.experience.min}
                                            -
                                            {props.jobData.experience &&
                                                props.jobData.experience.max}{' '}
                                            years
                                        </p>
                                    </div>
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ marginBottom: 50 }}>
                                <p style={{ color: '#9a9a9a', marginTop: 50 }}>Job Description</p>
                                <pre
                                    style={{
                                        marginTop: 10,
                                        color: '#1c1c1c',
                                        fontsize: 20,
                                        fontFamily: 'Libre Franklin',
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {props.jobData.description}
                                </pre>
                            </div>
                            {props.jobData.additionalDetails && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginBottom: 50 }}>
                                        <p style={{ color: '#9a9a9a', marginTop: 50 }}>
                                            Additional Instruction
                                        </p>
                                        <pre
                                            style={{
                                                marginTop: 10,
                                                color: '#1c1c1c',
                                                fontsize: 20,
                                                fontFamily: 'Libre Franklin',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {props.jobData.additionalDetails}
                                        </pre>
                                    </div>
                                </>
                            )}
                            {props.isApplied && props.applicationData.status === 'APPROVED' && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginTop: 50 }}>
                                        <p
                                            style={{
                                                color: '#9a9a9a',
                                                marginTop: 50,
                                            }}
                                        >
                                            Talent Questions
                                        </p>
                                        <p style={{ marginTop: 10, color: '#1c1c1c' }}>
                                            {props.applicationData &&
                                                props.applicationData.QuestionAnswer &&
                                                props.applicationData.QuestionAnswer.length > 0 &&
                                                props.applicationData.QuestionAnswer[0].message}
                                        </p>
                                    </div>
                                    <div style={{ marginBottom: 50, marginTop: 30 }}>
                                        <p style={{ color: '#9a9a9a' }}>Client Response</p>
                                        <p style={{ marginTop: 10, color: '#1c1c1c' }}>
                                            {props.applicationData &&
                                                props.applicationData.QuestionAnswer &&
                                                props.applicationData.QuestionAnswer.length > 1
                                                ? props.applicationData.QuestionAnswer[1].message
                                                : 'None'}
                                        </p>
                                    </div>
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Job Post Expiry Date:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {moment(jobData.jobExpiryDate).utc(0).format('MMM DD, YY')}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Address:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.remoteWorking
                                        ? 'Remote Working'
                                        : jobData.workAddress &&
                                        `${jobData.workAddress.unit_number + ', ' ?? ''}${jobData.workAddress.street_address + ', ' ?? ''
                                        }${jobData.workAddress.city + ', ' ?? ''}${jobData.workAddress.state + ', ' ?? ''
                                        }${jobData.workAddress.postal_code + ', ' ?? ''}${jobData.workAddress.country ?? ''
                                        }`}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work Start Date:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {moment(jobData && jobData.startDate)
                                        .utc(0)
                                        .format('MMM DD, YY')}
                                </p>
                            </div>
                            <div style={{ display: 'flex', marginTop: 30, marginBottom: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Work End Date:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {moment(jobData && jobData.endDate)
                                        .utc(0)
                                        .format('MMM DD, YY')}
                                </p>
                            </div>
                            {props.isApplied && props.applicationData?.status === 'EMPLOYEE_ACCEPT' && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <p style={{ width: 350, color: '#9a9a9a', marginTop: 59 }}>
                                        Payment Schedule
                                    </p>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: 379,
                                            borderRadius: 10,
                                            border: 'solid 2px #c7c7c7',
                                            marginTop: 10,
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginTop: '1.5vw',
                                                marginLeft: '1.5vw',
                                                marginRight: '1.5vw',
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{ flex: 4, textAlign: 'left' }}
                                                className="scheduleTableHeading"
                                            >
                                                Milestones
                                            </div>
                                            <div
                                                style={{ flex: 3 }}
                                                className="scheduleTableHeading"
                                            >
                                                Milestone Name
                                            </div>
                                            <div
                                                style={{ flex: 3 }}
                                                className="scheduleTableHeading"
                                            >
                                                Payment Date
                                            </div>
                                            <div
                                                style={{ flex: 4, textAlign: 'right' }}
                                                className="scheduleTableHeading"
                                            >
                                                Amount
                                            </div>
                                        </div>
                                        <div className="scheduleTableHeadingsBottom"></div>
                                        <div
                                            style={{
                                                height: '270px',
                                                overflowX: 'auto',
                                                marginTop: '10px',
                                            }}
                                        >
                                            {props.applicationData &&
                                                props.applicationData.milestone &&
                                                props.applicationData.milestone.map((item, id) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                borderBottom: '1px solid #c7c7c7',
                                                                marginLeft: '1.5vw',
                                                                width: '93%',
                                                                marginTop: '5px',
                                                                paddingBottom: '5px',
                                                                height: 40,
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                className="sceduleListDate"
                                                                style={{ flex: 4 }}
                                                            >
                                                                {id + 1}
                                                            </div>
                                                            <div
                                                                className="sceduleListStartTime"
                                                                style={{ flex: 3 }}
                                                            >
                                                                {item.description}
                                                            </div>
                                                            <div
                                                                className="sceduleListStartTime"
                                                                style={{ flex: 3 }}
                                                            >
                                                                {moment(item.dateSchedule)
                                                                    .utc(0)
                                                                    .format('MMM DD, YY')}
                                                            </div>
                                                            <div
                                                                className="sceduleListStartTime"
                                                                style={{
                                                                    flex: 4,
                                                                    textAlign: 'right',
                                                                }}
                                                            >
                                                                ${item.amount}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginLeft: '1.5vw',
                                                marginRight: '1.5vw',
                                                borderTop: '2px solid #707070',
                                                paddingTop: 10,
                                            }}
                                        >
                                            <p style={{ color: '#1c1c1c', fontSize: 18 }}>
                                                Total Amount
                                            </p>
                                            <p style={{ color: '#1c1c1c', fontSize: 18 }}>
                                                $
                                                {props.applicationData &&
                                                    props.applicationData.milestone &&
                                                    props.applicationData.milestone.reduce(
                                                        function (accumulator, item) {
                                                            return (
                                                                accumulator +
                                                                parseFloat(item.amount)
                                                            );
                                                        },
                                                        0
                                                    )}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="EditDetailsBottomLineStyles" />
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>Fixed Fee:</p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                    {(jobData && jobData.JobValue.toFixed(2)) || '00.00'}{' '}{jobData.currency}
                                </p>
                            </div>
                            {!props.isApplied && (
                                <div
                                    style={{ display: 'flex', marginTop: 16, alignItems: 'center' }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: 342,
                                            marginLeft: 8,
                                        }}
                                    >
                                        <CheckboxComponent
                                            checked={isCounterOffer}
                                            padding="0px"
                                            onChange={() => {
                                                setIsCounterOffer(!isCounterOffer);
                                            }}
                                            style={{ margin: 0 }}
                                            color="#a4c772"
                                        />
                                        <p style={{ color: '#9a9a9a' }}>Counter Offer:</p>
                                    </div>
                                    <div>
                                        <Textfield
                                            width={'150px'}
                                            placeholder="$00.00"
                                            disabled={!isCounterOffer}
                                            value={counterOffer}
                                            newProps={{
                                                inputProps: {
                                                    maxLength: 13,
                                                    step: "1"
                                                }
                                            }}
                                            onChange={(event) => {
                                                setCounterOffer(handleDecimalsOnValue(event.target.value));
                                                setCounterOfferError(false);
                                            }}
                                            error={counterOfferError}
                                        />
                                    </div>
                                </div>
                            )}
                            {props.isApplied && (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: 30,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 350,
                                            }}
                                        >
                                            <p style={{ color: '#9a9a9a' }}>Counter Offer:</p>
                                        </div>
                                        <div>
                                            <p style={{ color: '#1c1c1c' }}>
                                                {jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                {props.applicationData.finalFee.toFixed(2) ||
                                                    props.applicationData.proposedFeeByEmployee.toFixed(2)}{' '}{jobData.currency}
                                            </p>
                                        </div>
                                    </div>
                                    {props.applicationData?.status === 'EMPLOYEE_ACCEPT' && (
                                        <>
                                            <div style={{ display: 'flex', marginTop: 30 }}>
                                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                                    Empolink Service Fee*:
                                                </p>
                                                <p style={{ color: '#1c1c1c' }}>
                                                    {jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                    {props.applicationData?.empolinkServiceFee.toFixed(2) ||
                                                        '00.00'}{' '}{jobData.currency}
                                                </p>
                                            </div>
                                            <p
                                                style={{
                                                    color: '#9a9a9a',
                                                    fontSize: 18,
                                                    marginTop: 10,
                                                }}
                                            >
                                                {jobData.paymentMethod === 'DIRECT'
                                                    ? '* Empolink service fee (%X of the Job Value) will be charged to talent account.'
                                                    : '* %X of the Job Value.'}
                                            </p>
                                            <div style={{ display: 'flex', marginTop: 30 }}>
                                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                                    Talent Compensation*:
                                                </p>
                                                <p style={{ color: '#1c1c1c' }}>
                                                    {jobData.jobLocationType === "LOCAL" ? localStorage.getItem('local_currency_symbol') : "$"}
                                                    {props.applicationData &&
                                                        props.applicationData.employeeCompensation.toFixed(2)}
                                                    {' '}{jobData.currency}
                                                </p>
                                            </div>
                                            <p
                                                style={{
                                                    color: '#9a9a9a',
                                                    fontSize: 18,
                                                    marginTop: 5,
                                                }}
                                            >
                                                {jobData.paymentMethod === 'DIRECT'
                                                    ? '* Talent compensation is equal to job value that client will pay talent directly.'
                                                    : '* Talent compensation is equal to job value deducted by empolink service fee. This amount will be paid to talent through empolink.'}
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                            <div style={{ display: 'flex', marginTop: 30 }}>
                                <p style={{ width: 350, color: '#9a9a9a' }}>
                                    Client Payment Method:
                                </p>
                                <p style={{ color: '#1c1c1c' }}>
                                    {jobData.paymentMethod === 'DIRECT'
                                        ? 'Directly by Client'
                                        : 'Through Empolink'}
                                </p>
                            </div>
                            {/* && props.applicationData?.status === 'APPLIED' */}
                            {!props.isApplied && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginTop: 50, marginBottom: 60 }}>
                                        <p style={{ color: '#9a9a9a', marginBottom: 10 }}>
                                            Write any questions you have to the client
                                        </p>
                                        <Textfield
                                            width={'100%'}
                                            rows="8"
                                            multiline
                                            value={
                                                question ||
                                                props.applicationData?.QuestionAnswer?.[0]?.message
                                            }
                                            onChange={(event) => setQuestion(event.target.value)}
                                            disabled={props.isApplied}
                                        />
                                    </div>
                                </>
                            )}
                            {props.isApplied && props.applicationData?.status !== 'APPROVED' && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ marginTop: 50, marginBottom: 60 }}>
                                        <p style={{ color: '#9a9a9a', marginBottom: 10 }}>
                                            Write any questions you have to the client
                                        </p>
                                        <Textfield
                                            width={'100%'}
                                            rows="8"
                                            multiline
                                            value={
                                                question ||
                                                props.applicationData?.QuestionAnswer?.[0]?.message
                                            }
                                            onChange={(event) => setQuestion(event.target.value)}
                                            disabled={props.isApplied}
                                        />
                                    </div>
                                </>
                            )}
                            {(!props?.applicationData || !props.applicationData?.isRehiring) && (
                                <>
                                    <div className="EditDetailsBottomLineStyles" />
                                    <div style={{ display: 'flex', marginTop: 50 }}>
                                        <p style={{ width: 350, color: '#9a9a9a' }}>Attachments</p>
                                    </div>
                                    <div
                                        style={{
                                            height: 210,
                                            border: '1px solid #c7c7c7',
                                            marginTop: 10,
                                            borderRadius: 10,
                                            padding: 20,
                                        }}
                                    >
                                        {jobData &&
                                            jobData.attachments &&
                                            jobData.attachments.map((attach) => (
                                                <a
                                                    href={attach.url}
                                                    download
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <img
                                                        src={getAttachmentIcon(attach.name)}
                                                        style={{
                                                            width: 60,
                                                            height: 60,
                                                            marginRight: 10,
                                                        }}
                                                    />
                                                </a>
                                            ))}
                                    </div>
                                </>
                            )}
                            {!props.isApplied && (
                                <>
                                    {!props.isPaymentMethodVerified && (
                                        <p
                                            style={{
                                                marginBottom: 8,
                                                marginTop: 24,
                                                color: 'red'
                                            }}
                                        >
                                            Note: Your payment method is either expired or not
                                            verified. Please update your payment information.
                                        </p>
                                    )}
                                    <div style={{ display: 'flex', marginTop: 50 }}>
                                        <ContainedButtons
                                            text="Apply"
                                            color={['#a4c772', '#4e8400']}
                                            height="56px"
                                            width={'200px'}
                                            onClick={() =>
                                                props.isPaymentMethodVerified && ApplyForJob()
                                            }
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default JobApplicationApply;
