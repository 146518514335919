import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactS3 from "react-s3";

import Chip from '../../Components/Chip/CustomizableChip'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import './FileDropComponent.scss'
import { config } from '../FileUpload/FileUpload';
import { getFileName } from '../../utils/commonFunction';

const Basic = (props) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [any, setAny] = useState([]);
    const [dummy, setDummy] = useState("");

    const [queue, setQueue] = useState([]);


    const HandleFiles = () => {
        let givenArray = any
        acceptedFiles.map(file => {
            givenArray.push(file)
        })

        const filteredArr = givenArray.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);


        return filteredArr

    }

    let files = HandleFiles()

    const udpateFileStatus = (name, status) => {

        var fileQueue = queue;
        let objIndex = fileQueue.findIndex((obj => obj.name == name));
        fileQueue[objIndex].status = status;
        setQueue(fileQueue);
    }

    return (
        <section className="container">
            <div style={props.dropboxStyles} className={props.withoutWidth ? "dropboxWrapperElse" : "dropboxWrapper"}>
                <div style={{ width: "100%", height: "100%" }} {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p className="fileDropLabel">Drag and drop or Upload a maximum of 3 files up to 10MB each</p>
                    <div style={{ textAlign: "center", color: "#c7c7c7", fontSize: "2.5vw" }}><CloudDownloadIcon fontSize={"inherit"} /></div>
                    <div className="fileDropChipsWrapper">
                        {

                            files.map((item, index) => {
                                if (index < 3) {

                                    //get the index from the 
                                    let fileQueue = queue;
                                    let objIndex = fileQueue.findIndex((obj => obj.name == item.name));
                                    if (objIndex > -1) {
                                        console.log("Already in queue")
                                    } else {
                                        var uploadFile = {
                                            name: item.name,
                                            status: "new"
                                        }

                                        fileQueue.push(uploadFile);
                                        setQueue(fileQueue);

                                        if (fileQueue[fileQueue.findIndex((obj => obj.name == item.name))].status == "new") {
                                            udpateFileStatus(item.name, "uploading");
                                            const configS3 = {
                                                ...config,
                                                dirName: "documents",
                                            };

                                            const fileNameGenerate = getFileName(item.name);

                                            Object.defineProperty(item, 'name', {
                                                writable: true,
                                                value: fileNameGenerate
                                            });

                                            ReactS3.uploadFile(item, configS3)
                                                .then((data) => {
                                                    udpateFileStatus(item.name, "uploaded")
                                                    props.setFile(data.location, item.name);
                                                }).catch();
                                        } else {
                                            console.log("File is in Queue of uploaded" + item.name);
                                        }
                                    }


                                    return (
                                        <div style={{ marginLeft: "1vw" }}>
                                            <Chip
                                                height={"1.66vw"}
                                                fontSize={"0.729vw"}
                                                label={item.path}
                                                onDelete={(index) => {
                                                    files.splice(index, 1)
                                                    setDummy("0")
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            }

                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Basic;